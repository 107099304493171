import * as app from './action';

export class DataTemplate {
    title: string;
    description: string;
    // id: string;
    fileName: string;
    content: string;
    date:number;
    // get_file_name(extension: string = ""): string {
    //     return this.title.replace(/ /g, "_") + extension;
    // }
}

export class DataTemplateContext {
    constructor(public selectedDataTemplateId: string, public selectedDataTemplateTitle: string) {
    }
}

export interface State {
    dataTemplates: DataTemplate[];
    dataTemplate_context: DataTemplateContext;
    selected_dataTemplate: DataTemplate;
    content: any;
    model_content:any;
}

const initialState: State = {
    dataTemplates: [],
    dataTemplate_context: new DataTemplateContext("", ""),
    selected_dataTemplate: new DataTemplate(),
    content: null,
    model_content:null
};

function getDataTemplateArray(dataFromServer) {
    var result = [];
    if (dataFromServer) {
        dataFromServer.forEach(d => {
            let data = new DataTemplate();
            data.title = d.title;
            data.description = d.description;
            // data.id = d.id;
            // data.fileName = d.fileName;
            result.push(data);
        });
    }
    return result;
}
function selectDataTemplate(dataTemplate_list, title): DataTemplate {
    const dataTemplate: DataTemplate = dataTemplate_list.find(o => o.title == title);
    return dataTemplate;
}

export function reducer(state = initialState, action: app.Actions): State {
    switch (action.type) {
        case app.ActionTypes.SET_DATATEMPLATES:
            return Object.assign({}, state, { dataTemplates: getDataTemplateArray(action.payload.dataTemplates) });
        case app.ActionTypes.SET_SELECTED_DATATEMPLATE:
            return Object.assign({}, state, { selected_dataTemplate: selectDataTemplate(state.dataTemplates, action.payload.dataTemplateTitle) });
        case app.ActionTypes.SET_CONTENT:
            return Object.assign({}, state, { content: action.payload });
        case app.ActionTypes.SET_DATATEMPLATE_CONTEXT:
            return Object.assign({}, state, { dataTemplate_context: action.payload });
        case app.ActionTypes.SET_MODEL_CONTENT:
            return Object.assign({}, state, { model_content: action.payload });
        default:
            return state;
    }
}
export const get_dataTemplates = (state: State) => state.dataTemplates;
export const get_selected_dataTemplate = (state: State) => state.selected_dataTemplate;
export const get_content = (state: State) => state.content;
export const get_dataTemplate_context = (state: State) => state.dataTemplate_context;
export const get_model_content = (state: State) => state.model_content;


