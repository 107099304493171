import { ItemComponent } from '@swimlane/ngx-dnd';
import * as app from './action';

export class CurrentPersona {
    clientName: string;
    acadYearFrom: Date;
    acadYearTo: Date;
    id: string;
    clientId: string;
    isOrg: boolean;
    clientShortName: string;
    title: string;
}

export class Occupation {
    title: string = "";
    particulars: string = "";
    asOn: any;
}

export class Address {
    line1: string = "";
    line2: string = "";
    city: string = "";
    state: string = "";
    pincode: number;
    country: string = "";
}

export class Student {
    domicile: Domicile;
    termName: any;
    secName: any;
    attendance: string;
    attendanceList: any[];
    aadharNo: string;
    id: string;
    name: string;
    tags: string[];
    tagsAsString: string;
    gender: string;
    DOB: Date;
    DOA: Date;
    course: Course;
    courseId: string;
    courseName: string;
    admissionNo: number;
    applicationNo: number;
    rollNo: number;
    studentId: string;
    address: Address;
    email: string;
    mobile: number;
    homePhone: number;
    status: number;
    editRollNo: false;
    editEmail: false;
    editMobile: false;
    editGender: false;
    editTags: false;
    contacts: Contacts[];
    enrollments: Enrollments[] = [];
    father: Father;
    mother: Mother;
    guardian: Guardian;
    statusAsString: string;
    branch: any;
    instId: string;
    photoURL: string;
    sectionName: string;
    parentEmails: any[];
    parentMobiles: any[];
    // address: any;
    editStudentId: false;
    virtualId: string;
    parentMobilesAsString: string;
    uDID: string;
    fatherOccupation: Occupation;
    motherOccupation: Occupation;
    iep: any;
    baseline: any;
    medicalDiagnosisUrl: string;
    disabilityCertUrl: string;
    birthCertificateUrl: string;
    identificationProofUrl: string;
    addressProofUrl: string;
    therapyRptUrl: string;
    psycologicalRptUrl: string;

    constructor() {
        this.course = new Course();
        this.address = new Address();
        this.father = new Father();
        this.mother = new Mother();
        this.guardian = new Guardian();
        this.fatherOccupation = new Occupation();
        this.motherOccupation = new Occupation();
    }
}

class Father {
    name: string = "";
    email: string = "";
    mobile: number;
    emailStatusActive: boolean;
    mobileStatusActive: boolean;
    pan: string = "";
}

class Mother {
    name: string;
    email: string;
    mobile: number;
    emailStatusActive: boolean;
    mobileStatusActive: boolean;
    pan: string = "";
}

class Guardian {
    name: string;
    email: string;
    mobile: number;
    gender: string;
    emailStatusActive: boolean;
    mobileStatusActive: boolean;
}

class Enrollments {
    admissionNo: string;
    applicationNo: string;
    attributes: any;
    character: string;
    conduct: string;
    courseId: string;
    doa: string;
    instId: string;
    optionalSubjects: any;
    remarks: string;
    rollNo: string;
    rollNoAsInt: number;
    sectionName: string;
    status: number;
    studentId: string;
    tcAppliedOn: Date;
    tcIssuedOn: Date;
    tcNo: string;
    termName: string;
}

class Contacts {
    archivedOn: Date;
    contactType: number
    info: any
    location: number
    notes: string
    primary: boolean = true;
    status: number;
}

export class Course {
    name: string;
    termName: string;
    secName: string;
    id: string;
    fullName: string;
    sections: Section[] = [];
    status: number;
    branch: string;
    nameAndBranch: string;
    nameBranchAndSection: string;
}

export class Section {
    name: string;
    subjects: string[] = []
}

//Domicile
export class Domicile {
    country: string;
    state: string;
    motherTongue: string;
    caste: string;
}

export class Item {
    id: number;
    name: string;
    date: Date;
    amount: number;
    edit: false;
    amountAsString: string;
}


function disableStudent(students, id) {
    var studentList: Student[] = students.map((student) => {
        if (student.id == id) student.status = 99;
        return student;
    });
    return studentList;
}

function enableStudent(students, id) {
    var studentList: Student[] = students.map((student) => {
        if (student.id == id) student.status = 1;
        return student;
    });
    return studentList;
}

function tcStudent(students, id) {
    var studentList: Student[] = students.map((student) => {
        if (student.id == id) student.status = 98;
        return student;
    });
    return studentList;
}

function selectStudent(student_list, stu_id): Student {
    var selectedStudent: Student = student_list.find(student => student.id == stu_id);
    return selectedStudent;
}

function setStudentEssentials(dataFromServer) {
    let result = [];
    dataFromServer.items.forEach(o => {
        var item = new Item();
        item.id = o.id;
        item.name = o.name;
        item.amount = o.amount;
        item.date = o.date;
        item.amountAsString = o.amountAsString;
        result.push(item);
    });

    var obj = { Items: result, Id: dataFromServer.id };
    return obj;
}

function setStudentArray(dataFromServer) {
    let result = [];
    var totalPages = dataFromServer.totalPages;
    if (dataFromServer.data) {
        dataFromServer.data.forEach(o => {
            let student = new Student();
            student.domicile = o.domicile
            student.aadharNo = o.aadharNumber;
            student.id = o.id;
            student.attendance = o.attendance;
            student.attendanceList = o.attendanceList;
            student.name = o.name;
            student.DOB = o.dob;
            student.gender = o.gender;
            student.status = o.status;
            student.statusAsString = o.statusAsString;
            student.admissionNo = o.admissionNo;
            student.applicationNo = o.applicationNo;
            student.branch = o.branch;
            student.courseName = o.courseName;
            student.courseId = o.courseId;
            student.DOA = o.doa;
            student.email = o.email;
            student.instId = o.instId;
            student.mobile = o.mobile;
            student.parentEmails = o.parentEmails;
            student.parentMobiles = o.parentMobiles;
            student.parentMobilesAsString = o.parentMobiles.filter(m => m != '').toString();
            student.photoURL = o.photoURL;
            student.rollNo = o.rollNo;
            student.sectionName = o.sectionName;
            student.address = o.address;
            student.father = o.father;
            student.mother = o.mother;
            student.guardian = o.guardian;
            student.termName = o.termName;
            student.studentId = o.studentId;
            student.virtualId = o.virtualId;
            student.iep = o.iep;
            student.baseline = o.baseline;
            if (student.father && student.father['contacts'] && student.father['contacts'].length > 0) {
                student.father['contacts'].forEach(o => {
                    if (o.contactType == 10) {
                        student.father.email = o.info;
                        student.father.emailStatusActive = (o.info != '' && o.status == 1) ? true : false;
                    }
                    if (o.contactType == 20) {
                        student.father.mobile = o.info;
                        student.father.mobileStatusActive = (o.info != '' && o.status == 1) ? true : false;
                    }
                });
            }
            if (student.mother && student.mother['contacts'] && student.mother['contacts'].length > 0) {
                student.mother['contacts'].forEach(o => {
                    if (o.contactType == 10) {
                        student.mother.email = o.info;
                        student.mother.emailStatusActive = (o.info != '' && o.status == 1) ? true : false;
                    }
                    if (o.contactType == 20) {
                        student.mother.mobile = o.info;
                        student.mother.mobileStatusActive = (o.info != '' && o.status == 1) ? true : false;
                    }
                });
            }
            if (o.tags && o.tags.length > 0) { student.tagsAsString = o.tags.toString(); }
            if (student.guardian && student.guardian['contacts'] && student.guardian['contacts'].length > 0) {
                student.guardian['contacts'].forEach(o => {
                    if (o.contactType == 10) {
                        student.guardian.email = o.info;
                        student.guardian.emailStatusActive = (o.info != '' && o.status == 1) ? true : false;
                    }
                    if (o.contactType == 20) {
                        student.guardian.mobile = o.info;
                        student.guardian.mobileStatusActive = (o.info != '' && o.status == 1) ? true : false;
                    }
                });
            }
            if (student.address) {
                student.address.line1 = o.address.line1;
                student.address.line2 = o.address.line2;
                student.address.city = o.address.city;
                student.address.pincode = o.address.pincode;
                student.address.state = o.address.state;
                student.address.country = o.address.country;
            }
            else student.address = new Address();
            result.push(student);
        });
    }
    var stuObj = {
        "StudentList": result,
        "TotalPages": totalPages
    };

    return stuObj;
}

function updateDomicile(students, id, domicile) {
    var studentList: Student[] = students.map((student) => {
        if (student.id == id) {
            student.domicile = domicile;
        }
        return student;
    });

}
function updateHomePhone(students, id, homePhone) {
    var studentList: Student[] = students.map((student) => {
        if (student.id == id) {
            student.homePhone = homePhone;
        }
        return student;
    });
    return studentList
}

function updateStudentAddress(students, id, addressLine1, addressLine2, city, state, pinCode, country) {
    var studentList: Student[] = students.map((student) => {
        if (student.id == id) {
            student.addressLine1 = addressLine1;
            student.addressLine2 = addressLine2;
            student.city = city;
            student.state = state;
            student.pinCode = pinCode;
            student.country = country;
        }
        return student;
    });
}

function updateStudentDetails(students, id, name, DOB, gender, studentId, DOA, admissionNo, applicationNo) {
    var studentList: Student[] = students.map((student) => {
        if (student.id == id) {
            student.name = name;
            student.DOB = DOB;
            student.gender = gender;
            student.studentId = studentId;
            student.DOA = DOA;
            student.admissionNo = admissionNo;
            student.applicationNo = applicationNo;
        }
        return student;
    });
}

function setCourseArray(dataFromServer): Course[] {
    let courses = [];
    dataFromServer.data.forEach(o => {
        o.terms.forEach(t => {
            if (t)
                t.sections.forEach(s => {
                    if (s) {
                        let course = new Course();
                        course.id = o.id;
                        course.name = o.name;
                        course.fullName = o.name;
                        course.secName = s.name;
                        course.termName = t.name;
                        course.status = o.status;
                        course.branch = o.branch;
                        if (o.branch) { course.fullName = course.fullName + " " + o.branch; }
                        course.fullName = course.fullName + " " + s.name;
                        course.nameAndBranch = o.nameAndBranch;
                        course.nameBranchAndSection = o.nameAndBranch + " " + s.name;
                        courses.push(course);
                    }
                })
        })
    });
    return courses;
}

function setFilters(filters) {
    var fObj = { checked: false, children: [], fe: 'tcStudentsOnly:true', name: 'TC Students Only' };
    filters.filterItems.unshift(fObj);
    return filters;
}

export interface State {
    currentPersona: CurrentPersona;
    students: any;
    //selectedStudent: Student;
    selectedStudent: any;
    courseId: any;
    courseList: Course[];
    filters: any;
    response: any;
    searchTerm: string;
    queryString: string;
    essentials: any;
    documentUrl: any;
    baselineDataForm: any;
    baselineDataList: any;
}

const initialState: State = {
    currentPersona: null,
    students: null,
    //selectedStudent: new Student()
    selectedStudent: null,
    courseId: null,
    courseList: null,
    filters: null,
    response: null,
    searchTerm: null,
    queryString: null,
    essentials: null,
    documentUrl: null,
    baselineDataForm: null,
    baselineDataList: null
};

export function reducer(state = initialState, action: app.Actions): State {
    switch (action.type) {
        case app.ActionTypes.UPDATE_DOMICILE:
            return state;
        case app.ActionTypes.UPDATE_DOMICILE_SUCCESS:
            return Object.assign({}, state, { students: updateDomicile(state.response.StudentList, action.payload.id, action.payload.domicile) })
        case app.ActionTypes.ENROL_STUDENT:
            return Object.assign({}, state, { student: action.payload.student });
        case app.ActionTypes.DISABLE_STUDENT:
            return Object.assign({}, state, { students: disableStudent(state.response.StudentList, action.payload.id) });
        case app.ActionTypes.ENABLE_STUDENT:
            return Object.assign({}, state, { students: enableStudent(state.response.StudentList, action.payload.id) });
        case app.ActionTypes.TC_STUDENT:
            return Object.assign({}, state, { students: tcStudent(state.response.StudentList, action.payload.id) });
        case app.ActionTypes.SET_SELECTED_STUDENT:
            return Object.assign({}, state, { selectedStudent: selectStudent(state.response.StudentList, action.payload.student_id) });
        case app.ActionTypes.SET_STUDENTS:
            return Object.assign({}, state, { response: setStudentArray(action.payload.response) });
        case app.ActionTypes.UPDATE_HOME_PHONE:
            return Object.assign({}, state, { students: updateHomePhone(state.response.StudentList, action.payload.id, action.payload.homePhone) });
        case app.ActionTypes.UPDATE_STUDENT_ADDRESS:
            return Object.assign({}, state, { students: updateStudentAddress(state.response.StudentList, action.payload.id, action.payload.addressLine1, action.payload.addressLine2, action.payload.city, action.payload.state, action.payload.pinCode, action.payload.country) });
        case app.ActionTypes.UPDATE_STUDENT_DETAILS:
            return Object.assign({}, state, { students: updateStudentDetails(state.response.StudentList, action.payload.id, action.payload.name, action.payload.DOB, action.payload.gender, action.payload.studentId, action.payload.DOA, action.payload.admissionNo, action.payload.applicationNo) });
        case app.ActionTypes.SET_COURSE_ID:
            return Object.assign({}, state, { courseId: action.payload.id });
        case app.ActionTypes.SET_COURSES:
            return Object.assign({}, state, { courseList: setCourseArray(action.payload.course_list) });
        case app.ActionTypes.SET_FILTERS:
            return Object.assign({}, state, { filters: setFilters(action.payload.filters) });
        case app.ActionTypes.SET_SEARCH_TERM:
            return Object.assign({}, state, { searchTerm: action.payload.search_text });
        case app.ActionTypes.SET_QUERY_STRING:
            return Object.assign({}, state, { queryString: action.payload.query_string });
        case app.ActionTypes.SET_STUDENT_ESSENTIALS:
            return Object.assign({}, state, { essentials: setStudentEssentials(action.payload.essentials) });
        case app.ActionTypes.SET_DOCUMENT_URL:
            return Object.assign({}, state, { documentUrl: action.payload });    
        case app.ActionTypes.SET_BASELINE_DATA_FORM:
            return Object.assign({}, state, { baselineDataForm: action.payload.baselineDataForm });
            case app.ActionTypes.SET_BASELINE_DATA_LIST:
            return Object.assign({}, state, { baselineDataList: action.payload.baselineDataList });
        default:
            return state;
    }
}

export const get_state_student = (state: State) => state;
export const get_students = (state: State) => state.response;
export const get_selected_student = (state: State) => state.selectedStudent;
export const get_course_id = (state: State) => state.courseId;
export const get_courses_for_students = (state: State) => state.courseList;
export const get_filters = (state: State) => state.filters;
export const get_stu_manage_search_term = (state: State) => state.searchTerm;
export const get_stu_manage_query_string = (state: State) => state.queryString;
export const get_stu_essentials = (state: State) => state.essentials;
export const get_document_url = (state: State) => state.documentUrl;
export const get_baseline_data_form = (state: State) => state.baselineDataForm;
export const get_baseline_data_list = (state: State) => state.baselineDataList;
//Domicile

// export const get_states_data = (state: State) => state.statesData;