import { Component, OnInit,NgZone, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as rootReducer from '../rootReducer';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import {Location} from '@angular/common';

class model {
	public ctContext: any;
	tranData = {
		key: '9XGZQpPh',
		txnid: 'tx-12',
		hash: '001c73894ecb0c210f5064928923ca4f224834466ecc317bf541c85157dc12c9790ea2e559f13a6d01ef292dd4f47cbd8bf278c37764196be76b30f07f165f11',
		amount: '10000',
		firstname: 'abhay',
		email: 'abhay@bitloka.com',
		phone: '9845098450',
		productinfo: 'timetable',
		udf1: '9845098450',
		surl : '',
		furl: ''
	}
}

@Component({
	templateUrl: './component.html',
	styleUrls: ['./component.css'],
	// providers: [service]
})
export class PaymentComponent implements OnInit {
	public m: model;
	constructor(private httpClient: HttpClient, private store: Store<rootReducer.State>, private router: Router,private _ngZone: NgZone, private el: ElementRef, private _location: Location) {
    }
	ngOnInit() {
		this.init_model();
		var arr = environment.API_URL.split('api');
		this.m.tranData.surl = arr[0] + 'home';
		this.m.tranData.furl = this.m.tranData.surl;
		this.loadScript('https://sboxcheckout-static.citruspay.com/bolt/run/bolt.js');
		this.init_store();
		this.sub_store();
	}
	ngOnDestroy() {
	}
	init_model() {
		this.m = new model();
	}
	init_store() {
	}
	sub_store() {
	}
	launchBolt(){
		var that = this;
		var Handler = {
			responseHandler: function(resp){
				that.router.navigate(['/home']);
				// if(resp.response.txnStatus=="CANCEL"){
				// 	that.router.navigate(['/home']);
				// }
			},
			catchException: function(err){
				console.log(err);
			}
		};
		(<any>window).bolt.launch(this.m.tranData,Handler); 
	}
	loadScript(url: string) {
		var that = this;
		const body = <HTMLDivElement> document.body;
		const script = document.createElement('script');
		script.innerHTML = '';
		script['id'] = "bolt";
		script.src = url;
		script['bolt-color'] = "#eee";
		script['bolt-logo'] = "https://bitloka.com/img/about/abt-btl.png";
		script.async = false;
		script.defer = true;
		body.appendChild(script);
		script.onload = function() {
			that.launchBolt();
		  };
	}
}