import { Injectable, Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'ttPoolFilter' })
@Injectable()
export class TTPoolFilterPipe implements PipeTransform {
	transform(items: any[], searchText: string): any[] {
		if (!searchText) return items;
		if (items) {
			return items.filter(item => Object.values(item.cell).join(' ').toLowerCase().indexOf(searchText.toLowerCase()) != -1);
		}
		else
			return [];
	}
}