import { Component, OnInit, TemplateRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import * as rootReducer from '../../rootReducer';
import { HomeService as service } from '../service/home-service';
import { CTApi } from '../../service/ct-api';
import { Router } from '@angular/router';
import * as appAction from '../../store/app-action';
import * as _ from 'underscore';
import { RecaptchaLoaderService } from 'ng-recaptcha';
import { environment } from '../../../environments/environment';

export class Lead {
    instName: string;
    instShortName: string;
    instWebsite: string;
    contactPerson: Person;
    constructor() {
        this.contactPerson = new Person();
    }
}

export class Person {
    name: string;
    mobile: string;
    email: string;
    title: string = "";
}

class Model {
    lead: Lead;
    mobilePattern: "^[6-9]\d{9}$";
    referral: string;
    emailCode: string;
    smsCode: string;
    leadGenerated: boolean = false;
    leadId: string;
    resendVerCode: boolean = false;
    verCodeResent: boolean = false;
    timer: number;
    activateResendLink: boolean = false;
    ctContext: any;
    userPassword: string;
    userLinks: any = {
        "Academics":
        {
            "col1": [
                {
                    "id": "a1",
                    "groupType": "Report",
                    "title": "Attendance / Calendar / Timetable",
                    "groupLinks": [
                        {
                            "title": "Absentees report",
                            "description": "view the report for absentees for a particular day",
                            "endPoint": "/academics/absenteesReport"
                        },
                        {
                            "title": "Holiday list",
                            "description": "view the holiday list for the academic year",
                            "endPoint": "/academics/holidayListReport"
                        },
                        {
                            "title": "Today's events",
                            "description": "view the events for today",
                            "endPoint": "/academics/todaysEventsReport"
                        },
                        {
                            "title": "Upcoming events",
                            "description": "view the upcoming events",
                            "endPoint": "/academics/upcomingEventsReport"
                        }
                    ]
                },
                {
                    "id": "a2",
                    "groupType": "Report",
                    "title": "Management Reports",
                    "groupLinks": [
                        {
                            "title": "Consolidated Marks",
                            "description": "view course / sectionwise consolidated report for final exam",
                            "endPoint": "/academics/consolidatedMarksReport"
                        },
                        {
                            "title": "Rank List",
                            "description": "view course-wise top 10 rank holders for final exam",
                            "endPoint": "/academics/rankListReport"
                        },
                        {
                            "title": "Result At A Glance",
                            "description": "view course-wise result at a glance for final exam",
                            "endPoint": "/academics/resultAtAGlanceManagementReport"
                        },
                        {
                            "title": "Top Scorers In Each Subject",
                            "description": "view course-wise top scorers in each subject",
                            "endPoint": "/academics/topScorersReport"
                        },
                        {
                            "title": "First Ten Toppers",
                            "description": "view course-wise first ten toppers",
                            "endPoint": "/academics/firstTenToppersReport"
                        },
                        {
                            "title": "A+ In All Subjects",
                            "description": "view course-wise students scoring A+ in all subjects throughout the year",
                            "endPoint": "/academics/aPlusInAllSubjectsReport"
                        }
                    ]
                }
            ],
            "col2": [
                {
                    "id": "b1",
                    "groupType": "Report",
                    "title": "Exams (Ensure exams are locked before running these reports)",
                    "groupLinks": [
                        {
                            "title": "Attendance report",
                            "description": "view the attendance for all the exams",
                            "endPoint": "/academics/examAttendanceReport"
                        },
                        {
                            "title": "Coursewise Result Analysis",
                            "description": "view examwise / coursewise Coursewise Result Analysis report",
                            "endPoint": "/academics/coursewiseResultAnalysisReport"
                        },
                        {
                            "title": "Consolidated Report",
                            "description": "view exam consolidated report",
                            "endPoint": "/academics/consolidatedReport"
                        },
                        {
                            "title": "Exams list",
                            "description": "view the exams list",
                            "endPoint": "/academics/examListReport"
                        },
                        {
                            "title": "Examwise Failures",
                            "description": "view examwise / coursewise failures report",
                            "endPoint": "/academics/examwiseFailuresReport"
                        },
                        {
                            "title": "Failures Report",
                            "description": "view the failures report",
                            "endPoint": "/academics/failuresReport"
                        },
                        {
                            "title": "Full Marks",
                            "description": "view examwise / coursewise report of students who have secured full marks in each subject",
                            "endPoint": "/academics/fullMarksReport"
                        },
                        {
                            "title": "Classwise / Gradewise Statistics",
                            "description": "view examwise / coursewise / gradewise statistics",
                            "endPoint": "/academics/gradewiseStatisticsReport"
                        },
                        {
                            "title": "Hall Ticket",
                            "description": "view students hall ticket for an exam",
                            "endPoint": "/academics/hallTicket"
                        },
                        {
                            "title": "No Due Certificate",
                            "description": "view student no due certificate",
                            "endPoint": "/academics/noDueCertificate"
                        },
                        {
                            "title": "Result Analysis",
                            "description": "view examwise / coursewise result analysis",
                            "endPoint": "/academics/resultAnalysisReport"
                        },
                        {
                            "title": "Result At A Glance",
                            "description": "view examwise / coursewise result at a glance",
                            "endPoint": "/academics/resultAtAGlanceReport"
                        },
                        {
                            "title": "Subject Average",
                            "description": "view examwise / coursewise subject average",
                            "endPoint": "/academics/subjectAverageReport"
                        },
                        {
                            "title": "Student performance",
                            "description": "view the report for student performance for all the exams",
                            "endPoint": "/academics/studentPerformanceReport"
                        }
                    ]
                }
            ],
            "col3": [
                {
                    "id": "c1",
                    "groupType": "ActionLink",
                    "title": "Action Links",
                    "subGroup": [
                        {
                            "title": "Attendance",
                            "apis": ["get/acad/attendance/absentees"],
                            "subgroupLinks": [
                                {
                                    "title": "Take Attendance",
                                    "description": "take attendance",
                                    "endPoint": "/academics/attendance",
                                    "Icon": "fa fa-plus",
                                    "api": "get/acad/attendance/absentees"
                                }
                            ]
                        },
                        {
                            "title": "Calendar",
                            "subgroupLinks": [
                                {
                                    "title": "Migration in Process",
                                    "description": "",
                                    "endPoint": "",
                                    "Icon": "fa fa-plus"
                                },
                                {
                                    "title": "Migration in Process",
                                    "description": "",
                                    "endPoint": "",
                                    "Icon": "fa fa-search"
                                }
                            ]
                        },
                        {
                            "title": "Exam",
                            "apis": ["get/acad/exam"],
                            "subgroupLinks": [
                                {
                                    "title": "Evaluation",
                                    "description": "Evaluation",
                                    "endPoint": "",
                                    "Icon": "fa fa-plus"
                                },
                                {
                                    "title": "Manage",
                                    "description": "Search / Edit",
                                    "endPoint": "/academics/exam",
                                    "Icon": "fa fa-search",
                                    "api": "get/acad/exam"
                                }
                            ]
                        },
                        {
                            "title": "Timetable",
                            "subgroupLinks": [
                                {
                                    "title": "Migration in Process",
                                    "description": "",
                                    "endPoint": "",
                                    "Icon": "fa fa-search"
                                }
                            ]
                        },
                        {
                            "title": "Timetable Planner",
                            "subgroupLinks": [
                                {
                                    "title": "Migration in Process",
                                    "description": "",
                                    "endPoint": "",
                                    "Icon": "fa fa-plus"
                                }
                            ]
                        },
                        {
                            "title": "SMS",
                            "subgroupLinks": [
                                {
                                    "title": "",
                                    "description": "",
                                    "endPoint": "",
                                    "Icon": ""
                                }
                            ]
                        }
                    ]
                }
            ]
        },

        "Admin":
        {
            "col1": [
                {
                    "id": "a1",
                    "groupType": "Report",
                    "title": "Course",
                    "groupLinks": [
                        {
                            "title": "Courses Offered",
                            "description": "view the course list for academic year",
                            "endPoint": "/admin/courseListReport"
                        }
                    ]
                },
                {
                    "id": "a2",
                    "groupType": "Report",
                    "title": "Institute",
                    "groupLinks": [
                        {
                            "title": "Calendar",
                            "description": "view calendar report for an institute",
                            "endPoint": "/admin/calendarReport"
                        },
                        {
                            "title": "Summary report",
                            "description": "view summary report for an institute",
                            "endPoint": "/admin/summaryReport"
                        },
                        {
                            "title": "Upcoming events",
                            "description": "view upcoming events for an institute",
                            "endPoint": "/admin/upcomingEventsAdminReport"
                        }
                    ]
                },
                {
                    "id": "a3",
                    "groupType": "Report",
                    "title": "Receipt",
                    "groupLinks": [
                        {
                            "title": "Consolidated Report",
                            "description": "Receipts consolidated report",
                            "endPoint": "/admin/receiptConsolidatedReport"
                        }
                    ]
                },
                {
                    "id": "a4",
                    "groupType": "Report",
                    "title": "Organization",
                    "groupLinks": []
                }

            ],
            "col2": [
                {
                    "id": "b1",
                    "groupType": "Report",
                    "title": "Security",
                    "groupLinks": [
                        {
                            "title": "User access report",
                            "description": "view the report for org user access",
                            "endPoint": "/admin/usersReport"
                        }
                    ]
                },
                {
                    "id": "b2",
                    "groupType": "Report",
                    "title": "HR",
                    "groupLinks": [
                        {
                            "title": "HR report (WIP)",
                            "description": "view list of all HR",
                            "endPoint": "/admin/hrReport"
                        },
                        {
                            "title": "Subjectwise breakup (WIP)",
                            "description": "view report for subject wise HR breakup",
                            "endPoint": "/admin/subjectwiseBreakupReport"
                        }
                    ]
                },
                {
                    "id": "b3",
                    "groupType": "Report",
                    "title": "Student",
                    "groupLinks": [
                        {
                            "title": "Students Coursewise Breakup",
                            "description": "view report for course wise students breakup",
                            "endPoint": "/admin/coursewiseBreakupReport"
                        },
                        {
                            "title": "Students Sectionwise Breakup",
                            "description": "view report for section wise student details breakup",
                            "endPoint": "/admin/sectionwiseBreakupReport"
                        },
                        {
                            "title": "Students Basic Sectionwise Breakup",
                            "description": "view report for section wise student details breakup",
                            "endPoint": "/admin/basicSectionwiseBreakupReport"
                        }
                    ]
                }
            ],
            "col3": [
                {
                    "id": "c1",
                    "groupType": "ActionLink",
                    "title": "Action Links",
                    "subGroup": [
                        {
                            "title": "Artefact",
                            "apis": ["get/arte/artefact", "post/arte/artefact/upsert", "post/acad/artefact/StudentAttendanceTemplate"],
                            "subgroupLinks": [
                                {
                                    "title": "Upload artefact",
                                    "description": "",
                                    "endPoint": "/artefact/add",
                                    "Icon": "fa fa-plus",
                                    "api": "post/arte/artefact/upsert"
                                },
                                {
                                    "title": "Search artefacts",
                                    "description": "",
                                    "endPoint": "/artefact/manage",
                                    "Icon": "fa fa-search",
                                    "api": "get/arte/artefact"
                                },
                                {
                                    "title": "Download published",
                                    "description": "",
                                    "endPoint": "/artefact/download",
                                    "Icon": "fa fa-download",
                                    "api": "post/acad/artefact/StudentAttendanceTemplate"
                                }
                            ]
                        },
                        {
                            "title": "Course",
                            "apis": ["post/acad/course", "get/acad/course"],
                            "subgroupLinks": [
                                {
                                    "title": "Add Course",
                                    "description": "",
                                    "endPoint": "/admin/course/add",
                                    "Icon": "fa fa-plus",
                                    "api": "post/acad/course"
                                },
                                {
                                    "title": "Search courses",
                                    "description": "",
                                    "endPoint": "/admin/course/manage",
                                    "Icon": "fa fa-search",
                                    "api": "get/acad/course"
                                }
                            ]
                        },
                        {
                            "title": "Data",
                            "apis": ["get/arte/artefact/dataTemplates"],
                            "subgroupLinks": [
                                {
                                    "title": "Data Templates",
                                    "description": "",
                                    "endPoint": "/admin/data/manage",
                                    "Icon": "fa fa-search",
                                    "api": "get/arte/artefact/dataTemplates"
                                }
                            ]
                        },
                        {
                            "title": "HR",
                            "apis": ["post/staff", "get/staff"],
                            "subgroupLinks": [
                                {
                                    "title": "Add HR",
                                    "description": "",
                                    "endPoint": "/admin/staff/add",
                                    "Icon": "fa fa-plus",
                                    "api": "post/staff"
                                },
                                {
                                    "title": "Search/Edit HR",
                                    "description": "",
                                    "endPoint": "/admin/staff/manage",
                                    "Icon": "fa fa-search",
                                    "api": "get/staff"
                                }
                            ]
                        },
                        {
                            "title": "Institute",
                            "apis": ["post/auth/institute", "get/auth/institute"],
                            "subgroupLinks": [
                                {
                                    "title": "Add Inst",
                                    "description": "",
                                    "endPoint": "/admin/inst/add",
                                    "Icon": "fa fa-plus",
                                    "api": "post/auth/institute"
                                },
                                {
                                    "title": "Search/Edit Insts",
                                    "description": "",
                                    "endPoint": "/admin/org/inst",
                                    "Icon": "fa fa-search",
                                    "api": "get/auth/institute"
                                }
                            ]
                        },
                        {
                            "title": "Org",
                            "apis": ["post/auth/org", "get/auth/org"],
                            "subgroupLinks": [
                                {
                                    "title": "Add Org",
                                    "description": "",
                                    "endPoint": "/admin/org/add",
                                    "Icon": "fa fa-plus",
                                    "api": "post/auth/org"
                                },
                                {
                                    "title": "Search/Edit Orgs",
                                    "description": "",
                                    "endPoint": "/admin/org",
                                    "Icon": "fa fa-search",
                                    "api": "get/auth/org"
                                }
                            ]
                        },
                        {
                            "title": "Student",
                            "apis": ["get/stu/student", "post/stu/student"],
                            "subgroupLinks": [
                                {
                                    "title": "Add Student",
                                    "description": "",
                                    "endPoint": "/student/add",
                                    "Icon": "fa fa-plus",
                                    "api": "post/stu/student"
                                },
                                {
                                    "title": "Search/Edit student",
                                    "description": "",
                                    "endPoint": "/student/manage",
                                    "Icon": "fa fa-search",
                                    "api": "get/stu/student"
                                }
                            ]
                        },
                        {
                            "title": "Reports",
                            "apis": ["get/arte/artefact/reports", "post/arte/artefact/upsert"],
                            "subgroupLinks": [
                                {
                                    "title": "Add Report",
                                    "description": "",
                                    "endPoint": "/report/add",
                                    "Icon": "fa fa-plus",
                                    "api": "post/arte/artefact/upsert"
                                },
                                {
                                    "title": "Search/Edit report",
                                    "description": "",
                                    "endPoint": "/report/manage",
                                    "Icon": "fa fa-search",
                                    "api": "get/arte/artefact/reports"
                                }
                            ]
                        }
                    ]
                }
            ]
        },

        "Assets": {
            "col1": [
                {
                    "id": "a1",
                    "groupType": "Report",
                    "title": "Assets",
                    "groupLinks":
                        [
                            {
                                "title": "Location wise assets report",
                                "description": "view location wise reports for assets",
                                "endPoint": "/locationwiseReport"
                            }
                        ]
                }
            ],
            "col2": [],
            "col3": [
                {
                    "id": "c1",
                    "groupType": "ActionLink",
                    "title": "Action Links",
                    "subGroup":
                        [
                            {
                                "title": "Asset",
                                "subgroupLinks":
                                    [
                                        {
                                            "title": "Migration in Process",
                                            "description": "",
                                            "endPoint": "",
                                            "Icon": "fa fa-plus"
                                        },
                                        {
                                            "title": "Migration in Process",
                                            "description": "",
                                            "endPoint": "",
                                            "Icon": "fa fa-search"
                                        }
                                    ]
                            },
                            {
                                "title": "Checklist",
                                "subgroupLinks": [
                                    {
                                        "title": "Add checklist",
                                        "description": "",
                                        "endPoint": "",
                                        "Icon": "fa fa-plus"
                                    },
                                    {
                                        "title": "Search/Edit checklist",
                                        "description": "",
                                        "endPoint": "",
                                        "Icon": "fa fa-search"
                                    }
                                ]
                            }
                        ]
                }
            ]
        },

        "Connect": {
            "col1": [
                {
                    "id": "a1",
                    "groupType": "Report",
                    "title": "Connect",
                    "groupLinks": [
                        {
                            "title": "SMS usage report",
                            "description": "view the report for SMS usage",
                            "endPoint": "/connect/smsUsageReport"
                        }
                    ]
                }
            ],
            "col2": [
                {
                    "id": "b1",
                    "groupType": "Report",
                    "title": "Exception Reports",
                    "groupLinks": [
                        {
                            "title": "Invalid / Blocked emails report",
                            "description": "view the report for invalid Email IDs",
                            "endPoint": "/connect/invalidEmailsReport"
                        },
                        {
                            "title": "Invalid / Blocked mobiles report",
                            "description": "view the report for invalid mobile numbers",
                            "endPoint": "/connect/invalidMobilesReport"
                        }
                    ]
                }],
            "col3": [
                {
                    "id": "c1",
                    "groupType": "ActionLink",
                    "title": "Action Links",
                    "api": ["post/connect/lookup"],
                    "subGroup": [
                        {
                            "title": "Contact",
                            "apis": "",
                            "subgroupLinks": [
                                {
                                    "title": "Lookup Contacts",
                                    "description": "view the contacts",
                                    "endPoint": "/connect/contactLookup",
                                    "Icon": "fa fa-search",
                                    "api": "post/connect/lookup"
                                }
                            ]
                        },
                        {
                            "title": "Email",
                            "apis": ["post/connect/email", "get/connect/email"],
                            "subgroupLinks": [
                                {
                                    "title": "Send new Email",
                                    "description": "send new email",
                                    "endPoint": "/connect/newEmail",
                                    "Icon": "fa fa-plus",
                                    "api": "post/connect/email"
                                },
                                {
                                    "title": "Search/ View sent Emails",
                                    "description": "search/ view sent emails",
                                    "endPoint": "/connect/manageEmail",
                                    "Icon": "fa fa-search",
                                    "api": "get/connect/email"
                                }
                            ]
                        },
                        {
                            "title": "SMS",
                            "apis": ["post/connect/sms", "get/connect/sms"],
                            "subgroupLinks": [
                                {
                                    "title": "Send new SMS",
                                    "description": "send new SMS",
                                    "endPoint": "/connect/newSMS",
                                    "Icon": "fa fa-plus",
                                    "api": "post/connect/sms"
                                },
                                {
                                    "title": "Search/ View sent SMS",
                                    "description": "search/ view sent SMS",
                                    "endPoint": "/connect/manageSMS",
                                    "Icon": "fa fa-search",
                                    "api": "get/connect/sms"
                                },
                                {
                                    "title": "Manage SMS Templates",
                                    "description": "Manage SMS Templates",
                                    "endPoint": "/connect/manageSMSTemplate",
                                    "Icon": "fa fa-edit",
                                    "Style": "text-align: center;",
                                    "api": "get/connect/SMSTemplate"
                                }
                            ]
                        },
                        {
                            "title": "Website",
                            "apis": "",
                            "subgroupLinks": [
                                {
                                    "title": "Search/View TFTD",
                                    "description": "search/view TFTD",
                                    "endPoint": "/connect/website",
                                    "Icon": "fa fa-search",
                                    "api": "get/connect/website"
                                }
                            ]
                        }]
                }]
        },
        "Fees": {
            "col1": [
                {
                    "id": "a1",
                    "groupType": "Report",
                    "title": "Fees Setup",
                    "groupLinks": [
                        // {
                        // 	"title": "Concession (WIP)",
                        // 	"description": "view the Concession for the academic year",
                        // 	"endPoint": "/fees/feesConcessionReport"
                        // },
                        // {
                        // 	"title": "Fee Breakups",
                        // 	"description": "view the Fee Breakups for the academic year",
                        // 	"endPoint": "/fees/feesBreakupsReport"
                        // },
                        {
                            "title": "Fees Structure",
                            "description": "view the fee structure for the academic year",
                            "endPoint": "/fees/feesStructureReport"
                        },
                        {
                            "title": "Fees Expected",
                            "description": "view the fees expected for the academic year",
                            "endPoint": "/fees/feesExpectedReport"
                        }
                        // {
                        // 	"title": "Fees Time Line",
                        // 	"description": "view the Fees Time Line for the academic year",
                        // 	"endPoint": "/fees/feesTimeLineReport"
                        // }
                    ]
                },
                {
                    "id": "a2",
                    "groupType": "Report",
                    "title": "Fees Exception Reports",
                    "groupLinks": [
                        {
                            "title": "Defaulters List",
                            "description": "view the defaulters list for the academic year",
                            "endPoint": "/fees/feesDefaultersListReport"
                        }
                    ]
                }
            ],
            "col2": [{
                "id": "b1",
                "groupType": "Report",
                "title": "Fees Transaction",
                "groupLinks": [
                    {
                        "title": "Adjustment Balance",
                        "description": "view the adjustment balance",
                        "endPoint": "/fees/adjustmentBalanceReport"
                    },
                    {
                        "title": "Adjustment Transaction",
                        "description": "view the adjustment transactions",
                        "endPoint": "/fees/adjustmentTransactionReport"
                    },
                    {
                        "title": "Fee Collection Datewise / Monthwise",
                        "description": "view the datewise / monthwise fee collection",
                        "endPoint": "/fees/feesCollectedReport"
                    },
                    {
                        "title": "Fees Paid List",
                        "description": "view the fees paid by students for the academic year",
                        "endPoint": "/fees/feesPaidReport"
                    },
                    // {
                    // 	"title": "Fees Expected",
                    // 	"description": "view the fees expected for the academic year",
                    // 	"endPoint": "/fees/feesExpectedReport"
                    // },
                    // {
                    // 	"title": "Fine Collected (WIP)",
                    // 	"description": "view the fine collected for the academic year",
                    // 	"endPoint": "/fees/feesFineCollectedReport"
                    // },
                    // {
                    // 	"title": "Late Payments (WIP)",
                    // 	"description": "view the late payments for the academic year",
                    // 	"endPoint": "/fees/feesLatePaymentsReport"
                    // },
                    {
                        "title": "Receipt Transaction",
                        "description": "view the datewise receipt transaction",
                        "endPoint": "/fees/feesReceiptTransactionReport"
                    },
                    {
                        "title": "Student Transaction",
                        "description": "view the transactions for a student",
                        "endPoint": "/fees/studentTransactionReport"
                    },
                    {
                        "title": "Transaction History",
                        "description": "view fees transaction history of the institute",
                        "endPoint": "/fees/transactionHistoryReport"
                    }
                ]
            }],
            "col3": [
                {
                    "id": "c1",
                    "groupType": "ActionLink",
                    "title": "Action Links",
                    "subGroup": [
                        {
                            "title": "Fees",
                            "apis": ["post/fee", "get/fee"],
                            "subgroupLinks": [
                                {
                                    "title": "Add fee",
                                    "description": "",
                                    "endPoint": "/fees/add",
                                    "Icon": "fa fa-plus",
                                    "api": "post/fee"
                                },
                                {
                                    "title": "Search/Edit Fee",
                                    "description": "",
                                    "endPoint": "/fees/manage",
                                    "Icon": "fa fa-search",
                                    "api": "get/fee"
                                }
                            ]
                        }, {
                            "title": "Transact",
                            "apis": ["post/fee/transact", "get/fee/transact"],
                            "subgroupLinks": [
                                {
                                    "title": "New transaction",
                                    "description": "",
                                    "endPoint": "/fees/transactions/add",
                                    "Icon": "fa fa-plus",
                                    "api": "post/fee/transact"
                                },
                                {
                                    "title": "Search/View transaction",
                                    "description": "",
                                    "endPoint": "/fees/transactions",
                                    "Icon": "fa fa-search",
                                    "api": "get/fee/transact"
                                }
                            ]
                        }]
                }, {
                    "id": "c2",
                    "groupType": "ActionLink",
                    "title": "Notify",
                    "subGroup": [
                        {
                            "title": "Defaulters List",
                            "apis": [],
                            "subgroupLinks": [
                                {
                                    "title": "Migration in Process",
                                    "description": "",
                                    "endPoint": "",
                                    "Icon": "fa fa-mobile fa-lg",
                                    "api": ""
                                },
                                {
                                    "title": "Migration in Process",
                                    "description": "",
                                    "endPoint": "",
                                    "Icon": "fa fa-envelope-o fa-lg",
                                    "api": ""
                                }, {
                                    "title": "Migration in Process",
                                    "description": "",
                                    "endPoint": "",
                                    "Icon": "fa fa-bell-o fa-lg",
                                    "api": ""
                                }
                            ]
                        }]
                }]
        },

        "Library": {
            "col1": [
                {
                    "id": "a1",
                    "groupType": "Report",
                    "title": "Procurement (WIP)",
                    "groupLinks": [
                        {
                            "title": "Vendor management ",
                            "description": "manage vendors",
                            "endPoint": "/library/vendorManagement",
                            "WIP": true
                        },
                        {
                            "title": "Subscription management",
                            "description": "manage subscriptions",
                            "endPoint": "/library/subscriptionManagement",
                            "WIP": true
                        }
                    ]
                },
                {
                    "id": "a2",
                    "groupType": "Report",
                    "title": "Catalog",
                    "groupLinks": [
                        {
                            "title": "Print barcodes",
                            "description": "print barcodes for materials",
                            "endPoint": "/library/barcode",
                            "WIP": false
                        },
                        {
                            "title": "Summary report",
                            "description": "view the summary report for library",
                            "endPoint": "/library/summaryReport",
                            "WIP": false
                        },
                        {
                            "title": "Members report",
                            "description": "view the report of library members",
                            "endPoint": "/library/membersReport",
                            "WIP": true
                        },
                        {
                            "title": "Reference materials report",
                            "description": "view the report for reference materials",
                            "endPoint": "/library/referenceMaterialsReport",
                            "WIP": true
                        }
                    ]
                },
                {
                    "id": "a3",
                    "groupType": "Report",
                    "title": "House-keeping",
                    "groupLinks": [
                        {
                            "title": "Materials under maintenance report",
                            "description": "view the report for materials under maintenance",
                            "endPoint": "/library/maintenanceReport",
                            "WIP": false
                        },
                        {
                            "title": "Materials due for maintenance",
                            "description": "view the report for materials which are due for maintenance",
                            "endPoint": "/library/maintenanceDueReport",
                            "WIP": true
                        },
                        {
                            "title": "Last monitored report",
                            "description": "view the last monitored report for materials",
                            "endPoint": "/library/lastMonitoredReport",
                            "WIP": true
                        },
                        {
                            "title": "Lost materials report",
                            "description": "view the report for materials which are lost",
                            "endPoint": "/library/lostMaterialsReport",
                            "WIP": true
                        }
                    ]
                }

            ],
            "col2": [
                {
                    "id": "b1",
                    "groupType": "Report",
                    "title": "Circulation",
                    "groupLinks": [
                        {
                            "title": "Materials under circulation report",
                            "description": "view the report for materials in circulation",
                            "endPoint": "/library/circulationReport",
                            "WIP": false
                        },
                        {
                            "title": "Over-due report",
                            "description": "view the report for materials in circulation which are over-due",
                            "endPoint": "/library/overDueReport",
                            "WIP": true
                        }
                    ]
                },
                {
                    "id": "b2",
                    "groupType": "Report",
                    "title": "Reports",
                    "groupLinks": [
                        {
                            "title": "Age analysis",
                            "description": "view the report for age of the materials",
                            "endPoint": "/library/ageAnalysisReport",
                            "WIP": true
                        },
                        {
                            "title": "Valuation report",
                            "description": "view the report for materials Valuation",
                            "endPoint": "/library/valuationReport",
                            "WIP": true
                        },
                        {
                            "title": "Late fees report",
                            "description": "view the report for late payment of library fees",
                            "endPoint": "/library/lateFeesReport",
                            "WIP": true
                        },
                        {
                            "title": "Books without ISBN",
                            "description": "view the report for books without ISBN",
                            "endPoint": "/library/booksWithoutISBNReport",
                            "WIP": false
                        },
                        {
                            "title": "Books without synopsis",
                            "description": "view the report for books without synopsis",
                            "endPoint": "/library/booksWithoutSynopsisReport",
                            "WIP": true
                        },
                        {
                            "title": "Books without cover photo",
                            "description": "view the report for books without cover photo",
                            "endPoint": "/library/booksWithoutCoverPhotoReport",
                            "WIP": true
                        },
                        {
                            "title": "Unverified stock",
                            "description": "view the report of unverified materials",
                            "endPoint": "/library/unverifiedStockReport",
                            "WIP": true
                        }
                    ]
                }
            ],
            "col3": [
                {
                    "id": "c1",
                    "groupType": "ActionLink",
                    "title": "Action Links",
                    "subGroup": [
                        {
                            "title": "Classification",
                            "apis": [],
                            "subgroupLinks": [
                                {
                                    "title": "Migration in Process",
                                    "description": "",
                                    "endPoint": "",
                                    "Icon": "fa fa-barcode fa-lg",
                                    "api": ""
                                }
                            ]
                        },
                        {
                            "title": "Catalog",
                            "apis": ["post/library/material/add", "get/library/material"],
                            "subgroupLinks": [
                                {
                                    "title": "Add library material",
                                    "description": "",
                                    "endPoint": "/library/add",
                                    "Icon": "fa fa-plus",
                                    "api": "post/library/material/add"
                                },
                                {
                                    "title": "Search/Edit library material",
                                    "description": "",
                                    "endPoint": "/library/manage",
                                    "Icon": "fa fa-search",
                                    "api": "get/library/material"
                                }
                            ]
                        },
                        {
                            "title": "Circulation",
                            "apis": ["post/library/material/issue", "post/library/material/return"],
                            "subgroupLinks": [
                                {
                                    "title": "Issue/Return",
                                    "description": "",
                                    "endPoint": "/library/circulation",
                                    "Icon": "fa fa-retweet fa-lg",
                                    "api": "post/library/material/issue"
                                },
                                {
                                    "title": "SettMigration in Processings",
                                    "description": "",
                                    "endPoint": "",
                                    "Icon": "fa fa-cog fa-fw fa-lg",
                                    "api": ""
                                }
                            ]
                        },
                        {
                            "title": "House-keeping",
                            "apis": [],
                            "subgroupLinks": [
                                {
                                    "title": "Migration in Process",
                                    "description": "",
                                    "endPoint": "",
                                    "Icon": "fa fa-wrench fa-lg",
                                    "api": ""
                                },
                                {
                                    "title": "Migration in Process",
                                    "description": "",
                                    "endPoint": "",
                                    "Icon": "fa fa-trash fa-lg",
                                    "api": ""
                                }
                            ]
                        },
                        {
                            "title": "Stock Verification",
                            "apis": ["post/library/material/verifyStock"],
                            "subgroupLinks": [
                                {
                                    "title": "Verify Stock",
                                    "description": "",
                                    "endPoint": "/library/stockVerification",
                                    "Icon": "fa fa-search",
                                    "api": "post/library/material/verifyStock"
                                }
                            ]
                        }
                    ]
                },
                {
                    "groupType": "ActionLink",
                    "title": "Notify",
                    "groupLinks": [],
                    "subGroup": [
                        {
                            "title": "Defaulters",
                            "apis": [],
                            "subgroupLinks": [
                                {
                                    "title": "Migration in Process",
                                    "description": "",
                                    "endPoint": "",
                                    "Icon": "fa fa-mobile fa-lg",
                                    "api": ""
                                },
                                {
                                    "title": "Migration in Process",
                                    "description": "",
                                    "endPoint": "",
                                    "Icon": "fa fa-envelope-o fa-lg",
                                    "api": ""
                                },
                                {
                                    "title": "Migration in Process",
                                    "description": "",
                                    "endPoint": "",
                                    "Icon": "fa fa-bell-o fa-lg",
                                    "api": ""
                                }
                            ]
                        },
                        {
                            "title": "Reminders",
                            "apis": [],
                            "subgroupLinks": [
                                {
                                    "title": "Migration in Process",
                                    "description": "",
                                    "endPoint": "",
                                    "Icon": "fa fa-mobile fa-lg",
                                    "api": ""
                                },
                                {
                                    "title": "Migration in Process",
                                    "description": "",
                                    "endPoint": "",
                                    "Icon": "fa fa-envelope-o fa-lg",
                                    "api": ""
                                },
                                {
                                    "title": "Migration in Process",
                                    "description": "",
                                    "endPoint": "",
                                    "Icon": "fa fa-bell-o fa-lg",
                                    "api": ""
                                }
                            ]
                        },
                        {
                            "title": "Over Due",
                            "apis": [],
                            "subgroupLinks": [
                                {
                                    "title": "Migration in Process",
                                    "description": "",
                                    "endPoint": "",
                                    "Icon": "fa fa-mobile fa-lg",
                                    "api": ""
                                },
                                {
                                    "title": "Migration in Process",
                                    "description": "",
                                    "endPoint": "",
                                    "Icon": "fa fa-envelope-o fa-lg",
                                    "api": ""
                                },
                                {
                                    "title": "Migration in Process",
                                    "description": "",
                                    "endPoint": "",
                                    "Icon": "fa fa-bell-o fa-lg",
                                    "api": ""
                                }
                            ]
                        }
                    ]
                }
            ]
        },
        "RC": {
            "col1": [
                {
                    "id": "a1",
                    "groupType": "Report",
                    "title": "Reports",
                    "groupLinks": [
                        {
                            "title": "Admission List (WIP)",
                            "description": "view report for admission list for academic year",
                            "endPoint": "/regulatoryCompliance/admissionReport"
                        },
                        {
                            "title": "Department report",
                            "description": "view report for total marks and grade secured by each student for academic year",
                            "endPoint": "/regulatoryCompliance/departmentReport"
                        }
                    ]
                }
            ],
            "col2": [],
            "col3": [
                {
                    "id": "c1",
                    "groupType": "ActionLink",
                    "title": "Action Links",
                    "subGroup": [
                        {
                            "title": "TC Book",
                            "subgroupLinks": [
                                {
                                    "title": "Migration in Process",
                                    "description": "",
                                    "endPoint": "",
                                    "Icon": "fa fa-search"
                                }
                            ]
                        },
                        {
                            "title": "Fee Receipts",
                            "subgroupLinks": [
                                {
                                    "title": "Migration in Process",
                                    "description": "",
                                    "endPoint": "",
                                    "Icon": "fa fa-search"
                                }
                            ]
                        }
                    ]
                }]
        },
        "Receipts": {
            "col1": [
                {
                    "id": "a1",
                    "groupType": "Report",
                    "title": "Receipts",
                    "groupLinks": [
                        {
                            "title": "Detailed report",
                            "description": "view the details report of receipts within the academic year",
                            "endPoint": "/receipts/detailedReport"
                        },
                        {
                            "title": "Revenue sharing report",
                            "description": "view the report for revenue sharing within the academic year",
                            "endPoint": "/receipts/revenueSharingReport"
                        },
                        {
                            "title": "Summary report",
                            "description": "view the summary report for receipts within the academic year",
                            "endPoint": "/receipts/receiptsSummaryReport"
                        }
                    ]
                }
            ],
            "col2": [],
            "col3": [
                {
                    "id": "c1",
                    "groupType": "ActionLink",
                    "title": "Action Links",
                    "subGroup": [
                        {
                            "title": "Receipts",
                            "subgroupLinks": [
                                {
                                    "title": "Add / Create Receipt",
                                    "description": "Add / Create Receipt",
                                    "endPoint": "/receipts/add",
                                    "Icon": "fa fa-plus"
                                },
                                {
                                    "title": "Search / Edit Receipts",
                                    "description": "Search / Edit Receipts",
                                    "endPoint": "/receipts/manage",
                                    "Icon": "fa fa-search"
                                }
                            ]
                        }
                    ]
                }]
        }
    }
    access_token: any;
    ct_context_json: any;
    captchaVerified: boolean = false;
    captchaResponse: string;
    disableStep1: boolean = false;
    email: string;
    password: string = "";
    newUser: boolean = true;
    errorMessage: string;

    constructor() {
        this.lead = new Lead();
    }
}

@Component({
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
    providers: [service, RecaptchaLoaderService],
})
export class TrialComponent implements OnInit {

    public resolved(captchaResponse: string) {
        if (captchaResponse == null) this.m.captchaVerified = false;
        else {
            this.m.captchaVerified = true;
            this.m.captchaResponse = captchaResponse;
        }
    }
    public m: Model;
    modalRef: BsModalRef;
    private loader: RecaptchaLoaderService
    constructor(private modalService: BsModalService, public service: service, private store: Store<rootReducer.State>, public ctapi: CTApi, private router: Router) { }
    ngOnInit() {
        this.init_model();
        this.init_store();
        this.sub_store();
    }
    ngOnDestroy() {
    }
    init_model() {
        this.m = new Model();
    }
    init_store() {
    }
    sub_store() {
    }
    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template);
    }

    ut_submitLeadDetails(step1template: TemplateRef<any>, shortNameExistsTemplate: TemplateRef<any>) {
        this.m.lead.instShortName = this.m.lead.instShortName.toUpperCase();
        this.service.getLeadToken(this.m.lead, this.m.referral, this.m.captchaResponse).subscribe((res: any) => {
            if (res) {
                let token = res.body;
                token = token.replace(/^"(.*)"$/, '$1');
                token = 'Bearer' + ' ' + token;
                this.store.dispatch(new appAction.SetAccessToken({ "accessToken": token }));
                this.service.createLead(this.m.lead, this.m.referral, this.m.captchaResponse).subscribe(res => {
                    if (res) {
                        this.m.leadId = res['data'];
                        this.openModal(step1template);
                        this.m.leadGenerated = true;
                        this.m.disableStep1 = true;
                        this.service.getUserByEmail(this.m.lead.contactPerson.email).subscribe(res => {
                            if (res['data'] != null) this.m.newUser = false;
                        });
                    }
                },
                    error => {
                        if (error.status == 400) {
                            this.m.errorMessage = error.error;
                            this.m.lead.instShortName = "";
                            grecaptcha.reset();
                            this.m.captchaVerified = false;
                            this.openModal(shortNameExistsTemplate);
                        }
                        else {
                            grecaptcha.reset();
                            this.m.captchaVerified = false;
                        }
                    });
            }
        });
    }

    ut_setTimer() {
        var duration = 60 * 2;
        setTimeout(() => {
            this.startTimer(duration);
        }, 500);
    }

    startTimer(duration) {
        var display = document.getElementById("timer");
        var timer = duration;
        var minutes;
        var seconds;
        var self = this;
        setInterval(function () {
            minutes = parseInt((timer / 60).toString(), 10)
            seconds = parseInt((timer % 60).toString(), 10);
            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;
            display.textContent = minutes + ":" + seconds;
            if (--timer < 0) {
                timer = 0;
                self.m.activateResendLink = true;
            }
        }, 1000);
    }

    ut_completeVerification(step2template: TemplateRef<any>) {
        let date = new Date();
        let acadYearFrom = date.getFullYear() + '-' + '06' + '-' + '01';
        let acadYearTo = (date.getFullYear() + 1) + '-' + '05' + '-' + '31';
        let ownerId = `${environment.BITLOK_ORG_ID}`;
        this.service.verify(this.m.emailCode, this.m.smsCode, this.m.referral, this.m.leadId, this.m.lead.instName, this.m.lead.instShortName, this.m.password, acadYearFrom, acadYearTo, ownerId).subscribe((res: any) => {
            if (res) {
                let created_inst_persona_id = res['data'];
                this.openModal(step2template);
                this.router.navigate(['/home']);
            }
        });
    }

    getNavPerms(routes) {
        let sidebar = [];
        routes.forEach(v => {
            var routeArr = v.split('/');
            var so = _.find(sidebar, function (x) { return x.Route == routeArr[0] });
            if (so && routeArr[1]) {
                let sObj = { Route: "", DisplayText: "" };
                sObj.Route = v;
                sObj.DisplayText = this.getPascalCase(routeArr[1]);
                so.Sidebar.push(sObj);
            }
            else if (!so) {
                let spObj = { Route: "", DisplayText: "", Sidebar: [] };
                spObj.Route = routeArr[0];
                spObj.DisplayText = this.getPascalCase(routeArr[0]);
                let scObj = { Route: "", DisplayText: "" };
                scObj.Route = routeArr[0];;
                scObj.DisplayText = "Dashboard";
                spObj.Sidebar.push(scObj);
                if (routeArr[1]) {
                    let scObj = { Route: "", DisplayText: "" };
                    scObj.Route = v;
                    scObj.DisplayText = this.getPascalCase(routeArr[1]);
                    spObj.Sidebar.push(scObj);
                }
                sidebar.push(spObj);
            }
        });
        return sidebar;
    }

    getPascalCase(str) {
        let result = str.replace(/([A-Z])/g, " $1");
        let pascalStr = result.charAt(0).toUpperCase() + result.slice(1);
        return pascalStr;
    }
    ut_resendVerCode(resendTemplate: TemplateRef<any>) {
        this.service.resendVerCode(this.m.leadId).subscribe((res: any) => {
            if (res.status == 200) {
                this.m.verCodeResent = true;
                this.m.activateResendLink = false;
                this.openModal(resendTemplate);
            }
        });
    }
    _keyPress(event: any) {
        const pattern = /[0-9]/;
        let inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    }
}