import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { CTApi } from '../../service/ct-api';

import { Store } from '@ngrx/store';
import * as rootReducer from '../../rootReducer';

import { UserUpgradeService as service } from '../service/service';
import { BsModalRef, BsModalService } from "ngx-bootstrap";

class Model {
    userEmail: string;
    userId: string;
    userName: string;
    staffAgree: boolean = true;
    staffOtp: string;
    coursesSubjects: string;
    employedIn: string;
    emplInstAddr: string;
    stfMobile: string;
    selectedClientId: string;
    personas: any[] = [];
    instWebsite: string;
    smsCode: string;
}

@Component({
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
    providers: [service]
})

export class UserUpgradeTeacherComponent implements OnInit {
    @ViewChild("notification") private contentRef: TemplateRef<any>;
    public m: Model;
    modalRef: BsModalRef;
    constructor(
        public ctapi: CTApi,
        private store: Store<rootReducer.State>,
        private service: service,
        private modalService: BsModalService) { }

    ngOnInit() {
        this.init_model();
        this.init_store();
        this.sub_store();
    }

    init_model() {
        this.m = new Model();
    }

    init_store() {

    }

    sub_store() {
        this.store.select(rootReducer.get_state_app).subscribe((res: any) => {
            if (res) {
                this.m.userEmail = res.userEmail;
                this.m.userId = res.userId;
                let name = [];
                name.push(res.userFirstName);
                if (res.userMiddleName != null || res.userMiddleName != '') name.push(res.userMiddleName);
                name.push(res.userLastName);
                this.m.userName = name.join(' ');

                this.m.selectedClientId = res.currentPersona.clientId;
                if (this.m.selectedClientId != '') this.service.getInstPersonas('', this.m.selectedClientId);
            }
        });

        this.store.select(rootReducer.get_client_personas).subscribe(
            res => {
                if (res) {
                    this.m.personas = res;
                }
            });
    }

    ut_upgradeUser() {
        let verify_code_payload = { "Id": this.m.userId, "SMSCode": this.m.smsCode };
        this.service.verifySMSCode(verify_code_payload).subscribe(res => {
            if (res) {
                let persona_id = "";
                persona_id = this.m.personas.find(o => o.title == 'Teacher').id;
                let payload = { "Id": this.m.userId, "PersonaId": persona_id, "SMSCode": this.m.smsCode };
                this.service.upgradeUser(payload).subscribe(res => {
                    if (res) {
                        this.openNotificationModal();
                    }
                });
            }
            err => {
                
            }
        });
    }

    closeModal() {
        this.modalRef.hide();
    }

    openNotificationModal() {
        this.modalRef = this.modalService.show(this.contentRef);
    }

    ut_update() {
        let details = { "Id": this.m.userId, "Mobile": this.m.stfMobile, "InstituteName": this.m.employedIn, "InstituteAddress": this.m.emplInstAddr, "InstituteWebsite": this.m.instWebsite, "CoursesSubjectsHandled": this.m.coursesSubjects, "StudyingIn": "" };
        this.service.updateUserDetails(details).subscribe();

        let payload = { "Id": this.m.userId, "Mobile": this.m.stfMobile };
        this.service.sendVerificationCode(payload).subscribe();
    }
}