import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CTApi } from '../../service/ct-api';
import { Store } from '@ngrx/store';
import * as rootReducer from '../../../app/rootReducer';

@Injectable()
export class DeleteAccountService {
    constructor(private httpClient: HttpClient,public ctapi: CTApi, private store: Store<rootReducer.State>) { }

sendOTP(email: string) {
    return this.ctapi.sendEmailOTP(email);
}

verifyOTP(email: string, otp: string) {
    return this.ctapi.verifyEmailOTP(email, otp);
}

deleteAccount(email: string) {
    return this.ctapi.deleteAccount(email);
}
}