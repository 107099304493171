import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import * as rootReducer from '../../rootReducer';
import { HomeService as service } from '../service/home-service'

class model {
    acadYearFrom: Date;
    acadYearTo: Date;
    isAcadFiscalYear: boolean = false;
    courseComponent: any = { data: { count: 0 }, title: "course" };
    acadYearComponent: any = { data: { from: "2018-05-01", to: "2019-04-30" }, title: "acad_year" };
    ctContext: any;
}

@Component({
    selector: 'course',
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
    providers: [service]
})
export class HomeCourseComponent implements OnInit {
    public m: model;
    constructor(private service: service, private store: Store<rootReducer.State>) { }
    ngOnInit() {
        this.init_model();
        this.sub_store();
    }
    init_model() {
        this.m = new model();
    }
    sub_store() {
        this.store.select(rootReducer.get_state_app).subscribe((res: any) => {
            this.m.ctContext = res;
        });

        this.store.select(rootReducer.get_user_updated_data).subscribe((res: any) => {
            // this.m.acadYearComponent = res.components.find(function (element) {
            //     return element.title == "acad_year";
            // });
            this.m.acadYearFrom = this.m.acadYearComponent.data.from;
            this.m.acadYearTo = this.m.acadYearComponent.data.to;
            this.m.acadYearFrom = new Date(this.m.acadYearFrom);
            this.m.acadYearTo = new Date(this.m.acadYearTo);
            if (this.m.acadYearFrom.getMonth() == 4 && this.m.acadYearFrom.getDate() == 1 && this.m.acadYearTo.getMonth() == 3 && this.m.acadYearTo.getDate() == 30) {
                this.m.isAcadFiscalYear = true;
            }
        });
    }
}