import * as app from './action';

export class Kv {
    constructor(public key: string, public value: string) {
    }
}

export class Report {
    id: string;
    metaData: any;
    get metaKv(): Kv[] {
        var result = [];
        if (this.metaData) {
            for (var key in this.metaData) {
                if (this.metaData.hasOwnProperty(key)) { result.push(new Kv(key, this.metaData[key])); }
            }
        }
        return result;
    }
    fileName: string;
    url: string;
    fileSize: number;
    createdOn: Date;
    modifiedOn: Date;
    status: number;
    title: string;
    description: string;
    model: string;
}

export class ReportContext {
    constructor(public selectedReportId: string, public selectedReportTitle: string) {
    }
}

export interface State {
    reports: Report[];
    report_context: ReportContext;
    selected_report: Report;
    template: string;
}

const initialState: State = {
    reports: [],
    report_context: new ReportContext("", ""),
    selected_report: new Report(),
    template: null
};

function disableReport(report_list, report_id): Report[] {
    const reportList: Report[] = report_list.map((item) => {
        if (item.id == report_id) {
            item.status = 99;
            return item;
        }
        return item;
    });
    return reportList;
}

function enableReport(report_list, report_id): Report[] {
    const reportList: Report[] = report_list.map((item) => {
        if (item.id == report_id) {
            item.status = 1;
            return item;
        }
        return item;
    });
    return reportList;
}

function selectReport(report_list, report_id): Report {
    const report: Report = report_list.find(o => o.id == report_id);
    return report;
}
function getReportArray(dataFromServer) {
    var result = [];
    if (dataFromServer) {
        dataFromServer.forEach(d => {
            let report = new Report();
            report.id = d.id;
            report.metaData = d.metaData;
            report.fileName = d.fileName;
            report.url = d.url;
            report.fileSize = d.fileSize;
            report.createdOn = d.createdOn;
            report.modifiedOn = d.modifiedOn;
            report.status = d.status;
            report.title = d.title;
            report.description = d.description;
            report.model = d.model;
            result.push(report);
        });
    }
    return result;
}

export function reducer(state = initialState, action: app.Actions): State {
    switch (action.type) {
        case app.ActionTypes.SET_REPORT:
            return Object.assign({}, state, { reports: getReportArray(action.payload.report) });
        case app.ActionTypes.SET_TEMPLATE:
            return Object.assign({}, state, { template: action.payload.template });
        case app.ActionTypes.SET_VIEW:
            return Object.assign({}, state, { reports: action.payload.view });
        case app.ActionTypes.SET_SELECTED_REPORT:
            return Object.assign({}, state, { selected_report: selectReport(state.reports, action.payload.report_id) });
        case app.ActionTypes.DISABLE_REPORT:
            return Object.assign({}, state, { reports: disableReport(state.reports, action.payload.report_id) });
        case app.ActionTypes.ENABLE_REPORT:
            return Object.assign({}, state, { reports: enableReport(state.reports, action.payload.report_id) });
        case app.ActionTypes.SET_REPORT_CONTEXT:
            return Object.assign({}, state, { report_context: action.payload });

        default:
            return state;
    }
}
export const get_reports = (state: State) => state.reports;
export const get_report_context = (state: State) => state.report_context;
export const get_selected_report = (state: State) => state.selected_report;
export const get_view = (state: State) => state.reports;
export const get_template = (state: State) => state.template;
