import { Component, Input, TemplateRef } from '@angular/core';
import { Store } from '@ngrx/store';

import * as rootReducer from '../../rootReducer';
import { ConnectSetupService as service } from './service/service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';

class ConnectData {
    email_enabled: boolean;
    email_via: string;
    notification_enabled: boolean;
    sms_enabled: boolean
    sms_sender_id: string;
}

class Model {
    connectData: ConnectData;
    userEmail: string;
}
@Component({
    selector: 'connect-setup',
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
    providers: [service]
})

export class ConnectSetupComponent {
    public m: Model;
    public modalRef: BsModalRef;
    @Input() pin: boolean;
    constructor(private modalService: BsModalService, private service: service, private store: Store<rootReducer.State>) { }
    ngOnInit() {
        this.init_model();
        this.init_store();
    }
    init_model() {
        this.m = new Model();
    }

    init_store() {
        this.store.select(rootReducer.get_state_app).subscribe(context => {
            if (context)
                this.m.userEmail = context.userEmail;
        });

        this.store.select(rootReducer.get_connect_setup_widget).subscribe((res: any) => {
            if (res) {
                this.m.connectData = res.connect;
            }
        });
    }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template);
    }

    ut_customDomainRequest(ticketToSetupCustomDomain: TemplateRef<any>) {
        this.openModal(ticketToSetupCustomDomain);
    }
    ut_enableSMS(ticketToEnableSMS: TemplateRef<any>) {
        this.openModal(ticketToEnableSMS);
    }
    ut_notificationRequest(ticketToEnableNotification: TemplateRef<any>) {
        this.openModal(ticketToEnableNotification);
    }
    ut_smsCreditsAdd(ticketToAddSMSCredits: TemplateRef<any>) {
        this.openModal(ticketToAddSMSCredits);
    }

    pinChart() {
        this.pin = false;
        let payload = ["connect_setup"];
        this.service.pinWidget(payload).subscribe(res => {
            if (res) this.service.getDashboardWidgets();
        });

    }
    unpinChart() {
        this.pin = true;
        let payload = ["connect_setup"];
        this.service.unpinWidget(payload).subscribe(res => {
            if (res) this.service.getDashboardWidgets();
        });
    }
}