import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

class Model {
    line1: string = "";
    line2: string = "";
    city: string = "";
    state: string = "";
    country: string = "";
    pincode: string = "";
}

@Component({
    selector: 'address',
    templateUrl: './component.html',
    styleUrls: ['./component.css']
})

export class AddressComponent implements OnInit {
    public m: Model;
    @Input() line1: string;
    @Input() line2: string;
    @Input() city: string;
    @Input() state: string;
    @Input() country: string;
    @Input() pincode: string;
    @Output() data = new EventEmitter<any>();

    ngOnInit() {
        this.init_model();
        this.init_store();
        this.sub_store();
    }

    init_model() {
        this.m = new Model();
    }

    init_store() {}

    sub_store() {}

    setData() {
        var d = { line1: this.line1, line2: this.line2, city: this.city, country: this.country, state: this.state, pincode: this.pincode };
        this.data.emit(d);
    }
}