import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { SetupInstituteService as service } from './service/service';
import { CTApi } from '../service/ct-api';

import { Store } from '@ngrx/store';
import * as rootReducer from '../rootReducer';
import { BsModalRef, BsModalService } from "ngx-bootstrap";


class Model {
    userEmail: string;
    userId: string;
    agree: boolean = true;
    instName: string;
    instShortName: string;
    instWebsite: string;
    instOtpSent: boolean = false;
    instMobile: string;
    instOtp: string;
    smsCode: string;
    userName: string;
}

@Component({
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
    providers: [service]
})

export class SetupInstituteComponent implements OnInit {
    @ViewChild("notification") private contentRef: TemplateRef<any>;
    public m: Model;
    modalRef: BsModalRef;
    constructor(
        public ctapi: CTApi,
        private store: Store<rootReducer.State>,
        private service: service,
        private modalService: BsModalService) { }
    ngOnInit() {
        this.init_model();
        this.init_store();
        this.sub_store();
    }

    init_model() {
        this.m = new Model();
    }

    init_store() {
    }

    sub_store() {
        this.store.select(rootReducer.get_state_app).subscribe((res: any) => {
            if (res) {
                this.m.userEmail = res.userEmail;
                this.m.userId = res.userId;
                // let name = [];
                // name.push(res.userFirstName);
                // if (res.userMiddleName != null || res.userMiddleName != '') name.push(res.userMiddleName);
                // name.push(res.userLastName);
                // this.m.userName = name.join(' ');

                // this.m.selectedClientId = res.currentPersona.clientId;
                // if (this.m.selectedClientId != '') this.service.getInstPersonas('', this.m.selectedClientId);
            }
        });
    }

    ut_submit() {
        let details = { "Id": this.m.userId, "Mobile": this.m.instMobile, "InstituteName": "", "InstituteAddress": "", "InstituteWebsite": this.m.instWebsite, "CoursesSubjectsHandled": "", "StudyingIn": "" };
        this.service.updateUserDetails(details).subscribe();

        let payload = { "Id": this.m.userId, "Mobile": this.m.instMobile };
        this.service.sendVerificationCode(payload).subscribe();

    }

    ut_setupInst() {
        let verify_code_payload = { "Id": this.m.userId, "SMSCode": this.m.smsCode };
        this.service.verifySMSCode(verify_code_payload).subscribe(res => {
            if (res) {
                let payload = { "Name": this.m.instName, "ShortName": this.m.instShortName, "AcademicYearFrom": "", "AcademicYearTo": "", "UserId": this.m.userId };
                this.service.setupInstitute(payload).subscribe(res => {
                    if(res){
                        this.openNotificationModal();
                    }
                });
            }
        })
    }

    openNotificationModal() {
        this.modalRef = this.modalService.show(this.contentRef);
    }
}