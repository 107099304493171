import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

class Designation
{
    title: string = "";
    from: Date;
    remarks: string = "";
    role: string = "";
    subjectsTaught: any;
}

class Model {
    designation: Designation;
    
    constructor() {
        this.designation = new Designation();
    }
}

@Component({
    selector: 'designation',
    templateUrl: './component.html',
    styleUrls: ['./component.css']
})

export class DesignationComponent implements OnInit {
    constructor() { }
    public m: Model;

    @Input() title: string = "";
    @Input() from: any;
    @Input() remarks: string = "";
    @Input() role: any;
    @Input() designation: any;

    @Output() data = new EventEmitter<any>();

    ngOnInit() {
        this.init_model();
        this.init_store();
        this.sub_store();
    }

    ngOnDestroy() {
    }

    init_model() {
        if(this.designation){
            this.title = this.designation.title;
            this.remarks = this.designation.remarks;
            this.from = this.designation.from;
            this.role = this.designation.role;
        }
    }
    init_store() {}
    sub_store() {}

    setData() {
        var d = { title: this.title, from: this.from, remarks: this.remarks, role: this.role };
        this.data.emit(d);
    }
}