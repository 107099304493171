import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import * as rootReducer from '../../rootReducer';
import { SMSExpChartService as service } from './service/service';
import * as _ from 'underscore';

class Exp {
    mobile: string;
    error_message: string;
    row_span: number;
    students: any[] = [];
    stu_name: string;
}

class ExceptionData {
    year: number;
    month_no: number;
    month: string;
    exp: Exp[] = [];
}

class Model {
    smsExceptionData: ExceptionData[] = [];
    searchTerm: string;
}
@Component({
    selector: 'sms-exception-report',
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
    providers: [service]
})

export class SMSExceptionReportComponent {
    public m: Model;
    expData: any;
    @Input() pin: boolean;
    @Input() selectedMonthYear: string;
    constructor(private store: Store<rootReducer.State>, private service: service) { }

    ngOnInit() {
        this.init_model();
        this.init_store();
    }
    init_model() {
        this.m = new Model();
    }

    init_store() {
        this.store.select(rootReducer.get_selected_month_year).subscribe((res: any) => {
            if (res) {
                this.render_chart(res.monthYear);
            }
        });
        this.store.select(rootReducer.get_sms_delivery_chart_data).subscribe(res => {
            if (res) {
                this.expData = res.sms_dlr_exception;
                this.render_chart(this.selectedMonthYear);
            }
        });
    }

    render_chart(monthYear) {
        let data = this.expData;
        let mth_yr = monthYear.split(' ');
        let exp_data = _.find(data, function (d) {
            return (d.month == mth_yr[0] && d.year == mth_yr[1]);
        });
        this.m.smsExceptionData = exp_data ? exp_data.exp : [];
    }

    clearSearchText() {
        this.m.searchTerm = ""
    }
    pinChart() {
        this.pin = false;
        let payload = ["sms_dlr_exception"];
		this.service.pinWidget(payload).subscribe(res => {
			if(res) this.service.getDashboardWidgets();
		});
     
    }
    unpinChart() {
        this.pin = true;
        let payload = ["sms_dlr_exception"];
		this.service.unpinWidget(payload).subscribe(res => {
			if(res) this.service.getDashboardWidgets();
		});
    }
}