import { Component, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';

class Kv {
    constructor(public key: any, public value: string) { }
}

class Model {
    metaKv: Kv[] = [];
    total: any;

    addRow(i) {
        const attr = new Kv('', '0.00');
        this.metaKv.splice(i + 1, 0, attr);
    }
    deleteRow(i) {
        this.metaKv.splice(i, 1);
        if (this.metaKv.length == 0) { this.metaKv.push(new Kv('', '0.00')); }
    }
}

@Component({
    selector: 'metadata-with-typeahead',
    templateUrl: './component.html',
    styleUrls: ['./component.css']
})

export class MetadataWithTypeaheadComponent {
    public m: Model;
    constructor() { }

    @Input() typeaheadList: any;
    @Output() list = new EventEmitter<any>();
    @Input() clear: boolean = false;

    ngOnInit() {
        this.init_model();
        this.init_store();
    }
    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.clear && changes.clear.previousValue != undefined) {
            this.clearKv();
        }
    }
    init_model() {
        this.m = new Model();
        var initialTotal = 0;
        this.m.total = initialTotal.toFixed(2);
        this.m.metaKv.push(new Kv('', '0.00'));
    }

    init_store() { }

    trackByIndex(index: Number, kv: Kv) {
        if (!kv) return null;
        return kv.key;
    }

    clearKv() {
        if (this.m.metaKv != undefined && this.m.metaKv.length > 0) {
            this.m.metaKv = [];
            this.m.metaKv.push(new Kv('', '0.00'));
            this.m.total = '0.00'
            let val = { metaKv: this.m.metaKv, total: this.m.total };
            this.list.emit(val);
        }
    }

    emitValue() {
        var values = [];
        this.m.metaKv.forEach(num => {
            values.push(parseFloat(num.value));
        })
        var total = values.reduce((accumulator, currentValue) => {
            return accumulator + currentValue
        }, 0.00);

        this.m.total = total.toFixed(2);
        let val = { metaKv: this.m.metaKv, total: this.m.total };
        this.list.emit(val);
    }

    setKey(i, kv) {
        var val = kv
        this.m.metaKv[i].key = val.undefined;
        var obj = { key: val.undefined, value: this.m.metaKv[i].value };
        this.m.metaKv[i] = Object.assign({}, obj);
        this.emitValue();
    }

    clearKey(i, kv) {
        var obj = { key: '', value: this.m.metaKv[i].value };
        this.m.metaKv[i] = Object.assign({}, obj);
        this.emitValue();
    }

    clearValue(i, kv) {
        var obj = { key: this.m.metaKv[i].key, value: '0.00' };
        this.m.metaKv[i] = Object.assign({}, obj);
        this.emitValue();
    }
}