import { Action } from '@ngrx/store';
import { type } from '../../../app/util';
import { Notification } from './reducer';

export const ActionTypes = {
    GET_UNREAD_NOTIFICATIONS: type('[Notification] Get Unread Notifications'),
    SET_NOTIFICATIONS: type('[Notification] Set Notifications'),
    NOTIFICATION_READ: type('[Notification] Notification Read')
};

export class GetUnreadNotifications implements Action {
    type = ActionTypes.GET_UNREAD_NOTIFICATIONS;
    constructor(public payload: any = null) { }
}

export class SetNotifications implements Action {
    type = ActionTypes.SET_NOTIFICATIONS;
    constructor(public payload: { notifications: Array<Notification> }) { }
}

export class NotificationRead implements Action {
    type = ActionTypes.NOTIFICATION_READ;
    constructor(public payload: any = null) { }
}

export type Actions
    = GetUnreadNotifications | SetNotifications | NotificationRead;
