import { Action } from '@ngrx/store';
import { type } from '../../util';

export const ActionTypes = {
    SET_DATATEMPLATES: type('[DataTemplate] Set DataTemplate'),
    SET_SELECTED_DATATEMPLATE: type('[DataTemplate] Set Selected DataTemplate'),
    SET_CONTENT: type('[DataTemplate] Set Content'),
    SET_DATATEMPLATE_CONTEXT: type('[DataTemplate] Set DataTemplate Context'),
    SET_MODEL_CONTENT: type('[DataTemplate] Set Model Content')
}

export class SetDataTemplates implements Action {
    type = ActionTypes.SET_DATATEMPLATES;
    constructor(public payload: any) { }
}
export class SetSelectedDataTemplate implements Action {
    type = ActionTypes.SET_SELECTED_DATATEMPLATE;
    constructor(public payload: any) { }
}
export class SetContent implements Action {
    type = ActionTypes.SET_CONTENT;
    constructor(public payload: any) { }
}
export class SetDataTemplateContext implements Action {
    type = ActionTypes.SET_DATATEMPLATE_CONTEXT;
    constructor(public payload: any) { }
}
export class SetModelContent implements Action {
    type = ActionTypes.SET_MODEL_CONTENT;
    constructor(public payload: any) { }
}

export type Actions
    = SetDataTemplates | SetSelectedDataTemplate | SetContent | SetDataTemplateContext | SetModelContent