import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dex-prql-editor',
  templateUrl: './dex-prql-editor.component.html',
  styleUrls: ['./dex-prql-editor.component.css']
})
export class DexPrqlEditorComponent implements OnInit {
  @Input() prql: string = "";
  
  constructor() { }

  ngOnInit() {
  }

}
