import { Component, EventEmitter, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import * as rootReducer from '../../rootReducer';
import { SMSUsageChartService as service } from './service/service';
import * as _ from 'underscore';

class Model {
    chartData: any;
    chartoptions: any = {
        title: '',
        chartArea: { width: '85%' },
        backgroundColor: '#f5fafa',
        displayExactValues: true,
        hAxis: {
            title: 'Date',
            textStyle: {
                bold: true,
                fontSize: 10,
                color: '#4d4d4d'
            },
            titleTextStyle: {
                bold: true,
                fontSize: 10,
                color: '#4d4d4d'
            }
        },
        vAxis: {
            textPosition: "in",
            title: 'Count',
            minValue: 0,
            textStyle: {
                fontSize: 9,
                bold: true,
                color: '#848484'
            },
            titleTextStyle: {
                fontSize: 10,
                bold: true,
                color: '#848484'
            }
        },
        legend: { position: 'top' },
        isStacked: true,
    };
}
@Component({
    selector: 'sms-usage-chart',
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
    providers: [service]
})

export class SMSUsageChartComponent {
    public m: Model;
    @Input() pin: boolean;
    data: any;
    @Input() selectedMonthYear: string;
    constructor(private store: Store<rootReducer.State>, private service: service) { }

    ngOnInit() {
        this.init_model();
        this.init_store();
    }
    init_model() {
        this.m = new Model();
    }

    init_store() {
        // const csv =`date, delivered, undelivered
        // 1, 45, 10
        // 2, 33, 2
        // 3, 229, 27
        // 29, 34, 2
        // 30, 156, 30
        // 31, 70, 1`;
        // this.m.chartData = Data.dataTable(csv);
        this.store.select(rootReducer.get_selected_month_year).subscribe((res: any) => {
            if (res) {
                this.render_chart(res.monthYear);
            }
        });
        this.store.select(rootReducer.get_sms_delivery_chart_data).subscribe(res => {
            if (res) {
                this.data = res.sms_dlr_chart;
                this.render_chart(this.selectedMonthYear);
            }
        });
    }

    render_chart(monthYear) {
        let empty_chart = [['date', 'delivered', 'undelivered'], ['', 0, 0]];
        let data = this.data;
        let mth_yr = monthYear.split(' ');
        let chart_data = _.find(data, function (d) {
            return (d.month == mth_yr[0] && d.year == mth_yr[1]);
            // return (d.month == 'Dec' && d.year == 2018);
        });

        this.m.chartData = chart_data ? chart_data.data : empty_chart;
    }

    pinChart() {
        this.pin = false;
        let payload = ["sms_dlr_chart"];
		this.service.pinWidget(payload).subscribe(res => {
			if(res) this.service.getDashboardWidgets();
		});
     
    }
    
    unpinChart() {
        this.pin = true;
        let payload = ["sms_dlr_chart"];
		this.service.unpinWidget(payload).subscribe(res => {
			if(res) this.service.getDashboardWidgets();
		});
    }
}