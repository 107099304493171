import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { CTApi } from "../../../../app/service/ct-api";

import * as rootReducer from '../../../../app/rootReducer';
import * as homeAction from '../../../home/store/action';

@Injectable()
export class PublishedContentService {
    constructor(public ctapi: CTApi, private store: Store<rootReducer.State>, private httpClient: HttpClient) { }
    
    getDashboardWidgets() {
        return this.ctapi.getDashboardWidgets().subscribe(res => {
            let resp = JSON.parse(res.body);
            this.store.dispatch(new homeAction.SetDashboardWidgets({ "dashboardWigets": resp['data'] }));
        });
    }

    pinWidget(postdata) {
        return this.ctapi.pinDashboardWidget(postdata);
    }

    unpinWidget(postdata) {
        return this.ctapi.unpinDashboardWidget(postdata);
    }
}