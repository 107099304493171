import { Action } from '@ngrx/store';
import { type } from '../../util';

export const ActionTypes = {
    CREATE_COURSE: type('[Course] Create Course'),
    SET_COURSES: type('[Course] Set Course'),
    SET_SELECTED_COURSE: type('[Course] Set Selected Course'),
    DISABLE_COURSE: type('[Course] Disbale Course'),
    ENABLE_COURSE: type('[Course] Enable Course'),
    SET_COURSE_CONTEXT: type('[Course] Set Course Context'),
    SET_SELECTED_TERM: type('[Course] Set Course Term'),
    SET_SECTION: type('[Course] Set Section'),
    SET_SELECTED_SECTION: type('[Course] Set Selected Section'),
    SET_SELECTED_SECTION_TT: type('[Course] Set Selected Section TT'),
    SET_SELECTED_SUBJECT: type('[Course] Set Selected Subject'),
    REMOVE_TERM: type('[Course] Remove Term'),
    REMOVE_SECTION: type('[Course] Remove Section'),
    REMOVE_SUBJECT: type('[Course] Remove Subject'),
    SET_COURSE_SEARCH_TERM: type('[Course] Set Course Search Term'),
    SET_QUERY_STRING: type('[Course] Set Query String')
};

export class CreateCourse implements Action {
    type = ActionTypes.CREATE_COURSE;
    constructor(public payload: any) { }
}

export class SetCourses implements Action {
    type = ActionTypes.SET_COURSES;
    constructor(public payload: any) { }
}

export class SetSelectedCourse implements Action {
    type = ActionTypes.SET_SELECTED_COURSE;
    constructor(public payload: any) { }
}

export class DisableCourse implements Action {
    type = ActionTypes.DISABLE_COURSE;
    constructor(public payload: any) { }
}

export class EnableCourse implements Action {
    type = ActionTypes.ENABLE_COURSE;
    constructor(public payload: any) { }
}

export class SetCourseContext implements Action {
    type = ActionTypes.SET_COURSE_CONTEXT;
    constructor(public payload: any) { }
}

export class SetSelectedTerm implements Action {
    type = ActionTypes.SET_SELECTED_TERM;
    constructor(public payload: any) { }
}

export class SetSelectedSection implements Action {
    type = ActionTypes.SET_SELECTED_SECTION;
    constructor(public payload: any) { }
}

export class SetSelectedSectionTT implements Action {
    type = ActionTypes.SET_SELECTED_SECTION_TT;
    constructor(public payload: any) { }
}

export class SetSelectedSubject implements Action {
    type = ActionTypes.SET_SELECTED_SUBJECT
    constructor(public payload: any) { }
}

export class RemoveTerm implements Action {
    type = ActionTypes.REMOVE_TERM
    constructor(public payload: any) { }
}

export class RemoveSection implements Action {
    type = ActionTypes.REMOVE_SECTION
    constructor(public payload: any) { }
}

export class RemoveSubject implements Action {
    type = ActionTypes.REMOVE_SUBJECT
    constructor(public payload: any) { }
}

export class SetCourseSearchTerm implements Action {
    type = ActionTypes.SET_COURSE_SEARCH_TERM
    constructor(public payload: any) { }
}

export class SetQueryString implements Action {
    type = ActionTypes.SET_QUERY_STRING
    constructor(public payload: any) { }
}

export type Actions = CreateCourse | SetCourses | SetSelectedCourse | DisableCourse | EnableCourse | SetCourseContext | SetSelectedTerm
    | SetSelectedSection | SetSelectedSectionTT | SetSelectedSubject | RemoveTerm | RemoveSection | RemoveSubject | SetCourseSearchTerm | SetQueryString ;
