import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap';
import * as rootReducer from '../../rootReducer';
import { Router } from '@angular/router';


class Model {
    title: string = "";
}

@Component({
    selector: 'payment',
    templateUrl: './component.html',
    styleUrls: ['./component.css']
})

export class PaymentComponent implements OnInit {
    public m: Model;
    @Input() paymentFor: any;
    @Input() amount: any;
    @Input() gst: any;
    @Input() gstAmount: any;
    @Input() totalAmount: any;
    constructor(private store: Store<rootReducer.State>, private el: ElementRef, private modalService: BsModalService, private router: Router) { }
    ngOnInit() {
        this.init_model();
        this.init_store();
        this.sub_store();
    }

    init_model() {
        this.m = new Model();
    }

    init_store() {
    }

    sub_store() { }
    goToPayment(){ 
		this.router.navigate(['/home/payment']);
	}
}