import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import { Store } from '@ngrx/store';
import * as rootReducer from '../rootReducer';
import { Subscription } from 'rxjs/Subscription';
import { Router } from '@angular/router';

import * as ngxQryOptionsAction from '../ngxQryOptions/store/action';
import { NgxQryOptionsService as service } from './service/service';
import { TabsModule } from 'ngx-bootstrap/tabs';
import * as _ from 'underscore';

class model {
    public ctContext: any;
    public userProfile: any;
    public editUserPwd: boolean = false;
    public CurrentPwd: string = '';
    public NewPwd: string = '';
}

@Component({
    selector: 'ngxQryOptions',
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
    providers: [service]
})
export class NgxQryOptionsComponent implements OnInit {
    public m: model;
    public currentPage: number;
    public pageSize: number;
    public ph: string = "";
    public filterExpression: string = "";
    public filterArray: any[] = [];
    public filterOptionsCopy: any;
    constructor(public service: service, private store: Store<rootReducer.State>, private router: Router) {
    }

    @Input() showFilter: boolean;
    @Input() showPager: boolean;
    @Input() showSearch: boolean;
    @Input() totalPages: number;
    @Input() sortOn: string;
    @Input() sortOrder: string;
    @Input() placeHolder: string = "Search";
    @Input() orderBy: string;
    @Input() filterOptions: any;
    @Input() inputDisable: boolean;
    @Output() onQry = new EventEmitter<string>();
    @Output() onKeypress = new EventEmitter<string>();
    @Input() searchText: string = '';

    ngOnInit() {
        this.init_model();
        this.init_store();
        this.sub_store();
        this.showFilter = this.showFilter || false;
        this.totalPages = this.totalPages || 1000;
        this.currentPage = 1;
        this.pageSize = 10;
        this.orderBy = this.orderBy || "";
        this.sortOrder = this.sortOrder || "";
        this.sortOn = this.sortOn || "";
        this.filterOptions = this.filterOptions || null;
        if (this.filterOptions) { this.filterOptionsCopy = JSON.parse(JSON.stringify(this.filterOptions)); }
    }
    ngOnDestroy() {
    }
    init_model() {
        this.m = new model();
    }
    init_store() {
    }
    sub_store() {
    }
    getQryString() {
        var q = "$search=" + this.searchText;
        q = q + "&$orderby=" + this.orderBy + " " + this.sortOrder;
        var skipVal = (this.currentPage - 1) * this.pageSize;
        if (!skipVal) { skipVal = 0; }
        if (!this.pageSize) { this.pageSize = 10; }
        q = q + "&$skip=" + skipVal + "&$top=" + this.pageSize;
        return q;
    }
    search() {
        this.currentPage = 1;
        var str = this.getQryString();
        this.onQry.emit(str);
    }
    inpModelChange(st) {
        this.onKeypress.emit(st);
    }
    clearSearch() {
        this.currentPage = 1;
        this.searchText = "";
        var str = this.getQryString();
        this.onQry.emit(str);
        if (this.filterOptionsCopy) { this.filterOptions = JSON.parse(JSON.stringify(this.filterOptionsCopy)); }
    }
    paginate() {
        var str = this.getQryString();
        this.onQry.emit(str);
    }
    firstPage() {
        this.currentPage = 1;
        var str = this.getQryString();
        this.onQry.emit(str);
    }
    prevPage() {
        if (this.currentPage > 1) {
            this.currentPage--;
            var str = this.getQryString();
            this.onQry.emit(str);
        }
    }
    nextPage() {
        if (this.currentPage < this.totalPages) {
            this.currentPage++;
            var str = this.getQryString();
            this.onQry.emit(str);
        }
    }
    lastPage() {
        this.currentPage = this.totalPages;
        var str = this.getQryString();
        this.onQry.emit(str);
    }
    resetPlaceholder() {
        this.ph = "";
    }
    setPage() {
        var pageNo = isNaN(Number(this.ph)) ? this.currentPage : Number(this.ph);
        pageNo = (pageNo <= 0) ? 1 : (pageNo > this.totalPages) ? this.totalPages : pageNo;
        this.ph = "";
        this.currentPage = pageNo;
        var str = this.getQryString();
        this.onQry.emit(str);
    }
    getFilterExp() {
        setTimeout(() => {
            this.filterExpression = "";
            this.filterArray = [];
            this.filterOptions.filterItems.forEach(x => {
                if (x.fe && x.checked) {
                    var feObj = { k: "", v: "" };
                    var feArray = x.fe.split(":");
                    feObj.k = feArray[0];
                    feObj.v = feArray[1];
                    this.filterArray.push(feObj);
                }
                x.children.forEach(a => {
                    if (a.fe && a.checked) {
                        var feObj = { k: "", v: "" };
                        var feArray = a.fe.split(":");
                        feObj.k = feArray[0];
                        feObj.v = feArray[1];
                        this.filterArray.push(feObj);
                    }
                    else {
                        _.each(a.children, function (u, v) {
                            if (u.fe && u.checked) {
                                var feObj = { k: "", v: "" };
                                var feArray = u.fe.split(":");
                                feObj.k = feArray[0];
                                feObj.v = feArray[1];
                                this.filterArray.push(feObj);
                            }
                        });
                    }
                });
            });
            var groups = _
                .chain(this.filterArray)
                .groupBy('k')
                .map(function (value, key) {
                    return {
                        type: key,
                        items: _.pluck(value, 'v')
                    }
                })
                .value();

            this.filterExpression = "";
            groups.forEach((v, index) => {
                this.filterExpression += v.type + ':' + v.items.toString();
                if (index < groups.length - 1) { this.filterExpression += ';' }
            });
            this.searchText = this.filterExpression;
            this.onKeypress.emit(this.searchText);
            this.filterOptions.Search = this.filterExpression;
        }, 100)
    };
    handleEvent(event) {
        event.stopPropagation();
    }
    ngOnChanges(changes: SimpleChanges) {
        if (this.sortOn && this.sortOn) {
            this.sortOn = this.sortOn.split(',')[0];
            if (this.orderBy == this.sortOn) {
                this.sortOrder = this.sortOrder == "asc" ? "desc" : "asc";
            }
            else {
                this.sortOrder = "asc";
            }
            this.orderBy = this.sortOn;
            var str = this.getQryString();
            this.onQry.emit(str);
        }
    }
}