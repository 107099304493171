import { Action } from '@ngrx/store';
import { type } from '../../app/util';

export const IntroActionTypes = {
    SET_INTRO_DONE: type('[Intro] Set Intro Done'),
    SET_INTRO_UNDONE: type('[Intro] Set Intro Undone'),
    SET_USER_INTROS: type('[Intro] Set User Intros')
}

export class SetIntroDone implements Action {
    type = IntroActionTypes.SET_INTRO_DONE;
    constructor(public introId: string) { }
}
export class SetIntroUndone implements Action {
    type = IntroActionTypes.SET_INTRO_UNDONE;
    constructor(public introId: string) { }
}

export class SetUserIntros implements Action {
    type = IntroActionTypes.SET_USER_INTROS;
    constructor(public intros: any) { }
}

export type IntroActions = SetIntroDone | SetIntroUndone | SetUserIntros | any;
