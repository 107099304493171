import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class HeaderEffects {

    constructor(private actions$: Actions, private router: Router) { }

    @Effect({dispatch: false})
    logout$ = this.actions$
        .pipe(
            ofType('[App] Logout'),
            tap(() => {
                // this.router.navigate(['/home'])
            })
        );
}