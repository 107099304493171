import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { CTApi } from '../../../../app/service/ct-api';
import * as rootReducer from '../../../../app/rootReducer';
import * as homeAction from '../../../home/store/action';
import * as instAction from '../../../inst/store/action';

@Injectable()
export class InstSetupService {
    constructor(private router: Router, public ctapi: CTApi, private store: Store<rootReducer.State>, private httpClient: HttpClient) { }

    getDashboardWidgets() {
        return this.ctapi.getDashboardWidgets().subscribe(res => {
            let resp = JSON.parse(res.body);
            this.store.dispatch(new homeAction.SetDashboardWidgets({ "dashboardWigets": resp['data'] }));
        });
    }

    pinWidget(postdata) {
        return this.ctapi.pinDashboardWidget(postdata);
    }

    unpinWidget(postdata) {
        return this.ctapi.unpinDashboardWidget(postdata);
    }
    getInst(id) {
        this.ctapi.getInst(id).subscribe(res => {
            if (res['data']) {
                let data = res['data'];
                this.store.dispatch(new instAction.SetSelectedInst({ "selectedInst": data }));
                this.router.navigate(['/admin/inst/edit']);
            }
        });
    }
}
