import { Action } from '@ngrx/store';
import { type } from '../../util';

export const ActionTypes = {
    SET_PERSONAS: type('[Persona] Set personas'),
    SET_SELECTED_PERSONA: type('[Persona] Set Selected persona'),
    COPY_ITEM: type('[Persona] Copy item')
};

export class SetPersonas implements Action {
    type = ActionTypes.SET_PERSONAS;
    constructor(public payload: any) {
    }
}

export class SetSelectedPersona implements Action {
    type = ActionTypes.SET_SELECTED_PERSONA;
    constructor(public payload: any) {
    }
}

export class CopyItem implements Action {
    type = ActionTypes.COPY_ITEM;
    constructor(public payload: any) {
    }
}

export type Actions
    = SetPersonas | SetSelectedPersona | CopyItem;