const userLinks = {
    "Academics":
    {
        "tabIcon": "fa fa-university",
        "col2": [
            {
                "id": "a1",
                "groupType": "Report",
                "title": "Attendance / Calendar / Timetable (Demo data not uploaded)",
                "groupLinks": [
                    {
                        "title": "Absentees report",
                        "description": "view the report for absentees for a particular day",
                        "endPoint": "/academics/absenteesReport"
                    },
                    {
                        "title": "Holiday list",
                        "description": "view the holiday list for the academic year",
                        "endPoint": "/academics/holidayListReport"
                    },
                    {
                        "title": "Today's events",
                        "description": "view the events for today",
                        "endPoint": "/academics/todaysEventsReport"
                    },
                    {
                        "title": "Upcoming events",
                        "description": "view the upcoming events",
                        "endPoint": "/academics/upcomingEventsReport"
                    }
                ]
            },
            {
                "id": "a2",
                "groupType": "Report",
                "title": "Management Reports (Final exam based reports)",
                "groupLinks": [
                    {
                        "title": "Consolidated Marks",
                        "description": "view course / sectionwise consolidated report for final exam",
                        "endPoint": "/academics/consolidatedMarksReport"
                    },
                    {
                        "title": "Rank List",
                        "description": "view course-wise top 10 rank holders for final exam",
                        "endPoint": "/academics/rankListReport"
                    },
                    {
                        "title": "Result At A Glance",
                        "description": "view course-wise result at a glance for final exam",
                        "endPoint": "/academics/resultAtAGlanceManagementReport"
                    },
                    {
                        "title": "Top Scorers In Each Subject",
                        "description": "view course-wise top scorers in each subject",
                        "endPoint": "/academics/topScorersReport"
                    },
                    {
                        "title": "First Ten Toppers",
                        "description": "view course-wise first ten toppers",
                        "endPoint": "/academics/firstTenToppersReport"
                    },
                    {
                        "title": "A+ In All Subjects",
                        "description": "view course-wise students scoring A+ in all subjects throughout the year",
                        "endPoint": "/academics/aPlusInAllSubjectsReport"
                    }
                ]
            }
        ],
        "col3": [
            {
                "id": "b1",
                "groupType": "Report",
                "title": "Exams (Ensure exams are locked before running these reports)",
                "groupLinks": [
                    {
                        "title": "Attendance report",
                        "description": "view the attendance for all the exams",
                        "endPoint": "/academics/examAttendanceReport"
                    },
                    {
                        "title": "Coursewise Result Analysis",
                        "description": "view examwise / coursewise Coursewise Result Analysis report",
                        "endPoint": "/academics/coursewiseResultAnalysisReport"
                    },
                    {
                        "title": "Consolidated Report",
                        "description": "view exam consolidated report",
                        "endPoint": "/academics/consolidatedReport"
                    },
                    {
                        "title": "Exams list",
                        "description": "view the exams list",
                        "endPoint": "/academics/examListReport"
                    },
                    {
                        "title": "Examwise Failures",
                        "description": "view examwise / coursewise failures report",
                        "endPoint": "/academics/examwiseFailuresReport"
                    },
                    {
                        "title": "Failures Report",
                        "description": "view the failures report",
                        "endPoint": "/academics/failuresReport"
                    },
                    {
                        "title": "Full Marks",
                        "description": "view examwise / coursewise report of students who have secured full marks in each subject",
                        "endPoint": "/academics/fullMarksReport"
                    },
                    {
                        "title": "Classwise / Gradewise Statistics",
                        "description": "view examwise / coursewise / gradewise statistics",
                        "endPoint": "/academics/gradewiseStatisticsReport"
                    },
                    {
                        "title": "Hall Ticket",
                        "description": "view students hall ticket for an exam",
                        "endPoint": "/academics/hallTicket"
                    },
                    {
                        "title": "No Due Certificate",
                        "description": "view student no due certificate",
                        "endPoint": "/academics/noDueCertificate"
                    },
                    {
                        "title": "Result Analysis",
                        "description": "view examwise / coursewise result analysis",
                        "endPoint": "/academics/resultAnalysisReport"
                    },
                    {
                        "title": "Result At A Glance",
                        "description": "view examwise / coursewise result at a glance",
                        "endPoint": "/academics/resultAtAGlanceReport"
                    },
                    {
                        "title": "Subject Average",
                        "description": "view examwise / coursewise subject average",
                        "endPoint": "/academics/subjectAverageReport"
                    },
                    {
                        "title": "Student performance",
                        "description": "view the report for student performance for all the exams",
                        "endPoint": "/academics/studentPerformanceReport"
                    }
                ]
            }
        ],
        "col1": [
            {
                "id": "c1",
                "groupType": "ActionLink",
                "title": "Action Links",
                "subGroup": [
                    {
                        "title": "Attendance",
                        "route": "/academics/attendance",
                        "apis": ["get/acad/attendance/absentees"],
                        "subgroupLinks": [
                            {
                                "title": "Take Attendance",
                                "description": "take attendance",
                                "endPoint": "/academics/attendance",
                                "Icon": "fa fa-plus",
                                "api": "get/acad/attendance/absentees"
                            }
                        ]
                    },
                    {
                        "title": "Calendar",
                        "route": "/academics/calendar/manage",
                        "apis": ["get/acad/calendar"],
                        "subgroupLinks": [
                            {
                                "title": "Search/Edit event",
                                "description": "",
                                "endPoint": "/academics/calendar/manage",
                                "Icon": "fa fa-search",
                                "api": "get/acad/calendar"
                            },
                            {
                                "title": "Add event",
                                "description": "",
                                "endPoint": "/academics/calendar/events",
                                "Icon": "fa fa-plus",
                                "api": "post/acad/calendar"
                            }
                        ]
                    },
                    {
                        "title": "Exam",
                        "route": "/academics/exam",
                        "apis": ["get/acad/exam"],
                        "subgroupLinks": [
                            {
                                "title": "Manage",
                                "description": "Search / Edit",
                                "endPoint": "/academics/exam",
                                "Icon": "fa fa-search",
                                "api": "get/acad/exam"
                            },
                            {
                                "title": "Evaluation",
                                "description": "Evaluation",
                                "endPoint": "",
                                "Icon": "fa fa-plus"
                            }
                        ]
                    },
                    {
                        "title": "Timetable",
                        "route": "/academics/timetable",
                        "apis": ["get/acad/course"],
                        "subgroupLinks": [
                            {
                                "title": "Manage",
                                "description": "Add / Edit",
                                "endPoint": "/academics/timetable",
                                "Icon": "fa fa-search",
                                "api": "get/acad/course"
                            },
                            {
                                "title": "Add",
                                "description": "Add",
                                "endPoint": "",
                                "Icon": "fa fa-plus"
                            }
                        ]
                    },
                    {
                        "title": "SMS",
                        "subgroupLinks": [
                            {
                                "title": "",
                                "description": "",
                                "endPoint": "",
                                "Icon": ""
                            }
                        ]
                    }
                ]
            }
        ]
    },
    "Accounting": {
        "col1": [{
            "id": "c1",
            "groupType": "ActionLink",
            "title": "Action Links",
            "subGroup": [
                {
                    "title": "Chart of Accounts",
                    "route": "/accounting/coa",
                    "apis": ["get/accounting/journal", "get/accounting/ledger"],
                    "subgroupLinks": [
                        {
                            "title": "Search",
                            "description": "",
                            "endPoint": "/accounting/coa",
                            "Icon": "fa fa-search",
                            "api": "get/accounting/journal"
                        }
                    ]
                },
                {
                    "title": "Voucher",
                    "route": "/accounting/manageVoucher",
                    "apis": ["get/accounting/journal", "get/accounting/ledger"],
                    "subgroupLinks": [
                        {
                            "title": "Search Voucher",
                            "description": "",
                            "endPoint": "/accounting/manageVoucher",
                            "Icon": "fa fa-search",
                            "api": "get/accounting/journal"
                        },
                        {
                            "title": "Add Voucher",
                            "description": "",
                            "endPoint": "/accounting/addVoucher",
                            "Icon": "fa fa-plus",
                            "api": "get/accounting/ledger"
                        }
                    ]
                }
            ]
        }],
        "col2": [{
            "id": "a1",
            "groupType": "Report",
            "title": "Accounting Report",
            "groupLinks": [
                {
                    "title": "Categorywise Expense Report",
                    "description": "view the categorywise expense report for an institute",
                    "endPoint": "/accounting/categorywiseExpenseReport"
                },
                {
                    "title": "Datewise Expense Report",
                    "description": "view the datewise expense report for an institute",
                    "endPoint": "/accounting/datewiseExpenseReport"
                },
                {
                    "title": "Yearwise Expense Report",
                    "description": "view the yearwise expense report for an institute",
                    "endPoint": "/accounting/yearwiseExpenseReport"
                }

            ]
        }],
        "col3": []
    },
    "Admin":
    {
        "col2": [
            {
                "id": "a1",
                "groupType": "Report",
                "title": "Course",
                "groupLinks": [
                    {
                        "title": "Courses Offered",
                        "description": "view the course list for academic year",
                        "endPoint": "/admin/courseListReport"
                    }
                ]
            },
            {
                "id": "a2",
                "groupType": "Report",
                "title": "Institute (Demo data not uploaded)",
                "groupLinks": [
                    {
                        "title": "Calendar",
                        "description": "view calendar report for an institute",
                        "endPoint": "/admin/calendarReport"
                    },
                    {
                        "title": "Summary report (WIP)",
                        "description": "view summary report for an institute",
                        "endPoint": "/admin/summaryReport"
                    },
                    {
                        "title": "Upcoming events",
                        "description": "view upcoming events for an institute",
                        "endPoint": "/admin/upcomingEventsAdminReport"
                    }
                ]
            },
            {
                "id": "a3",
                "groupType": "Report",
                "title": "Receipt (Demo data not uploaded)",
                "groupLinks": [
                    {
                        "title": "Consolidated Report",
                        "description": "Receipts consolidated report",
                        "endPoint": "/admin/receiptConsolidatedReport"
                    }
                ]
            },
            {
                "id": "a4",
                "groupType": "Report",
                "title": "Security",
                "groupLinks": [
                    {
                        "title": "User access report",
                        "description": "view the report for org user access",
                        "endPoint": "/admin/usersReport"
                    }
                ]
            },
            // {
            // 	"id": "a5",
            // 	"groupType": "Report",
            // 	"title": "Organization",
            // 	"groupLinks": []
            // }

        ],
        "col3": [
            {
                "id": "b2",
                "groupType": "Report",
                "title": "HR (Demo data not uploaded)",
                "groupLinks": [
                    {
                        "title": "HR report (WIP)",
                        "description": "view list of all HR",
                        "endPoint": "/admin/hrReport"
                    },
                    // {
                    // 	"title": "Subjectwise breakup (WIP)",
                    // 	"description": "view report for subject wise HR breakup",
                    // 	"endPoint": "/admin/subjectwiseBreakupReport"
                    // }
                ]
            },
            {
                "id": "b3",
                "groupType": "Report",
                "title": "Student",
                "groupLinks": [
                    {
                        "title": "Students Coursewise Breakup",
                        "description": "view report for course wise students breakup",
                        "endPoint": "/admin/coursewiseBreakupReport"
                    },
                    {
                        "title": "Student Details",
                        "description": "view report for section wise student details",
                        "endPoint": "/admin/studentDetailsReport"
                    },
                    {
                        "title": "Students Sectionwise Breakup",
                        "description": "view report for section wise student details breakup",
                        "endPoint": "/admin/sectionwiseBreakupReport"
                    },
                    {
                        "title": "Students Basic Sectionwise Breakup",
                        "description": "view report for section wise student details breakup",
                        "endPoint": "/admin/basicSectionwiseBreakupReport"
                    },
                    {
                        "title": "Students Parent Details",
                        "description": "view report for section wise student parent details ",
                        "endPoint": "/admin/sectionwiseParentDetailsReport"
                    },
                    {
                        "title": "New Admissions Report",
                        "description": "view report for new admissions",
                        "endPoint": "/admin/newAdmissionsReport"
                    },
                    {
                        "title": "TC Students List",
                        "description": "view report for tc issued students",
                        "endPoint": "/admin/tcStudentsList"
                    }
                ]
            }
        ],
        "col1": [
            {
                "id": "c1",
                "groupType": "ActionLink",
                "title": "Action Links",
                "subGroup": [
                    {
                        "title": "Baseline Data",
                        "route": "/admin/student/manage",
                        "apis": ["get/stu/student", "post/stu/student"],
                        "subgroupLinks": [
                            {
                                "title": "Search/Edit baseline data",
                                "description": "",
                                "endPoint": "/admin/submittedBaselineData",
                                "Icon": "fa fa-search",
                                "api": "get/stu/student"
                            },
                            {
                                "title": "Add Baseline Data",
                                "description": "",
                                "endPoint": "/admin/baseline",
                                "Icon": "fa fa-plus",
                                "api": "post/stu/student"
                            }
                        ]
                    },
                    {
                        "title": "Course",
                        "route": "/admin/course/manage",
                        "apis": ["post/acad/course", "get/acad/course"],
                        "subgroupLinks": [
                            {
                                "title": "Search courses",
                                "description": "",
                                "endPoint": "/admin/course/manage",
                                "Icon": "fa fa-search",
                                "api": "get/acad/course"
                            },
                            {
                                "title": "Add Course",
                                "description": "",
                                "endPoint": "/admin/course/add",
                                "Icon": "fa fa-plus",
                                "api": "post/acad/course"
                            }
                        ]
                    },
                    {
                        "title": "Data",
                        "route": "/admin/data/manage",
                        "apis": ["get/arte/artefact/dataTemplates"],
                        "subgroupLinks": [
                            {
                                "title": "Data Templates",
                                "description": "",
                                "endPoint": "/admin/data/manage",
                                "Icon": "fa fa-search",
                                "api": "get/arte/artefact/dataTemplates"
                            }
                        ]
                    },
                    // {
                    // 	"title": "HR",
                    // 	"apis": ["post/staff", "get/staff"],
                    // 	"subgroupLinks": [
                    // 		{
                    // 			"title": "Search/Edit HR",
                    // 			"description": "",
                    // 			"endPoint": "/admin/staff/manage",
                    // 			"Icon": "fa fa-search",
                    // 			"api": "get/staff"
                    // 		},
                    // 		{
                    // 			"title": "Add HR",
                    // 			"description": "",
                    // 			"endPoint": "/admin/staff/add",
                    // 			"Icon": "fa fa-plus",
                    // 			"api": "post/staff"
                    // 		}
                    // 	]
                    // },
                    {
                        "title": "Institute",
                        "route": "/admin/inst",
                        "apis": ["post/auth/institute", "get/auth/institute"],
                        "subgroupLinks": [
                            {
                                "title": "Search/Edit Insts",
                                "description": "",
                                "endPoint": "/admin/inst",
                                "Icon": "fa fa-search",
                                "api": "get/auth/institute"
                            },
                            {
                                "title": "Add Inst",
                                "description": "",
                                "endPoint": "/admin/inst/add",
                                "Icon": "fa fa-plus",
                                "api": "post/auth/institute"
                            }
                        ]
                    },
                    {
                        "title": "Org",
                        "route": "/admin/org",
                        "apis": ["post/auth/org", "get/auth/org"],
                        "subgroupLinks": [
                            {
                                "title": "Search/Edit Orgs",
                                "description": "",
                                "endPoint": "/admin/org",
                                "Icon": "fa fa-search",
                                "api": "get/auth/org"
                            },
                            {
                                "title": "Add Org",
                                "description": "",
                                "endPoint": "/admin/org/add",
                                "Icon": "fa fa-plus",
                                "api": "post/auth/org"
                            }
                        ]
                    },
                    {
                        "title": "Student",
                        "route": "/admin/student/manage",
                        "apis": ["get/stu/student", "post/stu/student", "get/stu/student/absenteesRecipientCount"],
                        "subgroupLinks": [
                            {
                                "title": "Search/Edit student",
                                "description": "",
                                "endPoint": "/admin/student/manage",
                                "Icon": "fa fa-search",
                                "api": "get/stu/student"
                            },
                            {
                                "title": "Add Student",
                                "description": "",
                                "endPoint": "/admin/student/add",
                                "Icon": "fa fa-plus",
                                "api": "post/stu/student"
                            }
                        ]
                    },
                    {
                        "title": "Report",
                        "route": "/admin/report/manage",
                        "apis": ["get/arte/artefact/reports", "post/arte/artefact/upsert"],
                        //"apis": [],
                        "subgroupLinks": [
                            {
                                "title": "Search/Edit report",
                                "description": "",
                                "endPoint": "/admin/report/manage",
                                "Icon": "fa fa-search",
                                //"api": ""
                                "api": "get/arte/artefact/reports"
                            },
                            {
                                "title": "Add Report",
                                "description": "",
                                "endPoint": "/admin/report/add",
                                "Icon": "fa fa-plus",
                                //"api": ""
                                "api": "post/arte/artefact/upsert"
                            }
                        ]
                    },
                    {
                        "title": "Widgets",
                        "route": "/admin/widget/manage",
                        "apis": [],
                        //"apis": ["get/arte/artefact", "post/arte/artefact/upsert", "post/acad/artefact/StudentAttendanceTemplate"],
                        "subgroupLinks": [
                            {
                                "title": "Search widgets",
                                "description": "",
                                "endPoint": "/admin/widget/manage",
                                "Icon": "fa fa-search",
                                "api": ""
                                //"api": "get/arte/artefact"
                            },
                            {
                                "title": "Add widget",
                                "description": "",
                                "endPoint": "/admin/widget/add",
                                "Icon": "fa fa-plus",
                                "api": ""
                                //"api": "post/arte/artefact/upsert"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    "Applications": {
        "col1": [],
        "col2": [],
        "col3": []
    },
    "Admissions": {
        "col2": [],
        "col3": [],
        "col1": [
            {
                "id": "c1",
                "groupType": "ActionLink",
                "title": "Action Links",
                "subGroup": [
                    {
                        "title": "Seat Matrix",
                        "route": "/admissions/seatMatrix/manage",
                        "apis": ["get/admission"],
                        "subgroupLinks": [
                            {
                                "title": "Seat Matrix",
                                "description": "view seat matrix",
                                "endPoint": "/admissions/seatMatrix/manage",
                                "Icon": "fa fa-search",
                                "api": "get/admission"
                            },
                            {
                                "title": "Seat Matrix",
                                "description": "add seat matrix",
                                "endPoint": "/admissions/seatMatrix/add",
                                "Icon": "fa fa-plus",
                                "api": "get/admission"
                            }
                        ]
                    },
                    {
                        "title": "Application Form",
                        "route": "/admissions/applicationForm/manage",
                        "apis": ["get/admission"],
                        "subgroupLinks": [
                            {
                                "title": "Search / View Application Form Templates",
                                "description": "view application form templates",
                                "endPoint": "/admissions/applicationForm/manage",
                                "Icon": "fa fa-search",
                                "api": "get/admission"
                            },
                            {
                                "title": "Configure Application Form",
                                "description": "configure application form templates",
                                "endPoint": "/admissions/applicationForm/add",
                                "Icon": "fa fa-plus",
                                "api": "get/admission"
                            }
                        ]
                    },
                    {
                        "title": "Applications",
                        "route": "/admissions/applications/manage",
                        "apis": ["get/admission"],
                        "subgroupLinks": [
                            {
                                "title": "Search / View Applications",
                                "description": "view applications",
                                "endPoint": "/admissions/applications/manage",
                                "Icon": "fa fa-search",
                                "api": "get/admission"
                            }
                        ]
                    }, {
                        "title": "Selected Applications",
                        "route": "/admissions/selectedApplications/manage",
                        "apis": ["get/admission"],
                        "subgroupLinks": [
                            {
                                "title": "Search / View Selected Applications",
                                "description": "view selected applications",
                                "endPoint": "/admissions/selectedApplications/manage",
                                "Icon": "fa fa-search",
                                "api": "get/admission"
                            }
                        ]
                    }, {
                        "title": "Shortlists",
                        "route": "/admissions/shortlists/manage",
                        "apis": ["get/admission"],
                        "subgroupLinks": [
                            {
                                "title": "Search / View Shortlisted Applications",
                                "description": "view shortlisted applications",
                                "endPoint": "/admissions/shortlists/manage",
                                "Icon": "fa fa-search",
                                "api": "get/admission"
                            },
                            {
                                "title": "Add Shortlist Publish date",
                                "description": "add shortlist publish date",
                                "endPoint": "/admissions/shortlists/add",
                                "Icon": "fa fa-plus",
                                "api": "get/admission"
                            }
                        ]
                    }
                    // 	,
                    // {
                    // 	"title": "Applicant Manage",
                    // 		"apis": ["get/admission/applicant"],
                    // 		"subgroupLinks": [
                    // 			{
                    // 				"title": "Applicant Manage",
                    // 				"description": "view shortlisted applications",
                    // 				"endPoint": "/admissions/applicantManage",
                    // 				"Icon": "fa fa-search",
                    // 				"api": "get/admission/applicant"
                    // 			}
                    // 		]
                    // }
                ]
            }
        ]
    },
    "Assets": {
        "col1": [
            {
                "id": "a1",
                "groupType": "Report",
                "title": "Assets",
                "groupLinks":
                    [
                        {
                            "title": "Location wise assets report",
                            "description": "view location wise reports for assets",
                            "endPoint": "/assets/locationwiseReport"
                        }
                    ]
            }
        ],
        "col2": [],
        "col3": [
            {
                "id": "c1",
                "groupType": "ActionLink",
                "title": "Action Links",
                "subGroup":
                    [
                        {
                            "title": "Asset",
                            "route": "/assets/manage",
                            "apis": ["post/asset", "get/asset"],
                            "subgroupLinks":
                                [
                                    {
                                        "title": "Search / Edit asset",
                                        "description": "",
                                        "endPoint": "/assets/manage",
                                        "Icon": "fa fa-search",
                                        "api": "get/asset"
                                    },
                                    {
                                        "title": "Add asset",
                                        "description": "",
                                        "endPoint": "/assets/add",
                                        "Icon": "fa fa-plus",
                                        "api": "post/asset"
                                    }
                                ]
                        },
                        {
                            "title": "Checklist",
                            "route": "/checklist/manage",
                            "apis": [],
                            "subgroupLinks": [
                                {
                                    "title": "Search/Edit checklist",
                                    "description": "",
                                    "endPoint": "/checklist/manage",
                                    "Icon": "fa fa-search",
                                    "api": ""
                                },
                                {
                                    "title": "Add checklist",
                                    "description": "",
                                    "endPoint": "/checklist/add",
                                    "Icon": "fa fa-plus",
                                    "api": ""
                                }
                            ]
                        }
                    ]
            }
        ]
    },

    "Connect": {
        "col2": [
            {
                "id": "a1",
                "groupType": "Report",
                "title": "Connect",
                "groupLinks": [
                    {
                        "title": "SMS usage report",
                        "description": "view the report for SMS usage",
                        "endPoint": "/connect/smsUsageReport"
                    }
                ]
            }
        ],
        "col3": [
            {
                "id": "b1",
                "groupType": "Report",
                "title": "Exception Reports",
                "groupLinks": [
                    {
                        "title": "Invalid / Blocked emails report",
                        "description": "view the report for invalid Email IDs",
                        "endPoint": "/connect/invalidEmailsReport"
                    },
                    {
                        "title": "Invalid / Blocked mobiles report",
                        "description": "view the report for invalid mobile numbers",
                        "endPoint": "/connect/invalidMobilesReport"
                    }
                ]
            }],
        "col1": [
            {
                "id": "c1",
                "groupType": "ActionLink",
                "title": "Action Links",
                "api": ["post/connect/lookup"],
                "subGroup": [
                    {
                        "title": "Contact",
                        "route": "",
                        "apis": "",
                        "subgroupLinks": [
                            {
                                "title": "Lookup Contacts",
                                "description": "view the contacts",
                                "endPoint": "/connect/contactLookup",
                                "Icon": "fa fa-search",
                                "api": "post/connect/lookup"
                            }
                        ]
                    },
                    {
                        "title": "Email",
                        "route": "/connect/manageEmail",
                        "apis": ["post/connect/email", "get/connect/email"],
                        "subgroupLinks": [
                            {
                                "title": "Search/ View sent Emails",
                                "description": "search/ view sent emails",
                                "endPoint": "/connect/manageEmail",
                                "Icon": "fa fa-search",
                                "api": "get/connect/email"
                            },
                            {
                                "title": "Send new Email",
                                "description": "send new email",
                                "endPoint": "/connect/newEmail",
                                "Icon": "fa fa-plus",
                                "api": "post/connect/email"
                            }
                        ]
                    },
                    {
                        "title": "Notification",
                        "route": "/connect/notifications",
                        "apis": ["post/connect/sms", "get/connect/sms"],
                        "subgroupLinks": [
                            {
                                "title": "Search Notification",
                                "description": "search / view Notifications",
                                "endPoint": "/connect/notifications",
                                "Icon": "fa fa-search",
                                "api": "get/connect/sms"
                            },
                            {
                                "title": "Add Notification",
                                "description": "search / view Notifications",
                                "endPoint": "/connect/notifications",
                                "Icon": "",
                                "api": ""
                            }
                        ]
                    },
                    {
                        "title": "Meeting",
                        "route": "/connect/msTeams/meetings",
                        "apis": "",
                        "subgroupLinks": [
                            {
                                "title": "Search/ View Meetings",
                                "description": "search/ view meetings",
                                "endPoint": "/connect/msTeams/meetings",
                                "Icon": "fa fa-search",
                                "api": "get/connect/email"
                            },
                            {
                                "title": "Schedule Meeting",
                                "description": "schedule meeting",
                                "endPoint": "/connect/msTeams/scheduleMeeting",
                                "Icon": "fa fa-clock-o",
                                "api": "post/connect/email"
                            }
                        ]
                    },
                    {
                        "title": "SMS",
                        "route": "/connect/manageSMS",
                        "apis": ["post/connect/sms", "get/connect/sms"],
                        "subgroupLinks": [
                            {
                                "title": "Search/ View sent SMS",
                                "description": "search/ view sent SMS",
                                "endPoint": "/connect/manageSMS",
                                "Icon": "fa fa-search",
                                "api": "get/connect/sms"
                            },
                            {
                                "title": "Send new SMS",
                                "description": "send new SMS",
                                "endPoint": "/connect/newSMS",
                                "Icon": "fa fa-plus",
                                "api": "post/connect/sms"
                            },
                            {
                                "title": "Manage SMS Templates",
                                "description": "Manage SMS Templates",
                                "endPoint": "/connect/manageSMSTemplate",
                                "Icon": "fa fa-edit",
                                "Style": "text-align: center;",
                                "api": "get/connect/SMSTemplate"
                            }
                        ]
                    },
                    {
                        "title": "Subscriptions",
                        "route": "/connect/manageSubscriptions",
                        "apis": ["get/acad/lms/questions"],
                        "subgroupLinks": [
                            {
                                "title": "Search/ View Subscriptions",
                                "description": "search/ view subscriptions",
                                "endPoint": "/connect/manageSubscriptions",
                                "Icon": "fa fa-search",
                                "api": "get/acad/lms/questions"
                            },
                            {
                                "title": "",
                                "description": "",
                                "endPoint": "",
                                "Icon": "",
                                "api": ""
                            }
                        ]
                    },
                    {
                        "title": "Publish-Topic",
                        "route": "/connect/manageTopics",
                        "apis": ["get/acad/lms/questions"],
                        "subgroupLinks": [
                            {
                                "title": "Search/ View Topic",
                                "description": "search/ view topic",
                                "endPoint": "/connect/manageTopics",
                                "Icon": "fa fa-search",
                                "api": "get/acad/lms/questions"
                            },
                            {
                                "title": "Add new Topic",
                                "description": "add new topic",
                                "endPoint": "/connect/addTopic",
                                "Icon": "",
                                "api": "post/acad/lms/questions"
                            }
                        ]
                    },
                    {
                        "title": "Website",
                        "route": "/connect/website",
                        "apis": "",
                        "subgroupLinks": [
                            {
                                "title": "Search/View TFTD",
                                "description": "search/view TFTD",
                                "endPoint": "/connect/website",
                                "Icon": "fa fa-search",
                                "api": "get/connect/website"
                            }
                        ]
                    }]
            }]
    },
    "Fees": {
        "col2": [
            {
                "id": "a1",
                "groupType": "Report",
                "title": "Fees Setup",
                "groupLinks": [
                    // {
                    // 	"title": "Concession (WIP)",
                    // 	"description": "view the Concession for the academic year",
                    // 	"endPoint": "/fees/feesConcessionReport"
                    // },
                    // {
                    // 	"title": "Fee Breakups",
                    // 	"description": "view the Fee Breakups for the academic year",
                    // 	"endPoint": "/fees/feesBreakupsReport"
                    // },
                    {
                        "title": "Fees Structure",
                        "description": "view the fee structure for the academic year",
                        "endPoint": "/fees/feesStructureReport"
                    },
                    {
                        "title": "Fees Structure Coursewise",
                        "description": "view the coursewise fee structure for the academic year",
                        "endPoint": "/fees/coursewiseFeesStructureReport"
                    },
                    {
                        "title": "Fees Expected",
                        "description": "view the fees expected for the academic year",
                        "endPoint": "/fees/feesExpectedReport"
                    }
                    // {
                    // 	"title": "Fees Time Line",
                    // 	"description": "view the Fees Time Line for the academic year",
                    // 	"endPoint": "/fees/feesTimeLineReport"
                    // }
                ]
            },
            {
                "id": "a2",
                "groupType": "Report",
                "title": "Fees Exception Reports",
                "groupLinks": [
                    {
                        "title": "Defaulters List",
                        "description": "view the defaulters list for the academic year",
                        "endPoint": "/fees/feesDefaultersListReport"
                    }
                ]
            },
            // {
            // 	"id": "a3",
            // 	"groupType": "Report",
            // 	"title": "Accounting Reports",
            // 	"groupLinks": [
            // 		{
            // 			"title": "Student Fee Transaction ",
            // 			"description": "view the student fee transaction",
            // 			"endPoint": "/fees/transactionReport"
            // 		}
            // 	]
            // }
        ],
        "col3": [{
            "id": "b1",
            "groupType": "Report",
            "title": "Fees Transaction",
            "groupLinks": [
                {
                    "title": "Adjustment Balance",
                    "description": "view the adjustment balance",
                    "endPoint": "/fees/adjustmentBalanceReport"
                },
                {
                    "title": "Adjustment Transaction",
                    "description": "view the adjustment transactions",
                    "endPoint": "/fees/adjustmentTransactionReport"
                },
                {
                    "title": "Advance Balance",
                    "description": "view the advance balance as on March 31st",
                    "endPoint": "/fees/advanceBalanceReport"
                },
                {
                    "title": "Fee Collection Datewise / Monthwise",
                    "description": "view the datewise / monthwise fee collection",
                    "endPoint": "/fees/feesCollectedReport"
                },
                {
                    "title": "Fees Paid After Academic Year",
                    "description": "view the fees paid by students after the academic year",
                    "endPoint": "/fees/feesPaidAfterAcadYearReport"
                },
                {
                    "title": "Fees Paid By TC Students",
                    "description": "view the fees paid by TC students",
                    "endPoint": "/fees/feesPaidByTCStudentsReport"
                },
                {
                    "title": "Fees Paid List",
                    "description": "view the fees paid by students for the academic year",
                    "endPoint": "/fees/feesPaidReport"
                },
                // {
                // 	"title": "Fees Expected",
                // 	"description": "view the fees expected for the academic year",
                // 	"endPoint": "/fees/feesExpectedReport"
                // },
                // {
                // 	"title": "Fine Collected (WIP)",
                // 	"description": "view the fine collected for the academic year",
                // 	"endPoint": "/fees/feesFineCollectedReport"
                // },
                // {
                // 	"title": "Late Payments (WIP)",
                // 	"description": "view the late payments for the academic year",
                // 	"endPoint": "/fees/feesLatePaymentsReport"
                // },
                {
                    "title": "Payment Mode Breakup",
                    "description": "view the payment mode breakup report",
                    "endPoint": "/fees/paymentModeBreakupReport"
                },
                {
                    "title": "Receipt Transaction",
                    "description": "view the datewise receipt transaction",
                    "endPoint": "/fees/feesReceiptTransactionReport"
                },
                {
                    "title": "Student Transaction",
                    "description": "view the transactions for a student",
                    "endPoint": "/fees/studentTransactionReport"
                },
                {
                    "title": "Transaction History",
                    "description": "view fees transaction history of the institute",
                    "endPoint": "/fees/transactionHistoryReport"
                }
            ]
        }],
        "col1": [
            {
                "id": "c1",
                "groupType": "ActionLink",
                "title": "Action Links",
                "subGroup": [
                    {
                        "title": "Fees",
                        "route": "/fees/manage",
                        "apis": ["post/fee", "get/fee"],
                        "subgroupLinks": [
                            {
                                "title": "Search/Edit Fee",
                                "description": "",
                                "endPoint": "/fees/manage",
                                "Icon": "fa fa-search",
                                "api": "get/fee"
                            },
                            {
                                "title": "Add fee",
                                "description": "",
                                "endPoint": "/fees/add",
                                "Icon": "fa fa-plus",
                                "api": "post/fee"
                            }
                        ]
                    }, {
                        "title": "Transact",
                        "route": "/fees/transactions",
                        "apis": ["post/fee/transact", "get/fee/transact"],
                        "subgroupLinks": [
                            {
                                "title": "Search/View transaction",
                                "description": "",
                                "endPoint": "/fees/transactions",
                                "Icon": "fa fa-search",
                                "api": "get/fee/transact"
                            },
                            {
                                "title": "New transaction",
                                "description": "",
                                "endPoint": "/fees/transactions/add",
                                "Icon": "fa fa-plus",
                                "api": "post/fee/transact"
                            }
                        ]
                    }]
            }, {
                "id": "c2",
                "groupType": "ActionLink",
                "title": "Notify",
                "subGroup": [
                    {
                        "title": "Defaulters List",
                        "apis": [],
                        "subgroupLinks": [
                            {
                                "title": "Migration in Process",
                                "description": "",
                                "endPoint": "",
                                "Icon": "fa fa-mobile fa-lg",
                                "api": ""
                            },
                            {
                                "title": "Migration in Process",
                                "description": "",
                                "endPoint": "",
                                "Icon": "fa fa-envelope-o fa-lg",
                                "api": ""
                            }, {
                                "title": "Migration in Process",
                                "description": "",
                                "endPoint": "",
                                "Icon": "fa fa-bell-o fa-lg",
                                "api": ""
                            }
                        ]
                    }]
            }]
    },
    "LMS": {
        "col2": [
        ],
        "col3": [
        ],
        "col1": [
            {
                "id": "c1",
                "groupType": "ActionLink",
                "title": "LMS",
                "subGroup": [
                    {
                        "title": "Content",
                        "route": "/lms/curriculum",
                        "apis": ["get/acad/lms/questions"],
                        "subgroupLinks": [
                            {
                                "title": "Manage/Edit Content",
                                "description": "",
                                "endPoint": "/lms/curriculum",
                                "Icon": "fa fa-search",
                                "api": "get/acad/lms/questions"
                            },
                            {
                                "title": "Add Content",
                                "description": "",
                                "endPoint": "/lms/curriculum/add",
                                "Icon": "fa fa-plus",
                                "api": "get/acad/lms/question"
                            }
                        ]
                    },
                    {
                        "title": "QA",
                        "route": "/lms/qa",
                        "apis": ["get/acad/lms/questions"],
                        "subgroupLinks": [
                            {
                                "title": "Manage/Edit Questions",
                                "description": "",
                                "endPoint": "/lms/qa",
                                "Icon": "fa fa-search",
                                "api": "get/acad/lms/questions"
                            },
                            {
                                "title": "Add Question",
                                "description": "",
                                "endPoint": "/lms/qa/add",
                                "Icon": "fa fa-plus",
                                "api": "get/acad/lms/question"
                            }
                        ]
                    },
                    {
                        "title": "Assessment Setup",
                        "route": "/lms/assessment/manage",
                        "apis": ["get/acad/assessment"],
                        "subgroupLinks": [
                            {
                                "title": "Manage/Edit Assessment",
                                "description": "",
                                "endPoint": "/lms/assessment/manage",
                                "Icon": "fa fa-search",
                                "api": "get/acad/assessment"
                            },
                            {
                                "title": "Add Assessment",
                                "description": "",
                                "endPoint": "/lms/assessment/add",
                                "Icon": "fa fa-plus",
                                "api": "get/acad/assessment"
                            }
                        ]
                    },
                    {
                        "title": "Assessments",
                        "route": "/lms/assessment/taking",
                        "apis": ["get/acad/assessment/publishedAssessments"],
                        "subgroupLinks": [
                            {
                                "title": "Take Assessment",
                                "description": "",
                                "endPoint": "/lms/assessment/taking",
                                "Icon": "fa fa-search",
                                "api": "get/acad/assessment/publishedAssessments"
                            }
                        ]
                    },
                    {
                        "title": "Study",
                        "route": "/lms/curriculum/study",
                        "apis": ["get/acad/assessment/publishedAssessments"],
                        "subgroupLinks": [
                            {
                                "title": "Study",
                                "description": "",
                                "endPoint": "/lms/curriculum/study",
                                "Icon": "fa fa-search",
                                "api": "get/acad/assessment/publishedAssessments"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    "Vault": {
        "col1": [
            {
                "id": "a1",
                "groupType": "Report",
                "title": "Report",
                "groupLinks": [
                    {
                        "title": "Artefact list",
                        "description": "view the report for list of artefacts",
                        "endPoint": "/vault/report/artefactList"
                    }
                ]
            }
        ],
        "col2": [],
        "col3": [
            {
                "id": "c1",
                "groupType": "ActionLink",
                "title": "Vault",
                "subGroup": [
                    {
                        "title": "Artefact",
                        "route": "/vault/artefact/manage",
                        "apis": ["get/arte/artefact", "post/arte/artefact/upsert", "post/acad/artefact/StudentAttendanceTemplate"],
                        "subgroupLinks": [
                            {
                                "title": "Search artefacts",
                                "description": "",
                                "endPoint": "/vault/artefact/manage",
                                "Icon": "fa fa-search",
                                "api": "get/arte/artefact"
                            },
                            {
                                "title": "Upload artefact",
                                "description": "",
                                "endPoint": "/vault/artefact/add",
                                "Icon": "fa fa-plus",
                                "api": "post/arte/artefact/upsert"
                            },
                            {
                                "title": "Download published",
                                "description": "",
                                "endPoint": "/vault/artefact/download",
                                "Icon": "fa fa-download",
                                "api": "post/acad/artefact/StudentAttendanceTemplate"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    "HR": {
        "col2": [
        ],
        "col3": [
        ],
        "col1": [
            {
                "id": "c1",
                "groupType": "ActionLink",
                "title": "HR",
                "subGroup": [
                    {
                        "title": "Attendance",
                        "route": "/hr/staff/attendance",
                        "apis": ["post/staff", "get/staff"],
                        "subgroupLinks": [
                            {
                                "title": "Search / Edit",
                                "description": "",
                                "endPoint": "/hr/staff/attendance",
                                "Icon": "fa fa-search",
                                "api": "get/staff"
                            }
                        ]
                    },
                    {
                        "title": "HR",
                        "route": "/hr/staff/manage",
                        "apis": ["post/staff", "get/staff"],
                        "subgroupLinks": [
                            {
                                "title": "Search/Edit HR",
                                "description": "",
                                "endPoint": "/hr/staff/manage",
                                "Icon": "fa fa-search",
                                "api": "get/staff"
                            },
                            {
                                "title": "Add HR",
                                "description": "",
                                "endPoint": "/hr/staff/add",
                                "Icon": "fa fa-plus",
                                "api": "post/staff"
                            }
                        ]
                    },
                    // {
                    //     "title": "Salary Structure",
                    //     "route": "/hr/staff/salaryStructure",
                    //     "apis": ["post/staff", "get/staff"],
                    //     "subgroupLinks": [
                    //         {
                    //             "title": "Add / Manage",
                    //             "description": "",
                    //             "endPoint": "/hr/staff/salaryStructure",
                    //             "Icon": "fa fa-search",
                    //             "api": "get/staff"
                    //         }
                    //     ]
                    // }
                
                    {
                        "title": "Payslips",
                        "route": "/hr/payslip",
                        "apis": ["post/staff", "get/staff"],
                        "subgroupLinks": [
                            {
                                "title": "Search / Edit",
                                "description": "",
                                "endPoint": "/hr/payslip",
                                "Icon": "fa fa-search",
                                "api": "get/staff"
                            }
                        ]
                    }
                ]
            }
        ]
    },
    "Library": {
        "col2": [
            {
                "id": "a1",
                "groupType": "Report",
                "title": "Procurement (WIP)",
                "groupLinks": [
                    {
                        "title": "Vendor management ",
                        "description": "manage vendors",
                        "endPoint": "/library/vendorManagement",
                        "WIP": true
                    },
                    {
                        "title": "Subscription management",
                        "description": "manage subscriptions",
                        "endPoint": "/library/subscriptionManagement",
                        "WIP": true
                    }
                ]
            },
            {
                "id": "a2",
                "groupType": "Report",
                "title": "Catalog",
                "groupLinks": [
                    {
                        "title": "Print barcodes",
                        "description": "print barcodes for materials",
                        "endPoint": "/library/barcode",
                        "WIP": false
                    },
                    {
                        "title": "Summary report",
                        "description": "view the summary report for library",
                        "endPoint": "/library/summaryReport",
                        "WIP": false
                    },
                    {
                        "title": "Members report",
                        "description": "view the report of library members",
                        "endPoint": "/library/membersReport",
                        "WIP": true
                    },
                    {
                        "title": "Reference materials report",
                        "description": "view the report for reference materials",
                        "endPoint": "/library/referenceMaterialsReport",
                        "WIP": true
                    }
                ]
            },
            {
                "id": "a3",
                "groupType": "Report",
                "title": "House-keeping",
                "groupLinks": [
                    {
                        "title": "Materials under maintenance report",
                        "description": "view the report for materials under maintenance",
                        "endPoint": "/library/maintenanceReport",
                        "WIP": false
                    },
                    {
                        "title": "Materials due for maintenance",
                        "description": "view the report for materials which are due for maintenance",
                        "endPoint": "/library/maintenanceDueReport",
                        "WIP": true
                    },
                    {
                        "title": "Last monitored report",
                        "description": "view the last monitored report for materials",
                        "endPoint": "/library/lastMonitoredReport",
                        "WIP": true
                    },
                    {
                        "title": "Lost materials report",
                        "description": "view the report for materials which are lost",
                        "endPoint": "/library/lostMaterialsReport",
                        "WIP": true
                    }
                ]
            }

        ],
        "col3": [
            {
                "id": "b1",
                "groupType": "Report",
                "title": "Circulation",
                "groupLinks": [
                    {
                        "title": "Materials under circulation report",
                        "description": "view the report for materials in circulation",
                        "endPoint": "/library/circulationReport",
                        "WIP": false
                    },
                    {
                        "title": "Over-due report",
                        "description": "view the report for materials in circulation which are over-due",
                        "endPoint": "/library/overDueReport",
                        "WIP": true
                    }
                ]
            },
            {
                "id": "b2",
                "groupType": "Report",
                "title": "Reports",
                "groupLinks": [
                    {
                        "title": "Age analysis",
                        "description": "view the report for age of the materials",
                        "endPoint": "/library/ageAnalysisReport",
                        "WIP": true
                    },
                    {
                        "title": "Valuation report",
                        "description": "view the report for materials Valuation",
                        "endPoint": "/library/valuationReport",
                        "WIP": true
                    },
                    {
                        "title": "Late fees report",
                        "description": "view the report for late payment of library fees",
                        "endPoint": "/library/lateFeesReport",
                        "WIP": true
                    },
                    {
                        "title": "Books without ISBN",
                        "description": "view the report for books without ISBN",
                        "endPoint": "/library/booksWithoutISBNReport",
                        "WIP": false
                    },
                    {
                        "title": "Books without synopsis",
                        "description": "view the report for books without synopsis",
                        "endPoint": "/library/booksWithoutSynopsisReport",
                        "WIP": true
                    },
                    {
                        "title": "Books without cover photo",
                        "description": "view the report for books without cover photo",
                        "endPoint": "/library/booksWithoutCoverPhotoReport",
                        "WIP": true
                    },
                    {
                        "title": "Unverified stock",
                        "description": "view the report of unverified materials",
                        "endPoint": "/library/unverifiedStockReport",
                        "WIP": false
                    }
                ]
            }
        ],
        "col1": [
            {
                "id": "c1",
                "groupType": "ActionLink",
                "title": "Action Links",
                "subGroup": [
                    {
                        "title": "Classification",
                        "route": "",
                        "apis": [],
                        "subgroupLinks": [
                            {
                                "title": "Migration in Process",
                                "description": "",
                                "endPoint": "",
                                "Icon": "fa fa-barcode fa-lg",
                                "api": ""
                            }
                        ]
                    },
                    {
                        "title": "Catalog",
                        "route": "/library/manage",
                        "apis": ["post/library/material/add", "get/library/material"],
                        "subgroupLinks": [
                            {
                                "title": "Search/Edit library material",
                                "description": "",
                                "endPoint": "/library/manage",
                                "Icon": "fa fa-search",
                                "api": "get/library/material"
                            },
                            {
                                "title": "Add library material",
                                "description": "",
                                "endPoint": "/library/add",
                                "Icon": "fa fa-plus",
                                "api": "post/library/material/add"
                            }
                        ]
                    },
                    {
                        "title": "Circulation",
                        "route": "/library/circulation",
                        "apis": ["post/library/material/issue", "post/library/material/return"],
                        "subgroupLinks": [
                            {
                                "title": "Issue/Return",
                                "description": "",
                                "endPoint": "/library/circulation",
                                "Icon": "fa fa-retweet fa-lg",
                                "api": "post/library/material/issue"
                            },
                            {
                                "title": "SettMigration in Processings",
                                "description": "",
                                "endPoint": "",
                                "Icon": "fa fa-cog fa-fw fa-lg",
                                "api": ""
                            }
                        ]
                    },
                    {
                        "title": "House-keeping",
                        "route": "",
                        "apis": [],
                        "subgroupLinks": [
                            {
                                "title": "Migration in Process",
                                "description": "",
                                "endPoint": "",
                                "Icon": "fa fa-wrench fa-lg",
                                "api": ""
                            },
                            {
                                "title": "Migration in Process",
                                "description": "",
                                "endPoint": "",
                                "Icon": "fa fa-trash fa-lg",
                                "api": ""
                            }
                        ]
                    },
                    {
                        "title": "Stock Verification",
                        "route": "/library/stockVerification",
                        "apis": ["post/library/material/verifyStock"],
                        "subgroupLinks": [
                            {
                                "title": "Verify Stock",
                                "description": "",
                                "endPoint": "/library/stockVerification",
                                "Icon": "fa fa-search",
                                "api": "post/library/material/verifyStock"
                            }
                        ]
                    }
                ]
            },
            {
                "groupType": "ActionLink",
                "title": "Notify",
                "groupLinks": [],
                "subGroup": [
                    {
                        "title": "Defaulters",
                        "apis": [],
                        "subgroupLinks": [
                            {
                                "title": "Migration in Process",
                                "description": "",
                                "endPoint": "",
                                "Icon": "fa fa-mobile fa-lg",
                                "api": ""
                            },
                            {
                                "title": "Migration in Process",
                                "description": "",
                                "endPoint": "",
                                "Icon": "fa fa-envelope-o fa-lg",
                                "api": ""
                            },
                            {
                                "title": "Migration in Process",
                                "description": "",
                                "endPoint": "",
                                "Icon": "fa fa-bell-o fa-lg",
                                "api": ""
                            }
                        ]
                    },
                    {
                        "title": "Reminders",
                        "apis": [],
                        "subgroupLinks": [
                            {
                                "title": "Migration in Process",
                                "description": "",
                                "endPoint": "",
                                "Icon": "fa fa-mobile fa-lg",
                                "api": ""
                            },
                            {
                                "title": "Migration in Process",
                                "description": "",
                                "endPoint": "",
                                "Icon": "fa fa-envelope-o fa-lg",
                                "api": ""
                            },
                            {
                                "title": "Migration in Process",
                                "description": "",
                                "endPoint": "",
                                "Icon": "fa fa-bell-o fa-lg",
                                "api": ""
                            }
                        ]
                    },
                    {
                        "title": "Over Due",
                        "apis": [],
                        "subgroupLinks": [
                            {
                                "title": "Migration in Process",
                                "description": "",
                                "endPoint": "",
                                "Icon": "fa fa-mobile fa-lg",
                                "api": ""
                            },
                            {
                                "title": "Migration in Process",
                                "description": "",
                                "endPoint": "",
                                "Icon": "fa fa-envelope-o fa-lg",
                                "api": ""
                            },
                            {
                                "title": "Migration in Process",
                                "description": "",
                                "endPoint": "",
                                "Icon": "fa fa-bell-o fa-lg",
                                "api": ""
                            }
                        ]
                    }
                ]
            }
        ]
    },
    "RC": {
        "col1": [
            {
                "id": "a1",
                "groupType": "Report",
                "title": "Reports",
                "groupLinks": [
                    {
                        "title": "Admission List (WIP)",
                        "description": "view report for admission list for academic year",
                        "endPoint": "/regulatoryCompliance/admissionReport"
                    },
                    {
                        "title": "Department report",
                        "description": "view report for total marks and grade secured by each student for academic year",
                        "endPoint": "/regulatoryCompliance/departmentReport"
                    },
                ]
            },
            {
                "id": "a2",
                "groupType": "Report",
                "title": "NIRF Reports",
                "groupLinks": [
                    {
                        "title": "Annual capital expenditure",
                        "description": "view report for annual capital expenditure",
                        "endPoint": "/regulatoryCompliance/capitalExpenditure"
                    },
                    {
                        "title": "Annual operational expenditure",
                        "description": "view report for annual operational expenditure",
                        "endPoint": "/regulatoryCompliance/operationalExpenditure"
                    },
                    {
                        "title": "Faculty details",
                        "description": "view report for faculty details",
                        "endPoint": "/regulatoryCompliance/facultyDetails"
                    },
                    {
                        "title": "Financial resources",
                        "description": "view report for utilised amount for the capital",
                        "endPoint": "/regulatoryCompliance/financialResources"
                    },
                    {
                        "title": "PCS facilities: ",
                        "description": "view report for sanctioned or approved intake",
                        "endPoint": "/regulatoryCompliance/pcsFacilities"
                    },
                    {
                        "title": "Placement and higher studies",
                        "description": "view report for details about placement and higher studies",
                        "endPoint": "/regulatoryCompliance/placement"
                    },
                    {
                        "title": "Sanctioned (Approved) intake",
                        "description": "view report for sanctioned or approved intake",
                        "endPoint": "/regulatoryCompliance/sanctionedIntake"
                    },
                    {
                        "title": "Student strength",
                        "description": "view report for total of actual student strength",
                        "endPoint": "/regulatoryCompliance/studentStrength"
                    }
                ]
            }
        ],
        "col2": [],
        "col3": [
            {
                "id": "c1",
                "groupType": "ActionLink",
                "title": "Action Links",
                "subGroup": [
                    {
                        "title": "TC Book",
                        "subgroupLinks": [
                            {
                                "title": "Migration in Process",
                                "description": "",
                                "endPoint": "",
                                "Icon": "fa fa-search"
                            }
                        ]
                    },
                    {
                        "title": "Fee Receipts",
                        "subgroupLinks": [
                            {
                                "title": "Migration in Process",
                                "description": "",
                                "endPoint": "",
                                "Icon": "fa fa-search"
                            }
                        ]
                    }
                ]
            }]
    },
    "Receipts": {
        "col2": [
            {
                "id": "a1",
                "groupType": "Report",
                "title": "Receipts",
                "groupLinks": [
                    {
                        "title": "Detailed report",
                        "description": "view the details report of receipts within the academic year",
                        "endPoint": "/receipts/detailedReport"
                    },
                    {
                        "title": "Revenue sharing report",
                        "description": "view the report for revenue sharing within the academic year",
                        "endPoint": "/receipts/revenueSharingReport"
                    },
                    {
                        "title": "Summary report",
                        "description": "view the summary report for receipts within the academic year",
                        "endPoint": "/receipts/receiptsSummaryReport"
                    }
                ]
            }
        ],
        "col3": [],
        "col1": [
            {
                "id": "c1",
                "groupType": "ActionLink",
                "title": "Action Links",
                "subGroup": [
                    {
                        "title": "Receipts",
                        "route": "/receipts/manage",
                        "apis": ["post/gr", "get/gr"],
                        "subgroupLinks": [
                            {
                                "title": "Search / Edit Receipts",
                                "description": "Search / Edit Receipts",
                                "endPoint": "/receipts/manage",
                                "Icon": "fa fa-search",
                                "api": "get/gr"
                            },
                            {
                                "title": "Add / Create Receipt",
                                "description": "Add / Create Receipt",
                                "endPoint": "/receipts/add",
                                "Icon": "fa fa-plus",
                                "api": "post/gr"
                            }
                        ]
                    }
                ]
            }]
    }
}

export { userLinks }