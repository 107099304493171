import { Action } from '@ngrx/store';
import { type } from '../../../util';

export const ActionTypes = {
    SET_MEETINGS: type('[Google Meet] Set Meetings'),
    GET_MEETINGS: type('[Google Meet] Get Meetings'),
    SET_STUDENT_PROFILE: type('[Google Meet] Set Student Profile'),
    SET_SELECTED_DATE: type('[Google Meet] Set Selected Date'),
    RESET_SELECTED_MEETINGS: type('[Google Meet] Reset Selected Meetings')
};

export class SetMeetings implements Action {
    type = ActionTypes.SET_MEETINGS;
    constructor(public payload: any) { }
}

export class GetMeetings implements Action {
    type = ActionTypes.GET_MEETINGS;
    constructor(public payload: any) { }
}

export class SetStudentProfile implements Action {
    type = ActionTypes.SET_STUDENT_PROFILE;
    constructor(public payload: any) { }
}

export class SetSelectedDate implements Action {
    type = ActionTypes.SET_SELECTED_DATE;
    constructor(public payload: any) { }
}

export class ResetSelectedMeetings implements Action {
    type = ActionTypes.RESET_SELECTED_MEETINGS;
    constructor(public payload: any) { }
}

export type Actions = SetMeetings | GetMeetings | SetStudentProfile | SetSelectedDate | ResetSelectedMeetings;
