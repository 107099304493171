import { Action } from '@ngrx/store';
import { type } from '../../../app/util';

export const ActionTypes = {
    SET_TT_INPUT: type('[TT Input] Set TT Input'),
    SET_STAFF_COUNT: type('[TT Input] Set Staff Count'),
    SET_SUBJECTS_COUNT: type('[TT Input] Set Subjects Count'),
    SET_WEEKLY_PATTERN: type('[TT Input] Set Weekly Pattern'),
    SET_MAX_PPW: type('[TT Input] Set Max PPW'),
    SET_STAFF_SUBJECT_FLEXIBLE: type('[TT Input] Set Staff Subject Flexible'),
    SET_HTML: type('[Timetable] Set HTML'),
    SET_TT_MODEL: type('[Timetable Planner] Set Timetable Model'),
    SET_SAMPLE_CSV: type('[Timetable Planner] Set Sample CSV'),
    SET_UPLOADED_CSV: type('[Timetable Planner] Set Uploaded CSV'),
    SET_SECTION_NAMES: type('[TT Input] Set Section Names'),
    SET_STAFF_NAMES: type('[TT Input] Set Staff Names'),
    SET_SUBJECT_NAMES: type('[TT Input] Set Subject Names'),
    SET_SUBJECT_ROWS: type('[TT Matrix] Set Subject Rows'),
    SET_STAFF_ROWS: type('[TT Matrix] Set Staff Rows'),
    SET_ADDITIONAL_STAFF_ROWS: type('[TT Matrix] Set Additional Staff Rows'),
    SET_ADDITIONAL_STAFF_NAMES: type('[TT Input] Set Additional Staff Names'),
    CLEAR_UPLOADED_OR_SAMPLE_CSV: type('[TT Matrix] Clear Uploaded or Sample CSV'),
    MATRIX_CHANGE_SET: type('[TT Matrix] Matrix Change Set'),
    CSV_UPLOADED: type('[Timetable Planner] CSV Uploaded'),
    SET_MATRIX_VIEW: type('[TT Matrix] Set Matrix View'),
    RESET_TT: type('[Timetable Planner] Reset Timetable'),
    GET_TT_INPUT: type('[TT Input] Get TT Input'),
    SET_VIEW: type('[Timetable Planner] Set View'),
    SEC_SUB_STF_NAMES_SET: type('[TT Input] Sec Sub Stf Names Set'),
    SHOW_PERIODS: type('[Timetable Planner] Show Periods'),
    SHOW_LOCATIONS: type('[Timetable Planner] Show Locations'),
   // SHOW_SUBJECT_WEIGHT: type('[Timetable Planner] Show Subject Weight'),
    CHANGE_SUBJECT_WEIGHT: type('[Timetable] Change Weight'),
    GENERATE_TT: type('[Timetable Planner] Generate TT'),
    ASSIGN_SUBJECT_WEIGHT_PPW: type('[Timetable Planner] Assign Subject Weight PPW')
}

export class SetSampleCSV implements Action {
    type = ActionTypes.SET_SAMPLE_CSV;
    constructor(public payload: any) { }
}

export class SetUploadedCSV implements Action {
    type = ActionTypes.SET_UPLOADED_CSV;
    constructor(public payload: any) { }
}

export class SetTTInput implements Action {
    type = ActionTypes.SET_TT_INPUT;
    constructor(public payload: any) { }
}

export class SetHTML implements Action {
    type = ActionTypes.SET_HTML;
    constructor(public payload: any) { }
}

export class SetTTModel implements Action {
    type = ActionTypes.SET_TT_MODEL;
    constructor(public payload: any) { }
}

export class SetSectionNames implements Action {
    type = ActionTypes.SET_SECTION_NAMES;
    constructor(public payload: any) { }
}

export class SetStaffNames implements Action {
    type = ActionTypes.SET_STAFF_NAMES;
    constructor(public payload: any) { }
}

export class SetSubjectNames implements Action {
    type = ActionTypes.SET_SUBJECT_NAMES;
    constructor(public payload: any) { }
}

export class SetAdditionalStaffNames implements Action {
    type = ActionTypes.SET_ADDITIONAL_STAFF_NAMES;
    constructor(public payload: any) { }
}

export class SetSubjectRows implements Action {
    type = ActionTypes.SET_SUBJECT_ROWS;
    constructor(public payload: any) { }
}

export class SetStaffRows implements Action {
    type = ActionTypes.SET_STAFF_ROWS;
    constructor(public payload: any) { }
}

export class SetAdditionalStaffRows implements Action {
    type = ActionTypes.SET_ADDITIONAL_STAFF_ROWS;
    constructor(public payload: any) { }
}

export class ClearUploadedOrSampleCSV implements Action {
    type = ActionTypes.CLEAR_UPLOADED_OR_SAMPLE_CSV;
    constructor(public payload: any) { }
}

export class MatrixChangeSet implements Action {
    type = ActionTypes.MATRIX_CHANGE_SET;
    constructor(public payload: any) { }
}

export class CSVUploaded implements Action {
    type = ActionTypes.CSV_UPLOADED;
    constructor(public payload: any) { }
}

export class SetMatrixView implements Action {
    type = ActionTypes.SET_MATRIX_VIEW;
    constructor(public payload: any) { }
}

export class ResetTT implements Action {
    type = ActionTypes.RESET_TT;
    constructor(public payload: any) { }
}

export class GetTTInput implements Action {
    type = ActionTypes.GET_TT_INPUT;
    constructor(public payload: any) { }
}

export class SetView implements Action {
    type = ActionTypes.SET_VIEW;
    constructor(public payload: any) { }
}

export class SecSubStfNamesSet implements Action {
    type = ActionTypes.SEC_SUB_STF_NAMES_SET;
    constructor(public payload: any) { }
}

export class ShowPeriods implements Action {
    type = ActionTypes.SHOW_PERIODS;
    constructor(public payload: any) { }
}

export class ShowLocations implements Action {
    type = ActionTypes.SHOW_LOCATIONS;
    constructor(public payload: any) { }
}

export class ChangeSubjectWeight implements Action {
    type = ActionTypes.CHANGE_SUBJECT_WEIGHT;
    constructor(public payload: any) { }
}

// export class ShowSubjectWeight implements Action {
//     type = ActionTypes.SHOW_SUBJECT_WEIGHT;
//     constructor(public payload: any) { }
// }

export class GenerateTT implements Action {
    type = ActionTypes.GENERATE_TT;
    constructor(public payload: any) { }
}

export class AssignSubjectWeightPPW implements Action {
    type = ActionTypes.ASSIGN_SUBJECT_WEIGHT_PPW;
    constructor(public payload: any) { }
}

export type Actions
    = SetHTML | SetTTModel | SetTTInput | SetSampleCSV | SetUploadedCSV | SetSectionNames | SetStaffNames | SetSubjectNames | SetSubjectRows | ClearUploadedOrSampleCSV | MatrixChangeSet | CSVUploaded | SetMatrixView | ResetTT | GetTTInput | SetView | SetStaffRows | SecSubStfNamesSet | SetAdditionalStaffNames | SetAdditionalStaffRows | ShowPeriods | ChangeSubjectWeight | AssignSubjectWeightPPW | GenerateTT | ShowLocations; //ShowSubjectWeight