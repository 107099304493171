import { Action } from '@ngrx/store';
import { type } from '../../../app/util';

export const ActionTypes = {
    CLONE_MATERIAL: type('[Library] Clone Material'),
    CREATE_MATERIAL: type('[Library] Create Material'),
    DISABLE_MATERIAL: type('[Library] Disable Material'),
    ENABLE_MATERIAL: type('[Library] Enable Material'),
    ISSUE_MATERIAL: type('[Library] Issue Material'),
    MATERIAL_MAINTENANCE: type('[Library] Material Maintenance'),
    PRINT_BARCODES: type('[Library] Print Barcodes'),
    SET_ATTRIBUTES: type('[Library] Set Attributes'),
    SET_LIBRARY_CONTEXT: type('[Library] Set Library Context'),
    SET_MATERIAL: type('[Library] Set Material'),
    SET_MEMBER: type('[Library] Set Member'),
    RESET_MEMBER: type('[Library] Reset Member'),
    RETURN_MAINTENANCE_MATERIAL: type('[Library] Return Maintenance Material'),
    SET_MATERIAL_TO_CIRCULATE: type('[Library] Set Material To Circulate'),
    SET_MATERIAL_TO_ISSUE: type('[Library] Set Material To Issue'),
    SET_MATERIAL_TO_RETURN_OR_REISSUE: type('[Library] Set Material To Return Or Reissue'),
    SET_SELECTED_MATERIAL: type('[Library] Set Selected Material'),
    SET_SELECTED_USER_LINK: type('[Library] Set Selected User Link'),
    SET_USER_LINKS: type('Library Set User Links'),
    SET_VALUE: type('[Library] Set Value'),
    SET_MATERIAL_PAGES: type('[Library] Set Pages'),
    SET_TAGS: type('[Library] Set Tags'),
    SET_HTML: type('[Library] Set HTML'),
    UPDATE_DASHBOARD: type('[Library] Update Dashboard'),
    UPDATE_ISBN: type('[Library] Update ISBN'),
    UPDATE_LOCATION: type('[Library] Update Location'),
    UPDATE_MATERIAL: type('[Library] Update Material'),
    SET_TOTAL_PAGES: type ('[Libary] Set Total Pages'),
    SET_MATERIAL_FOR_STOCK_VERIFICATION: type ('[Library] Set Material For Stock Verification'),
    SET_LIB_MANAGE_SEARCH: type('[Library] Set Lib Manage Search'),
    SET_CIRCULATION_SEARCH: type('[Library] Set Circulation Search'),
    SET_MANAGE_QUERY_STRING: type('[Library] Set Manage Query String'),
    SET_DUE_DATE: type('[Library] Set Due Date')
};

export class CloneMaterial implements Action {
    type = ActionTypes.CLONE_MATERIAL;
    constructor(public payload: any) { }
}

export class CreateMaterial implements Action {
    type = ActionTypes.CREATE_MATERIAL;
    /**"TrackingId": "", "Location":"", "Title":"", "Author":"", "Publisher":"", "ISBN":"" */
    constructor(public payload: any) { }
}

export class DisableMaterial implements Action {
    type = ActionTypes.DISABLE_MATERIAL;
    /**"Id": "" */
    constructor(public payload: any) { }
}

export class EnableMaterial implements Action {
    type = ActionTypes.ENABLE_MATERIAL;
    /**"Id": "" */
    constructor(public payload: any) { }
}

export class IssueMaterial implements Action {
    type = ActionTypes.ISSUE_MATERIAL;
    /**"Id": "" */
    constructor(public payload: any) { }
}

export class MaterialMaintenance implements Action {
    type = ActionTypes.MATERIAL_MAINTENANCE;
    /**"Id": "" */
    constructor(public payload: any) { }
}

export class PrintBarcodes implements Action {
    type = ActionTypes.PRINT_BARCODES;
    /**"TrackingIds: "" */
    constructor(public payload: any) { }
}

export class ResetMember implements Action {
    type = ActionTypes.RESET_MEMBER;
    constructor(public payload: any) { }
}

export class ReturnMaintenanceMaterial implements Action {
    type = ActionTypes.RETURN_MAINTENANCE_MATERIAL;
    constructor(public payload: any) { }
}

export class SetAttributes implements Action {
    type = ActionTypes.SET_ATTRIBUTES;
    /**"Id": "", "Attributes": {key: "" , value: ""} */
    constructor(public payload: any) { }
}

export class SetLibraryContext implements Action {
    type = ActionTypes.SET_LIBRARY_CONTEXT;
    /**"Id": "" */
    constructor(public payload: any) { }
}

export class SetMaterial implements Action {
    type = ActionTypes.SET_MATERIAL;
    constructor(public payload: any) { }
}

export class SetMember implements Action {
    type = ActionTypes.SET_MEMBER;
    constructor(public payload: any) { }
}

export class SetMaterialToCirculate implements Action {
    type = ActionTypes.SET_MATERIAL_TO_CIRCULATE;
    constructor(public payload: any) { }
}

export class SetMaterialToIssue implements Action {
    type = ActionTypes.SET_MATERIAL_TO_ISSUE;
    constructor(public payload: any) { }
}

export class SetMaterialToReturnReissue implements Action {
    type = ActionTypes.SET_MATERIAL_TO_RETURN_OR_REISSUE;
    constructor(public payload: any) { }
}

export class SetHTML implements Action {
    type = ActionTypes.SET_HTML;
    constructor(public payload: any) { }
}

export class SetSelectedMaterial implements Action {
    type = ActionTypes.SET_SELECTED_MATERIAL;
    /**"Id": "" */
    constructor(public payload: any) { }
}

export class SetSelectedUserLink implements Action {
    type = ActionTypes.SET_SELECTED_USER_LINK;
    /**"Id": "" */
    constructor(public payload: any) { }
}

export class SetTags implements Action {
    type = ActionTypes.SET_TAGS;
    /**"Id": "", "Tags": [] */
    constructor(public payload: any) { }
}

export class SetUserLinks implements Action {
    type = ActionTypes.SET_USER_LINKS;
    constructor(public payload: any) { }
}

export class SetValue implements Action {
    type = ActionTypes.SET_VALUE;
    /**"Id": "", "Value": "" */
    constructor(public payload: any) { }
}

export class SetMaterialPages implements Action {
    type = ActionTypes.SET_MATERIAL_PAGES;
    /**"Id": "", "Value": "" */
    constructor(public payload: any) { }
}

export class UpdateDashboard implements Action {
    type = ActionTypes.UPDATE_DASHBOARD;
    constructor(public payload: any) { }
}

export class UpdateISBN implements Action {
    type = ActionTypes.UPDATE_ISBN;
    /**"Id": "", "ISBN": "" */
    constructor(public payload: any) { }
}

export class UpdateLocation implements Action {
    type = ActionTypes.UPDATE_LOCATION;
    /**"Id": "", "Location": "" */
    constructor(public payload: any) { }
}

export class UpdateMaterial implements Action {
    type = ActionTypes.UPDATE_MATERIAL;
    /**"Id": "", "Title": "", "Author": "", "Publisher": "" */
    constructor(public payload: any) { }
}

export class SetTotalPages implements Action {
    type = ActionTypes.SET_TOTAL_PAGES;
    constructor(public payload: any) { }
}

export class SetMaterialForStockVerification implements Action {
    type = ActionTypes.SET_MATERIAL_FOR_STOCK_VERIFICATION;
    constructor(public payload: any) { }
}

export class SetLibManageSearch implements Action {
    type = ActionTypes.SET_LIB_MANAGE_SEARCH;
    constructor(public payload: any) { }
}

export class SetManageQueryString implements Action {
    type = ActionTypes.SET_MANAGE_QUERY_STRING;
    constructor(public payload: any) { }
}

export class SetCirculationSearch implements Action {
    type = ActionTypes.SET_CIRCULATION_SEARCH;
    constructor(public payload: any) { }
}

export class SetDueDate implements Action {
    type = ActionTypes.SET_DUE_DATE;
    constructor(public payload: any) { }
}


export type Actions
    = CloneMaterial | CreateMaterial | DisableMaterial | EnableMaterial | IssueMaterial | MaterialMaintenance | PrintBarcodes | ReturnMaintenanceMaterial | SetAttributes | SetLibraryContext | SetMaterial | SetMember | ResetMember | SetMaterialToCirculate | SetMaterialToIssue| SetMaterialToReturnReissue | SetHTML | SetSelectedMaterial | SetSelectedUserLink | SetTags | SetUserLinks | SetValue | UpdateDashboard | UpdateISBN | UpdateLocation | UpdateMaterial | SetTotalPages | SetMaterialPages | SetMaterialForStockVerification | SetLibManageSearch | SetCirculationSearch | SetManageQueryString | SetDueDate;