import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'ct-yt',
  templateUrl: './ct-yt.component.html',
  styleUrls: ['./ct-yt.component.css']
})
export class CtYtComponent implements OnInit {
  @Input()
  ytURL: string = "";
  isVisible: boolean = false;
  urlSafe: SafeResourceUrl;
  isYtURL: boolean = true;
  audioFormats: Array<string> = ["mp3", "ogg", "wav"];
  audioMediaTypes: Array<string> = ["audio/mpeg", "audio/ogg", "audio/wav"];
  urlFormat: string = "";
  urlMediaType: string = "";
  isVideo: boolean = true;

  constructor(public sanitizer: DomSanitizer) { }

  ngOnInit() {
    if (this.ytURL != "") {
      this.isYtURL = true;
      this.userURLToEmbed();
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.ytURL);
      this.isVisible = true;
    } else {
      this.isVisible = false;
    }
  }
  ngOnChanges() {
    if (this.ytURL != "") {
      this.isYtURL = true;
      this.userURLToEmbed();
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.ytURL);
      this.isVisible = true;
    } else {
      this.isVisible = false;
    }
  }
  userURLToEmbed() {
    let watchValue = '';
    if (this.ytURL) {
      if (this.ytURL.search(" ") != -1) {
        this.ytURL = this.ytURL.trim();
      }
      if (this.ytURL.search("http") == -1) {
        this.ytURL = "http://" + this.ytURL;
      }
    }
    if (this.ytURL.search('v=') >= 0) {
      try {
        let url = new URL(this.ytURL);
        watchValue = url.searchParams.get("v");
      } catch (e) {
        this.isYtURL = false;
      }
    } else if (this.ytURL.search('youtu.be/') >= 0) {
      watchValue = this.ytURL.split('youtu.be/')[1];
    } else if (this.ytURL.search('embed/') >= 0) {
      watchValue = this.ytURL.split('embed/')[1];
    } else if (this.ytURL.toString() != "") {
      this.isVisible = false;
      this.isYtURL = false;
    }
    if (this.isYtURL) {
      this.ytURL = 'https://www.youtube.com/embed/' + watchValue;
    } else {
      if ((this.ytURL.match(/\./g)||[]).length > 1) {
        let tempList = this.ytURL.split(".");
        let extension = tempList[tempList.length-1];
        if (extension) {
          if (this.audioFormats.includes(extension)) {
            this.urlFormat = extension;
            this.urlMediaType = this.audioMediaTypes[this.audioFormats.indexOf(extension)];
            this.isVideo = false;
          } else {
            this.isVideo = true;
          }
        } else {
          this.isVideo = true;
        }
      } else {
        this.ytURL = this.ytURL;
      }
    }
  }
}
