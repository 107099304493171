import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dex-filter',
  templateUrl: './dex-filter.component.html',
  styleUrls: ['./dex-filter.component.css']
})
export class DexFilterComponent implements OnInit {
  @Input() dexFilter: any[];
  @Output() onChanges: EventEmitter<{ type: string; value: string; json: any }> = new EventEmitter();
  operator: "AND" | "OR" = "AND";
  constructor() { }

  ngOnInit() {
    this.dexFilter.forEach((column) => {
      if (column.hasOwnProperty('values')) {
        column.type = 'dropdown';
        column.val = column.default || "";
      } else if (column.hasOwnProperty('value')) {
        column.type = 'any';
        column.val = "";
      }
      column.rel = ""
      column.checked = false
    });
  }
  ut_onChange(e) {
    this.onChanges.emit({ type: "filter", value: this.pRQL, json: this.json });
  }
  private get json(): { operator: string; conditions: Array<any> } {
    const conditions = this.dexFilter.filter((s) => s.checked).map(c => ({ column: c.column, rel: c.rel, value: Number(c.val) || c.val }));
    return { operator: this.operator, conditions };
  }
  private get pRQL(): string {
    return "filter (" + this.dexFilter.filter((s) => s.checked).map(c => `${c.column} ${c.rel} ${c.val}`).join(" && ") + ")";
  }

}
