import { Component, OnInit } from '@angular/core';

class Model {
  topics: Array<any> = Array();
  topicName: string = '';
}

@Component({
  selector: 'app-dex-publisher',
  templateUrl: './dex-publisher.component.html',
  styleUrls: ['./dex-publisher.component.css']
})
export class DexPublisherComponent implements OnInit {
  readonly m: Model = new Model();

  constructor() { }

  ngOnInit() {
    this.m.topics = [
      {
        name: 'All',
        published: true
      },
      {
        name: "Fee Reports",
        published: true
      },
      {
        name: "Academic Performance",
        published: false
      }
    ];
  }
  ut_createTopic() {
    if (this.m.topicName) {
      const isExist = this.m.topics.find(x => x.name == this.m.topicName);
      if (!isExist) {
        this.m.topics.push({ name: this.m.topicName, published: false });
        this.m.topicName = '';
      }
    }
  }
}
