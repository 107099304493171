import { Component, ElementRef, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ApiService } from '../../chart-dashboard/service/api-service';
import { ChartWidgetModel } from '../../chart-dashboard/service/models';
import * as R from 'ramda';
import { Store } from "@ngrx/store";
import * as rootReducer from '../../../../app/rootReducer';
import { Subscription } from 'rxjs';

declare const google: any;

@Component({
  selector: 'app-g-gaugechart',
  templateUrl: './g-gaugechart.component.html',
  styleUrls: ['./g-gaugechart.component.css']
})
export class GGaugechartComponent implements OnInit {
  cwm!: ChartWidgetModel;
  @Input() modelObserver: any;
  currentPersona: string;
  subs = new Subscription();

  @ViewChild('gaugechart', { read: ViewContainerRef }) gaugeChartElement?: ElementRef;
  chartStatus = 'Loading chart';

  constructor(private apiService: ApiService, private store: Store<rootReducer.State>) { }

  ngOnInit(): void {
    this.sub_store();
  }

  sub_store() {
    if (this.modelObserver) {
      this.modelObserver.subscribe((modelObserver: any) => {
        this.cwm = modelObserver;
        this.drawChart();
      });
    } else {
      this.cwm = new ChartWidgetModel();
    }
    this.store.select(rootReducer.get_state_app).subscribe((res: any) => {
      if (res) {
        this.currentPersona = res.currentPersona.title;
      }
    });
    this.subs.add(this.store.select(rootReducer.get_chart_meta_data).subscribe((res: any) => {
      if (res) {
        this.cwm.chartMetaData = res;
        this.drawChart();
      }
    }));
  }

  ngAfterContentInit() { }

  ngOnDestroy() {
    if (this.modelObserver) {
      this.modelObserver.unsubscribe();
    }
    this.subs.unsubscribe();
  }

  ut_refreshWidget() {
    this.apiService.refreshWidgetData(this.cwm);
  }

  drawChart() {
    if ((!R.path(["data", "isSampleData"], this.cwm.widgetSpecs)) && R.path(["widgetSpecs", "data", "api", "end_point"], this.cwm) && R.path([this.cwm.dashboardId, this.cwm.widgetId, "msg"], this.cwm.chartMetaData)) {
      this.chartStatus = R.path([this.cwm.dashboardId, this.cwm.widgetId, "msg"], this.cwm.chartMetaData);
    } else if (!R.path(["data", "isSampleData"], this.cwm.widgetSpecs) && R.path(["widgetSpecs", "data", "api", "end_point"], this.cwm) && this.cwm.data_values && (this.cwm.data_values.length > 0)) {
      try {
        google.charts.load('current', { 'packages': ['gauge'] });
        const self: any = this;
        let gaugeOptions = {
          width: 600, height: 300,
          redFrom: 90, redTo: 100,
          yellowFrom: 75, yellowTo: 90,
          minorTicks: 5
        };
        if (R.path(['settings'], this.cwm.options)) {
          gaugeOptions = R.mergeDeepRight(gaugeOptions, this.cwm.options.settings);
        }
        google.setOnLoadCallback(function () {
          const dataTable = new google.visualization.DataTable();
          if (R.pathOr([], ['columns'], self.cwm.options).length > 0) {
            self.cwm.options.columns.forEach((column: any) => {
              if (R.path(['type'], column) && R.path(['title'], column)) {
                dataTable.addColumn(column.type, column.title);
              }
            });
            let row = []
            self.cwm.data_values.forEach((datum: any) => {
              row = [];
              self.cwm.options.columns.forEach((column: any) => {
                row.push(datum[column.field]);
              });
              if (row.length > 0) {
                dataTable.addRows([row]);
              }
            });
          } else {
            this.chartStatus = 'No table columns';
          }
          const chart = new google.visualization.Gauge(self.gaugeChartElement.element.nativeElement);
          chart.draw(dataTable, gaugeOptions);
        });
      } catch (_) { }
    } else if (R.path(["data", "isSampleData"], this.cwm.widgetSpecs) && this.cwm.data_values && (this.cwm.data_values.length > 0)) {
      try {
        google.charts.load('current', { 'packages': ['gauge'] });
        const self: any = this;
        let gaugeOptions = {
          width: 600, height: 300,
          redFrom: 90, redTo: 100,
          yellowFrom: 75, yellowTo: 90,
          minorTicks: 5
        };
        if (R.path(['settings'], this.cwm.options)) {
          gaugeOptions = R.mergeDeepRight(gaugeOptions, this.cwm.options.settings);
        }
        google.setOnLoadCallback(function () {
          const dataTable = new google.visualization.DataTable();
          if (R.pathOr([], ['columns'], self.cwm.options).length > 0) {
            self.cwm.options.columns.forEach((column: any) => {
              if (R.path(['type'], column) && R.path(['title'], column)) {
                dataTable.addColumn(column.type, column.title);
              }
            });
            let row = []
            self.cwm.data_values.forEach((datum: any) => {
              row = [];
              self.cwm.options.columns.forEach((column: any) => {
                row.push(datum[column.field]);
              });
              if (row.length > 0) {
                dataTable.addRows([row]);
              }
            });
          } else {
            this.chartStatus = 'No table columns';
          }
          const chart = new google.visualization.Gauge(self.gaugeChartElement.element.nativeElement);
          chart.draw(dataTable, gaugeOptions);
        });
      } catch (_) { }
    } else {
      this.chartStatus = 'No data available.';
    }
  }
}
