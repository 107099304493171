import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'orderBy'})
export class OrderByPipe {
  constructor(){}
  transform(array: Array<Object>, args: string): Array<Object> {
    array.sort((a: any, b: any) => {
	    if ( a[args] < b[args] ){
	    	return -1;
	    }else if( a[args] > b[args] ){
	        return 1;
	    }else{
	    	return 0;
	    }
    });
    return array;
  }
}