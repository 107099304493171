import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';

import * as rootReducer from '../rootReducer';

import * as _ from 'underscore';

class Model {
    toDate: Date = new Date();
    acadYearFromDate: Date;
    acadYearFrom: string;
    acadYearTo: string;
    ctContext: any;
    selectedDateRange: string = "";
    calendarMonths: any[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    dateRange: any[] = [{ id: 0, name: "Monthly" }, { id: 1, name: "Quarterly" }, { id: 2, name: "Semi-Annual" }, { id: 3, name: "Annual" }, { id: 4, name: "Custom" }, { id: 5, name: "Default (no date check)" }];
    months: any[] = [];
    quarters: any[] = [];
    semiAnnual: any[] = [];
    acadToDate: any;
}

@Component({
    selector: 'dateRangeOptions',
    templateUrl: './component.html',
    styleUrls: ['./component.css']
})
export class DateRangeOptionsComponent implements OnInit {
    public m: Model;
    selectedQuarters = [];
    dropdownSettings = {};
    dateRangeDropDownSettings = {};
    selectedHalfYear = [];
    selectedRanges = [];
    selectedMonths = [];
    @Output() onQry = new EventEmitter<string>();
    constructor(private store: Store<rootReducer.State>) {
    }
    ngOnInit() {
        this.init_model();
        this.sub_store();
        this.dropdownSettings = {
            singleSelection: true,
            textField: 'name',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            allowSearchFilter: true
        };

        this.dateRangeDropDownSettings = {
            singleSelection: true,
            textField: 'name',
            allowSearchFilter: true,
            closeDropDownOnSelection: true
        };

        this.selectedRanges = [
            { id: 5, name: "Default (no date check)" }
        ]
    }

    init_model() {
        this.m = new Model();
    }

    sub_store() {
        this.store.select(rootReducer.get_state_app).subscribe(
            context => {
                this.m.ctContext = context;
                let currentPersona = this.m.ctContext.currentPersona;
                let _acadYearFrom = currentPersona.acadYearFrom;
                let _acadYearTo = currentPersona.acadYearTo;
                this.m.acadYearFrom = _acadYearFrom.toString().slice(0, 4);
                this.m.acadYearTo = _acadYearTo.toString().slice(0, 4);
                let acadYearFromStr = _acadYearFrom.toString().slice(0, 10);
                let acadYearToStr = _acadYearTo.toString().slice(0, 10);
                this.m.acadToDate = acadYearToStr;
                let date = acadYearFromStr.split("-");
                let convertedDate = date[1] + "-" + date[2] + "-" + date[0];
                this.m.acadYearFromDate = new Date(Date.parse(convertedDate));
            });
        this.getMonths();
        this.getQuarters();
        this.getSemiAnnual();
    }

    getMonths() {
        let from = this.m.acadYearFrom;
        let to = this.m.acadYearTo;
        let ms = this.m.calendarMonths;
        let monthsList = this.m.months;
        let i = 1;
        _.each(ms, function (a, b) {
            let m = a + ' ' + from;
            let json = { id: i, name: m };
            monthsList.push(json);
            i++;
        });

        _.each(ms, function (a, b) {
            let m = a + ' ' + to;
            let json = { id: i, name: m };
            monthsList.push(json);
            i++;
        });
    }

    getQuarters() {
        let from = this.m.acadYearFrom;
        let to = this.m.acadYearTo;

        let q1 = 'Jan' + ' ' + from + ' ' + '-' + ' ' + 'Mar' + ' ' + from;
        let q2 = 'Apr' + ' ' + from + ' ' + '-' + ' ' + 'Jun' + ' ' + from;
        let q3 = 'Jul' + ' ' + from + ' ' + '-' + ' ' + 'Sep' + ' ' + from;
        let q4 = 'Oct' + ' ' + from + ' ' + '-' + ' ' + 'Dec' + ' ' + from;
        let q5 = 'Jan' + ' ' + to + ' ' + '-' + ' ' + 'Mar' + ' ' + to;
        let q6 = 'Apr' + ' ' + to + ' ' + '-' + ' ' + 'Jun' + ' ' + to;

        let quarter1 = { id: 1, name: q1 };
        let quarter2 = { id: 4, name: q2 };
        let quarter3 = { id: 7, name: q3 };
        let quarter4 = { id: 10, name: q4 };
        let quarter5 = { id: 11, name: q5 };
        let quarter6 = { id: 14, name: q6 };
        this.m.quarters.push(quarter1, quarter2, quarter3, quarter4, quarter5, quarter6);
    }

    getSemiAnnual() {
        let from = this.m.acadYearFrom;
        let to = this.m.acadYearTo;

        let s1 = 'Jan' + ' ' + from + ' ' + '-' + ' ' + 'Jun' + ' ' + from;
        let s2 = 'Jul' + ' ' + from + ' ' + '-' + ' ' + 'Dec' + ' ' + from;
        let s3 = 'Jan' + ' ' + to + ' ' + '-' + ' ' + 'Jun' + ' ' + to;
        let s4 = 'Jul' + ' ' + to + ' ' + '-' + ' ' + 'Dec' + ' ' + to;

        let sa1 = { id: 1, name: s1 };
        let sa2 = { id: 7, name: s2 };
        let sa3 = { id: 11, name: s3 };
        let sa4 = { id: 17, name: s4 };
        this.m.semiAnnual.push(sa1, sa2, sa3, sa4);
    }

    getOption(event) {
        if (event == '0') {
            this.m.selectedDateRange = 'Monthly';
            let sm: any = [];
            _.each(this.selectedMonths, function (a, b) {
                let mth = a.name;
                let mth_arr = mth.split(' ');
                let yr = mth_arr[1];

                a.id == 13 ? a.id = 1 : a.id;
                a.id == 14 ? a.id = 2 : a.id;
                a.id == 15 ? a.id = 3 : a.id;
                a.id == 16 ? a.id = 4 : a.id;
                a.id == 17 ? a.id = 5 : a.id;
                a.id == 18 ? a.id = 6 : a.id;
                a.id == 19 ? a.id = 7 : a.id;
                a.id == 20 ? a.id = 8 : a.id;
                a.id == 21 ? a.id = 9 : a.id;
                a.id == 22 ? a.id = 10 : a.id;
                a.id == 23 ? a.id = 11 : a.id;
                a.id == 24 ? a.id = 12 : a.id;
                let monthYear = { month: a.id, year: yr };
                sm.push(monthYear);
            });
            var lastDt = 0;
            var dateRange = [];
            if (sm && sm.length >= 1) {
                lastDt = this.getDaysInMonth(parseInt(sm[0].year), sm[0].month);
                dateRange = [{ 'From': sm[0].year + '-' + sm[0].month + '-' + '01', 'To': sm[0].year + '-' + sm[0].month + '-' + lastDt }];
            }
            let range: any = { type: 'Monthly', value: dateRange };
            this.onQry.emit(range);
        }
        else if (event == '1') {
            this.m.selectedDateRange = 'Quarterly';
            let sq: any = [];
            _.each(this.selectedQuarters, function (a, b) {
                let quarter = a.name;
                let first_string = quarter.split('-');
                let arr = first_string[0].split(' ');

                a.id == 11 ? a.id = 1 : a.id;
                a.id == 14 ? a.id = 4 : a.id;

                let qr = { month: a.id, year: arr[1] };
                sq.push(qr);
            });

            var lastDt = 0;
            var dateRange = [];
            if (sq && sq.length >= 1) {
                var from = sq[0].year + '-' + sq[0].month + '-' + '01';
                var toMonth = sq[0].month + 2;
                lastDt = this.getDaysInMonth(parseInt(sq[0].year), toMonth);
                var to = sq[0].year + '-' + toMonth + '-' + lastDt;
                dateRange = [{ 'From': from, 'To': to }];
            }
            let range: any = { type: 'Quarterly', value: dateRange };
            this.onQry.emit(range);
        }
        else if (event == '2') {
            this.m.selectedDateRange = 'Semi-Annual';
            let sh: any = [];
            _.each(this.selectedHalfYear, function (a, b) {
                let sa = a.name;
                let first_string = sa.split('-');
                let arr = first_string[0].split(' ');

                a.id == 11 ? a.id = 1 : a.id;
                a.id == 17 ? a.id = 7 : a.id;

                let s = { month: a.id, year: arr[1] };
                sh.push(s);
            });
            var lastDt = 0;
            var dateRange = [];
            if (sh && sh.length >= 1) {
                var from = sh[0].year + '-' + sh[0].month + '-' + '01';
                var toMonth = sh[0].month + 5;
                lastDt = this.getDaysInMonth(parseInt(sh[0].year), toMonth);
                var to = sh[0].year + '-' + toMonth + '-' + lastDt;
                dateRange = [{ 'From': from, 'To': to }];
            }

            let range: any = { type: 'Semi-Annual', value: dateRange };
            this.onQry.emit(range);
        }
        else if (event == '3') {
            this.m.selectedDateRange = 'Annual';
            var fD = this.m.acadYearFromDate;
            var from = fD.getFullYear() + '-' + (fD.getMonth() + 1) + '-' + fD.getDate();
            var tD = this.m.acadToDate;
            //var to = tD.getFullYear() + '-' + (tD.getMonth() + 1) + '-' + tD.getDate();
            let dt: any = [{ From: from, To: tD }];
            let range: any = { type: 'Annual', value: dt };
            this.onQry.emit(range);
        }
        else if (event == '4') {
            this.m.selectedDateRange = 'Custom';
            var fD = this.m.acadYearFromDate;
            var from = fD.getFullYear() + '-' + (fD.getMonth() + 1) + '-' + fD.getDate();
            var today = this.m.toDate;
            var to = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
            let dt: any = [{ From: from, To: to }];
            let range: any = { type: 'Custom', value: dt };
            this.onQry.emit(range);
        }

        else if (event == '5') {
            this.m.selectedDateRange = 'Default (no date check)';
            let range: any = { type: 'Default', value: [] };
            this.onQry.emit(range);
        }
    }

    onItemSelect(event) {
    }
    onSelectAll(event) {
    }

    onFromDateChange(event) {
        this.m.acadYearFromDate = event;
        this.getOption('4');
    }

    onToDateChange(event) {
        this.m.toDate = event;
        this.getOption('4');
    }

    onSelectedMonthChange() {
        this.getOption('0');
    }

    onSelectedQuarterChange() {
        this.getOption('1');
    }

    onSelectedHalfYearChange() {
        this.getOption('2');
    }

    onSelectedRangeChange() {
        if (this.selectedRanges.length > 0) {
            var selRange: any = this.selectedRanges[0];
            this.getOption(selRange.id);
        }
    }

    getDaysInMonth(year, month) {
        return new Date(year, month, 0).getDate();
    }
}

