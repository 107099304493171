import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import * as rootReducer from '../rootReducer';
import * as navTabsAction from './store/navTabs-action';
import * as appAction from '../store/app-action';

import 'rxjs/Rx';
import { Router, NavigationStart } from '@angular/router';
import * as _ from 'underscore';
import { AcademicsService as service } from '../academics/service/service';

class model {
	public ctContext: any;
	public navPerms: any[];
	public selectedTab: any;
	showMsg: any = false;
	unreadNotifs: any = false;
	errorNotif: any = false;
	Notifications: any[] = [];
	navbarDisplayOptions: any[] = [{ name: "Standard", type: "standard" }, { name: "Classic", type: "classic" }];
	selectedNBDisplay: any;
	classic: any = { name: "Classic", type: "classic", hideNavBar: false };
	standard: any = { name: "Standard", type: "standard", hideNavBar: false };
	classicMode: boolean = false;
	getSelectedTab(nav) {
		let path = window.location.pathname;
		let pathArr = path.split("/");
		if (nav.Route == pathArr[1]) return true;
		else return false;
	};
}

@Component({
	selector: 'navTabs',
	templateUrl: './navTabs.component.html',
	styleUrls: ['./navTabs.component.css'],
	providers: [service]
})
export class NavTabsComponent implements OnInit {
	public m: model;
	currentPersona: string;
	constructor(private store: Store<rootReducer.State>, private router: Router, public service: service) {
		router.events.subscribe((val) => {
			if (val instanceof NavigationStart) {
				var navArr = val.url.split('/');
				if (this.m.navPerms && this.m.navPerms.length > 0) {
					_.each(this.m.navPerms, function (v, k) {
						v.selected = false;
					});

				}
			}
		});
	}
	ngOnInit() {
		this.init_model();
		this.sub_store();
	}
	init_model() {
		this.m = new model();
	}

	sub_store() {
		this.store.select(rootReducer.get_selected_nb_display).subscribe(res => {
			if (res) {
				this.m.selectedNBDisplay = res;
				if (this.m.selectedNBDisplay.type == "classic") { this.m.classicMode = true; }
				else { this.m.classicMode = false; }
			}
		}
		);
		this.store.select(rootReducer.get_notifications).subscribe(
			res => {
				if (res && res.length > 0) {
					this.m.Notifications = res;
					this.m.unreadNotifs = true;
					if (this.m.Notifications[0].status == 'success') {
						this.m.errorNotif = false;
						this.m.unreadNotifs = true;
					}
					else {
						this.m.unreadNotifs = false;
						this.m.errorNotif = true;
					}
				}
			});
		this.store.select(rootReducer.get_state_app).subscribe(
			res => {
				if (res) {
					this.m.navPerms = res.currentPersona.navPerms;
					this.currentPersona = res.currentPersona.title;
					if (this.currentPersona == 'Student') {
						this.m.navPerms = this.m.navPerms.filter(o => (o.Route == 'home' || o.Route == 'academics' || o.Route == 'lms'));
					}
					this.m.ctContext = res;
				}
			},
			err => console.log(err)
		);
		this.store.select(rootReducer.get_reset_tab).subscribe(
			res => {
				if (res && res.reset) {
					let path = window.location.pathname;
					let pathArr = path.split("/");
					let nav = this.m.navPerms.find(n => n.Route == pathArr[1]);
					if (nav) {
						for (var i = 0; i < this.m.navPerms.length; i++) {
							this.m.navPerms[i].selected = false;
						}
						nav.selected = true;
					}
				}
			});

		this.store.select(rootReducer.get_selected_tab).subscribe(res => {
			if (res) {
				let nav = this.m.navPerms.find(n => n.Route == res.Route);
				if (nav) {
					for (var i = 0; i < this.m.navPerms.length; i++) {
						this.m.navPerms[i].selected = false;
					}
					nav.selected = true;
				}
			}
		}
		);
	}
	navSelect(tab: any, index: any) {
		this.store.dispatch(new navTabsAction.SetTab({ "tab": tab }));
		//this.store.dispatch(new appAction.SetTab({ "tab": tab }));
		for (var i = 0; i < this.m.navPerms.length; i++) {
			this.m.navPerms[i].selected = false;
		}
		this.m.navPerms[index].selected = true;
	}
	mouseEnter() {
		this.m.showMsg = true;
		this.m.unreadNotifs = false;
		this.m.errorNotif = false;
	}
	mouseLeave() {
		this.m.showMsg = false;
	}
	closeNotifs() {
		this.m.showMsg = false;
	}
	setNBMode() {
		if (this.m.classicMode) { this.store.dispatch(new appAction.SetNBDisplay(this.m.classic)); }
		else { this.store.dispatch(new appAction.SetNBDisplay(this.m.standard)); }
	}
}