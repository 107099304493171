import { Component, OnInit } from '@angular/core';
import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import * as rootReducer from '../rootReducer';
import { Subscription } from 'rxjs/Subscription';

import * as appAction from '../store/app-action';
import { CTApi } from './ct-api';

class Model {
}

@Injectable()
export class RecentActivitiesComponent implements OnInit {
    public m: Model;
    constructor(public ctapi: CTApi, private store: Store<rootReducer.State>) { }
    ngOnInit() {
        this.init_model();
        this.init_store();
        this.sub_store();
    }
    ngOnDestroy() {
    }
    init_model() {
        this.m = new Model();
    }
    init_store() {
    }
    sub_store() {
    }
    recentActivities(recent_act) {
        var recAct;
        var resObj;
        this.store.select(rootReducer.get_rec_act).subscribe(
            res => {
                if (res) {
                    recAct = res;
                    resObj = recAct.find(x => x.title == recent_act.title);
                    if (resObj) {
                        var index = recAct.findIndex(x => x.title == resObj.title);
                        resObj.Context.SelectedFeeId = recent_act.Context.SelectedFeeId;
                        recAct.splice(index, 1);
                        recAct.splice(0, 0, resObj);                        
                        this.store.dispatch(new appAction.RecentActivity(recAct));
                    }
                    else {
                        recAct.unshift(recent_act);
                        if (recAct.length > 10) {
                            recAct.splice(-1, 1);
                        }
                        this.store.dispatch(new appAction.RecentActivity(recAct));
                    }
                }
            });
        this.ctapi.setRecentActivity( recAct );
    }
}