import { Component, ElementRef, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ApiService } from '../../chart-dashboard/service/api-service';
import { ChartWidgetModel } from '../../chart-dashboard/service/models';
import * as R from 'ramda';
import { Store } from "@ngrx/store";
import * as rootReducer from '../../../../app/rootReducer';
import { Subscription } from 'rxjs';

declare const google: any;

@Component({
  selector: 'app-gv-areachart',
  templateUrl: './gv-areachart.component.html',
  styleUrls: ['./gv-areachart.component.css']
})
export class GvAreachartComponent implements OnInit {
  cwm!: ChartWidgetModel;
  @Input() modelObserver: any;
  currentPersona: string;
  subs = new Subscription();

  @ViewChild('areachart', { read: ViewContainerRef }) areaChartElement?: ElementRef;
  chartStatus = 'Loading chart';

  areaChartSpec: any = {
    "$schema": "https://vega.github.io/schema/vega-lite/v5.json",
    "description": "Google's stock price over time.",
    "data": {
      "values": []
    },
    "mark": {
      "type": "area",
      "line": {
        "color": "darkgreen"
      },
      "color": {
        "x1": 1,
        "y1": 1,
        "x2": 1,
        "y2": 0,
        "gradient": "linear",
        "stops": [
          {
            "offset": 0,
            "color": "white"
          },
          {
            "offset": 1,
            "color": "darkgreen"
          }
        ]
      }
    },
    "encoding": {
      "x": {
        "field": "date",
        "type": "temporal"
      },
      "y": {
        "field": "price",
        "type": "quantitative"
      }
    }
  };

  constructor(private apiService: ApiService, private store: Store<rootReducer.State>) { }

  ngOnInit(): void {
    this.sub_store();
  }

  sub_store() {
    if (this.modelObserver) {
      this.modelObserver.subscribe((modelObserver: any) => {
        this.cwm = modelObserver;
        this.drawChart();
      });
    } else {
      this.cwm = new ChartWidgetModel();
    }
    this.store.select(rootReducer.get_state_app).subscribe((res: any) => {
      if (res) {
        this.currentPersona = res.currentPersona.title;
      }
    });
    this.subs.add(this.store.select(rootReducer.get_chart_meta_data).subscribe((res: any) => {
      if (res) {
        this.cwm.chartMetaData = res;
        this.drawChart();
      }
    }));
  }

  ngAfterContentInit() { }

  ngOnDestroy() {
    if (this.modelObserver) {
      this.modelObserver.unsubscribe();
    }
    this.subs.unsubscribe();
  }

  ut_refreshWidget() {
    this.apiService.refreshWidgetData(this.cwm);
  }

  drawChart() {
    if (this.cwm.options) {
      this.areaChartSpec = R.mergeDeepRight(this.areaChartSpec, this.cwm.options);
    }
    if ((!R.path(["data", "isSampleData"], this.cwm.widgetSpecs)) && R.path(["widgetSpecs", "data", "api", "end_point"], this.cwm) && R.path([this.cwm.dashboardId, this.cwm.widgetId, "msg"], this.cwm.chartMetaData)) {
      this.chartStatus = R.path([this.cwm.dashboardId, this.cwm.widgetId, "msg"], this.cwm.chartMetaData);
    } else if (!R.path(["data", "isSampleData"], this.cwm.widgetSpecs) && R.path(["widgetSpecs", "data", "api", "end_point"], this.cwm) && this.cwm.data_values && (this.cwm.data_values.length > 0)) {
      this.areaChartSpec['data']['values'] = this.cwm.data_values;
      try {
        google.charts.load('visualization', { 'packages': ['vegachart'] });
        const self: any = this;
        google.setOnLoadCallback(function () {
          const dataTable = new google.visualization.DataTable();
          const chart = new google.visualization.VegaChart(self.areaChartElement.element.nativeElement);
          chart.draw(dataTable, { 'vegaLite': self.areaChartSpec });
        });
      } catch (_) { }
    } else if (R.path(["data", "isSampleData"], this.cwm.widgetSpecs) && this.cwm.data_values && (this.cwm.data_values.length > 0)) {
      this.areaChartSpec['data']['values'] = this.cwm.data_values;
      try {
        google.charts.load('visualization', { 'packages': ['vegachart'] });
        const self: any = this;
        google.setOnLoadCallback(function () {
          const dataTable = new google.visualization.DataTable();
          const chart = new google.visualization.VegaChart(self.areaChartElement.element.nativeElement);
          chart.draw(dataTable, { 'vegaLite': self.areaChartSpec });
        });
      } catch (_) { }
    } else {
      this.chartStatus = 'No data available.';
    }
  }
}
