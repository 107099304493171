import { Action } from '@ngrx/store';
import { type } from '../../../app/util';

export const ActionTypes = {
    SET_INSTITUTES: type('[Home] Set Inst'),
    SET_DASHBOARD_WIDGETS: type('[Home] Set Dashboard Widgets'),
    UPDATE_DASHBOARD: type('[Home] Update Dashboard'),
    APPEND_WIDGET_TO_DASHBOARD: type('[Home] Append Widget To Dashboard'),
    UPDATE_DEX_DATA: type('[Home] Update Dex Data'),
    UPDATE_DEX_QUERIES: type('[Home] Update Dex Queries'),
}

export class SetInsts implements Action {
    type = ActionTypes.SET_INSTITUTES;
    constructor(public payload: any) { }
}

export class SetDashboardWidgets implements Action {
    type = ActionTypes.SET_DASHBOARD_WIDGETS;
    constructor(public payload: any) { }
}
export class AppendWidgetToDashboard implements Action {
    type = ActionTypes.APPEND_WIDGET_TO_DASHBOARD;
    constructor(public payload: any) { }
}
export class UpdateDashboard implements Action {
    type = ActionTypes.UPDATE_DASHBOARD;
    constructor(public payload: Array<any>) { }
}
export class UpdateDexData implements Action {
    type = ActionTypes.UPDATE_DEX_DATA;
    constructor(public payload: Array<any>) { }
}
export class UpdateDexQueries implements Action {
    type = ActionTypes.UPDATE_DEX_QUERIES;
    constructor(public payload: Array<any>) { }
}

export type Actions
    = SetInsts | SetDashboardWidgets | AppendWidgetToDashboard | UpdateDashboard | UpdateDexData | UpdateDexQueries;
