import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import * as rootReducer from '../../rootReducer';
import { AcademicsService as service } from '../../academics/service/service';
import * as acadAction from '../../academics/store/action';
import { SearchPipe as pipe } from '../../search.pipe';

declare const google: any;

class Model {
    path: string = "vx/curriculum";
    topics: any[] = [];
    searchText: string;
}

@Component({
    selector: 'curriculum-published',
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
    providers: [service, pipe]
})

export class CurriculumPublishedComponent implements OnInit {
    constructor(public pipe: pipe, private store: Store<rootReducer.State>, public service: service) { }
    public m: Model;
    pin: any;
    subs = new Subscription();
    topicsCopy: any[] = [];
    ngOnInit() {
        this.init_model();
        this.init_store();
        this.sub_store();
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    init_model() {
        this.m = new Model();
    }
    init_store() {
        this.getFiles();
    }
    sub_store() {
        this.subs.add(this.store.select(rootReducer.get_published_topics).subscribe(res => {
            if (res) {
                this.m.topics = res;
                this.topicsCopy = res;
                this.drawTable()
            }
        })
        );
    }

    clearSearch() {
        this.m.searchText = "";
        this.m.topics = this.pipe.transform(this.topicsCopy, 'title', this.m.searchText);
        this.drawTable();
    }

    filterList() {
        let list;
        if (this.m.searchText == "") list = this.topicsCopy;
        else list = this.m.topics;
        this.m.topics = this.pipe.transform(list, 'title', this.m.searchText);
        this.drawTable();
    }

    drawTable() {
        let topics = this.m.topics;
        google.charts.load('current', { 'packages': ['table'] });
        google.charts.setOnLoadCallback(drawTable);

        function drawTable() {
            var data = new google.visualization.DataTable();
            data.addColumn('string', 'Title');
            data.addColumn('string', 'Published On');
            topics.forEach(tp => {
                let dt = new Date(tp.publishedOn);
                let date = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate();
                data.addRows([
                    [tp.title, date]
                ]);
            });

            var table = new google.visualization.Table(document.getElementById('table_div'));
            table.draw(data, { showRowNumber: true, width: '100%', height: '100%' });
        }
    }

    getFiles() {
        this.service.getDocsByPath(this.m.path, '&$search=status:published&$skip=0&$top=1024').subscribe((res: any) => {
            if (res && res['data']) {
                let files = res['data'];
                this.store.dispatch(new acadAction.SetPublishedTopics(files));
            }
        });
    }
}