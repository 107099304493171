import { Action } from '@ngrx/store';
import { type } from '../../util';

export const ActionTypes = {
    SET_ARTEFACT: type('[Artefact] Set Artefact'),
    DISABLE_ARTEFACT: type('[Artefact] Disable Artefact'),
    ENABLE_ARTEFACT: type('[Artefact] Enable Artefact'),
    PUBLISH_ARTEFACT: type('[Artefact] Publish Artefact'),
    UNPUBLISH_ARTEFACT: type('[Artefact] Unpublish Artefact'),
    SET_ARTEFACT_CONTEXT: type('[Artefact] Set Artefact Context'),
    SET_SELECTED_ARTEFACT: type('[Artefact] Set Selected Artefact'),
    UPDATE_ARTEFACT: type('[Artefact] Update Artefact'),
    SET_URL: type('[Artefact] Set Url'),
    SET_EXAMS: type('[Artefact] Set Exam'),
    SET_ARTEFACT_DATA: type('[Artefact] Set Artefact Data'),
    SET_ETL_URL: type('[Artefact] Set Etl Url'),
    SET_HTML: type('[Artefact] Set Html'),
    SET_SELECTED_ETL: type('[Artefact] Set Selected Etl'),
    SET_ETL_FOLDER_FILES: type('[Artefact] Set ETL folder files')
};

export class SetHtml implements Action {
    type = ActionTypes.SET_HTML;
    constructor(public payload: any) { }
}

export class UpdateArtefact implements Action {
    type = ActionTypes.UPDATE_ARTEFACT;
    constructor(public payload: any) { }
}

export class SetArtefact implements Action {
    type = ActionTypes.SET_ARTEFACT;
    constructor(public payload: any) { }
}

export class DisableArtefact implements Action {
    type = ActionTypes.DISABLE_ARTEFACT;
    constructor(public payload: any) { }
}

export class EnableArtefact implements Action {
    type = ActionTypes.ENABLE_ARTEFACT;
    constructor(public payload: any) { }
}
export class PublishArtefact implements Action {
    type = ActionTypes.PUBLISH_ARTEFACT;
    constructor(public payload: any) { }
}
export class UnpublishArtefact implements Action {
    type = ActionTypes.UNPUBLISH_ARTEFACT;
    constructor(public payload: any) { }
}
export class SetArtefactContext implements Action {
    type = ActionTypes.SET_ARTEFACT_CONTEXT;
    constructor(public payload: any) { }
}
export class SetSelectedArtefact implements Action {
    type = ActionTypes.SET_SELECTED_ARTEFACT;
    constructor(public payload: any) { }
}

export class SetUrl implements Action {
    type = ActionTypes.SET_URL;
    constructor(public payload: any) { }
}
export class SetExams implements Action {
    type = ActionTypes.SET_EXAMS;
    constructor(public payload: any) { }
}

export class SetArtefactData implements Action {
    type = ActionTypes.SET_ARTEFACT_DATA;
    constructor(public payload: any) { }
}

export class SetEtlUrl implements Action {
    type = ActionTypes.SET_ETL_URL;
    constructor(public payload: any) { }
}

export class SetSelectedEtl implements Action {
    type = ActionTypes.SET_SELECTED_ETL;
    constructor(public payload: any) { }
}

export class SetEtlFolderFiles implements Action {
    type = ActionTypes.SET_ETL_FOLDER_FILES;
    constructor(public payload: any) { }
}

export type Actions
    = SetArtefact | DisableArtefact | EnableArtefact | PublishArtefact | UnpublishArtefact | UpdateArtefact | SetSelectedArtefact | SetUrl | SetArtefactData | SetEtlUrl | SetArtefactContext | SetExams | SetHtml | SetSelectedEtl | SetEtlFolderFiles;
