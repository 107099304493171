import * as app from './action';

class Section {
    name: string;
    subjects: string[] = []
}

class Course {
    name: string;
    termName: string;
    secName: string;
    id: string;
    fullName: string;
    sections: Section[] = [];
    status: number;
    branch: string;
    nameAndBranch: string;
    nameBranchAndSection: string;
}

export class ParentDetails {
    name: string = "";
    email: string = "";
    mobile: string = "";
}
export class AcadDetails {
    yearOfPassing: string = "";
    board: string = "";
    percentage: number = 0;
}
export class Address {
        line1: string = "";
        line2: string = "";
        country: string = "";
        state: string = "";
        pincode: string = "";
}
class AppliedFor {
    course: string = "";
    courseLevel: string = "";
    stream: string = "";
    combination: string = "";
    year: number = 1;
    paymentStatus: any;
}
export class Application {
    id: string = "";
    name: string = "";
    email: string = "";
    mobile: string = "";
    address: Address;
    dob: any;
    residentialStatus: number;
    residentialStatusAsString: string = "";
    category: number;
    categoryAsString: string = "";
    gender: string = "";
    hsc: AcadDetails;
    ssc: AcadDetails;
    fatherDetails: ParentDetails;
    motherDetails: ParentDetails;
    guardianDetails: ParentDetails;
    appliedFor: any;
    photoURL: string;
    aadharURL: string;
    hscMarksCardURL: string;
    sscMarksCardURL: string;
    birthCertificateURL: string;
    casteCertificateURL: string;
    domicileCertificateURL: string;
    transferCertificateURL: string;
    leavingCertificateURL: string;
    applicationFormTemplateId: string;
    applicationStatus: string;
    applicationNumber: number;
    submittedOn: any;
    selected: boolean;
}

function setCourseArray(dataFromServer): Course[] {
    let courses = [];
    dataFromServer.data.forEach(o => {
        o.terms.forEach(t => {
            if (t)
                t.sections.forEach(s => {
                    if (s) {
                        let course = new Course();
                        course.id = o.id;
                        course.name = o.name;
                        course.secName = s.name;
                        course.termName = t.name;
                        course.status = o.status;
                        course.branch = o.branch;
                        course.fullName = o.branch != '' ? o.name + " " + o.branch : o.name;
                        course.nameAndBranch = o.nameAndBranch;
                        course.nameBranchAndSection = o.nameAndBranch + " " + s.name;
                        courses.push(course);
                    }
                })
        })
    });
    return courses;
}

function setApplicationFormTemplateArray(dataFromServer) {
    let result = [];
    if (dataFromServer) {
        dataFromServer.forEach(o => {
            let formTemp = new ApplicationFormTemplate();
            formTemp.id = o.id;
            formTemp.title = o.title;
            formTemp.description = o.description;
            formTemp.options = o.options;
            formTemp.shortlists = o.shortlists;
            formTemp.status = o.status;
            formTemp.statusAsString = o.status == 70 ? "Published" : o.status == 99 ? "Disabled" : "Active";
            result.push(formTemp);
        });
    }
    return result;
}

function disableApplicationFormTemplate(applicationFormTemplates, id) {
    var applicationForms: ApplicationFormTemplate[] = applicationFormTemplates.map((item) => {
        if (item.id == id) item.status = 99;
        return item;
    });
    return applicationForms;
}
function enableApplicationFormTemplate(applicationFormTemplates, id) {
    var applicationForms: ApplicationFormTemplate[] = applicationFormTemplates.map((item) => {
        if (item.id == id) item.status = 1;
        return item;
    });
    return applicationForms;
}

function publishApplicationFormTemplate(applicationFormTemplates, id) {
    var applicationForms: ApplicationFormTemplate[] = applicationFormTemplates.map((item) => {
        if (item.id == id) item.status = 70;
        return item;
    });
    return applicationForms;
}
function unpublishApplicationFormTemplate(applicationFormTemplates, id) {
    var applicationForms: ApplicationFormTemplate[] = applicationFormTemplates.map((item) => {
        if (item.id == id) item.status = 1;
        return item;
    });
    return applicationForms;
}

function setSubmittedApplications(dataFromServer){
    let result = [];
    if (dataFromServer) {
        dataFromServer.forEach(o => {
            let app = new Application();
            app.id = o.id;
            app.name = o.name;
            app.email = o.email;
            app.mobile = o.mobile;
            app.address = o.address;
            app.dob = o.dob;
            app.residentialStatus = o.residentialStatus;
            app.residentialStatusAsString = o.residentialStatusAsString;
            app.category = o.category;
            app.categoryAsString = o.categoryAsString;
            app.aadharURL = o.aadharURL;
            app.photoURL = o.photoURL;
            app.hscMarksCardURL = o.hscMarksCardURL;
            app.sscMarksCardURL = o.sscMarksCardURL;
            app.birthCertificateURL = o.birthCertificateURL;
            app.domicileCertificateURL = o.domicileCertificateURL;
            app.casteCertificateURL = o.casteCertificateURL;
            app.transferCertificateURL = o.transferCertificateURL;
            app.leavingCertificateURL = o.leavingCertificateURL;
            app.applicationFormTemplateId = o.applicationFormTemplateId;
            app.applicationStatus = o.applicationStatus;
            app.applicationNumber = o.applicationNumber;
            app.submittedOn = o.submittedOn;
            app.hsc = o.hsc;
            app.ssc = o.ssc;
            app.fatherDetails = o.fatherDetails;
            app.motherDetails = o.motherDetails;
            app.guardianDetails = o.guardianDetails;
            app.appliedFor = o.appliedFor;

            result.push(app);
        });
    }
    return result;
}

function setUrl(selectedApplicant, url){
    selectedApplicant.aadharURL = url;
    return selectedApplicant;
}

export class ApplicationFormTemplateAction {
    constructor(public id: number, public displayText: string, public methodName: string) {
    }
}

export class ApplicationFormTemplate {
    id: string;
    title: string;
    description: string;
    status: number;
    statusAsString: string;
    options: any;
    shortlists: any[]= [];
    actions: ApplicationFormTemplateAction[] = [];
    get allowedActions(): ApplicationFormTemplateAction[] {
        let result = [];
        switch (this.status) {
            case 1: //active
                result = [];
                result.push(this.actions[1]) && result.push(this.actions[2]) && result.push(this.actions[4]);
                break;
            case 99: //disabled
                result = [];
                result.push(this.actions[0]);
                break;
            case 70: //published
                result = [];
                result.push(this.actions[3]) && result.push(this.actions[4]);
                break;
            default:
                result = [];
        }
        return result;
    }
    constructor() {
        this.actions.push(new ApplicationFormTemplateAction(1, "Enable", "ut_enableApplicationFormTemplate"));
        this.actions.push(new ApplicationFormTemplateAction(2, "Disable", "ut_disableApplicationFormTemplate"));
        this.actions.push(new ApplicationFormTemplateAction(3, "Publish", "ut_publishApplicationFormTemplate"));
        this.actions.push(new ApplicationFormTemplateAction(4, "Unpublish", "ut_unpublishApplicationFormTemplate"));
        this.actions.push(new ApplicationFormTemplateAction(5, "View", "ut_viewApplicationFormTemplate"))
    }
}

export interface State {
    courseList: any[];
    seatMatrix: any[];
    applicant: any;
    applicantList: any[];
    selectedApplication: any;
    selSeatMatrix: any;
    shortlists: any[];
    coursewiseApplications: any[];
    seatsAvailable: any[];
    admissions: any[];
    applicantListNA: any[];
    selectedShortlist: any;
    admittedStudents: any[];
    shortlistExpandCollapse: any;
    applicationFormTemplates: ApplicationFormTemplate[];
    selectedApplicationFormTemplate: ApplicationFormTemplate;
    selectedApplicant: any;
    formTemplatePreview: any;
    submittedApplications: any[];
    totalPages: number;
}

const initialState: State = {
    courseList: null,
    seatMatrix: null,
    applicant: null,
    applicantList: null,
    selectedApplication: null,
    selSeatMatrix: null,
    shortlists: null,
    coursewiseApplications: null,
    seatsAvailable: null,
    admissions: null,
    applicantListNA: null,
    selectedShortlist: null,
    admittedStudents: null,
    shortlistExpandCollapse: null,
    applicationFormTemplates: null,
    selectedApplicationFormTemplate: null,
    selectedApplicant: null,
    formTemplatePreview: null,
    submittedApplications: null,
    totalPages: 0
};

export function reducer(state = initialState, action: app.Actions): State {
    switch (action.type) {
        case app.ActionTypes.SET_COURSES:
            // return Object.assign({}, state, { courseList: setCourseArray(action.payload.course_list) });
            return Object.assign({}, state, { courseList: action.payload.course_list });
        case app.ActionTypes.SET_SEAT_MATRIX:
            return Object.assign({}, state, { seatMatrix: action.payload.seatMatrix });
        case app.ActionTypes.SET_SELECTED_SEAT_MATRIX:
            return Object.assign({}, state, { selSeatMatrix: action.payload.selSeatMatrix });
        case app.ActionTypes.SET_APPLICANT_DETAILS:
            return Object.assign({}, state, { applicant: action.payload.applicant });
        case app.ActionTypes.SET_APPLICANTS:
            return Object.assign({}, state, { applicantList: action.payload.applicants });
        case app.ActionTypes.SET_APPLICANTS_NOT_ADMITTED:
            return Object.assign({}, state, { applicantListNA: action.payload.applicantsNotAdmitted });
        case app.ActionTypes.SET_SELECTED_APPLICATION:
            return Object.assign({}, state, { selectedApplication: action.payload.application });
        case app.ActionTypes.SHORTLISTS:
            return Object.assign({}, state, { shortlists: action.payload.shortlists });
        case app.ActionTypes.SET_COURSEWISE_APPLICATIONS:
            return Object.assign({}, state, { coursewiseApplications: action.payload.coursewiseApplications });
        case app.ActionTypes.SEATS_AVAILABLE:
            return Object.assign({}, state, { seatsAvailable: action.payload.seatsAvailable });
        case app.ActionTypes.SET_ADMISSIONS:
            return Object.assign({}, state, { admissions: action.payload.admissions });
        case app.ActionTypes.SET_SELECTED_SHORTLIST:
            return Object.assign({}, state, { selectedShortlist: action.payload.selectedShortlist });
        case app.ActionTypes.SET_ADMITTED_STUDENTS:
            return Object.assign({}, state, { admittedStudents: action.payload.admittedStudents });
        case app.ActionTypes.SET_SHORTLIST_EXPAND_COLLAPSE:
            return Object.assign({}, state, { shortlistExpandCollapse: action.payload.shortlistExpandCollapse });
        case app.ActionTypes.SET_APPLICATION_FORM_TEMPLATES:
            return Object.assign({}, state, { applicationFormTemplates: setApplicationFormTemplateArray(action.payload.applicationFormTemplates) });
        case app.ActionTypes.DISABLE_APPLICATION_FORM_TEMPLATE:
            return Object.assign({}, state, { applicationFormTemplates: disableApplicationFormTemplate(state.applicationFormTemplates, action.payload.id) });
        case app.ActionTypes.ENABLE_APPLICATION_FORM_TEMPLATE:
            return Object.assign({}, state, { applicationFormTemplates: enableApplicationFormTemplate(state.applicationFormTemplates, action.payload.id) });
        case app.ActionTypes.DISABLE_APPLICATION_FORM_TEMPLATE:
            return Object.assign({}, state, { applicationFormTemplates: publishApplicationFormTemplate(state.applicationFormTemplates, action.payload.id) });
        case app.ActionTypes.ENABLE_APPLICATION_FORM_TEMPLATE:
            return Object.assign({}, state, { applicationFormTemplates: unpublishApplicationFormTemplate(state.applicationFormTemplates, action.payload.id) });
        case app.ActionTypes.SET_SELECTED_APPLICATION_FORM_TEMPLATE:
            return Object.assign({}, state, { selectedApplicationFormTemplate: action.payload });
        case app.ActionTypes.SET_SELECTED_APPLICANT:
            return Object.assign({}, state, { selectedApplicant: action.payload });   
        case app.ActionTypes.SET_FORM_TEMPLATE_PREVIEW:
            return Object.assign({}, state, { formTemplatePreview: action.payload.formTemplatePreview });     
        case app.ActionTypes.SET_SUBMITTED_APPLICATIONS:
            return Object.assign({}, state, { submittedApplications: setSubmittedApplications(action.payload.submittedApplications) });    
        case app.ActionTypes.SET_TOTAL_PAGES:
            return Object.assign({}, state, { totalPages: action.payload.totalPages });
            case app.ActionTypes.SET_AADHAR_URL:
                return Object.assign({}, state, { selectedApplicant: setUrl(state.selectedApplicant, action.payload.aadharUrl) });    
        default:
            return state;
    }
}

export const get_courses_admissions = (state: State) => state.courseList;
export const get_seat_matrix = (state: State) => state.seatMatrix;
export const get_selected_seat_matrix = (state: State) => state.selSeatMatrix;
export const get_applicant_details = (state: State) => state.applicant;
export const get_applicants = (state: State) => state.applicantList;
export const get_applicants_not_admitted = (state: State) => state.applicantListNA;
export const get_selected_application = (state: State) => state.selectedApplication;
export const get_coursewise_applications = (state: State) => state.coursewiseApplications;
export const get_seats_available = (state: State) => state.seatsAvailable;
export const get_admissions = (state: State) => state.admissions;
export const get_selected_shortlist = (state: State) => state.selectedShortlist;
export const get_admitted_students = (state: State) => state.admittedStudents;
export const get_expand_collapse = (state: State) => state.shortlistExpandCollapse;

export const get_shortlists = (state: State) => state.shortlists;

export const get_application_form_templates = (state: State) => state.applicationFormTemplates;
export const get_selected_application_form_template = (state: State) => state.selectedApplicationFormTemplate;
export const get_selected_applicant = (state: State) => state.selectedApplicant;
export const get_form_template_preview = (state: State) => state.formTemplatePreview;

export const get_submitted_applications = (state: State) => state.submittedApplications;
export const get_total_pages = (state: State) => state.totalPages;