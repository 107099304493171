import { Action } from '@ngrx/store';
import { type } from '../../../app/util';

export const ActionTypes = {
    SET_SELECTED_USER_LINK: type('[Admin] Set Selected User Link'),
    SET_USER_LINKS: type('[Admin]] Set User Links'),
    UPDATE_DASHBOARD: type('[Admin] Update Dashboard'),
    SET_HTML: type('[Admin] Set Html'),
    SET_COURSES: type('[Admin] Set Courses'),
    SET_COURSES_SECTIONWISE: type('[Admin] Set Courses Section wise')
};

export class SetSelectedUserLink implements Action {
    type = ActionTypes.SET_SELECTED_USER_LINK;
    /**"Id": "" */
    constructor(public payload: any) { }
}

export class SetUserLinks implements Action {
    type = ActionTypes.SET_USER_LINKS;
    constructor(public payload: any) { }
}

export class UpdateDashboard implements Action {
    type = ActionTypes.UPDATE_DASHBOARD;
    constructor(public payload: any) { }
}

export class SetHtml implements Action {
    type = ActionTypes.SET_HTML;
    constructor(public payload: any) { }
}

export class SetCourses implements Action {
    type = ActionTypes.SET_COURSES;
    constructor(public payload: any) { }
}

export class SetCoursesSectionWise implements Action {
    type = ActionTypes.SET_COURSES_SECTIONWISE;
    constructor(public payload: any) { }
}

export type Actions
    = SetSelectedUserLink | SetUserLinks | UpdateDashboard | SetHtml | SetCourses | SetCoursesSectionWise;