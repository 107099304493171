import { ComponentFactoryResolver, Inject, Injectable, Renderer2, RendererFactory2, ViewContainerRef } from "@angular/core";
import { WIDGET_TYPES } from "./injection-token-widget-type";
import { BehaviorSubject } from "rxjs";
import * as R from 'ramda';
import { ChartDashboardModel, ChartWidgetModel, DashPanelModel } from './models';

@Injectable()
export class CreateDynamicComponentService {
    private m!: ChartWidgetModel;
    private dpm!: DashPanelModel;
    private renderer2: Renderer2;
    viewContainerRef: ViewContainerRef;
    createdComponents: any = [];
    componentReferences: any = {};
    dashPanels: any = {};

    constructor(@Inject(WIDGET_TYPES) private contentMappings: any,
        private componentFactoryResolver: ComponentFactoryResolver,
        private rendererFactory: RendererFactory2) {
        this.renderer2 = rendererFactory.createRenderer(null, null);
    }

    createComponent(dashboardJsonContent: any, chartDashboardModel: ChartDashboardModel, container: ViewContainerRef) {
        this.m = new ChartWidgetModel();
        if (!container) return;
        this.viewContainerRef = container;

        if (dashboardJsonContent instanceof Array) {
            if (R.path(['dashboardSpecs', 'groupBy'], chartDashboardModel)) {
                let groupBy = R.pathOr('', ['dashboardSpecs', 'groupBy'], chartDashboardModel);
                if (groupBy instanceof Array) {
                    for (const dashlet of dashboardJsonContent) {
                        if (dashlet.hasOwnProperty("status")) {
                            if (dashlet.status == "enabled") {
                                this.createComponent(dashlet, chartDashboardModel, container);
                            }
                        } else if (R.path(["enabled"], dashlet)) {
                            if (R.intersection(groupBy, R.keys(R.pathOr({}, ['labels'], dashlet))).length == groupBy.length) {
                                if (R.path(['labels'], dashlet)) {
                                    const labels = (x) => dashlet.labels[x];
                                    let label = R.map(labels, groupBy).join(", ");
                                    if (R.path([label], this.dashPanels)) {
                                        this.createComponent(dashlet, chartDashboardModel, this.dashPanels[label].instance["dashPanelPosition"]);
                                    } else {
                                        this.dashPanels[label] = this.renderComp({}, this.contentMappings["dash_panel"], container);
                                        this.dpm = new DashPanelModel();
                                        this.dpm.title = label;
                                        this.dashPanels[label].instance["dpm"] = this.dpm;
                                        this.createComponent(dashlet, chartDashboardModel, this.dashPanels[label].instance["dashPanelPosition"]);
                                    }
                                } else {
                                    this.createComponent(dashlet, chartDashboardModel, container);
                                }
                            }
                        }
                    }
                } else {
                    for (const dashlet of dashboardJsonContent) {
                        if (dashlet.hasOwnProperty("status")) {
                            if (dashlet.status == "enabled") {
                                this.createComponent(dashlet, chartDashboardModel, container);
                            }
                        } else if (R.path(["enabled"], dashlet)) {
                            if (R.path(['labels', groupBy], dashlet)) {
                                let label = R.pathOr('', ['labels', groupBy], dashlet);
                                if (R.path([label], this.dashPanels)) {
                                    this.createComponent(dashlet, chartDashboardModel, this.dashPanels[label].instance["dashPanelPosition"]);
                                } else {
                                    this.dashPanels[label] = this.renderComp({}, this.contentMappings["dash_panel"], container);
                                    this.dpm = new DashPanelModel();
                                    this.dpm.title = label;
                                    this.dashPanels[label].instance["dpm"] = this.dpm;
                                    this.createComponent(dashlet, chartDashboardModel, this.dashPanels[label].instance["dashPanelPosition"]);
                                }
                            } else {
                                this.createComponent(dashlet, chartDashboardModel, container);
                            }
                        }
                    }
                }
            } else {
                for (const dashlet of dashboardJsonContent) {
                    if (dashlet.hasOwnProperty("status")) {
                        if (dashlet.status == "enabled") {
                            this.createComponent(dashlet, chartDashboardModel, container);
                        }
                    } else {
                        this.createComponent(dashlet, chartDashboardModel, container);
                    }
                }
            }
        }
        const type = this.contentMappings[dashboardJsonContent.type];
        if (!type) return;
        const componentRef = this.renderComp(dashboardJsonContent, type, container);

        // this.renderer2.setStyle(componentRef.location.nativeElement, 'width', '48.5%');
        this.renderer2.setStyle(componentRef.location.nativeElement, 'margin', '0 0 1% 1%');
        this.renderer2.addClass(componentRef.location.nativeElement, 'd-flex-item');
        if (dashboardJsonContent.hasOwnProperty("widgetWidth")) {
            // set property "widgetWidth" : "90vw" in dashboard json to set the full width of the widget
            // 90vw for full width, 45vw for half width and 30vw for one third width (default)
            this.renderer2.setStyle(componentRef.location.nativeElement, 'flex-basis', dashboardJsonContent.widgetWidth);
        }
        if (dashboardJsonContent['description']) {
            this.renderer2.setAttribute(
                componentRef.location.nativeElement,
                'title',
                dashboardJsonContent['description']
            );
        }

        if (R.path(['id'], dashboardJsonContent)) {
            this.m.widgetId = dashboardJsonContent['id'];
            this.componentReferences[dashboardJsonContent['id']] = { "componentRef": componentRef };
        }
        this.m.data_values = R.pathOr([], ['data', 'data_values'], dashboardJsonContent);
        this.m.options = R.pathOr({}, ['options'], dashboardJsonContent);
        this.m.title = R.pathOr('', ['title'], dashboardJsonContent);
        this.m.description = R.pathOr('', ['description'], dashboardJsonContent);
        this.m.dashboardId = R.pathOr('', ['dashboardId'], chartDashboardModel);
        this.m.fileName = R.pathOr('', ['fileName'], chartDashboardModel);
        this.m.widgetSpecs = dashboardJsonContent;

        this.renderer2.setAttribute(componentRef.location.nativeElement, 'id', dashboardJsonContent['id']);
        // this.componentReferences[dashboardJsonContent['id']]["modelObserver"] = new BehaviorSubject(this.m);
        componentRef.instance["modelObserver"] = new BehaviorSubject(this.m);
        componentRef.changeDetectorRef.detectChanges();
    }

    renderComp(content: any, type: any, container: ViewContainerRef) {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(type);
        const componentRef = container.createComponent<any>(componentFactory);
        if (componentRef.instance.contentOnCreate) {
            componentRef.instance.contentOnCreate(content);
        }
        this.createdComponents.push(componentRef);
        return componentRef;
    }
    clearAllComponent() {
        this.componentReferences = {};
        for (const compo of this.createdComponents) {
            this.viewContainerRef!.remove();
        }
        this.createdComponents = [];
        this.dashPanels = {};
    }
    updateComponentData(dashboardJson: any) {
        if (Object.values(this.componentReferences).length > 0) {
            dashboardJson.forEach((widget: any) => {
                let m = new ChartWidgetModel();

                m.data_values = R.pathOr([], ['data', 'data_values'], widget);
                m.options = R.pathOr({}, ['options'], widget);
                m.title = R.pathOr('', ['title'], widget);
                m.description = R.pathOr('', ['description'], widget);
                m.widgetId = R.pathOr('', ['id'], widget);

                if (R.path(["componentRef"], this.componentReferences[widget.id])) {
                    try {
                        if (R.path([widget.id, "modelObserver", "closed"], this.componentReferences) == false) {
                            this.componentReferences[widget.id]["componentRef"].instance['modelObserver'] = this.componentReferences[widget.id]["modelObserver"].next(m);
                            this.componentReferences[widget.id]["componentRef"].changeDetectorRef.detectChanges();
                        }
                    } catch (_) { }
                }
            });
        }
    }
}