import * as app from './action';

export interface State {
    userLinks: any[];
    html: any;
    receipts: any;
    selectedReceipt: any;
}

const initialState: State = {
    userLinks: null,
    html: null,
    receipts: {},
    selectedReceipt:null
};

function setRcpts(res): any {
    var rcpts = {};
    rcpts['receipts'] = res.data;
    rcpts['totalPages'] = res.totalPages;
    return rcpts;
}

export function reducer(state = initialState, action: app.Actions): State {
    switch (action.type) {
        case app.ActionTypes.SET_SELECTED_USER_LINK:
            return Object.assign({}, state, { selectedUserLink: action.payload.userLink });
        case app.ActionTypes.SET_USER_LINKS:
            return Object.assign({}, state, { userLinks: action.payload.user_links });
        case app.ActionTypes.UPDATE_DASHBOARD:
            return Object.assign({}, state, { userLinks: action.payload.userLinks });
        case app.ActionTypes.SET_HTML:
            return Object.assign({}, state, { html: action.payload });
        case app.ActionTypes.SET_RECEIPTS:
            return Object.assign({}, state, { receipts: setRcpts(action.payload.receipts) });
        case app.ActionTypes.SET_SELECTED_RECEIPT:
            return Object.assign({}, state, { selectedReceipt: action.payload.selectedReceipt });
        default:
            return state;
    }
}

export const get_state_receipts = (state: State) => state;
export const get_html_receipts  = (state: State) => state.html;
export const get_receipts  = (state: State) => state.receipts;
export const get_selected_receipt  = (state: State) => state.selectedReceipt;