import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { AcademicsService as service } from '../../academics/service/service';
import * as _ from 'underscore';
import * as LC from 'literallycanvas';
import { FileUploader } from 'ng2-file-upload';
import 'brace/index';
import 'brace/theme/eclipse';
import 'brace/mode/markdown';
import 'brace/ext/language_tools';
import * as marked from 'marked';
import * as stopWords from '../../../../assets/dummy/stop-words.json';
import { KatexOptions, MarkdownService } from 'ngx-markdown';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as rootReducer from '../../rootReducer';

class Model {
    options: {
        wrap: true,
        showLineNumbers: false
    };
    showSizeError: boolean = false;
    uploadedFile: any = {};
    vaultImages: any[] = [];
    filterOptions: any = {
        "context": "QA",
        "filterItems": []
    }
    filterOptionsCopy: any = {
        "context": "QA",
        "filterItems": []
    }
    selectedFilter: string = "";
    searchText: string = ""
    mathContent = ``;
    chemContent = ``;
    expressions = ['${a \\over b}$', '$\\sqrt[3]{x}$'];
    chemExpressions = ['$\\ce{H+}$','$\\ce{H2O}$','$\\ce{H2SO4}$','$SO_4+\\downarrow 2H\\overset{H_2SO_4+2S_4}\\rightarrow{H_2+SO_4}\\uparrow$'];
    drawFileName: string = "";
    imageModalOpen: boolean = false;
    initQryStr: string = '?$orderby=createdOn%20desc&$skip=0&$top=1024&$search=media:true';
    imageHeight: number = 100;
    imageWidth: number = 300;
    selectedImageSize: string = "default";
    allTags:any;
    currentPersona:any;
    table:string = `| Column-1 | Column-2 | Column-3 |
| -------- |:--------:| --------:|
| Row 1    | Row 1    | Row 1    |`;
}

const URL = '';

@Component({
    selector: 'qa-editor',
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
    providers: [service],
})
export class QaEditorComponent implements OnInit {
    lc: any;
    public options: KatexOptions = {
        displayMode: false,
        throwOnError: false,
        errorColor: '#cc0000',
    };
    ts:string="1";
    constructor(private store: Store<rootReducer.State>,private el: ElementRef, public service: service, private markdownService: MarkdownService) {
        this.uploader = new FileUploader({
            url: URL,
            disableMultipart: true, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
            formatDataFunctionIsAsync: true,
            formatDataFunction: async (item) => {
                return new Promise((resolve, reject) => {
                    resolve({
                        name: item._file.name,
                        length: item._file.size,
                        contentType: item._file.type,
                        date: new Date()
                    });
                });
            }
        });
    }
    @ViewChild('editor') editor;
    @Input() q: string = "";
    @Input() question: any = {};
    @Input() indx: number = 0;
    @Input() ansIndx: number = 0;
    @Input() qIndx: number = 0;
    @Input() optionIndx: number = 0;
    @Input() isParentQ: boolean = false;
    @Input() isAns: boolean = false;
    @Input() isOption: boolean = false;
    @Input() showMark: boolean = true;
    @Output() updateQ = new EventEmitter<any>();
    @Output() selectedAnswer = new EventEmitter<any>();
    @Output() selectedOption = new EventEmitter<any>();
    allTags$: Observable<any>;
    fileSizeInBytes: number;
    uploader: FileUploader;
    public m: Model;
    ngOnInit() {
        this.init_model();
        this.sub_store();
    }
    ngAfterViewInit() {
        this.editor.getEditor().setOptions({
            enableBasicAutocompletion: true,
            enableSnippets: true,
            enableLiveAutocompletion: true,
            wrap: true
        });
        this.editor.getEditor().focus();
    }

    onPaste(e) {
      const items = (e.clipboardData || e.originalEvent.clipboardData).items;
      var blob = null;
      for (const item of items) {
        if (item.type.indexOf("image") === 0) {
          blob = item.getAsFile();
        }
      }
      var imageFile = blob;
      var acadYear = "";
      if (this.m.currentPersona) { acadYear = new Date(this.m.currentPersona.acadYearFrom).getFullYear().toString().substring(2, 4) + "-" + new Date(this.m.currentPersona.acadYearTo).getFullYear().toString().substring(2, 4); }
      var artefact_meta = {
        'Title': imageFile.name,
        'Description': "Image for Assessment question",
        'MetaData': {}
      };
      if(imageFile && acadYear){
        this.service.saveImage(imageFile,artefact_meta,acadYear).subscribe(res => {
          this.undo();
          if (res) {
            var uploadedFile = res;
            if(uploadedFile && uploadedFile['url']){
              var urlArr =  uploadedFile['url'].split('upload');
              if(urlArr && urlArr.length>0){
                var url_with_dimensions = urlArr[0] + "upload/c_scale,h_" + this.m.imageHeight.toString() + ",q_90,w_" + this.m.imageWidth.toString() + ",e_make_transparent" + urlArr[1];
                uploadedFile['url'] = url_with_dimensions;
                this.insertImage(uploadedFile)
              }
            }
          }
        });  
      }
    }

    init_model(){
        this.m = new Model()
      }
      sub_store(){
        this.allTags$ = this.store.pipe(select(rootReducer.get_all_tags))
        this.allTags$.subscribe(allTagsData => {
          let tags = {
            "resourceType":"QA",
            "list": []
          }
          if(allTagsData && allTagsData.length>0){
            tags.list = allTagsData;
          }
          this.m.allTags = Object.assign({}, tags)
          if(this.m.allTags.list && this.m.allTags.list.length>0){
            var tagFilter = [];
            this.m.allTags.list.forEach(tg => {
              if(tg.status==1){
                var tagFilterObj = {};
                tagFilterObj['name'] = tg.name;
                tagFilterObj['checked'] = false;
                tagFilterObj['children'] = [];
                tagFilterObj['fe'] = "tags:" + tg.name;
                tagFilter.push(tagFilterObj);
                this.m.filterOptions.filterItems = tagFilter;
                this.m.filterOptionsCopy = JSON.parse(JSON.stringify(this.m.filterOptions));
              }
            });
          }
        })
    
        this.store.select(rootReducer.get_state_app).subscribe(state => {
          if (state){ 
            this.m.currentPersona = state.currentPersona;
            var dt = new Date();
            var ts = dt.getFullYear().toString() + (dt.getMonth()+1).toString() + dt.getDate().toString() + dt.getHours().toString() + dt.getSeconds().toString();
            this.m.drawFileName =  this.m.currentPersona.clientShortName + "-" + ts.toString();
          }
        });
      }
    bold() {
        var selText = this.editor.getEditor().getSelectedText();
        if (selText) {
            this.editor.getEditor().insertSnippet("**${1:$SELECTION}**");
            this.editor.getEditor().renderer.scrollCursorIntoView();
        }
    }
    italic() {
        var selText = this.editor.getEditor().getSelectedText();
        if (selText) {
            this.editor.getEditor().insertSnippet("*${1:$SELECTION}*");
            this.editor.getEditor().renderer.scrollCursorIntoView();
        }
    }
    underline(){
      var selText = this.editor.getEditor().getSelectedText();
      if(selText){
        this.editor.getEditor().insertSnippet("<u>${1:$SELECTION}</u>");
        this.editor.getEditor().renderer.scrollCursorIntoView();
      }
    }
    undo() {
        this.editor.getEditor().undo();
    }
    redo() {
        this.editor.getEditor().redo();
    }
    copyAnswer() {
      var selText = this.editor.getEditor().getSelectedText();
      var selTextArr = selText.split("\n");
      if(selTextArr && selTextArr.length>0){
        selTextArr.forEach(text => {
          if(text){
            var selTextObj = {selText:"", qIndex:0, subQIndex:0};
            selTextObj.selText = text;
            selTextObj.qIndex = this.qIndx;
            selTextObj.subQIndex = this.indx;
            if(selTextObj.selText){ 
              this.selectedAnswer.emit(selTextObj); 
            }
          }
        });
        this.editor.getEditor().insertSnippet("");
        this.editor.getEditor().renderer.scrollCursorIntoView();
      }
    }
    copyOption() {
      var selText = this.editor.getEditor().getSelectedText();
      var selTextArr = selText.split("\n");
      if(selTextArr && selTextArr.length>0){
        selTextArr.forEach(text => {
          if(text){
            var selTextObj = {selText:"", qIndex:0, subQIndex:0};
            selTextObj.selText = text;
            selTextObj.qIndex = this.qIndx;
            selTextObj.subQIndex = this.indx;
            if(selTextObj.selText){ 
              this.selectedOption.emit(selTextObj); 
            }
          }
        });
        this.editor.getEditor().insertSnippet("");
        this.editor.getEditor().renderer.scrollCursorIntoView();
      }
    }
    get fileSizeInKb(): number {
        return this.fileSizeInBytes / 1024;
    }
    sizeWithinLimits(): boolean {
        return this.fileSizeInKb <= 500;
    }
    ut_upload(e) {
        this.m.showSizeError = false;
        if (e && e[0].name) {
            var acadYear = "";
            if (this.m.currentPersona) { acadYear = new Date(this.m.currentPersona.acadYearFrom).getFullYear().toString().substring(2, 4) + "-" + new Date(this.m.currentPersona.acadYearTo).getFullYear().toString().substring(2, 4); }
            const arte_meta = {
                'Title': e[0].name,
                'Description': "Image for Assessment question",
                'MetaData': {}
            };
            this.fileSizeInBytes = e[0].size;
            const size_within_limits = this.sizeWithinLimits();
            if (size_within_limits && acadYear != "") {
                this.service.uploadFiles(e, arte_meta, acadYear).subscribe(res => {
                    if (res) {
                        this.m.uploadedFile = res;
                        if (this.m.uploadedFile && this.m.uploadedFile.url) { this.insertImage(this.m.uploadedFile) }
                    }
                });
            }
            else { this.m.showSizeError = true; }
        }
    }
    ut_uploadFile() {
        this.m.showSizeError = false;
        const inputEl: HTMLInputElement = this.el.nativeElement.querySelector('#file');
        if (inputEl.files[0] && inputEl.files[0].name) {
            var acadYear = "";
            if (this.m.currentPersona) { acadYear = new Date(this.m.currentPersona.acadYearFrom).getFullYear().toString().substring(2, 4) + "-" + new Date(this.m.currentPersona.acadYearTo).getFullYear().toString().substring(2, 4); }
            const arte_meta = {
                'Title': inputEl.files[0].name,
                'Description': "Image for Assessment question",
                'MetaData': {}
            };
            this.fileSizeInBytes = inputEl.files[0].size;
            const size_within_limits = this.sizeWithinLimits();
            if (size_within_limits && acadYear != "") {
                this.service.uploadFiles(inputEl.files, arte_meta, acadYear).subscribe(res => {
                    if (res) {
                        this.m.uploadedFile = res;
                        if (this.m.uploadedFile && this.m.uploadedFile.url) { this.insertImage(this.m.uploadedFile) }
                    }
                });
            }
            else { this.m.showSizeError = true; }
        }
    }
    insertVaultImage(img) {
        if (img.selected) {
            var urlArr = img['url'].split('upload/e_make_transparent');
            if (urlArr && urlArr.length > 0) {
                var url_with_dimensions = urlArr[0] + "upload/c_scale,h_" + this.m.imageHeight.toString() + ",q_90,w_" + this.m.imageWidth.toString() + ",e_make_transparent" + urlArr[1];
                img['url'] = url_with_dimensions;
                this.insertImage(img);
            }
        }
    }
    insertImage(img) {
        if (img && img.url) {
            var imgLine = "\n\n![](" + img.url + ")";
            this.editor.getEditor().session.insert(this.editor.getEditor().getCursorPosition(), imgLine);
            this.editor.getEditor().renderer.scrollCursorIntoView();
        }
    }
    ut_getVaultImages(qryString) {
        if (this.m.imageModalOpen) {
            this.service.getVaultImages(qryString).subscribe((res: any) => {
                if (res && res.data && res.data.length > 0) {
                    this.m.vaultImages = res.data;
                    this.m.vaultImages.forEach(img => {
                        img.selected = false;
                        var urlArr = img['url'].split('upload');
                        if (urlArr && urlArr.length > 0) {
                            var url_with_transparent_bg = urlArr[0] + "upload/e_make_transparent" + urlArr[1];
                            img['url'] = url_with_transparent_bg;
                        }
                    });
                }
                else {
                    this.m.vaultImages = [];
                }
            });

        }
    }
    onQry(qStr: string) {
        if (qStr) {
            this.ut_getVaultImages(qStr);
        }
    }
    saveImage(){
        var fn = this.m.drawFileName + ".png";
        var imageBlob = new Blob([this.lc.getImage().toDataURL()], { type: "image/png" });
        var imageFile = new File([imageBlob], fn, {type: "image/png"});
        var acadYear = "";
        if (this.m.currentPersona) { acadYear = new Date(this.m.currentPersona.acadYearFrom).getFullYear().toString().substring(2, 4) + "-" + new Date(this.m.currentPersona.acadYearTo).getFullYear().toString().substring(2, 4); }
        var artefact_meta = {
          'Title': this.m.drawFileName,
          'Description': "Image for Assessment question",
          'MetaData': {}
        };
        if(imageFile && acadYear){
          this.service.saveImage(imageFile,artefact_meta,acadYear).subscribe(res => {
            if (res) {
              var uploadedFile = res;
              if(uploadedFile && uploadedFile['url']){
                var urlArr =  uploadedFile['url'].split('upload');
                if(urlArr && urlArr.length>0){
                  var url_with_dimensions = urlArr[0] + "upload/c_scale,h_" + this.m.imageHeight.toString() + ",q_90,w_" + this.m.imageWidth.toString() + ",e_make_transparent" + urlArr[1];
                  uploadedFile['url'] = url_with_dimensions;
                  this.insertImage(uploadedFile)
                }
              }
            }
          });  
        }
      }
      initLC() {
        if (!this.lc) {
          setTimeout(() => {
            var backgroundImage = new Image()
            backgroundImage.src = '/assets/img/grid.png';
            this.lc = LC.init(this.el.nativeElement.querySelector('.qaDraw'), { 
              imageURLPrefix: '/assets/img/lc-img',
              tools: [LC.tools.Pencil, LC.tools.Eraser, LC.tools.Line, LC.tools.Rectangle, LC.tools.Ellipse, LC.tools.Polygon, LC.tools.Text, LC.tools.Pan, LC.tools.SelectShape],
              backgroundShapes: [LC.createShape('Image', {x: 0, y: 0, image: backgroundImage, scale: 1})] 
            });
          }, 100);
        }
      }
      selectExpression(exp){
        if(exp){ this.m.mathContent = this.m.mathContent + " " + exp; }
      }
      selectChemExpression(exp){
        if(exp){ this.m.chemContent = this.m.chemContent + " " + exp; }
      }
      insertExpression(){
        if(this.m.mathContent && this.m.mathContent.length){
          var mathLine = " " + this.m.mathContent;
          this.editor.getEditor().session.insert(this.editor.getEditor().getCursorPosition(), mathLine);
          this.m.mathContent = ""
        }
      }
      insertChemExpression(){
        if(this.m.chemContent && this.m.chemContent.length){
          var chemLine = " " + this.m.chemContent;
          this.editor.getEditor().session.insert(this.editor.getEditor().getCursorPosition(), chemLine);
          this.m.chemContent = ""
        }
      }
      getHtmlText(q){
        if(q){
          let c = this.markdownService.compile(q);
          c = this.markdownService.renderKatex(c);
          return c;
        }
        else{ return ""; }
      }
      initImgModal(txt){
        this.m.searchText = txt;
        this.m.filterOptionsCopy = JSON.parse(JSON.stringify(this.m.filterOptions));
        this.ut_getVaultImages(this.m.initQryStr);
      }
      updateImageSize(){
        if(this.m.selectedImageSize=="small"){ 
          this.m.imageHeight=75; 
          this.m.imageWidth=90
        }
        else if(this.m.selectedImageSize=="default"){
          this.m.imageHeight=100; 
          this.m.imageWidth=300;
        }
        else if(this.m.selectedImageSize=="large"){
          this.m.imageHeight=200; 
          this.m.imageWidth=600;
        }
      }
      qChange(event){
        if(event){
          var qObj = {q:{},index:null,qIndex:null,ansIndex:null,optionIndex:null,isAns:null,isOption:null};
          qObj.q = event;
          qObj.index = this.indx;
          qObj.ansIndex = this.ansIndx;
          qObj.optionIndex = this.optionIndx;
          qObj.isAns = this.isAns;
          qObj.isOption = this.isOption;
          qObj.qIndex = this.qIndx;
          this.updateQ.emit(qObj);
        }
      }
      insertTable(){
        this.editor.getEditor().session.insert(this.editor.getEditor().getCursorPosition(), this.m.table);
      }
}