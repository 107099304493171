import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { PayuPaymentService as service } from './service/service';
import * as _ from 'underscore';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription, Subject } from 'rxjs';
import * as rootReducer from '../../rootReducer';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

class TxData {
  key: string;
  txnid: string;
  hash: string;
  amount: string;
  firstname: string;
  email: string;
  phone: string;
  productinfo: string;
  udf1: string;
  udf2: string;
  udf3: string;
  surl: string;
  furl: string;
}

class Model {
  boltCdn = "https://sboxcheckout-static.citruspay.com/bolt/run/bolt.js";
  btlLogo = "https://bitloka.com/img/about/abt-btl.png";
  txId: string = "";
  hash: string = "";
  payuKey: string = "";
}

@Component({
  selector: 'payu-payment',
  templateUrl: './component.html',
  styleUrls: ['./component.css'],
  providers: [service],
})
export class PayuPaymentComponent implements OnInit, OnDestroy {
  constructor(private store: Store<rootReducer.State>,
    private el: ElementRef,
    public service: service,
    private router: Router) {
  }
  @Input() txData: TxData;
  @Output() txStatus = new EventEmitter();
  public m: Model;

  ngOnInit() {
    this.init_model();
    this.sub_store();
     this.loadBoltScript(this.m.boltCdn);
  }
  ngOnDestroy() {
  }
  ngAfterViewInit() {
  }
  init_model() {
    this.m = new Model();
    this.m.payuKey = environment.PAYU_KEY;
  }
  sub_store() {
  }
  launchBolt() {
    var that = this;
    var Handler = {
      responseHandler: function (resp) {
        that.txStatus.emit(resp);
      },
      catchException: function (err) {
        that.txStatus.emit(err);
        console.log(err);
      }
    };
    (<any>window).bolt.launch(this.txData, Handler);
  }
  loadBoltScript(url: string) {
    var that = this;
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script['id'] = "bolt";
    script.src = url;
    script['bolt-color'] = "#eee";
    script['bolt-logo'] = this.m.btlLogo;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
    script.onload = function () {
      that.launchBolt();
    };
  }
}