import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { AcademicsService as acadService } from '../../academics/service/service';
import * as actions from '../../academics/store/action';
import { Router } from '@angular/router';

import * as rootReducer from '../../rootReducer';

import { PublishedContentService as service } from './service/service';

class Model {
  topics: any[] = [];
}

@Component({
  selector: 'published-content',
  templateUrl: './component.html',
  styleUrls: ['./component.css'],
  providers: [acadService, service]
})
export class PublishedContentComponent implements OnInit {
  constructor(private store: Store<rootReducer.State>, public acadService: acadService, public service: service, private router: Router) { }

  public m: Model;
  @Input() pin: boolean;

  ngOnInit() {
    this.init_model();
    this.init_store();
    this.sub_store();
  }

  init_model() {
    this.m = new Model();
  }
  init_store() { }
  sub_store() {
    this.m.topics = [{
      "id": "245b3029-2161-4506-8199-934f73e52a4d",
      "title": "Admissions",
      "fileName": "abhay@bitloka.com_1617765978012.json"
    }]
  }

  ut_viewTopic(topic) {
    if (topic) {
      this.acadService.getDocContent(topic.id).subscribe(res => {
        if (res && res['title']) {
          let docObj = { id: topic.id, fileName: topic.fileName, content: res, viewOnly: true }
          this.store.dispatch(new actions.SetSelectedTopic(docObj));
          this.router.navigate(['/lms/curriculum/edit']);
        }
      })
    }
  }

  pinChart() {
    this.pin = false;
    let payload = ["published_content"];
    this.service.pinWidget(payload).subscribe(res => {
      if (res) this.service.getDashboardWidgets();
    });

  }
  unpinChart() {
    this.pin = true;
    let payload = ["published_content"];
    this.service.unpinWidget(payload).subscribe(res => {
      if (res) this.service.getDashboardWidgets();
    });
  }
}