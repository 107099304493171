import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';

import * as rootReducer from '../../rootReducer';
import * as orgAction from '../../org/store/action';
import { InstSetupService as service } from './service/service';

class Persona {
    count: number;
    emails: string[];
    title: string;
}

class InstituteData {
    acad_year_from: string;
    acad_year_to: string;
    address: string;
    inst_id: string;
    logo_url: string;
    name: string;
    personas: Persona[];
    short_name: string;
    users: number;
}

class Model {
    showComponent: boolean; //if content is okay then hide the card
    instData: InstituteData;
    ctContext: any;
}
@Component({
    selector: 'institute-setup',
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
    providers: [service]
})

export class InstituteSetupComponent {
    public m: Model;
    @Input() pin: boolean;
    constructor(private router: Router, private service: service, private store: Store<rootReducer.State>) { }
    ngOnInit() {
        this.init_model();
        this.init_store();
    }
    init_model() {
        this.m = new Model();
    }

    init_store() {
        this.store.select(rootReducer.get_state_app).subscribe((res: any) => {
            this.m.ctContext = res;
        });

        this.store.select(rootReducer.get_institute_setup_widget).subscribe((res: any) => {
            if (res) {
                this.m.instData = res;
            }
        });
    }

    ut_routeToPersonaAdd() {
        let selectedClient = {
            "id": this.m.ctContext.currentPersona.clientId,
            "name": this.m.ctContext.currentPersona.clientName,
            "isGroup": false
        };
        this.store.dispatch(new orgAction.SetSelectedClient({ "selectedClient": selectedClient }));
        this.router.navigate(['/admin/inst/persona/add']);
    }

    ut_routeToUserAdd() {
        let selectedClient = {
            "id": this.m.ctContext.currentPersona.clientId,
            "name": this.m.ctContext.currentPersona.clientName,
            "isGroup": false
        };
        this.store.dispatch(new orgAction.SetSelectedClient({ "selectedClient": selectedClient }));
        this.router.navigate(['/admin/inst/user/add']);
    }

    ut_routeToInstEdit() {
        var inst_id = this.m.ctContext.currentPersona.clientId;
        this.service.getInst(inst_id);
    }

    ut_setAcadYear() {
        var inst_id = this.m.ctContext.currentPersona.clientId;
        this.service.getInst(inst_id);
    }

    pinChart() {
        this.pin = false;
        let payload = ["inst_setup"];
        this.service.pinWidget(payload).subscribe(res => {
            if (res) this.service.getDashboardWidgets();
        });

    }
    unpinChart() {
        this.pin = true;
        let payload = ["inst_setup"];
        this.service.unpinWidget(payload).subscribe(res => {
            if (res) this.service.getDashboardWidgets();
        });
    }
}