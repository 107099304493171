import { Injectable } from '@angular/core';

import { CTApi } from '../../../app/service/ct-api';

import { Store } from '@ngrx/store';
import * as rootReducer from '../../../app/rootReducer';

import * as ngxQryOptionsAction from '../store/action';
import { Observable } from 'rxjs/Observable';
import 'rxjs/Rx';


@Injectable()
export class NgxFilterService {
    constructor(public ctapi: CTApi, private store: Store<rootReducer.State>, ) { }
}
