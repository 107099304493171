import * as app from './action';
import * as Data from '../timetable/data.js';
import * as Data1 from '../sec-stf-sub-matrix/data.js';

import * as TTHelper from '../tt-helper.js';
import * as _ from 'underscore';

var sectionsCount: number = 12;
var staffCount: number = 20;
var subjectsCount: number = 18;
var weeklyPattern: string = "85";
var maxPPW: number = 27;
var totalPeriodsPerSection: number = 45;
var additionalStaffCount: number = 0;
var locationsCount: number = 20;

function get_staff_count(sections_count, max_ppw) {
    let staff_count = Math.ceil((totalPeriodsPerSection * sections_count) / max_ppw);
    return staff_count;
}

class MatrixChangeSet {
    k: string;
    v: string;
}

class Staff {
    id: string = "";
    name: string = "";
}

class Subject {
    id: string = "";
    name: string = "";
}

class MatrixCell {
    displayText: string;
    ppw: number;
    staff: Staff[] = [];
}

class SubjectRow {
    id: string;
    name: string;
    cells: MatrixCell[] = [];
}

class Section {
    id: string;
    name: string;
}

class Timetable {
    course: string = "";
    ttData: Data[] = [];
}

class TTData {
    dow: string = "";
    cells: Cell[] = [];
}

class Cell {
    period: string = "";
    subject: string = "";
    teacher: string = "";
    ppw: string = "";
    weight: string = "";
}

class Model {
    csv: string;
    currentComputedCount: number;
    ttId: string;
    ttPoolCSV: string;
}

class TTInput {
    sectionsCount: number;
    staffCount: number;
    subjectsCount: number;
    maxPPW: number;
    weeklyPattern: string;
    totalPeriodsPerSection: number;
    additionalStaffCount: number;
    locationsCount: number;
}

function setCSV(ttInput: TTInput, state: State) {
    if (!state.isCSVSet) {
        let weeklyPattern = ttInput.weeklyPattern;
        let sectionsCount = ttInput.sectionsCount;
        let maxPPW = ttInput.maxPPW;

        let csv = Data.blank_csv(sectionsCount, weeklyPattern, maxPPW);
        state.model = Object.assign({ ...state.model }, {
            "csv": csv.toString().trim(),
            "currentComputedCount": 0,
            "ttId": "",
            "ttPoolCSV": ""
        });

        state.baseCSV = csv.trim();
    }
    return ttInput;
}

function setModelAndNames(csv, currentComputedCount, ttId, ttPoolCSV, state) {
    let sections = state.sections;
    let staff = state.staff;
    let subjects = state.subjects;

    // additional_staff TBD


    let json = TTHelper.papa_parse_csv_to_json(csv.trim());

    json.map(item => {
        let section = sections.find(sec => sec.id === item.sec);
        item.sec_name = section ? section.name : "";

        let stf_arr = item.stf ? item.stf.split(',') : [];
        let stf_name_arr = [];
        stf_arr.map(stf => {
            let _staff = staff.find(sf => sf.id === stf);
            if (_staff) stf_name_arr.push(_staff.name);
        });
        item.stf_name = stf_name_arr.join(',');

        // let _staff = staff.find(sf => sf.id === item.stf);
        // item.stf_name = _staff ? _staff.name : '';

        let subject = subjects.find(sub => sub.id == item.sub);
        item.sub_name = subject ? subject.name : '';
        return item;
    });

    let final_csv = TTHelper.papa_parse_json_to_csv(json);

    state.model = Object.assign({ ...state.model }, {
        "csv": final_csv,
        "currentComputedCount": currentComputedCount,
        "ttId": ttId,
        "ttPoolCSV": ttPoolCSV
    });

    state.baseCSV = final_csv;
    return state.model;
}

function setSubjectList(subjects, state) {
    let subject_list: Subject[] = [];
    subjects.map(sub => {
        let _sub = { "id": sub.id, "name": sub.name };
        subject_list.push(_sub);
        return;
    });

    Data.set_subject_list(subject_list);

    if (state.subjectRows) {
        state.subjectRows = state.subjectRows.map(sr => {
            let s = _.find(subject_list, function (v) { return v.id == sr.id });
            sr.name = s.name;
            return sr;
        });
    }
    if (state.model) {
        let csv = state.model.csv;
        let json = TTHelper.papa_parse_csv_to_json(csv.trim());
        json.map(item => {
            let s = _.find(subject_list, function (v) { return v.id == item.sub });
            item.sub_name = s ? s.name : item.sub;
            return item;
        });

        let final_csv = TTHelper.papa_parse_json_to_csv(json);
        state.model = Object.assign({ ...state.model }, {
            "csv": final_csv.trim(),
            "currentComputedCount": 0,
            "ttId": "",
            "ttPoolCSV": ""
        });
    }
    return subjects;
}

function setStaffList(staff, state) {
    let staff_list: Staff[] = [];
    staff.map(stf => {
        let _staff = { "id": stf.id, "name": stf.name };
        staff_list.push(_staff);
    });
    Data.set_teacher_list(staff_list);
    // Data.set_teacher_count(staff_list.length);

    if (state.model) {
        let csv = state.model.csv;
        let json = TTHelper.papa_parse_csv_to_json(csv.trim());
        json.map(item => {
            let sf = _.find(staff_list, function (v) { return v.id == item.stf });
            item.stf_name = sf ? sf.name : item.stf;
            return item;
        });

        let final_csv = TTHelper.papa_parse_json_to_csv(json);
        state.model = Object.assign({ ...state.model }, {
            "csv": final_csv.trim(),
            "currentComputedCount": 0,
            "ttId": "",
            "ttPoolCSV": ""
        });
    }
    return staff;
}

function setAdditionalStaffList(additionalStaff, state) {
    let staff_list: Staff[] = [];
    additionalStaff.map(stf => {
        let _staff = { "id": stf.id, "name": stf.name };
        staff_list.push(_staff);
    });
    Data.set_additional_teacher_list(staff_list);
    Data.set_additional_teacher_count(staff_list.length);

    return additionalStaff;
}

function setSectionList(sections, state) {
    let section_list: Section[] = [];
    sections.map(sec => {
        let _section = { "id": sec.id, "name": sec.name };
        section_list.push(_section);
    });
    Data.set_section_list(section_list);
    Data.set_sections_count(section_list.length);

    if (state.view == "teacherwiseTT") { state.view = "classwiseTT"; }

    if (state.model) {
        let csv = state.model.csv;
        let json = TTHelper.papa_parse_csv_to_json(csv.trim());
        json.map(item => {
            let _sec = _.find(section_list, function (v) { return v.id == item.sec });
            item.sec_name = _sec ? _sec.name : item.sec;
            return item;
        });

        let final_csv = TTHelper.papa_parse_json_to_csv(json);
        state.model = Object.assign({ ...state.model }, {
            "csv": final_csv.trim(),
            "currentComputedCount": 0,
            "ttId": "",
            "ttPoolCSV": ""
        });
    }
    return sections;
}

function setUploadedData(uploadedCSV, state) {
    state.isCSVSet = true;
    state.secSubStfNamesSet = true;

    state.model = Object.assign({ ...state.model }, {
        "csv": uploadedCSV.toString().trim(),
        "currentComputedCount": 0,
        "ttId": "",
        "ttPoolCSV": ""
    });

    state.baseCSV = uploadedCSV.trim();

    let json = TTHelper.papa_parse_csv_to_json(uploadedCSV.trim());

    let weeklyPattern = json[0].sec_ppw.toString();
    let maxPPW = json[0].stf_max_ppw;
    state.ttInput = Object.assign({}, state.ttInput, { "weeklyPattern": weeklyPattern, "maxPPW": maxPPW });

    setSectionsState(json, state, maxPPW);

    setStaffState(json, state);

    setSubjectsState(json, state);

    setLocationsState(json, state);

    return uploadedCSV;
}

function setLocationsState(json, state){
    let locations = state.locations;
}

function setSubjectsState(json, state) {
    let sections = state.sections;
    let subject_list: SubjectRow[] = [];
    var groups_by_sub = _
        .chain(json)
        .groupBy('sub')
        .map(function (value, key) {
            if (key != '') {
                let first_val = value[0];
                let subject_row = new SubjectRow();
                subject_row.id = key;
                subject_row.name = first_val.sub_name;
                sections.forEach(sec => {
                    let cell = new MatrixCell();
                    subject_row.cells.push(cell);
                });
                return subject_list.push(subject_row);
            }
        }).value()
    state.subjects = state.subjects.map(sub => {
        let s = _.find(subject_list, function (x) { return x.id == sub.id });
        sub.name = s ? s.name : sub.name;
        return Object.assign({}, sub);
    }).slice(0);

    Data.set_subject_list(subject_list);
}

function setStaffState(json, state) {
    let staff_list: Staff[] = [];
    var groups_by_stf = _
        .chain(json)
        .groupBy('stf')
        .map(function (value, key) {
            if (key != '') {
                let val = value[0];
                let staff = new Staff();
                staff.id = key;
                staff.name = val.stf_name;
                return staff_list.push(staff);
            }
        }).value()

    Data.set_teacher_list(staff_list);

    state.staff = state.staff.map(stf => {
        let s = _.find(staff_list, function (x) { return x.id == stf.id });
        stf.name = s ? s.name : stf.name;
        return Object.assign({}, stf);
    }).slice(0);
}

function setSectionsState(json, state, maxPPW) {
    let sections: Section[] = [];
    var groups_by_sec = _
        .chain(json)
        .groupBy('sec')
        .map(function (value, key) {
            if (key != '') {
                let val = value[0];
                let sec = new Section();
                sec.id = key;
                sec.name = val.sec_name;
                return sections.push(sec);
            }
        }).value();

    let sections_count = sections.length;

    let sectionRows = Data1.sections.slice(0, sections_count);
    state.sections = sectionRows.map(sec => {
        let s = _.find(sections, function (x) { return x.id == sec.id });
        sec.name = s ? s.name : sec.name;
        return Object.assign({}, sec);
    }).slice(0);

    Data.set_section_list(sections);
    let staff_count = get_staff_count(sections_count, maxPPW);
    state.ttInput = Object.assign({}, state.ttInput, { "sectionsCount": sections_count, "staffCount": staff_count });
}

function resetTT(state) {
    let csv = Data.blank_csv(sectionsCount, weeklyPattern, maxPPW);
    state.view = "classwiseTT";

    let tt_model = { "csv": csv.toString().trim(), "currentComputedCount": 0, "ttId": "", "ttPoolCSV": "" };
    state.model = Object.assign({}, state.model, tt_model);

    state.baseCSV = csv.trim();
    state.isCSVSet = false;
    state.secSubStfNamesSet = false;
    state.matrixChangeSet = [];
    state.uploadedCSV = '';
    state.sampleCSV = '';
    state.matrixViewTeacherwise = false;
    state.showPeriods = false;
    // state.additionalStaffCount = additionalStaffCount;

    let input: TTInput = { "sectionsCount": sectionsCount, "staffCount": staffCount, "subjectsCount": subjectsCount, "maxPPW": maxPPW, "weeklyPattern": weeklyPattern, "totalPeriodsPerSection": totalPeriodsPerSection, "additionalStaffCount": additionalStaffCount, "locationsCount": locationsCount };
    state.ttInput = Object.assign({}, state.ttInput, input);

    let section_list = Data1.sections.slice(0, sectionsCount);
    state.sections = section_list.map(sec => {
        sec.name = sec.id;
        return sec;
    });

    Data.set_section_list(section_list);

    let staff_list = Data1.getStaffRows(sectionsCount, staffCount);
    state.staff = staff_list.map(stf => {
        stf.name = stf.id;
        return stf;
    });

    Data.set_teacher_list(staff_list);

    let additional_staff_list = Data1.getAdditionalStaffRows(sectionsCount, additionalStaffCount);
    state.additionalStaff = additional_staff_list.map(stf => {
        stf.name = stf.id;
        return stf;
    });

    Data.set_additional_teacher_list(additional_staff_list);

    let subject_list = Data1.getSubjectRows(sectionsCount, subjectsCount);
    state.subjects = subject_list.map(sub => {
        sub.name = sub.id;
        return sub;
    });

    Data.set_subject_list(subject_list);

    state.subjectRows = subject_list.map(row => {
        row.name = row.id;
        row.cells.map(cell => {
            cell.displayText = "";
            cell.ppw = 0;
            cell.staff = [];
            return cell;
        })
        return row;
    }).slice(0)

    state.staffRows = staff_list.map(row => {
        row.name = row.id;
        row.subjects.map(sub => {
            sub.name = "";
            return sub;
        })
        return row;
    }).slice(0)
}
function clearUploadedOrSampleCSV(state) {
    state.sampleCSV = '';
    state.uploadedCSV = ''
}

function setTTInput(state) {
    if (!state.isCSVSet) {
        let input: TTInput = { "sectionsCount": sectionsCount, "staffCount": staffCount, "subjectsCount": subjectsCount, "maxPPW": maxPPW, "weeklyPattern": weeklyPattern, "totalPeriodsPerSection": totalPeriodsPerSection, "additionalStaffCount": additionalStaffCount, "locationsCount": locationsCount };
        state.ttInput = Object.assign({}, state.ttInput, input);

        Data.set_ppw(totalPeriodsPerSection);

        let csv = Data.blank_csv(sectionsCount, weeklyPattern, maxPPW);
        state.model = Object.assign({ ...state.model }, {
            "csv": csv.toString().trim(),
            "currentComputedCount": 0,
            "ttId": "",
            "ttPoolCSV": ""
        });

        state.baseCSV = csv.trim();
        return;
    }
    return;
}

function subjectWeightChanged(csv, state) {
    state.model = Object.assign({ ...state.model }, {
        "csv": csv.toString().trim(),
        "currentComputedCount": 0,
        "ttId": "",
        "ttPoolCSV": ""
    });
    return;
}

export interface State {
    model: Model;
    ttInput: TTInput;
    uploadedCSV: string;
    sampleCSV: string;
    sections: Section[],
    staff: Staff[],
    subjects: SubjectRow[],
    staffRows: any[],
    additionalStaffRows: any[],
    subjectRows: SubjectRow[],
    baseCSV: string,
    matrixChangeSet: MatrixChangeSet[],
    isCSVSet: boolean,
    matrixViewTeacherwise: boolean
    sectionRows: Section[],
    view: string,
    secSubStfNamesSet: boolean,
    additionalStaff: Staff[],
    showPeriods: boolean,
    showLocations: boolean,
    //showSubjectWeight: boolean,
    subjectWeight: number,
    isTTRun: boolean,
    assignSubjectWeightPPW: boolean
}

const initialState: State = {
    model: null,
    ttInput: null,
    uploadedCSV: '',
    sampleCSV: '',
    sections: [],
    staff: null,
    subjects: null,
    staffRows: null,
    additionalStaffRows: null,
    subjectRows: null,
    baseCSV: '',
    matrixChangeSet: [],
    isCSVSet: false,
    matrixViewTeacherwise: false,
    sectionRows: [],
    view: '',
    secSubStfNamesSet: false,
    additionalStaff: [],
    showPeriods: null,
    showLocations: null,
   // showSubjectWeight: null,
    subjectWeight: null,
    isTTRun: false,
    assignSubjectWeightPPW: null
};

export function reducer(state = initialState, action: app.Actions): State {
    switch (action.type) {
        case app.ActionTypes.SET_SECTION_NAMES:
            return Object.assign({}, state, { sections: setSectionList(action.payload.sections.slice(0), state) });
        case app.ActionTypes.SET_STAFF_NAMES:
            return Object.assign({}, state, { staff: setStaffList(action.payload.staff.slice(0), state) });
        case app.ActionTypes.SET_SUBJECT_NAMES:
            return Object.assign({}, state, { subjects: setSubjectList(action.payload.subjects.slice(0), state) });
        case app.ActionTypes.SET_ADDITIONAL_STAFF_NAMES:
            return Object.assign({}, state, { additionalStaff: setAdditionalStaffList(action.payload.additionalStaff.slice(0), state) });
        case app.ActionTypes.SET_STAFF_ROWS:
            return Object.assign({}, state, { staffRows: action.payload.staffRows.slice(0) });
        case app.ActionTypes.SET_ADDITIONAL_STAFF_ROWS:
            return Object.assign({}, state, { additionalStaffRows: action.payload.additionalStaffRows.slice(0) });
        case app.ActionTypes.SET_SUBJECT_ROWS:
            return Object.assign({}, state, { subjectRows: action.payload.subjectRows.slice(0) });
        case app.ActionTypes.SET_TT_INPUT:
            return Object.assign({}, state, { ttInput: setCSV(action.payload.ttInput, state) });
        case app.ActionTypes.SET_UPLOADED_CSV:
            return Object.assign({}, state, { uploadedCSV: setUploadedData(action.payload.uploadedCSV, state) });
        case app.ActionTypes.SET_SAMPLE_CSV:
            return Object.assign({}, state, { sampleCSV: setUploadedData(action.payload.sampleCSV, state) });
        case app.ActionTypes.SET_HTML:
            return Object.assign({}, state, { setHTML: action.payload });
        case app.ActionTypes.SET_TT_MODEL:
            return Object.assign({}, state, { model: setModelAndNames(action.payload.csv, action.payload.currentComputedCount, action.payload.ttId, action.payload.ttPoolCSV, state) });
        case app.ActionTypes.CLEAR_UPLOADED_OR_SAMPLE_CSV:
            return Object.assign({}, state, { '': clearUploadedOrSampleCSV(state) });
        case app.ActionTypes.MATRIX_CHANGE_SET:
            return Object.assign({}, state, { matrixChangeSet: action.payload.matrixChangeSet.slice(0) });
        case app.ActionTypes.CSV_UPLOADED:
            return Object.assign({}, state, { isCSVSet: action.payload.isCSVSet });
        case app.ActionTypes.SET_MATRIX_VIEW:
            return Object.assign({}, state, { matrixViewTeacherwise: action.payload.matrixViewTeacherwise });
        case app.ActionTypes.RESET_TT:
            return Object.assign({}, state, { '': resetTT(state) });
        case app.ActionTypes.GET_TT_INPUT:
            return Object.assign({}, state, { '': setTTInput(state) });
        case app.ActionTypes.SET_VIEW:
            return Object.assign({}, state, { view: action.payload.view });
        case app.ActionTypes.SEC_SUB_STF_NAMES_SET:
            return Object.assign({}, state, { secSubStfNamesSet: action.payload.secSubStfNamesSet });
        case app.ActionTypes.SHOW_PERIODS:
            return Object.assign({}, state, { showPeriods: action.payload.showPeriods });
            case app.ActionTypes.SHOW_LOCATIONS:
                return Object.assign({}, state, { showLocations: action.payload.showLocations });    
            // case app.ActionTypes.SHOW_SUBJECT_WEIGHT:
            // return Object.assign({}, state, { showSubjectWeight: action.payload.showSubjectWeight });  
            case app.ActionTypes.ASSIGN_SUBJECT_WEIGHT_PPW:
            return Object.assign({}, state, { assignSubjectWeightPPW: action.payload.assignSubjectWeightPPW });          
        case app.ActionTypes.CHANGE_SUBJECT_WEIGHT:
            return Object.assign({}, state, { csv: subjectWeightChanged(action.payload.csv, state) });
        case app.ActionTypes.GENERATE_TT:
            return Object.assign({}, state, { isTTRun: action.payload.isTTRun });
        default:
            return state;
    }
}

export const get_matrix_change_set = (state: State) => state.matrixChangeSet;
export const get_matrix_sections = (state: State) => state.sections;
export const get_matrix_staff = (state: State) => state.staff;
export const get_matrix_subjects = (state: State) => state.subjects;
export const get_matrix_subject_rows = (state: State) => state.subjectRows;
export const get_matrix_staff_rows = (state: State) => state.staffRows;
export const get_matrix_additional_staff_rows = (state: State) => state.additionalStaffRows;
export const get_tt_sample_csv = (state: State) => state.sampleCSV;
export const get_tt_uploaded_csv = (state: State) => state.uploadedCSV;
export const get_tt_input = (state: State) => state.ttInput;
export const get_tt_model = (state: State) => state.model;
export const get_tt_base_csv = (state: State) => state.baseCSV;
export const is_csv_set = (state: State) => state.isCSVSet;
export const set_matrix_view = (state: State) => state.matrixViewTeacherwise;
export const get_section_rows = (state: State) => state.sectionRows;
export const set_view = (state: State) => state.view;
export const sec_sub_stf_names_set = (state: State) => state.secSubStfNamesSet;
export const get_matrix_additional_staff = (state: State) => state.additionalStaff;
export const show_periods_in_tt = (state: State) => state.showPeriods;
export const show_locations_in_tt = (state: State) => state.showLocations;
//export const show_subject_weight = (state: State) => state.showSubjectWeight;
export const is_tt_run = (state: State) => state.isTTRun;
export const assign_subject_weight_ppw = (state: State) => state.assignSubjectWeightPPW;