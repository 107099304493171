import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { AcademicsService as service } from '../../academics/service/service';
import * as _ from 'underscore';
import * as LC from 'literallycanvas';
import { FileUploader } from 'ng2-file-upload';
import 'brace/index';
import 'brace/theme/eclipse';
import 'brace/mode/markdown';
import 'brace/ext/language_tools';
import * as marked from 'marked';
import * as stopWords from '../../../../assets/dummy/stop-words.json';
import { KatexOptions, MarkdownService } from 'ngx-markdown';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as rootReducer from '../../rootReducer';
import * as actions from '../../academics/store/action';
import { Router } from '@angular/router';

class Model {
  tree: any = { title: "", nodes: [] };
  nodes: any[] = [];
  title: string = "";
  titleCopy: string = "";
  lxTitleCopy: string = "";
  key: string = "children";
  editTitle: boolean = false;
  showTitleErr: boolean = false;
  selectedNode: any = {};
  showTab: boolean = false;
  topics: any[] = [];
  currentPersona: any;
}

const URL = '';

@Component({
  selector: 'vx-edit',
  templateUrl: './component.html',
  styleUrls: ['./component.css'],
  providers: [service],
})
export class VxEditComponent implements OnInit, OnDestroy {
  ts: string = "1";
  public options: KatexOptions = {
    displayMode: false,
    throwOnError: false,
    errorColor: '#cc0000',
  };
  private subs = new Subscription();

  constructor(private store: Store<rootReducer.State>,
    private el: ElementRef,
    public service: service,
    private markdownService: MarkdownService,
    private router: Router) {
  }
  @Input() topic: any = { title: "", nodes: [] };
  public m: Model;
  dropLxId: any;
  public popoverTitle: string = 'Confirm Delete';
  public popoverMessage: string = '';
  public confirmClicked: boolean = false;
  public cancelClicked: boolean = false;
  public confirmText: string = 'Yes';
  public cancelText: string = 'No';
  public showSave: boolean = true;

  ngOnInit() {
    this.init_model();
    this.m.tree = this.topic;
    if (this.m.tree.title) { this.showSave = false; }
    this.sub_store();
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
  init_model() {
    this.m = new Model();
    this.service.getArtefactData().subscribe(res => {
      if (res) {
        let curriculumFile = res['data'].find(file => file.fileName == "Curriculum.json");
        if (curriculumFile) {
          // this.service.getCurriculum(curriculumFile.id);
        }
      }
    });
  }
  sub_store() {
    this.subs.add(this.store.select(rootReducer.get_topics).subscribe(curriculum => {
      if (curriculum && curriculum['curriculum'] && curriculum['curriculum'].length > 0) {
        this.m.topics = curriculum['curriculum'];
      }
    }));

    this.subs.add(this.store.select(rootReducer.get_state_app).subscribe(state => {
      if (state)
        this.m.currentPersona = state.currentPersona;
    }));
  }
  selectedNode(ev) {
    this.m.selectedNode = ev;
    this.m.showTab = true;
  }
  deletedNode(ev) {
    if (this.m.selectedNode && ev && ev.isLeaf && (this.m.selectedNode.itemId == ev.itemId)) {
      this.m.selectedNode = {};
      this.m.showTab = false;
    }
    else if (this.m.selectedNode && ev && !ev.isLeaf && ev.children && ev.children.length > 0) {
      if (JSON.stringify(ev).includes(this.m.selectedNode.itemId)) {
        this.m.selectedNode = {};
        this.m.showTab = false;
      }
    }
  }
  updateQ(event) {
    if (event) {
      this.m.selectedNode.summary.text = event.q;
    }
  }
  updateContent(event) {
    if (event) {
      this.m.selectedNode.lxList[event.index].content.text = event.q;
    }
  }
  ut_removeLx(index) {
    this.m.selectedNode.lxList.splice(index, 1)
    let lxOb = { id: "", title: "", content: { text: "", htmlText: "", plainText: "" }, preview: true, itemId: +new Date() };
    if (this.m.selectedNode.lxList.length == 0) {
      this.m.selectedNode.lxList.push(lxOb);
    }
  }
  ut_addLx(index, lx) {
    if (lx.content.text) {
      this.m.selectedNode.lxList.forEach(q => {
        q.preview = true;
      });
      let lxOb = { id: "", title: "", content: { text: "", htmlText: "", plainText: "" }, preview: false, itemId: +new Date() };
      // this.m.selectedNode.lxList.push(qOb);
      this.m.selectedNode.lxList.splice(index + 1, 0, lxOb);
    }
  }
  focus() {
  }
  focusOut(ev, lx) {
    ev.preventDefault();
    ev.stopPropagation();
    lx.preview = true;
  }
  focusOutLx(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    if (this.m.selectedNode.lxList && this.m.selectedNode.lxList.length > 0) {
      this.m.selectedNode.lxList.forEach(lx => {
        lx.preview = true;
      });
    }
  }
  addNode() {
    let node = { name: "New Node", expand: false, children: [], isLeaf: false, itemId: +new Date() };
    this.m.tree.nodes.push(node);
  }
  allowDrop(ev) {
    ev.preventDefault();
  }
  dragLx(evt, data) {
    evt.dataTransfer.setData("text", JSON.stringify(data));
  }
  dropLx(ev) {
    ev.preventDefault();
    var data = ev.dataTransfer.getData("text");
    var lxData = JSON.parse(data);
    let dragLxIndex = this.m.selectedNode.lxList.findIndex(
      q => q.itemId == lxData.itemId
    );
    let dropLocationIndex = this.m.selectedNode.lxList.findIndex(
      q => q.itemId == this.dropLxId
    );
    if (this.m.selectedNode.lxList.length >= 1) {
      var temp = this.m.selectedNode.lxList[dragLxIndex];
      this.m.selectedNode.lxList[dragLxIndex] = this.m.selectedNode.lxList[dropLocationIndex];
      this.m.selectedNode.lxList[dropLocationIndex] = temp;
    }
  }
  dragEnter(ev, lx) {
    if (lx) {
      this.dropLxId = lx.itemId;
    }
  }
  editTitle() {
    this.m.editTitle = true;
    this.m.titleCopy = this.m.tree.title;
  }
  updateTitle() {
    if (this.m.titleCopy) {
      this.m.tree.title = this.m.titleCopy;
      this.m.titleCopy = "";
      this.m.editTitle = false;
      this.m.showTitleErr = false;
      if (this.m.tree.nodes.length < 1) { this.addNode(); }
    }
    else { this.m.showTitleErr = true; }
  }
  discardTitleEdit() {
    this.m.editTitle = false;
    this.m.showTitleErr = false;
  }
  editNodeInfo() {
    this.m.selectedNode.summary.preview = false;
    if (this.m.selectedNode.lxList && this.m.selectedNode.lxList.length > 0) {
      this.m.selectedNode.lxList.forEach(lx => {
        lx.preview = true;
      });
    }
  }
  editLx(lx) {
    this.m.selectedNode.summary.preview = true;
    if (this.m.selectedNode.lxList && this.m.selectedNode.lxList.length > 0) {
      this.m.selectedNode.lxList.forEach(lx => {
        lx.preview = true;
      });
    }
    lx.preview = false;
  }
  editLxTitle(lx) {
    lx.editTitle = true;
    this.m.lxTitleCopy = lx.title;
  }
  updateLxTitle(lx) {
    lx.title = this.m.lxTitleCopy;
    this.m.lxTitleCopy = "";
    lx.editTitle = false;
  }
  discardLxTitleUpdate(lx) {
    lx.editTitle = false;
    this.m.lxTitleCopy = "";
  }
  addTopic() {
    if (this.m.tree.title) {
      this.m.tree.modifiedOn = new Date();
      if (this.m.tree.nodes && this.m.tree.nodes.length > 0) {
        this.formatData(this.m.tree.nodes);
      }
    }
    this.m.topics.push(this.m.tree);
    let currObj = { curriculum: this.m.topics };
    var jsonStr = JSON.stringify(currObj);
    var jsonBlob = new Blob([jsonStr], { type: "application/json" });
    var jsonFile = new File([jsonBlob], "Curriculum.json", { type: "application/json" });
    const json_meta = {
      'Title': "Curriculum",
      'Description': "Curriculum",
      'MetaData': {}
    };
    if (this.m.currentPersona) {
      var acadYear = new Date(this.m.currentPersona.acadYearFrom).getFullYear().toString().substring(2, 4) + "-" + new Date(this.m.currentPersona.acadYearTo).getFullYear().toString().substring(2, 4);
      this.service.saveJson(jsonFile, json_meta, acadYear).subscribe(res => {
        if (res) {
          this.router.navigate(['/lms/curriculum']);
        }
      })
    }
  }
  formatData(nodes) {
    nodes.forEach(node => {
      node.expand = false;
      node.selected = false;
      if (node.children && node.children.length > 0) { this.formatData(node.children); }
    });
  }
}