import { Injectable } from '@angular/core';

import { CTApi } from '../../../app/service/ct-api';
import { RecentActivitiesComponent } from '../../../app/service/recent-activities';

import { Store } from '@ngrx/store';
import * as rootReducer from '../../../app/rootReducer';

import * as userProfileAction from '../store/action';
import { Observable } from 'rxjs/Observable';
import 'rxjs/Rx';


@Injectable()
export class UserProfileService {
    constructor(public ctapi: CTApi, public rec_act: RecentActivitiesComponent, private store: Store<rootReducer.State>, ) { }
    getUserDetails(userId) {
        this.ctapi.get_user_details(userId)
            .subscribe((res) => {
                this.store.dispatch(new userProfileAction.SetUserDetails({ "user_details": res.data }));
            });
    }
    changePassword(id,currentPassword,newPassword){
        this.ctapi.changePassword(id,currentPassword,newPassword)
            .subscribe((res) => { });
    }
    updateUser(user){
        this.ctapi.updateUser(user)
            .subscribe((res) => { });
    }
    upsertArtefact(files){
        return this.ctapi.upsertArtefact(files);
    }
    uploadUserPhoto(id, photoUrl){
        return this.ctapi.uploadUserPhoto(id, photoUrl);
    }
    clearUserPhoto(id){
         this.ctapi.clearUserPhoto(id)
           .subscribe((res) => { });
    }

    updateRecoveryMobile(id, mobile){
        return this.ctapi.updateRecoveryMobile(id, mobile);
    }

    updateRecoveryEmail(id, email){
        return this.ctapi.updateRecoveryEmail(id, email);
    }

    updateUserHandle(payload){
        return this.ctapi.updateUserHandle(payload);
      }
}
