import { Component, OnInit, TemplateRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { CTApi } from "../../service/ct-api";
import { HomeService as service } from '../service/home-service';
import * as googleMeetAction from '../../components/google-meet/store/action';
import * as countdownTimerAction from '../../components/countdownTimer/store/action';
import { timer } from 'rxjs';

import * as rootReducer from '../../rootReducer';
import { BsModalRef, BsModalService } from "ngx-bootstrap";
import * as appAction from '../../store/app-action';
import * as Data from './../../userLinks.js';
import { Subscription } from "rxjs/Subscription";
import * as _ from 'underscore';

import * as ClientIds from '../../clientIdMapper.js';

declare const gapi: any;

class Model {
    userLoggedIn: any = false;
    email: any = '';
    password: any = '';
    showInvalidCred: any = false;
    token: any = '';
    ctContext: any;
    timerStopped: boolean;
    timer: any;
    showOTPExpired: boolean = false;
    recoveryType: string = "email";
    recoveryEmail: string;
    recoveryMobile: string;
    showTimer: boolean = false;
    showRecoveryErr: boolean;
    showErrMsg: boolean = false;
    errorMsg: string;
    passwordReset: boolean = false;
    resetPassword: any = { Email: '' };
    userLinks: any;
    personaChanging: boolean = false;
    subDomain: string = '';
    domain: string = '';
    section: string = 'emailSection';
}

@Component({
    selector: 'sign-in-gen',
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
    providers: [service],
})
export class SignInGenComponent implements OnInit {
    public m: Model;
    modalRef: BsModalRef;
    private sub_selected_persona: Subscription;
    private subs: Subscription;
    counter: number;
    countDown: Subscription;
    tick: number = 1000;
    public auth2: any;
    public mobile: boolean = false;
    clientId: string = "";

    constructor(public service: service, private store: Store<rootReducer.State>, private modalService: BsModalService,
        public ctapi: CTApi, private router: Router, private activatedRoute: ActivatedRoute) { }

    ngOnInit() {
        this.init_model();
        this.init_store();
        this.sub_store();

        gapi.load('auth2', () => {
            this.auth2 = gapi.auth2.init({
                client_id: '47075676153-u72dnkjhvod0vahrgts92tp54qnctd6i.apps.googleusercontent.com'
            });
            // this.attachSignin(document.getElementById('googleBtn'));
        });

        gapi.signin2.render('googleBtn', {
            'scope': 'profile email',
            'width': 200,
            'height': 50,
            'longtitle': false,
            'theme': 'dark'
        });

        // gapi.signin2.render('googleBtn2', {
        // 	'scope': 'profile email',
        // 	'width': 240,
        // 	'height': 50,
        // 	'longtitle': false,
        // 	'theme': 'dark'
        // });

       
        if (performance.navigation.type == 0 || performance.navigation.type == 1) {
            if (sessionStorage.accessToken && sessionStorage.ctContext) {
                let accessToken = sessionStorage.accessToken;
                let ctContext = JSON.parse(sessionStorage.ctContext);
                this.m.ctContext = ctContext;
                this.store.dispatch(new appAction.SetAccessToken({ "accessToken": accessToken }));
                this.store.dispatch(new appAction.SetAccessCode());
                if (ctContext.notification) this.store.dispatch(new appAction.LoginSuccess({ "userLoggedIn": true, "ctContext": ctContext }));
                else this.store.dispatch(new appAction.LoginSuccess({ "userLoggedIn": false, "ctContext": ctContext }));
            }
        }

        this.router.events.subscribe((res) => {
            if ((window.screen.width < 500) && this.router.url == '/mobilePrivacyPolicy') { this.mobile = true; }
            else { this.mobile = false; }
        })
    }

    ngOnDestroy() {
        this.sub_selected_persona.unsubscribe();
        this.subs.unsubscribe();
    }
    init_model() {
        this.m = new Model();
        this.m.userLinks = Data.userLinks;
        this.getSubdomain();
        this.getClientId();
    }
    init_store() {
    }
    sub_store() {
        this.store.select(rootReducer.timer_stopped).subscribe(res => {
            if (res) this.m.timerStopped = res;
        })

        this.subs = this.store.select(rootReducer.get_otp_timer).subscribe(
            res => {
                if (res) {
                    this.counter = 180;
                    let timestamp = Date.now();
                    var seconds = (new Date().getTime() - new Date(timestamp).getTime()) / 1000;
                    this.counter = this.counter - seconds;
                    this.countDown = timer(0, this.tick).subscribe(() => {
                        if (this.counter >= 1) { --this.counter }
                        else if (this.counter === 0) {
                            this.m.showOTPExpired = true;
                        }
                    });
                }
                else if (!res) {
                    this.counter = 180;
                }
            }
        );

        this.sub_selected_persona = this.store.select(rootReducer.get_selected_persona).subscribe(
            res => {
                let res_is_null = res ? Object.keys(res).length === 0 && res.constructor === Object : true;
                if (!res_is_null && this.m.ctContext) {
                    if (res.id != this.m.ctContext.currentPersona.id) {
                        if (res.clientShortName == 'BTLCOL' || res.clientShortName == 'PPECPS') this.router.navigate(['/cards']);
                        else this.router.navigate(['/home']);
                        //this.router.navigate(['/home']);
                        this.m.personaChanging = true;
                        this.ctapi.setCurrentPersona(this.m.ctContext.userId, res.id)
                            .subscribe(data => {
                                if (data) {
                                    let access_token = data.headers.get('Authorization');
                                    let ct_context = data.headers.get('CT-Context');
                                    let ct_context_json = JSON.parse(ct_context);
                                    ct_context_json.currentPersona.Routes = ["home", "home/userProfile", "admin", "admin/org", "admin/inst", "academics", "assets", "fees", "fees/manage", "fees/transactions", "library", "library/circulation", "library/barcode", "connect", "receipts", "regulatoryCompliance"];
                                    ct_context_json.currentPersona.navPerms = this.getNavPerms(ct_context_json.currentPersona.Routes);
                                    this.m.ctContext = ct_context_json;
                                    this.store.dispatch(new appAction.SetAccessToken({ "accessToken": access_token }));
                                    this.store.dispatch(new appAction.LoginSuccess({ "userLoggedIn": true, "ctContext": ct_context_json }));
                                    this.m.personaChanging = false;
                                    ct_context_json.scope.forEach((sc: string) => {
                                        if (sc && sc.includes("acad/course"))
                                            this.ctapi.courseCache().subscribe(() => { });
                                    });
                                    ct_context_json.scope.forEach((sc: string) => {
                                        if (sc && sc.includes("staff"))
                                            this.ctapi.staffCache().subscribe(() => { });
                                    });
                                    ct_context_json.scope.forEach((sc: string) => {
                                        if (sc && sc.includes("auth/user")) {
                                            let postData: any = ["sms_dlr_chart", "sms_dlr_json", "sms_dlr_exception"];
                                            if (ct_context_json.currentPersona.title != 'Student') this.ctapi.userDashboard(postData).subscribe(() => { });
                                        }
                                    });
                                    this.store.dispatch(new googleMeetAction.SetMeetings({ "meetings": [] }));
                                }
                            },
                                err => console.log(err))
                    }
                }
            },
            err => console.log(err)
        );
    }

    getClientId() {
        let hostName = window.location.hostname;
        if (hostName) {
            let hostNameArr = hostName.split('.');
            let subDomain = hostNameArr[0];
            let clientIds = ClientIds.clientIdMaps;
            let clientId = clientIds[subDomain];
            this.clientId = clientId;
        }
    }

    getSubdomain() {
        var hostName: string = window.location.hostname;
        if (hostName) {
            let hostNameArr = hostName.split('.');
            this.m.subDomain = hostNameArr[0];
        }
        if (hostName.startsWith("localhost")) this.m.domain = "localhost";
    }

    googleInit() {
        let idToken = "";
        this.auth2.signIn()
            .then((d) => {
                for (const property in d) {
                    if (typeof d[property] === 'object') {
                        Object.keys(d[property]).forEach(key => {
                            if (key == "id_token") { idToken = d[property][key] }
                        });
                    }
                }
                if (idToken) {
                    this.m.token = idToken;
                    this.gLogin();
                }
            },
                () => { });
    }

    gLogin() {
        this.m.showInvalidCred = false;
        this.ctapi.authenticate(this.m.email, this.m.password, this.m.token)
            .subscribe(data => {
                if (data.headers.get('Authorization')) {
                    let access_token = data.headers.get('Authorization');
                    let ct_context = data.headers.get('CT-Context');
                    let ct_context_json = JSON.parse(ct_context);
                    this.m.ctContext = ct_context_json;
                    sessionStorage.accessToken = access_token;
                    sessionStorage.ctContext = JSON.stringify(ct_context_json);
                    this.store.dispatch(new appAction.SetAccessToken({ "accessToken": access_token }));
                    this.store.dispatch(new appAction.SetAccessCode());
                    if (ct_context_json.notification) this.store.dispatch(new appAction.LoginSuccess({ "userLoggedIn": true, "ctContext": ct_context_json }));
                    else this.store.dispatch(new appAction.LoginSuccess({ "userLoggedIn": false, "ctContext": ct_context_json }));
                    this.store.dispatch(new appAction.SetUserLinks({ "userLinks": this.m.userLinks }));
                    ct_context_json.scope.forEach((sc: string) => {
                        if (sc && sc.includes("acad/course"))
                            this.ctapi.courseCache().subscribe(() => { });
                    });
                    ct_context_json.scope.forEach((sc: string) => {
                        if (sc && sc.includes("staff"))
                            this.ctapi.staffCache().subscribe(() => { });
                    });
                    if (ct_context_json.currentPersona.clientShortName == 'BTLCOL' || this.m.ctContext.currentPersona.clientShortName == 'PPECPS' || this.m.ctContext.currentPersona.title == 'Applicant') this.router.navigate(['/cards']);
                    else this.router.navigate(['/home']);
                }
            },
                err => { this.m.showInvalidCred = true; })
    }

    getNavPerms(routes) {
        let sidebar = [];
        routes.forEach(v => {
            var routeArr = v.split('/');
            var so = _.find(sidebar, function (x) { return x.Route == routeArr[0] });
            if (so && routeArr[1]) {
                let sObj = { Route: "", DisplayText: "" };
                sObj.Route = v;
                sObj.DisplayText = this.getPascalCase(routeArr[1]);
                so.Sidebar.push(sObj);
            }
            else if (!so) {
                let spObj = { Route: "", DisplayText: "", Sidebar: [] };
                spObj.Route = routeArr[0];
                spObj.DisplayText = this.getPascalCase(routeArr[0]);
                let scObj = { Route: "", DisplayText: "" };
                scObj.Route = routeArr[0];;
                scObj.DisplayText = "Dashboard";
                spObj.Sidebar.push(scObj);
                if (routeArr[1]) {
                    let scObj = { Route: "", DisplayText: "" };
                    scObj.Route = v;
                    scObj.DisplayText = this.getPascalCase(routeArr[1]);
                    spObj.Sidebar.push(scObj);
                }
                sidebar.push(spObj);
            }
        });
        return sidebar;
    }

    getPascalCase(str) {
        let result = str.replace(/([A-Z])/g, " $1");
        let pascalStr = result.charAt(0).toUpperCase() + result.slice(1);
        return pascalStr;
    }

    login() {
        this.m.showInvalidCred = false;
        this.ctapi.authenticate(this.m.email, this.m.password, this.m.token)
            .subscribe(data => {
                if (data.headers.get('Authorization')) {
                    let access_token = data.headers.get('Authorization');
                    let ct_context = data.headers.get('CT-Context');
                    let ct_context_json = JSON.parse(ct_context);
                    this.m.ctContext = ct_context_json;
                    sessionStorage.accessToken = access_token;
                    sessionStorage.ctContext = JSON.stringify(ct_context_json);
                    this.store.dispatch(new appAction.SetAccessToken({ "accessToken": access_token }));
                    this.store.dispatch(new appAction.SetAccessCode());
                    if (ct_context_json.notification) this.store.dispatch(new appAction.LoginSuccess({ "userLoggedIn": true, "ctContext": ct_context_json }));
                    else this.store.dispatch(new appAction.LoginSuccess({ "userLoggedIn": false, "ctContext": ct_context_json }));
                    this.store.dispatch(new appAction.SetUserLinks({ "userLinks": this.m.userLinks }));
                    ct_context_json.scope.forEach((sc: string) => {
                        if (sc && sc.includes("acad/course"))
                            this.ctapi.courseCache().subscribe(() => { });
                    });
                    ct_context_json.scope.forEach((sc: string) => {
                        if (sc && sc.includes("staff"))
                            this.ctapi.staffCache().subscribe(() => { });
                    });

                    // After successful login redirect to return url else handle below cases
                    const returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'];
                    if (returnUrl) {
                        this.router.navigateByUrl(returnUrl);
                    } else {
                        if (['BTLCOL', 'PPECPS'].includes(ct_context_json.currentPersona.clientShortName) || this.m.ctContext.currentPersona.title == 'Applicant') {
                            this.router.navigate(['/cards']);
                        } else {
                            this.router.navigate(['/home']);
                        }
                    }

                    if (ct_context_json.currentPersona.clientShortName == 'PPECPS')
                        this.store.dispatch(new appAction.SetNBDisplay({ name: "Classic", type: "classic", hideNavBar: false }));
                }
            },
                err => { this.m.showInvalidCred = true; })
    }

    openResetPasswordModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, { ignoreBackdropClick: true, keyboard: false });
        this.m.timerStopped = false;
        this.m.showTimer = false;
        this.m.recoveryEmail = null;
        this.m.recoveryMobile = null;
        this.m.resetPassword.Email = this.m.email;
    }

    openLoginModal() {
        this.m.showTimer = true;
        this.store.dispatch(new appAction.SetOTPTimer({ "showTimer": true }));
    }

    ut_showPasswordSection() {
        this.m.section = 'passwordSection';
    }

    ut_showRegisterSection() {
        this.m.section = 'registerSection';
    }

    ut_register(step2Template: TemplateRef<any>) {
        this.ctapi.registerAnon(this.m.email, this.clientId).subscribe();
        this.showModal(step2Template);
    }

    showModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, { keyboard: true });
    }

    ut_goBack() {
        this.m.section = 'emailSection';
    }

    ut_ok() {
        this.modalRef.hide();
        this.m.section = 'emailSection';
    }

    ut_submitEmail(email) {
        if (this.m.timerStopped) this.store.dispatch(new countdownTimerAction.TimerStopped({ 'timerStopped': false }))
        let payload = { VirtualId: this.m.resetPassword.Email, RecoveryEmail: email, RecoveryMobile: "" };
        this.ctapi.resetPasswordVirtual(payload).subscribe(res => {
            if (res) {
                this.modalRef.hide();
                this.openLoginModal();
            }
        },
            err => {
                let error = JSON.parse(err.error);
                this.m.showErrMsg = true;
                this.m.errorMsg = error.error;
            }
        );
    }

    ut_submitMobile(mobile) {
        if (this.m.timerStopped) this.store.dispatch(new countdownTimerAction.TimerStopped({ 'timerStopped': false }))
        let payload = { VirtualId: this.m.resetPassword.Email, RecoveryEmail: "", RecoveryMobile: mobile };
        this.ctapi.resetPasswordVirtual(payload).subscribe();
        this.modalRef.hide();
        this.openLoginModal();
    }

    ut_setRecoveryType(recoveryType) {
        this.m.recoveryType = recoveryType;
    }

    ut_closeResetModal() {
        this.modalRef.hide();
        this.m.recoveryType = "email";
        this.m.showRecoveryErr = false;
        this.m.showErrMsg = false;
        this.m.errorMsg = '';
    }

    omit_special_char_handle(event) {
        var k;
        k = event.charCode;
        return (k == 46 || k == 64 || (k > 96 && k < 123) || k == 8 || (k >= 48 && k <= 58) || k == 95 || k == 43);
    }

    resetPassword() {
        if (this.m.showErrMsg) this.m.showErrMsg = false;
        if (this.m.resetPassword.Email.includes("@campustrack.net")) {
            this.ctapi.getRecoveryInfo(this.m.resetPassword.Email)
                .subscribe(res => {
                    if (res) {
                        let resp = JSON.parse(res.body)
                        let data = resp.data;
                        this.m.recoveryEmail = data.recoveryEmail;
                        this.m.recoveryMobile = data.recoveryMobile;
                    }
                }, err => {
                    this.m.showRecoveryErr = true;
                });
        }
        else {
            this.ctapi.resetPassword(this.m.resetPassword.Email)
                .subscribe(data => {
                    if (data) {
                        this.modalRef.hide();
                        this.m.passwordReset = true;
                        this.m.resetPassword = { Email: '' };
                        this.store.dispatch(new appAction.ResetPasswordEmailSent({ "resetPasswordEmailSent": true }));
                    }
                },
                    err => {
                        this.m.passwordReset = false;
                    })
        }
    }
}