import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import * as rootReducer from '../../rootReducer';
import { Router } from '@angular/router';
import { StudentEnrollmentSetupService as service } from './service/service';

class Section {
    boys: number;
    girls: number;
    name: string;
}
class Course {
    course_id: string;
    name: string;
    sections: Section[];
    total_student_count: number;
}
class StudentData {
    courses: Course[];
}
class Model {
    studentData: StudentData;
}
@Component({
    selector: 'student-enrollment-setup',
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
    providers: [service]
})

export class StudentEnrollmentSetupComponent {
    public m: Model;
    @Input() pin: boolean;
    constructor(private router: Router, private service: service, private store: Store<rootReducer.State>) { }
    ngOnInit() {
        this.init_model();
        this.init_store();
    }
    init_model() {
        this.m = new Model();
    }

    init_store() {
        this.store.select(rootReducer.get_student_enrollment_setup_widget).subscribe((res: any) => {
            if (res) {
                this.m.studentData = res;
            }
        });
    }

    ut_routeToStudentsAdd() {
        this.router.navigate(['/student/add']);
    }

    pinChart() {
        this.pin = false;
        let payload = ["student_enrollment_setup"];
        this.service.pinWidget(payload).subscribe(res => {
            if (res) this.service.getDashboardWidgets();
        });

    }
    unpinChart() {
        this.pin = true;
        let payload = ["student_enrollment_setup"];
        this.service.unpinWidget(payload).subscribe(res => {
            if (res) this.service.getDashboardWidgets();
        });
    }
}