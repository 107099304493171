import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DexRoutingModule } from './dex-routing.module';
import { DexComponent } from './dex.component';
import { DexSidebarComponent } from './dex-sidebar/dex-sidebar.component';
import { DexSidebarHeaderComponent } from './dex-sidebar-header/dex-sidebar-header.component';
import { DexSidebarContentComponent } from './dex-sidebar-content/dex-sidebar-content.component';
import { DexViewComponent } from './dex-view/dex-view.component';
import { ModalModule } from 'ngx-bootstrap';
import { DexSelectComponent } from './dex-select/dex-select.component';
import { DexSearchComponent } from './dex-search/dex-search.component';
import { DexFilterComponent } from './dex-filter/dex-filter.component';
import { DexGroupByComponent } from './dex-group-by/dex-group-by.component';
import { DexOrderByComponent } from './dex-order-by/dex-order-by.component';
import { DexPagingComponent } from './dex-paging/dex-paging.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DexPrqlEditorComponent } from './dex-prql-editor/dex-prql-editor.component';
import { DragulaModule } from 'ng2-dragula';

import { PopoverModule } from 'ngx-bootstrap/popover';
import { DexPublisherComponent } from './dex-publisher/dex-publisher.component';
import { DexPageComponent } from './dex-page/dex-page.component';

@NgModule({
  declarations: [
    DexComponent, DexSidebarComponent, DexSidebarHeaderComponent, DexSidebarContentComponent,
    DexViewComponent, DexSelectComponent, DexSearchComponent, DexFilterComponent,
    DexGroupByComponent, DexOrderByComponent, DexPagingComponent,
    DexPrqlEditorComponent, DexPublisherComponent, DexPageComponent
  ],
  imports: [
    CommonModule,
    DexRoutingModule,
    ModalModule,
    FormsModule,
    ReactiveFormsModule,
    DragulaModule,
    PopoverModule
  ],
  exports: [
    DexSidebarComponent,
    DexViewComponent
  ]
})
export class DexModule { }
