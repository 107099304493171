import * as R from 'ramda';
import * as Papa from '../../../app/papaparse.min.js'

const sayX = x => console.log(x);
const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
// TBD: To be captured from UI
const ppd = 8;
let ppw; // = 45;
let teacher_count; // = 20;
let additional_teacher_count;
let section_count;
let weekly_pattern;
let location_count;

let teacher_list = [];
let section_list = [];
let subject_list = [];
let additional_teacher_list = [];
const set_teacher_list = o => teacher_list = o;
const set_section_list = o => section_list = o;
const set_subject_list = o => subject_list = o;
const set_additional_teacher_list = o => additional_teacher_list = o;

const set_ppw = o => ppw = o;
const set_teacher_count = o => teacher_count = o;
const set_sections_count = o => section_count = o;
const set_weekly_pattern = o => weekly_pattern = o;
const set_additional_teacher_count = o => additional_teacher_count = o;

const get_day = (per_no, ppw, ppd) => {
  var per_rem = per_no % ppw;
  var per_quo = Math.trunc(per_no / ppw);
  var per_normalized = per_rem == 0 && per_quo > 0 ? ppw : per_rem; //normalize period no between 1 and 45
  var day_quo = Math.trunc(per_normalized / ppd);
  var day_no = per_normalized % ppd == 0 ? day_quo : day_quo + 1;
  var day_rem = per_normalized % ppd;
  var per_no_in_day = day_rem == 0 ? ppd : day_rem;
  return { "day": days[day_no], "per_no_in_day": per_no_in_day };
}
const get_cell = cell => {
  var per_no = parseInt(R.replace("P", "", cell.per), 10);
  var d = get_day(per_no, ppw, ppd);
  return {
    "section": cell.sec ? cell.sec.trim() : "",
    "teacher": cell.stf ? cell.stf.trim() : "",
    "dow": d.day,
    "cell": {
      "dow": d.day,
      "section": cell.sec ? cell.sec.trim() : "",
      "per_id": cell.per,
      "per": d.per_no_in_day,
      "subject": cell.sub ? cell.sub.trim() : "",
      "teacher": cell.stf ? cell.stf.trim().split(',') : "",
      "teacher_name": cell.stf_name ? cell.stf_name.trim().split(',') : "",
      "stf": cell.stf ? cell.stf.trim() : "",
      "ppw": 0,
      "weight": cell.sub_weight,
      "per_set": JSON.parse(cell.per_set),
      "section_name": cell.sec_name ? cell.sec_name.trim() : cell.sec.trim(),
      "staff_name": cell.stf_name ? cell.stf_name.trim() : cell.stf.trim(),
      "subject_name": cell.sub_name ? cell.sub_name.trim() : cell.sub.trim(),
      "location": cell.loc ? cell.loc.trim() : "",
      "location_name": cell.loc_name ? cell.loc_name : ""
    }
  }
}

const get_subject_name = id => R.find(R.propEq('id', id))(subject_list);

const get_cell_teacher = cell => {
  if (cell) {
    const res = [];
    var per_no = parseInt(R.replace("P", "", cell.per_id), 10);
    var d = get_day(per_no, ppw, ppd);
    const arr = cell.teacher;
    let t_cell = {
      "section": cell.section.trim(),
      "dow": d.day,
      "cell": {
        "dow": d.day,
        "section": cell.section.trim(),
        "per_id": cell.per_id,
        "per": d.per_no_in_day,
        "subject": cell.subject.trim(),
        "ppw": 0,
        "weight": cell.weight,
        "per_set": JSON.parse(cell.per_set),
        "section_name": get_section_name(cell.section.trim())["name"],
        "subject_name": get_subject_name(cell.subject.trim())["name"],
        "location": cell.location.trim()
      }
    }
    arr.forEach(function (x) {
      let teacher_cell = Object.assign({}, t_cell, { "teacher": x });
      teacher_cell.cell["teacher"] = x;
      res.push(teacher_cell);
    });
    return res;
  }
}

const csv_to_json = csv => Papa.parse(csv, { header: true, skipEmptyLines: true });
const get_json = R.compose(R.reject(R.isNil), R.map(o => get_cell(o)), R.prop('data'), csv_to_json);
const get_json_teacher = R.compose(R.reject(R.isNil), R.chain(o => get_cell_teacher(o)), R.prop('data'), csv_to_json);

const groupByDow = R.compose(R.values, R.groupBy(R.prop('dow')));
const get_dow = R.compose(R.prop('dow'), R.head)
const mergeCells = R.reduce((acc, item) => R.append(item.cell, acc), []);
const wrapDowCells = x => [{ "dow": get_dow(x), "cells": mergeCells(x) }];
const wrapTtData = o => R.flatten(R.map(x => wrapDowCells(x), groupByDow(o)));

const get_sec = R.compose(R.prop('section'), R.head);
const get_section_name = id => R.find(R.propEq('id', id))(section_list)
const getTtData_by_sec = o => [{ "section": get_sec(o), "sectionName": get_section_name(o[0]['section']) ? get_section_name(o[0]['section'])["name"] : '', "ttData": wrapTtData(o) }];
const groupBySection = tt_json => R.compose(R.values, R.groupBy(R.prop('section')))(tt_json);
const tt_by_sec_fn = R.compose(R.flatten, R.map(getTtData_by_sec), groupBySection);

const search_period_fn = (teacher, dow, per) => R.find(R.allPass([R.propEq('teacher', teacher), R.propEq('dow', dow), R.propEq('per', per)]));
const tt_by_teacher_actual_fn = json => R.compose(mergeCells, R.chain(o => get_cell_teacher(o.cell)))(json);
var tt_by_teacher_actual = [];

const get_tt_data_cell = R.curry((teacher, ppw, ppd, per) => {
  const d = get_day(per, ppw, ppd);
  const period_match = search_period_fn(teacher, d.day, d.per_no_in_day)(tt_by_teacher_actual);
  const cell = period_match ? period_match : {
    dow: d.day,
    per: d.per_no_in_day,
    per_id: "",
    ppw: 0,
    section: "",
    subject: "",
    teacher: teacher,
    weight: 0
  }
  return { cell: cell, dow: d.day }
});
const get_tt_data_cell_fn = R.curry((teacher, ppw, ppd, p) => p > ppw ? false : [get_tt_data_cell(teacher, ppw, ppd, p), p + 1]);
const teacher_id = id => "T".concat(id);
const get_teacher_ids = R.compose(R.map(teacher_id), R.range(1))
const get_staff_name = id => R.find(R.propEq('id', id))(teacher_list)
const get_tt_for_teachers = R.reduce((acc, t_id) => R.append({
  "teacher": t_id,
  "teacherName": get_staff_name(t_id) ? get_staff_name(t_id)["name"] : t_id,
  "ttData": R.compose(wrapTtData, R.unfold(R.__, 1))(get_tt_data_cell_fn(t_id, ppw, ppd))
}, acc), []);

//invoke the functions
const tt_by_sec = json => tt_by_sec_fn(json);
const tt_by_teacher = json => {
  tt_by_teacher_actual = tt_by_teacher_actual_fn(json);
  var teacher_timetables = R.compose(get_tt_for_teachers, get_teacher_ids)(teacher_count + 1);
  return teacher_timetables;
}

const sum_fn = R.compose(R.map(R.sum), R.map(R.pluck('ppw')), R.groupBy(R.prop('teacher')), R.filter(x => x.per_set == true));
const reduce_fn = (acc, item) => { return R.append({ teacher: item.teacher, ppw: 1, per_set: item.per_set }, acc) };

const teacher_ppw = json => sum_fn(R.reduce(reduce_fn, [], tt_by_teacher_actual_fn(json)));
// const teacher_ppw = json => R.tap(sayX, sum_fn(R.reduce(reduce_fn, [], tt_by_teacher_actual_fn(json))));

const get_tt_for_teachers_flat = R.reduce((acc, t_id) => R.append(
  R.unfold(R.__, 1)(get_tt_data_cell_fn(t_id, ppw, ppd))
  , acc), []);
const tt_by_teacher_flat = json => {
  tt_by_teacher_actual = tt_by_teacher_actual_fn(json);
  var teacher_timetables_flat = R.compose(get_tt_for_teachers_flat, get_teacher_ids)(teacher_count + 1);
  return teacher_timetables_flat;
}
const is_5th_per = R.propEq('per', 5);
const is_8th_per = R.propEq('per', 8);
const is_saturday = R.propEq('dow', "Sat");
const is_weekday = R.compose(R.curry(R.contains(R.__, ["Mon", "Tue", "Wed", "Thu", "Fri"])), R.prop('dow'));
//const is_free = R.propEq('subject', "");
const is_per_set = R.propEq('per_set', true);
const lp_sat = R.allPass([is_5th_per, is_saturday,  is_per_set]);
const lp_weekday = R.allPass([is_8th_per, is_weekday, is_per_set]);
const last_per_not_free = R.anyPass([lp_sat, lp_weekday])

const last_per_not_free_fn = R.compose(R.filter(last_per_not_free), R.pluck('cell'), R.flatten, tt_by_teacher_flat)
const count_by_teacher = R.countBy(R.prop('teacher'))

const subtract = x => 6 - x;
const get_free_periods_by_teacher = R.compose(R.map(subtract), count_by_teacher, last_per_not_free_fn)

const expand_tt_cell = cell => { cell.id = cell.per; cell.stf_list = cell.stf.split(','); return cell };
const read_csv_1 = R.compose(R.reject(R.isNil), R.map(o => expand_tt_cell(o)), R.prop('data'), csv_to_json);
const init_json = csv => read_csv_1(csv);

const tt = pool_tt => R.head(pool_tt);
const pool = pool_tt => R.last(pool_tt);
const tt_json = csv => get_json(csv);
const pool_json = csv => get_json(csv);
//const no_teacher = R.propEq('per_set', false);
const per_not_set = o => o.cell.per_set == false;
const pluck_period = (acc, { cell }) => acc.concat(cell.per_id)
const sectionwise = ({ section }) => section
const get_unassigned_periods = tt_json => R.reduceBy(pluck_period, [], sectionwise, R.filter(per_not_set, tt_json));
const all_periods = tt_json => R.reduceBy(pluck_period, [], sectionwise, tt_json);

const per_set = o => o.cell.per_set == true;
const pluck_per = (acc, { cell }) => acc.concat(cell.per_id)
const get_assigned_periods = tt_json => R.reduceBy(pluck_per, [], sectionwise, R.filter(per_set, tt_json));

const apply_change_set = (acc_init, changes) => R.reduce(move, acc_init, changes);
const move = ([acc_tt, acc_pool], val) => {
  let item = {
    per: val.cell.per.trim(),
    sec: val.cell.sec.trim(),
    sub: val.cell.sub.trim(),
    stf: val.cell.stf,
    sec_name: val.cell.section_name.trim(),
    sub_name: val.cell.subject_name.trim(),
    stf_name: val.cell.staff_name.trim()
  }
  if (val.target == "tt") {
    let cells = R.map(o => o.cell)(acc_tt);
    let acc_tt_indx = R.findIndex(R.propEq('per_id', item.per))(cells);
    let c = acc_tt[acc_tt_indx].cell;
    if (c.per_set == false) {
      let teacher_list = R.concat([], item.stf);
      Object.assign(acc_tt[acc_tt_indx].cell,
        {
          "per_set": true,
          "subject": item.sub,
          "subject_name": item.sub_name,
          "staff_name": item.stf_name,
          "stf": teacher_list.toString(),
          "teacher": teacher_list,
          "teacher_name": R.append(item.stf_name.trim(), [])
        });
      Object.assign(acc_tt[acc_tt_indx],
        { "teacher": teacher_list.toString() })
      let pool_cells = R.map(o => o.cell)(acc_pool);
      let pool_index = R.findIndex(
        R.allPass([R.propEq('section', item.sec),
        R.propEq('subject', item.sub),
        R.propEq('teacher', item.stf)]))(pool_cells);
      acc_pool = R.remove(pool_index, 1, acc_pool);
      return [acc_tt, acc_pool];
    }
    else {
      let t = R.concat([], item.stf);
      let teacher_list = R.concat(c.teacher, t);
      let teacher_name = R.append(item.stf_name, c.teacher_name);
      Object.assign(acc_tt[acc_tt_indx].cell,
        {
          "stf": R.uniq(teacher_list).toString(),
          "teacher": R.uniq(teacher_list),
          "teacher_name": R.uniq(teacher_name),
          "staff_name": R.uniq(teacher_name).toString()
        });
      Object.assign(acc_tt[acc_tt_indx],
        { "teacher": R.uniq(teacher_list).toString() })

      let pool_cells = R.map(o => o.cell)(acc_pool);
      let pool_index = R.findIndex(
        R.allPass([R.propEq('section', item.sec),
        R.propEq('subject', item.sub),
        R.propEq('teacher', item.stf)]))(pool_cells);
      acc_pool = R.remove(pool_index, 1, acc_pool);
      return [acc_tt, acc_pool]
    }
  }
  else {
    let cells = R.map(o => o.cell)(acc_tt);
    let acc_tt_indx = R.findIndex(R.propEq('per_id', item.per))(cells);
    let c = acc_tt[acc_tt_indx].cell;
    c.teacher = R.flatten(c.teacher);
    c.teacher = R.uniq(c.teacher);
    let idx = R.indexOf(item.stf_name, c.teacher_name);
    let teacher_arr = R.remove(idx, 1, c.teacher)
    c.teacher = teacher_arr;
    c.teacher_name = R.reject(t => t == item.stf_name, c.teacher_name);
    Object.assign(acc_tt[acc_tt_indx].cell,
      {
        "stf": c.teacher.toString(),
        "stf_name": c.teacher_name.toString(),
        "teacher": c.teacher,
        "teacher_name": c.teacher_name,
        "per_set": c.teacher_name.length >= 1 ? true : false
      });
    Object.assign(acc_tt[acc_tt_indx],
      { "teacher": c.teacher.toString() });
    let new_pool_object = {
      "section": item.sec,
      "teacher": item.stf,
      "cell": {
        "section": item.sec,
        "per_id": "",
        "per": null,
        "subject": item.sub,
        "teacher": item.stf,
        "section_name": item.sec_name,
        "staff_name": item.stf_name,
        "subject_name": item.sub_name,
        "ppw": 0,
        "weight": 0
      }
    }
    acc_pool = R.append(new_pool_object, acc_pool);
    return [acc_tt, acc_pool];
  }
}

const sortFnForStaff = function (a, b) {
  var int_a = parseInt(a.replace("T", ""), 10)
  var int_b = parseInt(b.replace("T", ""), 10)
  return int_a - int_b;
};
const getSubSec = (acc, { sec, sub }) =>
  acc == "" ?
    acc.concat(sec + ":" + sub) :
    acc.includes(sec + ":" + sub) ? acc : acc.concat("," + sec + ":" + sub)
const staffwise = ({ stf }) => stf
const subjects_taught = data => R.reduceBy(getSubSec, "", staffwise)(data)

const staffList = R.compose(R.uniq, R.pluck('stf'));

const expandStaff = (subs_taught, stf) => Object.assign({}, {
  "Name": stf,
  "CT": "",
  "SubjectsTaught": subs_taught[stf]
});
const rowDataStaff = data => {
  const subs_taught = subjects_taught(data)
  const expandStaff_partial = R.partial(expandStaff, [subs_taught])
  return R.map(expandStaff_partial, R.sort(sortFnForStaff, staffList(data)));
}

const getSubjects = (acc, { sub }) => acc == "" ? acc.concat(sub) : acc.includes(sub) ? acc : acc.concat("," + sub)
const section_wise = ({ sec }) => sec
const section_sub = data => R.reduceBy(getSubjects, "", section_wise)(data)
const sectionList = R.compose(R.uniq, R.pluck('sec'))
const expandSection = (subs, sec) => Object.assign({}, {
  "Name": sec,
  "MonToFri": 8,
  "Sat": 5,
  "Sun": 0,
  "Subjects": subs[sec]
});
const rowDataSections = data => {
  const subs = section_sub(data)
  const expandSection_partial = R.partial(expandSection, [subs])
  return R.map(expandSection_partial, sectionList(data));
}

const bySecSub = R.groupBy(cell => `${cell.sec}:${cell.sub}`);
const secItem = (num, key, obj) => {
  return {
    "sec": R.split(":", key)[0],
    "sub": key,
    "ppw": obj[key][0],
    "multipleStaff": obj[key][1],
    "weight": "1"
  }
}
const assignId = (obj, idx) => R.assoc('id', `S${idx + 1}`, obj);
const mapIndexed = R.addIndex(R.map);
const greaterThan1 = R.gt(R.__, 1);
const subTuple = arr => {
  let stf_list_count = arr.map(a => a["stf_list"].length);
  return [arr.length, R.any(greaterThan1)(stf_list_count)];
}
const rowDataSubjects = data => R.compose(mapIndexed(assignId), R.sortBy(R.prop('sub')), R.values, R.mapObjIndexed(secItem, R.__), R.map(subTuple, R.__), bySecSub)(data);

// split cell data as two different cell for multiple staff to get subjects in staff panel
const stf_cell_generate = (acc, [cell, stf]) =>
  R.concat(acc, [Object.assign({}, cell, { "stf": stf, stf_list: [stf] })])
const expander = cell => {
  let arr = R.map(x => [cell, x], R.split(",", cell["stf"]))
  return R.reduce(stf_cell_generate, [], arr)
}
const expandData = data => R.compose(R.flatten, R.map(expander))(data);

const staff_cell_generate = (acc, [o, stf]) => {
  let new_stf = Object.assign({}, o, { "teacher": stf },
    { "cell": Object.assign({}, o.cell, { "teacher": [stf] }) }
  )
  return R.concat(acc, [new_stf])
}
const staff_expander = o => {
  let arr = R.map(x => [o, x], o.cell.teacher)
  return R.reduce(staff_cell_generate, [], arr)
}
const expand_staff_data = x => R.compose(R.flatten, R.map(staff_expander))(x);

const blank_csv = (no_of_sections, weekly_pattern, max_ppw) => {
  let csv_string = "per,sec,sub,stf,per_set,sec_ppw,sub_ppw,sub_weight,stf_max_ppw,sec_name,sub_name,stf_name,loc,loc_name\n";
  const ppw = 45;
  const subject_weight = 1;
  const ppw_total = no_of_sections * ppw;
  const staff_count = Math.round(ppw_total / max_ppw);
  const per_ids = R.map(x => "P" + x)(R.range(1, ppw_total + 1))
  const sec_ids = R.map(x => "G" + x)(R.range(1, no_of_sections + 1))
  const staff_ids = R.map(x => "T" + x)(R.range(1, staff_count + 1))
  const per_ids_by_section = R.splitEvery(ppw, per_ids);

  const add_row = (per_id, sec_idx) => {
    csv_string += `${per_id},${sec_ids[sec_idx]},,,false,${weekly_pattern},,${subject_weight},${max_ppw},,,,\n`;
  }
  const mapIndexed = R.addIndex(R.map);
  const x = mapIndexed((per_list, sec_idx) =>
    R.forEach(per_id => add_row(per_id, sec_idx), per_list));
  x(per_ids_by_section);
  return csv_string;
}



export { get_assigned_periods, set_additional_teacher_count, set_subject_list, set_section_list, set_additional_teacher_list, set_teacher_list, set_weekly_pattern, set_ppw, set_teacher_count, set_sections_count, expand_staff_data, expandData, apply_change_set, rowDataSections, rowDataStaff, rowDataSubjects, tt_json, pool_json, tt_by_sec, tt_by_teacher, get_unassigned_periods, teacher_ppw, get_free_periods_by_teacher, init_json, tt, pool, all_periods, blank_csv };
