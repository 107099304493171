import * as app from './action';

export class AssetAction {
    constructor(public id: number, public displayText: string, public methodName: string) {
    }
}

export class Asset
{
    id: string;
    trackingId: string;
    location: string;
    tags: string[];
    attributes: any;
    value: number;
    status: any;

    actions: AssetAction[] = [];
    get allowedActions(): AssetAction[] {
        let result = [];
        switch (this.status) {
            case 1: //active
                result = [];
                result.push(result.push(this.actions[3]) && result.push(this.actions[4]) && result.push(this.actions[5]) && result.push(this.actions[6]) && result.push(this.actions[2]) ) ;
                break;
            case 99: //disabled
                result = [];
                result.push(this.actions[0]);
                break;
            default:
                result = [];
        }
        return result;
    }
    constructor() {
        this.actions.push(new AssetAction(1, "Enable", "ut_enableAsset"));
        this.actions.push(new AssetAction(2, "Disable", "ut_disableAsset"));
        this.actions.push(new AssetAction(3, "Clone", "ut_cloneAsset"));
        this.actions.push(new AssetAction(4, "History", "ut_assetHistory"));
        this.actions.push(new AssetAction(5, "Maintain", "ut_maintainAsset"));
        this.actions.push(new AssetAction(6, "Warranty", "ut_assetWarranty"));
        this.actions.push(new AssetAction(7, "Divider", ""));
    }
}

export class Kv {
    constructor(public key: string, public value: string) {
    }
}

export interface State {
    userLinks: any[];
    html: any;
    assets: Asset[];
    selectedAsset: Asset;
}

const initialState: State = {
    userLinks: null,
    html: null,
    assets: [],
    selectedAsset: null
};

function selectedAsset(assets, id): Asset {
    var selectedAsset: Asset = assets.find(asset => asset.id == id);
    return selectedAsset;
}

export function reducer(state = initialState, action: app.Actions): State {
    switch (action.type) {
        case app.ActionTypes.CREATE_ASSET:
            return Object.assign({}, state, { asset: action.payload.asset });
        case app.ActionTypes.SET_SELECTED_USER_LINK:
            return Object.assign({}, state, { selectedUserLink: action.payload.userLink });
        case app.ActionTypes.SET_USER_LINKS:
            return Object.assign({}, state, { userLinks: action.payload.user_links });
        case app.ActionTypes.UPDATE_DASHBOARD:
            return Object.assign({}, state, { userLinks: action.payload.userLinks });
        case app.ActionTypes.SET_HTML:
            return Object.assign({}, state, { html: action.payload });
            case app.ActionTypes.SET_SELECTED_ASSET:
            return Object.assign({}, state, { selectedAsset: selectedAsset(state.assets, action.payload.asset_id) });    
        default:
            return state;
    }
}

export const get_state_asset = (state: State) => state;
export const get_html_asset = (state: State) => state.html;
export const get_assets = (state: State) => state.assets;
export const get_selected_asset = (state: State) => state.selectedAsset;