import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { CTApi } from './ct-api';
import { IN_PAGE_SIDEBAR_DATA } from './constants';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  private timers = {};
  isLoggedIn: boolean = false;
  public dexSidebarOpen: boolean = false;
  // public dexSidebarOpen: boolean = true;
  public showDexResults: boolean = false;
  public isDexEnabled: boolean = false;
  // to use in other components; to reduce subscriptions
  public currentPersona = {};
  public dryRunStats: Array<any> = [];
  public publisherEventEmitter = new EventEmitter();
  public publisherPayload: Array<any> = [];
  public inPageSidebarOpen: boolean = false;
  public inPageSidebarKey: string = 'ScheduledPublishes';
  public isAccordionOpen = false;
  public inPageSidebarData = IN_PAGE_SIDEBAR_DATA[this.inPageSidebarKey];

  constructor(private ctApi: CTApi) { }

  /**
   * Logger for Debugging in Development Mode
   * @param message 
   * @param optionalParams 
   */
  dLog(message?: any, ...optionalParams: any[]): void {
    if (!environment.production) {
      console.log(message, optionalParams);
    }
  }

  /**
   * 
   * @param key to identify the timer and to prevent clearing other timers
   * @param callback 
   * @param delay default 3000
   */
  debouncerWithKey(key, callback, delay = 3000) {
    clearTimeout(this.timers[key]);
    if (callback) {
      this.timers[key] = setTimeout(() => { callback() }, delay);
    }
  }

  getUserIntros() {
    return this.ctApi.getUserIntros();
  }
  updateUserIntros(intros: { Id: string, Intros: { [a: string]: boolean } }) {
    return this.ctApi.updateUserIntros(intros);
  }
  toggleDexSidebar() {
    this.dexSidebarOpen = !this.dexSidebarOpen;
  }
  ut_toggleInPageSidebar() {
    this.inPageSidebarOpen = !this.inPageSidebarOpen;
  }
}
