import * as app from './action';

export interface State {
    userLinks: any[];
    html: any;
}

const initialState: State = {
    userLinks: null,
    html: null
};

export function reducer(state = initialState, action: app.Actions): State {
    switch (action.type) {
        case app.ActionTypes.SET_SELECTED_USER_LINK:
            return Object.assign({}, state, { selectedUserLink: action.payload.userLink });
        case app.ActionTypes.SET_USER_LINKS:
            return Object.assign({}, state, { userLinks: action.payload.user_links });
        case app.ActionTypes.UPDATE_DASHBOARD:
            return Object.assign({}, state, { userLinks: action.payload.userLinks });
        case app.ActionTypes.SET_HTML:
            return Object.assign({}, state, { html: action.payload });
        default:
            return state;
    }
}

export const get_state_rc = (state: State) => state;
export const get_html_rc = (state: State) => state.html;