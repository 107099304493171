import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import * as rootReducer from '../../rootReducer';
import { Router } from '@angular/router';

import * as _ from 'underscore';

class Model {
    qualifier: string = "all";
}

@Component({
    selector: 'filter-search',
    templateUrl: './component.html',
    styleUrls: ['./component.css']
    //providers: [service,dateService]
})
export class FilterSearchComponent implements OnInit {
    public m: Model;
    public currentPage: number;
    public pageSize: number;
    constructor(
        //public service: service, 
        //public dateService: dateService, 
        private store: Store<rootReducer.State>, private router: Router) {
    }

    public ph: string = "";
    @Input() showFilter: boolean;
    @Input() showPager: boolean;
    @Input() showSearch: boolean;
    @Input() totalPages: number;
    @Input() sortOn: string;
    @Input() sortOrder: string;
    @Input() placeHolder: string = "Search";
    @Input() orderBy: string;
    @Input() filterOptions: any;
    @Output() onQry = new EventEmitter<string>();
    @Output() onKeypress = new EventEmitter<string>();
    @Input() searchText: string = '';
    @Input() searchString: string = '';
    @Input() mediaOnly: boolean = false;
    @Input() publishedOnly: boolean = false;
    @Input() notAdmitted: boolean = false;
    @Input() admitted: boolean = false;
    @Input() disabled: boolean = false;
    @Input() getFilterExpr: boolean = false;
    @Input() admission: boolean = false;
    @Input() content: boolean = false;
    public filterExpression: string = "";
    public filterArray: any[] = [];

    ngOnInit() {
        this.init_model();
        this.init_store();
        this.sub_store();
        this.showFilter = this.showFilter || false;
        this.showPager = this.showPager || false;
        this.totalPages = this.totalPages || 1000;
        this.currentPage = 1;
        this.pageSize = 10;
        this.orderBy = this.orderBy || "";
        this.sortOrder = this.sortOrder || "";
        this.sortOn = this.sortOn || "";
        this.filterOptions = this.filterOptions || null;
        this.init_qry();
    }

    ngOnDestroy() {
    }

    init_model() {
        this.m = new Model();
    }

    init_store() {
        if (this.getFilterExpr) this.getFilterExp();
    }

    init_qry() {
        var str = this.getQryString();
        this.onQry.emit(str);
    }

    sub_store() {
    }

    search() {
        this.currentPage = 1;
        var str = this.getQryString();
        this.onQry.emit(str);
    }

    inpModelChange(st) {
        this.onKeypress.emit(st);
    }

    clearSearch(ev) {
        this.currentPage = 1;
        this.searchText = "";
        this.searchString = "";
        var str = this.getQryString();
        this.onQry.emit(str);
        ev.stopPropagation();
        ev.preventDefault();
    }

    getQryString() {
        var searchQry = "?$search=";
        if (this.publishedOnly) {
            var searchQry = searchQry + "is:published";
        }
        if (this.notAdmitted) {
            var searchQry = searchQry + "status:applied,shortlisted";
        }
        if (this.admitted) {
            var searchQry = searchQry + "status:applied,shortlisted,admitted";
        }
        if (this.mediaOnly) {
            var sQry = "$search=";
            var searchQry = "?$orderby=createdOn%20desc&$skip=0&$top=1024&" + sQry + "media:true ";
        }
        var q = searchQry + this.filterExpression;
        if (this.content) {
            q = "";
            q = "&$search=" + this.filterExpression;
        }
        if (this.searchString != '') {
            if (this.filterExpression) {
                q = q + ' search:' + this.searchString;
            }
            else {
                q = q + 'search:' + this.searchString;
            }
        }
        if (this.admission) {
            var skipVal = (this.currentPage - 1) * this.pageSize;
            if (!skipVal) { skipVal = 0; }
            this.pageSize = 1024;
            q = q + "&$skip=" + skipVal + "&$top=" + this.pageSize;
        }
        if (this.content) {
            var skipVal = (this.currentPage - 1) * this.pageSize;
            if (!skipVal) { skipVal = 0; }
            this.pageSize = 1024;
            q = q + "&$skip=" + skipVal + "&$top=" + this.pageSize;
            if(this.filterExpression){ q = q + "&$qual=" + this.m.qualifier; }
        }
        if (!this.mediaOnly && !this.admission && !this.content) {
            var skipVal = (this.currentPage - 1) * this.pageSize;
            if (!skipVal) { skipVal = 0; }
            if (!this.pageSize) { this.pageSize = 10; }
            q = q + "&$skip=" + skipVal + "&$top=" + this.pageSize;
        }
        if (this.mediaOnly && this.filterExpression) {
            q = q + "&$qual=" + this.m.qualifier;
        }
        return q;
    }

    handleEvent(event) {
        event.stopPropagation();
    }

    resetPlaceholder(ev) {
        ev.stopPropagation();
        this.ph = "";
    }

    paginate() {
        var str = this.getQryString();
        this.onQry.emit(str);
    }

    firstPage() {
        this.currentPage = 1;
        var str = this.getQryString();
        this.onQry.emit(str);
    }

    prevPage() {
        if (this.currentPage > 1) {
            this.currentPage--;
            var str = this.getQryString();
            this.onQry.emit(str);
        }
    }

    nextPage() {
        if (this.currentPage < this.totalPages) {
            this.currentPage++;
            var str = this.getQryString();
            this.onQry.emit(str);
        }
    }

    lastPage() {
        this.currentPage = this.totalPages;
        var str = this.getQryString();
        this.onQry.emit(str);
    }

    setPage() {
        var pageNo = isNaN(Number(this.ph)) ? this.currentPage : Number(this.ph);
        pageNo = (pageNo <= 0) ? 1 : (pageNo > this.totalPages) ? this.totalPages : pageNo;
        this.ph = "";
        this.currentPage = pageNo;
        var str = this.getQryString();
        this.onQry.emit(str);
    }

    getFilterExp() {
        setTimeout(() => {
            this.filterExpression = "";
            this.filterArray = [];
            this.filterOptions.sections.forEach(sec => {
                sec.filterItems.forEach(x => {
                    if (x.fe && (x.checked)) {
                        var feObj = { k: "", v: "", d: x.name };
                        var feArray = x.fe.split(":");
                        feObj.k = feArray[0];
                        feObj.v = feArray[1];
                        this.filterArray.push(feObj);
                    }
                    x.children.forEach(a => {
                        if (a.fe && (a.checked)) {
                            var feObj = { k: "", v: "", d: a.name };
                            var feArray = a.fe.split(":");
                            feObj.k = feArray[0];
                            feObj.v = feArray[1];
                            this.filterArray.push(feObj);
                        }
                        else {
                            _.each(a.children, function (u, v) {
                                if (u.fe && (u.checked)) {
                                    var feObj = { k: "", v: "", d: u.name };
                                    var feArray = u.fe.split(":");
                                    feObj.k = feArray[0];
                                    feObj.v = feArray[1];
                                    this.filterArray.push(feObj);
                                }
                            });
                        }
                    });
                });
            });
            var groups = _
                .chain(this.filterArray)
                .groupBy('k')
                .map(function (value, key) {
                    return {
                        type: key,
                        items: _.pluck(value, 'v')
                    }
                })
                .value();

            this.filterExpression = "";
            this.filterArray.sort((a, b) => a.d.localeCompare(b.d))
            groups.forEach((v, index) => {
                this.filterExpression += v.type + ':' + v.items.toString();
                if (index < groups.length - 1) { this.filterExpression += ' ' }
            });
            this.search();
        }, 100)
    };

    removeFilterOption(filterOp) {
        if (filterOp && filterOp.k == 'tags') {
            this.filterOptions.sections.forEach(sec => {
                var filOp = sec.filterItems.find(fil => fil.name == filterOp.d);
                if (filOp) { filOp.checked = false; }
            });
        }
        this.getFilterExp();
    }

    clearFilters() {
        this.currentPage = 1;
        this.filterExpression = "";
        this.filterArray = [];
        this.filterOptions.sections.forEach(sec => {
            sec.filterItems.forEach(fil => {
                fil.checked = false;
            });
        });

        var str = this.getQryString();
        this.onQry.emit(str);
    }

    setQualifier() {
        if (this.m.qualifier == "all") { this.m.qualifier = "any"; }
        else if (this.m.qualifier == "any") { this.m.qualifier = "all"; }
        var str = this.getQryString();
        this.onQry.emit(str);
    }
}