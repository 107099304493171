import { Injectable } from "@angular/core";
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from "@ngrx/store";
import * as rootReducer from '../../../rootReducer';
import { ActionTypes, LoadAssetsData, LoadEndpointData, UpdateChartDashboardData, UpdateChartMetaData } from '../../../widgets/store/action';
import { map, mergeMap } from 'rxjs/operators';
import { ApiService } from "./api-service";
import * as R from 'ramda';
import { none } from "ramda";
import { Observable } from "rxjs";
import * as actions from '../../../widgets/store/action';

@Injectable()
export class ChartDashboardEffects {
    @Effect() loadDataFromApi$ = this.actions$
        .pipe(
            ofType<LoadEndpointData>(ActionTypes.LOAD_ENDPOINT_DATA),
            mergeMap(
                (action) => this.apiService.getApiContent(action.payload)
                    .pipe(
                        map((res: any) => {
                            if (R.path(["httpStatus"], res)) {
                                if (res.httpStatus == 200) {
                                    let msg: string = "";
                                    if (R.path(['data'], res)) {
                                        if (res.data.length > 0) {
                                            return new UpdateChartDashboardData({ dashboardId: action.payload.dashboardId, widgetId: action.payload.widgetId, data: res.data });
                                        } else {
                                            msg = "Endpoint " + R.pathOr("", ["data", "api", "end_point"], action.payload) + "<span class='red-text'> has no data.</span>";
                                            return new UpdateChartMetaData({ dashboardId: action.payload.dashboardId, widgetId: action.payload.widgetId, msg: msg });
                                        }
                                    } else {
                                        msg = "Endpoint " + R.pathOr("", ["data", "api", "end_point"], action.payload) + "<span class='red-text'> missing data property.</span>";
                                        return new UpdateChartMetaData({ dashboardId: action.payload.dashboardId, widgetId: action.payload.widgetId, msg: msg });
                                    }
                                }
                            }
                        })
                    )
                    .catch((error: any) => {
                        if (error.status == 404) {
                            let msg: string = "Endpoint " + R.pathOr("", ["data", "api", "end_point"], action.payload) + "<span class='red-text'> is not present.</span>";
                            this.store.dispatch(new actions.UpdateChartMetaData({ dashboardId: action.payload.dashboardId, widgetId: action.payload.widgetId, msg: msg }));
                        } else {
                            this.store.dispatch(new actions.UpdateChartMetaData({ dashboardId: action.payload.dashboardId, widgetId: action.payload.widgetId, msg: "Error while getting data." }));
                        }
                        return [];
                        // return Observable.throwError(error.statusText || "Something went wrong!");
                    })
            )
        )

    @Effect() loadDataFromAssets$ = this.actions$
        .pipe(
            ofType<LoadAssetsData>(ActionTypes.LOAD_ASSETS_DATA),
            mergeMap(
                (action) => this.apiService.getAssetContent(action.payload)
                    .pipe(
                        map((res: any) => {
                            if (res) {
                                let widgetData = [];
                                if (R.path(['data_list'], res)) {
                                    res.data_list.forEach((wData: any) => {
                                        if (wData.id.split("/")[1] == action.payload.widgetId) {
                                            widgetData = wData.data;
                                        }
                                    });
                                }
                                return new UpdateChartDashboardData({ dashboardId: action.payload.dashboardId, widgetId: action.payload.widgetId, data: widgetData });
                            }
                        })
                    )
            )
        )
    constructor(
        private store: Store<rootReducer.State>,
        private actions$: Actions, private apiService: ApiService
    ) { }
}