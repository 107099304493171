import * as org from './action';
import * as _ from 'underscore';

export interface State {
    orgs: any[];
    firstLevelOrgs: any[];
    modules: any[];
    selectedOrg: any;
    insts: any[];
    users: any[];
    personas: any[];
    selectedUser: any;
    selectedPersona: any;
    permissions: any[];
    selectedClient: any;
    totalPages: number;
}

const initialState: State = {
    orgs: [],
    firstLevelOrgs: [],
    modules: [],
    selectedOrg: {},
    insts: [],
    users: [],
    personas: [],
    selectedUser: {},
    selectedPersona: {},
    permissions: [],
    selectedClient: {},
    totalPages: null
};

function get_first_lvl_orgs(orgs): any {
    var none = {id:'', name:'None'};
    orgs.push(none);
    return orgs;
}

export function reducer(state = initialState, action: org.Actions): State {
    switch (action.type) {
        case org.ActionTypes.SET_ORGS:
            return Object.assign({}, state, { orgs: action.payload.orgs });
        case org.ActionTypes.SET_FIRST_LEVEL_ORGS:
            return Object.assign({}, state, { firstLevelOrgs: get_first_lvl_orgs(action.payload.firstLevelOrgs) });
        case org.ActionTypes.SET_MODULES:
            return Object.assign({}, state, { modules: action.payload.modules });
        case org.ActionTypes.SET_SELECTED_ORG:
            return Object.assign({}, state, { selectedOrg: action.payload.selectedOrg });
        case org.ActionTypes.SET_ORG_INSTS:
            return Object.assign({}, state, { insts: action.payload.insts });
        case org.ActionTypes.SET_ORG_USERS:
            return Object.assign({}, state, { users: action.payload.users, totalPages: action.payload.totalPages });
        case org.ActionTypes.SET_ORG_PERSONAS:
            return Object.assign({}, state, { personas: action.payload.personas });
        case org.ActionTypes.SET_SELECTED_USER:
            return Object.assign({}, state, { selectedUser: action.payload.selectedUser });
        case org.ActionTypes.SET_SELECTED_PERSONA:
            return Object.assign({}, state, { selectedPersona: action.payload.selectedPersona });
        case org.ActionTypes.SET_ORG_PERMISSIONS:
            return Object.assign({}, state, { permissions: action.payload.permissions });
        case org.ActionTypes.SET_SELECTED_CLIENT:
            return Object.assign({}, state, { selectedClient: action.payload.selectedClient });
        default:
            return state;
    }
}

export const get_orgs = (state: State) => state;
export const get_first_level_orgs = (state: State) => state.firstLevelOrgs;
export const get_modules = (state: State) => state.modules;
export const get_selected_org = (state: State) => state.selectedOrg;
export const get_org_insts = (state: State) => state.insts;
export const get_client_users = (state: State) => state.users;
export const get_client_personas = (state: State) => state.personas;
export const get_selected_user = (state: State) => state.selectedUser;
export const get_selected_persona = (state: State) => state.selectedPersona;
export const get_client_permissions = (state: State) => state.permissions;
export const get_selected_client = (state: State) => state.selectedClient;
export const get_user_manage_pages_count = (state: State) => state.totalPages;
