import { Component, OnInit, OnDestroy } from "@angular/core";
import { timer, Subscription } from "rxjs";
import * as rootReducer from '../../rootReducer';
import { Store } from '@ngrx/store';

import * as countdownTimerAction from './store/action';

@Component({
  selector: "countdown-timer",
  templateUrl: "./component.html",
  styleUrls: ["./component.css"]
})
export class CountdownTimerComponent implements OnInit, OnDestroy {
  countDown: Subscription;
  counter = 180;
  tick = 1000;
  constructor(private store: Store<rootReducer.State>) { }
  ngOnInit() {
     if(this.counter != 0) --this.counter;
     this.countDown = timer(0, this.tick).subscribe(() => {
      if (this.counter === 0) this.stopTimer();
    });

  }
  ngOnDestroy() {
    this.countDown = null;
  }

  stopTimer() {
    this.store.dispatch(new countdownTimerAction.TimerStopped({ 'timerStopped' : true }))
  }
}


