import * as app from './action';

export class MaterialAction {
    constructor(public id: number, public displayText: string, public methodName: string) {
    }
}
export class Kv {
    constructor(public key: string, public value: string) {
    }
}
export class Material {
    id: string;
    title: string;
    trackingId: string;
    location: string;
    author: string;
    publisher: string;
    isbn: string;
    tags: string[];
    value: number;
    pages: number;
    attributes: any;
    get attributesKv(): Kv[] {
        var result = [];
        if (this.attributes) {
            for (var key in this.attributes) {
                if (this.attributes.hasOwnProperty(key)) { result.push(new Kv(key, this.attributes[key])); }
            }
        }
        return result;
    }
    status: number;
    actions: MaterialAction[] = [];
    get allowedActions(): MaterialAction[] {
        let result = [];
        switch (this.status) {
            case 1: //active
                result = [];
                result.push(this.actions[8]) && result.push(this.actions[4]) && result.push(this.actions[9]) && result.push(this.actions[1]);
                break;
            case 99: //disabled
                result = [];
                result.push(this.actions[0]);
                break;
            case 10: //issued
                result = [];
                result.push(this.actions[7]);
                break;
            case 11: //reIssued
                result = [];
                result.push(this.actions[7]);
                break;
            case 20: //underMaintenance
                result = [];
                result.push(this.actions[5]);
                break;
            default:
                result = [];
        }
        return result;
    }
    constructor() {
        this.actions.push(new MaterialAction(1, "Enable", "ut_enableMaterial"));
        this.actions.push(new MaterialAction(2, "Disable", "ut_disableMaterial"));
        this.actions.push(new MaterialAction(3, "Issue", "ut_issueMaterial"));
        this.actions.push(new MaterialAction(4, "Archive", "ut_archiveMaterial"));
        this.actions.push(new MaterialAction(5, "Maintain", "ut_maintainMaterial"));
        this.actions.push(new MaterialAction(6, "Return", "ut_returnMaintenanceMaterial"));
        this.actions.push(new MaterialAction(7, "Reissue", "ut_reissueMaterial"));
        this.actions.push(new MaterialAction(8, "NonReturn", "ut_nonReturnMaterial"));
        this.actions.push(new MaterialAction(9, "Clone", "ut_cloneMaterial"));
        this.actions.push(new MaterialAction(10, "Divider", ""));
    }
}
export class LibraryContext {
    constructor(SelectedMaterialId: string, SelectedMaterialTitle: string) {
    }
}

export class CircMaterial {
    id: string;
    trackingId: string;
    title: string;
    author: string;
    publisher: string;
    issuedOn: Date = null;
    dueOn: Date = null;
    dueIn: string;
    returnedOn: Date = null;
    overDue: boolean = false;
    status: string;
    canCirculate: boolean;
}

export class LibMember {
    membershipId: string = "";
    name: string = "";
    memberType: string = ""; //Student / Staff
    course: string = ""; //Course if Student else blank
    section: string = "";
    maxIssues: number = 3;
    issuedList: Array<CircMaterial> = [];
    newIssue: any[] = [];

    materialCanBeIssued: boolean = true;
    get canIssue(): boolean { return this.issuedList.length < this.maxIssues; }
}

export interface State {
    member: any;
    materials: Material[];
    selectedMaterial: Material;
    materialsUnderCirculation: Material[];
    materialToIssue: any;
    materialToReturnReissue: any;
    materialToCirculate: any;
    userLinks: any[];
    selectedUserLink: {};
    libraryContext: LibraryContext;
    totalPages: any;
    materialForStockVerification: Material;
    manageSearchTerm: string;
    circulationSearchTerm: string;
    queryString: string;
    dueDate: any;
}

const initialState: State = {
    member: null,
    materials: [],
    selectedMaterial: new Material(),
    materialsUnderCirculation: [],
    materialToIssue: null,
    materialToReturnReissue: null,
    materialToCirculate: null,
    userLinks: null,
    selectedUserLink: null,
    libraryContext: new LibraryContext("", ""),
    totalPages: null,
    materialForStockVerification: null,
    manageSearchTerm: null,
    circulationSearchTerm: null,
    queryString: null,
    dueDate: null
};

function disableMaterial(materials, id) {
    var materialList: Material[] = materials.map((item) => {
        if (item.id == id) item.status = 99;
        return item;
    });
    return materialList;
}
function enableMaterial(materials, id) {
    var materialList: Material[] = materials.map((item) => {
        if (item.id == id) item.status = 1;
        return item;
    });
    return materialList;
}
function issueMaterial(materials, id) {
    var materialList: Material[] = materials.map((item) => {
        if (item.id == id) item.status = 10;
        return item;
    });
    return materialList;
}
function materialMaintenance(materials, id) {
    var materialList: Material = materials.map((item) => {
        if (item.id == id) item.status = 20;
        return item;
    });
    return materialList;
}
function returnMaintenanceMaterial(materials, id) {
    var materialList: Material = materials.map((item) => {
        if (item.id == id) item.status = 1;
        return item;
    });
    return materialList;
}

function selectedMaterial(materials, id): Material {
    var selectedStudent: Material = materials.find(student => student.id == id);
    return selectedStudent;
}

function setAttributes(materials, id, attributes) {
    var materialList: Material[] = materials.map((item) => {
        if (item.id == id) item.attributes = attributes;
        return item;
    });
    return materialList;
}
function setTags(materials, id, tags) {
    var materialList: Material[] = materials.map((item) => {
        if (item.id == id) item.tags = tags;
        return item;
    });
    return materialList;
}
function setValue(materials, id, value) {
    var materialList: Material[] = materials.map((item) => {
        if (item.id == id) item.value = value;
        return item;
    });
    return materialList;
}
function setMaterialPages(materials, id, pages) {
    var materialList: Material[] = materials.map((item) => {
        if (item.id == id) item.pages = pages;
        return item;
    });
    return materialList;
}
function updateMaterial(materials, id, title, author, publisher) {
    var materialList: Material[] = materials.map((item) => {
        if (item.id == id) {
            item.title = title;
            item.author = author;
            item.publisher = publisher;
        }
        return item;
    });
    return materialList;
}
function updateISBN(materials, id, isbn) {
    var materialList: Material[] = materials.map((item) => {
        if (item.id == id) item.isbn = isbn;
        return item;
    });
    return materialList;
}
function updateLocation(materials, id, location) {
    var materialList: Material[] = materials.map((item) => {
        if (item.id == id) item.location = location;
        return item;
    });
    return materialList;
}
function setMaterialArray(dataFromServer) {
    let result = [];
    if (dataFromServer) {
        dataFromServer.forEach(o => {
            let mat = new Material();
            mat.id = o.id;
            mat.author = o.author;
            mat.isbn = o.isbn;
            mat.location = o.location;
            mat.publisher = o.publisher;
            mat.status = o.status;
            mat.tags = o.tags;
            mat.title = o.title;
            mat.trackingId = o.trackingId;
            mat.value = o.value;
            mat.attributes = o.attributes;
            mat.pages = o.pages;
            result.push(mat);
        });
    }
    return result;
}

function setMember(dataFromServer): LibMember {
    let mem = new LibMember();
    mem.membershipId = dataFromServer.membershipId;
    mem.name = dataFromServer.name;
    mem.memberType = dataFromServer.memberType;
    mem.course = dataFromServer.course;
    mem.section = dataFromServer.section;
    mem.issuedList = dataFromServer.issuedList;
    return mem;
}
function setMaterialForStockVerification(dataFromServer): Material {
    if (dataFromServer) {
        let mat = new Material();
        mat.id = dataFromServer.id;
        mat.title = dataFromServer.title;
        mat.author = dataFromServer.author;
        mat.publisher = dataFromServer.publisher;
        mat.trackingId = dataFromServer.trackingId;
        mat.attributes = dataFromServer.attributes;
        mat.tags = dataFromServer.tags;
        mat.isbn = dataFromServer.isbn;
        mat.location = dataFromServer.location;
        mat.pages = dataFromServer.pages;
        mat.value = dataFromServer.value;
        return mat;
    }
}

export function reducer(state = initialState, action: app.Actions): State {
    switch (action.type) {
        case app.ActionTypes.CREATE_MATERIAL:
            return Object.assign({}, state, { materials: action.payload.materials });
        case app.ActionTypes.CLONE_MATERIAL:
            return Object.assign({}, state, { materials: action.payload.trackingIds });
        case app.ActionTypes.DISABLE_MATERIAL:
            return Object.assign({}, state, { materials: disableMaterial(state.materials, action.payload.id) });
        case app.ActionTypes.ENABLE_MATERIAL:
            return Object.assign({}, state, { materials: enableMaterial(state.materials, action.payload.id) });
        case app.ActionTypes.ISSUE_MATERIAL:
            return Object.assign({}, state, { materials: issueMaterial(state.materials, action.payload.id) });
        case app.ActionTypes.MATERIAL_MAINTENANCE:
            return Object.assign({}, state, { materials: materialMaintenance(state.materials, action.payload.id) });
        case app.ActionTypes.RETURN_MAINTENANCE_MATERIAL:
            return Object.assign({}, state, { materials: returnMaintenanceMaterial(state.materials, action.payload.id) });
        case app.ActionTypes.SET_ATTRIBUTES:
            return Object.assign({}, state, { materials: setAttributes(state.materials, action.payload.id, action.payload.attributes) });
        case app.ActionTypes.SET_LIBRARY_CONTEXT:
            return Object.assign({}, state, { libraryContext: action.payload });
        case app.ActionTypes.SET_MATERIAL:
            return Object.assign({}, state, { materials: setMaterialArray(action.payload.material_list) });
        case app.ActionTypes.SET_TOTAL_PAGES:
            return Object.assign({}, state, { totalPages: action.payload.totalPages });
        case app.ActionTypes.SET_MEMBER:
            return Object.assign({}, state, { member: setMember(action.payload.member) });
        case app.ActionTypes.RESET_MEMBER:
            return Object.assign({}, state, { member: action.payload.member });
        case app.ActionTypes.SET_MATERIAL_TO_CIRCULATE:
            return Object.assign({}, state, { materialToCirculate: action.payload.materialToCirculate });
        case app.ActionTypes.SET_MATERIAL_TO_ISSUE:
            return Object.assign({}, state, { materialToIssue: action.payload.materialToIssue });
        case app.ActionTypes.SET_MATERIAL_TO_RETURN_OR_REISSUE:
            return Object.assign({}, state, { materialToReturnReissue: action.payload.materialToReturnReissue });
        case app.ActionTypes.SET_HTML:
            return Object.assign({}, state, { setHTML: action.payload });
        case app.ActionTypes.SET_SELECTED_MATERIAL:
            return Object.assign({}, state, { selectedMaterial: selectedMaterial(state.materials, action.payload.material_id) });
        case app.ActionTypes.SET_SELECTED_USER_LINK:
            return Object.assign({}, state, { selectedUserLink: action.payload.userLink });
        case app.ActionTypes.SET_TAGS:
            return Object.assign({}, state, { materials: setTags(state.materials, action.payload.id, action.payload.tags) });
        case app.ActionTypes.SET_USER_LINKS:
            return Object.assign({}, state, { userLinks: action.payload.user_links });
        case app.ActionTypes.SET_VALUE:
            return Object.assign({}, state, { materials: setValue(state.materials, action.payload.id, action.payload.value) });
        case app.ActionTypes.SET_MATERIAL_PAGES:
            return Object.assign({}, state, { materials: setMaterialPages(state.materials, action.payload.id, action.payload.pages) });

        case app.ActionTypes.UPDATE_DASHBOARD:
            return Object.assign({}, state, { userLinks: action.payload.userLinks });
        case app.ActionTypes.UPDATE_ISBN:
            return Object.assign({}, state, { materials: updateISBN(state.materials, action.payload.id, action.payload.isbn) });
        case app.ActionTypes.UPDATE_LOCATION:
            return Object.assign({}, state, { materials: updateLocation(state.materials, action.payload.id, action.payload.location) });
        case app.ActionTypes.UPDATE_MATERIAL:
            return Object.assign({}, state, { materials: updateMaterial(state.materials, action.payload.id, action.payload.title, action.payload.author, action.payload.publisher) });
        case app.ActionTypes.SET_MATERIAL_FOR_STOCK_VERIFICATION:
            return Object.assign({}, state, { materialForStockVerification: setMaterialForStockVerification(action.payload.materialForStockVerification) });
        case app.ActionTypes.SET_LIB_MANAGE_SEARCH:
            return Object.assign({}, state, { manageSearchTerm: action.payload.search_text });
        case app.ActionTypes.SET_MANAGE_QUERY_STRING:
            return Object.assign({}, state, { queryString: action.payload.query_string });
        case app.ActionTypes.SET_CIRCULATION_SEARCH:
            return Object.assign({}, state, { circulatiionSearchTerm: action.payload.search_text });
        case app.ActionTypes.SET_DUE_DATE:
            return Object.assign({}, state, { dueDate: action.payload.dueDate });
        default:
            return state;
    }
}
export const get_state_library = (state: State) => state;
export const get_material = (state: State) => state.materials;
export const get_materials_under_circulation = (state: State) => state.materialsUnderCirculation;
export const get_library_context = (state: State) => state.libraryContext;
export const get_selected_material = (state: State) => state.selectedMaterial;
export const get_selected_user_link = (state: State) => state.selectedUserLink;
export const get_member = (state: State) => state.member;
export const get_material_to_circulate = (state: State) => state.materialToCirculate;
export const get_material_to_issue = (state: State) => state.materialToIssue;
export const get_material_to_return_or_reissue = (state: State) => state.materialToReturnReissue;
export const get_materials_total_pages = (state: State) => state.totalPages;
export const get_material_for_stock_verification = (state: State) => state.materialForStockVerification;
export const get_lib_manage_search_term = (state: State) => state.manageSearchTerm;
export const get_lib_manage_query_string = (state: State) => state.queryString;
export const get_circulation_search_term = (state: State) => state.circulationSearchTerm;
export const get_library_due_date = (state: State) => state.dueDate;