import { NotificationForWidget } from '../service/interfaces';
import * as app from './action';

function disable_voucher(vouchers, id) {
    var voucher_list = vouchers.map((item) => {
        if (item.id == id) item.status = 99;
        return item;
    });
    return voucher_list;
}

function enable_voucher(vouchers, id) {
    var voucher_list = vouchers.map((item) => {
        if (item.id == id) item.status = 1;
        return item;
    });
    return voucher_list
}

export interface State {
    vouchers: any[];
    sourceAccounts: any[];
    targetAccounts: any[];
    widgets: {
        notification: {
            title: string,
            data: Array<NotificationForWidget>
        }
    }
}

const initialState: State = {
    vouchers: [],
    sourceAccounts: [],
    targetAccounts: [],
    widgets: {
        notification: {
            title: "Notification",
            data: []
        }
    }
};

export function reducer(state = initialState, action: app.Actions): State {
    switch (action.type) {
        case app.ActionTypes.SET_VOUCHER_LIST:
            return Object.assign({}, state, { vouchers: action.payload });
        case app.ActionTypes.DISABLE_VOUCHER:
            return Object.assign({}, state, { vouchers: disable_voucher(state.vouchers, action.payload) });
        case app.ActionTypes.ENABLE_VOUCHER:
            return Object.assign({}, state, { vouchers: enable_voucher(state.vouchers, action.payload) });
        case app.ActionTypes.SET_SOURCE_ACCOUNTS:
            return Object.assign({}, state, { sourceAccounts: action.payload });
        case app.ActionTypes.SET_TARGET_ACCOUNTS:
            return Object.assign({}, state, { targetAccounts: action.payload });
        case app.ActionTypes.SET_HTML:
            return Object.assign({}, state, { setHTML: action.payload });
        case app.ActionTypes.SET_NOTIFICATION_FOR_WIDGET:
            return Object.assign({}, state, { widgets: { notification: { title: "Notification", data: action.payload } } });
        default:
            return state;
    }
}

export const get_vouchers = (state: State) => state.vouchers;
export const get_source_accounts = (state: State) => state.sourceAccounts;
export const get_target_accounts = (state: State) => state.targetAccounts;
export const get_notifications_for_widgets = (state: State) => state.widgets.notification.data;