import * as app from './action';
import * as R from 'ramda';

export class CurrentPersona {
    clientName: string;
    acadYearFrom: Date;
    acadYearTo: Date;
}

export class Kv {
    constructor(public key: string, public value: string) { }
}

export class ArtefactAction {
    constructor(public id: number, public displayText: string, public methodName: string) {
    }
}

export class Artefact {
    id: string;
    metaData: any;
    get metaKv(): Kv[] {
        var result = [];
        if (this.metaData) {
            for (var key in this.metaData) {
                if (this.metaData.hasOwnProperty(key)) { result.push(new Kv(key, this.metaData[key])); }
            }
        }
        return result;
    }
    fileName: string;
    url: string;
    fileSize: number;
    createdOn: Date;
    modifiedOn: Date;
    status: number;
    statusAsString: string;
    title: string;
    description: string;
    mimeType: string;
    actions: ArtefactAction[] = [];
    etlUrl: string;
    tags: any[] = [];
    tagList: any[] = [];
    get allowedActions(): ArtefactAction[] {
        let result = [];
        switch (this.status) {
            case 1:
                result.push(this.actions[1]);
                break;
            case 99:
                result.push(this.actions[8]);
                break;
            case 80:
                result.push(this.actions[9]);
                break;
            case 60:
                result.push(this.actions[10]);
                break;
            default:
                result = [];
        }
        switch (this.mimeType) {
            case 'text/st': //template
            case 'text/html':
            case 'application/json':
                if (isADashboardSpecJson(this.fileName)) {
                    if (this.status == 70) {
                        result.push(this.actions[2]) && result.push(this.actions[13]) && result.push(this.actions[15]) && result.push(this.actions[3]);
                    } else if (this.status != 99) {
                        result.push(this.actions[2]) && result.push(this.actions[13]) && result.push(this.actions[14]) && result.push(this.actions[3]);
                    } else {
                        result.push(this.actions[2]) && result.push(this.actions[13]) && result.push(this.actions[3]);
                    }
                } else {
                    result.push(this.actions[2]) && result.push(this.actions[3]);
                }
                break;
            case 'text/csv':
                result.push(this.actions[2]) && result.push(this.actions[9]) && result.push(this.actions[7]);
                break;
            case 'application/pdf':
            case 'image/gif':
            case 'image/jpeg':
            case 'image/jpg':
            case 'image/bmp':
            case 'image/png':
                result.push(this.actions[11]);
                break;
            case 'application/octet-stream':
                result.push(this.actions[2]) && result.push(this.actions[12]);
                break;
            case 'application/etl':
                result.push(this.actions[16]);
                break;
            default:
                result = [];
        }
        let index = R.findIndex(R.propEq('id', 2))(result);
        if (index > -1) {
            result = R.move(index, -1, result);
        }
        return result;
    }
    constructor() {
        this.actions.push(new ArtefactAction(1, "Edit", "ut_editArtefact"));
        this.actions.push(new ArtefactAction(2, "Disable", "ut_disableArtefact"));
        this.actions.push(new ArtefactAction(3, "Download", "ut_downloadArtefact"));
        this.actions.push(new ArtefactAction(4, "View", "ut_viewArtefact"));
        this.actions.push(new ArtefactAction(5, "With data", "ut_withData"));
        this.actions.push(new ArtefactAction(6, "Without data", "ut_withoutData"));
        this.actions.push(new ArtefactAction(7, "Work with data", "ut_workWithData"));
        this.actions.push(new ArtefactAction(8, "Edit / View", "ut_editViewData"));
        this.actions.push(new ArtefactAction(9, "Enable", "ut_enableArtefact"));
        this.actions.push(new ArtefactAction(10, "Generate Etl", "ut_generateEtl"));
        this.actions.push(new ArtefactAction(11, "Run Etl", "ut_runEtl"));
        this.actions.push(new ArtefactAction(12, "View / Download", "ut_viewArtefact"));
        this.actions.push(new ArtefactAction(13, "View", "ut_viewData"));
        this.actions.push(new ArtefactAction(14, "Preview", "ut_chartSpecPreview"));
        this.actions.push(new ArtefactAction(15, "Publish", "ut_publishArtefact"));
        this.actions.push(new ArtefactAction(16, "Unpublish", "ut_unpublishArtefact"));
        this.actions.push(new ArtefactAction(17, "View", "ut_viewEtls"));
    }
}

export class ArtefactContext {
    constructor(public selectedArtefactId: string, public selectedArtefactTitle: string) {
    }
}

export interface State {
    artefacts: Artefact[];
    artefact_context: ArtefactContext;
    selected_artefact: Artefact;
    url: any;
    exams: any;
    data: any;
    html: any;
    selectedEtl: string;
    etlFolderFiles: any;
}

const initialState: State = {
    artefacts: [],
    artefact_context: new ArtefactContext("", ""),
    selected_artefact: new Artefact(),
    url: null,
    exams: null,
    data: null,
    html: null,
    selectedEtl: null,
    etlFolderFiles: null
};

function setEtlUrl(artefact_list, arte_id, etl_url): Artefact[] {
    const artefactList: Artefact[] = artefact_list.map((item) => {
        if (item.id == arte_id) {
            item.etlUrl = etl_url;
            return item;
        }
        return item;
    });
    return artefactList;
}

function disableArtefact(artefact_list, arte_id) {
    const artefactList: Artefact[] = artefact_list.ArtefactList.map((item) => {
        if (item.id == arte_id) {
            item.status = 99;
            item.statusAsString = "Disabled";
            return item;
        }
        return item;
    });
    let obj = { ArtefactList: artefactList, TotalPages: artefact_list.TotalPages };
    return obj;
}

function enableArtefact(artefact_list, arte_id) {
    const artefactList: Artefact[] = artefact_list.ArtefactList.map((item) => {
        if (item.id == arte_id) {
            item.status = 1;
            item.statusAsString = "Active";
            return item;
        }
        return item;
    });
    let obj = { ArtefactList: artefactList, TotalPages: artefact_list.TotalPages };
    return obj;
}
function publishArtefact(artefact_list, arte_id) {
    const artefactList: Artefact[] = artefact_list.ArtefactList.map((item) => {
        if (item.id == arte_id) {
            item.status = 70;
            item.statusAsString = "Published";
            return item;
        }
        return item;
    });
    let obj = { ArtefactList: artefactList, TotalPages: artefact_list.TotalPages };
    return obj;
}
function unpublishArtefact(artefact_list, arte_id) {
    const artefactList: Artefact[] = artefact_list.ArtefactList.map((item) => {
        if (item.id == arte_id) {
            item.status = 1;
            item.statusAsString = "Unpublished";
            return item;
        }
        return item;
    });
    let obj = { ArtefactList: artefactList, TotalPages: artefact_list.TotalPages };
    return obj;
}

function selectArtefact(artefact_list, arte_id): Artefact {
    const artefact: Artefact = artefact_list.find(o => o.id == arte_id);
    return artefact;
}
function getArtefactArray(dataFromServer) {
    var result = [];
    var totalPages = dataFromServer.totalPages;
    if (dataFromServer) {
        dataFromServer['data'].forEach(d => {
            let artefact = new Artefact();
            artefact.id = d.id;
            artefact.metaData = d.metaData;
            artefact.fileName = d.fileName;
            artefact.url = d.url;
            artefact.fileSize = d.fileSize;
            artefact.createdOn = d.createdOn;
            artefact.modifiedOn = d.modifiedOn;
            artefact.status = d.status;
            artefact.title = d.title;
            artefact.description = d.description;
            artefact.tags = d.tags;
            artefact.tagList = d.tagList;
            artefact.mimeType = d.mimeType;
            artefact.statusAsString = d.statusAsString;
            result.push(artefact);
        });
    }
    var artefactObj = {
        "ArtefactList": result,
        "TotalPages": totalPages
    };
    return artefactObj;
}
function setExamStatus(exams) {
    exams.forEach(v => {
        v.statusAsString = v.status == '0' ? 'Unknown' : v.status == '1' ? 'Active' : v.status == '10' ? 'Scheduled' : v.status == '20' ? 'Conducted' : v.status == '90' ? 'Locked' : v.status == '99' ? 'Disabled' : '';
    });
    return exams;
}
function isADashboardSpecJson(fileName: any) {
    return fileName.endsWith(".json") && fileName.startsWith("specs");
}
export function reducer(state = initialState, action: app.Actions): State {
    switch (action.type) {
        case app.ActionTypes.SET_ARTEFACT:
            return Object.assign({}, state, { artefacts: getArtefactArray(action.payload.artefact) });
        case app.ActionTypes.SET_SELECTED_ARTEFACT:
            return Object.assign({}, state, { selected_artefact: action.payload.selected_artefact });
        case app.ActionTypes.DISABLE_ARTEFACT:
            return Object.assign({}, state, { artefacts: disableArtefact(state.artefacts, action.payload.artefact_id) });
        case app.ActionTypes.ENABLE_ARTEFACT:
            return Object.assign({}, state, { artefacts: enableArtefact(state.artefacts, action.payload.artefact_id) });
        case app.ActionTypes.PUBLISH_ARTEFACT:
            return Object.assign({}, state, { artefacts: publishArtefact(state.artefacts, action.payload.artefact_id) });
        case app.ActionTypes.UNPUBLISH_ARTEFACT:
            return Object.assign({}, state, { artefacts: unpublishArtefact(state.artefacts, action.payload.artefact_id) });
        case app.ActionTypes.SET_ARTEFACT_CONTEXT:
            return Object.assign({}, state, { artefact_context: action.payload });
        case app.ActionTypes.SET_URL:
            return Object.assign({}, state, { url: action.payload });
        case app.ActionTypes.SET_ARTEFACT_DATA:
            return Object.assign({}, state, { data: action.payload });
        case app.ActionTypes.SET_EXAMS:
            return Object.assign({}, state, { exams: setExamStatus(action.payload.exams) });
        case app.ActionTypes.SET_ETL_URL:
            return Object.assign({}, state, { artefacts: setEtlUrl(state.artefacts, action.payload.artefact_id, action.payload.etl_url) });
        case app.ActionTypes.SET_HTML:
            return Object.assign({}, state, { html: action.payload });
        case app.ActionTypes.SET_SELECTED_ETL:
            return Object.assign({}, state, { selectedEtl: action.payload.selectedEtl });
        case app.ActionTypes.SET_ETL_FOLDER_FILES:
            return Object.assign({}, state, { etlFolderFiles: action.payload.etlFiles });
        default:
            return state;
    }
}
export const get_artefacts = (state: State) => state.artefacts;
export const get_artefact_context = (state: State) => state.artefact_context;
export const get_selected_artefact = (state: State) => state.selected_artefact;
export const get_url = (state: State) => state.url;
export const get_exams_for_arte = (state: State) => state.exams;
export const get_artefact_data = (state: State) => state.data;
export const get_html_artefact = (state: State) => state.html;
export const get_selected_etl = (state: State) => state.selectedEtl;
export const get_etl_folder_files = (state: State) => state.etlFolderFiles;