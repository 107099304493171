import * as app from './action';

export interface State {
    instituteData: any;
    connectData: any;
    academicsData: any;
    studentEnrollmentData: any;
    calendarData: any;
    userAccessData: any;
}

const initialState: State = {
    instituteData: null,
    connectData: null,
    academicsData: null,
    studentEnrollmentData: null,
    calendarData: null,
    userAccessData: null
};


export function reducer(state = initialState, action: app.Actions): State {
    switch (action.type) {
        case app.ActionTypes.SET_INST_SETUP_WIDGET:
            return Object.assign({}, state, { instituteData: action.payload.instituteData });
        case app.ActionTypes.SET_CONNECT_SETUP_WIDGET:
            return Object.assign({}, state, { connectData: action.payload.connectData });
        case app.ActionTypes.SET_ACADEMICS_SETUP_WIDGET:
            return Object.assign({}, state, { academicsData: action.payload.academicsData });
        case app.ActionTypes.SET_STUDENT_ENROLLMENT_SETUP_WIDGET:
            return Object.assign({}, state, { studentEnrollmentData: action.payload.studentEnrollmentData });
        case app.ActionTypes.SET_CALENDAR_SETUP_WIDGET:
            return Object.assign({}, state, { calendarData: action.payload.calendarData });
            case app.ActionTypes.SET_USER_ACCESS_WIDGET:
            return Object.assign({}, state, { userAccessData: action.payload.userAccessData });    
        default:
            return state;
    }
}

export const get_state_pulse = (state: State) => state;
export const get_institute_setup_widget = (state: State) => state.instituteData;
export const get_connect_setup_widget = (state: State) => state.connectData;
export const get_academics_setup_widget = (state: State) => state.academicsData;
export const get_student_enrollment_setup_widget = (state: State) => state.studentEnrollmentData;
export const get_calendar_setup_widget = (state: State) => state.calendarData;
export const get_user_access_widget = (state: State) => state.userAccessData;