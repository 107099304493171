import { Component, OnInit } from '@angular/core';
import TouchEvent from './swipe.js';

declare var $: any;

class Model { }

@Component({
  templateUrl: './component.html',
  styleUrls: ['./component.css']
})
export class MobilePageComponent implements OnInit {
  constructor() { }
  m: Model;
  touchEvent: any;
  ngOnInit() {
    this.init_model();
    this.init_store();
  }
  init_model() {
    this.m = new Model();
  }

  init_store() {
    var el = document.getElementById("swipe");
    if (el) this.swipe(el);
  }

  swipe(el) {
    var self = this;
    el.addEventListener('touchstart', (event) => {
      self.touchEvent = new TouchEvent(event);
    });
    el.addEventListener('touchend', function () { handleSwipe(event, self.touchEvent) }, false);
  }
}
function handleSwipe(event, te) {
  if (!te) {
    return;
  }

  te.setEndEvent(event);
  if (te.isSwipeRight()) {
    nextCarousel();
  }
  else if (te.isSwipeLeft()) {
    previousCarousel();
  }

  // Reset event for next touch
  te = null;
}

function previousCarousel() {
  var currentIndex = $("#carouselExampleControls .carousel-inner .item.active").index() + 1;
  var totalItems = $("#carouselExampleControls .carousel-inner .item").length;
  var targetIndex = currentIndex + 1;
  if (targetIndex < 0) {
    targetIndex = 1;
  } else if (targetIndex > totalItems) {
    targetIndex = 1;
  }

  $('div#' + currentIndex + '.item.active').removeClass('active');
  $('div#' + targetIndex + '.item').addClass('active');
}

function nextCarousel() {
  var currentIndex = $("#carouselExampleControls .carousel-inner .item.active").index() + 1;
  var totalItems = $("#carouselExampleControls .carousel-inner .item").length;
  var targetIndex = currentIndex - 1;
  if (targetIndex == 0) {
    targetIndex = totalItems;
  }

  $('div#' + currentIndex + '.item.active').removeClass('active');
  $('div#' + targetIndex + '.item').addClass('active');
}