import * as userProfile from './action';

export interface State {
    userProfileDetails: any;
}

const initialState: State = {
    userProfileDetails: null
};

function get_userProfile_info(userDetails): any {
    var defaultDate = new Date('1947-08-15');
    userDetails.dob = userDetails.dob == null ? defaultDate : userDetails.dob;
    // userDetails.name = userDetails.firstName + '' + userDetails.middleName + ' ' + userDetails.lastName;
    userDetails.gender = userDetails.gender == 0 ? 'Female' : userDetails.gender == 1 ? 'Male' : 'No Info';
    userDetails.genderPrefix = userDetails.gender == 'Female' ? 'Ms' : userDetails.gender == 'Male' ? 'Mr' : 'No Info';
    return userDetails;
}

export function reducer(state = initialState, action: userProfile.Actions): State {
    switch (action.type) {
        case userProfile.ActionTypes.SET_USER_DETAILS:
            return Object.assign({}, state, { userProfileDetails: get_userProfile_info(action.payload.user_details) });
        default:
            return state;
    }
}

export const get_state_userProfile = (state: State) => state;
export const get_user_profile_details = (state: State) => state.userProfileDetails;