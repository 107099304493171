import { Injectable } from '@angular/core';

@Injectable()
export class DateService {
  constructor() { }

  getToday(dt,acadFromDate,acadToDate) {
    var fromDt = this.formatDate(dt);
    var toDt = fromDt;
    return {from:fromDt, to:toDt};
  }
  getPriorDay(dt,acadFromDate,acadToDate) {
    var priorDate = new Date();
    priorDate.setDate(dt.getDate() - 1)
    var fromDt = this.formatDate(priorDate);
    var toDt = fromDt;
    return {from:fromDt, to:toDt};
  }
  getCurrentWeek(dt,acadFromDate,acadToDate) {
    var lastday = new Date(dt);
    var first = dt.getDate() - dt.getDay()+1; 
    var firstday = new Date(dt.setDate(first));
    var fromDt = this.formatDate(firstday);
    var toDt = this.formatDate(lastday);
    return {from:fromDt, to:toDt};
  }
  getPriorWeek(dt,acadFromDate,acadToDate) {
    var lastday = dt.setTime(dt.getTime() - (dt.getDay() ? dt.getDay() : 7) * 24 * 60 * 60 * 1000);
    lastday = new Date(lastday);
    var firstday = dt.setTime(dt.getTime() - 6 * 24 * 60 * 60 * 1000);
    firstday = new Date(firstday);
    var fromDt = this.formatDate(firstday);
    var toDt = this.formatDate(lastday);
    return {from:fromDt, to:toDt};
  }
  getCurrentMonth(dt,acadFromDate,acadToDate) {
    var firstday = new Date(dt.getFullYear(), dt.getMonth(), 1);
    var lastday = dt;
    var fromDt = this.formatDate(firstday);
    var toDt = this.formatDate(lastday);
    return {from:fromDt, to:toDt};
  }
  getPriorMonth(dt,acadFromDate,acadToDate) {
    var firstday = new Date(dt.getFullYear(), dt.getMonth()-1, 1);
    var lastday = new Date(dt.getFullYear(), dt.getMonth(), 0);;
    var fromDt = this.formatDate(firstday);
    var toDt = this.formatDate(lastday);
    return {from:fromDt, to:toDt};
  }
  getCurrentQuarter(dt,acadFromDate,acadToDate) {
    var quarter = Math.floor((dt.getMonth() / 3));
    var firstday = new Date(dt.getFullYear(), quarter * 3, 1);
    var lastday = dt;
    var fromDt = this.formatDate(firstday);
    var toDt = this.formatDate(lastday);
    return {from:fromDt, to:toDt};
  }
  getPriorQuarter(dt,acadFromDate,acadToDate) {
    var quarter = Math.floor((dt.getMonth() / 3));
    var firstday = new Date(dt.getFullYear(), quarter * 3 - 3, 1);
    var lastday = new Date(firstday.getFullYear(), firstday.getMonth() + 3, 0);
    var fromDt = this.formatDate(firstday);
    var toDt = this.formatDate(lastday);
    return {from:fromDt, to:toDt};
  }
  getCurrentYear(dt,acadFromDate,acadToDate) {
    var firstday = new Date(dt.getFullYear(), 0, 1);
    var lastday = dt;
    var fromDt = this.formatDate(firstday);
    var toDt = this.formatDate(lastday);
    return {from:fromDt, to:toDt};
  }
  formatDate(date){
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    return [year, month, day].join('-');
  }
}
