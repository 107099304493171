import { Action } from '@ngrx/store';
import { type } from '../../util';

export const ActionTypes = {
    CREATE_TIMETABLE: type('[Timetable] Create Timetable'),
    SET_TIMETABLE: type('[Timetable] Set Timetable'),
};

export class CreateTimetable implements Action {
    type = ActionTypes.CREATE_TIMETABLE;
    constructor(public payload: any) { }
}
export class SetTimetable implements Action {
    type = ActionTypes.SET_TIMETABLE;
    constructor(public payload: any) { }
}

export type Actions = CreateTimetable | SetTimetable;