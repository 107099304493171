export const DEX_FILTER_OPTIONS = ["Filter", "Group", "Sort", "Select", "Limit", "Offset"];
export const ICONS = {
    "Filter": "fa fa-filter",
    "Group": "fa fa-object-group",
    "Sort": "fa fa-sort",
    "Select": "fa fa-columns",
    "Limit": "fa fa-list-ol",
    "Offset": "fa fa-arrows-v"
}
export const DEX_FILTERING_INFO = `<i class="custom-icons ${ICONS.Filter}"></i> Filter:\n &nbsp; status=Active\n`
    + ` &nbsp; ---\n &nbsp; section=A AND course=PUC I\n`
    + `<i class="custom-icons ${ICONS.Group}"></i> Group:\n &nbsp; course, section\n`
    + `<i class="custom-icons ${ICONS.Sort}"></i> Sort:\n &nbsp; course:asc, section:desc\n`
    + ` &nbsp; ---\n &nbsp; course, section:desc\n`
    + `<i class="custom-icons ${ICONS.Select}"></i> Select:\n &nbsp; name, course, section\n`
    + ` &nbsp; ---\n &nbsp; first_name AS name, course\n`
    + ` &nbsp; ---\n &nbsp; first_name name, course\n`
    + `<i class="custom-icons ${ICONS.Limit}"></i> Limit:\n &nbsp; 20\n`
    + `<i class="custom-icons ${ICONS.Offset}"></i> Offset:\n &nbsp; 0\n`;

export const EMPTY_DEX_QUERY = {
    "from": "",
    "group_by": [],
    "order_by": [],
    "page": {
        "limit": 20,
        "offset": 0
    },
    "search": "",
    "select": [],
    "where": {
        "conditions": [],
        "operator": "AND"
    },
    qe: []
}
export const DEX_CONTEXTS = {
    "admissions/applications/manage": {
        tab: "admissions", component: "applications", label: "Applications", param: "applications"
    },
    "fees/feesDefaultersListReport": {
        tab: "Fees", component: "fee reports", label: "Fee Defaulters", param: "fee-defaulters"
    },
    "admin/student/manage": {
        tab: "admin", component: "students", label: "Students", param: "students"
    },
    "user-activities": {
        tab: "user-activities", component: "user activities", label: "User Activities", param: "user-activities"
    },
}
export const DEX_ENABLED_PATHS = Object.keys(DEX_CONTEXTS);