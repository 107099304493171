import { Action } from '@ngrx/store';
import { type } from '../../../app/util';

export const ActionTypes = {
    SET_SELECTED_USER_LINK: type('[Receipts] Set Selected User Link'),
    SET_USER_LINKS: type('[Receipts]] Set User Links'),
    UPDATE_DASHBOARD: type('[Receipts] Update Dashboard'),
    SET_HTML: type('[Receipts] Set Html'),
    SET_RECEIPTS: type('[Receipts] Set Receipts'),
    SET_SELECTED_RECEIPT: type('[Receipts] Set Selected Receipt')
};

export class SetSelectedUserLink implements Action {
    type = ActionTypes.SET_SELECTED_USER_LINK;
    /**"Id": "" */
    constructor(public payload: any) { }
}

export class SetUserLinks implements Action {
    type = ActionTypes.SET_USER_LINKS;
    constructor(public payload: any) { }
}

export class UpdateDashboard implements Action {
    type = ActionTypes.UPDATE_DASHBOARD;
    constructor(public payload: any) { }
}

export class SetHtml implements Action {
    type = ActionTypes.SET_HTML;
    constructor(public payload: any) { }
}

export class SetReceipts implements Action {
    type = ActionTypes.SET_RECEIPTS;
    constructor(public payload: any) { }
}

export class SetSelectedReceipt implements Action {
    type = ActionTypes.SET_SELECTED_RECEIPT;
    constructor(public payload: any) { }
}

export type Actions
    = SetSelectedUserLink | SetUserLinks | UpdateDashboard | SetHtml | SetReceipts | SetSelectedReceipt;