import { Action } from '@ngrx/store';
import { type } from '../../../app/util';

export const ActionTypes = {
    SET_SELECTED_USER_LINK: type('[Connect] Set Selected User Link'),
    SET_USER_LINKS: type('[Connect]] Set User Links'),
    UPDATE_DASHBOARD: type('[Connect] Update Dashboard'),
    SET_HTML: type('[Connect] Set Html'),
    SET_SMS: type('[Connect] Set SMS'),
    SET_STUDENT_FACET: type('[Connect] Set Student Facet'),
    SET_STUDENT_FACET_EMAIL: type('[Connect] Set Student Facet Email'),
    SET_APPROVED_TEMPLATES: type('[Connect] Set Approved Templates'),
    SET_SMS_RECIPIENTS: type('[Connect] Set SMS Recipients'),
    SET_PARENT_RECIPIENTS: type('[Connect] Set Parent Recipients'),
    SET_STAFF_FACET: type('[Connect] Set Staff Facet'),
    SET_EMAIL_RECIPIENTS: type('[Connect] Set Email Recipients'),
    SET_EMAIL_MANUAL_RECIPIENTS: type('[Connect] Set Email Manual Recipients'),
    SET_EMAIL_TYPE: type('[Connect] Set Email Type'),
    SET_EMAIL_FROM: type('[Connect] Set Email From'),
    SET_EMAIL_SUBJECT: type('[Connect] Set Email Subject'),
    SET_EMAIL_SMS: type('[Connect] Set Email SMS'),
    SET_EMAIL_BODY: type('[Connect] Set Email Body'),
    SET_EMAIL_TO: type('[Connect] Set Email To'),
    SET_EMAIL_ATTACHMENT: type('[Connect] Set Email Attachment'),
    SET_SMS_TOTAL_PAGES: type('[Connect] Set SMS Total Pages'),
    SET_WORK_FLOW: type('[Connect] Set Work Flow'),
    DISABLE_SMS_TEMPLATE: type('[Connect] Disable SMS Template'),
    ENABLE_SMS_TEMPLATE: type('[Connect] Enable SMS Template'),
    APPROVE_SMS_TEMPLATE: type('[Connect] Approve SMS Template'),
    DISAPPROVE_SMS_TEMPLATE: type('[Connect] Disapprove SMS Template'),
    SET_SMS_TEMPLATES: type('[Connect] Set SMS Tepmlates'),
    SET_SMS_DELIVERY_STATUS: type('[Connect] Set Sms Delivery Status'),
    SET_SMS_DELIVERY_DATA: type('[Connect] Set Sms Delivery Data'),
    SET_EMAIL_DELIVERY_DATA: type('[Connect] Set Email Delivery Data'),
    SET_EMAIL: type('[Connect] Set Email'),
    SET_EMAIL_TOTAL_PAGES: type('[Connect] Set Email Total Pages'),
    SET_SELECTED_EMAIL: type('[Connect] Set Selected Email'),
    SET_SMS_DLR_CHART_DATA: type('[Connect] Set SMS Dlr Chart Data'),
    SET_SMS_BALANCE: type('[Connect] Set Sms Balance'),
    SET_EMAIL_BALANCE: type('[Connect] Set Email Balance'),
    SELECTED_MONTH_YEAR: type('[Connect] Selected Month Year'),
    SET_CONNECT_INFO: type('[Connect] Set Connect Info')
    // SET_TOPICS: type('[Connect] Set Topics List'),
    // DISABLE_TOPIC: type('[Connect] Disable Topic'),
    // ENABLE_TOPIC: type('[Connect] Enable Topic')
};

export class SetSelectedUserLink implements Action {
    type = ActionTypes.SET_SELECTED_USER_LINK;
    /**"Id": "" */
    constructor(public payload: any) { }
}

export class SetUserLinks implements Action {
    type = ActionTypes.SET_USER_LINKS;
    constructor(public payload: any) { }
}

export class UpdateDashboard implements Action {
    type = ActionTypes.UPDATE_DASHBOARD;
    constructor(public payload: any) { }
}

export class SetHtml implements Action {
    type = ActionTypes.SET_HTML;
    constructor(public payload: any) { }
}
export class SetSMS implements Action {
    type = ActionTypes.SET_SMS;
    constructor(public payload: any) { }
}
export class SetEmail implements Action {
    type = ActionTypes.SET_EMAIL;
    constructor(public payload: any) { }
}

export class SetSmsDeliveryStatus implements Action {
    type = ActionTypes.SET_SMS_DELIVERY_STATUS;
    constructor(public payload: any = null) {
    }
}

export class SetSmsDeliveryData implements Action {
    type = ActionTypes.SET_SMS_DELIVERY_DATA;
    constructor(public payload: any = null) {
    }
}

export class SetSmsDlrChartData implements Action {
    type = ActionTypes.SET_SMS_DLR_CHART_DATA;
    constructor(public payload: any = null) {
    }
}

export class SetEmailDeliveryData implements Action {
    type = ActionTypes.SET_EMAIL_DELIVERY_DATA;
    constructor(public payload: any = null) {
    }
}

export class SetStudentFacet implements Action {
    type = ActionTypes.SET_STUDENT_FACET;
    constructor(public payload: any) { }
}

export class SetStudentFacetEmail implements Action {
    type = ActionTypes.SET_STUDENT_FACET_EMAIL;
    constructor(public payload: any) { }
}

export class SetApprovedTemplates implements Action {
    type = ActionTypes.SET_APPROVED_TEMPLATES;
    constructor(public payload: any) { }
}
export class SetSMSRecipients implements Action {
    type = ActionTypes.SET_SMS_RECIPIENTS;
    constructor(public payload: any) { }
}
export class SetParentRecipients implements Action {
    type = ActionTypes.SET_PARENT_RECIPIENTS;
    constructor(public payload: any) { }
}
export class SetStaffFacet implements Action {
    type = ActionTypes.SET_STAFF_FACET;
    constructor(public payload: any) { }
}
export class SetEmailReceiptients implements Action {
    type = ActionTypes.SET_EMAIL_RECIPIENTS;
    constructor(public payload: any) { }
}
export class SetEmailManualReceiptients implements Action {
    type = ActionTypes.SET_EMAIL_MANUAL_RECIPIENTS;
    constructor(public payload: any) { }
}
export class SetEmailType implements Action {
    type = ActionTypes.SET_EMAIL_TYPE;
    constructor(public payload: any) { }
}
export class SetEmailFrom implements Action {
    type = ActionTypes.SET_EMAIL_FROM;
    constructor(public payload: any) { }
}
export class SetEmailSubject implements Action {
    type = ActionTypes.SET_EMAIL_SUBJECT;
    constructor(public payload: any) { }
}
export class SetEmailSMS implements Action {
    type = ActionTypes.SET_EMAIL_SMS;
    constructor(public payload: any) { }
}
export class SetEmailBody implements Action {
    type = ActionTypes.SET_EMAIL_BODY;
    constructor(public payload: any) { }
}
export class SetEmailTo implements Action {
    type = ActionTypes.SET_EMAIL_TO;
    constructor(public payload: any) { }
}
export class SetEmailAttachment implements Action {
    type = ActionTypes.SET_EMAIL_ATTACHMENT;
    constructor(public payload: any) { }
}
export class SetSMSTotalPages implements Action {
    type = ActionTypes.SET_SMS_TOTAL_PAGES;
    constructor(public payload: any) { }
}
export class SetEmailTotalPages implements Action {
    type = ActionTypes.SET_EMAIL_TOTAL_PAGES;
    constructor(public payload: any) { }
}
export class SetWorkflow implements Action {
    type = ActionTypes.SET_WORK_FLOW;
    constructor(public payload: any) { }
}

export class DisableSMSTemplate implements Action {
    type = ActionTypes.DISABLE_SMS_TEMPLATE
    constructor(public payload: any) { }
}

export class EnableSMSTemplate implements Action {
    type = ActionTypes.ENABLE_SMS_TEMPLATE;
    constructor(public payload: any) { }
}

export class ApproveSMSTemplate implements Action {
    type = ActionTypes.APPROVE_SMS_TEMPLATE;
    constructor(public payload: any) { }
}

export class DisapproveSMSTemplate implements Action {
    type = ActionTypes.DISAPPROVE_SMS_TEMPLATE;
    constructor(public payload: any) { }
}

export class SetSMSTemplates implements Action {
    type = ActionTypes.SET_SMS_TEMPLATES;
    constructor(public payload: any) { }
}

export class SetSelectedEmail implements Action {
    type = ActionTypes.SET_SELECTED_EMAIL;
    constructor(public payload: any) { }
}

export class SetSmsBalance implements Action {
    type = ActionTypes.SET_SMS_BALANCE;
    constructor(public payload: any = null) {
    }
}
export class SetEmailBalance implements Action {
    type = ActionTypes.SET_EMAIL_BALANCE;
    constructor(public payload: any = null) {
    }
}
export class SelectedMonthYear implements Action {
    type = ActionTypes.SELECTED_MONTH_YEAR;
    constructor(public payload: any = null) {
    }
}

export class SetConnectInfo implements Action {
    type = ActionTypes.SET_CONNECT_INFO;
    constructor(public payload: any = null) { }
}

// export class SetTopics implements Action {
//     type = ActionTypes.SET_TOPICS;
//     constructor(public payload: any = null) { }
// }

// export class DisableTopic implements Action {
//     type = ActionTypes.DISABLE_TOPIC;
//     constructor(public payload: any) { }
// }

// export class EnableTopic implements Action {
//     type = ActionTypes.ENABLE_TOPIC;
//     constructor(public payload: any) { }
// }

export type Actions
    = SetSelectedUserLink | SetUserLinks | UpdateDashboard | SetHtml | SetSMS | SetStudentFacet | SetApprovedTemplates | SetSMSRecipients | SetParentRecipients | SetStaffFacet | SetEmailReceiptients | SetEmailType | SetEmailFrom | SetEmailSubject | SetEmailBody | SetEmailTo | SetEmailAttachment | SetSMSTotalPages | SetWorkflow | DisableSMSTemplate | EnableSMSTemplate | ApproveSMSTemplate | DisapproveSMSTemplate | SetSMSTemplates | SetSmsDeliveryStatus | SetSmsDeliveryData | SetEmail | SetEmailDeliveryData | SetEmailManualReceiptients | SetEmailTotalPages | SetEmailSMS | SetSelectedEmail | SetSmsBalance | SetEmailBalance | SetSmsDlrChartData | SelectedMonthYear | SetConnectInfo | SetStudentFacetEmail;
