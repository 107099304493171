import { Action } from '@ngrx/store';
import { type } from '../../../app/util';

export const ActionTypes = {
    SET_COURSES: type('[Admissions] Set Courses'),
    SET_SEAT_MATRIX: type('[Admissions] Set Seat Matrix'),
    SET_SELECTED_SEAT_MATRIX: type('[Admissions] Set Selected Seat Matrix'),
    SET_APPLICANT_DETAILS: type('[Admissions] Set Applicant Details'),
    SET_APPLICANTS: type('[Admissions] Set Applicants'),
    SET_APPLICANTS_NOT_ADMITTED: type('[Admissions] Set Applicants Not Admitted'),
    SET_SELECTED_APPLICATION: type('[Admissions] Set Selected Application'),
    SET_COURSEWISE_APPLICATIONS: type('[Admissions] Set Coursewise Applications'),
    SEATS_AVAILABLE: type('[Admissions] Seats Available'),
    SET_ADMISSIONS: type('[Admissions] Set Admissions'),
    SET_SELECTED_SHORTLIST: type('[Admissions] Set Selected Shortlist'),
    SET_ADMITTED_STUDENTS: type('[Admissions] Set Admitted Students'),
    SET_SHORTLIST_EXPAND_COLLAPSE: type('[Admissions] Set Shortlist ExpandCollapse'),
    SHORTLISTS: type('[Admissions] Shortlists'),
    SET_APPLICATION_FORM_TEMPLATES: type('[Admissions] Set Application Form Templates'),
    DISABLE_APPLICATION_FORM_TEMPLATE: type('[Admissions] Disable Application Form Template'),
    ENABLE_APPLICATION_FORM_TEMPLATE: type('[Admissions] Enable Application Form Template'),
    PUBLISH_APPLICATION_FORM_TEMPLATE: type('[Admissions] Publish Application Form Template'),
    UNPUBLISH_APPLICATION_FORM_TEMPLATE: type('[Admissions] Unpublish Application Form Template'),
    SET_SELECTED_APPLICATION_FORM_TEMPLATE: type('[Admissions] Set Selected Application Form Template'),
    SET_SELECTED_APPLICANT: type('[Admissions] Set Selected Applicant'),
    SET_FORM_TEMPLATE_PREVIEW: type('[Admissions] Set Form Template Preview'),
    SET_SUBMITTED_APPLICATIONS: type('[Admissions] Set Submitted Applications'),
    SET_TOTAL_PAGES: type('[Admissions] Set Total Pages'),
    SET_AADHAR_URL: type('[Admissions] Set Aadhar Url')
};

export class SetSubmittedApplications implements Action {
    type = ActionTypes.SET_SUBMITTED_APPLICATIONS;
    constructor(public payload: any) {}
}

export class SetTotalPages implements Action {
    type = ActionTypes.SET_TOTAL_PAGES;
    constructor(public payload: any) {}
}

export class SetFormTemplatePreview implements Action {
    type = ActionTypes.SET_FORM_TEMPLATE_PREVIEW;
    constructor(public payload: any) {
    }
}

export class SetApplicants implements Action {
    type = ActionTypes.SET_APPLICANTS;
    constructor(public payload: any) {
    }
}

export class SetApplicantsNotAdmitted implements Action {
    type = ActionTypes.SET_APPLICANTS_NOT_ADMITTED;
    constructor(public payload: any) {
    }
}

export class SetCourses implements Action {
    type = ActionTypes.SET_COURSES;
    constructor(public payload: any) {
    }
}

export class SetSeatMatrix implements Action {
    type = ActionTypes.SET_SEAT_MATRIX;
    constructor(public payload: any) {
    }
}

export class SetSelectedSeatMatrix implements Action {
    type = ActionTypes.SET_SELECTED_SEAT_MATRIX;
    constructor(public payload: any) {
    }
}

export class SetApplicantDetails implements Action {
    type = ActionTypes.SET_APPLICANT_DETAILS;
    constructor(public payload: any) {
    }
}

export class SetSelectedApplication implements Action {
    type = ActionTypes.SET_SELECTED_APPLICATION;
    constructor(public payload: any) {
    }
}

export class Shortlists implements Action {
    type = ActionTypes.SHORTLISTS;
    constructor(public payload: any) {}
}

export class SetCoursewiseApplications implements Action {
    type = ActionTypes.SET_COURSEWISE_APPLICATIONS;
    constructor(public payload: any) {}
}

export class SeatsAvailable implements Action {
    type = ActionTypes.SEATS_AVAILABLE;
    constructor(public payload: any) {}
}

export class SetAdmissions implements Action {
    type = ActionTypes.SET_ADMISSIONS;
    constructor(public payload: any) {}
}

export class SetSelectedShortlist implements Action {
    type = ActionTypes.SET_SELECTED_SHORTLIST;
    constructor(public payload: any) {}
}

export class SetAdmittedStudents implements Action {
    type = ActionTypes.SET_ADMITTED_STUDENTS;
    constructor(public payload: any) {}
}

export class SetExpandCollapse implements Action {
    type = ActionTypes.SET_SHORTLIST_EXPAND_COLLAPSE;
    constructor(public payload: any) {}
}

export class SetApplicationFormTemplates implements Action {    
    type = ActionTypes.SET_APPLICATION_FORM_TEMPLATES;
    constructor(public payload: any) {}
}

export class DisableApplicationFormTemplate implements Action {
    type = ActionTypes.DISABLE_APPLICATION_FORM_TEMPLATE;
    constructor(public payload: any) {}
}

export class EnableApplicationFormTemplate implements Action {
    type = ActionTypes.ENABLE_APPLICATION_FORM_TEMPLATE;
    constructor(public payload: any) {}
}

export class PublishApplicationFormTemplate implements Action {
    type = ActionTypes.PUBLISH_APPLICATION_FORM_TEMPLATE;
    constructor(public payload: any) {}
}

export class UnpublishApplicationFormTemplate implements Action {
    type = ActionTypes.UNPUBLISH_APPLICATION_FORM_TEMPLATE;
    constructor(public payload: any) {}
}

export class SetSelectedApplicationFormTemplate implements Action {
    type = ActionTypes.SET_SELECTED_APPLICATION_FORM_TEMPLATE;
    constructor(public payload: any) {}
}

export class SetSelectedApplicant implements Action {
    type = ActionTypes.SET_SELECTED_APPLICANT;
    constructor(public payload: any) {}
}

export class SetAadharUrl implements Action {
    type = ActionTypes.SET_AADHAR_URL;
    constructor(public payload: any) {}
}

export type Actions=
            SetCourses | SetSeatMatrix | SetApplicantDetails | SetApplicants | SetSelectedApplication | SetSelectedSeatMatrix | SeatsAvailable | Shortlists | SetCoursewiseApplications | SetAdmissions | SetApplicantsNotAdmitted | SetSelectedShortlist | SetAdmittedStudents | SetExpandCollapse | SetApplicationFormTemplates | DisableApplicationFormTemplate | EnableApplicationFormTemplate | PublishApplicationFormTemplate | UnpublishApplicationFormTemplate | SetSelectedApplicationFormTemplate | SetSelectedApplicant | SetFormTemplatePreview
        | SetSubmittedApplications | SetTotalPages | SetAadharUrl;