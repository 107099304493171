import { Injectable } from '@angular/core';
import { CTApi } from './ct-api';
import { Store } from '@ngrx/store';
import * as rootReducer from '../rootReducer';
import * as appAction from '../store/app-action';
import * as personaAction from '../persona/store/action';

@Injectable()
export class ClipboardService {
  constructor(public ctapi: CTApi, private store: Store<rootReducer.State>) { }

  copy(item) {
    this.store.dispatch(new appAction.CopyItem(item));
  }
  remove(item) {
    this.store.dispatch(new appAction.Remove(item.id));
  }
  select(item) {
    this.store.dispatch(new appAction.Select(item));
  }
  unselect(item){
    this.store.dispatch(new appAction.Unselect(item.id));
  }
}
