import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Store } from '@ngrx/store';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import { Observable } from 'rxjs/Observable';
import 'rxjs/Rx';
import { environment } from '../../environments/environment';
import * as rootReducer from '../rootReducer';

const kes_client_id = "74781000-2122-4000-93c5-877b8c3892dd";

export const topics = [
    "email.send",
    "exam.evaluate",
    "institute.create",
    "institute.update",
    "persona.create",
    "sms.absentee",
    "sms.delivery",
    "sms.send",
    "user.dashboard",
    "user.signin"
]

@Injectable()
export class CTApi {
    constructor(
        public httpClient: HttpClient,
        public http: Http,
        private store: Store<rootReducer.State>
    ) { }
    getProtocols() {
        return this.http.get('assets/dummy/protocols.json');
    }

    updateAllTags(data) {
        return this.http.post('/updateAllTags', data);
    }
    createTag(tag) {
        let payload = {
            "Name": tag.name,
            "Predefined": tag.predefined,
            "Meta": tag.meta,
        }
        const req = { url: `${environment.API_URL}/acad/lms/tag` };
        return this.httpClient.post(req.url, payload);
    }

    enableTag(tagId) {
        const req = { url: `${environment.API_URL}/acad/lms/enableTag?id=` + tagId };
        return this.httpClient.post(req.url, null);
    }
    disableTag(tagId) {
        const req = { url: `${environment.API_URL}/acad/lms/disableTag?id=` + tagId };
        return this.httpClient.post(req.url, null);
    }
    enableContentTag(tagId) {
        const req = { url: `${environment.API_URL}/arte/artefact/enableContentTag?id=` + tagId };
        return this.httpClient.post(req.url, null);
    }
    disableContentTag(tagId) {
        const req = { url: `${environment.API_URL}/arte/artefact/disableContentTag?id=` + tagId };
        return this.httpClient.post(req.url, null);
    }
    enableImgTag(tagId) {
        const req = { url: `${environment.API_URL}/arte/artefact/enableTag?id=` + tagId };
        return this.httpClient.post(req.url, null);
    }
    disableImgTag(tagId) {
        const req = { url: `${environment.API_URL}/arte/artefact/disableTag?id=` + tagId };
        return this.httpClient.post(req.url, null);
    }
    archiveQ(qId) {
        const req = { url: `${environment.API_URL}/acad/lms/archive?id=` + qId };
        return this.httpClient.post(req.url, null);
    }
    unarchiveQ(qId) {
        const req = { url: `${environment.API_URL}/acad/lms/unarchive?id=` + qId };
        return this.httpClient.post(req.url, null);
    }
    updateTag(tagObj) {
        let payload = tagObj;
        const req = { url: `${environment.API_URL}/acad/lms/updateTag` };
        return this.httpClient.post(req.url, payload);
    }
    updateImgTag(tagObj) {
        let payload = tagObj;
        const req = { url: `${environment.API_URL}/arte/artefact/updateTag` };
        return this.httpClient.post(req.url, payload);
    }
    updateQTags(qArr) {
        let payload = qArr;
        const req = { url: `${environment.API_URL}/acad/lms/updateQuestionsTags` };
        return this.httpClient.post(req.url, payload);
    }
    updateArtefactTags(payload) {
        const req = { url: `${environment.API_URL}/arte/artefact/updateTags` };
        return this.httpClient.post(req.url, payload);
    }
    getAllTags() {
        // return this.http.get('/assets/dummy/questions.json');
        const req = { url: `${environment.API_URL}/acad/lms/tags` };
        // return this.http.get('/assets/dummy/questions.json');
        return this.httpClient.get(req.url);
    }

    createContentTag(tag) {
        let payload = {
            "Name": tag.name,
            "Predefined": tag.predefined,
            "Meta": tag.meta,
        }
        const req = { url: `${environment.API_URL}/arte/artefact/contentTag` };
        return this.httpClient.post(req.url, payload);
    }

    getAllContentTags() {
        const req = { url: `${environment.API_URL}/arte/lms/tags` };
        return this.httpClient.get(req.url);
    }

    getQuestions(queryString) {
        const req = { url: `${environment.API_URL}/acad/lms/questions` + (queryString.payload || "") };
        return this.httpClient.get(req.url);
    }
    saveQuestion(data) {
        let payload = data.payload;
        const req = { url: `${environment.API_URL}/acad/lms/updateQuestion` };
        return this.httpClient.post(req.url, payload);

    }
    publishQuestions(data) {
        const req = { url: `${environment.API_URL}/acad/lms/publish` };
        return this.httpClient.post(req.url, data.payload);
    }
    unpublishQuestions(data) {
        const req = { url: `${environment.API_URL}/acad/lms/unpublish` };
        return this.httpClient.post(req.url, data.payload);
    }
    addQuestion(data) {
        let payload = { QuestionList: data.payload };
        const req = { url: `${environment.API_URL}/acad/lms/question` };
        return this.httpClient.post(req.url, payload);
    }

    // Assessment
    cloneQuestion(data) {
        let payload = data;
        const req = { url: `${environment.API_URL}/acad/lms/remodelQuestion` };
        return this.httpClient.post(req.url, payload);
    }

    createAssessmentTag(tag) {
        let payload = {
            "Name": tag.name,
            "Predefined": tag.predefined,
            "Meta": tag.meta,
        }
        const req = { url: `${environment.API_URL}/acad/assessment/tag` };
        return this.httpClient.post(req.url, payload);
    }

    getAllAssessmentTags() {
        const req = { url: `${environment.API_URL}/acad/assessment/tags` };
        return this.httpClient.get(req.url);
    }

    enableAssessmentTag(tagId) {
        const req = { url: `${environment.API_URL}/acad/assessment/enableTag?id=` + tagId };
        return this.httpClient.post(req.url, null);
    }

    disableAssessmentTag(tagId) {
        const req = { url: `${environment.API_URL}/acad/assessment/disableTag?id=` + tagId };
        return this.httpClient.post(req.url, null);
    }

    updateAssessmentTag(tagObj) {
        let payload = tagObj;
        const req = { url: `${environment.API_URL}/acad/assessment/updateTag` };
        return this.httpClient.post(req.url, payload);
    }

    getPublishedQuestions(queryString) {
        // queryString.payload  += 'is:published';
        const req = { url: `${environment.API_URL}/acad/lms/questions` + (queryString.payload || "") };
        return this.httpClient.get(req.url);
    }

    saveAssessment(data) {
        let payload = data.payload;
        const req = { url: `${environment.API_URL}/acad/assessment` };
        return this.httpClient.post(req.url, payload);
    }

    getAssessment(id) {
        const req = { url: `${environment.API_URL}/acad/report/assessment?id=` + id };
        return this.httpClient.post(req.url, null, { observe: 'response', responseType: 'text' });
    }

    updateAssessment(assessment) {
        let payload = assessment;
        const req = { url: `${environment.API_URL}/acad/assessment/update` };
        return this.httpClient.post(req.url, payload);
    }

    refreshAssessment(assessmentId) {
        let payload = { AssessmentId: assessmentId, QuestionIds: [] };
        const req = { url: `${environment.API_URL}/acad/assessment/refresh` };
        return this.httpClient.post(req.url, payload);
    }

    getAssessments(orderBy, sortOrder, qStr) {
        var req;
        var orderByStr = `$orderby=` + orderBy + '&$sortorder=' + sortOrder;
        var searchStr = `?$search=`;
        var urlStr = `${environment.API_URL}/acad/assessment`;
        if (qStr) {
            urlStr = urlStr + qStr + '&' + orderByStr;
            req = { url: urlStr };
        }
        else {
            req = { url: urlStr + searchStr + '&' + orderByStr };
        }
        return this.httpClient.get(req.url);
    }

    getPublishedAssessments(qStr) {
        var req;
        var urlStr = `${environment.API_URL}/acad/assessment/publishedAssessments`;
        var searchStr = `?$search=`;
        if (qStr) {
            urlStr = urlStr + qStr;
            req = { url: urlStr };
        }
        else {
            req = { url: urlStr + searchStr };
        }
        return this.httpClient.get(req.url);
    }

    publishAssessment(id) {
        const req = { url: `${environment.API_URL}/acad/assessment/publish?id=` + id };
        return this.httpClient
            .post(req.url, null, { observe: 'response', responseType: 'text' });
    }

    unpublishAssessment(id) {
        const req = { url: `${environment.API_URL}/acad/assessment/unpublish?id=` + id };
        return this.httpClient
            .post(req.url, null, { observe: 'response', responseType: 'text' });
    }

    archiveAssessment(id) {
        const req = { url: `${environment.API_URL}/acad/assessment/archive?id=` + id };
        return this.httpClient
            .post(req.url, null, { observe: 'response', responseType: 'text' });
    }

    unarchiveAssessment(id) {
        const req = { url: `${environment.API_URL}/acad/assessment/unarchive?id=` + id };
        return this.httpClient
            .post(req.url, null, { observe: 'response', responseType: 'text' });
    }

    submitAssessment(payload) {
        const req = { url: `${environment.API_URL}/acad/assessment/submit` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    getSubmissions(id) {
        const req = { url: `${environment.API_URL}/acad/assessment/submissions?id=` + id };
        return this.httpClient.get(req.url);
    }

    getAssessmentById(id) {
        const req = { url: `${environment.API_URL}/acad/assessment/assessmentById?id=` + id };
        return this.httpClient.get(req.url);
    }

    evaluateAssessment(payload) {
        const req = { url: `${environment.API_URL}/acad/assessment/evaluate` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    getStudentSubmissions(studentId) {
        const req = { url: `${environment.API_URL}/acad/assessment/studentSumbissions?studentId=` + studentId };
        return this.httpClient.get(req.url);
    }

    getSubmissionsCount() {
        // let payload = { "AssessmentIds": ids };
        const req = { url: `${environment.API_URL}/acad/assessment/submissionsCount` };
        return this.httpClient
            .get(req.url);
    }

    //Domicile
    updateDomicile(data): Observable<any> {
        let payload = {
            "Id": data.payload.id,
            "Caste": data.payload.domicile.caste,
            "Country": data.payload.domicile.country,
            "MotherTongue": data.payload.domicile.motherTongue,
            "State": data.payload.domicile.state
        }
        const req = { url: `${environment.API_URL}/stu/student/updateDomicile` };
        return this.httpClient.post(req.url, payload);
    }
    getStates() {
        return this.http.get('https://swiftekin.github.io/ctng4/states_data.json');
    }
    authenticate(userName: string, password: string, token: string) {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('AccessCode', environment.ACCESS_CODE);
        if (token) {
            headers = headers.append('GToken', token);
        }
        else {
            headers = headers.append('UserEmail', userName);
            headers = headers.append('Password', password);
        }
        const url = `${environment.API_URL}/auth/token`;
        return this.httpClient
            .get(url, { headers: headers, observe: 'response', responseType: 'text' });
    }

    logout(userEmail) {
        const payload = { UserEmail: userEmail };
        const req = { url: `${environment.API_URL}/auth/user/logout` };
        return this.httpClient.post(req.url, payload);
    }

    getPaymentCreds(service) {
        const req = { url: `${environment.PAYMENT_URL}/payu/hash?service=` + service + `&plan=standard` };
        return this.httpClient
            .get(req.url);
    }

    getUserInitData() {
        let headers = new HttpHeaders();
        let topic = "user.signin";
        headers = headers.append('Topic', topic);
        headers = headers.append('Cache-Control', 'no-cache');
        const url = `${environment.API_URL}/auth/user/initData`;
        return this.httpClient.get(url, { headers: headers, observe: 'response', responseType: 'text' });
    }

    confirmNotificationConsent(userId) {
        const payload: any = null;
        const req = { url: `${environment.API_URL}/auth/user/enableNotification` + '?id=' + userId };
        return this.httpClient.post(req.url, payload);
    }

    handle_error(error: any) {
        return Observable.throw(error.json() || 'Server error');
    }

    register(userDetails) {
        const payload: any = userDetails;
        const url = `${environment.API_URL}/auth/anon/register`;
        return this.httpClient
            .post(url, payload, { observe: 'response', responseType: 'text' })
        // .subscribe(o => console.log(o.headers.get('AccessToken')), e => console.log("err", e));
        // .catch(this.handle_error);
    }

    upgradeUser(payload) {
        const url = `${environment.API_URL}/auth/user/upgrade`;
        return this.httpClient
            .post(url, payload, { observe: 'response', responseType: 'text' })
    }

    getRecoveryInfo(email: string) {
        const req = { url: `${environment.API_URL}/auth/anon/recoveryInfo?email=` + email };
        return this.httpClient.get(req.url, { observe: 'response', responseType: 'text' });
    }

    resetPassword(email: string) {
        const payload: any = { 'Email': email };
        const url = `${environment.API_URL}/auth/anon/ResetPassword`;
        return this.httpClient
            .post(url, payload, { observe: 'response', responseType: 'text' })
        // .subscribe(o => console.log(o.headers.get('AccessToken')), e => console.log("err", e));
        // .catch(this.handle_error);
    }

    resetPasswordVirtual(payload) {
        const url = `${environment.API_URL}/auth/anon/resetPasswordVirtual`;
        return this.httpClient
            .post(url, payload, { observe: 'response', responseType: 'text' })
    }

    changePassword(id: string, currentPassword: string, newPassword: string) {
        const payload: any = { 'Id': id, 'CurrentPassword': currentPassword, 'NewPassword': newPassword };
        const url = `${environment.API_URL}/auth/user/ChangePassword`;
        return this.httpClient
            .post(url, payload, { observe: 'response', responseType: 'text' })
        // .subscribe(o => console.log(o.headers.get('AccessToken')), e => console.log("err", e));
        // .catch(this.handle_error);
    }

    setCurrentPersona(userId: string, personaId: string) {
        const payload: any = { 'UserId': userId, 'PersonaId': personaId };
        const url = `${environment.API_URL}/auth/user/setCurrentPersona`;
        return this.httpClient
            .post(url, payload, { observe: 'response', responseType: 'text' })
        // .subscribe(o => console.log(o.headers.get('AccessToken')), e => console.log("err", e));
        // .catch(this.handle_error);
    }

    getUserByEmail(email: string) {
        const req = { url: `${environment.API_URL}/auth/lead/getUserByEmail?email=` + email };
        return this.httpClient
            .get(req.url);
    }

    resetStudentPassword(virtualId, mobiles) {
        const payload: any = { VirtualId: virtualId, Mobiles: mobiles };
        const url = `${environment.API_URL}/auth/user/resetPasswordStudent`;
        return this.httpClient
            .post(url, payload, { observe: 'response', responseType: 'text' })
    }

    stuCache() {
        const url = `${environment.API_URL}/stu/student/cache`;
        return this.httpClient
            .post(url, null, { observe: 'response', responseType: 'text' })
    }

    courseCache() {
        const url = `${environment.API_URL}/acad/course/cache`;
        return this.httpClient
            .post(url, null, { observe: 'response', responseType: 'text' })
    }
    resetStuCache() {
        const url = `${environment.API_URL}/stu/student/cache?reset=true`;
        return this.httpClient
            .post(url, null, { observe: 'response', responseType: 'text' })
    }

    resetCourseCache() {
        const url = `${environment.API_URL}/acad/course/cache?reset=true`;
        return this.httpClient
            .post(url, null, { observe: 'response', responseType: 'text' })
    }

    // setCurrentPersona(userId: string,personaId: string) {
    //   const payload: any = { 'userId':userId,'personaId':personaId };
    //   const url = `http://localhost:5210/api/auth/User/setCurrentPersona`;
    //   return this.httpClient
    //   .post(url, payload, { observe: 'response', responseType: 'text' })
    //   // .subscribe(o => console.log(o.headers.get('AccessToken')), e => console.log("err", e));
    //   // .catch(this.handle_error);
    // }

    set_headers(): Observable<any> {
        const obs_app_state = this.store.select(rootReducer.get_state_app);
        const obs_headers = obs_app_state.flatMap(res => {
            if (res && res.accessToken === '') return Observable.empty();
            let headers = new HttpHeaders();
            let token = res ? res.accessToken : '';
            let accessCode = res ? res.accessCode : '';
            let access_token = '';
            access_token = 'Bearer ' + token; // jwt
            headers = headers.append('Content-Type', 'application/json');
            headers = headers.append('AccessCode', accessCode);
            headers = headers.append('Authorization', access_token);
            headers = headers.append('Cache-Control', 'no-cache');

            return Observable.of(headers);
        });
        return obs_headers;
    }
    // deletes
    // gets
    get_personas(userId): Observable<any> {
        const obs_req = this.set_headers()
            .map(res => {
                const requestOptions = { params: new HttpParams() };
                requestOptions.params.set('headers', res);
                const obj = { request_options: requestOptions, url: `${environment.API_URL}/auth/persona?userId=` + userId };
                return obj;
            }).switchMap(res => {
                return this.httpClient
                    .get(res.url, res.request_options)
                // .map(res => res.json())
                // .catch(this.handle_error);
            });
        return obs_req;
    }
    get_user(userId: any): Observable<any> {
        const obs_req = this.set_headers()
            .map(res => {
                const requestOptions = { params: new HttpParams() };
                requestOptions.params.set('headers', res);
                const obj = { request_options: requestOptions, url: `${environment.API_URL}/user/GetPersonas` };
                // const obj = { request_options: new RequestOptions({ headers: res }), url: `${environment.API_URL}/user/` + userId };
                return obj;
            }).switchMap(res => {
                return this.httpClient
                    .get(res.url, res.request_options)
                // .map(res => res.json())
                // .catch(this.handle_error);
            });
        return obs_req;
    }
    get_user_details(userId: any): Observable<any> {
        const obs_req = this.set_headers()
            .map(res => {
                const requestOptions = { params: new HttpParams() };
                requestOptions.params.set('headers', res);
                const obj = { request_options: requestOptions, url: `${environment.API_URL}/auth/user?id=` + userId };
                // const obj = { request_options: new RequestOptions({ headers: res }), url: `${environment.API_URL}/user/` + userId };
                return obj;
            }).switchMap(res => {
                return this.httpClient
                    .get(res.url, res.request_options)
                // .map(res => res.json())
                // .catch(this.handle_error);
            });
        return obs_req;
    }
    updateUser(user) {
        const payload: any = { Id: user.id || "", Name: user.name || "", DOB: user.dob || "", Gender: user.gender || "" };
        const req = { url: `${environment.API_URL}/auth/user/update` };
        return this.httpClient.post(req.url, payload);
    }
    uploadUserPhoto(id, photoUrl) {
        const payload: any = { PhotoUrl: photoUrl };
        const req = { url: `${environment.API_URL}/auth/user/uploadPhoto?id=` + id };
        return this.httpClient.post(req.url, payload);
    }
    clearUserPhoto(id) {
        const payload: any = null;
        const req = { url: `${environment.API_URL}/auth/user/clearPhoto?id=` + id };
        return this.httpClient.post(req.url, payload);
    }

    updateRecoveryEmail(id, email) {
        const payload: any = { Id: id, RecoveryEmail: email };
        const req = { url: `${environment.API_URL}/auth/user/updateRecoveryEmail` };
        return this.httpClient.post(req.url, payload);
    }

    updateRecoveryMobile(id, mobile) {
        const payload: any = { Id: id, RecoveryMobile: mobile };
        const req = { url: `${environment.API_URL}/auth/user/updateRecoveryMobile` };
        return this.httpClient.post(req.url, payload);
    }

    updateUserHandle(payload) {
        const req = { url: `${environment.API_URL}/auth/user/handle` };
        return this.httpClient.post(req.url, payload);
    }
    // Inst
    getInst(id) {
        const req = { url: `${environment.API_URL}/auth/institute?id=` + id };
        return this.httpClient
            .get(req.url);
    }
    getInsts(qrystring) {
        const req = { url: `${environment.API_URL}/auth/institute` + '?' + qrystring };
        return this.httpClient
            .get(req.url);
    }
    getServices() {
        const req = { url: `${environment.API_URL}/auth/institute/services` };
        return this.httpClient
            .get(req.url);
    }
    getConnectInfo() {
        const req = { url: `${environment.API_URL}/auth/institute/connectInfo` };
        return this.httpClient
            .get(req.url);
    }
    enableEmail() {
        let headers = new HttpHeaders();
        let topic = "connect.info.update";
        headers = headers.append('Topic', topic);
        const req = { url: `${environment.API_URL}/auth/institute/enableEmail` };
        return this.httpClient.post(req.url, null, { headers: headers, observe: 'response', responseType: 'text' });
    }
    enableNotification() {
        let headers = new HttpHeaders();
        let topic = "connect.info.update";
        headers = headers.append('Topic', topic);
        const req = { url: `${environment.API_URL}/auth/institute/enableNotification` };
        return this.httpClient.post(req.url, null, { headers: headers, observe: 'response', responseType: 'text' });
    }
    enableSMS() {
        let headers = new HttpHeaders();
        let topic = "connect.info.update";
        headers = headers.append('Topic', topic);
        const req = { url: `${environment.API_URL}/auth/institute/enableSMS` };
        return this.httpClient.post(req.url, null, { headers: headers, observe: 'response', responseType: 'text' });
    }
    disableEmail() {
        let headers = new HttpHeaders();
        let topic = "connect.info.update";
        headers = headers.append('Topic', topic);
        const req = { url: `${environment.API_URL}/auth/institute/disableEmail` };
        return this.httpClient.post(req.url, null, { headers: headers, observe: 'response', responseType: 'text' });
    }
    disableSMS() {
        let headers = new HttpHeaders();
        let topic = "connect.info.update";
        headers = headers.append('Topic', topic);
        const req = { url: `${environment.API_URL}/auth/institute/disableSMS` };
        return this.httpClient.post(req.url, null, { headers: headers, observe: 'response', responseType: 'text' });
    }
    disableNotification() {
        let headers = new HttpHeaders();
        let topic = "connect.info.update";
        headers = headers.append('Topic', topic);
        const req = { url: `${environment.API_URL}/auth/institute/disableNotification` };
        return this.httpClient.post(req.url, null, { headers: headers, observe: 'response', responseType: 'text' });
    }
    uploadInstLogo(id, url) {
        let headers = new HttpHeaders();
        let topic = "institute.update";
        headers = headers.append('Topic', topic);
        const payload: any = { Id: id, Url: url };
        const req = { url: `${environment.API_URL}/auth/institute/setLogo` };
        return this.httpClient.post(req.url, payload, { headers: headers, observe: 'response', responseType: 'text' });
    }
    clearInstLogo(id) {
        let headers = new HttpHeaders();
        let topic = "institute.update";
        headers = headers.append('Topic', topic);
        const payload: any = null;
        const req = { url: `${environment.API_URL}/auth/institute/clearLogo?id=` + id };
        return this.httpClient.post(req.url, payload, { headers: headers, observe: 'response', responseType: 'text' });
    }
    updateInst(inst) {
        let headers = new HttpHeaders();
        let topic = "institute.update";
        headers = headers.append('Topic', topic);
        const payload: any = { Id: inst.id, Name: inst.name, ShortName: inst.shortName, AcademicYearFrom: inst.academicYearFrom, AcademicYearTo: inst.academicYearTo };
        const req = { url: `${environment.API_URL}/auth/institute/update` };
        return this.httpClient.post(req.url, payload, { headers: headers, observe: 'response', responseType: 'text' });
    }
    signUp(payload) {
        const req = { url: `${environment.API_URL}/auth/anon/signup` };
        return this.httpClient.post(req.url, payload);
    }

    getUserByName(name) {
        const req = { url: `${environment.API_URL}/auth/anon/userByName?name=` + name };
        return this.httpClient
            .get(req.url);
    }

    setRC(inst) {
        const payload: any = { Id: inst.id, InstituteCode: inst.InstituteCode, RegistrationNumber: inst.RegistrationNumber };
        const req = { url: `${environment.API_URL}/auth/institute/setRC` };
        return this.httpClient.post(req.url, payload);
    }
    setCourseOrder(instId, courseOrder) {
        const payload: any = { Id: instId, CourseOrder: courseOrder };
        const req = { url: `${environment.API_URL}/auth/institute/setCourseOrder` };
        return this.httpClient.post(req.url, payload);
    }
    updateCourseRank(payload) {
        const req = { url: `${environment.API_URL}/acad/course/setRank` };
        return this.httpClient.post(req.url, payload);
    }
    updateInstWebsite(inst) {
        const payload: any = { Id: inst.id, Website: inst.Website };
        const req = { url: `${environment.API_URL}/auth/institute/setWebsite` };
        return this.httpClient.post(req.url, payload);
    }
    updateInstAddress(instAddress) {
        let headers = new HttpHeaders();
        let topic = "institute.update";
        headers = headers.append('Topic', topic);
        const payload: any = instAddress;
        const req = { url: `${environment.API_URL}/auth/institute/setAddress?id=` + instAddress.Id };
        return this.httpClient.post(req.url, payload, { headers: headers, observe: 'response', responseType: 'text' });
    }
    getInstUsers(qrystring, instId) {
        const req = { url: `${environment.API_URL}/auth/user` + '?' + qrystring };
        return this.httpClient
            .get(req.url);
    }
    addInstUser(user) {
        const payload: any = user;
        const req = { url: `${environment.API_URL}/auth/institute/addUser` };
        return this.httpClient.post(req.url, payload);
    }
    addUser(user) {
        const payload: any = user;
        const req = { url: `${environment.API_URL}/auth/user` };
        return this.httpClient.post(req.url, payload);
    }
    setStudentAsUser(user) {
        const payload: any = user;
        const req = { url: `${environment.API_URL}/auth/user/setStudentUser` };
        return this.httpClient.post(req.url, payload);
    }
    revokeInstUser(user) {
        const payload: any = user;
        const req = { url: `${environment.API_URL}/auth/user/revokeAccess` };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    updateInstUserPwd(user) {
        const payload: any = { Id: user.id, NewPassword: user.pwd };
        const req = { url: `${environment.API_URL}/auth/user/changePassword` };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    getInstPersonas(qrystring, instId) {
        const req = { url: `${environment.API_URL}/auth/persona` + '?clientId=' + instId + '&' + qrystring };
        return this.httpClient
            .get(req.url);
    }
    assignInstPersona(user) {
        const payload: any = user;
        const req = { url: `${environment.API_URL}/auth/user/assignPersona` };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    removeInstPersona(user) {
        const payload: any = user;
        const req = { url: `${environment.API_URL}/auth/user/removePersona` };
        return this.httpClient.post(req.url, payload);
    }
    addInstPersona(persona) {
        const payload: any = persona;
        const req = { url: `${environment.API_URL}/auth/persona` };
        return this.httpClient.post(req.url, payload);
    }
    updateInstPersona(persona) {
        const payload: any = persona;
        const req = { url: `${environment.API_URL}/auth/persona` };
        return this.httpClient.post(req.url, payload);
    }
    disableInstPersona(id) {
        const payload: any = null;
        const req = { url: `${environment.API_URL}/auth/persona/disable` + '?id=' + id };
        return this.httpClient.post(req.url, payload);
    }
    enableInstPersona(id) {
        const payload: any = null;
        const req = { url: `${environment.API_URL}/auth/persona/enable` + '?id=' + id };
        return this.httpClient.post(req.url, payload);
    }
    lockInstitute(id) {
        const payload: any = null;
        const req = { url: `${environment.API_URL}/auth/institute/lock` + '?id=' + id };
        return this.httpClient.post(req.url, payload);
    }
    unlockInstitute(id) {
        const payload: any = null;
        const req = { url: `${environment.API_URL}/auth/institute/unlock` + '?id=' + id };
        return this.httpClient.post(req.url, payload);
    }
    disableInstitute(id) {
        const payload: any = null;
        const req = { url: `${environment.API_URL}/auth/institute/disable` + '?id=' + id };
        return this.httpClient.post(req.url, payload);
    }
    setInstituteAsReadOnly(id) {
        const payload: any = null;
        const req = { url: `${environment.API_URL}/auth/institute/readOnly` + '?id=' + id };
        return this.httpClient.post(req.url, payload);
    }
    setInstituteAsReadWrite(id) {
        const payload: any = null;
        const req = { url: `${environment.API_URL}/auth/institute/readWrite` + '?id=' + id };
        return this.httpClient.post(req.url, payload);
    }
    enableInstitute(id) {
        let headers = new HttpHeaders();
        let topic = "institute.update";
        headers = headers.append('Topic', topic);
        const payload: any = null;
        const req = { url: `${environment.API_URL}/auth/institute/enable` + '?id=' + id };
        return this.httpClient.post(req.url, payload, { headers: headers, observe: 'response', responseType: 'text' });
    }

    getDashboardWidgets() {
        let headers = new HttpHeaders();
        let topic = "user.dashboard";
        headers = headers.append('Topic', topic);
        const req = { url: `${environment.API_URL}/auth/user/dashboardWidgets` };
        return this.httpClient.get(req.url, { headers: headers, observe: 'response', responseType: 'text' });
    }
    setWidgetsOrder(postdata) {
        const payload: any = { DashboardWidgets: postdata };
        const req = { url: `${environment.API_URL}/auth/user/dashboardWidgets/setOrder` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    pinDashboardWidget(postdata) {
        const payload: any = { DashboardWidgets: postdata };
        const req = { url: `${environment.API_URL}/auth/user/pin` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    unpinDashboardWidget(postdata) {
        const payload: any = { DashboardWidgets: postdata };
        const req = { url: `${environment.API_URL}/auth/user/unpin` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    addSmsCredit(ownerId, partyId, date, creditItems, debitItems, reference, particulars) {
        const payload: any = { Id: "", OwnerId: ownerId, PartyId: partyId, Date: date, CreditItems: creditItems, DebitItems: debitItems, Reference: reference, Particulars: particulars, AccountingJournalId: "" };
        const req = { url: `${environment.API_URL}/accounting/journal/smsInventoryCredit` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    getSMSTx(instId) {
        const req = { url: `${environment.API_URL}/accounting/journal/smsTx?instId=` + instId };
        return this.httpClient
            .get(req.url);
    }

    sendMessage(contactFormDeatils) {
        let headers = new HttpHeaders();
        let topic = "new.enquiry";
        headers = headers.append('Topic', topic);
        const payload: any = contactFormDeatils;
        const req = { url: `${environment.API_URL}/auth/anon/contact` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    // Org
    getOrgs(qrystring) {
        const req = { url: `${environment.API_URL}/auth/org` + '?' + qrystring };
        return this.httpClient
            .get(req.url);
    }
    getFirstLevelOrgs() {
        const req = { url: `${environment.API_URL}/auth/org/getFirstLevelOrgs` };
        return this.httpClient
            .get(req.url);
    }
    getModules() {
        const req = { url: `${environment.API_URL}/auth/org/getModules` };
        return this.httpClient
            .get(req.url);
    }
    uploadOrgLogo(id, url) {
        const payload: any = { Id: id, Url: url };
        const req = { url: `${environment.API_URL}/auth/org/setLogo` };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    clearOrgLogo(id) {
        const payload: any = null;
        const req = { url: `${environment.API_URL}/auth/org/clearLogo?id=` + id };
        return this.httpClient.post(req.url, payload);
    }
    addOrg(newOrg) {
        const payload: any = { Name: newOrg.name, ShortName: newOrg.shortName || "", ParentId: newOrg.parentId || "", OwnerId: newOrg.ownerId || "" };
        const req = { url: `${environment.API_URL}/auth/org` };
        return this.httpClient.post(req.url, payload);
    }
    updateOrgDetails(org) {
        const payload: any = { Id: org.id, Name: org.name, ShortName: org.shortName, ParentId: org.parentId, OwnerId: org.ownerId, Modules: org.modules };
        const req = { url: `${environment.API_URL}/auth/org/update` };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    updateOrgAddress(org) {
        const payload: any = { Line1: org.address.line1, Line2: org.address.line2, City: org.address.city, Pincode: org.address.pincode, State: org.address.state, Country: org.address.country };
        const req = { url: `${environment.API_URL}/auth/org/setAddress?id=` + org.id };
        return this.httpClient.post(req.url, payload);
    }
    getOrgInsts(qrystring, orgId) {
        const req = { url: `${environment.API_URL}/auth/institute?` + qrystring };
        return this.httpClient
            .get(req.url);
    }
    getSelectedOrgInsts(orgId) {
        const req = { url: `${environment.API_URL}/auth/institute/getInstitutesInOrg?orgId=` + orgId };
        return this.httpClient
            .get(req.url);
    }
    disableOrg(id) {
        const payload: any = null;
        const req = { url: `${environment.API_URL}/auth/org/disable` + '?id=' + id };
        return this.httpClient.post(req.url, payload);
    }
    enableOrg(id) {
        const payload: any = null;
        const req = { url: `${environment.API_URL}/auth/org/enable` + '?id=' + id };
        return this.httpClient.post(req.url, payload);
    }
    disableInst(id) {
        let headers = new HttpHeaders();
        let topic = "institute.update";
        headers = headers.append('Topic', topic);
        const payload: any = null;
        const req = { url: `${environment.API_URL}/auth/institute/disable` + '?id=' + id };
        return this.httpClient.post(req.url, payload, { headers: headers, observe: 'response', responseType: 'text' });
    }
    enableInst(id) {
        const payload: any = null;
        const req = { url: `${environment.API_URL}/auth/institute/enable` + '?id=' + id };
        return this.httpClient.post(req.url, payload);
    }
    addInst(inst) {
        const payload: any = inst;
        let headers = new HttpHeaders();
        let topic = "institute.create";
        headers = headers.append('Topic', topic);
        const req = { url: `${environment.API_URL}/auth/institute` };
        return this.httpClient.post(req.url, payload, { headers: headers, observe: 'response', responseType: 'text' });
    }
    getOrgUsers(qrystring, orgId) {
        const req = { url: `${environment.API_URL}/auth/user?` + qrystring };
        // const req = { url: `${environment.API_URL}/auth/user` + '?clientId=' + orgId + '&' + qrystring };
        return this.httpClient
            .get(req.url);
    }
    addOrgUser(user) {
        const payload: any = user;
        const req = { url: `${environment.API_URL}/auth/org/addUser` };
        return this.httpClient.post(req.url, payload);
    }
    revokeOrgUser(user) {
        const payload: any = user;
        const req = { url: `${environment.API_URL}/auth/user/revokeAccess` };
        return this.httpClient.post(req.url, payload);
    }
    updateUserPwd(user) {
        const payload: any = { Id: user.id, NewPassword: user.pwd };
        const req = { url: `${environment.API_URL}/auth/user/changePassword` };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    getOrgPersonas(qrystring, orgId) {
        const req = { url: `${environment.API_URL}/auth/persona` + '?clientId=' + orgId + '&' + qrystring };
        return this.httpClient
            .get(req.url);
    }
    assignOrgPersona(user) {
        const payload: any = user;
        const req = { url: `${environment.API_URL}/auth/user/assignPersona` };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    removeOrgPersona(user) {
        const payload: any = user;
        const req = { url: `${environment.API_URL}/auth/user/removePersona` };
        return this.httpClient.post(req.url, payload);
    }
    addOrgPersona(persona) {
        const payload: any = persona;
        let headers = new HttpHeaders();
        let topic = "persona.create";
        headers = headers.append('Topic', topic);
        const req = { url: `${environment.API_URL}/auth/persona` };
        return this.httpClient.post(req.url, payload, { headers: headers, observe: 'response', responseType: 'text' });
    }
    updateOrgPersona(persona) {
        const payload: any = persona;
        const req = { url: `${environment.API_URL}/auth/persona/update` };
        return this.httpClient.post(req.url, payload);
    }
    getPermissions(qrystring) {
        // const req = { url: `${environment.API_URL}/auth/permission` + '?' + qrystring };
        const req = { url: `${environment.API_URL}/auth/persona/scope` };
        return this.httpClient
            .get(req.url);
    }
    updateScope(personaId, scopeArr) {
        const payload: any = { NamedScope: scopeArr };
        const req = { url: `${environment.API_URL}/auth/persona/scope/update?id=` + personaId };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    addPermToPersona(perm) {
        const payload: any = perm;
        const req = { url: `${environment.API_URL}/auth/permission/addToPersona` };
        return this.httpClient.post(req.url, payload);
    }
    removePermFromPersona(perm) {
        const payload: any = perm;
        const req = { url: `${environment.API_URL}/auth/permission/removePermFromPersona` };
        return this.httpClient.post(req.url, payload);
    }
    disableOrgPersona(id) {
        const payload: any = null;
        const req = { url: `${environment.API_URL}/auth/persona/disable` + '?id=' + id };
        return this.httpClient.post(req.url, payload);
    }
    enableOrgPersona(id) {
        const payload: any = null;
        const req = { url: `${environment.API_URL}/auth/persona/enable` + '?id=' + id };
        return this.httpClient.post(req.url, payload);
    }

    userDashboard(postData) {
        const payload: any = { 'DashboardItems': postData };
        const url = `${environment.API_URL}/auth/user/dashboard`;
        return this.httpClient
            .post(url, payload, { observe: 'response', responseType: 'text' })
    }
    //Academics
    printabsenteesReport(printOptions, date) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": { "Date": date } };
        const req = { url: `${environment.API_URL}/stu/report/absentees` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    printAllAPlusReport(printOptions, courseIds, tags) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": { "CourseIds": courseIds, "Tags": tags } };
        const req = { url: `${environment.API_URL}/acad/report/allAPlus` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    printAttendanceReport(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/acad/report/attendance` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    printConsolidatedMarksReport(printOptions, selectedCourseIds, sortBy, tags) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": { "CourseIds": selectedCourseIds, "SortBy": sortBy, "Tags": tags } };
        const req = { url: `${environment.API_URL}/acad/report/consolidatedMarks` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    printConsolidatedReport(printOptions, courseId, sectionName, term, sortBy, showTopTen) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": { "CourseId": courseId, "SortBy": sortBy, "ShowTopTen": showTopTen, "SectionName": sectionName, "TermName": term } };
        const req = { url: `${environment.API_URL}/acad/report/consolidatedReport` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    printCoursewiseResultAnalysisReport(printOptions, examIds, examName, tags) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": { "ExamIds": examIds, "ExamName": examName, "Tags": tags } };
        const req = { url: `${environment.API_URL}/acad/report/coursewiseResultAnalysis` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    printExamAttendanceReport(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/acad/report/examAttendance` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    printExamListReport(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/acad/report/examList` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    printExamwiseFailuresReport(printOptions, examIds, examName, tags) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": { "ExamIds": examIds, "ExamName": examName, "Tags": tags } };
        const req = { url: `${environment.API_URL}/acad/report/examwiseFailures` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    printFailuresReport(printOptions, courseIds, tags) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": { "CourseIds": courseIds, "Tags": tags } };
        const req = { url: `${environment.API_URL}/acad/report/failures` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    printTopTenScorersReport(printOptions, courseIds, tags) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": { "CourseIds": courseIds, "Tags": tags } };
        const req = { url: `${environment.API_URL}/acad/report/topTen` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    printFullMarksReport(printOptions, examIds, examName, tags) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": { "ExamIds": examIds, "ExamName": examName, "Tags": tags } };
        const req = { url: `${environment.API_URL}/acad/report/fullMarks` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    printGradewiseStatisticsReport(printOptions, examIds, examName, tags) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": { "ExamIds": examIds, "ExamName": examName, "Tags": tags } };
        const req = { url: `${environment.API_URL}/acad/report/gradewiseStatistics` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    printHallTicketReport(contentType, students) {
        const payload: any = { "ContentType": contentType, "Students": students };
        const req = { url: `${environment.API_URL}/acad/report/hallTicket` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    printHolidayList(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/scheduler/report/holidayList` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    printNoDueCertificateReport(students) {
        const payload: any = { "Students": students };
        const req = { url: `${environment.API_URL}/acad/report/noDueCertificate` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    printRankListReport(printOptions, tags) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": { "Tags": tags } };
        const req = { url: `${environment.API_URL}/acad/report/rankList` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    printResultAnalysisReport(printOptions, examIds, examName, tags) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": { "ExamIds": examIds, "ExamName": examName, "Tags": tags } };
        const req = { url: `${environment.API_URL}/acad/report/resultAnalysis` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    printResultAtAGlanceReport(printOptions, tags) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": { "Tags": tags } };
        const req = { url: `${environment.API_URL}/acad/report/resultAtAGlance` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    printResultAtAGlanceSummaryReport(printOptions, examIds, examName, tags, comprehensive) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": { "ExamIds": examIds, "ExamName": examName, "Tags": tags, "Comprehensive": comprehensive } };
        const req = { url: `${environment.API_URL}/acad/report/resultAtAGlanceSummary` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    printStudentPerformanceReport(printOptions, examId, tags) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": { "ExamId": examId, "Tags": tags } };
        const req = { url: `${environment.API_URL}/acad/report/studentPerformance` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    studentPerformanceReportAsCSV(examId, tags) {
        const payload: any = { "Data": { "ExamId": examId, "Tags": tags } };
        const req = { url: `${environment.API_URL}/acad/report/studentPerformanceCSV` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    studentParentDetailsAsCSV(courseId, secName, termName) {
        const payload: any = { "Data": { "CourseId": courseId, "Section": secName, "Term": termName } };
        const req = { url: `${environment.API_URL}/stu/report/studentParentDetailsCSV` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    printSubjectAverageReport(printOptions, examIds, examName, tags) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": { "ExamIds": examIds, "ExamName": examName, "Tags": tags } };
        const req = { url: `${environment.API_URL}/acad/report/subjectAverage` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    printTodaysEvent(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/acad/report/todaysEvents` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    printSubjectwiseTopScorersReport(printOptions, courseIds, tags) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": { "CourseIds": courseIds, "Tags": tags } };
        const req = { url: `${environment.API_URL}/acad/report/subjectTopScorers` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    printAcademicsUpcomingEvents(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/scheduler/report/upcomingEvents` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    // Artefact
    createArtefactTag(tag) {
        let payload = {
            "Name": tag.name,
            "Predefined": tag.predefined,
            "Meta": tag.meta,
        }
        const req = { url: `${environment.API_URL}/arte/artefact/tag` };
        return this.httpClient.post(req.url, payload);
    }

    enableArtefactTag(tagId) {
        const req = { url: `${environment.API_URL}/arte/artefact/enableTag?id=` + tagId };
        return this.httpClient.post(req.url, null);
    }

    disableArtefactTag(tagId) {
        const req = { url: `${environment.API_URL}/arte/artefact/disableTag?id=` + tagId };
        return this.httpClient.post(req.url, null);
    }

    updateArtefactTag(tagObj) {
        let payload = tagObj;
        const req = { url: `${environment.API_URL}/arte/artefact/updateTag` };
        return this.httpClient.post(req.url, payload);
    }

    updateContentTag(tagObj) {
        let payload = tagObj;
        const req = { url: `${environment.API_URL}/arte/artefact/updateContentTag` };
        return this.httpClient.post(req.url, payload);
    }

    getArtefactTags() {
        const req = { url: `${environment.API_URL}/arte/artefact/tags` };
        return this.httpClient.get(req.url);
    }

    getContentTags() {
        const req = { url: `${environment.API_URL}/arte/artefact/contentTags` };
        return this.httpClient.get(req.url);
    }

    get(qStr) {
        const req = { url: `${environment.API_URL}/arte/artefact?` + qStr };
        return this.httpClient
            .get(req.url);
    }
    getArtefactData() {
        const req = { url: `${environment.API_URL}/arte/artefact?$search=data:true&$orderby=%20&$skip=0&$top=1024` };
        return this.httpClient
            .get(req.url);
    }
    getDocs(id) {
        const req = { url: `${environment.API_URL}/arte/github?id=` + id };
        return this.httpClient
            .get(req.url);
    }
    getDocsByPath(path, qry) {
        const req = { url: `${environment.API_URL}/arte/github/getFiles?url=` + path + qry + `&$orderby=title&$sortorder=asc` };
        return this.httpClient
            .get(req.url);
    }
    getDocsContentByPath(path, qry) {
        const req = { url: `${environment.API_URL}/arte/github/filesWithContent?url=` + path + qry };
        return this.httpClient
            .get(req.url);
    }
    publishDoc(id, topicId) {
        const payload: any = null;
        const req = { url: `${environment.API_URL}/arte/artefact/publishArtefact?id=` + id + `&topicId=` + topicId };
        return this.httpClient.post(req.url, payload);
    }
    unpublishDoc(id) {
        const payload: any = null;
        const req = { url: `${environment.API_URL}/arte/artefact/unpublishArtefact?id=` + id };
        return this.httpClient.post(req.url, payload);
    }
    disableDoc(id) {
        const payload: any = null;
        const req = { url: `${environment.API_URL}/arte/github/disable?id=` + id };
        return this.httpClient.post(req.url, payload);
    }
    enableDoc(id) {
        const payload: any = null;
        const req = { url: `${environment.API_URL}/arte/github/enable?id=` + id };
        return this.httpClient.post(req.url, payload);
    }
    getPubArte() {
        const req = { url: `${environment.API_URL}/arte/artefact?$search=published&$skip=0&$top=500` };
        return this.httpClient
            .get(req.url);
    }

    getDataTemplates() {
        const req = { url: `${environment.API_URL}/arte/artefact/dataTemplates` };
        return this.httpClient
            .get(req.url);
    }

    fetchLiveData(id) {
        const req = { url: `${environment.API_URL}/arte/artefact/dataTemplatesLive?id=` + id };
        return this.httpClient
            .get(req.url);
    }

    fetchWorkingData(id) {
        const req = { url: `${environment.API_URL}/arte/artefact/dataTemplatesWorking?id=` + id };
        return this.httpClient
            .get(req.url);
    }

    getUploadedFileContent(name, acadYear) {
        const req = { url: `${environment.API_URL}/arte/artefact/getDataTemplatesByName?name=` + name };
        return this.httpClient
            .get(req.url);
    }

    saveCSV(data) {
        const req = { url: `${environment.API_URL}/arte/artefact/dataTemplatesSave` };
        return this.httpClient.post(req.url, data);
    }

    getFiles(): any {
        const req = { url: `${environment.API_URL}/arte/artefact/fileNames` };
        return this.httpClient.get(req.url);
    }

    getVaultImages(qryString): any {
        const req = { url: `${environment.API_URL}/arte/artefact` + qryString };
        // const req = { url: `${environment.API_URL}/arte/artefact?$search=media:true` };
        // const req = { url: `${environment.API_URL}/arte/artefact?$search=media:true&$orderby=%20&$skip=0&$top=1024` };
        return this.httpClient.get(req.url);
    }

    getFileContent(file_name): any {
        const req = { url: `${environment.API_URL}/arte/artefact/file?name=` + file_name };
        return this.httpClient.get(req.url);
    }

    getEmailTemplate() {
        const req = { url: `${environment.API_URL}/arte/artefact/emailTemplate` };
        return this.httpClient.get(req.url);
    }

    getEmailTemplateWithoutHeader() {
        const req = { url: `${environment.API_URL}/arte/artefact/emailTemplateWithoutHF` };
        return this.httpClient.get(req.url);
    }

    getStudentProfile(userEmail) {
        const req = { url: `${environment.API_URL}/stu/student/profile?userEmail=` + userEmail };
        return this.httpClient.get(req.url);
    }

    downloadWithData(template_title, format = "csv") {
        var req;
        if (template_title == "Receipts")
            req = { url: `${environment.API_URL}/gr/gr/getDtoWithData?format=` + format };
        if (template_title == "Students")
            req = { url: `${environment.API_URL}/stu/student/getDtoWithData?format=` + format };
        if (template_title == "Library")
            req = { url: `${environment.API_URL}/library/material/getDtoWithData?format=` + format };
        if (template_title == "Courses")
            req = { url: `${environment.API_URL}/acad/course/getDtoWithData?format=` + format };
        if (template_title == "Institute")
            req = { url: `${environment.API_URL}/auth/institute/getDtoWithData?format=` + format };
        return this.httpClient.get(req.url);
    }

    getDto(template_title) {
        var req;
        if (template_title == "Receipts")
            req = { url: `${environment.API_URL}/gr/gr/getDto` };
        if (template_title == "Students")
            req = { url: `${environment.API_URL}/stu/student/getDto` };
        if (template_title == "Library")
            req = { url: `${environment.API_URL}/library/material/getDto` };
        if (template_title == "Courses")
            req = { url: `${environment.API_URL}/acad/course/getDto` };
        if (template_title == "Institute")
            req = { url: `${environment.API_URL}/auth/institute/getDto` };
        return this.httpClient.get(req.url);
    }

    getOnQry(qrystring) {
        const req = { url: `${environment.API_URL}/arte/artefact/getOnQry` + '?' + qrystring };
        return this.httpClient
            .get(req.url);
    }

    update(artefact: any) {
        const payload: any = artefact;
        const req = { url: `${environment.API_URL}/arte/artefact/update` };
        return this.httpClient.post(req.url, payload);
    }

    disableArtefact(artefact_id) {
        const payload: any = artefact_id;
        const req = { url: `${environment.API_URL}/arte/artefact/disable?id=` + artefact_id };
        return this.httpClient.post(req.url, payload);
    }

    enableArtefact(artefact_id) {
        const payload: any = artefact_id;
        const req = { url: `${environment.API_URL}/arte/artefact/enable?id=` + artefact_id };
        return this.httpClient.post(req.url, payload);
    }

    download(artefact) {
        var url = `${environment.API_URL}/arte/artefact/download?id=` + encodeURIComponent(artefact.id);
        return this.httpClient.get(url, { observe: 'response', responseType: "blob" });
    }

    downloadJson(id) {
        var url = `${environment.API_URL}/arte/artefact/download?id=` + id;
        return this.httpClient.get(url);
    }

    downloadDoc(id) {
        var url = `${environment.API_URL}/arte/github/download?id=` + id;
        return this.httpClient.get(url);
    }

    downloadStudentAttendance(payload) {
        var url = `${environment.API_URL}/acad/artefact/StudentAttendanceTemplate`;
        // return this.httpClient.post(url, payload, { observe: 'response', responseType: 'text' });
        return this.httpClient.post(url, payload, { observe: 'response' });

    }

    addComment(payload) {
        const req = { url: `${environment.API_URL}/arte/artefact/comment` };
        return this.httpClient.post(req.url, payload, { observe: 'response' });
    }

    addVideoLink(payload) {
        const req = { url: `${environment.API_URL}/arte/artefact/videoLink` };
        return this.httpClient.post(req.url, payload, { observe: 'response' });
    }

    updateDataAttributes(payload) {
        const req = { url: `${environment.API_URL}/arte/artefact/attribute` };
        return this.httpClient.post(req.url, payload, { observe: 'response' });
    }

    getUrl(arte_id) {
        const req = { url: `${environment.API_URL}/arte/artefact?id=` + arte_id };
        return this.httpClient
            .get(req.url);
    }

    generateEtl(endpoint, path) {
        const req = { url: `${environment.API_URL}/arte/etl/${endpoint}?path=` + path };
        return this.httpClient.post(req.url, null, { observe: 'response' });
    }

    runEtl(id) {
        const req = { url: `${environment.API_URL}/arte/artefact/runEtl?id=` + id };
        return this.httpClient.post(req.url, null);
    }

    runAllEtlsInFolder(id) {
        const req = { url: `${environment.API_URL}/arte/artefact/executeAllEtlsInDir?id=` + id };
        return this.httpClient.post(req.url, null);
    }

    viewData(id) {
        const req = { url: `${environment.API_URL}/arte/artefact/fileContent?id=` + id };
        return this.httpClient
            .get(req.url);
    }

    upsertArtefact(files) {
        const payload: any = null;
        const req = { url: `${environment.API_URL}/arte/artefact/upsert` };
        const fileCount: number = files.length;
        const formData = new FormData();
        if (fileCount > 0) {
            for (let i = 0; i < fileCount; i++) {
                formData.append('files', files.item(i));
            }
        }
        return this.httpClient.post(req.url, formData);
    }

    getEtlFiles(path) {
        const req = { url: `${environment.API_URL}/arte/artefact/etlFileNames?path=` + path };
        return this.httpClient
            .get(req.url);
    }

    getEtlFileContent(path) {
        const req = { url: `${environment.API_URL}/arte/artefact/etlFileContent?path=` + path };
        return this.httpClient
            .get(req.url);
    }

    // Report
    getReports() {
        const req = { url: `${environment.API_URL}/arte/artefact/reports` };
        return this.httpClient
            .get(req.url);
    }

    updateReport(report) {
        const payload: any = report;
        const req = { url: `${environment.API_URL}/arte/artefact/updateReport?id=` + report.id };
        return this.httpClient.post(req.url, payload);
    }

    render(payload) {
        const req = { url: `${environment.API_URL}/arte/artefact/template` };
        return this.httpClient.post(req.url, payload);
    }

    getTemplate(id) {
        const req = { url: `${environment.API_URL}/arte/artefact/getTemplate?id=` + id };
        return this.httpClient
            .get(req.url, id);
    }

    getModel(id) {
        const req = { url: `${environment.API_URL}/arte/artefact/getModel?id=` + id };
        return this.httpClient
            .get(req.url, id);
    }

    // Library
    createMaterial(material: any) {
        const payload: any = material;
        const req = { url: `${environment.API_URL}/library/material` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    getMaterial(qrystring) {
        const req = { url: `${environment.API_URL}/library/material` + '?' + qrystring };
        return this.httpClient
            .get(req.url);
    }

    cloneMaterial(id, trackingIds) {
        const payload: any = { "Id": id, "TrackingIds": trackingIds };
        const req = { url: `${environment.API_URL}/library/material/clone` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    disableMaterial(id) {
        const req = { url: `${environment.API_URL}/library/material/disable?id=` + id };
        return this.httpClient
            .post(req.url, null, { observe: 'response', responseType: 'text' });
    }

    enableMaterial(id) {
        const req = { url: `${environment.API_URL}/library/material/enable?id=` + id };
        return this.httpClient
            .post(req.url, null, { observe: 'response', responseType: 'text' });
    }

    issueMaterial(id, trackingId, membershipId, issuedOn, dueOn) {
        const payload: any = { "MaterialId": id, "TrackingId": trackingId, "MembershipId": membershipId, "TxDate": issuedOn, "DueOn": dueOn };
        const req = { url: `${environment.API_URL}/library/circulation/issue` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    reIssueMaterial(id, trackingId, reIssuedOn, dueOn) {
        const payload: any = { "MaterialId": id, "TrackingId": trackingId, "TxDate": reIssuedOn, "DueOn": dueOn };
        const req = { url: `${environment.API_URL}/library/circulation/reIssue` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    getBarcodeDetails(barcodeValue) {
        const req = { url: `${environment.API_URL}/library/circulation?barcodeValue=` + barcodeValue };
        return this.httpClient
            .get(req.url);
    }

    materialMaintenance(id) {
        const req = { url: `${environment.API_URL}/library/material/maintenance?id=` + id };
        return this.httpClient
            .post(req.url, null, { observe: 'response', responseType: 'text' });
    }

    returnMaintenanceMaterial(id) {
        const req = { url: `${environment.API_URL}/library/material/returnMaintenanceMaterial?id=` + id };
        return this.httpClient
            .post(req.url, null, { observe: 'response', responseType: 'text' });
    }

    returnMaterial(id, trackingId, returnedOn) {
        const payload: any = { "MaterialId": id, "TrackingId": trackingId, "TxDate": returnedOn };
        const req = { url: `${environment.API_URL}/library/circulation/return` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printAgeAnalysisReport(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/library/report/ageAnalysis` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printBarcodes(trackingIds, barcodePosition, noOfPrints, marginTop) {
        const payload: any = { "Input": trackingIds, "BarcodePosition": barcodePosition, "NoOfPrints": noOfPrints, "MarginTop": marginTop };
        const req = { url: `${environment.API_URL}/library/barcode/generate` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printBooksWithoutCoverPhotoReport(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/library/report/booksWithoutCoverPhoto` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printBooksWithoutISBNReport(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/library/report/booksWithoutISBN` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printBooksWithoutSynopsisReport(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/library/report/booksWithoutSynopsis` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printLastMonitoredReport(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/library/report/lastMonitored` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printLateFeesReport(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/library/report/lateFees` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printReceipt(printOptions, txId) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": { TxId: txId } };
        const req = { url: `${environment.API_URL}/fee/report/printFeeReceipt` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    emailReceipt(printOptions, txId, studentId) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": { TxId: txId, StudentId: studentId } };
        const req = { url: `${environment.API_URL}/fee/email/feeReceipt` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }


    printLostMaterialsReport(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/library/report/lostMaterials` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printMaterialDueForMaintenanceReport(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/library/report/materialDueForMaintenance` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printMaterialsUnderCirculationReport(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/library/report/materialsUnderCirculation` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printMaterialsUnderMaintenanceReport(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/library/report/materialsUnderMaintenance` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printLibraryMembersReport(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/library/report/members` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printLibraryOverDueReport(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/library/report/overDue` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printReferenceMaterialReport(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/library/report/referenceMaterials` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printLibrarySummaryReport(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/library/report/summary` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printUnverifiedStockReport(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/library/report/unverifiedStock` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printValuationReport(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/library/report/valuation` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    // Admin Reports
    printCourseList(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/acad/report/getCourseReport` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printReceiptConsolidatedReport(postData) {
        const req = { url: `${environment.API_URL}/gr/report/getReceiptConsolidatedReport` };
        return this.httpClient
            .post(req.url, postData, { observe: 'response', responseType: 'text' });
    }

    printHrReport(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/auth/report/hr` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printStudentCourseWiseBreakUp(printOptions, courseId) {
        const payload: any = { "Meta": { "PrintOptions": printOptions, }, "Data": { "CourseId": courseId } };
        const req = { url: `${environment.API_URL}/stu/report/courseWiseBreakup` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printStudentSectionWiseBreakUp(printOptions, courseId, secName, termName) {
        const payload: any = { "Meta": { "PrintOptions": printOptions, }, "Data": { "CourseId": courseId, "Section": secName, "Term": termName } };
        const req = { url: `${environment.API_URL}/stu/report/sectionwiseBreakUp` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printStudentParentDetails(printOptions, courseId, secName, termName) {
        const payload: any = { "Meta": { "PrintOptions": printOptions, }, "Data": { "CourseId": courseId, "Section": secName, "Term": termName } };
        const req = { url: `${environment.API_URL}/stu/report/studentParentDetails` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printBasicSectionWiseBreakUp(printOptions, courseId, secName, termName, newAdmissionsOnly) {
        const payload: any = { "Meta": { "PrintOptions": printOptions, }, "Data": { "CourseId": courseId, "Section": secName, "Term": termName, "NewAdmissionsOnly": newAdmissionsOnly } };
        const req = { url: `${environment.API_URL}/stu/report/basicSectionwiseBreakUp` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printStudentDetails(printOptions, courseIds) {
        const payload: any = { "Meta": { "PrintOptions": printOptions, }, "Data": { "CourseIds": courseIds } };
        const req = { url: `${environment.API_URL}/stu/report/studentDetails` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    getCSVStudentDetails() {
        const req = { url: `${environment.API_URL}/stu/student/getDtoWithData?format=csv` };
        return this.httpClient.get(req.url);
    }

    getBaselineDataForm() {
        const req = { url: `${environment.API_URL}/stu/student/baselineDataForm` };
        return this.httpClient.get(req.url);
    }

    baselineDataForms() {
        const req = { url: `${environment.API_URL}/stu/student/baselineData?id=` };
        return this.httpClient.get(req.url);
    }

    createBaselineData(payload) {
        const req = { url: `${environment.API_URL}/stu/student/createBaselineData` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printNewAdmissions(printOptions, courseIds) {
        const payload: any = { "Meta": { "PrintOptions": printOptions, }, "Data": { "CourseIds": courseIds } };
        const req = { url: `${environment.API_URL}/stu/report/newAdmissions` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printTCStudents(printOptions, courseIds) {
        const payload: any = { "Meta": { "PrintOptions": printOptions, }, "Data": { "CourseIds": courseIds } };
        const req = { url: `${environment.API_URL}/stu/report/tcStudents` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printSectionCourseWiseBreakUp(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/auth/report/sectionCourseWiseBreakUp` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printSubjectWiseBreakUp(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/auth/report/subjectWiseBreakUp` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printUpcomingEventsReportAdmin(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/auth/report/upcomingEvents` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printUserReports(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/auth/report/user` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printCalendarReport(printOptions,) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/scheduler/report/calendar` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printSummaryReportAdmin(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/auth/report/summaryReportAdmin` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    getPdf(html: string) {
        const payload = { htmlString: html };
        const req = { url: `https://poc.campustrack.net/pdf/render/` };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'blob' });
    }
    // Connect
    getSMS(qryStr) {
        const req = { url: `${environment.API_URL}/connect/SMSSent?` + qryStr };
        return this.httpClient
            .get(req.url);
    }
    getSMSDeliveryData(smsId) {
        let headers = new HttpHeaders();
        let topic = "sms.delivery";
        headers = headers.append('Topic', topic);
        const req = { url: `${environment.API_URL}/connect/smsDeliveryData?smsId=` + smsId };
        return this.httpClient
            .get(req.url, { headers: headers, observe: 'response', responseType: 'text' });
    }
    getEmailDeliveryData(emailId) {
        let headers = new HttpHeaders();
        let topic = "email.delivery";
        headers = headers.append('Topic', topic);
        const req = { url: `${environment.API_URL}/connect/emailDeliveryData?smsId=` + emailId };
        return this.httpClient
            .get(req.url, { headers: headers, observe: 'response', responseType: 'text' });
    }
    getApprovedMessages() {
        const req = { url: `${environment.API_URL}/connect/smsMessages?activeOnly=true` };
        return this.httpClient
            .get(req.url);
    }

    sendParentSMSMeet(recp, text, smsRefId, includeTags) {
        let headers = new HttpHeaders();
        let topic = "sms.send";
        headers = headers.append('Topic', topic);
        const payload: any = { Recipients: recp, Text: text, SMSRefId: smsRefId, IncludeTags: includeTags };
        const req = { url: `${environment.API_URL}/stu/student/sms/parent` };
        return this.httpClient
            .post(req.url, payload, { headers: headers, observe: 'response', responseType: 'text' });
    }

    sendParentSMS(recp, text, smsRefId) {
        let headers = new HttpHeaders();
        let topic = "sms.send";
        headers = headers.append('Topic', topic);
        const payload: any = { Recipients: recp, Text: text, SMSRefId: smsRefId };
        const req = { url: `${environment.API_URL}/stu/student/sms/parent` };
        return this.httpClient
            .post(req.url, payload, { headers: headers, observe: 'response', responseType: 'text' });
    }
    sendStaffSMS(recp, text, smsRefId) {
        let headers = new HttpHeaders();
        let topic = "sms.send";
        headers = headers.append('Topic', topic);
        const payload: any = { Recipients: recp, Text: text, SMSRefId: smsRefId };
        const req = { url: `${environment.API_URL}/staff/sms/staff` };
        return this.httpClient
            .post(req.url, payload, { headers: headers, observe: 'response', responseType: 'text' });
    }
    sendManualSMS(recp, text, smsRefId) {
        let headers = new HttpHeaders();
        let topic = "sms.send";
        headers = headers.append('Topic', topic);
        const payload: any = { Recipients: recp, Text: text, SMSRefId: smsRefId };
        const req = { url: `${environment.API_URL}/connect/sms` };
        return this.httpClient
            .post(req.url, payload, { headers: headers, observe: 'response', responseType: 'text' });
    }
    getParentSMSRecpMeet(recp, text, includeTags) {
        const payload: any = { Recipients: recp, Text: text, IncludeTags: includeTags };
        const req = { url: `${environment.API_URL}/stu/student/SMSRecipients` };
        return this.httpClient
            .post(req.url, payload);
    }
    getParentSMSRecp(recp, text) {
        const payload: any = { Recipients: recp, Text: text };
        const req = { url: `${environment.API_URL}/stu/student/SMSRecipients` };
        return this.httpClient
            .post(req.url, payload);
    }
    getStaffSMSRecp(recp, text) {
        const payload: any = { Recipients: recp, Text: text };
        const req = { url: `${environment.API_URL}/staff/SMSRecipients` };
        return this.httpClient
            .post(req.url, payload);
    }
    getStuFacets(qry) {
        const req = { url: `${environment.API_URL}/stu/student/facet?includeStudents=` + qry };
        return this.httpClient
            .get(req.url);
    }
    getStaffFacets() {
        const req = { url: `${environment.API_URL}/staff/facet` };
        return this.httpClient
            .get(req.url);
    }
    getSMSCredits() {
        const req = { url: `${environment.API_URL}/connect/sms/credits` };
        return this.httpClient
            .get(req.url);
    }
    sendAbsenteeSMS(per) {
        let headers = new HttpHeaders();
        let topic = "sms.absentee";
        headers = headers.append('Topic', topic);
        const payload: any = { Per: per };
        const req = { url: `${environment.API_URL}/stu/student/sms/absentees` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    updateSMSSenderId(instId, smsSenderId) {
        const payload = { "Id": instId, "SMSSenderId": smsSenderId };
        const req = { url: `${environment.API_URL}/auth/institute/setSMSSenderId` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    createInventoryJournal(ownerId, partyId, date, creditItems, debitItems, reference, particulars) {
        const payload: any = { Id: "", OwnerId: ownerId, PartyId: partyId, Date: date, CreditItems: creditItems, DebitItems: debitItems, Reference: reference, Particulars: particulars, AccountingJournalId: "" };
        const req = { url: `${environment.API_URL}/accounting/journal/inventory` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    // Connect Reports
    printSmsUsage(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/connect/report/SMSUsageReport` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printInvalidBlockedNumbers(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/connect/report/invalidMobiles` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printInvalidBlockedEmails(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/connect/report/invalidEmails` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    lookupContacts(contactsArray) {
        const payload: any = { "Contacts": contactsArray };
        const req = { url: `${environment.API_URL}/connect/lookup` };
        return this.httpClient.post(req.url, payload);
    }

    blockContacts(contactsArray) {
        const payload: any = { "Contacts": contactsArray };
        const req = { url: `${environment.API_URL}/connect/block` };
        return this.httpClient.post(req.url, payload);
    }

    updateFatherEmail(id, email) {
        const payload: any = { "Id": id, "Email": email };
        const req = { url: `${environment.API_URL}/connect/updateFatherEmail` };
        return this.httpClient.post(req.url, payload);
    }

    updateMotherEmail(id, email) {
        const payload: any = { "Id": id, "Email": email };
        const req = { url: `${environment.API_URL}/connect/updateMotherEmail` };
        return this.httpClient.post(req.url, payload);
    }

    updateGuardianEmail(id, email) {
        const payload: any = { "Id": id, "Email": email };
        const req = { url: `${environment.API_URL}/connect/updateGuardianEmail` };
        return this.httpClient.post(req.url, payload);
    }

    updateStaffEmail(id, email) {
        const payload: any = { "Id": id, "Email": email };
        const req = { url: `${environment.API_URL}/connect/updateStaffEmail` };
        return this.httpClient.post(req.url, payload);
    }

    updateFatherMobile(id, mobile) {
        const payload: any = { "Id": id, "Mobile": mobile };
        const req = { url: `${environment.API_URL}/connect/updateFatherMobile` };
        return this.httpClient.post(req.url, payload);
    }

    updateMotherMobile(id, mobile) {
        const payload: any = { "Id": id, "Mobile": mobile };
        const req = { url: `${environment.API_URL}/connect/updateMotherMobile` };
        return this.httpClient.post(req.url, payload);
    }

    updateGuardianMobile(id, mobile) {
        const payload: any = { "Id": id, "Mobile": mobile };
        const req = { url: `${environment.API_URL}/connect/updateGuardianMobile` };
        return this.httpClient.post(req.url, payload);
    }

    updateStaffMobile(id, mobile) {
        const payload: any = { "Id": id, "Mobile": mobile };
        const req = { url: `${environment.API_URL}/connect/updateStaffMobile` };
        return this.httpClient.post(req.url, payload);
    }

    addQuote(tftd) {
        const req = { url: `${environment.API_URL}/connect/addQuote` };
        return this.httpClient.post(req.url, tftd);
    }

    removeQuote(id) {
        const req = { url: `${environment.API_URL}/connect/removeQuote?id=` + id };
        return this.httpClient.post(req.url, null);
    }

    absenteeFacets() {
        const req = { url: `${environment.API_URL}/connect/absenteeFacets` };
        return this.httpClient.get(req.url);
    }

    feeDefaultersFacet() {
        const req = { url: `${environment.API_URL}/connect/FeeDefaultersFacet` };
        return this.httpClient.get(req.url);
    }
    removeAttachment(file_name) {
        const req = { url: `${environment.API_URL}/arte/artefact/removeFile?FileName=` + file_name };
        return this.httpClient.post(req.url, null);
    }
    sendManualEmail(email_obj) {
        let headers = new HttpHeaders();
        let topic = "email.send";
        headers = headers.append('Topic', topic);
        const req = { url: `${environment.API_URL}/connect/email` };
        return this.httpClient.post(req.url, email_obj, { headers: headers, observe: 'response', responseType: 'text' });
    }

    sendParentEmail(email_obj) {
        let headers = new HttpHeaders();
        let topic = "email.send";
        headers = headers.append('Topic', topic);
        const req = { url: `${environment.API_URL}/stu/student/email/parent` };
        return this.httpClient
            .post(req.url, email_obj, { headers: headers, observe: 'response', responseType: 'text' });
    }

    sendStaffEmail(email_obj) {
        let headers = new HttpHeaders();
        let topic = "email.send";
        headers = headers.append('Topic', topic);
        const req = { url: `${environment.API_URL}/staff/email/staff` };
        return this.httpClient
            .post(req.url, email_obj, { headers: headers, observe: 'response', responseType: 'text' });
    }

    getEmail(qryStr) {
        const req = { url: `${environment.API_URL}/connect/EmailSent?` + qryStr };
        return this.httpClient
            .get(req.url);
    }

    generateMarksCard(payload) {
        const req = { url: `${environment.API_URL}/acad/exam/enqueMarksCardJob` };
        return this.httpClient.post(req.url, payload);
    }
    sendMarksCard(payload) {
        const req = { url: `${environment.API_URL}/acad/exam/enqueEmailMarksCardJob` };
        return this.httpClient.post(req.url, payload);
    }

    getStudentsForSection(examId) {
        const req = { url: `${environment.API_URL}/acad/exam/getStudents?examId=` + examId };
        return this.httpClient.get(req.url);
    }

    viewMarksCard(payload) {
        const req = { url: `${environment.API_URL}/arte/artefact/viewMarksCard` };
        return this.httpClient.post(req.url, payload);
    }
    initWF(type) {
        const req = { url: `${environment.API_URL}/connect/workflow/init?title=` + type };
        return this.httpClient.get(req.url);
    }
    wfJob(postData) {
        const req = { url: `${environment.API_URL}/connect/workflow` };
        return this.httpClient.post(req.url, postData);
    }

    getSMSTemplates() {
        const req = { url: `${environment.API_URL}/connect/SMSTemplate` };
        return this.httpClient.get(req.url);
    }
    addNewSMSTemplate(text) {
        var payload = { "Text": text };
        const req = { url: `${environment.API_URL}/connect/SMSTemplate` };
        return this.httpClient.post(req.url, payload);
    }

    enableSMSTemplate(id) {
        const req = { url: `${environment.API_URL}/connect/SMSTemplate/enable?id=` + id };
        return this.httpClient.post(req.url, null, { observe: 'response', responseType: 'text' });
    }
    disableSMSTemplate(id) {
        const req = { url: `${environment.API_URL}/connect/SMSTemplate/disable?id=` + id };
        return this.httpClient.post(req.url, null, { observe: 'response', responseType: 'text' });
    }
    approveSMSTemplate(id, reasons) {
        var payload = { "Id": id, "Reason": reasons };
        const req = { url: `${environment.API_URL}/connect/SMSTemplate/approve` };
        return this.httpClient.post(req.url, payload);
    }
    disapproveSMSTemplate(id, reason) {
        var payload = { "Id": id, "Reason": reason };
        const req = { url: `${environment.API_URL}/connect/SMSTemplate/disapprove` };
        return this.httpClient.post(req.url, payload);
    }

    // Receipt Reports
    printDetailedReport(postData) {
        const req = { url: `${environment.API_URL}/gr/report/getReceiptReports` };
        return this.httpClient
            .post(req.url, postData, { observe: 'response', responseType: 'text' });
    }

    printRevenueSharingReport(postData) {
        const req = { url: `${environment.API_URL}/gr/report/getReceiptRevenueSharingReport` };
        return this.httpClient
            .post(req.url, postData, { observe: 'response', responseType: 'text' });
    }

    printSummaryReportReceipts(postData) {
        const req = { url: `${environment.API_URL}/gr/report/getReceiptsSummary` };
        return this.httpClient
            .post(req.url, postData, { observe: 'response', responseType: 'text' });
    }

    // RC Reports
    printDepartmentReport(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/rc/report/departmentReport` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printAdmissionList(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/rc/report/printAdmissionList` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    // Asset Reports
    createAsset(trackingId, location) {
        const payload: any = { TrackingId: trackingId, Location: location };
        const req = { url: `${environment.API_URL}/asset` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' })
    }

    printLocationWiseAsset(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/asset/report/locationWiseAssets` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    setAttributes(id, attributes) {
        const payload: any = { "Id": id, "Attributes": attributes };
        const req = { url: `${environment.API_URL}/library/material/setAttributes` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    setTags(id, tags) {
        const payload: any = { "Id": id, "Tags": tags };
        const req = { url: `${environment.API_URL}/library/material/setTags` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    setValue(id, value) {
        const payload: any = { "Id": id, "Value": value };
        const req = { url: `${environment.API_URL}/library/material/setValue` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    updateMaterial(id, title, author, publisher) {
        const payload: any = { "Id": id, "Title": title, "Author": author, "Publisher": publisher };
        const req = { url: `${environment.API_URL}/library/material/update` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    updateISBN(id, isbn) {
        const payload: any = { "Id": id, "ISBN": isbn };
        const req = { url: `${environment.API_URL}/library/material/updateISBN` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    updateLocation(id, location) {
        const payload: any = { "Id": id, "Location": location };
        const req = { url: `${environment.API_URL}/library/material/relocate` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    verifyStock(id) {
        const req = { url: `${environment.API_URL}/library/material/verifyStock?id=` + id };
        return this.httpClient
            .post(req.url, null, { observe: 'response', responseType: 'text' });
    }

    setMaterialPages(id, pages) {
        const payload: any = { "Id": id, "Pages": pages };
        const req = { url: `${environment.API_URL}/library/material/setPages` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    getVerificationMaterial(barcodeValue) {
        const req = { url: `${environment.API_URL}/library/material/verificationMaterial?barcodeValue=` + barcodeValue };
        return this.httpClient
            .get(req.url);
    }

    getLibraryDueDate(date) {
        const req = { url: `${environment.API_URL}/scheduler/getLibraryDueDate?date=` + date };
        return this.httpClient
            .get(req.url);
    }

    // Fees
    createFee(fee) {
        const req = { url: `${environment.API_URL}/fee` };
        return this.httpClient.post(req.url, fee, { observe: 'response', responseType: 'text' })
    }

    updateFee(fee) {
        const req = { url: `${environment.API_URL}/fee/updateFee` };
        return this.httpClient.post(req.url, fee, { observe: 'response', responseType: 'text' })
    }

    canRemoveStudent(stu_id, fee_id) {
        const payload = { StudentId: stu_id, FeeId: fee_id };
        const req = { url: `${environment.API_URL}/fee/canRemoveStudent` };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' })
    }

    getFee() {
        const req = { url: `${environment.API_URL}/fee` };
        return this.httpClient.get(req.url);
    }
    getFeeInstallments(courseId) {
        const req = { url: `${environment.API_URL}/fee/feeInstFlattened?courseId=` + courseId };
        return this.httpClient.get(req.url);
    }
    getFeeById(id) {
        const req = { url: `${environment.API_URL}/fee?id=` + id };
        return this.httpClient.get(req.url);
    }
    disableFee(disable_fee_id) {
        const req = { url: `${environment.API_URL}/fee/disable?id=` + disable_fee_id };
        return this.httpClient.post(req.url, null);
    }
    enableFee(enable_fee_id) {
        const req = { url: `${environment.API_URL}/fee/enable?id=` + enable_fee_id };
        return this.httpClient.post(req.url, null);
    }

    getStudentDetails(id) {
        const req = { url: `${environment.API_URL}/fee/getStudentDetails?id=` + id };
        return this.httpClient.post(req.url, null);
    }

    getTxList(qrystring) {
        const req = { url: `${environment.API_URL}/feeTx?id=&` + qrystring };
        return this.httpClient.get(req.url);
    }
    getTxHtml(id) {
        const req = { url: `${environment.API_URL}/feeTx/getTxHTML?id=` + id };
        return this.httpClient.get(req.url);
    }
    getStudentTx(id) {
        const req = { url: `${environment.API_URL}/feeTx/studentTx?id=` + id };
        return this.httpClient.get(req.url);
    }
    transact(tx) {
        const payload: any = tx;
        const req = { url: `${environment.API_URL}/feeTx/transact` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    enableTx(id) {
        const req = { url: `${environment.API_URL}/feeTx/enable?id=` + id };
        return this.httpClient
            .post(req.url, null, { observe: 'response', responseType: 'text' });
    }
    disableTx(id) {
        const req = { url: `${environment.API_URL}/feeTx/disable?id=` + id };
        return this.httpClient
            .post(req.url, null, { observe: 'response', responseType: 'text' });
    }

    getRecentActivity() {
        var recAct: any = [
            {
                "Component": "/fees/edit",
                "Title": "Edit Fee",
                "Context": {
                    "SelectedFeeId": "fees/26-A"
                }
            }
        ]
        return recAct;
    }
    setRecentActivity(rec_act) {
        // console.log("from ctapi",rec_act);
    }

    // Fee Reports
    printBreakUpsReport(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": {} };
        const req = { url: `${environment.API_URL}/fee/report/getFeeBreakUpReport` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printDatewiseFeeCollectionReport(printOptions, from, to) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": { "From": from, "To": to } };
        const req = { url: `${environment.API_URL}/fee/report/datewiseFeeCollection` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printConcessionReport(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": {} };
        const req = { url: `${environment.API_URL}/fee/report/getConcessionReport` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printFeeDefaultersListReport_v1(printOptions, courseId, date, fees, showOpeningBalance) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": { "Date": date, "CourseId": courseId, "Fees": fees, "ShowOpeningBalance": showOpeningBalance } };
        const req = { url: `${environment.API_URL}/fee/report/feesDefaultersVersion1` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printFeeDefaultersListReport_v2(printOptions, courseId, date, fees, showOpeningBalance) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": { "Date": date, "CourseId": courseId, "Fees": fees, "ShowOpeningBalance": showOpeningBalance } };
        const req = { url: `${environment.API_URL}/fee/report/feesDefaultersVersion2` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printExpectedReport(printOptions, selectedRange, selectedValues, feeIds, merge) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": { "FeeIds": feeIds, "RangeType": selectedRange, "RangeValues": selectedValues, "MergeFees": merge } };
        const req = { url: `${environment.API_URL}/fee/report/feesExpected` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printFeesPaidListReport(printOptions, courseId, fees, fromDate, toDate) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": { "CourseId": courseId, "Fees": fees, "From": fromDate, "To": toDate } };
        const req = { url: `${environment.API_URL}/fee/report/feesPaid` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printFineCollectedReport(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": {} };
        const req = { url: `${environment.API_URL}/fee/report/getFineCollectedReport` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printLatePaymentReport(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": {} };
        const req = { url: `${environment.API_URL}/fee/report/getLatePaymentReport` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printReceiptTransactionReport(printOptions, from, to) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": { "From": from, "To": to } };
        const req = { url: `${environment.API_URL}/fee/report/receiptTransaction` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printPaymentModeBreakupReport(printOptions, from, to) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": { "From": from, "To": to } };
        const req = { url: `${environment.API_URL}/fee/report/paymentModeBreakup` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printFeeStructureReport(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/fee/report/feeStructure` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printCoursewiseFeeStructureReport(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/fee/report/coursewiseFeeStructure` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printStudentwiseTransactionReport(printOptions, studentId, selectedFeeList, allFees) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": { "StudentId": studentId, "FeeIds": selectedFeeList, "AllFees": allFees } };
        const req = { url: `${environment.API_URL}/fee/report/studentwiseTransaction` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printTimeLineReport(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": {} };
        const req = { url: `${environment.API_URL}/fee/report/getFeeTimeLineReport` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printAdjustmentTransactionReport(printOptions, courseId) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": { "CourseId": courseId } };
        const req = { url: `${environment.API_URL}/fee/report/adjustmentTransactions` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printAdjustmentBalanceReport(printOptions, courseId) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": { "CourseId": courseId } };
        const req = { url: `${environment.API_URL}/fee/report/adjustmentBalance` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printTransactionHistoryReport(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/fee/report/txHistory` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printAdvanceBalanceReport(printOptions, feeIds) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": { "FeeIds": feeIds } };
        const req = { url: `${environment.API_URL}/fee/report/advanceBalance` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printFeesPaidAfterAcadYearReport(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/fee/report/feesPaidAfterAcademicYear` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printFeesPaidByTCStudentsReport(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/fee/report/feesPaidByTCStudents` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    getRTEStudents(tags) {
        const req = { url: `${environment.API_URL}/stu/student/getStudentsByTag?tags=` + tags };
        return this.httpClient.get(req.url);
    }

    // Staff
    addStaff(payload) {
        // const payload = {
        //     "Name": staff.name,
        //     "Gender": staff.gender,
        //     "DOJ": staff.DOJ,
        //     "Email": staff.email,
        //     "Mobile": staff.mobile,
        //     "StaffType": staff.staffType,
        //     "Title": designation.title,
        //     "From": designation.from
        // };
        const req = { url: `${environment.API_URL}/staff` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    updateStaff(id, name, designation, DOB, DOJ, staffType) {
        // if (typeof (DOB) != "string") DOB = DOB.toDateString();
        // if (typeof (DOJ) != "string") DOJ = DOJ.toDateString();
        const payload =
        {
            "Id": id,
            "Name": name,
            "Designation": designation,
            "DOB": DOB,
            "DOJ": DOJ,
            "StaffType": staffType
        };
        const req = { url: `${environment.API_URL}/staff/update` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    updateStaffGender(id, gender) {
        var payload = { "Id": id, "Gender": gender };
        const req = { url: `${environment.API_URL}/staff/updateGender` };
        return this.httpClient.post(req.url, payload);
    }

    assign() {
        // const payload: any ;
        // const req = { url: `${environment.API_URL}/staff/assignSubjects` };
        // return this.httpClient
        //   .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    disableStaff(id) {
        const payload: any = { "Id": id };
        const req = { url: `${environment.API_URL}/staff/disable?id=` + id };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    enableStaff(id) {
        const payload: any = { "Id": id };
        const req = { url: `${environment.API_URL}/staff/enable?id=` + id };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    getStaff() {
        const req = { url: `${environment.API_URL}/staff` };
        return this.httpClient
            .get(req.url);
    }

    updateEmail(id, email) {
        const payload: any = { "Id": id, "Email": email };
        const req = { url: `${environment.API_URL}/staff/setPrimaryEmail` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    updateMobile(id, mobile) {
        const payload: any = { "Id": id, "Mobile": mobile };
        const req = { url: `${environment.API_URL}/staff/setPrimaryMobile` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    updateStaffRefId(id, staffRefId) {
        var payload = { Id: id, StaffRefId: staffRefId };
        const req = { url: `${environment.API_URL}/staff/updateStaffRefId` };
        return this.httpClient.post(req.url, payload);
    }

    staffCache() {
        const url = `${environment.API_URL}/staff/cache`;
        return this.httpClient
            .post(url, null, { observe: 'response', responseType: 'text' })
    }

    // Student
    enrolStudent(student) {
        const payload = student;
        const req = { url: `${environment.API_URL}/stu/student` };
        return this.httpClient
            .post(req.url, payload);
    }
    printMarksCard(stuPostData) {
        const payload: any = stuPostData;
        const req = { url: `${environment.API_URL}/acad/report/GetMarksCard` };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    getAbsenteeSMSRecipientCount(per) {
        const req = { url: `${environment.API_URL}/stu/student/absenteesRecipientCount?per=` + per };
        return this.httpClient.get(req.url);
    }

    getStudents(activeStudentsOnly, todaysAbsentees, per, qStr) {
        var req;
        var urlStr = `${environment.API_URL}/stu/student?activeStudentsOnly=` + activeStudentsOnly + `&todaysAbsenteesOnly=` + todaysAbsentees;
        if (qStr) {
            urlStr = urlStr + '&' + qStr;
        }
        if (per) {
            urlStr = urlStr + '&per=' + per;
        }
        // else {
        //   req = { url: `${environment.API_URL}/stu/student?activeStudentsOnly=` + activeStudentsOnly + `&todaysAbsenteesOnly=` + todaysAbsentees };
        // }
        req = { url: urlStr };
        return this.httpClient.get(req.url);
    }
    getStuById(id) {
        var req;
        req = { url: `${environment.API_URL}/stu/student?id=` + id };
        return this.httpClient.get(req.url);
    }
    getAllStudents(qry) {
        var req;
        req = { url: `${environment.API_URL}/stu/student/all?includeRTE=` + qry };
        return this.httpClient.get(req.url);
    }

    getStudentsForExamFacet(courseId) {
        var req;
        req = { url: `${environment.API_URL}/stu/student/getStudentsForExamFacets?courseId=` + courseId };
        return this.httpClient.get(req.url);
    }

    disableStudent(id) {
        const payload: any = { "Id": id };
        const req = { url: `${environment.API_URL}/stu/student/disable?id=` + id };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    enableStudent(id) {
        const payload: any = { "Id": id };
        const req = { url: `${environment.API_URL}/stu/student/enable?id=` + id };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }


    updateHomePhone(payload) {
        const req = { url: `${environment.API_URL}/stu/student/setHomePhone` };
        return this.httpClient.post(req.url, payload);
    }

    updateStudentAddress(payload) {
        const req = { url: `${environment.API_URL}/stu/student/setHomeAddress` };
        return this.httpClient
            .post(req.url, payload);
    }

    updateStudentDetails(payload) {
        const req = { url: `${environment.API_URL}/stu/student/update` };
        return this.httpClient.post(req.url, payload);
    }

    updateFatherDetails(fatherDetails) {
        const req = { url: `${environment.API_URL}/stu/student/updateFatherDetails` };
        return this.httpClient.post(req.url, fatherDetails);
    }

    updateMotherDetails(motherDetails) {
        const req = { url: `${environment.API_URL}/stu/student/updateMotherDetails` };
        return this.httpClient.post(req.url, motherDetails);
    }

    updateGuardianDetails(guardianDetails) {
        const req = { url: `${environment.API_URL}/stu/student/updateGuardianDetails` };
        return this.httpClient.post(req.url, guardianDetails);
    }

    updateStudentEmail(payload) {
        const req = { url: `${environment.API_URL}/stu/student/setPrimaryEmail` };
        return this.httpClient.post(req.url, payload);
    }

    updateStudentRollNo(payload) {
        const req = { url: `${environment.API_URL}/stu/student/setRollNo` };
        return this.httpClient.post(req.url, payload);
    }

    updateStudentMobile(payload) {
        const req = { url: `${environment.API_URL}/stu/student/setPrimaryMobile` };
        return this.httpClient.post(req.url, payload);
    }

    getStu(qStr) {
        var req;
        if (qStr) {
            req = { url: `${environment.API_URL}/stu/student/getStu?` + qStr };
        }
        else {
            req = { url: `${environment.API_URL}/stu/student` };
        }
        return this.httpClient.get(req.url);
    }

    updateTags(tags, id) {
        var payload = {
            "Id": id,
            "Tags": tags
        }
        const req = { url: `${environment.API_URL}/stu/student/updateTags` };
        return this.httpClient.post(req.url, payload);
    }

    updateCourseForStu(id, courseName, courseId, secName, branch) {
        var payload = { "Id": id, "CourseName": courseName, "CourseId": courseId, "Section": secName, "Branch": branch };
        const req = { url: `${environment.API_URL}/stu/student/updateCourse` };
        return this.httpClient.post(req.url, payload);
    }

    updateAttendance(id, courseName, attendance) {
        var payload = { "Id": id, "CourseName": courseName, "Attendance": attendance };
        const req = { url: `${environment.API_URL}/stu/student/updateAttendance` };
        return this.httpClient.post(req.url, payload);
    }

    updateGender(id, gender) {
        var payload = { "Id": id, "Gender": gender };
        const req = { url: `${environment.API_URL}/stu/student/updateGender` };
        return this.httpClient.post(req.url, payload);
    }

    getFilters() {
        const req = { url: `${environment.API_URL}/acad/course/getFilters` };
        return this.httpClient.get(req.url);
    }

    applyAttnEvents(attendanceEvents) {
        const req = { url: `${environment.API_URL}/stu/student/applyEvents` };
        return this.httpClient.post(req.url, attendanceEvents);
    }
    assignStudentId(id, studentId) {
        var payload = { Id: id, StudentId: studentId };
        const req = { url: `${environment.API_URL}/stu/student/assignStudentId` };
        return this.httpClient.post(req.url, payload);
    }
    issueTc(id) {
        const req = { url: `${environment.API_URL}/stu/student/tc?id=` + id };
        return this.httpClient.post(req.url, null, { observe: 'response', responseType: 'text' });
    }

    disableFatherEmail(id) {
        const req = { url: `${environment.API_URL}/stu/student/disableFatherEmail?id=` + id };
        return this.httpClient
            .post(req.url, null, { observe: 'response', responseType: 'text' });
    }
    enableFatherEmail(id) {
        const req = { url: `${environment.API_URL}/stu/student/enableFatherEmail?id=` + id };
        return this.httpClient
            .post(req.url, null, { observe: 'response', responseType: 'text' });
    }

    disableMotherEmail(id) {
        const req = { url: `${environment.API_URL}/stu/student/disableMotherEmail?id=` + id };
        return this.httpClient
            .post(req.url, null, { observe: 'response', responseType: 'text' });
    }
    enableMotherEmail(id) {
        const req = { url: `${environment.API_URL}/stu/student/enableMotherEmail?id=` + id };
        return this.httpClient
            .post(req.url, null, { observe: 'response', responseType: 'text' });
    }

    disableGuardianEmail(id) {
        const req = { url: `${environment.API_URL}/stu/student/disableGuardianEmail?id=` + id };
        return this.httpClient
            .post(req.url, null, { observe: 'response', responseType: 'text' });
    }
    enableGuardianEmail(id) {
        const req = { url: `${environment.API_URL}/stu/student/enableGuardianEmail?id=` + id };
        return this.httpClient
            .post(req.url, null, { observe: 'response', responseType: 'text' });
    }

    disableFatherMobile(id) {
        const req = { url: `${environment.API_URL}/stu/student/disableFatherMobile?id=` + id };
        return this.httpClient
            .post(req.url, null, { observe: 'response', responseType: 'text' });
    }
    enableFatherMobile(id) {
        const req = { url: `${environment.API_URL}/stu/student/enableFatherMobile?id=` + id };
        return this.httpClient
            .post(req.url, null, { observe: 'response', responseType: 'text' });
    }

    disableMotherMobile(id) {
        const req = { url: `${environment.API_URL}/stu/student/disableMotherMobile?id=` + id };
        return this.httpClient
            .post(req.url, null, { observe: 'response', responseType: 'text' });
    }
    enableMotherMobile(id) {
        const req = { url: `${environment.API_URL}/stu/student/enableMotherMobile?id=` + id };
        return this.httpClient
            .post(req.url, null, { observe: 'response', responseType: 'text' });
    }

    disableGuardianMobile(id) {
        const req = { url: `${environment.API_URL}/stu/student/disableGuardianMobile?id=` + id };
        return this.httpClient
            .post(req.url, null, { observe: 'response', responseType: 'text' });
    }
    enableGuardianMobile(id) {
        const req = { url: `${environment.API_URL}/stu/student/enableGuardianMobile?id=` + id };
        return this.httpClient
            .post(req.url, null, { observe: 'response', responseType: 'text' });
    }

    setStudentMedicalDiagnosisRpt(payload) {
        const req = { url: `${environment.API_URL}/stu/student/medicalDiagnosisReport` };
        return this.httpClient.post(req.url, payload);
    }

    setBirthCertificate(payload) {
        const req = { url: `${environment.API_URL}/stu/student/birthCertificate` };
        return this.httpClient.post(req.url, payload);
    }

    setDisabilityRpt(payload) {
        const req = { url: `${environment.API_URL}/stu/student/disabilityReport` };
        return this.httpClient.post(req.url, payload);
    }

    setIdentificationProof(payload) {
        const req = { url: `${environment.API_URL}/stu/student/identificationProof` };
        return this.httpClient.post(req.url, payload);
    }

    setAddressProof(payload) {
        const req = { url: `${environment.API_URL}/stu/student/addressProof` };
        return this.httpClient.post(req.url, payload);
    }

    setTherapyReport(payload) {
        const req = { url: `${environment.API_URL}/stu/student/therapyReport` };
        return this.httpClient.post(req.url, payload);
    }

    setPsycologicalAssessment(payload) {
        const req = { url: `${environment.API_URL}/stu/student/psycologicalAssessmentReport` };
        return this.httpClient.post(req.url, payload);
    }

    setStudentPhotoUrl(payload) {
        const req = { url: `${environment.API_URL}/stu/student/photo` };
        return this.httpClient.post(req.url, payload);
    }

    //Env
    getEnv(qrystring) {
        const req = { url: `${environment.API_URL}/montessori/environment?` + qrystring };
        return this.httpClient
            .get(req.url);
    }
    addEnv(newEnv) {
        const payload: any = { Name: newEnv.name };
        const req = { url: `${environment.API_URL}/montessori/environment` };
        return this.httpClient.post(req.url, payload);
    }
    updateEnv(env) {
        const payload: any = { Name: env.name };
        const req = { url: `${environment.API_URL}/montessori/environment` };
        return this.httpClient.post(req.url, payload);
    }
    disableEnv(id) {
        const payload: any = null;
        const req = { url: `${environment.API_URL}/montessori/environment/disable` + '?id=' + id };
        return this.httpClient.post(req.url, payload);
    }
    enableEnv(id) {
        const payload: any = null;
        const req = { url: `${environment.API_URL}/montessori/environment/enable` + '?id=' + id };
        return this.httpClient.post(req.url, payload);
    }

    //Activity
    getActivity(qrystring) {
        const req = { url: `${environment.API_URL}/montessori/activity?` + qrystring };
        return this.httpClient
            .get(req.url);
    }
    addActivity(newAct) {
        const payload: any = { name: newAct.name, children: newAct.children };
        const req = { url: `${environment.API_URL}/montessori/activity` };
        return this.httpClient.post(req.url, payload);
    }
    updateActivity(act) {
        const payload: any = { id: act.id, name: act.name, children: act.children };
        const req = { url: `${environment.API_URL}/montessori/activity` };
        return this.httpClient.post(req.url, payload);
    }
    disableActivity(id) {
        const payload: any = null;
        const req = { url: `${environment.API_URL}/montessori/activity/disable` + '?id=' + id };
        return this.httpClient.post(req.url, payload);
    }
    enableActivity(id) {
        const payload: any = null;
        const req = { url: `${environment.API_URL}/montessori/activity/enable` + '?id=' + id };
        return this.httpClient.post(req.url, payload);
    }

    //Workbook
    getWorkbook(stu, act) {
        const req = { url: `${environment.API_URL}/montessori/workbook?student=` + stu + `&activity=` + act };
        return this.httpClient
            .get(req.url);
    }
    saveWorkbook(wb) {
        const payload: any = wb;
        const req = { url: `${environment.API_URL}/montessori/workbook` };
        return this.httpClient.post(req.url, payload);
    }

    //Exam
    getExams(qrystring = "", status = "") {
        const req = { url: `${environment.API_URL}/acad/exam?` + qrystring + `&status=` + status };
        return this.httpClient.get(req.url);
    }
    getExamsForMarkscard(qrystring = "", status = "") {
        const req = { url: `${environment.API_URL}/acad/exam/getExams?` + qrystring + `status=` + status };
        return this.httpClient.get(req.url);
    }
    getAllExams() {
        const req = { url: `${environment.API_URL}/acad/exam?$skip=0&$top=256` };
        return this.httpClient.get(req.url);
    }
    getExamsCoursewise() {
        const req = { url: `${environment.API_URL}/acad/exam/getExamsCoursewise` };
        return this.httpClient.get(req.url);
    }
    disableExam(id) {
        const req = { url: `${environment.API_URL}/acad/exam/disable?id=` + id };
        return this.httpClient.post(req.url, null);
    }
    enableExam(id) {
        const req = { url: `${environment.API_URL}/acad/exam/enable?id=` + id };
        return this.httpClient.post(req.url, null);
    }
    lockExam(id, reason) {
        const payload: any = { Reason: reason };
        const req = { url: `${environment.API_URL}/acad/exam/lockExam?id=` + id };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    };
    unlockExam(id, reason) {
        const payload: any = { Reason: reason };
        const req = { url: `${environment.API_URL}/acad/exam/unlockExam?id=` + id };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    };
    forStudent(stuObj) {
        const payload: any = stuObj;
        const req = { url: `${environment.API_URL}/acad/exam/ForStudent` };
        return this.httpClient.post(req.url, payload);
    };
    getGradingDetails() {
        const req = { url: `${environment.API_URL}/acad/exam/getGradingDetails` };
        // const req = { url: `${environment.API_URL}/acad/grading/getGradingDetails` };
        return this.httpClient.get(req.url);
    }
    enableGrading() {
        const req = { url: `${environment.API_URL}/acad/grading/enableGrading` };
        return this.httpClient.post(req.url, null);
    }
    disableGrading() {
        const req = { url: `${environment.API_URL}/acad/grading/disableGrading` };
        return this.httpClient.post(req.url, null);
    }
    setGradingRules(gradingRules) {
        const payload: any = { GradingRules: gradingRules };
        const req = { url: `${environment.API_URL}/acad/grading/setGradingRules` };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    createExam(exam, courses) {
        const payload: any = { Name: exam.Name, Name1: exam.Name1, Name2: exam.Name2, StartDate: exam.StartDate, SplitExam: exam.SplitExam, Courses: courses };
        const req = { url: `${environment.API_URL}/acad/exam/createExam` };
        return this.httpClient.post(req.url, payload);
    }
    renameExam(id, name, newName) {
        const req = { url: `${environment.API_URL}/acad/exam/renameExam?id=` + id + `&name=` + name + `&newName=` + newName };
        return this.httpClient.post(req.url, null);
    }
    setTotalMaxMarks(id, totalMaxMarks) {
        const payload: any = { TotalMaxMarks: totalMaxMarks };
        const req = { url: `${environment.API_URL}/acad/exam/SetTotalMaxMarks?id=` + id };
        return this.httpClient.post(req.url, payload);
    }
    setDaysWorked(id, daysWorked) {
        const payload: any = { DaysWorked: daysWorked };
        const req = { url: `${environment.API_URL}/acad/exam/setDaysWorked?id=` + id };
        return this.httpClient.post(req.url, payload);
    }
    getExamContents(id) {
        const req = { url: `${environment.API_URL}/acad/exam/getExamContents?id=` + id };
        return this.httpClient.get(req.url);
    }
    setSubjectGrading(id, item) {
        const payload: any = { SubjectName: item.Subject, GradingOnly: item.GradingOnly };
        const req = { url: `${environment.API_URL}/acad/exam/subjectGrading?id=` + id };
        return this.httpClient.post(req.url, payload);
    };
    updateMaxMarks(id, item) {
        const payload: any = { SubjectName: item.Subject, MaxMarks: item.MaxMarks };
        const req = { url: `${environment.API_URL}/acad/exam/setMaxMarks?id=` + id };
        return this.httpClient.post(req.url, payload);
    };
    updateMinMarks(id, item) {
        const payload: any = { SubjectName: item.Subject, MinMarks: item.MinMarks };
        const req = { url: `${environment.API_URL}/acad/exam/setMinMarks?id=` + id };
        return this.httpClient.post(req.url, payload);
    };
    addChildSubject(id, sub) {
        const payload: any = { SubjectName: sub };
        const req = { url: `${environment.API_URL}/acad/exam/addChildSubject?id=` + id };
        return this.httpClient.post(req.url, payload);
    };
    removeChildSubject(id, sub) {
        const payload: any = { SubjectName: sub };
        const req = { url: `${environment.API_URL}/acad/exam/removeChildSubject?id=` + id };
        return this.httpClient.post(req.url, payload);
    };
    setScheduledOn(id, item) {
        var so;
        // if(item.ScheduledOn){ so = item.ScheduledOn.local(); }
        if (item.ScheduledOn) {
            var dt = new Date(item.ScheduledOn);
            so = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate();
        }
        else { so = null; }
        const payload: any = { SubjectName: item.Subject, ScheduledOn: so };
        const req = { url: `${environment.API_URL}/acad/exam/setScheduledOn?id=` + id };
        return this.httpClient.post(req.url, payload);
    };
    setConductedOn(id, item) {
        var co;
        // if(item.ConductedOn){ co = item.ConductedOn.local(); }
        if (item.ConductedOn) {
            var dt = new Date(item.ConductedOn);
            co = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate();
        }
        else { co = null; }
        const payload: any = { SubjectName: item.Subject, ConductedOn: co };
        const req = { url: `${environment.API_URL}/acad/exam/setConductedOn?id=` + id };
        return this.httpClient.post(req.url, payload);
    };
    getStudentsToEvaluate(id) {
        const req = { url: `${environment.API_URL}/acad/exam/getStudentsToEvaluate?id=` + id };
        return this.httpClient.get(req.url);
    }
    setMarksObtained(id, subject, student) {
        let headers = new HttpHeaders();
        let topic = "exam.evaluate";
        headers = headers.append('Topic', topic);
        var dt = subject.EvaluatedOn;
        var formattedDate = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate();
        const payload: any = { SubjectName: subject.Subject, StudentId: student.StudentId, MarksObtained: subject.MarksObtained, EvaluatedOn: formattedDate };
        const req = { url: `${environment.API_URL}/acad/exam/evaluate?id=` + id };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    setGradeObtained(id, subject, student) {
        var dt = subject.EvaluatedOn;
        var formattedDate = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate();
        const payload: any = { SubjectName: subject.Subject, StudentId: student.StudentId, GradeObtained: subject.Grade, EvaluatedOn: formattedDate };
        const req = { url: `${environment.API_URL}/acad/exam/evaluate?id=` + id };
        return this.httpClient.post(req.url, payload);
    }
    setAttendance(id, subject, student, status) {
        var eo = new Date();
        const payload: any = { SubjectName: subject, StudentId: student.StudentId, Status: status, EvaluatedOn: eo };
        const req = { url: `${environment.API_URL}/acad/exam/attendance?id=` + id };
        return this.httpClient.post(req.url, payload);
    }
    applyEvents(eventList, id) {
        var eo = new Date();
        const payload: any = eventList;
        const req = { url: `${environment.API_URL}/acad/exam/applyEventsAsync?id=` + id };
        return this.httpClient.post(req.url, payload);
    }

    setDaysPresent(id, student, daysPresent) {
        var eo = new Date();
        const payload: any = { StudentId: student.StudentId, DaysPresent: daysPresent, EvaluatedOn: eo };
        const req = { url: `${environment.API_URL}/acad/exam/daysPresent?id=` + id };
        return this.httpClient.post(req.url, payload);
    }

    setRemarks(id, student, remarks) {
        var eo = new Date();
        const payload: any = { StudentId: student.StudentId, Remarks: remarks, EvaluatedOn: eo };
        const req = { url: `${environment.API_URL}/acad/exam/remarks?id=` + id };
        return this.httpClient.post(req.url, payload);
    }

    setExamResultDate(payload) {
        const req = { url: `${environment.API_URL}/acad/exam/setResultDate` };
        return this.httpClient.post(req.url, payload);
    }

    // Course
    addNewCourse(newCourse) {
        const req = { url: `${environment.API_URL}/acad/course` };
        return this.httpClient.post(req.url, newCourse);
    }

    getCourses() {
        const req = { url: `${environment.API_URL}/acad/course` };
        return this.httpClient.get(req.url);
    }

    updateCourse(course) {
        const req = { url: `${environment.API_URL}/acad/course/updateCourse` };
        return this.httpClient.post(req.url, course);
    }

    disableCourse(course_id) {
        const req = { url: `${environment.API_URL}/acad/course/disable?id=` + course_id };
        return this.httpClient.post(req.url, null);
    }

    enableCourse(course_id) {
        const req = { url: `${environment.API_URL}/acad/course/enable?id=` + course_id };
        return this.httpClient.post(req.url, null);
    }

    getOnQryCourse(qrystring) {
        const req = { url: `${environment.API_URL}/acad/course?` + qrystring };
        return this.httpClient.get(req.url);
    }

    newTerm(name, from, to, course_id) {
        //end point
        const req = { url: `${environment.API_URL}/acad/course/newTerm` };
        //payload
        var term = {
            "Name": name,
            "From": from,
            "To": to,
            "Id": course_id
        }
        return this.httpClient.post(req.url, term);
    }

    removeTerm(course_id, term_name) {
        const req = { url: `${environment.API_URL}/acad/course/removeTerm?id=` + course_id + "&termName=" + term_name };
        return this.httpClient.post(req.url, null);
    }

    removeSection(course_id, term_name, sec_name) {
        const req = { url: `${environment.API_URL}/acad/course/removeSection?id=` + course_id + "&termName=" + term_name + "&secName=" + sec_name };
        return this.httpClient.post(req.url, null);
    }

    cloneCourse(clone) {
        const req = { url: `${environment.API_URL}/acad/course/clone` };
        return this.httpClient.post(req.url, clone);
    }

    renameTerm(newName, oldName, course_id) {
        const req = { url: `${environment.API_URL}/acad/course/renameTerm` };
        var payload = {
            "NewName": newName,
            "OldName": oldName,
            "Id": course_id
        };
        return this.httpClient.post(req.url, payload);
    }

    updateTerm(name, from, to, course_id) {
        const req = { url: `${environment.API_URL}/acad/course/updateTerm` };
        var payload = {
            "Name": name,
            "From": from,
            "To": to,
            "Id": course_id
        };
        return this.httpClient.post(req.url, payload);
    }

    addNewSection(sectionName, course_id, termName) {
        const req = { url: `${environment.API_URL}/acad/course/addSection` };
        var payload = {
            "SectionName": sectionName,
            "Id": course_id,
            "TermName": termName,
        };
        return this.httpClient.post(req.url, payload);
    }

    updateSection(newName, oldName, termName, course_id) {
        const req = { url: `${environment.API_URL}/acad/course/updateSection` };
        var payload = {
            "NewName": newName,
            "OldName": oldName,
            "Id": course_id,
            "TermName": termName,
        };
        return this.httpClient.post(req.url, payload);
    }

    addNewSubject(name, alias, course_id, termName, sectionName) {
        const req = { url: `${environment.API_URL}/acad/course/addSubject` };
        var payload = {
            "Name": name,
            "Alias": alias,
            "Id": course_id,
            "TermName": termName,
            "SectionName": sectionName
        };
        return this.httpClient.post(req.url, payload);

    }

    removeSubject(subjectName, sectionName, course_id, termName) {
        const req = { url: `${environment.API_URL}/acad/course/removeSubject` };
        var payload = {
            "SubjectName": subjectName,
            "Id": course_id,
            "TermName": termName,
            "SectionName": sectionName
        };
        return this.httpClient.post(req.url, payload);
    }

    setExamSubOrder(examSubOrderString, course_id) {
        const req = { url: `${environment.API_URL}/acad/course/setExamSubjectOrder?id=` + course_id };
        var payload = examSubOrderString;
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    getExamFacet() {
        const req = { url: `${environment.API_URL}/acad/exam/facet` };
        return this.httpClient.get(req.url);
    }

    getCourseFacet() {
        const req = { url: `${environment.API_URL}/acad/course/facet` };
        return this.httpClient.get(req.url);
    }

    // Attendance
    getAbsentees(instId, courseId, termName, secName, period, date) {
        const req = { url: `${environment.API_URL}/acad/attendance/absentees?InstId=` + instId + "&CourseId=" + courseId + "&TermName=" + termName + "&SecName=" + secName + "&Period=" + period + "&Date=" + date };
        return this.httpClient.get(req.url);
    }

    getAbsenteeList() {
        const req = { url: `${environment.API_URL}/acad/attendance/absenteeList` };
        return this.httpClient.get(req.url);
    }

    //GR
    getReceipts(qrystring) {
        const req = { url: `${environment.API_URL}/gr/gr` + '?' + qrystring };
        return this.httpClient
            .get(req.url);
    }
    activateReceipt(id) {
        const req = { url: `${environment.API_URL}/gr/gr/activate?id=` + id };
        return this.httpClient.post(req.url, null, { observe: 'response', responseType: 'text' });
    }
    cancelReceipt(id) {
        const req = { url: `${environment.API_URL}/gr/gr/cancel?id=` + id };
        return this.httpClient.post(req.url, null, { observe: 'response', responseType: 'text' });
    }
    getReceiptView(receipt) {
        var include80G;
        if (receipt.meta) { include80G = receipt.meta.include80G || false; }
        else { include80G = false; }
        var meta = { include80G: include80G };
        var payload = { Meta: meta };
        const req = { url: `${environment.API_URL}/gr/gr/printReceipt?id=` + receipt.id };
        return this.httpClient.post(req.url, payload);
    }
    generateReceipt(newReceipt) {
        var data = {
            Date: newReceipt.Date,
            Customer: newReceipt.Customer,
            OrderItems: newReceipt.OrderItems,
            PaymentMode: newReceipt.PaymentMode,
            ReceiptType: newReceipt.ReceiptTypeName,
            RevenueSharingEnabled: newReceipt.RevenueSharingEnabled || false,
            RevenueShare: newReceipt.RevenueShare,
            ReceivedBy: newReceipt.ReceivedBy,
        };
        data.OrderItems.forEach(ele => {
            delete ele.Add;
            delete ele.Edit;
        });
        var dat = data.Date;
        // var formatDate = dat.getFullYear() + '-' + (dat.getMonth() + 1) + '-' + dat.getDate();
        var formatDate = new Date(dat).toISOString();
        data.Date = formatDate;
        var meta = { Include80G: newReceipt.Meta.Include80G };
        var payload = { Data: data, Meta: meta };
        if (newReceipt.PaymentMode != 10 && newReceipt.FinancialInstrument != null) {
            payload.Data['FinancialInstrument'] = newReceipt.FinancialInstrument;
            var dt = payload.Data['FinancialInstrument'].DrawnOn;
            var formattedDate = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate();
            payload.Data['FinancialInstrument'].DrawnOn = formattedDate;
        }
        const req = { url: `${environment.API_URL}/gr/gr` };
        return this.httpClient.post(req.url, payload);
    }
    updateReceipt(id, date, cust, incl80g, rcpt) {
        var data = {
            receiptDate: date,
            customer: cust,
            receiptType: rcpt.receiptTypeName,
            revenueSharingEnabled: rcpt.revenueSharingEnabled || false,
            revenueShare: rcpt.revenueShare || ""
        };
        var meta = { include80G: incl80g };
        var dat = new Date(data.receiptDate);
        var formatDate = dat.getFullYear() + '-' + (dat.getMonth() + 1) + '-' + dat.getDate();
        data.receiptDate = formatDate;
        var payload = { data: data, meta: meta };
        const req = { url: `${environment.API_URL}/gr/gr/update?id=` + id };
        return this.httpClient.post(req.url, payload);
    }
    updateOrderItemParticular(id, newParticular, oldParticular, amount) {
        var data = { oldParticular: oldParticular, newParticular: newParticular, amount: amount };
        var payload = { data: data };
        const req = { url: `${environment.API_URL}/gr/gr/updateOrderItemParticular?id=` + id };
        return this.httpClient.post(req.url, payload);
    }
    updateOrderItemAmount(id, newAmount, oldAmount, particular) {
        var data = { oldAmount: oldAmount, newAmount: newAmount, particular: particular };
        var payload = { data: data };
        const req = { url: `${environment.API_URL}/gr/gr/updateOrderItemAmount?id=` + id };
        return this.httpClient.post(req.url, payload);
    }
    updateOrderItemExtras(id, fieldName, fieldValues, amount, particular) {
        var data = {
            particular: particular,
            amount: amount,
            extraFieldName: fieldName,
            extraFieldValues: fieldValues
        };
        var payload = { data: data };
        const req = { url: `${environment.API_URL}/gr/gr/updateOrderItemExtras?id=` + id };
        return this.httpClient.post(req.url, payload);
    }
    updatePaymentDetails(id, rcpt) {
        var data = { paymentMode: rcpt.paymentMode };
        if (rcpt.paymentMode != '10' && rcpt.financialInstrument != null) {
            data['financialInstrument'] = rcpt.financialInstrument;
            var dt = new Date(data['financialInstrument'].drawnOn);
            var formattedDate = dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate();
            data['financialInstrument'].drawnOn = formattedDate;
        }
        else { data['financialInstrument'] = null; }
        var payload = { data: data };
        const req = { url: `${environment.API_URL}/gr/gr/updatePaymentDetails?id=` + id };
        return this.httpClient.post(req.url, payload);
    }

    updateUserDetails(postData) {
        const req = { url: `${environment.API_URL}/auth/user/updateUserDetails` };
        return this.httpClient.post(req.url, postData);
    }

    setupInstitute(postData) {
        const req = { url: `${environment.API_URL}/auth/institute/setup` };
        return this.httpClient.post(req.url, postData);
    }

    verifySMSCode(postData) {
        const req = { url: `${environment.API_URL}/auth/user/verifySMSOTP` };
        return this.httpClient.post(req.url, postData);
    }

    sendVerificationCode(postData) {
        const req = { url: `${environment.API_URL}/auth/user/sendSMSOTP` };
        return this.httpClient.post(req.url, postData);
    }

    createLead(postData) {
        const req = { url: `${environment.API_URL}/auth/lead` };
        return this.httpClient.post(req.url, postData);
    }

    getLeadToken(postData) {
        const req = { url: `${environment.API_URL}/auth/token/lead` };
        return this.httpClient.post(req.url, postData, { observe: 'response', responseType: 'text' });
    }

    getApplicantOTP(postData) {
        let payload = { Id: postData.id, Title: postData.title, Name: postData.name, ClientId: kes_client_id, Mobile: postData.mobile, Email: postData.email }
        let headers = new HttpHeaders();
        headers = headers.append('ClientId', kes_client_id);
        const req = { url: `${environment.API_URL}/admission/anon/otp` };
        return this.httpClient.post(req.url, payload, { headers: headers, observe: 'response', responseType: 'text' });
    }

    getDashboardToken() {
        const req = { url: `${environment.API_URL}/auth/token/ctdash` };
        return this.httpClient.get(req.url, { observe: 'response', responseType: 'text' });
    }

    registerAnon(email, clientId) {
        var payload = { "Email": email, "InstId": clientId }
        const req = { url: `${environment.API_URL}/auth/anon/registerAnon` };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    registerApplicant(postData) {
        let payload = { Id: postData.id, Title: postData.title, Name: postData.name, ClientId: kes_client_id, Mobile: postData.mobile, Email: postData.email, EmailOTP: postData.otpEmail, SMSOTP: postData.otpMobile }
        let headers = new HttpHeaders();
        headers = headers.append('ClientId', kes_client_id);
        const req = { url: `${environment.API_URL}/auth/anon/applicationRegister` };
        return this.httpClient.post(req.url, payload, { headers: headers, observe: 'response', responseType: 'text' });
    }

    registerApplicant2(payload) {
        const req = { url: `${environment.API_URL}/admission/anon/registration` };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    verify(postData) {
        const req = { url: `${environment.API_URL}/auth/lead/verify` };
        return this.httpClient.post(req.url, postData, { observe: 'response', responseType: 'text' });
    }
    resendVerCode(id) {
        const req = { url: `${environment.API_URL}/auth/lead/update?id=` + id };
        return this.httpClient.post(req.url, null, { observe: 'response', responseType: 'text' });
    }

    // Calendar
    addCalendarRule(postData) {
        const req = { url: `${environment.API_URL}/scheduler/calendar/addCalendarRule` };
        return this.httpClient.post(req.url, postData, { observe: 'response', responseType: 'text' });
    }
    removeCalendarRule(postData) {
        const req = { url: `${environment.API_URL}/scheduler/calendar/removeCalendarRule` };
        return this.httpClient.post(req.url, postData, { observe: 'response', responseType: 'text' });
    }
    removeOccurence(postData) {
        const req = { url: `${environment.API_URL}/scheduler/calendar/removeOccurrence` };
        return this.httpClient.post(req.url, postData, { observe: 'response', responseType: 'text' });
    }
    generateCalendar() {
        const req = { url: `${environment.API_URL}/scheduler/calendar/generateCalendar` };
        return this.httpClient.post(req.url, null, { observe: 'response', responseType: 'text' });
    }
    getCalendarEvents() {
        const req = { url: `${environment.API_URL}/scheduler/calendar/getCalendarEvents` };
        return this.httpClient.get(req.url, { observe: 'response', responseType: 'text' });
    }
    getCalendarRules() {
        const req = { url: `${environment.API_URL}/scheduler/calendar` };
        return this.httpClient.get(req.url, { observe: 'response', responseType: 'text' });
    }

    // Timetable planner 
    enque_job_to_generate_tt(csv, constraints) {
        const payload: any = { "Data": { "csv": csv, "constraints": constraints } };
        const req = { url: `${environment.API_URL}/acad/ttPlanner/generate` };
        return this.httpClient.post(req.url, payload);
    }
    enque_job_to_get_csv_data_for_tt(postData) {
        const payload: any = { "Data": postData };
        const req = { url: `${environment.API_URL}/acad/ttPlanner/run` };
        return this.httpClient.post(req.url, payload);
    }

    printTimetable(pivot, cells, ttType, printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": { "Pivot": pivot, "TTData": cells, "TimetableType": ttType } };
        const req = { url: `${environment.API_URL}/acad/ttPlanner/report/timetable` };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    cancelTTComputations(timetableId) {
        const payload: any = { "Data": { "TimetableId": timetableId } };
        const req = { url: `${environment.API_URL}/acad/ttPlanner/cancel` };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    // Timetable
    getTimeTablePeriodwise(crs) {
        const req = { url: `${environment.API_URL}/acad/course/getTimeTablePeriodwise?id=` + crs.id + `&termName=` + crs.termName + `&secName=` + crs.secName };
        return this.httpClient.get(req.url, { observe: 'response', responseType: 'text' });
    }
    saveTimeTable(courseId, termName, secName, result) {
        const payload: any = { TermName: termName, SectionName: secName, TimeTable: result };
        const req = { url: `${environment.API_URL}/acad/course/saveTimeTablePeriodWise?id=` + courseId };
        return this.httpClient.post(req.url, payload);
    }

    // MS teams meeting
    createMeeting(subject, startDateTime, endDateTime) {
        const payload: any = { "Data": { Subject: subject, StartDateTime: startDateTime, EndDateTime: endDateTime } };
        const req = { url: `${environment.API_URL}/connect/msteams/createMeeting` };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    getMeetings() {
        const req = { url: `${environment.API_URL}/connect/msteams/getMeetings` };
        return this.httpClient.get(req.url, { observe: 'response', responseType: 'text' });
    }

    // Topic
    addTopic(topics) {
        const payload: any = { "TopicList": topics };
        const req = { url: `${environment.API_URL}/connect/topic` };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    getTopics() {
        const req = { url: `${environment.API_URL}/connect/topic` };
        return this.httpClient.get(req.url);
    }

    getMainTopics() {
        const req = { url: `${environment.API_URL}/connect/topic/mainTopics` };
        return this.httpClient.get(req.url);
    }

    updateTopicHandle(payload) {
        const req = { url: `${environment.API_URL}/connect/topic/handle` };
        return this.httpClient.post(req.url, payload);
    }

    addTopicDescription(id, description) {
        const payload: any = { Id: id, Description: description };
        const req = { url: `${environment.API_URL}/connect/topic/updateDescription` };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    addTopicFriendlyName(id, friendlyName) {
        const payload: any = { Id: id, FriendlyName: friendlyName };
        const req = { url: `${environment.API_URL}/connect/topic/updateFriendlyName` };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    /**
     * To update Topic's Category
     * @param Id Topic ID
     * @param Category PrivateToInstitue = 30 | Public = 40
     * @returns Observable 
     */
    updateTopicCategory(Id: string, Category: 30 | 40) {
        const body = { Id, Category };
        const req = { url: `${environment.API_URL}/connect/topic/updateCategory` };
        return this.httpClient.post(req.url, body);
    }
    updateAllowSubscription(Id: string, IsSubscriptionAllowed: boolean) {
        const body = { Id, IsSubscriptionAllowed };
        const req = { url: `${environment.API_URL}/connect/topic/subscriptionAllowed` };
        return this.httpClient.post(req.url, body);
    }
    addTopicRole(id, role) {
        const payload: any = { Id: id, Role: role };
        const req = { url: `${environment.API_URL}/connect/topic/updateRole` };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    addTopicAccess(id, access) {
        const payload: any = { Id: id, Access: access };
        const req = { url: `${environment.API_URL}/connect/topic/updateAccess` };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    enableTopic(topicId) {
        const req = { url: `${environment.API_URL}/connect/topic/enable?id=` + topicId };
        return this.httpClient.post(req.url, null);
    }

    disableTopic(topicId) {
        const req = { url: `${environment.API_URL}/connect/topic/disable?id=` + topicId };
        return this.httpClient.post(req.url, null);
    }

    updateTopicSubscriptions(body: { Id: string, isSubscriptionAllowed: boolean, Subscriptions: any }) {
        const req = { url: `${environment.API_URL}/connect/topic/updateSubscriptions` };
        return this.httpClient.post(req.url, body);
    }
    getTxHash(postData) {
        const payload: any = postData;
        const req = { url: `${environment.API_URL}/admission/txHash` };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }
    txStatus(postData) {
        const payload: any = postData;
        const req = { url: `${environment.API_URL}/admission/tx` };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    // Admission
    addShortlist(payload) {
        const req = { url: `${environment.API_URL}/admission/applicationFormTemplate/addShortlist` };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    updateShortlist(payload) {
        const req = { url: `${environment.API_URL}/admission/applicationFormTemplate/updateShortlist` };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    submitApplication(id) {
        let payload = { "Id": id }
        const req = { url: `${environment.API_URL}/admission/application/submit` };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    getApplicantDetails(userEmail) {
        const req = { url: `${environment.API_URL}/admission/application/getByEmail?userEmail=` + userEmail };
        return this.httpClient.get(req.url, { observe: 'response', responseType: 'text' });
    }

    apply(payload) {
        const req = { url: `${environment.API_URL}/admission/application` };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    applyingFor(postdata) {
        const payload: any = postdata;
        const req = { url: `${environment.API_URL}/admission/application/applyingFor` };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    updateIdentity(postdata) {
        const payload: any = postdata;
        const req = { url: `${environment.API_URL}/admission/application/updateIdentity` };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    updateApplicantFatherDetails(postdata) {
        const payload: any = postdata;
        const req = { url: `${environment.API_URL}/admission/application/updateFatherDetails` };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    updateApplicantMotherDetails(postdata) {
        const payload: any = postdata;
        const req = { url: `${environment.API_URL}/admission/application/updateMotherDetails` };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    updateApplicantGuardianDetails(postdata) {
        const payload: any = postdata;
        const req = { url: `${environment.API_URL}/admission/application/updateGuardianDetails` };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    updateAcadHistory(postdata) {
        const payload: any = postdata;
        const req = { url: `${environment.API_URL}/admission/application/updateAcademicHistory` };
        return this.httpClient.post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    getApplicantCourseDetails() {
        return this.http.get('/assets/dummy/courses.json');
    }

    viewDocument(payload) {
        const req = { url: `${environment.API_URL}/arte/artefact/viewDocument` };
        return this.httpClient.post(req.url, payload);
    }

    getFileUrl(path) {
        const req = { url: `${environment.API_URL}/arte/artefact/getFileUrl?path=` + path };
        return this.httpClient.get(req.url);
    }

    printArtefactsList(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/arte/report/artefactList` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    getSubmittedApplications(qStr) {
        var req;
        // var orderBy = "hsc";
        var searchStr = `?$search=` + qStr;
        // var orderByStr = `$orderby=` + orderBy + '&$sortorder=' + sortOrder;
        var urlStr = `${environment.API_URL}/admission/application`;
        if (qStr) {
            urlStr = urlStr + searchStr;
            req = { url: urlStr };
        }
        else {
            req = { url: urlStr };
        }
        return this.httpClient.get(req.url);
    }

    setApplicantPhotoUrl(id, url) {
        const payload: any = { "Id": id, "Url": url };
        const req = { url: `${environment.API_URL}/admission/application/updatePhotoUrl` };
        return this.httpClient.post(req.url, payload);
    }

    setApplicantAadharUrl(id, url) {
        const payload: any = { "Id": id, "Url": url };
        const req = { url: `${environment.API_URL}/admission/application/updateAadharUrl` };
        return this.httpClient.post(req.url, payload);
    }

    setApplicantHSCUrl(id, url) {
        const payload: any = { "Id": id, "Url": url };
        const req = { url: `${environment.API_URL}/admission/application/updateHSCMarksCardUrl` };
        return this.httpClient.post(req.url, payload);
    }

    setApplicantSSCUrl(id, url) {
        const payload: any = { "Id": id, "Url": url };
        const req = { url: `${environment.API_URL}/admission/application/updateSSCMarksCardUrl` };
        return this.httpClient.post(req.url, payload);
    }

    setApplicantCasteCertificateUrl(id, url) {
        const payload: any = { "Id": id, "Url": url };
        const req = { url: `${environment.API_URL}/admission/application/casteCertificateUrl` };
        return this.httpClient.post(req.url, payload);
    }

    setApplicantTransferCertificateUrl(id, url) {
        const payload: any = { "Id": id, "Url": url };
        const req = { url: `${environment.API_URL}/admission/application/transferCertificateUrl` };
        return this.httpClient.post(req.url, payload);
    }

    setApplicantBirthCertificateUrl(id, url) {
        const payload: any = { "Id": id, "Url": url };
        const req = { url: `${environment.API_URL}/admission/application/birthCertificateUrl` };
        return this.httpClient.post(req.url, payload);
    }

    setApplicantLeavingCertificateUrl(id, url) {
        const payload: any = { "Id": id, "Url": url };
        const req = { url: `${environment.API_URL}/admission/application/leavingCertificateUrl` };
        return this.httpClient.post(req.url, payload);
    }

    setApplicantDomicileCertificateUrl(id, url) {
        const payload: any = { "Id": id, "Url": url };
        const req = { url: `${environment.API_URL}/admission/application/domicileCertificateUrl` };
        return this.httpClient.post(req.url, payload);
    }

    addSeatMatrix(payload) {
        const req = { url: `${environment.API_URL}/admission/seatMatrix` };
        return this.httpClient.post(req.url, payload);
    }

    updateSeatMatrix(payload) {
        const req = { url: `${environment.API_URL}/admission/seatMatrix/update` };
        return this.httpClient.post(req.url, payload);
    }

    getSeatMatrix() {
        const req = { url: `${environment.API_URL}/admission/seatMatrix` };
        return this.httpClient.get(req.url, { observe: 'response', responseType: 'text' });
    }

    applicationsCoursewise() {
        const req = { url: `${environment.API_URL}/admission/applicationsCoursewise` };
        return this.httpClient.get(req.url, { observe: 'response', responseType: 'text' });
    }

    seatsAvailable() {
        const req = { url: `${environment.API_URL}/admission/seatsAvailable` };
        return this.httpClient.get(req.url, { observe: 'response', responseType: 'text' });
    }

    getAdmissions() {
        const req = { url: `${environment.API_URL}/admission?$search=` };
        return this.httpClient.get(req.url, { observe: 'response', responseType: 'text' });
    }

    seatMatrix() {
        return this.http.get('/assets/dummy/seatMatrix.json');
    }

    getShortlists() {
        return this.http.get('/assets/dummy/bcom_shortlist.json');
    }

    getAdmittedStudents(shortlistNo, courseId) {
        let payload = { "ShortlistNo": shortlistNo, "CourseId": courseId };
        const req = { url: `${environment.API_URL}/admission/application/admitted` };
        return this.httpClient.post(req.url, payload);
    }

    selectApplications(payload) {
        const req = { url: `${environment.API_URL}/admission/application/select` };
        return this.httpClient.post(req.url, payload);
    }

    declineApplications(payload) {
        const req = { url: `${environment.API_URL}/admission/application/decline` };
        return this.httpClient.post(req.url, payload);
    }

    shortlistApplications(payload) {
        const req = { url: `${environment.API_URL}/admission/application/shortlist` };
        return this.httpClient.post(req.url, payload);
    }

    rejectApplications(payload) {
        const req = { url: `${environment.API_URL}/admission/application/reject` };
        return this.httpClient.post(req.url, payload);
    }

    removeFromSelection(payload) {
        const req = { url: `${environment.API_URL}/admission/application/select/remove` };
        return this.httpClient.post(req.url, payload);
    }

    removeFromShortlist(payload) {
        const req = { url: `${environment.API_URL}/admission/application/shortlist/remove` };
        return this.httpClient.post(req.url, payload);
    }

    admit(payload) {
        const req = { url: `${environment.API_URL}/admission/application/admit` };
        return this.httpClient.post(req.url, payload);
    }

    createApplicationFormTemplate(payload) {
        const req = { url: `${environment.API_URL}/admission/applicationFormTemplate` };
        return this.httpClient.post(req.url, payload);
    }

    updateApplicationFormTemplate(payload) {
        const req = { url: `${environment.API_URL}/admission/applicationFormTemplate/update` };
        return this.httpClient.post(req.url, payload);
    }

    getApplicationFormTemplates(qryStr) {
        var req;
        var urlStr;
        if (qryStr == '') {
            urlStr = `${environment.API_URL}/admission/applicationFormTemplate`;
            req = { url: urlStr };
        }
        else {
            urlStr = `${environment.API_URL}/admission/applicationFormTemplate?` + qryStr;
            req = { url: urlStr };
        }
        return this.httpClient.get(req.url, { observe: 'response', responseType: 'text' });
    }

    disableApplicationFormTemplate(id) {
        const req = { url: `${environment.API_URL}/admission/applicationFormTemplate/disable?id=` + id };
        return this.httpClient
            .post(req.url, null, { observe: 'response', responseType: 'text' });
    }

    enableApplicationFormTemplate(id) {
        const req = { url: `${environment.API_URL}/admission/applicationFormTemplate/enable?id=` + id };
        return this.httpClient
            .post(req.url, null, { observe: 'response', responseType: 'text' });
    }

    publishApplicationFormTemplate(id) {
        const req = { url: `${environment.API_URL}/admission/applicationFormTemplate/publish?id=` + id };
        return this.httpClient
            .post(req.url, null, { observe: 'response', responseType: 'text' });
    }

    unpublishApplicationFormTemplate(id) {
        const req = { url: `${environment.API_URL}/admission/applicationFormTemplate/unpublish?id=` + id };
        return this.httpClient
            .post(req.url, null, { observe: 'response', responseType: 'text' });
    }

    // Widget
    addWidget(payload) {
        const req = { url: `${environment.API_URL}/auth/widget/add` };
        return this.httpClient.post(req.url, payload);
    }

    getWidgets() {
        const req = { url: `${environment.API_URL}/auth/widget` };
        return this.httpClient.get(req.url, { observe: 'response', responseType: 'text' });
    }

    disableWidget(id) {
        const req = { url: `${environment.API_URL}/auth/widget/disable?id=` + id };
        return this.httpClient.post(req.url, null);
    }

    enableWidget(id) {
        const req = { url: `${environment.API_URL}/auth/widget/enable?id=` + id };
        return this.httpClient.post(req.url, null);
    }

    wipWidget(id) {
        const req = { url: `${environment.API_URL}/auth/widget/wip?id=` + id };
        return this.httpClient.post(req.url, null);
    }

    updateWidget(payload) {
        const req = { url: `${environment.API_URL}/auth/widget/update` };
        return this.httpClient.post(req.url, payload);
    }

    getWidgetSampleData(widget) {
        const req = { url: `${environment.API_URL}/` + widget.dataEndPoint + '?sample=true' };
        return this.httpClient.post(req.url, null);
    }
    getSpecsFile(fileName: string) {
        const req = { url: `${environment.API_URL}/arte/github/specsFile?filename=` + fileName };
        return this.httpClient.get(req.url, { observe: 'response', responseType: 'text' });
    }

    // Accounting

    getVouchers(orderBy, sortOrder, qStr) {
        var req;
        var orderByStr = `$orderby=` + orderBy + '&$sortorder=' + sortOrder;
        var searchStr = `?$search=`;
        var urlStr = `${environment.API_URL}/accounting/voucher`;
        if (qStr && orderBy != '') {
            urlStr = urlStr + '?' + qStr + '&' + orderByStr;
            req = { url: urlStr };
        }
        else if (qStr && orderBy == '') {
            urlStr = urlStr + '?' + qStr;
            req = { url: urlStr };
        }
        else {
            req = { url: urlStr };
        }
        return this.httpClient.get(req.url, { observe: 'response', responseType: 'text' });
    }

    disableVoucher(voucher_id) {
        const req = { url: `${environment.API_URL}/accounting/voucher/disable?id=` + voucher_id };
        return this.httpClient.post(req.url, null);
    }

    enableVoucher(voucher_id) {
        const req = { url: `${environment.API_URL}/accounting/voucher/enable?id=` + voucher_id };
        return this.httpClient.post(req.url, null);
    }

    getSourceAccounts() {
        const req = { url: `${environment.API_URL}/accounting/ledger/voucherSourceAccounts ` };
        return this.httpClient.get(req.url, { observe: 'response', responseType: 'text' });
    }

    getTargetAccounts() {
        const req = { url: `${environment.API_URL}/accounting/ledger/voucherTargetAccounts ` };
        return this.httpClient.get(req.url, { observe: 'response', responseType: 'text' });
    }

    generateVoucher(payload) {
        const req = { url: `${environment.API_URL}/accounting/voucher/create` };
        return this.httpClient.post(req.url, payload);
    }

    printDatewiseExpenseReport(fromDate, toDate, printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": { "From": fromDate, "To": toDate } };
        const req = { url: `${environment.API_URL}/accounting/report/datewiseExpense` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printCategorywiseExpenseReport(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/accounting/report/categorywiseExpense` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    printYearwiseExpenseReport(printOptions) {
        const payload: any = { "Meta": { "PrintOptions": printOptions } };
        const req = { url: `${environment.API_URL}/accounting/report/yearwiseExpense` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    voucherXMLView(voucherId) {
        // const payload: any = { "Meta": { "PrintOptions": printOptions }};
        const req = { url: `${environment.API_URL}/accounting/report/tallyXML?id=` + voucherId };
        return this.httpClient
            .get(req.url);
    }
    schedulerCoasReminder(payload) {
        const headers = new HttpHeaders({ 'Content-Type': 'text/plain' });
        return this.httpClient.post(`${environment.PHX_API_URL}pubsub/scheduleReminder`, { payload, token: sessionStorage.accessToken }, { headers });
    }

    tallyPrimeXmlView(voucherId) {
        // const payload: any = { "Meta": { "PrintOptions": printOptions }};
        const req = { url: `${environment.API_URL}/accounting/report/tallyPrimeXML?id=` + voucherId };
        return this.httpClient
            .get(req.url);
    }
    getUserIntros() {
        const req = { url: `${environment.API_URL}/auth/user/intros` };
        return this.httpClient.get(req.url);
    }
    updateUserIntros(payload: { Id: string, Intros: { [a: string]: boolean } }) {
        const req = { url: `${environment.API_URL}/auth/user/intros` };
        return this.httpClient.post(req.url, payload);
    }

    // Delete Account

    sendEmailOTP(email) {
        var payload = { "Email": email };
        const req = { url: `${environment.API_URL}/auth/user/sendEmailOTP` };
        return this.httpClient.post(req.url, payload);
    }

    verifyEmailOTP(email, otp) {
        var payload = { "Email": email, "OTP": otp };
        const req = { url: `${environment.API_URL}/auth/user/verifyEmailOTP` };
        return this.httpClient.post(req.url, payload);
    }

    deleteAccount(email) {
        var payload = { "Email": email };
        const req = { url: `${environment.API_URL}/auth/user/deleteAccount` };
        return this.httpClient.post(req.url, payload);
    }

    // Salary
    addSalary(data) {
        var payload = data;
        const req = { url: `${environment.API_URL}/staff/salaryAdd` };
        return this.httpClient.post(req.url, payload);
    }

    getSalaryStructures() {
        const req = { url: `${environment.API_URL}/staff/salary` };
        return this.httpClient.get(req.url);
    }

    getCurrentSalaryStructure() {
        const req = { url: `${environment.API_URL}/staff/salary/currentSalaryStructure` };
        return this.httpClient.get(req.url);
    }

    getStaffById(id) {
        const req = { url: `${environment.API_URL}/staff/getById?id=` + id };
        return this.httpClient.get(req.url);
    }

    updateDesignation(postdata) {
        var payload = postdata;
        const req = { url: `${environment.API_URL}/staff/updateDesignation` };
        return this.httpClient.post(req.url, payload);
    }

    updateSalary(payload) {
        const url = `${environment.API_URL}/staff/salaryUpdate`;
        return this.httpClient
            .post(url, payload)
    }

    deleteSalary(payload) {
        const url = `${environment.API_URL}/staff/salaryDelete`;
        return this.httpClient
            .post(url, payload)
    }

    addPayslip(payload) {
        const url = `${environment.API_URL}/staff/addPayslip`;
        return this.httpClient
            .post(url, payload)
    }

    verifyPayslip(payload) {
        const url = `${environment.API_URL}/staff/verifyPayslip`;
        return this.httpClient
            .post(url, payload)
    }

    viewPayslip(printOptions, data) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": data };
        const url = `${environment.API_URL}/staff/report/viewPayslip`;
        return this.httpClient
            .post(url, payload, { observe: 'response', responseType: 'text' })
    }

    applyStaffAttnEvents(attendanceEvents) {
        const req = { url: `${environment.API_URL}/staff/applyEvents` };
        return this.httpClient.post(req.url, attendanceEvents);
    }

    updateIEP(payload) {
        const req = { url: `${environment.API_URL}/stu/student/iep` };
        return this.httpClient.post(req.url, payload);
    }

    updateBaseline(payload) {
        const req = { url: `${environment.API_URL}/stu/student/baseline` };
        return this.httpClient.post(req.url, payload);
    }

    printIEPReport(printOptions, studentId) {
        const payload: any = { "Meta": { "PrintOptions": printOptions }, "Data": { "StudentId": studentId } };
        const req = { url: `${environment.API_URL}/stu/report/iep` };
        return this.httpClient
            .post(req.url, payload, { observe: 'response', responseType: 'text' });
    }

    addEssential(payload) {
        const req = { url: `${environment.API_URL}/stu/student/addStudentEssentials` };
        return this.httpClient.post(req.url, payload);
    }

    getEssentials(studentId) {
        const req = { url: `${environment.API_URL}/stu/student/essentials?studentId=` + studentId };
        return this.httpClient.get(req.url);
    }

    disableEssentialItem(payload) {
        const req = { url: `${environment.API_URL}/stu/student/disableItem` };
        return this.httpClient.post(req.url, payload);
    }

    enableEssentialItem(payload) {
        const req = { url: `${environment.API_URL}/stu/student/enableItem` };
        return this.httpClient.post(req.url, payload);
    }

    updateEssentialItem(payload) {
        const req = { url: `${environment.API_URL}/stu/student/updateItem` };
        return this.httpClient.post(req.url, payload);
    }
}