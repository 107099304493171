import { Action } from '@ngrx/store';
import { type } from '../../../app/util';

export const ActionTypes = {
    SET_SELECTED_USER_LINK: type('[RC] Set Selected User Link'),
    SET_USER_LINKS: type('[RC] Set User Links'),
    UPDATE_DASHBOARD: type('[RC] Update Dashboard'),
    SET_HTML: type('[RC] Set Html')
};

export class SetSelectedUserLink implements Action {
    type = ActionTypes.SET_SELECTED_USER_LINK;
    /**"Id": "" */
    constructor(public payload: any) { }
}

export class SetUserLinks implements Action {
    type = ActionTypes.SET_USER_LINKS;
    constructor(public payload: any) { }
}

export class UpdateDashboard implements Action {
    type = ActionTypes.UPDATE_DASHBOARD;
    constructor(public payload: any) { }
}

export class SetHtml implements Action {
    type = ActionTypes.SET_HTML;
    constructor(public payload: any) { }
}

export type Actions
    = SetSelectedUserLink | SetUserLinks | UpdateDashboard | SetHtml;