import { NgModule, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GvBarchartComponent } from '../google-vegalite/gv-barchart/gv-barchart.component';
import { GvLinechartComponent } from '../google-vegalite/gv-linechart/gv-linechart.component';
import { WIDGET_TYPES } from './service/injection-token-widget-type';
import { GvPiechartComponent } from '../google-vegalite/gv-piechart/gv-piechart.component';
import { GvAreachartComponent } from '../google-vegalite/gv-areachart/gv-areachart.component';
import { GvScatterchartComponent } from '../google-vegalite/gv-scatterchart/gv-scatterchart.component';
import { GvTableComponent } from '../google-vegalite/gv-table/gv-table.component';
import { GGaugechartComponent } from '../google-vegalite/g-gaugechart/g-gaugechart.component';
import { DashPanelComponent } from '../google-vegalite/dash-panel/dash-panel.component';

export const WIDGET_COMPONENTS = [
    GvBarchartComponent,
    GvLinechartComponent,
    GvPiechartComponent,
    GvAreachartComponent,
    GvScatterchartComponent,
    GvTableComponent,
    GGaugechartComponent,
    DashPanelComponent
];

export const WIDGET_MAPPINGS_PROVIDER: Provider = [
    {
        provide: WIDGET_TYPES,
        useValue: {
            gv_bar: GvBarchartComponent,
            gv_line: GvLinechartComponent,
            gv_pie: GvPiechartComponent,
            gv_area: GvAreachartComponent,
            gv_scat: GvScatterchartComponent,
            g_table: GvTableComponent,
            g_gauge: GGaugechartComponent,
            dash_panel: DashPanelComponent
        }
    }
];

@NgModule({
    imports: [CommonModule],
    entryComponents: WIDGET_COMPONENTS,
    providers: WIDGET_MAPPINGS_PROVIDER,
})
export class GoogleVegaliteModule { }