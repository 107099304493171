const SWPIE_THRESHOLD = 50; 
const SWIPE_LEFT   = 1;
const SWIPE_RIGHT  = 2;
const SWIPE_UP     = 3;
const SWIPE_DOWN   = 4;

export default class TouchEvent
{
    
    constructor(startEvent, endEvent)
    {
        this.startEvent = startEvent
        this.endEvent = endEvent || null
    }

    isSwipeLeft()
    {
        return this.getSwipeDirection() == SWIPE_LEFT
    }

    isSwipeRight()
    {
        return this.getSwipeDirection() == SWIPE_RIGHT
    }

    isSwipeUp()
    {
        return this.getSwipeDirection() == SWIPE_UP
    }

    isSwipeDown()
    {
        return this.getSwipeDirection() == SWIPE_DOWN
    }

    getSwipeDirection()
    {
        let start = this.startEvent.changedTouches[0]
        let end = this.endEvent.changedTouches[0]

        if (!start || !end) {
            return null
        }

        let horizontalDifference = start.screenX - end.screenX
        let verticalDifference = start.screenY - end.screenY

        // Horizontal difference dominates
        if (Math.abs(horizontalDifference) > Math.abs(verticalDifference)) {
            if (horizontalDifference >= SWPIE_THRESHOLD) {
                return SWIPE_LEFT
            } else if (horizontalDifference <= -SWPIE_THRESHOLD) {
                return SWIPE_RIGHT
            }

        // Verical or no difference dominates
        } else {
            if (verticalDifference >= SWPIE_THRESHOLD) {
                return SWIPE_UP
            } else if (verticalDifference <= -SWPIE_THRESHOLD) {
                return SWIPE_DOWN
            }
        }

        return null
    }

    setEndEvent(endEvent)
    {
        this.endEvent = endEvent
    }
}