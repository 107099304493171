import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

import { Store } from '@ngrx/store';
import * as rootReducer from '../rootReducer';
import { Subscription } from 'rxjs/Subscription';
import { Router } from '@angular/router';

import * as ngxFilterAction from '../ngxFilter/store/action';
import { NgxFilterService as service } from './service/service';
import { TabsModule } from 'ngx-bootstrap/tabs';
import * as _ from 'underscore';
import { DateService as dateService } from '../../app/service/date-service';

class model {
    public ctContext: any;
    public userProfile: any;
    public editUserPwd: boolean = false;
    public CurrentPwd: string = '';
    public NewPwd: string = '';
    qualifier:string = "all";
    filterStatusOptions:any = {
        "context": "QA",
        "filterItems": [
            {
                name:"archived",
                checked:false,
                children:[],
                fe:"is:archived"
            },
            {
                name:"published",
                checked:false,
                children:[],
                fe:"is:published"
            },
            {
                name:"wip",
                checked:false,
                children:[],
                fe:"is:wip"
            }
        ]
      };
    filterDifficultyOptions:any = {
    "context": "QA",
    "filterItems": [
            {
                name:"low",
                checked:false,
                children:[],
                fe:"difficulty:low"
            },
            {
                name:"medium",
                checked:false,
                children:[],
                fe:"difficulty:medium"
            },
            {
                name:"high",
                checked:false,
                children:[],
                fe:"difficulty:high"
            }
        ]
    };
    filterDateOptions:any = {
        "context": "QA",
        "filterItems": [
                {
                    name:"today",
                    checked:false,
                    children:[],
                    fe:""
                },
                {
                    name:"yesterday",
                    checked:false,
                    children:[],
                    fe:""
                },
                {
                    name:"this_week",
                    checked:false,
                    children:[],
                    fe:""
                },
                {
                    name:"last_week",
                    checked:false,
                    children:[],
                    fe:""
                },
                {
                    name:"this_month",
                    checked:false,
                    children:[],
                    fe:""
                },
                {
                    name:"last_month",
                    checked:false,
                    children:[],
                    fe:""
                },
                {
                    name:"this_quarter",
                    checked:false,
                    children:[],
                    fe:""
                },
                {
                    name:"last_quarter",
                    checked:false,
                    children:[],
                    fe:""
                },
                {
                    name:"this_year",
                    checked:false,
                    children:[],
                    fe:""
                }
            ]
        }
}

@Component({
    selector: 'ngxFilter',
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
    providers: [service,dateService]
})
export class NgxFilterComponent implements OnInit {
    public m: model;
    public currentPage: number;
    public pageSize: number;
    public ph: string = "";
    public filterExpression: string = "";
    public filterArray: any[] = [];
    public filterOptionsCopy: any;
    public filterStatusOptionsCopy: any;
    public filterDifficultyOptionsCopy: any;
    public filterDateOptionsCopy:any;
    constructor(public service: service, public dateService: dateService, private store: Store<rootReducer.State>, private router: Router) {
    }

    @Input() showFilter: boolean;
    @Input() showPager: boolean;
    @Input() showSearch: boolean;
    @Input() publishedOnly: boolean = false;
    @Input() mediaOnly: boolean = false;
    @Input() totalPages: number;
    @Input() sortOn: string;
    @Input() sortOrder: string;
    @Input() placeHolder: string = "Search";
    @Input() orderBy: string;
    @Input() filterOptions: any;
    @Input() inputDisable: boolean;
    @Output() onQry = new EventEmitter<string>();
    @Output() onKeypress = new EventEmitter<string>();
    @Input() searchText: string = '';
    @Input() selectedFilter: string;

    ngOnInit() {
        this.init_model();
        this.init_store();
        this.sub_store();
        this.showFilter = this.showFilter || false;
        this.showPager = this.showPager || false;
        this.totalPages = this.totalPages || 1000;
        this.currentPage = 1;
        this.pageSize = 10;
        this.orderBy = this.orderBy || "";
        this.sortOrder = this.sortOrder || "";
        this.sortOn = this.sortOn || "";
        this.filterOptions = this.filterOptions || null;
        if (this.filterOptions) { this.filterOptionsCopy = JSON.parse(JSON.stringify(this.filterOptions)); }
        if (this.m.filterStatusOptions) { this.filterStatusOptionsCopy = JSON.parse(JSON.stringify(this.m.filterStatusOptions)); }
        if (this.m.filterDifficultyOptions) { this.filterDifficultyOptionsCopy = JSON.parse(JSON.stringify(this.m.filterDifficultyOptions)); }
        if (this.m.filterDateOptions) { this.filterDateOptionsCopy = JSON.parse(JSON.stringify(this.m.filterDateOptions)); }
        this.init_qry();
    }
    ngOnDestroy() {
    }
    init_model() {
        this.m = new model();
    }
    init_store() {
    }
    init_qry() {
        var str = this.getQryString();
        this.onQry.emit(str);
    }
    sub_store() {
        this.store.select(rootReducer.get_state_app).subscribe((state: any) => {
            if(state) {
                var instAcadFrom = new Date(state.currentPersona.acadYearFrom);
                var instAcadTo = new Date(state.currentPersona.acadYearTo);
                this.m.filterDateOptions.filterItems.forEach(fil => {
                    if(fil.name=="today"){ 
                        var dt = new Date();
                        var dtObj = this.dateService.getToday(dt,instAcadFrom,instAcadTo);
                        if(dtObj){ fil.fe = "date:"+dtObj.from+"to"+dtObj.to; }
                    }
                    else if(fil.name=="yesterday"){ 
                        var dt = new Date();
                        var dtObj = this.dateService.getPriorDay(dt,instAcadFrom,instAcadTo);
                        if(dtObj){ fil.fe = "date:"+dtObj.from+"to"+dtObj.to; }
                    }
                    else if(fil.name=="this_week"){ 
                        var dt = new Date();
                        var dtObj = this.dateService.getCurrentWeek(dt,instAcadFrom,instAcadTo);
                        if(dtObj){ fil.fe = "date:"+dtObj.from+"to"+dtObj.to; }
                    }
                    else if(fil.name=="last_week"){ 
                        var dt = new Date();
                        var dtObj = this.dateService.getPriorWeek(dt,instAcadFrom,instAcadTo);
                        if(dtObj){ fil.fe = "date:"+dtObj.from+"to"+dtObj.to; }
                    }
                    else if(fil.name=="this_month"){ 
                        var dt = new Date();
                        var dtObj = this.dateService.getCurrentMonth(dt,instAcadFrom,instAcadTo);
                        if(dtObj){ fil.fe = "date:"+dtObj.from+"to"+dtObj.to; }
                    }
                    else if(fil.name=="last_month"){ 
                        var dt = new Date();
                        var dtObj = this.dateService.getPriorMonth(dt,instAcadFrom,instAcadTo);
                        if(dtObj){ fil.fe = "date:"+dtObj.from+"to"+dtObj.to; }
                    }
                    else if(fil.name=="this_quarter"){ 
                        var dt = new Date();
                        var dtObj = this.dateService.getCurrentQuarter(dt,instAcadFrom,instAcadTo);
                        if(dtObj){ fil.fe = "date:"+dtObj.from+"to"+dtObj.to; }
                    }
                    else if(fil.name=="last_quarter"){ 
                        var dt = new Date();
                        var dtObj = this.dateService.getPriorQuarter(dt,instAcadFrom,instAcadTo);
                        if(dtObj){ fil.fe = "date:"+dtObj.from+"to"+dtObj.to; }
                    }
                    else if(fil.name=="this_year"){ 
                        var dt = new Date();
                        var dtObj = this.dateService.getCurrentYear(dt,instAcadFrom,instAcadTo);
                        if(dtObj){ fil.fe = "date:"+dtObj.from+"to"+dtObj.to; }
                    }
                });
            }
        });
    }
    getQryString() {
        var searchQry = "?$search=";
        if(this.publishedOnly){
            var searchQry = searchQry + "is:published";
        }
        if(this.mediaOnly){
            var sQry = "$search=";
            var searchQry = "?$orderby=createdOn%20desc&$skip=0&$top=1024&" + sQry + "media:true ";
        }
        var q = searchQry + this.filterExpression;
        if(this.searchText){ 
            if(this.filterExpression){ q = q + ' search:' +  this.searchText }
            else{ q = q + 'search:' +  this.searchText }
        }
        if(!this.mediaOnly){
            var skipVal = (this.currentPage - 1) * this.pageSize;
            if (!skipVal) { skipVal = 0; }
            if (!this.pageSize) { this.pageSize = 10; }
            q = q + "&$skip=" + skipVal + "&$top=" + this.pageSize;
            if(this.filterExpression){ q = q + "&$qual=" + this.m.qualifier; }
        }
        return q;
    }
    search() {
        this.currentPage = 1;
        var str = this.getQryString();
        this.onQry.emit(str);
    }
    inpModelChange(st) {
        this.onKeypress.emit(st);
    }
    clearSearch() {
        this.currentPage = 1;
        this.searchText = "";
        var str = this.getQryString();
        this.onQry.emit(str);
    }
    clearFilters() {
        this.currentPage = 1;
        this.filterExpression = "";
        this.filterArray = [];
        this.filterOptions.filterItems.forEach(fil => {
            fil.checked = false;
        });
        this.m.filterStatusOptions.filterItems.forEach(fil => {
            fil.checked = false;
        });
        this.m.filterDifficultyOptions.filterItems.forEach(fil => {
            fil.checked = false;
        });
        this.m.filterDateOptions.filterItems.forEach(fil => {
            fil.checked = false;
        });
        var str = this.getQryString();
        this.onQry.emit(str);
        if (this.filterOptionsCopy) { this.filterOptions = JSON.parse(JSON.stringify(this.filterOptionsCopy)); }
        if (this.filterStatusOptionsCopy) { this.m.filterStatusOptions = JSON.parse(JSON.stringify(this.filterStatusOptionsCopy)); }
        if (this.filterDifficultyOptionsCopy) { this.m.filterDifficultyOptions = JSON.parse(JSON.stringify(this.filterDifficultyOptionsCopy)); }
        if (this.filterDateOptionsCopy) { this.m.filterDateOptions = JSON.parse(JSON.stringify(this.filterDateOptionsCopy)); }
    }
    paginate() {
        var str = this.getQryString();
        this.onQry.emit(str);
    }
    firstPage() {
        this.currentPage = 1;
        var str = this.getQryString();
        this.onQry.emit(str);
    }
    prevPage() {
        if (this.currentPage > 1) {
            this.currentPage--;
            var str = this.getQryString();
            this.onQry.emit(str);
        }
    }
    nextPage() {
        if (this.currentPage < this.totalPages) {
            this.currentPage++;
            var str = this.getQryString();
            this.onQry.emit(str);
        }
    }
    lastPage() {
        this.currentPage = this.totalPages;
        var str = this.getQryString();
        this.onQry.emit(str);
    }
    resetPlaceholder() {
        this.ph = "";
    }
    setPage() {
        var pageNo = isNaN(Number(this.ph)) ? this.currentPage : Number(this.ph);
        pageNo = (pageNo <= 0) ? 1 : (pageNo > this.totalPages) ? this.totalPages : pageNo;
        this.ph = "";
        this.currentPage = pageNo;
        var str = this.getQryString();
        this.onQry.emit(str);
    }
    removeFilterOption(filterOp){
        if(filterOp && filterOp.k=='tags'){
            var filOp = this.filterOptions.filterItems.find(fil => fil.name == filterOp.d);
            if(filOp){ filOp.checked = false; }
        }
        else if(filterOp && filterOp.k=='is'){
            var filItem = this.m.filterStatusOptions.filterItems.find(fil => fil.name == filterOp.d);
            if(filItem){ filItem.checked = false; }
        }
        else if(filterOp && filterOp.k=='difficulty'){
            var filDiffItem = this.m.filterDifficultyOptions.filterItems.find(fil => fil.name == filterOp.d);
            if(filDiffItem){ filDiffItem.checked = false; }
        }
        else if(filterOp && filterOp.k=='date'){
            var filDateItem = this.m.filterDateOptions.filterItems.find(fil => fil.name == filterOp.d);
            if(filDateItem){ filDateItem.checked = false; }
        }
        this.getFilterExp();
    }
    getFilterExp() {
        setTimeout(() => {
            this.filterExpression = "";
            this.filterArray = [];
            this.filterOptions.filterItems.forEach(x => {
                if (x.fe && x.checked) {
                    var feObj = { k: "", v: "", d:x.name };
                    var feArray = x.fe.split(":");
                    feObj.k = feArray[0];
                    feObj.v = feArray[1];
                    this.filterArray.push(feObj);
                }
                x.children.forEach(a => {
                    if (a.fe && a.checked) {
                        var feObj = { k: "", v: "", d:a.name };
                        var feArray = a.fe.split(":");
                        feObj.k = feArray[0];
                        feObj.v = feArray[1];
                        this.filterArray.push(feObj);
                    }
                    else {
                        _.each(a.children, function (u, v) {
                            if (u.fe && u.checked) {
                                var feObj = { k: "", v: "", d:u.name };
                                var feArray = u.fe.split(":");
                                feObj.k = feArray[0];
                                feObj.v = feArray[1];
                                this.filterArray.push(feObj);
                            }
                        });
                    }
                });
            });
            this.m.filterStatusOptions.filterItems.forEach(x => {
                if (x.fe && x.checked) {
                    var feObj = { k: "", v: "", d:x.name };
                    var feArray = x.fe.split(":");
                    feObj.k = feArray[0];
                    feObj.v = feArray[1];
                    this.filterArray.push(feObj);
                }
                x.children.forEach(a => {
                    if (a.fe && a.checked) {
                        var feObj = { k: "", v: "", d:a.name };
                        var feArray = a.fe.split(":");
                        feObj.k = feArray[0];
                        feObj.v = feArray[1];
                        this.filterArray.push(feObj);
                    }
                    else {
                        _.each(a.children, function (u, v) {
                            if (u.fe && u.checked) {
                                var feObj = { k: "", v: "", d:u.name };
                                var feArray = u.fe.split(":");
                                feObj.k = feArray[0];
                                feObj.v = feArray[1];
                                this.filterArray.push(feObj);
                            }
                        });
                    }
                });
            });
            this.m.filterDifficultyOptions.filterItems.forEach(x => {
                if (x.fe && x.checked) {
                    var feObj = { k: "", v: "", d:x.name };
                    var feArray = x.fe.split(":");
                    feObj.k = feArray[0];
                    feObj.v = feArray[1];
                    this.filterArray.push(feObj);
                }
                x.children.forEach(a => {
                    if (a.fe && a.checked) {
                        var feObj = { k: "", v: "", d:a.name };
                        var feArray = a.fe.split(":");
                        feObj.k = feArray[0];
                        feObj.v = feArray[1];
                        this.filterArray.push(feObj);
                    }
                    else {
                        _.each(a.children, function (u, v) {
                            if (u.fe && u.checked) {
                                var feObj = { k: "", v: "", d:u.name };
                                var feArray = u.fe.split(":");
                                feObj.k = feArray[0];
                                feObj.v = feArray[1];
                                this.filterArray.push(feObj);
                            }
                        });
                    }
                });
            });
            this.m.filterDateOptions.filterItems.forEach(x => {
                if (x.fe && x.checked) {
                    var feObj = { k: "", v: "", d:x.name };
                    var feArray = x.fe.split(":");
                    feObj.k = feArray[0];
                    feObj.v = feArray[1];
                    this.filterArray.push(feObj);
                }
            });
            var groups = _
                .chain(this.filterArray)
                .groupBy('k')
                .map(function (value, key) {
                    return {
                        type: key,
                        items: _.pluck(value, 'v')
                    }
                })
                .value();

            this.filterExpression = "";
            this.filterArray.sort((a, b) => a.d.localeCompare(b.d))
            // this.filterArray.sort((a, b) => a.v.localeCompare(b.v))
            groups.forEach((v, index) => {
                this.filterExpression += v.type + ':' + v.items.toString();
                if (index < groups.length - 1) { this.filterExpression += ' ' }
            });
            this.search();
            // this.searchText = this.filterExpression;
            // this.onKeypress.emit(this.searchText);
            // this.filterOptions.Search = this.filterExpression;
        }, 100)
    };
    handleEvent(event) {
        event.stopPropagation();
    }
    setQualifier(){
        if(this.m.qualifier=="all"){ this.m.qualifier = "any"; }
        else if(this.m.qualifier=="any"){ this.m.qualifier = "all"; }
        var str = this.getQryString();
        this.onQry.emit(str);
    }
    setDateFilter(selFilter){
        if(selFilter.checked){
            this.m.filterDateOptions.filterItems.forEach(fil => {
                fil.checked = false;
            });
            selFilter.checked = true;
        }
        this.getFilterExp();
    }
    ngOnChanges(changes: SimpleChanges) {
        // if (changes && changes.sortOn) {
        //     this.sortOn = changes.sortOn.currentValue.split(',')[0];
        //     if (this.orderBy == this.sortOn) {
        //         this.sortOrder = this.sortOrder == "asc" ? "desc" : "asc";
        //     }
        //     else {
        //         this.sortOrder = "asc";
        //     }
        //     this.orderBy = this.sortOn;
        //     // var str = this.getQryString();
        //     // this.onQry.emit(str);

        // }
        if(changes){
            if(changes.searchText){ 
                this.searchText = changes.searchText.currentValue; 
                if(changes.searchText.currentValue.includes("clearsearchtext")){
                    this.searchText = "";
                }
            }
            if(changes.showPager){ 
                this.showPager = changes.showPager.currentValue;
            }
        }
        if(changes && changes.filterOptions){ 
            this.filterArray = [];
            this.filterExpression = "";
            if(changes.filterOptions.currentValue && changes.filterOptions.currentValue.list && changes.filterOptions.currentValue.list.length>0){
                this.filterOptions = changes.filterOptions.currentValue;
            }
        }
        if(changes && changes.selectedFilter){
            this.selectedFilter = changes.selectedFilter.currentValue;
            if(this.selectedFilter){
                var filOp = this.filterOptions.filterItems.find(fil => fil.name == this.selectedFilter);
                if(filOp){
                    filOp.checked = true;
                    this.getFilterExp();
                }
                else{
                    var filStatusOp = this.m.filterStatusOptions.filterItems.find(fil => fil.name == this.selectedFilter);
                    var filDifficultyOp = this.m.filterDifficultyOptions.filterItems.find(fil => fil.name == this.selectedFilter);
                    if(filStatusOp){
                        filStatusOp.checked = true;
                        this.getFilterExp();
                    }
                    if(filDifficultyOp){
                        filDifficultyOp.checked = true;
                        this.getFilterExp();
                    }
                }
            }
        }
    }
}