import { Action } from '@ngrx/store';
import { type } from '../../../app/util';

export const ActionTypes = {
    SET_USER_DETAILS: type('[User] Set User Details')
}

export class SetUserDetails implements Action {
    type = ActionTypes.SET_USER_DETAILS;
    constructor(public payload: any) {
    }
}

export type Actions
    = SetUserDetails;