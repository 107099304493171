import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import * as rootReducer from '../../rootReducer';
import { HomeService as service } from '../service/home-service'
import * as instAction from '../../inst/store/action';
import { Router } from '@angular/router';


class model {
    acadYearFrom: Date;
    acadYearTo: Date;
    isAcadFiscalYear: boolean = false;
    ctContext: any;
    // acadYearComponent: any = { title: 'acad_year', data: { from: '2018-05-01', to: '2019-04-30' } };
    acadYearComponent: any;
}

@Component({
    selector: 'acad-year',
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
    providers: [service]
})
export class AcademicYearComponent implements OnInit {
    public m: model;
    constructor(private service: service, private store: Store<rootReducer.State>, private router: Router) { }
    ngOnInit() {
        this.init_model();
        this.sub_store();
    }
    init_model() {
        this.m = new model();
    }
    sub_store() {
        this.store.select(rootReducer.get_state_app).subscribe(
            context => {
                this.m.ctContext = context;
                if (this.m.ctContext.currentPersona.isOrg) { this.service.getInsts(''); }
                else { this.service.getInst(this.m.ctContext.currentPersona.clientId); }
            });

        this.store.select(rootReducer.get_user_updated_data).subscribe((res: any) => {
            this.m.acadYearComponent = res.components.find(function (element) {
                return element.title == "acad_year";
            });
            this.m.acadYearFrom = this.m.acadYearComponent.data.from;
            this.m.acadYearTo = this.m.acadYearComponent.data.to;
            this.m.acadYearFrom = new Date(this.m.acadYearFrom);
            this.m.acadYearTo = new Date(this.m.acadYearTo);
            if (this.m.acadYearFrom.getMonth() == 4 && this.m.acadYearFrom.getDate() == 1 && this.m.acadYearTo.getMonth() == 3 && this.m.acadYearTo.getDate() == 30) {
                this.m.isAcadFiscalYear = true;
            }

        });
    }

    ut_editInst() {
        this.store.select(rootReducer.get_insts).subscribe(res => {
            if (res) {
                let self = this.m;
                var selectedInst = res.insts.find(function (element) {
                    return element.id == self.ctContext.currentPersona.clientId;
                });
                selectedInst.academicYearFrom = this.m.acadYearFrom;
                selectedInst.academicYearTo = this.m.acadYearTo;

                if (selectedInst) {
                    this.store.dispatch(new instAction.SetSelectedInst({ "selectedInst": selectedInst }));
                    this.router.navigate(['/admin/inst/edit']);
                }
            }
        });
    }
}