export class ChartWidgetModel {
    widgetId: any = "";
    dashboardId: any = "";
    title: any = "";
    description: any = "";
    data_values: any = [];
    options: any = {};
    widgetSpecs: any = {};
    fileName: any = "";
    chartMetaData:any = {};
}

export class ChartDashboardModel {
    dashboardId: any = "";
    widgetList: any = [];
    dataList: any = [];
    dashboardSpecs: any = {};
    fileName: any = "";
}

export class DashPanelModel {
    title: any = "";
}