import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnDestroy, HostListener } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { AcademicsService as service } from '../../academics/service/service';
import * as _ from 'underscore';
import * as LC from 'literallycanvas';
import { FileUploader } from 'ng2-file-upload';
import 'brace/index';
import 'brace/theme/eclipse';
import 'brace/mode/markdown';
import 'brace/ext/language_tools';
import * as marked from 'marked';
import * as stopWords from '../../../../assets/dummy/stop-words.json';
import { KatexOptions, MarkdownService } from 'ngx-markdown';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as rootReducer from '../../rootReducer';

class Model {
  selectedItem: any = {};
  itemNameCopy: string = "";
  currentNode: any = null;
  prevNode: any = null;
  nextNode: any = null;
}

const URL = '';

@Component({
  selector: 'tree-view',
  templateUrl: './component.html',
  styleUrls: ['./component.css'],
  providers: [service],
})
export class TreeViewComponent implements OnInit, OnDestroy {
  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    if (event.target instanceof Element && ((event.target as Element).tagName).toLowerCase() == "textarea") return;
    if (event.key == "ArrowUp") {
      this.selectPreviousNode(event);
    } else if (event.key == "ArrowDown") {
      this.selectNextNode(event);
    } else if (event.key == "ArrowLeft") {
      this.collapseNode();
    } else if (event.key == "ArrowRight") {
      this.expandNode();
    }
  }
  constructor(private store: Store<rootReducer.State>, private el: ElementRef, public service: service, private markdownService: MarkdownService) {
  }
  @Input('data') items: Array<Object> = [];
  @Input('key') key: string;
  @Input('canEdit') canEdit: boolean = true;
  @Input('canRename') canRename: boolean = true;
  @Input('dropZoneName') dropZoneName: string = "";
  @Input('navItems') navItems: any = null;
  @Output() deletedItem = new EventEmitter<any>();
  @Output() selectedItem = new EventEmitter<any>();
  @Output() itemEdit = new EventEmitter<any>();
  public m: Model;
  public popoverTitle: string = 'Confirm Delete';
  public popoverMessage: string = '';
  public confirmClicked: boolean = false;
  public cancelClicked: boolean = false;
  public confirmText: string = 'Yes';
  public cancelText: string = 'No';
  private eventsSubscription: Subscription;
  public ngxDroppableName = "tree";
  public ngxDraggableName = "";
  public dropZones = "['tree']";
  public currentNode;
  public prevNode;
  public nextNode;
  @Input() events: Observable<void>;

  ngOnInit() {
    if (this.dropZoneName) { this.ngxDroppableName = this.dropZoneName; }
    if (this.items && this.items.length > 0) {
      let hasL = this.items.find(i => i['isLeaf'])
      if (hasL) {
        this.ngxDroppableName = "node";
        this.ngxDraggableName = "['node']";
      } else {
        this.ngxDroppableName = "tree";
        this.ngxDraggableName = "['node','tree']";
      }
      this.items.forEach(item => {
        if (item['isLeaf']) { item['ngxDraggableName'] = "['node']"; }
        else { item['ngxDraggableName'] = "['node','tree']"; }
      });
    }
    if (this.events) {
      this.eventsSubscription = this.events.subscribe(() => {
        this.closeEdits(this.items);
      });
    }
    this.init_model();
    this.sub_store();
  }
  ngOnDestroy() {
    if (this.eventsSubscription) {
      this.eventsSubscription.unsubscribe();
    }
  }
  ngAfterViewInit() {
  }
  init_model() {
    this.m = new Model()
  }
  sub_store() {
  }
  setInnerNodes(nodes, parentNode) {
    nodes.forEach(node => {
      if (parentNode) { node.parentNode = true; }
      else { node.parentNode = false; }
      if (node.children && node.children.length > 0) { this.setInnerNodes(node.children, false); }
    });
  }
  toggleExpand(item) {
    if (!item.isLeaf) {
      item.expand = !item.expand;
    }
  }
  addPeerNode(indx, item, e) {
    if (e.ctrlKey) {
      let nodeClone = JSON.parse(JSON.stringify(item));
      nodeClone.itemId = +new Date();
      nodeClone.selected = false;
      if (nodeClone.children && nodeClone.children.length) { this.setItemId(nodeClone.children) }
      this.items.splice(indx + 1, 0, nodeClone);
    } else {
      let node = { name: "New Node", expand: false, children: [], isLeaf: false, itemId: +new Date(), ngxDraggableName: "['node','tree']" };
      this.items.splice(indx + 1, 0, node);
      this.editItem(node);
    }
  }
  addNode(item) {
    let node = { name: "New Node", expand: false, children: [], isLeaf: false, itemId: +new Date(), ngxDraggableName: "['node','tree']" };
    item.children.push(node);
    if (!item.expand) { item.expand = true }
    this.editItem(node);
  }
  addLeaf(item) {
    let leaf = { itemId: +new Date(), ngxDraggableName: "['node']", name: "New Leaf", expand: false, children: [], isLeaf: true, summary: { text: "", preview: false, htmlText: "", plainText: "" }, lxList: [{ id: "", title: "", content: { text: "", htmlText: "", plainText: "" }, preview: true, itemId: +new Date() }] };
    item.children.push(leaf);
    if (!item.expand) { item.expand = true }
    this.editItem(leaf);
  }
  remove(parent, indx) {
    this.deletedItem.emit(parent[indx]);
    parent.splice(indx, 1);
  }
  setSelectedItem(item) {
    this.closeEdits(this.items);
    this.toggleExpand(item);
    this.m.selectedItem = item;
    this.unsetSelected(this.items);
    this.selectedItem.emit(this.m.selectedItem);
    this.m.selectedItem.selected = true;
  }
  navToItem(item) {
    this.closeEdits(this.items);
    this.m.selectedItem = item;
    this.selectedItem.emit(this.m.selectedItem);
    this.m.selectedItem.selected = true;
  }
  selectedNode(ev) {
    this.selectedItem.emit(ev);
    this.unsetSelected(this.items);
  }
  nodeEdit(ev) {
    this.closeEdits(this.items);
    ev.edit = true;
    this.itemEdit.emit(ev);
  }
  deletedNode(ev) {
    this.deletedItem.emit(ev);
  }
  editItem(item) {
    if (this.canRename) {
      if (this.canEdit) {
        this.m.itemNameCopy = item.name;
        this.closeEdits(this.items);
        item.edit = true;
        if (item.children && item.children.length > 0) {
          item.expand = !item.expand;
        }
        this.itemEdit.emit(item);
      }
    }
  }
  finishEdit(item) {
    if (this.m.itemNameCopy) {
      item.name = this.m.itemNameCopy;
      this.m.itemNameCopy = "";
      item.edit = false;
    }
  }
  undoEdit(item) {
    this.m.itemNameCopy = "";
    item.edit = false;
  }
  closeEdits(nodes) {
    nodes.forEach(node => {
      node.edit = false;
      node.selected = false;
      if (node.children && node.children.length > 0) { this.closeEdits(node.children); }
    });
  }
  setItemId(nodes) {
    nodes.forEach(node => {
      setTimeout(() => {
        node.itemId = +new Date();
        node.selected = false;
        if (node.children && node.children.length > 0) { this.setItemId(node.children); }
      }, 10);
    });
  }
  unsetSelected(nodes) {
    nodes.forEach(node => {
      node.selected = false;
      if (node.children && node.children.length > 0) { this.unsetSelected(node.children); }
    });
  }
  allowDrop(ev) {
    ev.preventDefault();
  }
  dragLx(evt, data) {
    evt.dataTransfer.setData("text", JSON.stringify(data));
  }
  dropLx(ev) {
  }
  dragEnter(ev, item) {
  }
  expandNode() {
    let selNode = this.items.find(i => i['selected']);
    if (selNode && !selNode['edit']) {
      if (!selNode['isLeaf']) {
        selNode['expand'] = true;
      }
      this.navToItem(selNode);
    }
  }
  collapseNode() {
    let selNode = this.items.find(i => i['selected']);
    if (selNode && !selNode['edit']) {
      if (!selNode['isLeaf']) {
        selNode['expand'] = false;
      }
      this.navToItem(selNode);
    }
  }
  selectNextNode(e) {
    if (this.nextNode) {
      let nextNode = this.findNode(this.items, this.nextNode);
      let currNode = this.findNode(this.items, this.currentNode);
      if (!currNode) { currNode = this.currentNode };
      if (!nextNode) { nextNode = this.nextNode };
      if (currNode) {
        currNode['selected'] = false;
      }
      if (nextNode) {
        this.nextNode['selected'] = true;
        this.navToItem(nextNode);
      }
      e.stopPropagation();
    }
  }
  selectPreviousNode(e) {
    if (this.prevNode) {
      let prevNode = this.findNode(this.items, this.prevNode);
      let currNode = this.findNode(this.items, this.currentNode);
      if (!currNode) { currNode = this.currentNode };
      if (!prevNode) { prevNode = this.prevNode };
      if (currNode) { currNode['selected'] = false; }
      if (prevNode) {
        this.navToItem(prevNode);
      }
      e.stopPropagation();
    }
  }
  selectNext(node, indx, e) {
    let selNode;
    let selNodeIndx;
    let nextNode;
    if (node && indx) {
      selNode = node;
      selNodeIndx = indx;
    } else {
      selNode = this.items.find(i => i['selected'] == true || i['edit'] == true);
      selNodeIndx = this.items.findIndex(i => i['selected'] == true || i['edit'] == true);
      if (!selNode) {
        selNode = this.items[0];
        selNodeIndx = 0;
      }
    }

    if (selNode.expand && selNode.children.length) {
      nextNode = selNode.children[0];
      if (e) {
        e.stopPropagation();
      }
    }
    else {
      if (selNode && (selNodeIndx < this.items.length - 1)) {
        nextNode = this.items[selNodeIndx + 1];
        selNode['expand'] = false;
        selNode['selected'] = false;
        this.setSelectedItem(nextNode);
        if (e) {
          e.stopPropagation();
        }
      }
    }
  }
  selectPrev(node, indx, e) {
    let selNode;
    let selNodeIndx;
    if (node && indx) {
      selNode = node;
      selNodeIndx = indx;
    } else {
      selNode = this.items.find(i => i['selected'] == true || i['edit'] == true);
      selNodeIndx = this.items.findIndex(i => i['selected'] == true || i['edit'] == true);
    }
    if (selNode && (selNodeIndx > 0)) {
      let prevNode = this.items[selNodeIndx - 1];
      selNode['expand'] = false;
      selNode['selected'] = false;
      this.setSelectedItem(prevNode);
      if (e) {
        e.stopPropagation();
      }
    }
  }
  findNode(nodeList, selectedNode) {
    for (let [index, node] of nodeList.entries()) {
      if (node.children && node.children.length) {
        let n = node.children.find(c => c.itemId == selectedNode.itemId);
        if (n) {
          return n;
        }
      }
      if (node.children && node.children.length > 0) { this.findNode(node.children, selectedNode); }
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.navItems) {
      if (changes.navItems.currentValue) {
        this.currentNode = changes.navItems.currentValue.currentNode;
        this.prevNode = changes.navItems.currentValue.prevNode;
        this.nextNode = changes.navItems.currentValue.nextNode;
      }
    }
  }
}