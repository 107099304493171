import { Component, OnInit, ElementRef, Input } from '@angular/core';
import { Http } from '@angular/http';
import { Store } from '@ngrx/store';
import * as rootReducer from '../../../app/rootReducer';
import { ArtefactService as service } from '../../artefact/service/service';
import * as R from 'ramda';

class Error {
    constructor(public id: string, public message: string, public show: boolean = false) {
    }
}

class Kv {
    constructor(public key: string, public value: string) { }
}

class NewArte {
    title: string;
    description: string;
    metaKv: Kv[] = [];
    fileSizeInBytes: number;
    get fileSizeInKb(): number {
        return this.fileSizeInBytes / 1024;
    }
    sizeWithinLimits(): boolean {
        return this.fileSizeInKb <= 500;
    }
    constructor() {
        this.metaKv.push(new Kv('', ''));
    }
}
class Model {
    newArte: NewArte;
    errorMessages: Error[] = [];
    showError(id: string) {
        const err = this.errorMessages.find(o => o.id == id);
        if (err) { err.show = true; }
    }
    hideError(id: string) {
        const err = this.errorMessages.find(o => o.id == id);
        if (err) { err.show = false; }
    }
    currentPersona: any;
    tags: any[] = [];
    constructor() {
        this.newArte = new NewArte();
    }
}

@Component({
    selector: 'file-upload',
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
    providers: [service],
})
export class FileUploadComponent implements OnInit {
    public m: Model;
    @Input() meetingMeta: any;
    constructor(private http: Http, private el: ElementRef, public service: service, private store: Store<rootReducer.State>) { }
    ngOnInit() {
        this.init_model();
        this.sub_store();
    }
    sub_store() {
        this.store.select(rootReducer.get_state_app).subscribe(state => {
            if (state)
                this.m.currentPersona = state.currentPersona;
        });
    }
    init_model() {
        this.m = new Model();
        this.m.errorMessages.push(new Error('ARTE_1', 'File size exceeds 500 Kb'));
    }
    ut_upload() {
        var acadYear = "";
        // For upload folderName
        if (this.m.currentPersona)
            acadYear = this.m.currentPersona.acadYearFrom.substring(2, 4) + "-" + this.m.currentPersona.acadYearTo.substring(2, 4);
        var meta_dict = {};
        for (let m of this.m.newArte.metaKv) {
           if(m.key != '')
            meta_dict[m.key] = m.value;
        }

        if (this.meetingMeta != null) {
            var meta = this.meetingMeta.split(':');
            meta_dict[meta[0]] = meta[1];
        }

        let tags = R.uniq(this.m.tags);

        const arte_meta = {
            'Title': this.m.newArte.title,
            'Description': this.m.newArte.description,
            'Tags': tags,
            'MetaData': meta_dict
        };
        const inputEl: HTMLInputElement = this.el.nativeElement.querySelector('#file');
        // set size in bytes. It'd be converted to Kb within the class.
        this.m.newArte.fileSizeInBytes = inputEl.files[0].size;
        const size_within_limits = this.m.newArte.sizeWithinLimits();
        if (size_within_limits && acadYear != "") {
            this.m.hideError('ARTE_1');
            this.service.uploadFiles(inputEl.files, arte_meta, acadYear);
        } else {
            this.m.showError('ARTE_1');
        }
    }
    ut_addMetaRow(i) {
        const attr = new Kv('', '');
        this.m.newArte.metaKv.splice(i + 1, 0, attr);
    }
    ut_deleteMetaRow(i) {
        this.m.newArte.metaKv.splice(i, 1);
        if (this.m.newArte.metaKv.length == 0) { this.m.newArte.metaKv.push(new Kv('', '')); }
    }

    trackByIndex(index: Number, kv: Kv) {
        if (!kv) return null;
        return kv.key;
    }

    onTagsAdded(event){
        this.m.tags.push(event.name);
    }

    onTagsRemoved(event) {
        this.m.tags = this.m.tags.filter(e => e !== event.name);
    }
}
