import { Injectable } from '@angular/core';
import { CTApi } from '../../../app/service/ct-api';
import { Store } from '@ngrx/store';

import * as action from './../store/action';
import * as rootReducer from '../../../app/rootReducer';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';


@Injectable()
export class AdmissionsService {
    constructor(private httpClient: HttpClient, public ctapi: CTApi, private store: Store<rootReducer.State>) { }

    getSpecsFile(fileName: string) {
        return this.ctapi.getSpecsFile(fileName);
    }

    getCourses() {
        return this.ctapi.getCourses().subscribe(res => {
            this.store.dispatch(new action.SetCourses({ "course_list": res['data'] }))
        });
    }
    getTxHash(txInfo) {
        // return this.httpClient.get('/assets/dummy/tx.json');
        return this.ctapi.getTxHash(txInfo);
    }
    txStatus(txInfo) {
        return this.ctapi.txStatus(txInfo);
    }

    getApplicantDetails(userEmail) {
        return this.ctapi.getApplicantDetails(userEmail).subscribe(res => {
            let resp = JSON.parse(res.body)
            this.store.dispatch(new action.SetApplicantDetails({ "applicant": resp.data }));
        });
    }

    apply(payload){
        return this.ctapi.apply(payload).subscribe(res => {
            //this.store.dispatch(new action.SetApplicantDetails({ "applicant": res['data'] }));
            this.getApplicantDetails(payload.Email);
        });
    }

    applyingFor(payload) {
        return this.ctapi.applyingFor(payload).subscribe(res => {
        });
    }

    updateIdentity(payload) {
        return this.ctapi.updateIdentity(payload).subscribe(res => {
        });
    }

    updateFatherDetails(payload) {
        return this.ctapi.updateApplicantFatherDetails(payload).subscribe();
    }

    updateMotherDetails(payload) {
        return this.ctapi.updateApplicantMotherDetails(payload).subscribe();
    }

    updateGuardianDetails(payload) {
        return this.ctapi.updateApplicantGuardianDetails(payload).subscribe();
    }

    updateAcadHistory(payload) {
        return this.ctapi.updateAcadHistory(payload).subscribe();
    }

    uploadPhoto(files_list, data, fileName, filePath, applicantId) {
        const uploadUrl = `${environment.API_URL}/arte/artefact/applicantPhoto`;
        const fileCount: number = files_list.length;
        const formData = new FormData();
        let headers = new HttpHeaders();
        const content = JSON.stringify(data);
        const blob = new Blob([content], { type: 'application/json' });
        if (fileCount > 0) {
            for (let i = 0; i < fileCount; i++) {
                formData.append('files', blob);
                formData.append('files', files_list.item(i), fileName);
                formData.append('path', filePath);
            }
            this.httpClient.post(uploadUrl, formData, { headers: headers }).subscribe((res: any) => {
                if (res) { 
                    this.store.dispatch(new action.SetAadharUrl({aadharUrl: res.url}))
                    this.ctapi.setApplicantPhotoUrl(applicantId, res.url).subscribe(); }
            }, err => { }
            );
        }
    }

    uploadAadhar(files_list, data, fileName, filePath, applicantId) {
        const uploadUrl = `${environment.API_URL}/arte/artefact/applicantAadhar`;
        const fileCount: number = files_list.length;
        const formData = new FormData();
        let headers = new HttpHeaders();
        const content = JSON.stringify(data);
        const blob = new Blob([content], { type: 'application/json' });
        if (fileCount > 0) {
            for (let i = 0; i < fileCount; i++) {
                formData.append('files', blob);
                formData.append('files', files_list.item(i), fileName);
                formData.append('path', filePath);
            }
            this.httpClient.post(uploadUrl, formData, { headers: headers }).subscribe((res: any) => {
                if (res) { this.ctapi.setApplicantAadharUrl(applicantId, res.url).subscribe(); }
            }, err => { });
        }
    }

    uploadHSCMarksCard(files_list, data, fileName, filePath, applicantId) {
        const uploadUrl = `${environment.API_URL}/arte/artefact/applicantHSCMarksCard`;
        const fileCount: number = files_list.length;
        const formData = new FormData();
        let headers = new HttpHeaders();
        const content = JSON.stringify(data);
        const blob = new Blob([content], { type: 'application/json' });
        if (fileCount > 0) {
            for (let i = 0; i < fileCount; i++) {
                formData.append('files', blob);
                formData.append('files', files_list.item(i), fileName);
                formData.append('path', filePath);
            }
            this.httpClient.post(uploadUrl, formData, { headers: headers }).subscribe((res: any) => {
                if (res) { this.ctapi.setApplicantHSCUrl(applicantId, res.url).subscribe(); }
            }, err => { });
        }
    }

    uploadSSCMarksCard(files_list, data, fileName, filePath, applicantId) {
        const uploadUrl = `${environment.API_URL}/arte/artefact/applicantSSCMarksCard`;
        const fileCount: number = files_list.length;
        const formData = new FormData();
        let headers = new HttpHeaders();
        const content = JSON.stringify(data);
        const blob = new Blob([content], { type: 'application/json' });
        if (fileCount > 0) {
            for (let i = 0; i < fileCount; i++) {
                formData.append('files', blob);
                formData.append('files', files_list.item(i), fileName);
                formData.append('path', filePath);
            }
            this.httpClient.post(uploadUrl, formData, { headers: headers }).subscribe((res: any) => {
                if (res) { this.ctapi.setApplicantSSCUrl(applicantId, res.url).subscribe(); }
            }, err => { });
        }
    }

    uploadApplicantDocument(files_list, data, fileName, filePath, applicantId) {
        const uploadUrl = `${environment.API_URL}/arte/artefact/uploadApplicantDocument`;
        const fileCount: number = files_list.length;
        const formData = new FormData();
        let headers = new HttpHeaders();
        const content = JSON.stringify(data);
        const blob = new Blob([content], { type: 'application/json' });
        if (fileCount > 0) {
            for (let i = 0; i < fileCount; i++) {
                formData.append('files', blob);
                formData.append('files', files_list.item(i), fileName);
                formData.append('path', filePath);
            }
            this.httpClient.post(uploadUrl, formData, { headers: headers }).subscribe((res: any) => {
                if (res) {
                    if(fileName == 'caste_certificate.pdf')
                    this.ctapi.setApplicantCasteCertificateUrl(applicantId, res.url).subscribe(); 

                    else if(fileName == 'domicile_certificate.pdf')
                    this.ctapi.setApplicantDomicileCertificateUrl(applicantId, res.url).subscribe(); 

                    else if(fileName == 'birth_certificate.pdf')
                    this.ctapi.setApplicantBirthCertificateUrl(applicantId, res.url).subscribe(); 

                    else if(fileName == 'transfer_certificate.pdf')
                    this.ctapi.setApplicantTransferCertificateUrl(applicantId, res.url).subscribe(); 

                    else if(fileName == 'leaving_certificate.pdf')
                    this.ctapi.setApplicantLeavingCertificateUrl(applicantId, res.url).subscribe(); 
                }
            }, err => { });
        }
    }



    viewDocument(payload) {
        return this.ctapi.viewDocument(payload);
    }

    getSubmittedApplications(qStr) {
        return this.ctapi.getSubmittedApplications(qStr).subscribe((res: any) => {
            this.store.dispatch(new action.SetSubmittedApplications({ "submittedApplications": res['data']['data'] }));
            this.store.dispatch(new action.SetTotalPages({ "totalPages": res['data']['totalPages'] }));
        })
    }

    getApplicantsNotAdmitted(qStr, sortOrder) {
        // return this.ctapi.getApplicants(qStr, sortOrder).subscribe((res: any) => {
        //     this.store.dispatch(new action.SetApplicantsNotAdmitted({ "applicantsNotAdmitted": res['data'] }));
        // })
    }

    addSeatMatrix(payload) {
        return this.ctapi.addSeatMatrix(payload);
    }

    updateSeatMatrix(payload) {
        return this.ctapi.updateSeatMatrix(payload);
    }

    getSeatMatrix() {
        return this.ctapi.getSeatMatrix().subscribe(res => {
            var resp = JSON.parse(res.body);
            this.store.dispatch(new action.SetSeatMatrix({ "seatMatrix": resp.data }));
        });
    }

    // seat_matrix() {
    //     return this.ctapi.seatMatrix().subscribe((res:any) => {
    //         var resp = JSON.parse(res._body);
    //         this.store.dispatch(new action.SeatMatrix({ "sm": resp}));
    //     });
    // }

    get_shortlists() {
        return this.ctapi.getShortlists().subscribe((res: any) => {
            var resp = JSON.parse(res._body);
            this.store.dispatch(new action.Shortlists({ "shortlists": resp }));
        });
    }

    getApplicationsCoursewise() {
        return this.ctapi.applicationsCoursewise().subscribe(res => {
            var resp = JSON.parse(res.body);
            this.store.dispatch(new action.SetCoursewiseApplications({ "coursewiseApplications": resp.data }));
        });
    }

    seatsAvailable() {
        return this.ctapi.seatsAvailable().subscribe(res => {
            var resp = JSON.parse(res.body);
            this.store.dispatch(new action.SeatsAvailable({ "seatsAvailable": resp.data }));
        });
    }

    getAdmissions() {
        return this.ctapi.getAdmissions().subscribe(res => {
            var resp = JSON.parse(res.body);
            this.store.dispatch(new action.SetAdmissions({ "admissions": resp.data }));
        });
    }

    getAdmittedStudents(shortlistNo, courseId) {
        return this.ctapi.getAdmittedStudents(shortlistNo, courseId).subscribe(res => {
            this.store.dispatch(new action.SetAdmittedStudents({ "admittedStudents": res }));
        })
    }

    selectApplications(payload) {
        return this.ctapi.selectApplications(payload).subscribe();
    }

    declineApplications(payload) {
        return this.ctapi.declineApplications(payload).subscribe();
    }

    shortlistApplications(payload) {
        return this.ctapi.shortlistApplications(payload).subscribe();
    }

    rejectApplications(payload) {
        return this.ctapi.rejectApplications(payload).subscribe();
    }

    removeFromSelection(payload) {
        return this.ctapi.removeFromSelection(payload).subscribe();
    }

    removeFromShortlist(payload) {
        return this.ctapi.removeFromShortlist(payload).subscribe();
    }

    admit(payload) {
        return this.ctapi.admit(payload).subscribe();
    }

    createApplicationFormTemplate(payload) {
        return this.ctapi.createApplicationFormTemplate(payload);
    }

    updateApplicationFormTemplate(payload) {
        return this.ctapi.updateApplicationFormTemplate(payload).subscribe();
    }

    getApplicationFormTemplates(qryStr) {
        return this.ctapi.getApplicationFormTemplates(qryStr).subscribe(res => {
            var resp = JSON.parse(res.body);
            this.store.dispatch(new action.SetApplicationFormTemplates({ "applicationFormTemplates": resp['data'] }));
        })
    }

    disableApplicationFormTemplate(id) {
        return this.ctapi.disableApplicationFormTemplate(id);
    }

    enableApplicationFormTemplate(id) {
        return this.ctapi.enableApplicationFormTemplate(id);
    }

    publishApplicationFormTemplate(id) {
        return this.ctapi.publishApplicationFormTemplate(id);
    }

    unpublishApplicationFormTemplate(id) {
        return this.ctapi.unpublishApplicationFormTemplate(id);
    }

    registerApplicant(payload) {
        return this.ctapi.registerApplicant2(payload);
    }

    submit(id) {
        return this.ctapi.submitApplication(id);
    }

    addShortlist(payload){
        return this.ctapi.addShortlist(payload);
    }

    updateShortlist(payload){
        return this.ctapi.updateShortlist(payload);
    }
}