import { MarkedOptions, MarkedRenderer } from 'ngx-markdown';

export function MarkedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();

  renderer.table = (header:string, body:string) => {
    return '<table class="table table-bordered"><thead>'+header+'</thead><tbody>'+body+'</tbody></table>';
  };

  return {
    renderer: renderer,
    gfm: true,
    breaks: false,
    pedantic: false,    
    smartLists: true,
    smartypants: false,
  };
}