import { Action } from '@ngrx/store';
import { type } from '../../app/util';

export const ActionTypes = {
    SET_INST_SETUP_WIDGET: type('[Pulse] Set Institute Setup Widget'),
    SET_CONNECT_SETUP_WIDGET: type('[Pulse] Set Connect Setup Widget'),
    SET_ACADEMICS_SETUP_WIDGET: type('[Pulse] Set Academics Setup Widget'),
    SET_STUDENT_ENROLLMENT_SETUP_WIDGET: type('[Pulse] Set Student Enrollment Setup Widget'),
    SET_CALENDAR_SETUP_WIDGET: type('[Pulse] Set Calendar Setup Widget'),
    SET_USER_ACCESS_WIDGET: type('[Pulse] Set User Access Widget')
};

export class SetInstSetupWidget implements Action {
    type = ActionTypes.SET_INST_SETUP_WIDGET;
    constructor(public payload: any) {
    }
}

export class SetConnectSetupWidget implements Action {
    type = ActionTypes.SET_CONNECT_SETUP_WIDGET;
    constructor(public payload: any) {
    }
}

export class SetAcademicsSetupWidget implements Action {
    type = ActionTypes.SET_ACADEMICS_SETUP_WIDGET;
    constructor(public payload: any) {
    }
}

export class SetStudentEnrollmentSetupWidget implements Action {
    type = ActionTypes.SET_STUDENT_ENROLLMENT_SETUP_WIDGET;
    constructor(public payload: any) {
    }
}

export class SetCalendarSetupWidget implements Action {
    type = ActionTypes.SET_CALENDAR_SETUP_WIDGET;
    constructor(public payload: any) {
    }
}

export class SetUserAccessWidget implements Action {
    type = ActionTypes.SET_USER_ACCESS_WIDGET;
    constructor(public payload: any) {
    }
}

export type Actions
    = SetInstSetupWidget | SetConnectSetupWidget | SetAcademicsSetupWidget | SetStudentEnrollmentSetupWidget | SetCalendarSetupWidget | SetUserAccessWidget;