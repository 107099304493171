import { Action } from '@ngrx/store';
import { type } from '../../../app/util';

export const ActionTypes = {
    DISABLE_WIDGET: type('[Widget] Disable Widget'),
    ENABLE_WIDGET: type('[Widget] Enable Widget'),
    WIP_WIDGET: type('[Widget] WIP Widget'),
    SET_WIDGETS: type('[Widget] Set Widgets'),
    SET_SELECTED_WIDGET: type('[Widget] Set Selected Widget'),
    SET_SAMPLE_DATA: type('[Widget] Set Sample Data'),
    LOAD_ENDPOINT_DATA: type('[Widget] Load Endpoint Data'),
    LOAD_ASSETS_DATA: type('[Widget] Load Assets Data'),
    UPDATE_CHART_DASHBOARD_DATA: type('[Widget] Update Chart Dashboard Data'),
    UPDATE_CHART_DASHBOARD_SPECS: type('[Widget] Update Chart Dashboard Specs'),
    UPDATE_CHART_META_DATA: type('[Widget] Update Chart Meta Data'),
    UPDATE_DASHBOARD_SPECS: type('[Widget] Update Dashboard Specs'),
    UPDATE_DASHBOARD_SPECS_SEARCH_BY: type('[Widget] Update Dashboard Specs Search By')
};

export class DisableWidget implements Action {
    type = ActionTypes.DISABLE_WIDGET;
    /**"Id": ""*/
    constructor(public payload: any) { }
}

export class EnableWidget implements Action {
    type = ActionTypes.ENABLE_WIDGET;
    /**"Id": ""*/
    constructor(public payload: any) { }
}

export class WIPWidget implements Action {
    type = ActionTypes.WIP_WIDGET;
    /**"Id": ""*/
    constructor(public payload: any) { }
}

export class SetWidgets implements Action {
    type = ActionTypes.SET_WIDGETS;
    constructor(public payload: any) { }
}

export class SetSelectedWidget implements Action {
    type = ActionTypes.SET_SELECTED_WIDGET;
    constructor(public payload: any) { }
}

export class SetSampleData implements Action {
    type = ActionTypes.SET_SAMPLE_DATA;
    constructor(public payload: any) { }
}

export class LoadEndpointData implements Action {
    type = ActionTypes.LOAD_ENDPOINT_DATA;
    constructor(public payload: any) { }
}

export class LoadAssetsData implements Action {
    type = ActionTypes.LOAD_ASSETS_DATA;
    constructor(public payload: any) { }
}

export class UpdateChartDashboardData implements Action {
    type = ActionTypes.UPDATE_CHART_DASHBOARD_DATA;
    constructor(public payload: any) { }
}
export class UpdateChartDashboardSpecs implements Action {
    type = ActionTypes.UPDATE_CHART_DASHBOARD_SPECS;
    constructor(public payload: any) { }
}
export class UpdateDashboardSpecs implements Action {
    type = ActionTypes.UPDATE_DASHBOARD_SPECS;
    constructor(public payload: any) { }
}
export class UpdateDashboardSpecsSearchBy implements Action {
    type = ActionTypes.UPDATE_DASHBOARD_SPECS_SEARCH_BY;
    constructor(public payload: any) { }
}
export class UpdateChartMetaData implements Action {
    type = ActionTypes.UPDATE_CHART_META_DATA;
    constructor(public payload: any) { }
}
export type Actions
    = SetWidgets | EnableWidget | DisableWidget | SetSelectedWidget | WIPWidget | SetSampleData | LoadEndpointData | LoadAssetsData | UpdateChartDashboardData | UpdateChartDashboardSpecs | UpdateDashboardSpecs | UpdateDashboardSpecsSearchBy | UpdateChartMetaData;