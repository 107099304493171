import * as app from './action';

class Link {
    href: string;
    text: string;
}

export class Notification {
    id: string;
    title: string;
    content: string;
    sender: string;
    ts: string;
    read: boolean;
    date: Date;
    links: Link[] = [];
    expand: boolean = false;
    from: string;
    expiry: Date;
    status: string;
    type: string;
}

export interface State {
    notifications: Array<Notification>;
    unreadNotifications: number;
}

const initialState: State = {
    notifications: Array(),
    unreadNotifications: 0
};

export function reducer(state = initialState, action: app.Actions): State {
    switch (action.type) {
        case app.ActionTypes.SET_NOTIFICATIONS:
            return Object.assign({}, state, { notifications: action.payload.notifications });
        case app.ActionTypes.GET_UNREAD_NOTIFICATIONS:
            return Object.assign({}, state, { unreadNotifications: action.payload });
        case app.ActionTypes.NOTIFICATION_READ:
            return Object.assign({}, state, { notificationRead: action.payload });
        default:
            return state;
    }
}

export const get_notifications = (state: State) => state.notifications;
export const get_unread_notifications = (state: State) => state.unreadNotifications;