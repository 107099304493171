import { Injectable, Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'searchFilter' })
@Injectable()
export class SearchFilterPipe implements PipeTransform {
	transform(items: any[], field: string, value: string): any[] {
		if (!items && !value) return [];
		if (items && items.length > 0 && !value) return items;
		if (items && items.length > 0 && value) {
			return items.filter(item => item[field].toLowerCase().indexOf(value.toLowerCase()) != -1);
		};
	}
}