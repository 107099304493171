import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { CTApi } from '../service/ct-api';

import { Store } from '@ngrx/store';
import * as rootReducer from '../rootReducer';

import { UserUpgradeService as service } from './service/service';
import { BsModalRef, BsModalService } from "ngx-bootstrap";

class Course {
    id: string;
    name: string;
    fullName: string;
    termName: string;
    secName: string;
    status: number
    branch: any;
    selected: boolean = false;
    section: any;
}

class Model {
    staffOtpSent: boolean = false;
    upgradeTo: string = "student";
    userEmail: string;
    userId: string;
    userName: string;
    studyingIn: string = "";
    institute: string;
    stuInstAddrLine1: string;
    stuInstAddrLine2: string;
    stuInstCity: string;
    stfMobile: string;
    courses: string;
    subjects: string;
    employedIn: string;
    emplInstAddrLine1: string;
    emplInstAddrLine2: string;
    emplInstCity: string;
    courseList: any[] = [];
    personas: any[] = [];
    selectedClientId: string;
    stuAgree: boolean = false;
    staffAgree: boolean = false;
    staffOtp: string;
}

@Component({
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
    providers: [service]
})

export class UserUpgradeComponent implements OnInit {
    @ViewChild("notification") private contentRef: TemplateRef<any>;
    public m: Model;
    modalRef: BsModalRef;
    constructor(
        public ctapi: CTApi,
        private store: Store<rootReducer.State>,
        private service: service,
        private modalService: BsModalService) { }

    ngOnInit() {
        this.init_model();
        this.init_store();
        this.sub_store();
    }

    init_model() {
        this.m = new Model();
    }

    init_store() {
        this.service.getCourses();
    }

    sub_store() {

        this.store.select(rootReducer.get_state_app).subscribe((res: any) => {
            if (res) {
                this.m.userEmail = res.userEmail;
                this.m.userId = res.userId;
                let name = [];
                name.push(res.userFirstName);
                if (res.userMiddleName != null || res.userMiddleName != '') name.push(res.userMiddleName);
                name.push(res.userLastName);
                this.m.userName = name.join(' ');

                this.m.selectedClientId = res.currentPersona.clientId;
                if (this.m.selectedClientId != '') this.service.getInstPersonas('', this.m.selectedClientId);
            }
        });

        this.store.select(rootReducer.get_client_personas).subscribe(
            res => {
                if (res) {
                    this.m.personas = res;
                }
            });

        this.store.select(rootReducer.get_courses_for_acad).subscribe((courseList: Course[]) => {
            this.m.courseList = courseList;
        });
    }

    ut_setSelectedCourseId(courseId) {
        //this.m.courseId = courseId;
    }

    ut_upgradeUser() {
        let persona_id = "";
        if (this.m.upgradeTo == 'student') {
            persona_id = this.m.personas.find(o => o.title == 'Student').id;
        }
        else {
            persona_id = this.m.personas.find(o => o.title == 'Teacher').id;
        }
        let payload = { "Id": this.m.userId, "PersonaId": persona_id };
        this.service.upgradeUser(payload).subscribe(res => {
            if (res) {
                this.openNotificationModal();
            }
        });
    }

    closeModal() {
        this.modalRef.hide();
    }

    openNotificationModal() {
        this.modalRef = this.modalService.show(this.contentRef);
    }

    mobileFocusOut(){
        //this.service.verifyMobile(this.m.stfMobile).subscribe();
        this.m.staffOtpSent = true;
    }

    ut_verifyOtp(){}

    setUpgradeType() { }

    back() { }
}