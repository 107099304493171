import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, mergeMap,withLatestFrom, tap} from 'rxjs/operators';
import {ActionTypes, LoadStates,LoadStatesSuccess} from './app-action';
import { Store, select } from '@ngrx/store';
import * as rootReducer from '../rootReducer';
import {CTApi} from '../service/ct-api';
import { get_states_data } from './app-reducer';
import { filter } from 'lodash';
import { Observable } from 'rxjs';

@Injectable()
export class AppEffects {
    @Effect() loadStates$ = this.actions$
    .pipe(
      ofType<LoadStates>(ActionTypes.LOAD_STATES),
      withLatestFrom(this.store.select(rootReducer.get_states_data)),
      mergeMap(
        ([action,data]) => {
            if(data.states.length == 0){
                return this.ctAPIService.getStates()
                .pipe(
                  map(statesData => {
                    statesData = statesData.json()
                    return new LoadStatesSuccess(statesData['states'])
                  })
                )
            }
            else{
                return Observable.empty();
            }
        }

      )
    )
    constructor(
        private actions$: Actions,
        private ctAPIService: CTApi,
        private store: Store<rootReducer.State>
      ){}
    }