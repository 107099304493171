import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

class Model {
    
}

@Component({
    selector: 'occupation',
    templateUrl: './component.html',
    styleUrls: ['./component.css']
})

export class OccupationComponent implements OnInit {
    public m: Model;
    @Input() title: string;
    @Input() particulars: string;
    @Input() asOn: any;
    @Output() data = new EventEmitter<any>();

    ngOnInit() {
        this.init_model();
        this.init_store();
        this.sub_store();
    }

    init_model() {
        this.m = new Model();
    }

    init_store() {}

    sub_store() {}

    setData() {
        var d = { title: this.title, particulars: this.particulars, asOn: this.asOn };
        this.data.emit(d);
    }
}