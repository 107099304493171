import { Action } from '@ngrx/store';
import { type } from '../../../app/util';

export const ActionTypes = {
    ADD_STAFF: type('[Staff] Add Staff'),
    ASSIGN: type('[Staff] Assign'),
    DISABLE_STAFF: type('[Staff] Disable Staff'),
    ENABLE_STAFF: type('[Staff] Enable Staff'),
    SET_SELECTED_STAFF: type('[Staff] Set Selected Staff'),
    SET_STAFF: type('[Staff] Set Staff'),
    UPDATE: type('[Staff] Update'),
    UPDATE_EMAIL: type('[Staff] Update Email'),
    UPDATE_MOBILE: type('[Staff] Update Mobile'),
    SET_COURSES: type('[Staff] Set Courses'),
    UPDATE_REF_ID: type('[Staff] Update Ref Id'),
    SET_SALARY_STRUCTURES: type('[Staff] Set Salary Structures'),
    SET_CURRENT_SALARY_STRUCTURE: type('[Staff] Set Current Salary Structure'),
    SET_STAFF_SALARIES: type('[Staff] Set Staff Salaries'),
    SET_SELECTED_STAFF_DETAILS: type('[Staff] Set Selected Staff Details'),
    SET_PAYSLIP_DATE: type('[Staff] Set Payslip Date')
};

export class AddStaff implements Action {
    type = ActionTypes.ADD_STAFF;
    /**"Name": "", "Gender":"", "DOJ":"", "Designation":"", "Role":"", "Email":"", "Mobile": "" */
    constructor(public payload: any) { }
}

export class Assign implements Action {
    type = ActionTypes.ASSIGN;
    /**"Id": ""*/
    constructor(public payload: any) { }
}

export class DisableStaff implements Action {
    type = ActionTypes.DISABLE_STAFF;
    /**"Id": ""*/
    constructor(public payload: any) { }
}

export class EnableStaff implements Action {
    type = ActionTypes.ENABLE_STAFF;
    /**"Id": ""*/
    constructor(public payload: any) { }
}

export class SetSelectedStaff implements Action {
    type = ActionTypes.SET_SELECTED_STAFF;
    /**"Id": ""*/
    constructor(public payload: any) { }
}

export class SetStaff implements Action {
    type = ActionTypes.SET_STAFF;
    constructor(public payload: any) { }
}

export class SetCourses implements Action {
    type = ActionTypes.SET_COURSES;
    constructor(public payload: any) {
    }
}

export class Update implements Action {
    type = ActionTypes.UPDATE;
    /**"Id": "", "Name": "", "Designation": "", "DOB": "", "DOJ": "", "Role": "" */
    constructor(public payload: any) { }
}

export class UpdateEmail implements Action {
    type = ActionTypes.UPDATE_EMAIL;
    /**"Id": "", "Email": "" */
    constructor(public payload: any) { }
}

export class UpdateMobile implements Action {

    type = ActionTypes.UPDATE_MOBILE;
    /**"Id": "", "Mobile": "" */
    constructor(public payload: any) { }
}

export class UpdateRefId implements Action {
    type = ActionTypes.UPDATE_REF_ID;
    /**"Id": "", "RefId": "" */
    constructor(public payload: any) { }
}

export class SetSalaryStructures implements Action {
    type = ActionTypes.SET_SALARY_STRUCTURES;
    /**"Id": "", "RefId": "" */
    constructor(public payload: any) { }
}

export class SetSalaryCurrentStructure implements Action {
    type = ActionTypes.SET_CURRENT_SALARY_STRUCTURE;
    constructor(public payload: any) { }
}

export class SetStaffSalaries implements Action {
    type = ActionTypes.SET_STAFF_SALARIES;
    constructor(public payload: any) { }
}

export class SetSelectedStaffDetails implements Action {
    type = ActionTypes.SET_SELECTED_STAFF_DETAILS;
    constructor(public payload: any) { }
}

export class SetPayslipDate implements Action {
    type = ActionTypes.SET_PAYSLIP_DATE;
    constructor(public payload: any) { }
}

export type Actions
    = AddStaff | Assign | DisableStaff | EnableStaff | SetSelectedStaff | SetStaff | Update | UpdateEmail | UpdateMobile | UpdateRefId | SetCourses | SetSalaryStructures | SetSalaryCurrentStructure | SetStaffSalaries | SetSelectedStaffDetails | SetPayslipDate;