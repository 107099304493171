import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

class Model {
    name: string = "";
    email: string = "";
    mobile: any;
}

@Component({
    selector: 'contact-info',
    templateUrl: './component.html',
    styleUrls: ['./component.css']
})

export class ContactInfoComponent implements OnInit {
    public m: Model;
    @Input() pName: string;
    @Input() email: string;
    @Input() mobile: string;
    @Output() data = new EventEmitter<any>();

    ngOnInit() {
        this.init_model();
        this.init_store();
        this.sub_store();
    }

    init_model() {
        this.m = new Model();
    }

    init_store() {}

    sub_store() {}

    setData() {
        var d = { pName: this.pName, email: this.email, mobile: this.mobile };
        this.data.emit(d);
    }
}