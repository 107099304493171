import * as Papa from '../../app/papaparse.min.js'

const jsonToCSV = objArray => {
    var obj_array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var csv = '';
    var keys = (obj_array[0] && Object.keys(obj_array[0])) || [];
    csv += keys.join(',') + '\n';
    for (var obj of obj_array) {
        csv += keys.map(key => quote(obj[key])).join(',') + '\n';
    }
    return csv;
}

const quote = val => {
    var val_as_str = val.toString().trim();
    let res = val_as_str.includes(",") ? `"${val_as_str}"` : val_as_str;
    return res;
}

const csvToJson = csvText => {
    var lines = csvText.split("\n");
    var result = [];
    var headers = lines[0].split(",");
    for (var i = 1; i <= lines.length - 1; i++) {
        var obj = {};
        var currentline = splitCSVButIgnoreCommasInDoublequotes(lines[i]);
        for (var j = 0; j < headers.length; j++) {
            obj[headers[j]] = currentline[j].replace(/"/g, '');
        }
        result.push(obj);
    }
    return result;
}

const splitCSVButIgnoreCommasInDoublequotes = string =>  {
    //split the string first
    //then merge the elments between two double quotes
    var delimiter = ',';
    var quotes = '"';
    var elements = string.split(delimiter);
    var newElements = [];
    for (var i = 0; i < elements.length; ++i) {
        if (elements[i].indexOf(quotes) >= 0) {//the left double quotes is found
            var indexOfRightQuotes = -1;
            var tmp = elements[i];
            //find the right double quotes
            for (var j = i + 1; j < elements.length; ++j) {
                if (elements[j].indexOf(quotes) >= 0) {
                    indexOfRightQuotes = j;
                    break;
                }
            }
            //found the right double quotes
            //merge all the elements between double quotes
            if (-1 != indexOfRightQuotes) {
                for (var j = i + 1; j <= indexOfRightQuotes; ++j) {
                    tmp = tmp + delimiter + elements[j];
                }
                newElements.push(tmp);
                i = indexOfRightQuotes;
            }
            else { //right double quotes is not found
                newElements.push(elements[i]);
            }
        }
        else {//no left double quotes is found
            newElements.push(elements[i]);
        }
    }
    return newElements;
}

const papa_parse_csv_to_json = csv => {
    let csv_to_json = Papa.parse(csv, {
        header: true,  // retain header
        dynamicTyping: true // numeric and boolean data will be converted to their type instead of remaining strings
    });
    let json = csv_to_json['data'];
    return json;
} 

const papa_parse_json_to_csv = json => {
  let csv = Papa.unparse(json);
  return csv.trim();
}


export { jsonToCSV, csvToJson, papa_parse_csv_to_json, papa_parse_json_to_csv }