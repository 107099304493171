import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { timer } from 'rxjs';
import { DeleteAccountService as service } from './service/service';

class Model {
    email: string = "";
    deleteClicked: boolean = false;
    otp: string = "";
    sendOTP: boolean = false;
    timer: any;
    showOTPExpired: any;
    enableConfirm: boolean = false;
}

@Component({
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
    providers: [service]
})
export class DeleteAccountComponent implements OnInit {
    m: Model;
    counter: number;
    countDown: Subscription;
    tick: number = 1000;
    constructor(private service: service) { }
    ngOnInit() {
        this.init_model();
        this.sub_store();
    }
    init_model() {
        this.m = new Model();
    }

    sub_store() { }

    omit_special_char_handle(event) {
        var k;
        k = event.charCode;
        return (k == 46 || k == 64 || (k > 96 && k < 123) || k == 8 || (k >= 48 && k <= 58) || k == 95 || k == 43);
    }

    verifyOtp() {
        this.m.deleteClicked = true;
        this.service.verifyOTP(this.m.email, this.m.otp).subscribe((res: any) => {
            if (res) {
                var resp = res.data[0];
                if (resp.valid == true) this.m.enableConfirm = true;
            }
        });

    }

    sendOTP() {
        this.m.sendOTP = true;
        this.service.sendOTP(this.m.email).subscribe();
        this.counter = 600;
        let timestamp = Date.now();
        var seconds = (new Date().getTime() - new Date(timestamp).getTime()) / 1000;
        this.counter = this.counter - seconds;
        this.countDown = timer(0, this.tick).subscribe(() => {
            if (this.counter >= 1) { --this.counter }
            else if (this.counter === 0) {
                this.m.showOTPExpired = true;
            }
        });
    }

    deleteConfirm() {
        this.service.deleteAccount(this.m.email).subscribe((res: any) => { });
    }
}