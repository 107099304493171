import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dex-sidebar',
  templateUrl: './dex-sidebar.component.html',
  styleUrls: ['./dex-sidebar.component.css']
})
export class DexSidebarComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

}
