import { Directive, ElementRef, Input, Renderer } from '@angular/core';
import * as rootReducer from './rootReducer';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs/Subscription';
import * as _ from 'underscore';

class model {
  public ctContext: any;
  public isAllowed: boolean = false;
}

@Directive({
  selector: '[scopeCheck]'
})
export class ScopeCheckDirective {
  private sub_user_status: Subscription;
  public m: model;
  constructor(public el: ElementRef, public renderer: Renderer,private store: Store<rootReducer.State>) {}

  @Input() scopeCheck: string;

  ngOnInit(){
    this.m = new model();
    this.sub_user_status = this.store.select(rootReducer.get_state_app).subscribe(
			  context => {
                this.m.ctContext = context;
      });
      if(this.scopeCheck){
        this.m.isAllowed = this.check(this.scopeCheck);
      }
      if(!this.m.isAllowed){
        this.renderer.setElementStyle(this.el.nativeElement, 'visibility', 'hidden');}
  }
  check(perm) {
    if(perm){
      var g = 1;
      var p = 2;
      var status = false;
      var perm_type = '';
      if (perm.includes('get/')) {
        perm_type = 'get';
        perm = perm.replace('get/', '');
      } else {
        perm_type = 'post';
        perm = perm.replace('post/', '');
      }
      var m = _.find(this.m.ctContext.scope, function(a) {
        return a.includes(perm);
      });
      if (m) {
        var bit = m.slice(-1);
        if (perm_type == 'get') {
          status = (g & bit) > 0;
        } else {
          status = (p & bit) > 0;
        }
        return status;
      } else {
        var res = perm.split('/');
        for (var i = 0; i < res.length; i++) {
          var a = _.find(this.m.ctContext.scope, function(x) {
            return x.includes(res[i] + '*');
          });
          if (a) {
            var bit = a.slice(-1);
            if (perm_type == 'get') {
              status = (g & bit) > 0;
            } else {
              status = (p & bit) > 0;
            }
            return status;
          }
        }
      }
    }
    else{
      return false;
    }
  }
}