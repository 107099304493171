import * as app from './action';

export class StudentDetail {
  id: string;
  name: string;
  gender: string;
  courseId: string;
  courseName: string;
  status: number;
}

export class Transaction {
  searchText: string;
  student: Student;
  studentList: string[] = [];
  showTransactionTabs: boolean = true;
  showFeesPaid: boolean = false;
  showPendingFees: boolean = false;
  showFine: boolean = false;
  showDiscount: boolean = false;
  showAdj: boolean = false;
  adjApplied: boolean = false;
  discounts: Discount[] = [];
  discountList: Discount[] = [];
  hideAddDiscountOption: boolean = false;
  constructor() {
    this.student = new Student();
  }
}

export class CurrentPersona {
  clientName: string;
  acadYearFrom: Date;
  acadYearTo: Date;
  clientShortName: string;
}

export class Discount {
  description: string;
  amount: number;
  add: boolean = false;
  edit: boolean = false;
}

export class Student {
  id: string;
  name: string;
  studentId: string;
  course: string;
  section: string;
  gender: string;
  dob: Date;
  email: string;
  mobile: string;
  fatherName: string;
  motherName: string;
  aadharCardNo: string;
  feesPaid: Array<FeesPaid>;
  pendingFees: Array<PendingFees>;
  discount: boolean = false;
  fine: Array<any>;
  finesApplicable: any[];
  finesPaid: any[];
  status: boolean;
  nwTransactions: any[];
  father: string;
  mother: string;
  guardian: string;
  txAmount: number;
  adjAmt: number;
  feeAdjustment: any;

  courseNameAndBranch: string;
}

export class PendingFees {
  name: string;
  dueDate: Date;
  amount: number;
  transaction: boolean = false;
  overdue: boolean = false;
  feeId: string;
 // amountPaid: number = 0;
}

export class FeesPaid {
  name: string;
  txDate: Date;
  amount: number;
  receiptNo: string;
  feeId: string;
}

export class TxFees {
  name: string;
  amount: number;
}

export class NewTransaction {
  txDate: Date;
  fees: Array<TxFees>
  total: number;
}

export class Fee {
  id: string;
  name: string;
  displayText: string;
  installments: Installment[] = [];
  courseList: string[] = [];
  fines: Fine[] = [];
  student: Student;
  studentList: string[] = [];
  tags: string;
  status: number;
  collectStudentWise: boolean = false;
  isTxDone: boolean = false;
  selectedStudents: Student[] = [];
  // selectedStudentIds: string[] = [];

  studentDetails: any[] = [];
  selectedCourses: Course[] = [];
  selectedStudentIds: any[] = [];
}

export class Installment {
  description: string;
  amount: string;
  dueDate: any;
}

export class PaymentSchedule {
  description: string;
  amount: number;
  dueDate: string;
}

export class Fine {
  description: string;
  amount: number;
  overDueByDays: number;
}

export class Course {
  id: string;
  name: string;
  branch: string;
  status: boolean = false;
  sections: Section[] = [];
}

export class Section {
  name: string;
}

export class FeesContext {
  constructor(public selectedFeeId: string = "", selectedFeeName: string = "") { }
}

export class FeeInstallmentsFlattened {
  feeName: string;
  feeId: string;
  feeInstId: number;
  feeDisplayText: string;
}

export interface State {
  fees: Fee[];
  fees_context: FeesContext;
  feeWithInstallments: FeeInstallmentsFlattened[];
  selectedFee: any;
  userLinks: any[];
  html: any;
  searchedStudent: Student;
  newTransaction: any[];
  txList: any;
  studentList: Student[];
  courseList: Course[];
  studentTx: any;
  selectedTx: any;
  selTxDate: any;
  txSearchTerm: any;
  queryString: string;
}
//state
const initialState: State = {
  fees: [],
  fees_context: new FeesContext(),
  feeWithInstallments: null,
  selectedFee: null,
  userLinks: null,
  html: null,
  searchedStudent: null,
  newTransaction: null,
  txList: null,
  studentList: null,
  courseList: null,
  studentTx: {},
  selectedTx: {},
  selTxDate: null,
  txSearchTerm: null,
  queryString: null
};

function disable_fee(fees, id): Fee {
  var fee_list: Fee = fees.map((item) => {
    if (item.id == id) item.status = 99;
    return item;
  });
  return fee_list
}

function enable_fee(fees, id): Fee {
  var fee_list: Fee = fees.map((item) => {
    if (item.id == id) item.status = 1;
    return item;
  });
  return fee_list
}

function setFeeArray(dataFromServer): Fee[] {
  let result = [];
  dataFromServer.data.forEach(o => {
    let fee = new Fee();
    fee.id = o.id;
    fee.collectStudentWise = o.collectStudentWise;
    fee.courseList = o.courseList;
    fee.studentList = o.studentList;
    fee.displayText = o.displayText;
    fee.fines = o.fines;
    fee.name = o.name;
    fee.installments = o.installments;
    if (o.status == "Active") fee.status = 1;
    else fee.status = 99;
    fee.tags = o.tags;
    fee.isTxDone = o.isTxDone;

    result.push(fee);
  });
  return result;
}

function setSearchedStudent(dataFromServer): Student {
  let stu = new Student();
  stu.name = dataFromServer.name;
  stu.studentId = dataFromServer.studentId;
  stu.course = dataFromServer.course;
  stu.section = dataFromServer.section;
  stu.gender = dataFromServer.gender;
  stu.dob = dataFromServer.dob;
  stu.aadharCardNo = dataFromServer.aadharCardNo;
  stu.email = dataFromServer.email;
  stu.mobile = dataFromServer.mobile;
  stu.fatherName = dataFromServer.fatherName;
  stu.motherName = dataFromServer.motherName;
  stu.feesPaid = dataFromServer.feesPaid;
  stu.pendingFees = dataFromServer.pendingFees;
  stu.discount = dataFromServer.discount;
  stu.nwTransactions = dataFromServer.nwTransactions;
  return stu;
}

function setStudentArray(dataFromServer) {
  let result = [];
  dataFromServer.data.forEach(o => {
    let student = new Student();
    student.name = o.name;
    // student.dob = o.dob;
    student.id = o.id;
    student.studentId = o.studentId;
    // student.status = o.status;
    // student.father = o.father.name;
    // student.mother = o.mother.name;
    // student.guardian = o.guardian.name;
    // student.email = o.email;
    student.course = o.courseName;
    student.section = o.sectionName;
    student.courseNameAndBranch = o.courseName;
    result.push(student);
  });
  return result;
}

function setCourseArray(dataFromServer): Course[] {
  let result = [];
  dataFromServer.data.forEach(o => {
    let course = new Course();
    course.id = o.id;
    course.name = o.name;
    course.branch = o.branch;
    o.terms.forEach(t => {
      course.sections = t.sections;
    })
    result.push(course);
  });
  return result;
}

function setSelectedFee(dataFromServer) {
  if (dataFromServer.data) {
    let data = dataFromServer.data;
    let fee = new Fee();
    fee.collectStudentWise = data.collectStudentWise;
    fee.courseList = data.courseList;
    fee.displayText = data.displayText;
    fee.fines = data.fines;
    fee.id = data.id;
    fee.installments = data.installments;
    fee.isTxDone = data.isTxDone;
    fee.name = data.name;
    fee.status = data.status;
    fee.studentDetails = data.studentDetails;
    fee.studentList = data.studentList;
    fee.tags = data.tags;
    return fee;
  }
}

function setfeeWithInstallments(dataFromServer) {
  let result = [];
  dataFromServer.data.forEach(o => {
    let fee = new FeeInstallmentsFlattened();
    fee.feeId = o.feeId;
    fee.feeName = o.feeName;
    fee.feeInstId = o.feeInstId;
    fee.feeDisplayText = o.feeDisplayText;
    result.push(fee);
  });
  return result;
}

//commands
export function reducer(state = initialState, action: app.Actions): State {
  switch (action.type) {
    case app.ActionTypes.CREATE_FEE:
      return Object.assign({}, state, { fees: action.payload.fee });
    case app.ActionTypes.SET_FEES:
      return Object.assign({}, state, { fees: setFeeArray(action.payload.fees) });
    case app.ActionTypes.DISABLE_FEE:
      return Object.assign({}, state, { fees: disable_fee(state.fees, action.payload) });
    case app.ActionTypes.ENABLE_FEE:
      return Object.assign({}, state, { fees: enable_fee(state.fees, action.payload) });
    case app.ActionTypes.SET_FEE_CONTEXT:
      const ns = Object.assign({}, state, { fees_context: action.payload });
      return ns;
    case app.ActionTypes.SET_SELECTED_FEE:
      return Object.assign({}, state, { selectedFee: setSelectedFee(action.payload.fee) });
    case app.ActionTypes.SET_SELECTED_USER_LINK:
      return Object.assign({}, state, { selectedUserLink: action.payload.userLink });
    case app.ActionTypes.SET_USER_LINKS:
      return Object.assign({}, state, { userLinks: action.payload.user_links });
    case app.ActionTypes.UPDATE_DASHBOARD:
      return Object.assign({}, state, { userLinks: action.payload.userLinks });
    case app.ActionTypes.SET_HTML:
      return Object.assign({}, state, { html: action.payload });
    case app.ActionTypes.SET_NEW_TRANSACTION:
      return Object.assign({}, state, { newTransaction: action.payload.newTransaction });
    case app.ActionTypes.SET_TX_LIST:
      return Object.assign({}, state, { txList: action.payload.txList });
    case app.ActionTypes.SET_SEARCHED_STUDENT:
      return Object.assign({}, state, { searchedStudent: setSearchedStudent(action.payload.searchedStudent) });
    case app.ActionTypes.SET_STUDENTS:
      return Object.assign({}, state, { studentList: setStudentArray(action.payload.student_list) });
    case app.ActionTypes.SET_COURSES:
      return Object.assign({}, state, { courseList: setCourseArray(action.payload.course_list) });
    case app.ActionTypes.SET_STUDENT_TX:
      return Object.assign({}, state, { studentTx: action.payload.studentTx });
    case app.ActionTypes.SET_SELECTED_TX:
      return Object.assign({}, state, { selectedTx: action.payload.selectedTx });
    case app.ActionTypes.SET_FEE_WITH_INSTALLMENTS:
      return Object.assign({}, state, { feeWithInstallments: setfeeWithInstallments(action.payload.feesInstFlattened) });
    case app.ActionTypes.SET_TX_DATE:
      return Object.assign({}, state, { selTxDate: action.payload.selTxDate });
    case app.ActionTypes.SET_TX_SEARCH_TERM:
      return Object.assign({}, state, { txSearchTerm: action.payload.search_text });
    case app.ActionTypes.SET_TX_QUERY_STRING:
      return Object.assign({}, state, { queryString: action.payload.query_string });
    default:
      return state;
  }
}

//queries
export const get_state_fees = (state: State) => state;
export const get_fees = (state: State) => state.fees;
export const get_fee_context = (state: State) => state.fees_context;
export const get_selected_fee = (state: State) => state.selectedFee;
export const get_new_transaction = (state: State) => state.newTransaction;
export const get_tx_list = (state: State) => state.txList;
export const get_html_fee = (state: State) => state.html;
export const get_searched_student = (state: State) => state.searchedStudent;
export const get_students_for_fees = (state: State) => state.studentList;
export const get_courses_for_fees = (state: State) => state.courseList;
export const get_student_tx = (state: State) => state.studentTx;
export const get_selected_tx = (state: State) => state.selectedTx;
export const get_fees_with_installments = (state: State) => state.feeWithInstallments;
export const get_tx_date = (state: State) => state.selTxDate;
export const get_tx_search_term = (state: State) => state.txSearchTerm;
export const get_tx_query_string = (state: State) => state.queryString;