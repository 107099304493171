import * as app from './navTabs-action';

export interface State {
  tab: any;
}

const initialState: State = {
  tab: {}
};

// function get_user_info(ctContext: any): any {
//     let inst_short_name = ctContext.currentPersona.ClientShortName;
//     let acad_year_from = ctContext.currentPersona.acadYearFrom;
//     let acad_year_from_yy = acad_year_from.substr(2, 2);
//     let acad_year_to = ctContext.currentPersona.acadYearTo;
//     let acad_year_to_yy = acad_year_to.substr(2, 2);
//     let user_persona = ctContext.currentPersona.title;
//     let user_info = inst_short_name + " " + acad_year_from_yy + "-" + acad_year_to_yy + " " + "(" + user_persona + ")";
//     return user_info;
// }

export function reducer(state = initialState, action: app.Actions): State {
  switch (action.type) {
    case app.ActionTypes.SET_TAB:
      return Object.assign({}, state, { tab: action.payload.tab });
    default:
      return state;
  }
}

export const getTab = (state: State) => state.tab;