import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import * as rootReducer from '../../rootReducer';
import { FinanceService as service } from './service/service';
import * as _ from 'underscore';

class Model {
}
@Component({
    selector: 'finance',
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
    providers: [service]
})

export class FinanceComponent {
    public m: Model;
    dlrData: any;
    @Input() pin: boolean;
    constructor(private store: Store<rootReducer.State>, private service: service, private router: Router) { }

    ngOnInit() {
        this.init_model();
        this.init_store();
    }
    init_model() {
        this.m = new Model();
    }

    init_store() {
    }

    pinChart() {
        this.pin = false;
        let payload = ["finance_json"];
		this.service.pinWidget(payload).subscribe(res => {
			if(res) this.service.getDashboardWidgets();
		});
     
    }
    unpinChart() {
        this.pin = true;
        let payload = ["finance_json"];
		this.service.unpinWidget(payload).subscribe(res => {
			if(res) this.service.getDashboardWidgets();
		});
    }
    goToPayment(){ 
		this.router.navigate(['/home/payment']);
	}
}