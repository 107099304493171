import { Action } from '@ngrx/store';
import { type } from '../../../app/util';

export const ActionTypes = {
    SET_ORGS: type('[Org] Set Org'),
    SET_FIRST_LEVEL_ORGS: type('[Org] Set First Level Orgs'),
    SET_MODULES: type('[Org] Set Modules'),
    SET_SELECTED_ORG: type('[Org] Set Selected Org'),
    SET_ORG_INSTS: type('[Org] Set Org Insts'),
    SET_ORG_USERS: type('[Org] Set Org Users'),
    SET_ORG_PERSONAS: type('[Org] Set Org Personas'),
    SET_SELECTED_USER: type('[Org] Set Selected User'),
    SET_SELECTED_PERSONA: type('[Org] Set Selected Persona'),
    SET_ORG_PERMISSIONS: type('[Org] Set Org Permissions'),
    SET_SELECTED_CLIENT: type('[Org] Set Selected Client')
}

export class SetOrgs implements Action {
    type = ActionTypes.SET_ORGS;
    constructor(public payload: any) {
    }
}
export class SetFirstLevelOrgs implements Action {
    type = ActionTypes.SET_FIRST_LEVEL_ORGS;
    constructor(public payload: any) {
    }
}
export class SetModules implements Action {
    type = ActionTypes.SET_MODULES;
    constructor(public payload: any) {
    }
}
export class SetSelectedOrg implements Action {
    type = ActionTypes.SET_SELECTED_ORG;
    constructor(public payload: any) {
    }
}
export class SetOrgInsts implements Action {
    type = ActionTypes.SET_ORG_INSTS;
    constructor(public payload: any) {
    }
}
export class SetOrgUsers implements Action {
    type = ActionTypes.SET_ORG_USERS;
    constructor(public payload: any) {
    }
}
export class SetOrgPersonas implements Action {
    type = ActionTypes.SET_ORG_PERSONAS;
    constructor(public payload: any) {
    }
}
export class SetSelectedUser implements Action {
    type = ActionTypes.SET_SELECTED_USER;
    constructor(public payload: any) {
    }
}
export class SetSelectedPersona implements Action {
    type = ActionTypes.SET_SELECTED_PERSONA;
    constructor(public payload: any) {
    }
}
export class SetOrgPermissions implements Action {
    type = ActionTypes.SET_ORG_PERMISSIONS;
    constructor(public payload: any) {
    }
}
export class SetSelectedClient implements Action {
    type = ActionTypes.SET_SELECTED_CLIENT;
    constructor(public payload: any) {
    }
}

export type Actions
    = SetOrgs | SetFirstLevelOrgs | SetModules | SetSelectedOrg | SetOrgInsts | SetOrgUsers | SetOrgPersonas | SetSelectedUser | SetSelectedPersona | SetOrgPermissions | SetSelectedClient ;
