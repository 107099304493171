import { INTRO_IDS } from "../service/constants";
import { IntroActionTypes, IntroActions } from "./intro-action";

export interface State {
    intros: { [a: string]: any };
}

export const initialState: State = {
    intros: {
        // [INTRO_IDS.HEADER]: { isDone: false },
    }
};

export function reducer(state = initialState, action: IntroActions): State {
    switch (action.type) {
        case IntroActionTypes.SET_INTRO_DONE:
            return Object.assign({}, state, { intros: setIntroDone(state.intros, action.introId) });
        case IntroActionTypes.SET_INTRO_DONE:
            return Object.assign({}, state, { intros: setIntroDone(state.intros, action.introId) });
        case IntroActionTypes.SET_USER_INTROS:
            return Object.assign({}, state, { intros: action.intros });
        default:
            return state;
    }
}
function setIntroDone(intros, id) {
    intros[id] = true;
    return intros;
}
function setIntroUndone(intros, id) {
    intros[id] = false;
    return intros;
}

export const get_intros = (state: State) => state.intros;
