import * as R from 'ramda';

const get_list = (prefix, num) => [...Array(num).keys()].map(i => Object.assign(
    {},
    { "id": prefix + (i + 1) },
    { "name": prefix + (i + 1) }
));
const sections = get_list("G", 24)
const staff = get_list("T", 40)
const subjects = get_list("S", 24)
const additional_staff = get_list("A", 10)
const locations = get_list("L", 100)
const set_name = (list, id, new_name) => list.map(item => {
    if (item.id == id) {
        name = new_name ? new_name.trim() : id;
        return { "id": id, "name": name };
    }
    else return item;
});

const getSubjectRows = (sec_count, sub_count) => {
    let sec_list = sections.slice(0, sec_count);
    let sub_list = subjects.slice(0, sub_count);
    return sub_list.map(sub => Object.assign(
        {},
        sub,
        { "cells": sec_list.map(sec => Object.assign({}, { "displayText": "", "ppw": 0, "staff": [], "subjectWeight": 1 })) },
        {"uniqueStaff": 0 }
    ))
}

const getStaffRows = (sec_count, stf_count) => {
    let sec_list = sections.slice(0, sec_count);
    let stf_list = staff.slice(0, stf_count);
    return stf_list.map(stf => Object.assign(
        {},
        stf,
        { "subjects": sec_list.map(sec => Object.assign({}, { "name": "" })) },
        { "uniqueSubject": 0 }
    ))
}

// const getAdditionalStaffRows = (sec_count, stf_count) => {
//     let sec_list = sections.slice(0, sec_count);
//     let stf_list = additional_staff.slice(0, stf_count);
//     return stf_list.map(stf => Object.assign(
//         {},
//         stf,
//         { "subjects": sec_list.map(sec => Object.assign({}, { "name": "" })) }
//     ))
// }

const getAdditionalStaffRows = (sec_count, actual_stf_count, additional_stf_count) => {
    let sec_list = sections.slice(0, sec_count);
    let stf_list = staff.slice(actual_stf_count, actual_stf_count+additional_stf_count);
    return stf_list.map(stf => Object.assign(
        {},
        stf,
        { "subjects": sec_list.map(sec => Object.assign({}, { "name": "" })) },
        { "uniqueSubject": 0 }
    ))
}

const reducer = (state, kv_item) => {
    let indx = R.findIndex(R.propEq('k', kv_item.k))(state);
    if (indx == -1) return R.concat(state, [kv_item])
    else {state.splice(indx,1,kv_item); return state}
  }
const staff_assign = stf_assign_change_set => R.reduce(reducer,[], stf_assign_change_set);

export { locations, additional_staff, sections, staff, set_name, getSubjectRows, getStaffRows, staff_assign, getAdditionalStaffRows }