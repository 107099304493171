import { Component, OnInit } from '@angular/core';

class model {
}

@Component({
	templateUrl: './faq.component.html',
	styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
	public showQ1Ans: any = false;
	public showQ2Ans: any = false;
	public showQ3Ans: any = false;
	public showQ4Ans: any = false;
	public showQ5Ans: any = false;
	public showQ6Ans: any = false;
	public showQ7Ans: any = false;
	public m: model;
	ngOnInit() {
		this.m = new model();
		this.showQ1Ans = false;
		this.showQ2Ans = false;
		this.showQ3Ans = false;
		this.showQ4Ans = false;
		this.showQ5Ans = false;
		this.showQ6Ans = false;
		this.showQ7Ans = false;
	}
}