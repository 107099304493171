import { Action } from '@ngrx/store';
import { type } from '../../../util';

export const ActionTypes = {
    TIMER_STOPPED: type('[Countdown Timer] Timer_Stopped'),
};

export class TimerStopped implements Action {
    type = ActionTypes.TIMER_STOPPED;
    constructor(public payload: any) { }
}

export type Actions = TimerStopped;