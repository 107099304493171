import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DragulaService } from 'ng2-dragula';

@Component({
  selector: 'app-dex-group-by',
  templateUrl: './dex-group-by.component.html',
  styleUrls: ['./dex-group-by.component.css']
})
export class DexGroupByComponent implements OnInit {
  @Input() dexGroupBy: { column: string; }[];
  @Output() onChanges: EventEmitter<{ type: string; value: string; json: Array<string> }> = new EventEmitter();
  selections: Array<{ column: string; checked: boolean }> = []

  constructor(private dragulaService: DragulaService) { }

  ngOnInit() {
    this.selections = this.dexGroupBy.map((g) => ({ column: g.column, checked: true }));
    this.emitChanges();
    this.dragulaService.drop("dex-group").subscribe((value) => {
      this.emitChanges();
    });
  }
  ut_onChangeSelection(e: any) {
    this.emitChanges();
  }
  private emitChanges() {
    this.onChanges.emit({ type: "group", value: this.pRQL, json: this.json });
  }
  private get json(): Array<string> {
    return this.selections.filter((s) => s.checked).map(c => c.column);
  }
  private get pRQL(): string {
    return "group " + this.selections.filter((s) => s.checked).map(c => c.column).join(", ");
  }
}
