import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { CTApi } from "../../../app/service/ct-api";
import * as rootReducer from '../../../app/rootReducer';

@Injectable()
export class SetupInstituteService {
  constructor(private httpClient: HttpClient, public ctapi: CTApi, private store: Store<rootReducer.State>) { }
  addInst(inst) {
    return this.ctapi.addInst(inst);
  }

  updateUserDetails(payload) {
    return this.ctapi.updateUserDetails(payload);
  }

  setupInstitute(payload) {
    return this.ctapi.setupInstitute(payload);
  }

  verifySMSCode(payload){
    return this.ctapi.verifySMSCode(payload);
  }

  sendVerificationCode(payload){
    return this.ctapi.sendVerificationCode(payload);
  }
}