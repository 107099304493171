import { Component, Input, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import * as rootReducer from '../../rootReducer';
import { Router } from '@angular/router';
import { CalendarSetupService as service } from './service/service';
import { GridOptions } from 'ag-grid/main';
import * as _ from 'underscore';

class Event {
    audience: string[];
    category: string;
    conductedBy: string[];
    from: string;
    priority: number;
    title: string;
    to: string;
}

class CalendarData {
    date: string;
    events: Event[] = [];
}

class Model {
    calendarData: CalendarData[] = [];
    getColor(category) {
        switch (category) {
            case 'Holiday': return 'gray';
            case 'Event': return '#da9517';
            default: return 'black';
        }
    };
    weeklyHolidays: boolean;
}
@Component({
    selector: 'calendar-setup',
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
    providers: [service]
})

export class CalendarSetupComponent {
    public m: Model;
    private gridColumnApi;
    private gridApi;
    gridOptions: GridOptions;
    private columnDefEvent;
    rowDataCalendar: any;
    @Input() pin: boolean;
    constructor(private router: Router, private service: service, private store: Store<rootReducer.State>) {
        this.gridOptions = <GridOptions>{
            enableColResize: false,
            suppressHorizontalScroll: true,
            headerHeight: 0,
        };
        this.rowDataCalendar = [];
        this.columnDefEvent = [
            { headerName: "Date", field: "Date", editable: true, width: 40 },
            { headerName: "Event", field: "Event", editable: true, width: 40 }
        ];
    }
    ngOnInit() {
        this.init_model();
        this.init_store();
    }
    init_model() {
        this.m = new Model();
    }

    init_store() {
        this.store.select(rootReducer.get_calendar_setup_widget).subscribe((res: any) => {
            if (res) {
                this.m.calendarData = res.calendar;
                this.rowDataCalendar = [];
                this.m.calendarData.forEach(j => {
                    j.events.forEach(e => {
                        if (e.category != 'WeeklyHoliday') {
                            let d = new Date(j.date);
                            var date = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
                            this.rowDataCalendar.push({ Date: date, Event: e.title });
                        }
                    });
                });
                this.rowDataCalendar.sort(function (a, b) {
                    return +new Date(a.Date) - +new Date(b.Date);
                });
            }
        });
    }

    onGridReady(params) {
        params.api.sizeColumnsToFit();
        params.api.setRowData(this.rowDataCalendar);
        params.api.refreshCells({ force: true });
        let date = new Date();
        let i: any;
        params.api.forEachNode(function (rowNode, index) {
            i = index;
            if (new Date(rowNode.data.Date) <= date) return index;
        });
        params.api.ensureIndexVisible(i, 'middle');
    }

    ut_routeToCalendar() {
        this.router.navigate(['/academics/calendar/events']);
    }

    pinChart() {
        this.pin = false;
        let payload = ["calendar_setup"];
        this.service.pinWidget(payload).subscribe(res => {
            if (res) this.service.getDashboardWidgets();
        });

    }
    unpinChart() {
        this.pin = true;
        let payload = ["calendar_setup"];
        this.service.unpinWidget(payload).subscribe(res => {
            if (res) this.service.getDashboardWidgets();
        });
    }

    ut_weeklyHolidays() {
        if (this.m.weeklyHolidays) {
            this.rowDataCalendar = [];
            this.m.calendarData.forEach(j => {
                j.events.forEach(e => {
                    let d = new Date(j.date);
                    var date = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
                    this.rowDataCalendar.push({ Date: date, Event: e.title });
                });
            });
            this.rowDataCalendar.sort(function (a, b) {
                return +new Date(a.Date) - +new Date(b.Date);
            });
        }
        else {
            this.rowDataCalendar = [];
            this.m.calendarData.forEach(j => {
                j.events.forEach(e => {
                    if (e.category != 'WeeklyHoliday') {
                        let d = new Date(j.date);
                        var date = d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
                        this.rowDataCalendar.push({ Date: date, Event: e.title });
                    }
                });
            });
            this.rowDataCalendar.sort(function (a, b) {
                return +new Date(a.Date) - +new Date(b.Date);
            });
        }
    }
}