import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { CTApi } from "../../../app/service/ct-api";
import * as rootReducer from '../../../app/rootReducer';
import * as acadAction from '../../academics/store/action';
import * as orgAction from '../../org/store/action';

@Injectable()
export class UserUpgradeService {
    constructor(private httpClient: HttpClient,public ctapi: CTApi, private store: Store<rootReducer.State>) { }

    getCourses() {
        return this.ctapi.getCourses().subscribe(res => {
            this.store.dispatch(new acadAction.SetCourses({ "course_list": res }))
        });
    }

    getInstPersonas(qryStr, instId){
        this.ctapi.getInstPersonas(qryStr, instId)
        .subscribe(
            res => {
              this.store.dispatch(new orgAction.SetOrgPersonas({ "personas": res['data'] }));
            },
            err => {}
        );
      }

      upgradeUser(payload){
          return this.ctapi.upgradeUser(payload);
      }

      updateUserDetails(payload){
          return this.ctapi.updateUserDetails(payload);
      }

      sendVerificationCode(payload){
        return this.ctapi.sendVerificationCode(payload);
      }

      verifySMSCode(payload){
        return this.ctapi.verifySMSCode(payload);
      }
}