import { Directive, ElementRef, Input, Output, OnChanges, EventEmitter, HostBinding, HostListener } from '@angular/core';
declare const google: any;
declare let googleLoaded: any;
declare const googleChartsPackagesToLoad: any;
@Directive({
    selector: '[GoogleTable]'
})

export class GoogleTable implements OnChanges {
    public _element: any;
    @Input('tableColumns') public columns: any[] = [];
    @Input('tableRows') public rows: any[] = [];
    @Input('tableId') public tableId: any[] = [];

    constructor(public element: ElementRef) {
        this._element = this.element.nativeElement;
    }

    ngOnChanges() {
        if (!googleLoaded) {
            googleLoaded = true;
            google.charts.load('current', { 'packages': ['table'] });
        }
        setTimeout(() => this.drawTable(this.columns, this.rows, this.tableId), 0);
    }

    @HostListener('window:resize') onResize(event: Event) {
        this.drawTable(this.columns, this.rows, this.tableId);
    }

    drawTable(columns, rows, tableId) {
        google.charts.setOnLoadCallback(drawTab);
        const self = this;

        function drawTab() {
            var data = new google.visualization.DataTable();
            if (columns && columns.length > 0) {
                columns.forEach(col => {
                    data.addColumn(col.type, col.title);
                });
            }
            if (rows && rows.length > 0) {
                rows.forEach(row => {
                    data.addRows([
                        [row[0], row[1]]
                    ]);
                });
            }
            var table = new google.visualization.Table(document.getElementById(tableId));

            table.draw(data, { showRowNumber: true, width: '100%', height: '100%' });
        }
    }
}