import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';

import * as rootReducer from '../../rootReducer';
import { HomeService as service } from '../service/home-service'

class model {
    acadYearFrom: Date;
    acadYearTo: Date;
    isAcadFiscalYear: boolean = false;
}

@Component({
    selector: 'calendar',
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
    providers: [service]
})
export class CalendarComponent implements OnInit {
    public m: model;
    constructor(private service: service, private store: Store<rootReducer.State>) { }
    ngOnInit() {
        this.init_model();
        this.sub_store();
    }
    init_model() {
        this.m = new model();
    }
    sub_store() {
        this.store.select(rootReducer.get_state_app).subscribe((res: any) => {
            this.m.acadYearFrom = res.currentPersona.acadYearFrom;
            this.m.acadYearTo = res.currentPersona.acadYearTo;
            if (new Date(this.m.acadYearFrom).getMonth() == 3 && new Date(this.m.acadYearFrom).getDate() == 1 && new Date(this.m.acadYearTo).getMonth() == 2 && new Date(this.m.acadYearTo).getDate() == 31) {
                this.m.isAcadFiscalYear = true;
            }
        });
    }
}