import { Action } from '@ngrx/store';
import { type } from '../../../app/util';
import { NotificationForWidget } from '../service/interfaces';

export const ActionTypes = {
    SET_SELECTED_USER_LINK: type('[Accounting] Set Selected User Link'),
    SET_VOUCHER_LIST: type('[Accounting] Set Voucher List'),
    DISABLE_VOUCHER: type('[Accounting] Disable Voucher'),
    ENABLE_VOUCHER: type('[Accounting] Enable Voucher'),
    SET_SOURCE_ACCOUNTS: type('[Accounting] Set Source Accounts'),
    SET_TARGET_ACCOUNTS: type('[Accounting] Set Target Accounts'),
    SET_HTML: type('[Accounting] Set HTML'),
    SET_NOTIFICATION_FOR_WIDGET: type('[Accounting] Set Notification For Widget'),
};

export class SetSelectedUserLink implements Action {
    type = ActionTypes.SET_SELECTED_USER_LINK;
    /**"Id": "" */
    constructor(public payload: any) { }
}

export class SetVoucherList implements Action {
    type = ActionTypes.SET_VOUCHER_LIST;
    constructor(public payload: any) { }
}

export class DisableVoucher implements Action {
    type = ActionTypes.DISABLE_VOUCHER;
    constructor(public payload: any) { }
}

export class EnableVoucher implements Action {
    type = ActionTypes.ENABLE_VOUCHER;
    constructor(public payload: any) { }
}

export class SetSourceAccounts implements Action {
    type = ActionTypes.SET_SOURCE_ACCOUNTS;
    constructor(public payload: any) { }
}

export class SetTargetAccounts implements Action {
    type = ActionTypes.SET_TARGET_ACCOUNTS;
    constructor(public payload: any) { }
}

export class SetHTML implements Action {
    type = ActionTypes.SET_HTML;
    constructor(public payload: any) { }
}
export class SetNotificationForWidget implements Action {
    type = ActionTypes.SET_NOTIFICATION_FOR_WIDGET;
    constructor(public payload: Array<NotificationForWidget>) { }
}

export type Actions = SetSelectedUserLink | SetVoucherList | DisableVoucher | EnableVoucher
    | SetSourceAccounts | SetTargetAccounts | SetHTML | SetNotificationForWidget;