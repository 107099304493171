import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dex-sidebar-content',
  templateUrl: './dex-sidebar-content.component.html',
  styleUrls: ['./dex-sidebar-content.component.css']
})
export class DexSidebarContentComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
