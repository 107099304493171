import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, HostListener } from '@angular/core';
import { NavTreeService as service } from './service/service';
import * as _ from 'underscore';
import 'brace/index';
import 'brace/theme/eclipse';
import 'brace/mode/markdown';
import 'brace/ext/language_tools';
import { Store } from '@ngrx/store';
import * as rootReducer from '../../rootReducer';
import { Router } from '@angular/router';

class Model {
  selectedItem: any = {};
  ctContext: any;
  getSelectedTab(nav) {
		let path = window.location.pathname;
		let pathArr = path.split("/");
		if (nav.route == pathArr[1]) return true;
		else return false;
	};
}

@Component({
  selector: 'nav-tree',
  templateUrl: './component.html',
  styleUrls: ['./component.css'],
  providers: [service],
})
export class NavTreeComponent implements OnInit, OnDestroy {
  @HostListener('document:click', ['$event']) onDocumentClick() {
    this.closeDropdown();
  }

  constructor(private store: Store<rootReducer.State>, public service: service, public router: Router) { }
  @Input('data') items: Array<Object> = [];
  @Output() selectedItem = new EventEmitter<any>();
  feesHovered: boolean = false;
  public m: Model;

  ngOnInit() {
    this.setTab();
    this.init_model();
    this.sub_store();
  }
  ngOnDestroy() {}

  init_model() {
    this.m = new Model();
  }
  sub_store() {
    this.store.select(rootReducer.get_state_app).subscribe(
      context => {
        this.m.ctContext = context;
      });
  }

  setTab() {
    let url = this.router.url;
    var navArr = url.split('/');
    if (this.items) {
      this.items.forEach(item => {
        item['selected'] = false;
        if (item['route'] == navArr[1]) { item['selected'] = true; }
      });
    }
  }

  toggleExpand(item) {
    if (!item.isLeaf) {
      item.expand = !item.expand;
    }
    var _items = this.items.filter((i: any) => i.name != item.name && i['expand'] == true);
    _items.forEach((i: any) => i.expand = false);
    if (item.name == 'Fees' && !this.feesHovered) {
      this.feesHovered = true;
      setTimeout(() => {
        this.service.getAllStudents(true);
      }, 300);
    }
  }
  setSelectedItem(item) {
    item.expand = false;
    this.m.selectedItem = item;
    this.unsetSelected(this.items);
    this.selectedItem.emit(this.m.selectedItem);
    this.m.selectedItem.selected = true;
    if (item.route) {
      let path = "/" + item.route;
      this.router.navigate([path]);
    }
  }

  unsetSelected(nodes) {
    nodes.forEach(node => {
      node.selected = false;
      if (node.children && node.children.length > 0) { this.unsetSelected(node.children); }
    });
  }

  closeDropdown() {
    this.items.forEach((it: any) => it.expand = false);
  }
}