import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as rootReducer from '../rootReducer';
import { Subscription } from 'rxjs/Subscription';
import { Observable } from 'rxjs/Observable';
import 'rxjs/Rx';

class model {
	openDD : any = false;
	selectedTab : {};
}

@Component({
	selector: 'ctMenu',
	templateUrl: './ctMenu.component.html',
	styleUrls: ['./ctMenu.component.css']
})
export class CtMenuComponent implements OnInit {
	public m: model;
	private sub_selected_tab: Subscription;
	constructor(private router: Router,private store: Store<rootReducer.State>) { }
	ngOnInit() {
		this.init_model();
		this.init_store();
		this.sub_store();
	}
	ngOnDestroy() {
		this.sub_selected_tab.unsubscribe();
	}
	init_model() {
		this.m = new model();
	}
	init_store() {
  	}
	sub_store() {
		this.sub_selected_tab = this.store.select(rootReducer.get_selected_tab).subscribe(res => {
			if(res)	{
				this.m.selectedTab = res;}
			},
			err => console.log(err)
		);
	}
}