import { Component, OnInit, TemplateRef, ElementRef, EventEmitter, Output } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { Store } from '@ngrx/store';
import * as rootReducer from '../../rootReducer';

import { Subscription, Observable } from 'rxjs';
import { ConnectService as connectService } from '../../connect/service/service';
import { PulseService } from '../../../app/service/pulse';
import { GoogleMeetService as service } from './service/service';
import * as connectAction from '../../connect/store/action';
import * as googleMeetAction from './store/action';

import { environment } from '../../../environments/environment';

import { Http } from '@angular/http';
import * as _ from 'underscore';
import * as R from 'ramda';

class cell {
    public css_class: string = "cal-default";
    constructor(public value: string, public col_pos: number) { }
}

class Meeting {
    from_time: string;
    to_time: string;
    title: string;
    host: string;
    host_mobiles: string[] = []
    co_hosts: string[] = [];
    attendees: string[] = [];
    status: string;
    meeting_link: string;
    start_time: string;
    event_id: string;
    inst_short_name: string;
}

class Job {
    id: string;
    payload: any;
    status: number;
    statusAsString: string;
    title: string;
}

class Workflow {
    id: string;
    jobs: Job[] = [];
    title: string;
}

class Model {
    noOfClasses: number;
    selectedDate: Date;
    smsRecp: string = "ios-oc";
    userEmail: string;
    enableNotify: boolean;
    smsContent: string = "";
    smsSent: boolean = false;
    showSMSSend: boolean = false;
    parentReceipients: any[] = [];
    studentReceipients: any[] = [];
    manualReceipients: any[] = [];
    facets: any;
    showRefresh: boolean = true;
    emailTemplate: string = "";
    bodyResult: string = "";
    currentPersona: any;
    smsNotification: boolean = false;
    emailNotification: boolean = false;
    smsEmailNotification: boolean = false;
    smsBalance: any;
    workflow: Workflow;
    htmlString: string;
    months: string[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    acadMonths: string[] = [];
    selectedAcadMonth: string;
    days: string[] = ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"];
    dateRows: any[] = [];
    daysInMonth: any[] = new Array<cell>();
    meetings: Meeting[] = [];
    files: any[] = [{
        "fileName": "Chapter 1.pdf",
    }, {
        "fileName": "Chapter 2.pdf"
    }];
    meetingsDate: string;
    todayDate = new Date();
    currentYear = this.todayDate.getFullYear();
    currentMonth = this.todayDate.getMonth() + 1;
    currentDate = this.todayDate.getDate();
    currentDateString = this.currentYear + '-' + ('0' + this.currentMonth).slice(-2) + '-' + ('0' + this.currentDate).slice(-2);
    constructor() { }
    get_firstDate() {
        let arr = this.selectedAcadMonth.split(" ");
        let mth_indx = _.indexOf(this.months, arr[0]);
        return new Date(+arr[1], mth_indx, 1);
    }
    get_lastDate() {
        let arr = this.selectedAcadMonth.split(" ");
        let mth_indx = _.indexOf(this.months, arr[0]);
        return new Date(+arr[1], mth_indx + 1, 0);
    }
    set_dates() {
        this.dateRows = [];
        this.daysInMonth = [];
        this.set_dow();
        let arr = this.selectedAcadMonth.split(" ");
        let mth_indx = _.indexOf(this.months, arr[0]);
        let date = new Date(+arr[1], mth_indx, 1);
        let today = new Date();

        while (date.getMonth() === mth_indx) {
            let cl = new cell("" + date.getDate(), date.getDay());
            if (date.getDay() == 0) { cl.css_class = "cal-holiday"; }
            if (date.setHours(0, 0, 0, 0) == today.setHours(0, 0, 0, 0)) { cl.css_class = "cal-today"; }
            this.daysInMonth.push(cl);
            date.setDate(date.getDate() + 1);
        }
        let blank_dates = [
            new cell("", 0),
            new cell("", 1),
            new cell("", 2),
            new cell("", 3),
            new cell("", 4),
            new cell("", 5),
            new cell("", 6),
        ]
        let first_date = _.first(this.daysInMonth);
        let last_date = _.last(this.daysInMonth);
        let prepend_slice_size = first_date.col_pos - 0;
        let append_slice_size = 6 - last_date.col_pos;
        if (prepend_slice_size > 0) {
            this.daysInMonth = blank_dates.slice(0, prepend_slice_size).concat(this.daysInMonth);
        }
        if (append_slice_size > 0) {
            this.daysInMonth = this.daysInMonth.concat(blank_dates.slice(0 - append_slice_size));
        }
        let rows = this.chunk(this.daysInMonth, 7);
        let rows_copy = this.dateRows;
        _.forEach(rows, function (row, k) {
            rows_copy.push(row);
        });
    }
    set_dow() {
        let row = new Array<cell>();
        _.forEach(this.days, function (d, k) {
            let cl = new cell(d, k);
            cl.css_class = "cal-day";
            row.push(cl);
        });
        this.dateRows.push(row);
    }
    set_months(acad_from: any, acad_to: any) {
        let fd = new Date(acad_from.getFullYear(), acad_from.getMonth(), 1);
        let td = new Date(acad_to.getFullYear(), acad_to.getMonth(), 1);
        while (fd <= td) {
            let mth = this.months[fd.getMonth()] + " " + fd.getFullYear();
            this.acadMonths.push(mth);
            if (fd.getMonth() == 11) {
                fd = new Date(fd.getFullYear() + 1, 0, 1);
            } else {
                fd = new Date(fd.getFullYear(), fd.getMonth() + 1, 1);
            }
        }
        let current_date = new Date();
        this.selectedAcadMonth = this.months[current_date.getMonth()] + " " + current_date.getFullYear();
        let dateOrdinal = this.getOrdinalNum(this.currentDate);
        this.meetingsDate = dateOrdinal + ' ' + this.selectedAcadMonth;
    }

    chunk(arr: any, size: number) {
        var newArr = [];
        for (var i = 0; i < arr.length; i += size) {
            newArr.push(arr.slice(i, i + size));
        }
        return newArr;
    }

    getOrdinalNum(n) {
        return n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
    }
}

@Component({
    selector: 'google-meet',
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
    providers: [service, connectService]
})

export class GoogleMeetComponent implements OnInit {
    public m: Model;
    modalRef: BsModalRef;
    userChannelJoined: boolean = false;
    subs = new Subscription();
    parent_stu_recp_count: number;
    fileList: any[] = [];
    selectedMeetToUploadNotes: Meeting;
    isInstituteLocked: boolean;
    acadYearTo: Date;
    studentProfiles: any;
    currentPersonaTitle: string;
    meetings: Meeting[] = [];
    includeTags: boolean = true; // to filter SMS recipients on tags ios/oc
    @Output() meetingMeta = new EventEmitter();
    constructor(
        private store: Store<rootReducer.State>, private el: ElementRef, private connectService: connectService, private service: service, private pulseService: PulseService, private http: Http, private modalService: BsModalService) { }
    ngOnInit() {
        this.init_model();
        this.sub_store();
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    init_model() {
        this.m = new Model();
    }

    sub_store() {
        this.subs.add(this.store.select(rootReducer.get_state_app).subscribe(res => {
            if (res) {
                this.m.userEmail = res.userEmail;
                this.m.currentPersona = res.currentPersona;
                if (res.currentPersona.title != 'Student') this.getStudentFacets();
                this.isInstituteLocked = res.currentPersona.isInstituteLocked;
                this.currentPersonaTitle = res.currentPersona.title;
                let from = new Date(res.currentPersona.acadYearFrom);
                let to = new Date(res.currentPersona.acadYearTo);
                this.acadYearTo = to;
                this.m.set_months(from, to);
                this.m.set_dates();
                this.m.meetings = [];
                if (this.userChannelJoined && !this.isInstituteLocked) {
                    if (this.acadYearTo >= this.m.todayDate) this.pulseService.get_meetings(this.m.currentDateString.toString());
                }
            }
        }));

        this.subs.add(this.store.select(rootReducer.set_online_class_selected_date).subscribe(res => {
            if (res) {
                this.m.selectedDate = res;
                this.getMeetingsForSelectedDate(res);
            }
        }));

        this.subs.add(this.store.select(rootReducer.user_channel_joined).subscribe(res => {
            if (res) {
                this.userChannelJoined = res;
                if (this.userChannelJoined && !this.isInstituteLocked) {
                    if (this.acadYearTo >= this.m.todayDate) {
                        if (this.m.selectedDate == null)
                            this.pulseService.get_meetings(this.m.currentDateString.toString())
                    };
                }
            }
        }));

        this.subs.add(this.store.select(rootReducer.get_google_meetings).subscribe(res => {
            this.m.noOfClasses = 0;
            if (res) {
                let meetings = res;
                this.meetings = meetings;
                if (meetings && meetings.length > 0) {
                    this.m.noOfClasses = meetings.length;
                    this.setMeetings(meetings);
                }
            }
        }));

        this.subs.add(this.store.select(rootReducer.get_datewise_meetings).subscribe((res: any) => {
            if (res) {
                let arr = Array.isArray(res);
                if (arr) {
                    let meetings = res;
                    this.setMeetings(meetings);
                }
                else {
                    if (Date.parse(this.m.currentDateString) >= Date.parse(res))
                        this.pulseService.get_meetings(res);
                }
            }
        }));

        this.subs.add(this.store.select(rootReducer.get_student_facet).subscribe(res => {
            if (res) {
                this.m.facets = res
            }
        }));

        this.store.select(rootReducer.get_sms_balance).subscribe((res: any) => {
            if (res && res.balance) {
                this.m.smsBalance = res.balance;
            }
        });
    }

    getStudentFacets() {
        this.connectService.getStuFacets(true);
    }

    setMeetings(meetings) {
        if (this.m.currentPersona.title != 'Student') {
            this.m.meetings = meetings;
            this.m.meetings.forEach(meet => {
                let dt = new Date(meet.from_time);
                let hrs = dt.getHours();
                let mins = dt.getMinutes();
                let ampm = hrs >= 12 ? 'pm' : 'am';
                hrs = hrs % 12;
                hrs = hrs ? hrs : 12;
                meet.start_time = "" + hrs + ":" + ('0' + mins).slice(-2) + " " + ampm;
            });
        }
        else {
            if (this.studentProfiles == null || undefined)
                this.service.getStudentProfile(this.m.userEmail).subscribe((studentProfiles: any) => {
                    this.studentProfiles = studentProfiles['data'];
                });
            else {
                let course_list = [];
                this.studentProfiles.forEach(p => {
                    var c;
                    if (p.branch) c = p.courseName.trim() + ' ' + p.branch.trim() + ' ' + p.sectionName.trim();
                    else c = p.courseName.trim() + ' ' + p.sectionName.trim();
                    course_list.push(c);
                });
                course_list = R.uniq(course_list);
                let student_meetings = [];
                course_list.forEach(c => {
                    meetings.forEach(m => {
                        m.attendees.forEach(a => {
                            if (a == c) student_meetings.push(m);
                        });
                    });
                });
                this.m.meetings = student_meetings;
                this.m.meetings.forEach(meet => {
                    let dt = new Date(meet.from_time);
                    let hrs = dt.getHours();
                    let mins = dt.getMinutes();
                    let ampm = hrs >= 12 ? 'pm' : 'am';
                    hrs = hrs % 12;
                    hrs = hrs ? hrs : 12;
                    meet.start_time = "" + hrs + ":" + ('0' + mins).slice(-2) + " " + ampm;
                });
            }
        }
    }

    ut_selectDate(cell, dateRows) {
        this.m.meetings = [];
        if (cell.value != "") {
            dateRows.forEach(row => {
                row.forEach(cl => {
                    if (cl.css_class == "cal-selected") cl.css_class = "cal-default";
                })
            });
            let arr = this.m.selectedAcadMonth.split(" ");
            let mth_indx = _.indexOf(this.m.months, arr[0]);
            let date = new Date(+arr[1], mth_indx, cell.value);

            let month = date.getMonth() + 1;
            let year = date.getFullYear();
            let d = date.getDate();
            let dt = year + '-' + ('0' + month).slice(-2) + '-' + ('0' + d).slice(-2);
            this.store.dispatch(new googleMeetAction.GetMeetings({ "date": dt, "clientShortName": this.m.currentPersona.clientShortName }));
            this.store.dispatch(new googleMeetAction.SetSelectedDate({ "selectedDate": dt }));
            if (this.m.currentDateString >= dt) {
                cell.css_class = this.m.currentDateString === dt ? "cal-today" : this.m.currentDateString < dt ? "cal-default" : "cal-selected";
                this.m.showRefresh = this.m.currentDateString === dt ? true : false;

                let dateOrdinal = this.m.getOrdinalNum(d);
                this.m.meetingsDate = dateOrdinal + ' ' + this.m.selectedAcadMonth;
                this.store.dispatch(new googleMeetAction.GetMeetings({ "date": dt, "clientShortName": this.m.currentPersona.clientShortName }));
            }
        }
    }

    ut_meet(url, eventId) {
        window.open(url, "_blank");
        if (this.m.currentPersona.clientShortName == 'BTLCOL' || this.m.currentPersona.clientShortName == "BTLSCH" || this.m.currentPersona.clientShortName == "VVSSPU") this.pulseService.join_meeting(url, eventId);
    }

    ut_delete(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template);
    }

    ut_viewFile(file) {

    }

    ut_copyURL(template: TemplateRef<any>, url: string, title: string, start_time: string) {
        this.modalRef = this.modalService.show(template);
        var copy_text = start_time + ' ' + title + ' ' + ':' + ' ' + url;
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = copy_text;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        setTimeout(() => {
            this.modalRef.hide();
        }, 2000);
    }

    ut_deleteConfirmed() { }

    ut_addNotes(template: TemplateRef<any>, meetObj) {
        this.modalRef = this.modalService.show(template);
        this.selectedMeetToUploadNotes = meetObj;
        var meta: any = "meeting_id" + ':' + meetObj.event_id;
        this.meetingMeta = meta;
    }

    ut_notes(template: TemplateRef<any>, meet) {
        this.modalRef = this.modalService.show(template);
    }

    ut_chooseFilesToUpload($event) {
        const file = $event.target.files;
        this.fileList.push(file)
    }

    ut_upload() {
        let meet = this.selectedMeetToUploadNotes;
        this.service.uploadNotes(this.fileList, meet.event_id, meet.meeting_link);
    }

    ut_closeUpload() {
        this.modalRef.hide();
        this.fileList = [];
        this.selectedMeetToUploadNotes = new Meeting();
    }


    ut_smsNext() {
        this.m.showSMSSend = true;
        let selected_parent_recp = this.m.parentReceipients.filter(r => { return r.checked });
        let selected_student_recp = this.m.studentReceipients.filter(s => { return s.checked });
        let parent_recp = [];
        selected_parent_recp.forEach(p => {
            let arr = [];
            arr.push(p.courseId);
            arr.push("Annual");
            arr.push(p.section);
            arr.push("*");
            parent_recp.push(arr);
        });

        let student_recp = [];
        selected_student_recp.forEach(s => {
            let arr = [];
            arr.push(s.courseId);
            arr.push("Annual");
            arr.push(s.section);
            arr.push("*");
            student_recp.push(arr);
        });

        let payload = { Parent: parent_recp };
        if (student_recp.length > 0)
            payload = Object.assign({}, payload, { Student: student_recp })
        this.service.getParentSMSRecp(payload, this.m.smsContent, this.includeTags)
            .subscribe(res => {
                let recipientList = [];
                recipientList.concat(res['data']);
                this.parent_stu_recp_count = recipientList.length;
            },
                err => { }
            );
    }

    ut_sendSMS() {
        let selected_parent_recp = this.m.parentReceipients.filter(r => { return r.checked });
        let selected_student_recp = this.m.studentReceipients.filter(s => { return s.checked });
        let parent_recp = [];
        selected_parent_recp.forEach(p => {
            let arr = [];
            arr.push(p.courseId);
            arr.push("Annual");
            arr.push(p.section);
            arr.push("*");
            parent_recp.push(arr);
        });

        let student_recp = [];
        selected_student_recp.forEach(s => {
            let arr = [];
            arr.push(s.courseId);
            arr.push("Annual");
            arr.push(s.section);
            arr.push("*");
            student_recp.push(arr);
        });

        let stu_recp = student_recp.length > 0 ? student_recp : undefined;

        let final_recp_count = this.m.manualReceipients.length + this.parent_stu_recp_count;

        let payload = { Parent: parent_recp, Student: stu_recp };
        var ownerId = this.m.currentPersona.clientId;
        var partyId = this.m.currentPersona.clientId;
        var creditItems: any = [{
            'InventoryItemId': environment.SMS_INVENTORY_ID,
            'TrackingIds': [],
            'Rate': 1.0,
            'Quantity': 1 * final_recp_count,
        }];
        var debitItems: any = [];
        var date = new Date();
        var reference = "SMS Sent";
        var particulars = this.m.smsContent;

        this.service.createInventoryJournal(ownerId, partyId, date, creditItems, debitItems, reference, particulars).subscribe();
        this.service.sendParentSMS(payload, this.m.smsContent, "", this.includeTags)
            .subscribe(res => {
                //  this.m.smsSent = true;
            },
                err => { }
            );

        // let manual_sms_payload = { Manual: this.m.manualReceipients };
        // this.connectService.sendManualSMS(manual_sms_payload, this.m.smsContent, "")
        //     .subscribe(res => {
        //         this.m.smsSent = true;
        //     },
        //         err => { }
        //     );
        this.ut_closeNotify();
    }

    ut_notify(template: TemplateRef<any>, meetObj) {
        this.modalRef = this.modalService.show(template);

        let selectedMeet: Meeting = meetObj;

        this.m.enableNotify = selectedMeet.status == 'scheduled' ? false : true;

        this.m.smsContent = selectedMeet.title + ' ' + '-' + ' ' + selectedMeet.start_time + ':' + ' ' + selectedMeet.meeting_link;

        selectedMeet.attendees.forEach(att => {
            let crs_sec_arr = att.split(' ');
            let sec = crs_sec_arr.pop();
            let course = crs_sec_arr.join(' ');
            var course_id = get_course(course, this.m.facets);
            let p_obj = { courseId: course_id, name: att, section: sec, checked: true };
            this.m.parentReceipients.push(p_obj);
            let s_obj = { courseId: course_id, name: att, section: sec, checked: false };
            this.m.studentReceipients.push(s_obj);
        });

        // this.m.manualReceipients = selectedMeet.host_mobiles;

        // if (this.m.emailTemplate == null || this.m.emailTemplate == "") {
        //     this.connectService.getEmailTemplate().subscribe((res: any) => {
        //         if (res) {
        //             this.m.emailTemplate = res.data;
        //             this.renderEmailPreview(this.m.emailTemplate, selectedMeet);
        //         }
        //     });
        // }
        // else {
        //     this.renderEmailPreview(this.m.emailTemplate, selectedMeet);
        // }
    }

    ut_closeNotify() {
        this.modalRef.hide();
        this.m.smsNotification = false;
        this.m.emailNotification = false;
        this.m.smsEmailNotification = false;
        this.m.parentReceipients = [];
        this.m.studentReceipients = [];
        this.m.manualReceipients = [];
        this.m.showSMSSend = false;
        // this.m.smsSent = false;
        this.m.smsContent = "";
        this.parent_stu_recp_count = 0;
    }

    ut_refresh() {
        if (this.acadYearTo >= this.m.todayDate) this.pulseService.get_meetings(this.m.currentDateString.toString());
    }

    renderEmailPreview(emailTemplate, selectedMeet) {
        let line1 = 'Online class for your ward on' + ' ' + this.m.meetingsDate + ' ' + 'at' + ' ' + selectedMeet.start_time;
        let line2 = 'Subject :' + ' ' + selectedMeet.title;
        let line3 = 'Link :';

        let html = `<p>${line1} <br />${line2} <br />${line3}&nbsp;<a style="cursor:pointer;">${selectedMeet.meeting_link}</a></p>`

        this.m.bodyResult = emailTemplate.replace("{{email_body}}", html);
        this.store.dispatch(new connectAction.SetEmailBody({ "emailBody": this.m.bodyResult }));
        if (this.m.workflow && this.m.workflow.jobs) {
            this.m.workflow.jobs.forEach(job => {
                if (job.title == "email_set_body") {
                    job.payload["body"] = this.m.bodyResult;
                }
            });
            this.store.dispatch(new connectAction.SetWorkflow({ "workflow": this.m.workflow }));
        }
    }

    ut_setRecipients(recipients) {
        if (recipients == "all") this.includeTags = false;
        else if (recipients == "ios-oc") this.includeTags = true;
    }

    getMeetingsForSelectedDate(date) {
        this.store.dispatch(new googleMeetAction.GetMeetings({ "date": date, "clientShortName": this.m.currentPersona.clientShortName }));

        let _date = new Date(date);
        var d = date.split("-");
        this.m.selectedAcadMonth = this.m.months[_date.getMonth()] + " " + _date.getFullYear();
        let dateOrdinal = this.m.getOrdinalNum(d[2]);
        this.m.meetingsDate = dateOrdinal + ' ' + this.m.selectedAcadMonth;
    }
}

function get_course(course_name, facets) {
    let arr = []
    facets.forEach(x => {
        x.children.forEach(y => {
            if (y.name == course_name) arr.push(y.elemId);
        })
    })
    return arr[0]
}