// import { createSelector, createSelectorCreator } from 'reselect';

/**
 * The compose function is one of our most handy tools. In basic terms, you give
 * it any number of functions and it returns a function. This new function
 * takes a value and chains it through every composed function, returning
 * the output.
 *
 * More: https://drboolean.gitbooks.io/mostly-adequate-guide/content/ch5.html
 */
// import { compose } from '@ngrx/core/compose';

/**
 * storeFreeze prevents state from being mutated. When mutation occurs, an
 * exception will be thrown. This is useful during development mode to
 * ensure that none of the reducers accidentally mutates the state.
 */
// import { storeFreeze } from 'ngrx-store-freeze';

/**
 * combineReducers is another useful metareducer that takes a map of reducer
 * functions and creates a new reducer that gathers the values
 * of each reducer and stores them using the reducer's key. Think of it
 * almost like a database, where every reducer is a table in the db.
 *
 * More: https://egghead.io/lessons/javascript-redux-implementing-combinereducers-from-scratch
 */
import { combineReducers } from '@ngrx/store';
import { compose } from "@ngrx/core";
import { localStorageSync, LocalStorageConfig } from 'ngrx-store-localstorage';
import { ActionReducer, ActionReducerMap, createSelector, MetaReducer } from '@ngrx/store';
/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */
import * as appReducer from '../app/store/app-reducer';
import * as academicsReducer from './academics/store/reducer';
import * as adminReducer from './admin/store/reducer';
import * as admissionsReducer from './admissions/store/reducer';
import * as artefactReducer from './artefact/store/reducer';
import * as assetReducer from './asset/store/reducer';
import * as connectReducer from './connect/store/reducer';
import * as feeReducer from './fees/store/reducer';
import * as libraryReducer from './library/store/reducer';
import * as navTabsReducer from './navTabs/store/navTabs-reducer';
import * as staffReducer from './staff/store/reducer';
import * as studentReducer from './student/store/reducer';
import * as personaReducer from './persona/store/reducer';
import * as userProfileReducer from './userProfile/store/reducer';
import * as rcReducer from './rc/store/reducer';
import * as receiptsReducer from './receipts/store/reducer';
import * as reportReducer from './report/store/reducer';
import * as instReducer from './inst/store/reducer';
import * as homeReducer from './home/store/reducer';
import * as orgReducer from './org/store/reducer';
import * as dataTemplateReducer from './data/store/reducer';
import * as courseReducer from './course/store/reducer';
import * as examReducer from './exam/store/reducer';
import * as timetablePlannerReducer from './timetablePlanner/store/reducer';
import * as pulseReducer from './pulse/reducer';
import * as timetableReducer from './timetable/store/reducer';
import * as googleMeetReducer from './components/google-meet/store/reducer';
import * as countdownTimerReducer from './components/countdownTimer/store/reducer';
import * as notificationReducer from './notifications/store/reducer';
import * as assessmentReducer from './assessment/store/reducer';
import * as widgetReducer from './widgets/store/reducer';
import * as accountingReducer from './accounting/store/reducer';
import * as cardsReducer from './cards/store/reducer';
import * as introReducer from './store/intro-reducer';

import { environment } from '../environments/environment';
// step#1 import new reducer above this line

// combine the state(s)
export interface State {
    app: appReducer.State;
    academics: academicsReducer.State;
    admin: adminReducer.State;
    admissions: admissionsReducer.State;
    artefact: artefactReducer.State;
    asset: assetReducer.State;
    connect: connectReducer.State;
    fees: feeReducer.State;
    library: libraryReducer.State;
    navTabs: navTabsReducer.State;
    staff: staffReducer.State;
    student: studentReducer.State;
    persona: personaReducer.State;
    userProfile: userProfileReducer.State;
    rc: rcReducer.State;
    receipts: receiptsReducer.State;
    report: reportReducer.State;
    inst: instReducer.State;
    home: homeReducer.State;
    org: orgReducer.State;
    dataTemplate: dataTemplateReducer.State;
    course: courseReducer.State;
    exam: examReducer.State;
    timetablePlanner: timetablePlannerReducer.State;
    pulse: pulseReducer.State;
    timetable: timetableReducer.State;
    googleMeet: googleMeetReducer.State;
    countdownTimer: countdownTimerReducer.State;
    notification: notificationReducer.State;
    assessment: assessmentReducer.State;
    widgets: widgetReducer.State;
    accounting: accountingReducer.State;
    cards: cardsReducer.State;
    intro: introReducer.State;
}

// combine reducers
export const reducers: ActionReducerMap<State> = {
    app: appReducer.reducer,
    academics: academicsReducer.reducer,
    admin: adminReducer.reducer,
    admissions: admissionsReducer.reducer,
    artefact: artefactReducer.reducer,
    asset: assetReducer.reducer,
    connect: connectReducer.reducer,
    fees: feeReducer.reducer,
    library: libraryReducer.reducer,
    navTabs: navTabsReducer.reducer,
    staff: staffReducer.reducer,
    persona: personaReducer.reducer,
    userProfile: userProfileReducer.reducer,
    rc: rcReducer.reducer,
    receipts: receiptsReducer.reducer,
    report: reportReducer.reducer,
    inst: instReducer.reducer,
    home: homeReducer.reducer,
    org: orgReducer.reducer,
    student: studentReducer.reducer,
    dataTemplate: dataTemplateReducer.reducer,
    course: courseReducer.reducer,
    exam: examReducer.reducer,
    timetablePlanner: timetablePlannerReducer.reducer,
    pulse: pulseReducer.reducer,
    timetable: timetableReducer.reducer,
    googleMeet: googleMeetReducer.reducer,
    countdownTimer: countdownTimerReducer.reducer,
    notification: notificationReducer.reducer,
    assessment: assessmentReducer.reducer,
    widgets: widgetReducer.reducer,
    accounting: accountingReducer.reducer,
    cards: cardsReducer.reducer,
    intro: introReducer.reducer
};

// Add the reducer state to keys
export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    const config: LocalStorageConfig = {
        keys: ['app', 'academics', 'admin', 'admissions', 'artefact', 'asset', 'connect', 'fees', 'library', 'navTabs', 'staff', 'persona', 'userProfile', 'rc', 'receipts', 'report', 'inst', 'org', 'student', 'dataTemplate', 'env', 'activity', 'workbook', 'course', 'exam', 'home', 'timetablePlanner', 'pulse', 'googleMeet', 'notification', 'assessment', 'widgets', 'accounting', 'cards', 'intro'],
        rehydrate: true,
        storage: sessionStorage
    };
    return localStorageSync(config)(reducer);
}

// console.log all actions
export function debug(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
        if (environment.ENV == 'dev') {
            // console.log('state', state);
            // console.log('action', action);
        }
        return reducer(state, action);
    };
}

export function logout(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
        return reducer(action.type === "[App] Logout" ? undefined : state, action);
    }
}

export const metaReducers: MetaReducer<any, any>[] = [localStorageSyncReducer, logout, debug];

// const developmentReducer: ActionReducer<State> = compose(storeFreeze, combineReducers)(reducers);
const productionReducer: ActionReducer<State> = compose(localStorageSyncReducer, combineReducers)(reducers);

export function reducer(state: any, action: any) {
    return productionReducer(state, action);
}

/**
 * A selector function is a map function factory. We pass it parameters and it
 * returns a function that maps from the larger state tree into a smaller
 * piece of state. This selector simply selects the `books` state.
 *
 * Selectors are used with the `select` operator.
 *
 * ```ts
 * class MyComponent {
 * 	constructor(state$: Observable<State>) {
 * 	  this.booksState$ = state$.select(getBooksState);
 * 	}
 * }
 * ```
 */
export const getAppState = (state: State) => state.app;
export const getAcadState = (state: State) => state.academics;
export const getAdminState = (state: State) => state.admin;
export const getAdmissionsState = (state: State) => state.admissions;
export const getArtefactState = (state: State) => state.artefact;
export const getAssetState = (state: State) => state.asset;
export const getConnectState = (state: State) => state.connect;
export const getFeesState = (state: State) => state.fees;
export const getLibraryState = (state: State) => state.library;
export const getNavTabsState = (state: State) => state.navTabs;
export const getStaffState = (state: State) => state.staff;
export const getStudentState = (state: State) => state.student;
export const getPersonaState = (state: State) => state.persona;
export const getUserProfileState = (state: State) => state.userProfile;
export const getRCState = (state: State) => state.rc;
export const getReceiptsState = (state: State) => state.receipts;
export const getReportState = (state: State) => state.report;
export const getInstState = (state: State) => state.inst;
export const getHomeState = (state: State) => state.home;
export const getOrgState = (state: State) => state.org;
export const getDataTemplateState = (state: State) => state.dataTemplate;
export const getCourseState = (state: State) => state.course;
export const getExamState = (state: State) => state.exam;
export const getTimetablePlannerState = (state: State) => state.timetablePlanner;
export const getPulseState = (state: State) => state.pulse;
export const getTimetableState = (state: State) => state.timetable;
export const getGoogleMeetState = (state: State) => state.googleMeet;
export const getCountdownTimerState = (state: State) => state.countdownTimer;
export const getNotificationState = (state: State) => state.notification;
export const getAssessmentState = (state: State) => state.assessment;
export const getWidgetState = (state: State) => state.widgets;
export const getAccountingState = (state: State) => state.accounting;
export const getCardsState = (state: State) => state.cards;
export const getIntroState = (state: State) => state.intro;

/**
 * Every reducer module exports selector functions, however child reducers
 * have no knowledge of the overall state tree. To make them useable, we
 * need to make new selectors that wrap them.
 *
 * The createSelector function from the reselect library creates
 * very efficient selectors that are memoized and only recompute when arguments change.
 * The created selectors can also be composed together to select different
 * pieces of state.
 */
export const get_state_app = createSelector(getAppState, appReducer.get_state_app);
//Domicile
export const get_states_data = createSelector(getAppState, appReducer.get_states_data);
export const get_user_status = createSelector(getAppState, appReducer.getUserStatus);
export const get_rec_act = createSelector(getAppState, appReducer.get_rec_act);
export const get_notifications = createSelector(getAppState, appReducer.get_notifications);
export const get_reqDate = createSelector(getAppState, appReducer.get_reqDate);
export const get_accessToken = createSelector(getAppState, appReducer.get_accessToken);
export const get_user_links = createSelector(getAppState, appReducer.get_user_links);
export const get_selected_tab = createSelector(getNavTabsState, navTabsReducer.getTab);
export const pulse = createSelector(getAppState, appReducer.pulse);
export const get_user_updated_data = createSelector(getAppState, appReducer.get_user_updated_data);
export const user_channel_joined = createSelector(getAppState, appReducer.user_channel_joined);
export const get_topic_list = createSelector(getAppState, appReducer.get_topic_list);
export const get_main_topic_list = createSelector(getAppState, appReducer.get_main_topic_list);
export const get_topics_stats = createSelector(getAppState, appReducer.get_topics_stats);
export const get_topics_stats_maped = createSelector(getAppState, appReducer.get_topics_stats_maped);
export const get_reset_tab = createSelector(getAppState, appReducer.get_reset_tab);
export const get_clipboard = createSelector(getAppState, appReducer.get_clipboard);
export const get_copy_items = createSelector(getAppState, appReducer.get_copy_items);
export const get_selected_nb_display = createSelector(getAppState, appReducer.get_selected_nb_display);
export const get_selected_app_topic = createSelector(getAppState, appReducer.get_selected_topic);
export const get_chat = createSelector(getAppState, appReducer.get_chat);
export const get_commands = createSelector(getAppState, appReducer.get_commands);
export const get_chat_options = createSelector(getAppState, appReducer.get_chat_options);
export const get_ack_indx = createSelector(getAppState, appReducer.get_ack_indx);
export const set_sign_in_page = createSelector(getAppState, appReducer.set_sign_in_page);
export const reset_password_email_sent = createSelector(getAppState, appReducer.reset_password_email_sent);
export const get_app_status = createSelector(getAppState, appReducer.get_app_status);
export const get_scheduled_publishes = createSelector(getAppState, appReducer.get_scheduled_publishes);

export const get_state_academics = createSelector(getAcadState, academicsReducer.get_state_academics);
export const get_courses_for_acad = createSelector(getAcadState, academicsReducer.get_courses_for_acad);
export const get_students_for_acad = createSelector(getAcadState, academicsReducer.get_students_for_acad);
export const get_students_for_exam_facet = createSelector(getAcadState, academicsReducer.get_students_for_exam_facet);
export const get_exam_facet_for_acad = createSelector(getAcadState, academicsReducer.get_exam_facet);
export const get_course_facet = createSelector(getAcadState, academicsReducer.get_course_facet);
export const get_absentee_list = createSelector(getAcadState, academicsReducer.get_absentee_list);
export const get_courses_sectionwise_for_acad = createSelector(getAcadState, academicsReducer.get_courses_sectionwise_for_acad)
export const get_exams_for_acad = createSelector(getAcadState, academicsReducer.get_exams_coursewise_for_acad);
export const get_all_exams_for_acad = createSelector(getAcadState, academicsReducer.get_all_exams_for_acad);
export const get_selected_exams = createSelector(getAcadState, academicsReducer.get_selected_exams);
export const get_questions = createSelector(getAcadState, academicsReducer.get_questions);
export const get_all_tags = createSelector(getAcadState, academicsReducer.get_all_tags);
export const get_selected_question = createSelector(getAcadState, academicsReducer.get_selected_question);
export const get_q_total_pages = createSelector(getAcadState, academicsReducer.get_q_total_pages);
export const get_topics = createSelector(getAcadState, academicsReducer.get_topics);
export const get_topics_by_content = createSelector(getAcadState, academicsReducer.get_topics_by_content);
export const get_selected_topic = createSelector(getAcadState, academicsReducer.get_selected_topic);
export const get_all_content_tags = createSelector(getAcadState, academicsReducer.get_all_content_tags);
export const get_published_topics = createSelector(getAcadState, academicsReducer.get_published_topics);

export const get_state_admin = createSelector(getAdminState, adminReducer.get_state_admin);
export const get_html_admin = createSelector(getAdminState, adminReducer.get_html_admin);
export const get_courses_for_admin = createSelector(getAdminState, adminReducer.get_courses_for_admin);

//Widgets
export const get_state_widgets = createSelector(getWidgetState, widgetReducer.get_state_widget);
export const get_widgets = createSelector(getWidgetState, widgetReducer.get_widgets);
export const get_selected_widget = createSelector(getWidgetState, widgetReducer.get_selected_widget);
export const get_sample_data = createSelector(getWidgetState, widgetReducer.get_sample_data);
export const get_chart_dashboard_data = createSelector(getWidgetState, widgetReducer.get_chart_dashboard_data);
export const get_chart_dashboard_specs = createSelector(getWidgetState, widgetReducer.get_chart_dashboard_specs);
export const get_dashboard_specs = createSelector(getWidgetState, widgetReducer.get_dashboard_specs);
export const get_chart_meta_data = createSelector(getWidgetState, widgetReducer.get_chart_meta_data);

// Pulse
export const get_state_pulse = createSelector(getPulseState, pulseReducer.get_state_pulse);
export const get_institute_setup_widget = createSelector(getPulseState, pulseReducer.get_institute_setup_widget);
export const get_connect_setup_widget = createSelector(getPulseState, pulseReducer.get_connect_setup_widget);
export const get_academics_setup_widget = createSelector(getPulseState, pulseReducer.get_academics_setup_widget);
export const get_student_enrollment_setup_widget = createSelector(getPulseState, pulseReducer.get_student_enrollment_setup_widget);
export const get_calendar_setup_widget = createSelector(getPulseState, pulseReducer.get_calendar_setup_widget);
export const get_user_access_widget = createSelector(getPulseState, pulseReducer.get_user_access_widget);

export const get_state_asset = createSelector(getAssetState, assetReducer.get_state_asset);
export const get_html_asset = createSelector(getAssetState, assetReducer.get_html_asset);
export const get_selected_asset = createSelector(getAssetState, assetReducer.get_selected_asset);

export const get_state_connect = createSelector(getConnectState, connectReducer.get_state_connect);
export const get_html_connect = createSelector(getConnectState, connectReducer.get_html_connect);
export const get_sms = createSelector(getConnectState, connectReducer.get_sms);
export const get_email = createSelector(getConnectState, connectReducer.get_email);

export const get_student_facet = createSelector(getConnectState, connectReducer.get_student_facet);
export const get_student_facet_email = createSelector(getConnectState, connectReducer.get_student_facet_email);
export const get_approved_templates = createSelector(getConnectState, connectReducer.get_approved_templates);
export const get_sms_recipients = createSelector(getConnectState, connectReducer.get_sms_recipients);
export const get_parent_recipients = createSelector(getConnectState, connectReducer.get_parent_recipients);
export const get_staff_facet = createSelector(getConnectState, connectReducer.get_staff_facet);
export const get_email_recipients = createSelector(getConnectState, connectReducer.get_email_recipients);
export const get_email_manual_recipients = createSelector(getConnectState, connectReducer.get_email_manual_recipients);
export const get_email_type = createSelector(getConnectState, connectReducer.get_email_type);
export const get_email_from = createSelector(getConnectState, connectReducer.get_email_from);
export const get_email_subject = createSelector(getConnectState, connectReducer.get_email_subject);
export const get_email_sms = createSelector(getConnectState, connectReducer.get_email_sms);
export const get_email_body = createSelector(getConnectState, connectReducer.get_email_body);
export const get_email_to = createSelector(getConnectState, connectReducer.get_email_to);
export const get_email_attachment = createSelector(getConnectState, connectReducer.get_email_attachment);
export const get_sms_total_pages = createSelector(getConnectState, connectReducer.get_sms_total_pages);
export const get_email_total_pages = createSelector(getConnectState, connectReducer.get_email_total_pages);
export const get_email_work_flow = createSelector(getConnectState, connectReducer.get_email_work_flow);
export const get_smsTemplates = createSelector(getConnectState, connectReducer.get_smsTemplates);
export const get_sms_delivery_status = createSelector(getConnectState, connectReducer.get_sms_delivery_status);
export const get_sms_delivery_data = createSelector(getConnectState, connectReducer.get_sms_delivery_data);
export const get_sms_delivery_chart_data = createSelector(getConnectState, connectReducer.get_sms_delivery_chart_data);
export const get_email_delivery_data = createSelector(getConnectState, connectReducer.get_email_delivery_data);
export const get_email_delivery_status = createSelector(getConnectState, connectReducer.get_email_delivery_status);
export const get_selected_email = createSelector(getConnectState, connectReducer.get_selected_email);
export const get_sms_balance = createSelector(getConnectState, connectReducer.get_sms_balance);
export const get_email_balance = createSelector(getConnectState, connectReducer.get_email_balance);
export const get_selected_month_year = createSelector(getConnectState, connectReducer.get_selected_month_year);
export const get_connect_info = createSelector(getConnectState, connectReducer.get_connect_info);

export const get_state_fees = createSelector(getFeesState, feeReducer.get_state_fees);
export const get_state_receipts = createSelector(getReceiptsState, receiptsReducer.get_state_receipts);
export const get_html_receipts = createSelector(getReceiptsState, receiptsReducer.get_html_receipts);
export const get_receipts = createSelector(getReceiptsState, receiptsReducer.get_receipts);
export const get_selected_receipt = createSelector(getReceiptsState, receiptsReducer.get_selected_receipt);
export const get_state_rc = createSelector(getRCState, rcReducer.get_state_rc);
export const get_html_rc = createSelector(getRCState, rcReducer.get_html_rc);

export const get_state_library = createSelector(getLibraryState, libraryReducer.get_state_library);
export const get_material = createSelector(getLibraryState, libraryReducer.get_material);
export const get_member = createSelector(getLibraryState, libraryReducer.get_member);
export const get_material_to_circulate = createSelector(getLibraryState, libraryReducer.get_material_to_circulate);
export const get_material_to_issue = createSelector(getLibraryState, libraryReducer.get_material_to_issue);
export const get_material_to_return_or_reissue = createSelector(getLibraryState, libraryReducer.get_material_to_return_or_reissue);
export const get_materials_under_circulation = createSelector(getLibraryState, libraryReducer.get_materials_under_circulation);
export const get_library_context = createSelector(getLibraryState, libraryReducer.get_library_context);
export const get_selected_material = createSelector(getLibraryState, libraryReducer.get_selected_material);
export const get_selected_user_link = createSelector(getLibraryState, libraryReducer.get_selected_user_link);
export const get_materials_total_pages = createSelector(getLibraryState, libraryReducer.get_materials_total_pages);
export const get_material_for_stock_verification = createSelector(getLibraryState, libraryReducer.get_material_for_stock_verification);
export const get_lib_manage_search_term = createSelector(getLibraryState, libraryReducer.get_lib_manage_search_term);
export const get_circulation_search_term = createSelector(getLibraryState, libraryReducer.get_circulation_search_term);
export const get_lib_manage_query_string = createSelector(getLibraryState, libraryReducer.get_lib_manage_query_string);
export const get_lib_due_date = createSelector(getLibraryState, libraryReducer.get_library_due_date);

//timetable
export const get_timetable = createSelector(getTimetableState, timetableReducer.get_timetable);


export const get_fees = createSelector(getFeesState, feeReducer.get_fees);
export const get_fee_context = createSelector(getFeesState, feeReducer.get_fee_context);
export const get_selected_fee = createSelector(getFeesState, feeReducer.get_selected_fee);
export const get_fees_with_installments = createSelector(getFeesState, feeReducer.get_fees_with_installments);

export const get_searched_student = createSelector(getFeesState, feeReducer.get_searched_student);
export const get_students_for_fees = createSelector(getFeesState, feeReducer.get_students_for_fees);
export const get_courses_for_fees = createSelector(getFeesState, feeReducer.get_courses_for_fees);

export const get_fee_courses_selected = createSelector(get_selected_fee, get_courses_for_fees, (fee, courses) => {
    if (courses && fee) {
        courses.forEach(c => {
            c.status = false;
            if (fee && fee.courseList) {
                var crs = fee.courseList.find(fc => fc == c.id);
                if (crs) c.status = true;
            }
        });
        return { "fee": fee, "courses": courses };
    }
});

export const get_html_fee = createSelector(getFeesState, feeReducer.get_html_fee);
export const get_artefacts = createSelector(getArtefactState, artefactReducer.get_artefacts);
export const get_selected_artefact = createSelector(getArtefactState, artefactReducer.get_selected_artefact);
export const get_artefact_context = createSelector(getArtefactState, artefactReducer.get_artefact_context);
export const get_url = createSelector(getArtefactState, artefactReducer.get_url);
export const get_exams_for_arte = createSelector(getArtefactState, artefactReducer.get_exams_for_arte);
export const get_artefact_data = createSelector(getArtefactState, artefactReducer.get_artefact_data);
export const get_html_artefact = createSelector(getArtefactState, artefactReducer.get_html_artefact);
export const get_selecetd_etl = createSelector(getArtefactState, artefactReducer.get_selected_etl);
export const get_etl_folder_files = createSelector(getArtefactState, artefactReducer.get_etl_folder_files);

export const get_reports = createSelector(getReportState, reportReducer.get_reports);
export const get_selected_report = createSelector(getReportState, reportReducer.get_selected_report);
export const get_template = createSelector(getReportState, reportReducer.get_template);
export const get_report_context = createSelector(getReportState, reportReducer.get_report_context);
export const get_view = createSelector(getReportState, reportReducer.get_view);
export const get_staff = createSelector(getStaffState, staffReducer.get_staff);
export const get_selected_staff = createSelector(getStaffState, staffReducer.get_selected_staff);
export const get_state_staff = createSelector(getStaffState, staffReducer.get_state_staff);
export const get_courses_for_staff = createSelector(getStaffState, staffReducer.get_courses_for_staff);
export const get_students = createSelector(getStudentState, studentReducer.get_students);
export const get_filters = createSelector(getStudentState, studentReducer.get_filters);
export const get_selected_student = createSelector(getStudentState, studentReducer.get_selected_student);
export const get_state_student = createSelector(getStudentState, studentReducer.get_selected_student);
export const get_course_id = createSelector(getStudentState, studentReducer.get_course_id);
export const get_courses_for_students = createSelector(getStudentState, studentReducer.get_courses_for_students);
export const get_student_essentials = createSelector(getStudentState, studentReducer.get_stu_essentials);
export const get_student_document_url = createSelector(getStudentState, studentReducer.get_document_url);
export const get_baseline_data_form = createSelector(getStudentState, studentReducer.get_baseline_data_form);
export const get_baseline_data_list = createSelector(getStudentState, studentReducer.get_baseline_data_list);

export const get_salary_structures = createSelector(getStaffState, staffReducer.get_salary_structures);
export const get_current_salary_structure = createSelector(getStaffState, staffReducer.get_current_salary_structure);
export const get_staff_salaries = createSelector(getStaffState, staffReducer.get_staff_salaries);
export const get_selected_staff_details = createSelector(getStaffState, staffReducer.get_selected_staff_details);
export const get_payslip_month_year = createSelector(getStaffState, staffReducer.get_payslip_month_year);

export const get_student_manage_search_term = createSelector(getStudentState, studentReducer.get_stu_manage_search_term);
export const get_student_manage_query_string = createSelector(getStudentState, studentReducer.get_stu_manage_query_string);

export const get_new_transaction = createSelector(getFeesState, feeReducer.get_new_transaction);
export const get_tx_list = createSelector(getFeesState, feeReducer.get_tx_list);
export const get_student_tx = createSelector(getFeesState, feeReducer.get_student_tx);
export const get_selected_tx = createSelector(getFeesState, feeReducer.get_selected_tx);
export const get_tx_date = createSelector(getFeesState, feeReducer.get_tx_date);
export const get_tx_search_term = createSelector(getFeesState, feeReducer.get_tx_search_term);
export const get_tx_query_string = createSelector(getFeesState, feeReducer.get_tx_query_string);

export const get_personas = createSelector(getPersonaState, personaReducer.get_personas);
export const get_selected_persona = createSelector(getPersonaState, personaReducer.get_selected_persona);
export const get_user_profile_details = createSelector(getUserProfileState, userProfileReducer.get_user_profile_details);
export const get_insts = createSelector(getInstState, instReducer.get_insts);
export const get_selected_inst = createSelector(getInstState, instReducer.get_selected_inst);
export const get_sms_tx = createSelector(getInstState, instReducer.get_sms_tx);
export const get_wf_def = createSelector(getInstState, instReducer.get_wf_def);
export const get_wf_exec = createSelector(getInstState, instReducer.get_wf_exec);
export const get_files = createSelector(getInstState, instReducer.get_files);

export const get_orgs = createSelector(getOrgState, orgReducer.get_orgs);
export const get_first_level_orgs = createSelector(getOrgState, orgReducer.get_first_level_orgs);
export const get_modules = createSelector(getOrgState, orgReducer.get_modules);
export const get_selected_org = createSelector(getOrgState, orgReducer.get_selected_org);
export const get_selected_client = createSelector(getOrgState, orgReducer.get_selected_client);
export const get_org_insts = createSelector(getOrgState, orgReducer.get_org_insts);
export const get_dataTemplates = createSelector(getDataTemplateState, dataTemplateReducer.get_dataTemplates);
export const get_selected_dataTemplate = createSelector(getDataTemplateState, dataTemplateReducer.get_selected_dataTemplate);
export const get_content = createSelector(getDataTemplateState, dataTemplateReducer.get_content);
export const get_dataTemplate_context = createSelector(getDataTemplateState, dataTemplateReducer.get_dataTemplate_context);
export const get_model_content = createSelector(getDataTemplateState, dataTemplateReducer.get_model_content);

export const get_client_users = createSelector(getOrgState, orgReducer.get_client_users);
export const get_client_personas = createSelector(getOrgState, orgReducer.get_client_personas);
export const get_selected_user = createSelector(getOrgState, orgReducer.get_selected_user);
export const get_selected_client_persona = createSelector(getOrgState, orgReducer.get_selected_persona);
export const get_client_permissions = createSelector(getOrgState, orgReducer.get_client_permissions);
export const get_user_manage_pages_count = createSelector(getOrgState, orgReducer.get_user_manage_pages_count);

export const get_course_state = createSelector(getCourseState, courseReducer.get_course_state);
export const get_courses = createSelector(getCourseState, courseReducer.get_courses);
export const get_course_selected = createSelector(getCourseState, courseReducer.get_course_selected);
export const get_course_context = createSelector(getCourseState, courseReducer.get_course_context);
export const get_course_term_selected = createSelector(getCourseState, courseReducer.get_course_term_selected);
export const get_course_section_selected = createSelector(getCourseState, courseReducer.get_course_section_selected);
export const get_selected_section_ = createSelector(getCourseState, courseReducer.get_selected_section);
export const get_selected_section_tt = createSelector(getCourseState, courseReducer.get_selected_section_tt);
export const get_course_section_subject_selected = createSelector(getCourseState, courseReducer.get_course_section_subject_selected);
export const get_course_search_term = createSelector(getCourseState, courseReducer.get_course_search_term);
export const get_course_query_string = createSelector(getCourseState, courseReducer.get_course_query_string);

export const get_exam_state = createSelector(getExamState, examReducer.get_exam_state);
export const get_exams = createSelector(getExamState, examReducer.get_exams);
export const get_selected_exam = createSelector(getExamState, examReducer.get_selected_exam);
export const get_eval_students = createSelector(getExamState, examReducer.get_eval_students);
export const get_grading = createSelector(getExamState, examReducer.get_grading);

// Home
export const get_institutes = createSelector(getHomeState, homeReducer.get_institutes);
export const get_dashboard_widgets = createSelector(getHomeState, homeReducer.get_dashboard_widgets);
export const get_dashboard = createSelector(getHomeState, homeReducer.get_dashboard);
export const get_dex_data = createSelector(getHomeState, homeReducer.get_dex_data);
export const get_dex_queries = createSelector(getHomeState, homeReducer.get_dex_queries);

// Timetable Planner
export const get_tt_uploaded_csv = createSelector(getTimetablePlannerState, timetablePlannerReducer.get_tt_uploaded_csv);
export const get_tt_base_csv = createSelector(getTimetablePlannerState, timetablePlannerReducer.get_tt_base_csv);
export const get_tt_sample_csv = createSelector(getTimetablePlannerState, timetablePlannerReducer.get_tt_sample_csv);
export const get_tt_input = createSelector(getTimetablePlannerState, timetablePlannerReducer.get_tt_input);
export const get_matrix_sections = createSelector(getTimetablePlannerState, timetablePlannerReducer.get_matrix_sections);
export const get_matrix_change_set = createSelector(getTimetablePlannerState, timetablePlannerReducer.get_matrix_change_set);
export const get_matrix_staff = createSelector(getTimetablePlannerState, timetablePlannerReducer.get_matrix_staff);
export const get_matrix_subjects = createSelector(getTimetablePlannerState, timetablePlannerReducer.get_matrix_subjects);
export const get_matrix_subject_rows = createSelector(getTimetablePlannerState, timetablePlannerReducer.get_matrix_subject_rows);
export const get_matrix_staff_rows = createSelector(getTimetablePlannerState, timetablePlannerReducer.get_matrix_staff_rows);
export const get_matrix_additional_staff_rows = createSelector(getTimetablePlannerState, timetablePlannerReducer.get_matrix_additional_staff_rows);
export const get_tt_model = createSelector(getTimetablePlannerState, timetablePlannerReducer.get_tt_model);
export const is_csv_set = createSelector(getTimetablePlannerState, timetablePlannerReducer.is_csv_set);
export const set_matrix_view = createSelector(getTimetablePlannerState, timetablePlannerReducer.set_matrix_view);
export const get_section_rows = createSelector(getTimetablePlannerState, timetablePlannerReducer.get_section_rows);
export const set_view = createSelector(getTimetablePlannerState, timetablePlannerReducer.set_view);
export const sec_sub_stf_names_set = createSelector(getTimetablePlannerState, timetablePlannerReducer.sec_sub_stf_names_set);
export const get_matrix_additional_staff = createSelector(getTimetablePlannerState, timetablePlannerReducer.get_matrix_additional_staff);
export const show_periods_in_tt = createSelector(getTimetablePlannerState, timetablePlannerReducer.show_periods_in_tt);
export const show_locations_in_tt = createSelector(getTimetablePlannerState, timetablePlannerReducer.show_locations_in_tt);
// export const show_subject_weight = createSelector(getTimetablePlannerState, timetablePlannerReducer.show_subject_weight);
export const assign_subject_weight_ppw = createSelector(getTimetablePlannerState, timetablePlannerReducer.assign_subject_weight_ppw);
export const is_tt_run = createSelector(getTimetablePlannerState, timetablePlannerReducer.is_tt_run);

// Google Meet
export const get_google_meetings = createSelector(getGoogleMeetState, googleMeetReducer.get_google_meetings);
export const get_datewise_meetings = createSelector(getGoogleMeetState, googleMeetReducer.get_datewise_meetings);
export const get_student_profile = createSelector(getGoogleMeetState, googleMeetReducer.get_student_profile);
export const set_online_class_selected_date = createSelector(getGoogleMeetState, googleMeetReducer.set_online_class_selected_date);

export const timer_stopped = createSelector(getCountdownTimerState, countdownTimerReducer.timer_stopped);
export const get_otp_timer = createSelector(getAppState, appReducer.get_otp_timer);

// Notification
export const get_notification_list = createSelector(getNotificationState, notificationReducer.get_notifications);
export const get_unread_notifications = createSelector(getNotificationState, notificationReducer.get_unread_notifications);

// Assessment
export const get_questions_data = createSelector(getAssessmentState, assessmentReducer.get_questions_data);
export const get_assessments = createSelector(getAssessmentState, assessmentReducer.get_assessments);
export const get_published_assessments = createSelector(getAssessmentState, assessmentReducer.get_published_assessments);
export const get_selected_assessment = createSelector(getAssessmentState, assessmentReducer.get_selected_assessment);
export const assessment_total_pages = createSelector(getAssessmentState, assessmentReducer.assessment_total_pages);
export const assessment_submissions = createSelector(getAssessmentState, assessmentReducer.get_assessment_submissions);
export const get_selected_submission = createSelector(getAssessmentState, assessmentReducer.get_selected_submission);
export const get_submissions_count = createSelector(getAssessmentState, assessmentReducer.get_submissions_count);
export const get_all_assessment_tags = createSelector(getAssessmentState, assessmentReducer.get_all_assessment_tags);

// Admissions
export const get_courses_for_admissions = createSelector(getAdmissionsState, admissionsReducer.get_courses_admissions);
export const get_seat_matrix = createSelector(getAdmissionsState, admissionsReducer.get_seat_matrix);
export const get_selected_seat_matrix = createSelector(getAdmissionsState, admissionsReducer.get_selected_seat_matrix);
export const get_applicant_details = createSelector(getAdmissionsState, admissionsReducer.get_applicant_details);
export const get_applicants = createSelector(getAdmissionsState, admissionsReducer.get_applicants);
export const get_applicants_not_admitted = createSelector(getAdmissionsState, admissionsReducer.get_applicants_not_admitted);
export const get_selected_application = createSelector(getAdmissionsState, admissionsReducer.get_selected_application);
export const get_coursewise_applications = createSelector(getAdmissionsState, admissionsReducer.get_coursewise_applications);
export const get_seats_available = createSelector(getAdmissionsState, admissionsReducer.get_seats_available);
export const get_admissions = createSelector(getAdmissionsState, admissionsReducer.get_admissions);
export const get_selected_shortlist = createSelector(getAdmissionsState, admissionsReducer.get_selected_shortlist);
export const get_admitted_students = createSelector(getAdmissionsState, admissionsReducer.get_admitted_students);
export const get_expand_collapse = createSelector(getAdmissionsState, admissionsReducer.get_expand_collapse);

export const shortlists = createSelector(getAdmissionsState, admissionsReducer.get_shortlists);

export const get_application_form_templates = createSelector(getAdmissionsState, admissionsReducer.get_application_form_templates);
export const get_selected_application_form_template = createSelector(getAdmissionsState, admissionsReducer.get_selected_application_form_template);
export const get_selected_applicant = createSelector(getAdmissionsState, admissionsReducer.get_selected_applicant);
export const get_form_template_preview = createSelector(getAdmissionsState, admissionsReducer.get_form_template_preview);
export const get_submitted_applications = createSelector(getAdmissionsState, admissionsReducer.get_submitted_applications);
export const set_total_pages = createSelector(getAdmissionsState, admissionsReducer.get_total_pages);

// Accounting
export const get_vouchers = createSelector(getAccountingState, accountingReducer.get_vouchers);
export const get_source_accounts = createSelector(getAccountingState, accountingReducer.get_source_accounts);
export const get_target_accounts = createSelector(getAccountingState, accountingReducer.get_target_accounts);
export const get_notifications_for_widgets = createSelector(getAccountingState, accountingReducer.get_notifications_for_widgets);

//Cards
export const get_card_fee_defaulters_data = createSelector(getCardsState, cardsReducer.get_fee_defaulters_data);
export const get_card_upcoming_events_data = createSelector(getCardsState, cardsReducer.get_upcoming_events_data);
export const get_upcoming_events = createSelector(getCardsState, cardsReducer.get_upcoming_events);
export const get_quick_links_data = createSelector(getCardsState, cardsReducer.get_quick_links_data);

// Intros
export const get_intros = createSelector(getIntroState, introReducer.get_intros);