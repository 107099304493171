import * as app from './action';

export class Course {
    termName: string;
    secName: string;
    id: string;
    fullName: string;
    sections: Section[] = [];
    status: number
    branch: any;
}

export class Section {
    name: string;
    subjects: string[] = []
}

export interface State {
    userLinks: any[];
    html: any;
    courseList: Course[];
}

const initialState: State = {
    userLinks: null,
    html: null,
    courseList: null,
};

function setCourseArray(dataFromServer): Course[] {
    let courses = [];
    dataFromServer.data.forEach(o => {
        let course = new Course();
        course.fullName = o.name;
        if (o.branch) { course.fullName = course.fullName + " " + o.branch; }
        course.id = o.id;
        courses.push(course);
    });
    return courses;
}

function setCourseArraySectionwise(dataFromServer): Course[] {
    let courses = [];
    dataFromServer.data.forEach(o => {
        o.terms.forEach(t => {
            if (t)
                t.sections.forEach(s => {
                    if (s) {
                        let course = new Course();
                        course.id = o.id;
                        course.fullName = o.name;
                        course.secName = s.name;
                        course.termName = t.name;
                        course.status = o.status;
                        course.branch = o.branch;
                        if (o.branch) { course.fullName = course.fullName + " " + o.branch; }
                        course.fullName = course.fullName + " " + s.name;
                        courses.push(course);
                    }
                })
        })
    });
    return courses;
}

export function reducer(state = initialState, action: app.Actions): State {
    switch (action.type) {
        case app.ActionTypes.SET_SELECTED_USER_LINK:
            return Object.assign({}, state, { selectedUserLink: action.payload.userLink });
        case app.ActionTypes.SET_USER_LINKS:
            return Object.assign({}, state, { userLinks: action.payload.user_links });
        case app.ActionTypes.UPDATE_DASHBOARD:
            return Object.assign({}, state, { userLinks: action.payload.userLinks });
        case app.ActionTypes.SET_HTML:
            return Object.assign({}, state, { html: action.payload });
        case app.ActionTypes.SET_COURSES:
            return Object.assign({}, state, { courseList: setCourseArray(action.payload.course_list) });
        case app.ActionTypes.SET_COURSES_SECTIONWISE:
            return Object.assign({}, state, { courseList: setCourseArraySectionwise(action.payload.course_list) });
        default:
            return state;
    }
}

export const get_state_admin = (state: State) => state;
export const get_html_admin = (state: State) => state.html;
export const get_courses_for_admin = (state: State) => state.courseList;