import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { CTApi } from '../../../app/service/ct-api';

import * as rootReducer from '../../../app/rootReducer';

@Injectable()
export class ContactService {
    constructor(public ctapi: CTApi, private store: Store<rootReducer.State>) { }

    sendMessage(contactFormDetails){
     return this.ctapi.sendMessage(contactFormDetails);
    }
}