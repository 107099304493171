import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { PulseService } from '../../../../app/service/pulse';
import { CTApi } from '../../../../app/service/ct-api';
import * as rootReducer from '../../../../app/rootReducer';
import { environment } from '../../../../environments/environment';

@Injectable()
export class TagService {
    constructor(public ctapi: CTApi, private pulseService: PulseService, private store: Store<rootReducer.State>, private httpClient: HttpClient) { }

    // getStudentProfile(userEmail)
    // {
    //   return this.ctapi.getStudentProfile(userEmail);
    // }
}