import { Injectable } from '@angular/core';

import { CTApi } from '../../../app/service/ct-api';
import { RecentActivitiesComponent } from '../../../app/service/recent-activities';

import { Store } from '@ngrx/store';
import * as rootReducer from '../../../app/rootReducer';

import * as appAction from '../../store/app-action';
import { Lead } from '../trial/component';
import * as homeAction from '../store/action';
import { PulseService } from '../../../app/service/pulse';

@Injectable()
export class HomeService {
    constructor(public ctapi: CTApi, public rec_act: RecentActivitiesComponent,
        private store: Store<rootReducer.State>, private pulseService: PulseService) { }

    getSpecsFile(fileName: string) {
        return this.ctapi.getSpecsFile(fileName);
    }

    getRecentActivity() {
        var recAct = this.ctapi.getRecentActivity();
        this.store.dispatch(new appAction.RecentActivity(recAct));
    }

    getLeadToken(lead: Lead, referral, captchaResponse) {
        var postData = {
            InstituteName: lead.instName,
            InstituteShortName: lead.instShortName,
            InstituteWebsite: lead.instWebsite,
            ContactPerson:
            {
                Name: lead.contactPerson.name,
                Title: lead.contactPerson.title,
                Mobile: lead.contactPerson.mobile,
                Email: lead.contactPerson.email
            },
            CaptchaResponse: captchaResponse
        };
        return this.ctapi.getLeadToken(postData);
    };

    createLead(lead: Lead, referral, captchaResponse) {
        var postData = {
            InstituteName: lead.instName,
            InstituteShortName: lead.instShortName,
            InstituteWebsite: lead.instWebsite,
            ContactPerson:
            {
                Name: lead.contactPerson.name,
                Title: lead.contactPerson.title,
                Mobile: lead.contactPerson.mobile,
                Email: lead.contactPerson.email
            },
            CaptchaResponse: captchaResponse
        };
        return this.ctapi.createLead(postData);
    }

    verify(emailCode: string, smsCode: string, referral: string, leadId: string, instName: string, instShortName: string, password: string, acadYearFrom, acadYearTo, ownerId): any {
        var postData = { Id: leadId, EmailVerificationCode: emailCode, SMSVerificationCode: smsCode, Referral: referral || "", InstituteName: instName, InstituteShortName: instShortName, Password: password, AcademicYearFrom: acadYearFrom, AcademicYearTo: acadYearTo, OwnerId: ownerId };
        return this.ctapi.verify(postData);
    }
    resendVerCode(id) {
        return this.ctapi.resendVerCode(id);
    }

    getInst(id) {
        this.ctapi.getInst(id)
            .subscribe(res => {
                var instArr = [];
                if (res['data']) {
                    instArr.push(res['data']);
                }
                this.store.dispatch(new homeAction.SetInsts({ "insts": instArr }));
            },
                err => { }
            );
    }

    getInsts(qryString) {
        this.ctapi.getInsts(qryString)
            .subscribe(res => {
                this.store.dispatch(new homeAction.SetInsts({ "insts": res['data'] }));
            },
                err => { }
            );
    }

    getDashboardWidgets() {
        return this.ctapi.getDashboardWidgets().subscribe(res => {
            let d = JSON.parse(res.body)
            this.store.dispatch(new homeAction.SetDashboardWidgets({ "dashboardWigets": d['data'] }));
        });
    }

    unpinDashboardWidget(payload) {
        return this.ctapi.unpinDashboardWidget(payload).subscribe(res => {
            if (res) this.getDashboardWidgets();
        });
    }

    setWidgetsOrder(widgets) {
        return this.ctapi.setWidgetsOrder(widgets).subscribe(res => {
            if (res) this.getDashboardWidgets();
        });
    }

    getUserByEmail(email) {
        return this.ctapi.getUserByEmail(email);
    }

    confirmNotificationConsent(userId) {
        return this.ctapi.confirmNotificationConsent(userId);
    }
    getApplicantOTP(applicant) {
        return this.ctapi.getApplicantOTP(applicant);
    };
    registerApplicant(applicant) {
        return this.ctapi.registerApplicant(applicant);
    };

    signUp(payload) {
        return this.ctapi.signUp(payload);
    }

    getUserByName(userName) {
        return this.ctapi.getUserByName(userName);
    }

    getWidgetDataByNames(widgetNames: string[]) {
        this.pulseService.getWidgetDataByNames(widgetNames);
    }
}
