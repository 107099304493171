import * as app from './action';
import * as R from 'ramda';

export class WidgetAction {
    constructor(public id: number, public displayText: string, public methodName: string) {
    }
}

export class Widget {
    id: string
    title: string;
    dataSource: string;
    dataEndPoint: string;
    displayText: string;
    resource: string;
    sampleData: string;
    status: number;
    statusAsString: string;
    actions: WidgetAction[] = [];
    get allowedActions(): WidgetAction[] {
        let result = [];
        switch (this.status) {
            case 1: //active
                result = [];
                result.push(this.actions[1]) && result.push(this.actions[2]);
                break;
            case 99: //disabled
                result = [];
                result.push(this.actions[0]) && result.push(this.actions[2]);
                break;
            case 10: //wip
                result = [];
                result.push(this.actions[0]) && result.push(this.actions[1]);
                break;
            default:
                result = [];
        }
        return result;
    }
    constructor() {
        this.actions.push(new WidgetAction(1, "Active", "ut_enable"));
        this.actions.push(new WidgetAction(2, "Disable", "ut_disable"));
        this.actions.push(new WidgetAction(3, "WIP", "ut_wip"));
    }
}

function enableWidget(widget_list, widget_id): Widget[] {
    const widgetList: Widget[] = widget_list.map((item) => {
        if (item.id == widget_id) {
            item.status = 1;
            item.statusAsString = "Active";
            return item;
        }
        return item;
    });
    return widgetList;
}

function wipWidget(widget_list, widget_id): Widget[] {
    const widgetList: Widget[] = widget_list.map((item) => {
        if (item.id == widget_id) {
            item.status = 10;
            item.statusAsString = "WIP";
            return item;
        }
        return item;
    });
    return widgetList;
}

function disableWidget(widget_list, widget_id): Widget[] {
    const widgetList: Widget[] = widget_list.map((item) => {
        if (item.id == widget_id) {
            item.status = 99;
            item.statusAsString = "Disabled";
            return item;
        }
        return item;
    });
    return widgetList;
}

function setWidgetArray(dataFromServer) {
    let result = [];
    if (dataFromServer) {
        dataFromServer.forEach(o => {
            let widget = new Widget();
            widget.id = o.id;
            widget.title = o.title;
            widget.dataEndPoint = o.dataEndPoint;
            widget.dataSource = o.dataSource;
            widget.status = o.status;
            widget.statusAsString = o.status == 1 ? "Active" : o.status == 99 ? "Disabled" : "WIP";
            widget.resource = o.resource;
            widget.displayText = o.displayText;
            widget.sampleData = o.sampleData;
            result.push(widget);
        });
    }
    return result;
}

export interface State {
    widgets: Widget[];
    selectedWidget: any;
    sampleData: any;
    chartData: any;
    chartSpecs: any;
    chartMetaData: any;
    dashboardSpecs: any;
}

const initialState: State = {
    widgets: null,
    selectedWidget: null,
    sampleData: null,
    chartData: {},
    chartSpecs: {},
    chartMetaData: {},
    dashboardSpecs: {}
};

export function reducer(state = initialState, action: app.Actions): State {
    switch (action.type) {
        case app.ActionTypes.SET_WIDGETS:
            return Object.assign({}, state, { widgets: setWidgetArray(action.payload.widgets) });
        case app.ActionTypes.DISABLE_WIDGET:
            return Object.assign({}, state, { widgets: disableWidget(state.widgets, action.payload.id) });
        case app.ActionTypes.ENABLE_WIDGET:
            return Object.assign({}, state, { widgets: enableWidget(state.widgets, action.payload.id) });
        case app.ActionTypes.WIP_WIDGET:
            return Object.assign({}, state, { widgets: wipWidget(state.widgets, action.payload.id) });
        case app.ActionTypes.SET_SELECTED_WIDGET:
            return Object.assign({}, state, { selectedWidget: action.payload });
        case app.ActionTypes.SET_SAMPLE_DATA:
            return Object.assign({}, state, { sampleData: action.payload.sampleData });
        case app.ActionTypes.UPDATE_CHART_DASHBOARD_DATA:
            let newChartData = getNewChartData(state.chartData, action.payload);
            return Object.assign({}, state, { chartData: newChartData });
        case app.ActionTypes.UPDATE_CHART_DASHBOARD_SPECS:
            let newChartSpecs = getNewChartSpecs(state.chartSpecs, action.payload);
            return Object.assign({}, state, { newChartSpecs: newChartSpecs });
        case app.ActionTypes.UPDATE_DASHBOARD_SPECS:
            return Object.assign({}, state, { dashboardSpecs: updateDashboardSpecs(state.dashboardSpecs, action.payload) });
        case app.ActionTypes.UPDATE_DASHBOARD_SPECS_SEARCH_BY:
            return Object.assign({}, state, { dashboardSpecs: updateDashboardSpecsSearchBy(state.dashboardSpecs, action.payload) });
        case app.ActionTypes.UPDATE_CHART_META_DATA:
            return Object.assign({}, state, { chartMetaData: updateChartMetaData(state.chartMetaData, action.payload) });
        default:
            return state;
    }
}

function getNewChartData(chartData, payload: any) {
    if (chartData.hasOwnProperty(payload.dashboardId)) {
        chartData[payload.dashboardId][payload.widgetId] = payload.data;
    } else {
        chartData[payload.dashboardId] = {};
        chartData[payload.dashboardId][payload.widgetId] = payload.data;
    }
    return { ...chartData };
}
function getNewChartSpecs(chartSpecs: any, payload: any) {
    chartSpecs[payload.dashboardId] = payload.dashboardSpecs;
    return { ...chartSpecs };
}
function updateChartMetaData(chartMetaData: any, payload: any) {
    if (payload.hasOwnProperty("reset")) {
        chartMetaData = {};
    } else {
        if (chartMetaData.hasOwnProperty(payload.dashboardId)) {
            chartMetaData[payload.dashboardId][payload.widgetId]
            if (chartMetaData[payload.dashboardId].hasOwnProperty(payload.widgetId)) {
                chartMetaData[payload.dashboardId][payload.widgetId]["msg"] = payload.msg;
            } else {
                chartMetaData[payload.dashboardId][payload.widgetId] = {};
                chartMetaData[payload.dashboardId][payload.widgetId]["msg"] = payload.msg;
            }
        } else {
            chartMetaData[payload.dashboardId] = {};
            chartMetaData[payload.dashboardId][payload.widgetId] = {}
            chartMetaData[payload.dashboardId][payload.widgetId]["msg"] = payload.msg;
        }
    }
    return { ...chartMetaData };
}
function updateDashboardSpecs(dashboardSpecs: any, payload: any) {
    if (R.path(['dashboardId'], payload)) {
        if (R.path([payload.dashboardId], dashboardSpecs)) {
            dashboardSpecs[payload.dashboardId] = payload;
        } else {
            dashboardSpecs[payload.dashboardId] = payload;
        }
    }
    return { ...dashboardSpecs };
}
function updateDashboardSpecsSearchBy(dashboardSpecs: any, payload: any) {
    if (R.path(['searchBy'], payload)) {
        if (R.path([payload.dashboardId], dashboardSpecs)) {
            dashboardSpecs[payload.dashboardId].dashboardSpecs.searchBy = payload.searchBy;
            dashboardSpecs[payload.dashboardId].dashboardSpecs.widgets.forEach((widget: any) => {
                if (R.pathOr('', ["labels", "tag"], widget).includes(payload.searchBy)) {
                    widget.enabled = true;
                } else {
                    widget.enabled = false;
                }
            });
            dashboardSpecs[payload.dashboardId].widgetList = dashboardSpecs[payload.dashboardId].dashboardSpecs.widgets;
        }
    }
    return { ...dashboardSpecs };
}
export const get_state_widget = (state: State) => state;
export const get_widgets = (state: State) => state.widgets;
export const get_selected_widget = (state: State) => state.selectedWidget;
export const get_sample_data = (state: State) => state.sampleData;
export const get_chart_dashboard_data = (state: State) => state.chartData;
export const get_chart_dashboard_specs = (state: State) => state.chartSpecs;
export const get_chart_meta_data = (state: State) => state.chartMetaData;
export const get_dashboard_specs = (state: State) => state.dashboardSpecs;