import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import 'rxjs/Rx';
import { Observable } from 'rxjs/Observable';
import * as artefactAction from '../store/action';
import * as rootReducer from '../../../app/rootReducer';
import { CTApi } from '../../../app/service/ct-api';
import { environment } from '../../../environments/environment';
import { RecentActivitiesComponent } from '../../../app/service/recent-activities';

@Injectable()
export class ArtefactService {
    public jsonRes: any;
    constructor(private httpClient: HttpClient, public rec_act: RecentActivitiesComponent, public ctapi: CTApi, private store: Store<rootReducer.State>) { }
    createTag(tag) {
        return this.ctapi.createArtefactTag(tag);
    }

    enableTag(tagId) {
        return this.ctapi.enableArtefactTag(tagId);
    }

    disableTag(tagId) {
        return this.ctapi.disableArtefactTag(tagId);
    }

    updateTag(tagObj) {
        return this.ctapi.updateArtefactTag(tagObj);
    }

    getAllTags() {
        return this.ctapi.getArtefactTags();
    }

    disableArtefact(artefact_id) {
        return this.ctapi.disableArtefact(artefact_id);
    }

    enableArtefact(artefact_id) {
        return this.ctapi.enableArtefact(artefact_id);
    }
    publishArtefact(artefact_id) {
        return this.ctapi.publishDoc(artefact_id, "");
    }
    unpublishArtefact(artefact_id) {
        return this.ctapi.unpublishDoc(artefact_id);
    }

    get(qStr) {
        this.ctapi.get(qStr)
            .subscribe(res => {
                this.store.dispatch(new artefactAction.SetArtefact({ 'artefact': res }));
            });
    }
    getPubArte() {
        return this.ctapi.getPubArte();
    }

    update(artefact) {
        return this.ctapi.update(artefact);
    }

    uploadFiles(files_list, data, acadYear) {
        const uploadUrl = `${environment.API_URL}/arte/artefact/upsert`;
        const fileCount: number = files_list.length;
        const formData = new FormData();
        let headers = new HttpHeaders();
        let topic = "meet.sync";
        headers = headers.append('Topic', topic);
        // JavaScript file-like object
        const content = JSON.stringify(data);
        const blob = new Blob([content], { type: 'application/json' });
        if (fileCount > 0) {
            for (let i = 0; i < fileCount; i++) {
                formData.append('files', blob);
                formData.append('files', files_list.item(i));
                formData.append('acadYear', acadYear);
            }
            this.httpClient.post(uploadUrl, formData, { headers: headers }).subscribe();
        }
    }
    uploadSpecsToGithub(jsonFile, data, path) {
        const uploadUrl = `${environment.API_URL}/arte/artefact/upsert`;
        const formData = new FormData();
        const content = JSON.stringify(data);
        const blob = new Blob([content], { type: 'application/json' });
        const tags = [];
        if (jsonFile) {
            formData.append('files', blob);
            formData.append('files', jsonFile);
            formData.append('path', path);
            this.httpClient.post(uploadUrl, formData).subscribe();
        }
    }
    uploadTemplateFiles(files_list, data, acadYear) {
        const uploadUrl = `${environment.API_URL}/arte/artefact/publish`;
        const fileCount: number = files_list.length;
        const formData = new FormData();
        // JavaScript file-like object
        const content = JSON.stringify(data);
        const blob = new Blob([content], { type: 'application/json' });
        if (fileCount > 0) {
            for (let i = 0; i < fileCount; i++) {
                formData.append('files', blob);
                formData.append('files', files_list.item(i));
                formData.append('acadYear', acadYear);
            }
            this.httpClient.post(uploadUrl, formData).subscribe();
            // this.http.post(uploadUrl, formData).map((Response) => Response.json()).subscribe();
        }
    }
    updateArtefactContent(jsonFile, data, id, path) {
        const uploadUrl = `${environment.API_URL}/arte/github/upsert`;
        const formData = new FormData();
        const content = JSON.stringify(data);
        const blob = new Blob([content], { type: 'application/json' });
        if (jsonFile) {
            formData.append('files', blob);
            formData.append('files', jsonFile);
            formData.append('id', id);
            formData.append('path', path);
            return this.httpClient.post(uploadUrl, formData);
        }
    }
    recentAct(rec_act) {
        this.rec_act.recentActivities(rec_act);
    }

    download(artefact) {
        return this.ctapi.download(artefact);
    }

    getOnQry(qryString) {
        this.ctapi.getOnQry(qryString)
            .subscribe(res => {
                this.store.dispatch(new artefactAction.SetArtefact({ "artefact": res }));
            });
    }

    getUrl(arte_id) {
        this.ctapi.getUrl(arte_id).subscribe(res => {
            this.store.dispatch(new artefactAction.SetUrl({ "url": res }));
        });
    }
    downloadDoc(arte_id) {
        return this.ctapi.downloadDoc(arte_id);
    }
    getExams() {
        this.ctapi.getExams().subscribe(res => {
            this.store.dispatch(new artefactAction.SetExams({ 'exams': res['data'] }));
        });
    }
    getAllExams() {
        this.ctapi.getAllExams().subscribe(res => {
            this.store.dispatch(new artefactAction.SetExams({ 'exams': res['data'] }));
        });
    }

    downloadStudentAttendance(payload) {
        return this.ctapi.downloadStudentAttendance(payload);

    }

    getFileContents(artefactId) {
        return this.ctapi.fetchWorkingData(artefactId).subscribe(res => {
            this.store.dispatch(new artefactAction.SetArtefactData({ 'data': res }));
        })
    }

    generateEtl(enpoint, path) {
        return this.ctapi.generateEtl(enpoint, path);
    }

    runEtl(id) {
        return this.ctapi.runAllEtlsInFolder(id);
    }

    viewData(id) {
        return this.ctapi.viewData(id);
    }

    updateArtefactTags(payload) {
        return this.ctapi.updateArtefactTags(payload);
    }
    getSpecsFile(fileName: string) {
        return this.ctapi.getSpecsFile(fileName);
    }

    printArtefactsList(printOptions) {
        return this.ctapi.printArtefactsList(printOptions);
    }

    getPdf(html: any, filename: string) {
        return this.ctapi.getPdf(html).subscribe(res => {
            var newBlob = new Blob([res.body], { type: "application/pdf" });

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }

            // For other browsers: 
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.href = data;
            link.download = filename + ".pdf";
            link.click();

            setTimeout(() => {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
            }, 100);
        });
    }

    getEtlFiles(path){
        return this.ctapi.getEtlFiles(path).subscribe(res => {
            this.store.dispatch(new artefactAction.SetEtlFolderFiles({ etlFiles: res}))
        });
    }

    viewFile(path){
        return this.ctapi.getEtlFileContent(path);
    }
}

export class WindowRef {
    constructor() { }

    getNativeWindow() {
        return window;
    }
}