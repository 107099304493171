import { Component, OnInit } from '@angular/core';

class model {
}

@Component({
	templateUrl: './mobilePrivacyPolicy.component.html',
	styleUrls: ['./mobilePrivacyPolicy.component.css']
})
export class MobilePrivacyPolicyComponent implements OnInit {
	public m: model;
	ngOnInit() {
		this.m = new model();
	}
}