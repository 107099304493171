import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { AdmissionsService as service } from './../admissions/service/service';

import * as Data from '../clientIdMapper.js';

class Model {
	applicant: any = {
		name: "",
		title: "",
		mobile: "",
		email: ""
	};
	captchaVerified: boolean = false;
	captchaResponse: string;
}

@Component({
	templateUrl: './component.html',
	styleUrls: ['./component.css'],
	providers: [service]
})
export class OnlineApplicationComponent implements OnInit {
	public m: Model;
	clientId: string = "";
	modalRef: BsModalRef;
	public resolved(captchaResponse: string) {
		if (captchaResponse == null) this.m.captchaVerified = false;
		else {
			this.m.captchaVerified = true;
			this.m.captchaResponse = captchaResponse;
		}
	}
	constructor(public service: service, private modalService: BsModalService, private router: Router) { }
	ngOnInit() {
		this.init_model();
		this.init_store();
		this.sub_store();
	};
	init_model() {
		this.m = new Model();
	}
	init_store() {
		this.getClientId();
	}
	sub_store() { }

	getClientId() {
		let hostName = window.location.hostname;
		if (hostName) {
			let hostNameArr = hostName.split('.');
			let subDomain = hostNameArr[0];
			let clientIds = Data.clientIdMaps;
			let clientId = clientIds[subDomain];
			this.clientId = clientId;
		}
	}

	_keyPress(event: any) {
		const pattern = /[0-9]/;
		let inputChar = String.fromCharCode(event.charCode);
		if (!pattern.test(inputChar)) {
			event.preventDefault();
		}
	}

	ut_registerApplicant(step2Template: TemplateRef<any>) {
		var payload = { Name: this.m.applicant.name, Email: this.m.applicant.email, Title: this.m.applicant.title, Mobile: this.m.applicant.mobile, ClientId: this.clientId };
		this.service.registerApplicant(payload).subscribe(res => {
            console.log(res)
           if(res) this.showModal(step2Template) 
        },err =>{
        });
	}

	ut_ok(){
		this.modalRef.hide();
		this.router.navigate(['/home']);
	}

	showModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, { keyboard: true });
	}


}