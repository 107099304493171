import { Injectable } from '@angular/core';
import * as rootReducer from '../app/rootReducer';
import * as appAction from './store/app-action';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import 'rxjs/Rx';
import { Store } from '@ngrx/store';
import { environment } from '../environments/environment';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  public notifications: any[] = [];
  constructor(private store: Store<rootReducer.State>, private router: Router) {
    this.store.select(rootReducer.get_notifications).subscribe(res => {
      if (res) { this.notifications = res; }
    });
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.store.dispatch(new appAction.StartReq(+ new Date()));
    var access_token_header_not_required = req.url.endsWith("auth/token") || req.url.endsWith("anon/ResetPassword") || req.url.endsWith("anon/register") || req.url.endsWith("anon/registerAnon") || req.url.endsWith("anon/otp");
    var access_code_token_header_not_required = req.url.endsWith("_payment") || req.url.endsWith("payu/icpcheckout") || req.url.endsWith("pubsub/scheduleReminder");

    if (access_code_token_header_not_required) {
    } else if (access_token_header_not_required) {
      req = req.clone({
        setHeaders: { AccessCode: environment.ACCESS_CODE }
      });
    } else {
      this.store.select(rootReducer.get_accessToken).subscribe(res => {
        req = req.clone({
          setHeaders: {
            AccessCode: environment.ACCESS_CODE,
            AccessToken: res,
            'Cache-Control': 'no-cache'
          }
        });
      }, err => console.log(err));
    }
    // var re = /authenticate/gi;
    // if (req.url.search(re) == -1 ) {
    //   var ac = "";
    //   this.store.select(rootReducer.get_accessToken).subscribe(
    //     res => {
    //       ac =  res;
    //     },
    //     err => console.log(err)
    //   );
    //   req = req.clone({
    //     setHeaders: {
    //       AccessCode: environment.ACCESS_CODE,
    //       AccessToken : ac
    //     }
    //   });
    // }
    return next
      .handle(req)
      .do(event => {
        if (event instanceof HttpResponse) {
          var evtObj = { time: null, message: null, notifications: [], status: 'success' };
          evtObj.time = new Date();
          evtObj.message = event.statusText;
          this.notifications.forEach(element => {
            evtObj.notifications.push(element);
          });
          this.store.dispatch(new appAction.SetNotification(evtObj));
        }
      })
      .catch(err => {
        var evtObj = { time: null, message: null, notifications: [], status: 'error' };
        evtObj.time = new Date();
        if (err.hasOwnProperty("message")) {
          evtObj.message = err.message;
        } else {
          evtObj.message = "Unknown Error";
        }
        this.notifications.forEach(element => {
          evtObj.notifications.push(element);
        });
        this.store.dispatch(new appAction.SetNotification(evtObj));
        if (err.status == 410) {
          sessionStorage.accessToken = "";
          sessionStorage.ctContext = null;
          this.store.dispatch(new appAction.Logout(''));
          this.router.navigate(['/home']);
        }
        return Observable.throw(err);
      });
  }
}