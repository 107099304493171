import { Component, OnInit } from '@angular/core';
import { ContactService as service } from './service/service';

class Model {
	contactFormStatus: any = '';
	name: string;
	email: string;
	mobile: number;
	message: string = "";
	contactFormSuccess: boolean = false;
	contactFormError: boolean = false;
}

@Component({
	templateUrl: './contact.component.html',
	styleUrls: ['./contact.component.css'],
	providers: [service]
})
export class ContactComponent implements OnInit {
	public m: Model;
	constructor(public service: service) { }
	ngOnInit() {
		this.m = new Model();
	}

	sendContactForm() {
		let contactFormDetails = {
			"Name": this.m.name,
			"Email": this.m.email,
			"Mobile": this.m.mobile,
			"Message": this.m.message
		}
		this.service.sendMessage(contactFormDetails).subscribe(res => {
			if (res) this.m.contactFormSuccess = true;
		},
			err => { this.m.contactFormError = true; }
		);
	}

	_keyPress(event: any) {
		const pattern = /[0-9]/;
		let inputChar = String.fromCharCode(event.charCode);
		if (!pattern.test(inputChar)) {
			event.preventDefault();
		}
	}
}