import { Component, OnInit, NgZone, ElementRef } from '@angular/core';

import { Store } from '@ngrx/store';
import * as rootReducer from '../rootReducer';
import { Subscription } from 'rxjs/Subscription';
import { Router } from '@angular/router';

import * as userProfileAction from '../userProfile/store/action';
import { UserProfileService as service } from './service/service';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { Location } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';

class model {
    public ctContext: any;
    public userProfile: any;
    public editUserPwd: boolean = false;
    public CurrentPwd: string = '';
    public NewPwd: string = '';
    recoveryEmailEdit: boolean = false;
    recoveryMobileEdit: boolean = false;
    handleEdit: boolean = false;
    handleErr: boolean = false;
}

@Component({
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
    providers: [service]
})
export class UserProfileComponent implements OnInit {
    public m: model;
    modalRef: BsModalRef;
    private sub_ct_context: Subscription;
    private sub_user_details: Subscription;
    private Form;
    private startDate
    private endDate;
    constructor(public service: service, private store: Store<rootReducer.State>, private router: Router, private _ngZone: NgZone, private el: ElementRef, private location: Location, private spinner: NgxSpinnerService, private modalService: BsModalService) {
    }
    ngOnInit() {
        this.init_model();
        this.init_store();
        this.sub_store();
    }
    ngOnDestroy() {
        this.sub_ct_context.unsubscribe();
    }
    init_model() {
        this.m = new model();
    }
    init_store() {
    }
    sub_store() {
        this.sub_ct_context = this.store.select(rootReducer.get_state_app).subscribe(
            context => {
                this.m.ctContext = context;
            });
        if (this.m.ctContext.userId) { this.service.getUserDetails(this.m.ctContext.userId); }
        this.sub_user_details = this.store.select(rootReducer.get_user_profile_details).subscribe(
            res => {
                if (res) {
                    this.m.userProfile = res;
                    this.m.userProfile.dob = new Date(this.m.userProfile.dob);
                }
            });
    }
    changePwd() {
        this.m.editUserPwd = true;
    }
    updatePwd() {
        if (this.m.ctContext.userId && this.m.CurrentPwd && this.m.NewPwd) {
            this.service.changePassword(this.m.ctContext.userId, this.m.CurrentPwd, this.m.NewPwd);
        }
    }
    cancelChangePwd() {
        this.m.editUserPwd = false;
        this.m.CurrentPwd = '';
        this.m.NewPwd = '';
    }
    updateUser() {
        this.service.updateUser(this.m.userProfile);
    }
    uploadUserPhoto() {
        const inputEl: HTMLInputElement = this.el.nativeElement.querySelector('#file');
        this.service.upsertArtefact(inputEl.files)
            .subscribe(
                res => {
                    if (res) {
                        this.m.userProfile.photoURL = res["url"];
                        this.service.uploadUserPhoto(this.m.userProfile.id, this.m.userProfile.photoURL)
                            .subscribe(
                                res => { }
                            );
                    }
                }
            );
    }
    clearUserPhoto() {
        this.m.userProfile.photoURL = "";
        this.service.clearUserPhoto(this.m.userProfile.id);
    }

    ut_closeEdit(from) {
        if (from == 'mobile') this.m.recoveryMobileEdit = false;
        else this.m.recoveryEmailEdit = false;
    }

    ut_editRecoveryEmail() {
        this.m.recoveryEmailEdit = true;
    }

    ut_editRecoveryMobile() {
        this.m.recoveryMobileEdit = true;
    }

    ut_closeEditHandle() {
        this.m.handleEdit = false;
    }

    ut_editHandle() {
        this.m.handleEdit = true;
    }

    ut_updateRecoveryMobile() {
        this.m.recoveryMobileEdit = false;
        this.service.updateRecoveryMobile(this.m.userProfile.id, this.m.userProfile.recoveryMobile).subscribe();
    }

    ut_updateRecoveryEmail() {
        this.m.recoveryEmailEdit = false;
        this.service.updateRecoveryEmail(this.m.userProfile.id, this.m.userProfile.recoveryEmail).subscribe();
    }

    ut_updateHandle() {
        let payload = { Id: this.m.userProfile.id, Handle: this.m.userProfile.handle };
        this.service.updateUserHandle(payload).subscribe(res => {
            this.m.handleEdit = false;
        }, err => {
            this.m.handleEdit = true;
            this.spinner.hide();
        });
    }

    back() {
        this.location.back();
    }

    omit_special_char(event) {
        var k;
        k = event.charCode;
        return ((k > 96 && k < 123) || k == 8 || (k >= 48 && k <= 58) || k == 95);
    }
}