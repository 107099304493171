import * as app from './action';

export interface State {
    exams: any;
    selectedExam: any;
    eval: any;
    grading: any;
}

const initialState: State = {
    exams: null,
    selectedExam: null,
    eval: null,
    grading: null
};

function setExamStatus(exams) {
    exams.forEach(v => {
        v.statusAsString = v.status == '0' ? 'Unknown' : v.status == '1' ? 'Active' : v.status == '10' ? 'Scheduled' :  v.status == '20' ? 'Conducted' :  v.status == '90' ? 'Locked' :  v.status == '99' ? 'Disabled' : '';
    });
    return exams;
}

export function reducer(state = initialState, action: app.Actions): State {
    switch (action.type) {
        case app.ActionTypes.SET_EXAMS:
            return Object.assign({}, state, { exams: setExamStatus(action.payload.exams) });
        case app.ActionTypes.SET_SELECTED_EXAM:
            return Object.assign({}, state, { selectedExam: action.payload.selectedExam });
        case app.ActionTypes.SET_EVAL_STUDENTS:
            return Object.assign({}, state, { eval: action.payload.eval });
        case app.ActionTypes.SET_GRADING:
            return Object.assign({}, state, { grading: action.payload.grading });
        default:
            return state;
    }
}

export const get_exam_state = (state: State) => state;
export const get_exams = (state: State) => state.exams;
export const get_selected_exam = (state: State) => state.selectedExam;
export const get_eval_students = (state: State) => state.eval;
export const get_grading = (state: State) => state.grading;
