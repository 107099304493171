import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';

import { CTApi } from '../../../app/service/ct-api';

import * as connectAction from '../store/action';
import * as appAction from './../../store/app-action';
import * as rootReducer from '../../../app/rootReducer';
import { environment } from '../../../environments/environment';
import * as homeAction from '../../home/store/action';
import * as artefactAction from '../../artefact/store/action';

@Injectable()
export class ConnectService {
    constructor(public ctapi: CTApi, private store: Store<rootReducer.State>, private httpClient: HttpClient) { }

    getSpecsFile(fileName: string) {
        return this.ctapi.getSpecsFile(fileName);
    }

    getSMS(qryStr) {
        this.ctapi.getSMS(qryStr)
            .subscribe(res => {
                this.store.dispatch(new connectAction.SetSMS({ "smsList": res['data']['data'] }));
                this.store.dispatch(new connectAction.SetSMSTotalPages({ "smsTotalPages": res['data']['totalPages'] }));
            },
                err => { }
            );
    }
    getEmail(qryStr) {
        this.ctapi.getEmail(qryStr)
            .subscribe(res => {
                this.store.dispatch(new connectAction.SetEmail({ "emailList": res['data']['data'] }));
                this.store.dispatch(new connectAction.SetEmailTotalPages({ "emailTotalPages": res['data']['totalPages'] }));
            },
                err => { }
            );
    }
    getParentSMSRecp(recp, text) {
        return this.ctapi.getParentSMSRecp(recp, text);
    }
    getStaffSMSRecp(recp, text) {
        return this.ctapi.getStaffSMSRecp(recp, text);
    }
    sendParentSMS(recp, text, smsRefId) {
        return this.ctapi.sendParentSMS(recp, text, smsRefId);
    }
    sendStaffSMS(recp, text, smsRefId) {
        return this.ctapi.sendStaffSMS(recp, text, smsRefId);
    }
    sendManualSMS(recp, text, smsRefId) {
        return this.ctapi.sendManualSMS(recp, text, smsRefId);
    }

    createInventoryJournal(ownerId, partyId, date, creditItems, debitItems, reference, particulars) {
        return this.ctapi.createInventoryJournal(ownerId, partyId, date, creditItems, debitItems, reference, particulars);
    };

    getStuFacets(qry) {
        this.ctapi.getStuFacets(qry)
            .subscribe(res => {
                if (res)
                    this.store.dispatch(new connectAction.SetStudentFacet({ "studentFacet": res['data'] }));
            },
                err => { }
            );
    }
    getStuFacetsForEmail(qry) {
        this.ctapi.getStuFacets(qry)
            .subscribe(res => {
                if (res)
                    this.store.dispatch(new connectAction.SetStudentFacetEmail({ "studentFacetEmail": res['data'] }));
            },
                err => { }
            );
    }
    getStaffFacets() {
        this.ctapi.getStaffFacets()
            .subscribe(res => {
                if (res)
                    this.store.dispatch(new connectAction.SetStaffFacet({ "staffFacet": res['data'] }));
            },
                err => { }
            );
    }

    getApprovedTemplates() {
        this.ctapi.getApprovedMessages()
            .subscribe(res => {
                this.store.dispatch(new connectAction.SetApprovedTemplates({ "approvedTemplates": res['data'] }));
            },
                err => { }
            );
    }

    printSmsUsage(printOptions) {
        return this.ctapi.printSmsUsage(printOptions);
    }

    printInvalidBlockedNumbers(printOptions) {
        return this.ctapi.printInvalidBlockedNumbers(printOptions);
    }

    printInvalidBlockedEmails(printOptions) {
        return this.ctapi.printInvalidBlockedEmails(printOptions);
    }

    lookupContacts(contactsArray) {
        return this.ctapi.lookupContacts(contactsArray).subscribe(res => {
        });
    }

    blockContacts(contactsArray) {
        return this.ctapi.blockContacts(contactsArray).subscribe(res => {
        });
    }

    updateFatherEmail(id, email) {

        return this.ctapi.updateFatherEmail(id, email).subscribe();
    }

    updateMotherEmail(id, email) {
        return this.ctapi.updateMotherEmail(id, email).subscribe();

    }

    updateGuardianEmail(id, email) {
        return this.ctapi.updateGuardianEmail(id, email).subscribe();

    }

    updateStaffEmail(id, email) {
        return this.ctapi.updateStaffEmail(id, email).subscribe();

    }

    updateFatherMobile(id, mobile) {
        return this.ctapi.updateFatherMobile(id, mobile).subscribe();

    }

    updateMotherMobile(id, mobile) {
        return this.ctapi.updateMotherMobile(id, mobile).subscribe();

    }

    updateGuardianMobile(id, mobile) {
        return this.ctapi.updateGuardianMobile(id, mobile).subscribe();

    }

    updateStaffMobile(id, mobile) {
        return this.ctapi.updateStaffMobile(id, mobile).subscribe();

    }

    addQuote(tftd) {
        return this.ctapi.addQuote(tftd).subscribe(res => {

        });
    }

    removeQuote(id) {
        return this.ctapi.removeQuote(id).subscribe(res => {

        });
    }

    uploadFiles(files_list, acadYear) {
        const uploadUrl = `${environment.API_URL}/arte/artefact/upsert`;
        const fileCount: number = files_list.length;
        const formData = new FormData();
        // JavaScript file-like object
        if (fileCount > 0) {
            for (let i = 0; i < fileCount; i++) {
                formData.append('files', files_list.item(i));
                formData.append('acadYear', acadYear);
            }
            return this.httpClient.post(uploadUrl, formData);
        }
    }
    removeAttachment(file_name) {
        return this.ctapi.removeAttachment(file_name);
    }

    sendManualEmail(email_obj) {
        return this.ctapi.sendManualEmail(email_obj);
    }

    sendParentEmail(email_obj) {
        return this.ctapi.sendParentEmail(email_obj);
    }

    sendStaffEmail(email_obj) {
        return this.ctapi.sendStaffEmail(email_obj);
    }

    getExams() {
        // return this.ctapi.getExams("", "");
        return this.ctapi.getExamsForMarkscard("", "locked");
    }
    getStudents(qry) {
        return this.ctapi.getAllStudents(qry);
    }
    generateMarksCard(payload) {
        return this.ctapi.generateMarksCard(payload);
    }
    sendMarksCard(payload) {
        return this.ctapi.sendMarksCard(payload);
    }
    viewMarksCard(payload) {
        return this.ctapi.viewMarksCard(payload);
    }
    initWF(type) {
        return this.ctapi.initWF(type);
    }
    wfJob(postData) {
        return this.ctapi.wfJob(postData);
    }
    getSMSTemplates() {
        this.ctapi.getSMSTemplates().subscribe((res: any) => {
            this.store.dispatch(new connectAction.SetSMSTemplates({ 'smsTemplates': res.data }));
        });
    }
    addNewSMSTemplate(text) {
        return this.ctapi.addNewSMSTemplate(text);
    }
    enableSMSTemplate(id: string): any {
        return this.ctapi.enableSMSTemplate(id);
    }
    disableSMSTemplate(id: string): any {
        return this.ctapi.disableSMSTemplate(id);
    }
    approveSMSTemplate(id: string, reasons): any {
        return this.ctapi.approveSMSTemplate(id, reasons);
    }
    disapproveSMSTemplate(id, reason = ""): any {
        return this.ctapi.disapproveSMSTemplate(id, reason);
    }

    getSMSDeliveryData(smsId) {
        return this.ctapi.getSMSDeliveryData(smsId);
    }
    getEmailDeliveryData(emailId) {
        return this.ctapi.getEmailDeliveryData(emailId);
    }

    getFiles() {
        return this.ctapi.getFiles();
    }

    getFileContent(file_name) {
        return this.ctapi.getFileContent(file_name);
    }
    getEmailTemplate() {
        return this.ctapi.getEmailTemplate();
    }
    getEmailTemplateWithoutHeader() {
        return this.ctapi.getEmailTemplateWithoutHeader();
    }
    getStudentsForSection(examId) {
        return this.ctapi.getStudentsForSection(examId);
    }

    userDashboard(postData) {
        return this.ctapi.userDashboard(postData).subscribe(() => { });
    }

    pinWidget(postdata) {
        return this.ctapi.pinDashboardWidget(postdata);
    }

    unpinWidget(postdata) {
        return this.ctapi.unpinDashboardWidget(postdata);
    }

    getDashboardWidgets() {
        return this.ctapi.getDashboardWidgets().subscribe(res => {
            this.store.dispatch(new homeAction.SetDashboardWidgets({ "dashboardWigets": res['data'] }));
        });
    }

    getConnectInfo() {
        return this.ctapi.getConnectInfo().subscribe(res => {
            this.store.dispatch(new connectAction.SetConnectInfo({ "connectInfo": res['data'] }));
        });
    }

    enableEmail() {
        return this.ctapi.enableEmail().subscribe(res => {
        });
    }

    enableSMS() {
        return this.ctapi.enableSMS().subscribe(res => {
        });
    }

    enableNotification() {
        return this.ctapi.enableNotification().subscribe(res => {
        });
    }


    disableEmail() {
        return this.ctapi.disableEmail().subscribe(res => {
        });
    }

    disableSMS() {
        return this.ctapi.disableSMS().subscribe(res => {
        });
    }

    disableNotification() {
        return this.ctapi.disableNotification().subscribe(res => {
        });
    }

    createMeeting(subject, startDateTime, endDateTime) {
        return this.ctapi.createMeeting(subject, startDateTime, endDateTime).subscribe(res => { });
    }

    getArtefacts() {
        this.ctapi.get("$search=&$orderby=&$skip=0&$top=1024")
            .subscribe(res => {
                this.store.dispatch(new artefactAction.SetArtefact({ 'artefact': res }));
            });
    }

    addTopic(topics) {
        return this.ctapi.addTopic(topics);
    }

    getTopics() {
        this.ctapi.getTopics().subscribe(res => {
            if (res && res['data'] && res['data'].length > 0) {
                this.store.dispatch(new appAction.SetTopics({ topicList: res['data'] }));
            }
        })
    }

    getMainTopics() {
        this.ctapi.getMainTopics().subscribe(res => {
            if (res && res['data'] && res['data'].length > 0) {
                this.store.dispatch(new appAction.SetMainTopics({ mainTopicList: res['data'] }));
            }
        })
    }

    addTopicDescription(id, description) {
        this.ctapi.addTopicDescription(id, description).subscribe();
    }

    addTopicFriendlyName(id, friendlyName) {
        this.ctapi.addTopicFriendlyName(id, friendlyName).subscribe();
    }

    updateTopicCategory(id: string, category: 30 | 40, callback?: Function) {
        this.ctapi.updateTopicCategory(id, category).subscribe({
            next: () => {
                if (callback) { callback(); }
            }
        });
    }
    updateAllowSubscription(id: string, isSubscriptionAllowed: boolean, callback?: Function) {
        this.ctapi.updateAllowSubscription(id, isSubscriptionAllowed).subscribe({
            next: () => {
                if (callback) { callback(); }
            }
        });
    }

    addTopicRole(id, role) {
        this.ctapi.addTopicRole(id, role).subscribe();
    }

    addTopicAccess(id, access) {
        this.ctapi.addTopicAccess(id, access).subscribe();
    }

    enableTopic(topicId) {
        this.ctapi.enableTopic(topicId).subscribe();
    }

    disableTopic(topicId) {
        this.ctapi.disableTopic(topicId).subscribe();
    }

    updateTopicSubscriptions(body: { Id: string, isSubscriptionAllowed: boolean, Subscriptions: any }) {
        this.ctapi.updateTopicSubscriptions(body).subscribe();
    }

    updateTopicHandle(payload) {
        return this.ctapi.updateTopicHandle(payload);
    }

}

export class WindowRef {
    constructor() { }

    getNativeWindow() {
        return window;
    }
}