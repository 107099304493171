import * as inst from './action';

export interface State {
    insts: any[];
    selectedInst: any;
    smsTx: any[];
    wfDef:any[];
    wfExec:any[];
    files:any[];
}

const initialState: State = {
    insts: [],
    selectedInst: {},
    smsTx: null,
    wfDef: [],
    wfExec: [],
    files: []
};

function lockInstitute(insts, id) {
    var instList = insts.map((item) => {
        if (item.id == id) item.status = 90;
        return item;
    });
    return instList;
}

function unlockInstitute(insts, id) {
    var instList = insts.map((item) => {
        if (item.id == id) item.status = 1;
        return item;
    });
    return instList;
}

export function reducer(state = initialState, action: inst.Actions): State {
    switch (action.type) {
        case inst.ActionTypes.SET_INSTS:
            return Object.assign({}, state, { insts: action.payload.insts });
        case inst.ActionTypes.SET_SELECTED_INST:
            return Object.assign({}, state, { selectedInst: action.payload.selectedInst });
        case inst.ActionTypes.SET_SMS_TX:
            return Object.assign({}, state, { smsTx: action.payload.smsTx });
        case inst.ActionTypes.INSTITUTE_LOCKED:
            return Object.assign({}, state, { insts: lockInstitute(state.insts, action.payload.id) })    
        case inst.ActionTypes.INSTITUTE_UNLOCKED:
            return Object.assign({}, state, { insts: unlockInstitute(state.insts, action.payload.id) })
        case inst.ActionTypes.SET_WF_DEF:
            return Object.assign({}, state, { wfDef: action.payload.wfDef });
        case inst.ActionTypes.SET_WF_EXEC:
            return Object.assign({}, state, { wfExec: action.payload.wfExec });
        case inst.ActionTypes.SET_FILES:
            return Object.assign({}, state, { files: action.payload.files });
        default:
            return state;
    }
}

export const get_insts = (state: State) => state;
export const get_selected_inst = (state: State) => state.selectedInst;
export const get_sms_tx = (state: State) => state.smsTx;
export const get_wf_def = (state: State) => state.wfDef;
export const get_wf_exec = (state: State) => state.wfExec;
export const get_files = (state: State) => state.files;
