import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter, OnDestroy, HostListener, TemplateRef } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { AcademicsService as service, WindowRef } from '../../academics/service/service';
import { ClipboardService as clipboardService } from '../../service/clipboard-service';
import * as _ from 'underscore';
import * as LC from 'literallycanvas';
import { FileUploader } from 'ng2-file-upload';
import 'brace/index';
import 'brace/theme/eclipse';
import 'brace/mode/markdown';
import 'brace/ext/language_tools';
import * as marked from 'marked';
import * as stopWords from '../../../../assets/dummy/stop-words.json';
import { KatexOptions, MarkdownService } from 'ngx-markdown';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription, Subject, BehaviorSubject } from 'rxjs';
import * as rootReducer from '../../rootReducer';
import * as actions from '../../academics/store/action';
import * as appAction from '../../store/app-action';
import { Router, NavigationStart } from '@angular/router';
import { _fixedSizeVirtualScrollStrategyFactory } from '@angular/cdk/scrolling';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Location } from '@angular/common';

declare var $: any;

class Model {
  expand: boolean = true;
  lxStatusUpdated: boolean = false;
  studentVisibilityUpdated: boolean = false;
  isUrlUpdated: boolean = false;
  copyMessage: string = "Copied";
  isDirty: boolean = false;
  tree: any = { title: "", nodes: [] };
  treeCopy: any = {};
  nodes: any[] = [];
  title: string = "";
  titleCopy: string = "";
  lxTitleCopy: string = "";
  key: string = "children";
  editTitle: boolean = false;
  showTitleErr: boolean = false;
  selectedNode: any = {};
  ytUrlObj: any = {};
  ytUrlEventObj: any = {};
  ytUrlMsg: any = {};
  nextNode: any = null;
  prevNode: any = null;
  navItems: any = null;
  showTab: boolean = false;
  topics: any[] = [];
  currentPersona: any;
  copyItem: any;
  nodeSummaryList: any[] = [];
  treeSummaryList: any[] = [];
  showTreeSummary: boolean = false;
  checkExit: boolean = false;
  thStyle: string = `<!DOCTYPE html>
  <html lang="en">
  <head>
  <base target="_blank">
  <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
  
  <link rel="stylesheet" href="../../assets/styles/lx-view.css">
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.5/css/bootstrap.min.css"
    integrity="sha512-dTfge/zgoMYpP7QbHy4gWMEGsbsdZeCXz7irItjcC3sPUFtf0kuFbDz/ixG7ArTxmDjLXDmezHubeNikyKGVyQ=="
    crossorigin="anonymous">
    <script src="https://code.jquery.com/jquery-3.1.1.min.js"
    integrity="sha256-hVVnYaiADRTO2PzUGmuLJr8BLUSjGIZsDYGmIJLv2b8=" crossorigin="anonymous"></script>
    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.5/js/bootstrap.min.js"
    integrity="sha512-K1qjQ+NcF2TYO/eI3M6v8EiNYZfA95pQumfvcVrTHtwQVDG+aHRqLi/ETn2uB+1JqwYqVG3LIvdm9lj6imS/pQ=="
    crossorigin="anonymous"></script>
  </head>
  <body>`;
  closingTags: string = `</body>
   </html>`;
  getColor(visibility) {
    switch (visibility) {
      case true:
        return '#5cb85c';
      case false:
        return 'white';
      default:
        return 'white';
    }
  };
  getTitle(visibility) {
    switch (visibility) {
      case true:
        return 'Section visible to student';
      case false:
        return 'Section not visible to student';
      default:
        return 'Section not visible to student';
    }
  };
  getTitleStatus(status) {
    switch (status) {
      case true:
        return 'Completed';
      case false:
        return 'WIP';
      default:
        return 'WIP';
    }
  };
}

const URL = '';

@Component({
  selector: 'vx',
  templateUrl: './component.html',
  styleUrls: ['./component.css'],
  providers: [service, WindowRef, clipboardService],
})
export class VxComponent implements OnInit, OnDestroy {
  @HostListener('keyup.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.target instanceof Element && ((event.target as Element).tagName).toLowerCase() == "input") return;

    this.eventsSubject.next();
    this.closeAllEditors();
    this.discardTitleEdit();
  }
  @HostListener('window:beforeunload', ["$event"]) unload(event) {
    //calling api
    //this.canExit();

    event.preventDefault();
    event.returnValue = false;
  }
  ts: string = "1";
  nativeWindow: any;
  public options: KatexOptions = {
    displayMode: false,
    throwOnError: false,
    errorColor: '#cc0000',
  };
  modalRef: BsModalRef;
  constructor(private store: Store<rootReducer.State>,
    private el: ElementRef,
    public service: service,
    public clipboardService: clipboardService,
    private markdownService: MarkdownService,
    private router: Router,
    private winRef: WindowRef,
    private modalService: BsModalService, private location: Location) {
    this.nativeWindow = winRef.getNativeWindow();
  }
  @Input() topic: any = { title: "", nodes: [], itemId: +new Date(), status: "active", htmlText: "", sha1: "" };
  @Input() title: string;
  @Input() description: string;
  @Input() fileId: string = "";
  @Input() fileName: string;
  @Input() attributeName: string;
  @Input() backNavPath: string;
  @Input() selNode: any = null;
  @Input() path: string = "";
  @Input() canEdit: boolean = true;
  @Input() canPrint: boolean = true;
  public m: Model;
  dropLxId: any;
  public popoverTitle: string = 'Confirm Delete';
  public popoverMessage: string = '';
  public confirmClicked: boolean = false;
  public cancelClicked: boolean = false;
  public confirmText: string = 'Yes';
  public cancelText: string = 'No';
  public showSave: boolean = true;
  public sub1: Subscription;
  eventsSubject: Subject<void> = new Subject<void>();
  lxStatusList: any[] = [];

  ngOnInit() {
    this.init_model();
    this.m.tree = this.topic;
    this.m.treeCopy = JSON.parse(JSON.stringify(this.m.tree));
    if (this.m.tree.title) { this.showSave = false; }
    this.sub_store();
    if (this.selNode && this.selNode.itemId) {
      this.selectedNode(this.selNode);
    } else {
      this.showTreeSummary();
    }
  }
  ngOnDestroy() {
    this.sub1.unsubscribe();
  }
  ngAfterViewInit() {
  }
  init_model() {
    this.m = new Model();
  }
  sub_store() {
    this.sub1 = this.store.select(rootReducer.get_topics).subscribe(curriculum => {
      if (curriculum && curriculum[this.attributeName] && curriculum[this.attributeName].length > 0) {
        this.m.topics = curriculum[this.attributeName];
      }
    });
    this.store.select(rootReducer.get_state_app).subscribe(state => {
      if (state)
        this.m.currentPersona = state.currentPersona;
    });
    this.store.select(rootReducer.get_copy_items).subscribe(res => {
      if (res && res.items) {
        this.m.copyItem = res.items;
      }
    });
  }
  selectedNode(ev) {
    this.m.showTreeSummary = false;
    this.discardTitleEdit();
    this.m.selectedNode = ev;
    this.setNavItems(this.m.tree.nodes, this.m.selectedNode);
    this.m.navItems = { prevNode: this.m.prevNode, currentNode: this.m.selectedNode, nextNode: this.m.nextNode };
    this.m.nodeSummaryList = this.getNodeSummary(this.m.selectedNode);
    this.m.showTab = true;
    this.closeAllEditors();
    if (this.m.selectedNode.lxList) {
      this.m.selectedNode.lxList.forEach(lx => {
        lx.status == 'complete' ? lx.toggle = true : lx.toggle = false;
      });
    }
  }
  searchNode(nodeList, selectedNode) {
    for (let [index, node] of nodeList.entries()) {
      if (node.children && node.children.length) {
        let n = node.children.find(c => c.itemId == selectedNode.itemId);
        if (n) {
          return node;
        }
      }
      if (node.children && node.children.length > 0) { this.setNavItems(node.children, selectedNode); }
    }
  }
  searchNextNode(nodeList, selectedNode) {
    for (let [index, node] of nodeList.entries()) {
      if (node.itemId == selectedNode.itemId) {
        if (index < nodeList.length - 1) {
          return nodeList[index + 1]
        }
      }
      if (node.children && node.children.length > 0) { this.searchNextNode(node.children, selectedNode); }
    }
  }
  setNavItems(nodes, selectedNode) {
    for (let [index, node] of nodes.entries()) {
      if (node.itemId == selectedNode.itemId) {
        if ((index > 0) && (index < nodes.length - 1)) {
          let pNode = nodes[index - 1];
          if (pNode && pNode.expand && pNode.children && pNode.children.length) {
            this.m.prevNode = _.last(pNode.children);
          }
          else {
            this.m.prevNode = pNode;
          }
          if (selectedNode.expand && selectedNode.children && selectedNode.children.length) {
            this.m.nextNode = selectedNode.children[0];
          }
          else {
            this.m.nextNode = nodes[index + 1];
          }
        }
        else if (index == 0) {
          if (selectedNode.expand && selectedNode.children && selectedNode.children.length) {
            this.m.nextNode = selectedNode.children[0];
          }
          else {
            if (index < nodes.length - 1) {
              this.m.nextNode = nodes[index + 1];
            }
            else {
              let pNode = this.searchNode(this.m.tree.nodes, node);
              if (pNode) {
                let nNode = this.searchNextNode(this.m.tree.nodes, pNode);
                if (nNode) {
                  this.m.nextNode = nNode;
                }
                else {
                  this.m.nextNode = this.m.tree.nodes[0];
                }
              }
              else { this.m.nextNode = nodes[index + 1]; }
            }
          }
          let parentNode = this.searchNode(this.m.tree.nodes, node);
          if (parentNode) {
            if (parentNode && parentNode.expand && parentNode.children && parentNode.children.length && !selectedNode.isLeaf) {
              this.m.prevNode = _.last(parentNode.children);
            }
            else { this.m.prevNode = parentNode; }
          }
          else {
            let pNode = this.m.tree.nodes[this.m.tree.nodes.length - 1];
            if (pNode && pNode.expand && pNode.children && pNode.children.length && !selectedNode.isLeaf) {
              this.m.prevNode = _.last(pNode.children);
            }
            else {
              this.m.prevNode = pNode;
            }
            // this.m.prevNode = this.m.tree.nodes[this.m.tree.nodes.length-1]; 
          }
        }
        else if (index == nodes.length - 1) {
          if (selectedNode.expand && selectedNode.children && selectedNode.children.length) {
            this.m.nextNode = selectedNode.children[0];
          }
          else {
            let parentNode = this.searchNode(this.m.tree.nodes, node);
            if (parentNode) {
              let nNode = this.searchNextNode(this.m.tree.nodes, parentNode);
              if (nNode) { this.m.nextNode = nNode; }
              else { this.m.nextNode = this.m.tree.nodes[0]; }
            }
            else { this.m.nextNode = this.m.tree.nodes[0]; }
          }
          // this.m.prevNode = nodes[index - 1];
          let pNode = nodes[index - 1];
          if (pNode && pNode.expand && pNode.children && pNode.children.length) {
            this.m.prevNode = _.last(pNode.children);
          }
          else {
            this.m.prevNode = pNode;
          }
        }
        break;
      }
      if (node.children && node.children.length > 0) { this.setNavItems(node.children, selectedNode); }
    }
  }
  deletedNode(ev) {
    if (this.m.selectedNode && ev && ev.isLeaf && (this.m.selectedNode.itemId == ev.itemId)) {
      this.m.selectedNode = {};
      this.m.showTab = false;
    }
    else if (this.m.selectedNode && ev && !ev.isLeaf && ev.children && ev.children.length > 0) {
      if (JSON.stringify(ev).includes(this.m.selectedNode.itemId)) {
        this.m.selectedNode = {};
        this.m.showTab = false;
      }
    }
  }
  updateQ(event) {
    if (event) {
      this.m.selectedNode.summary.text = event.q;
    }
  }
  updateContent(event) {
    if (event) {
      this.m.selectedNode.lxList[event.index].content.text = event.q;
    }
  }
  ut_setVisibility(index) {
    let data = this.m.selectedNode.lxList[index];
    if (data.studentVisibility == true) data.studentVisibility = false;
    else {
      let obj = { studentVisibility: true };
      this.m.selectedNode.lxList[index] = Object.assign({}, data, obj);
    }
    this.m.studentVisibilityUpdated = true;
  }

  ut_toggle(index, toggle) {
    let data = this.m.selectedNode.lxList[index];
    if (toggle == true) data.status = 'complete';
    else {
      let obj = { status: 'wip' };
      this.m.selectedNode.lxList[index] = Object.assign({}, data, obj);
    }
    this.m.lxStatusUpdated = true;
    let status = this.m.selectedNode.lxList[index].status;
    let itemId = this.m.selectedNode.lxList[index].itemId;
    let payload = { Key: 'status', Value: status, ItemId: itemId };
    this.lxStatusList.push(payload);
    //this.service.updateDataAttributes(payload).subscribe();
  }

  ut_removeLx(index) {
    delete this.m.ytUrlObj[index];
    this.m.selectedNode.lxList.splice(index, 1);
    let lxOb = { id: "", title: "", content: { text: "", htmlText: "", plainText: "" }, preview: true, itemId: +new Date() };
    if (this.m.selectedNode.lxList.length == 0) {
      this.m.selectedNode.lxList.push(lxOb);
    }
  }
  ut_addLx(index, lx, e) {
    if (e.ctrlKey) {
      this.m.selectedNode.lxList.forEach(q => {
        q.preview = true;
      });
      let ytOb = { id: "", title: "", content: { text: "", htmlText: "", plainText: "", ytURL: "", isYtURL: true }, preview: false, itemId: +new Date() };
      this.m.ytUrlObj[index + 1] = "";
      this.m.selectedNode.lxList.splice(index + 1, 0, ytOb);
    }
    else {
      this.m.selectedNode.lxList.forEach(q => {
        q.preview = true;
      });
      let lxOb = { id: "", title: "", content: { text: "", htmlText: "", plainText: "" }, preview: false, itemId: +new Date() };
      this.m.selectedNode.lxList.splice(index + 1, 0, lxOb);
    }
    // else {
    //   this.editLx(lx); }
  }
  ut_getUserURL(index, e) {
    if (e.target.value || e.target.value == "") {
      this.m.ytUrlObj[index] = e.target.value;
      this.m.ytUrlEventObj[index] = e || {};
    }
  }
  ut_userURL(index, e) {
    if (this.m.ytUrlObj[index]) {
      if (this.m.ytUrlObj[index].search(" ") != -1) {
        this.m.ytUrlObj[index] = this.m.ytUrlObj[index].trim();
      }
      if (this.m.ytUrlObj[index].search("http") == -1) {
        this.m.ytUrlObj[index] = "http://" + this.m.ytUrlObj[index].trim();
      }
      try {
        let uri = this.m.ytUrlObj[index] || "";
        if (uri === decodeURIComponent(uri)) {
          this.m.ytUrlObj[index] = encodeURI(this.m.ytUrlObj[index]);
        }
        this.m.ytUrlMsg[index] = "";
      } catch (e) {
        this.m.ytUrlMsg[index] = "Invalid url: " + this.m.ytUrlObj[index];
        this.m.ytUrlObj[index] = "";
      }
      if (this.m.ytUrlObj[index]) {
        this.m.selectedNode.lxList[index].content.ytURL = this.m.ytUrlObj[index].trim();
      } else {
        this.m.ytUrlEventObj[index].target.value = "";
        this.m.selectedNode.lxList[index].content.ytURL = "";
      }
    } else if (this.m.ytUrlObj[index] == "") {
      this.m.selectedNode.lxList[index].content.ytURL = "";
    }
    this.m.isUrlUpdated = true;
  }
  focus() {
  }
  focusOut(ev, lx) {
    ev.preventDefault();
    ev.stopPropagation();
    if (lx.text) {
      lx.text = lx.text.trim();
    }
    if (lx.content && lx.content['isYtURL']) {
      ev.target.blur();
    }
    lx.preview = true;
  }
  focusOutLx(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    if (this.m.selectedNode.lxList && this.m.selectedNode.lxList.length > 0) {
      this.m.selectedNode.lxList.forEach(lx => {
        lx.preview = true;
      });
    }
  }
  addNode() {
    let node = { name: "New Node", expand: false, children: [], isLeaf: false, itemId: +new Date() };
    this.m.tree.nodes.push(node);
  }
  allowDrop(ev) {
    ev.preventDefault();
  }
  dragLx(evt, data) {
    if (this.canEdit) {
      evt.dataTransfer.setData("text", JSON.stringify(data));
    }
  }
  dropLx(ev) {
    if (this.canEdit) {
      ev.preventDefault();
      var data = ev.dataTransfer.getData("text");
      var lxData = JSON.parse(data);
      let dragLxIndex = this.m.selectedNode.lxList.findIndex(
        q => q.itemId == lxData.itemId
      );
      let dropLocationIndex = this.m.selectedNode.lxList.findIndex(
        q => q.itemId == this.dropLxId
      );
      if (this.m.selectedNode.lxList.length >= 1) {
        var temp = this.m.selectedNode.lxList[dragLxIndex];
        this.m.selectedNode.lxList[dragLxIndex] = this.m.selectedNode.lxList[dropLocationIndex];
        this.m.selectedNode.lxList[dropLocationIndex] = temp;
      }
    }
  }
  dragEnter(ev, lx) {
    if (this.canEdit) {
      if (lx) {
        this.dropLxId = lx.itemId;
      }
    }
  }
  editTitle() {
    if (this.canEdit) {
      this.m.editTitle = true;
      this.m.titleCopy = this.m.tree.title;
    }
  }
  updateTitle() {
    if (this.m.titleCopy) {
      this.m.tree.title = this.m.titleCopy;
      this.m.titleCopy = "";
      this.m.editTitle = false;
      this.m.showTitleErr = false;
      if (this.m.tree.nodes.length < 1) { this.addNode(); }
    }
    else { this.m.showTitleErr = true; }
  }
  discardTitleEdit() {
    this.m.editTitle = false;
    this.m.showTitleErr = false;
  }
  editNodeInfo() {
    if (this.canEdit) {
      this.eventsSubject.next();
      this.m.selectedNode.summary.preview = false;
      if (this.m.selectedNode.lxList && this.m.selectedNode.lxList.length > 0) {
        this.m.selectedNode.lxList.forEach(lx => {
          lx.preview = true;
        });
      }
    }
  }
  editLx(lx, e = null) {
    if (this.canEdit) {
      this.eventsSubject.next();
      this.m.selectedNode.summary.preview = true;
      if (this.m.selectedNode.lxList && this.m.selectedNode.lxList.length > 0) {
        this.m.selectedNode.lxList.forEach(lx => {
          lx.preview = true;
        });
      }
      lx.preview = false;
    }
    if (e != null) {
      if (e.target.querySelector('input')) {
        e.target.querySelector('input').focus();
      }
    }
  }
  editLxTitle(lx) {
    if (this.canEdit) {
      lx.editTitle = true;
      this.m.lxTitleCopy = lx.title;
    }
  }
  updateLxTitle(lx) {
    if (this.canEdit) {
      lx.title = this.m.lxTitleCopy;
      this.m.lxTitleCopy = "";
      lx.editTitle = false;
    }
  }
  discardLxTitleUpdate(lx) {
    lx.editTitle = false;
    this.m.lxTitleCopy = "";
  }
  addTopic(template: TemplateRef<any>) {
    try {
      if (this.m.tree.title) {
        this.m.tree.modifiedOn = new Date();
        if (this.m.tree.nodes && this.m.tree.nodes.length > 0) {
          this.formatData(this.m.tree.nodes);
          this.setTreeHtml(this.m.tree);
          this.isDirty().then((isDirty) => {
            if (isDirty) {
              var jsonStr = JSON.stringify(this.m.tree);
              var jsonBlob = new Blob([jsonStr], { type: "application/json" });
              var jsonFile = new File([jsonBlob], this.fileName, { type: "application/json" });
              const json_meta = {
                'Title': this.m.tree.title,
                'Description': this.description,
                'MetaData': {}
              };
              let pathName = this.path + "/" + this.fileName;
              this.service.saveVx(jsonFile, json_meta, this.fileId, pathName).subscribe(res => {
                if (res && res['id']) {
                  this.m.treeCopy = JSON.parse(JSON.stringify(this.m.tree));
                  this.fileId = res['id'];
                }
              })
            }
          });
        }
      }
    }
    catch (e) {
      if (e && e.name == "ParseError") {
        this.errorModal(template);
      }
    }
  }
  formatData(nodes) {
    nodes.forEach(node => {
      node.htmlText = "";
      node.expand = false;
      node.selected = false;
      if (node.isLeaf && node.lxList && node.lxList.length > 0) {
        if (node.summary.text && node.summary.text.length > 0) {
          node.summary.htmlText = this.getHtmlText(node.summary.text);
          node.htmlText += node.summary.htmlText;
        }
        node.lxList.forEach(lx => {
          lx.content.htmlText = this.getHtmlText(lx.content.text);
          lx.htmlText = "<section><h3>" + lx.title + "</h3>" + lx.content.htmlText + '</section>';
          node.htmlText += lx.htmlText;
        });

      }
      if (node.children && node.children.length > 0) { this.formatData(node.children); }
    });
  }
  setTreeHtml(tree) {
    if (tree.nodes && tree.nodes.length > 0) {
      tree.htmlText = "";
      tree.nodes.forEach(node => {
        node.htmlText = this.getNodeHtml(node);
        if (node.htmlText && node.htmlText.length > 0) { tree.htmlText += node.htmlText; }
      });
      tree.htmlText = '<h1>' + tree.title + '</h1>' + '<main>' + tree.htmlText + '</main>';
    }
  }
  update(template: TemplateRef<any>) {
    try {
      if (this.m.tree.title) {
        this.m.tree.modifiedOn = new Date();
        if (this.m.tree.nodes && this.m.tree.nodes.length > 0) {
          this.formatData(this.m.tree.nodes);
          this.setTreeHtml(this.m.tree);
          this.isDirty().then((isDirty) => {
            if (isDirty) {
              var jsonStr = JSON.stringify(this.m.tree);
              var jsonBlob = new Blob([jsonStr], { type: "application/json" });
              var jsonFile = new File([jsonBlob], this.fileName, { type: "application/json" });
              const json_meta = {
                'Title': this.m.tree.title,
                'Description': this.description,
                'MetaData': {}
              };
              let pathName = this.path + "/" + this.fileName;
              this.service.saveVx(jsonFile, json_meta, this.fileId, pathName).subscribe(res => {
                if (res && res['id']) {
                  this.m.treeCopy = JSON.parse(JSON.stringify(this.m.tree));
                  this.fileId = res['id'];
                }
              })
            }
          });
          if (this.m.studentVisibilityUpdated || this.m.lxStatusUpdated || this.m.isUrlUpdated) {
            var jsonStr = JSON.stringify(this.m.tree);
            var jsonBlob = new Blob([jsonStr], { type: "application/json" });
            var jsonFile = new File([jsonBlob], this.fileName, { type: "application/json" });
            const json_meta = {
              'Title': this.m.tree.title,
              'Description': this.description,
              'MetaData': {}
            };
            let pathName = this.path + "/" + this.fileName;
            this.service.saveVx(jsonFile, json_meta, this.fileId, pathName).subscribe(res => {
              if (res && res['id']) {
                this.m.treeCopy = JSON.parse(JSON.stringify(this.m.tree));
                this.fileId = res['id'];
                this.m.studentVisibilityUpdated = false;
                this.m.lxStatusUpdated = false;
                this.m.isUrlUpdated = false;
              }
            })
            if (this.m.lxStatusUpdated) {
              let payload = { Id: this.fileId, Attributes: this.lxStatusList };
              this.service.updateDataAttributes(payload).subscribe();
            }
          }
        }
      }
    }
    catch (e) {
      if (e && e.name == "ParseError") {
        this.errorModal(template);
      }
    }

  }
  nodeEdit(ev) {
    this.discardTitleEdit();
  }
  emitTitleEdit() {
    this.eventsSubject.next();
  }
  getNodeSummary(node) {
    let leafSummaryList = [];
    if (node && node.children && node.children.length > 0) {
      node.children.forEach(child => {
        if (child.isLeaf) {
          if (child.summary && child.summary) {
            let summaryObj = { name: child.name, summary: child.summary };
            leafSummaryList.push(summaryObj);
          }
        }
        else {
          if (child.children && child.children.length > 0) {
            let summList = this.getNodeSummary(child);
            if (summList && summList.length > 0) { leafSummaryList = leafSummaryList.concat(summList); }
          }
        }
      });
    }
    return leafSummaryList;
  }

  getNodeHtml(node) {
    let htmlText = '<h3>' + node.name + '</h3>';
    if (node && node.children && node.children.length > 0) {
      node.children.forEach(child => {
        if (child.isLeaf) {
          if (child.htmlText && child.htmlText.length > 0) {
            htmlText += '<h3>' + child.name + '</h3>' + '<article>' + child.htmlText + '</article>';
          }
        }
        else {
          if (child.children && child.children.length > 0) {
            let childHtmlText = this.getNodeHtml(child);
            if (childHtmlText && childHtmlText.length > 0) { htmlText += childHtmlText; }
          }
        }
      });
    }
    return htmlText;
  }
  setTreeSummary() {
    this.m.treeSummaryList = [];
    this.m.tree.nodes.forEach(node => {
      let nodeSummary = { title: node.name, summaryList: [] };
      nodeSummary.summaryList = this.getNodeSummary(node);
      this.m.treeSummaryList.push(nodeSummary);
    });
  }
  showTreeSummary() {
    this.m.selectedNode = this.m.tree;
    this.m.selectedNode.name = this.m.selectedNode.title;
    this.setTreeSummary();
    this.m.showTreeSummary = true;
    this.m.showTab = true;
    this.emitTitleEdit();
  }
  back(template) {
    //this.router.navigate([this.backNavPath]);
    this.location.back()
  }
  async canExit() {
    try {
      if (this.canEdit) {
        this.updateHtmlText();
        let isDirty = await this.isDirty();
        if (isDirty) {
          if (confirm("Leave document? \n\n Changes that you made may not be saved. ")) { return true; }
          else {
            this.store.dispatch(new appAction.ResetTab({ "reset": true, "ts": +new Date() }));
            return false;
          }
        }
        else if (this.m.studentVisibilityUpdated || this.m.lxStatusUpdated || this.m.isUrlUpdated) {
          if (confirm("Leave document? \n\n Changes that you made may not be saved.")) { return true; }
        }
        else {
          return true;
        }
      }
      else { return true }
    }
    catch (e) {
      if (e && e.name == "ParseError") {
        if (confirm("Leave document? \n\n Changes that you made may not be saved.")) { return true; }
        else {
          this.store.dispatch(new appAction.ResetTab({ "reset": true, "ts": +new Date() }));
          return false;
        }
      }
    }
  }

  continueAdd(template) {
    this.modalRef.hide();
    this.addTopic(template);
  }
  continueUpdate(template) {
    this.modalRef.hide();
    this.update(template);
  }
  getHtmlText(content) {
    var c = "";
    if (content) {
      c = this.markdownService.compile(content);
      c = this.markdownService.renderKatex(c);
    }
    return c;
  }
  print() {
    if (this.m.selectedNode.name && this.m.selectedNode.htmlText) {
      let html = "";
      var newWindow = this.nativeWindow.open('');
      if (this.m.selectedNode.isLeaf) {
        html = this.m.thStyle + '<div style="padding:1em 2em"><h3>' + this.m.selectedNode.name + '</h3>' + this.m.selectedNode.htmlText + '</div>' + this.m.closingTags;
      }
      else {
        html = this.m.thStyle + '<div style="padding:1em 2em">' + this.m.selectedNode.htmlText + '</div>' + this.m.closingTags;
      }
      newWindow.document.write(html);
    }
  }
  printNode() {
    if (this.m.selectedNode.name && this.m.selectedNode.htmlText) {
      var newWindow = this.nativeWindow.open('');
      let html = this.m.thStyle + '<div style="padding:1em 2em">' + this.m.selectedNode.htmlText + '</div>' + this.m.closingTags;
      newWindow.document.write(html);
    }
  }
  closeAllEditors() {
    if (this.m.selectedNode && this.m.selectedNode.summary) {
      if (this.m.selectedNode.summary.text) { this.m.selectedNode.summary.text = this.m.selectedNode.summary.text.trim(); }
      this.m.selectedNode.summary.preview = true;
    }
    if (this.m.selectedNode && this.m.selectedNode.lxList && this.m.selectedNode.lxList.length > 0) {
      this.m.selectedNode.lxList.forEach(lx => {
        if (lx.content.text) { lx.content.text = lx.content.text.trim(); }
        lx.preview = true;
      });
    }
  }
  updateHtmlText() {
    if (this.m.tree.nodes && this.m.tree.nodes.length > 0) {
      this.formatData(this.m.tree.nodes);
      this.setTreeHtml(this.m.tree);
    }
  }
  async sha1(text) {
    const msgUint8 = new TextEncoder().encode(text);
    const hashBuffer = await crypto.subtle.digest('SHA-1', msgUint8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
  }

  isDirty = async () => Promise.all([this.sha1(this.m.tree.htmlText), this.sha1(this.m.treeCopy.htmlText)]).then((values) => {
    return values[0] != values[1];
  });
  errorModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  copy(item) {
    let cbObj = { title: item.title || item.name, id: +new Date(), type: "data", data: JSON.parse(JSON.stringify(item)) };
    this.clipboardService.copy(cbObj);
  }
  paste(lx) {
    if (this.m.copyItem) {
      let copyItem = JSON.parse(JSON.stringify(this.m.copyItem));
      if (copyItem[0].data.content) {
        lx.title = copyItem[0].data.title;
        lx.content = copyItem[0].data.content;
      }
      else {
        lx.summary = copyItem[0].data.summary;
        lx.summary.preview = false;
      }
    }
  }

  ut_insertAudioVideo(index) {
    this.m.selectedNode.lxList.forEach(q => {
      q.preview = true;
    });
    let ytOb = { id: "", title: "", content: { text: "", htmlText: "", plainText: "", ytURL: "", isYtURL: true }, preview: false, itemId: +new Date() };
    this.m.ytUrlObj[index + 1] = "";
    this.m.selectedNode.lxList.splice(index + 1, 0, ytOb);
  }

  expandCollapse() {
    if (this.m.expand) this.m.expand = false;
    else this.m.expand = true;
  }
}