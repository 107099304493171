import { Action } from "@ngrx/store";
import { type } from '../../../app/util';

export const ActionTypes = {
  GET_QUESTIONS: type('[Set Assessment Questions] Get Questions'),
  GET_QUESTIONS_SUCCESS: type('[Set Assessment] Get Questions Success'),
  SAVE_ASSESSMENT: type('[Set Assessment] Save Assessment'),
  SAVE_ASSESSMENT_SUCCESS: type('[Set Assessment] Save Assessment Success'),
  GET_ASSESSMENTS: type('[Set Assessment List] Get Assessments'),
  GET_PUBLISHED_ASSESSMENTS: type('[Set Published Assessment List] Get Published Assessments'),
  PUBLISH_ASSESSMENT: type('[Publish Assessment] Publish Assessment'),
  UNPUBLISH_ASSESSMENT: type('[UnPublish Assessment] UnPublish Assessment'),
  ARCHIVE_ASSESSMENT: type('[Archive Assessment] Archive Assessment'),
  UNARCHIVE_ASSESSMENT: type('[Unarchive Assessment] Unarchive Assessment'),
  SELECTED_ASSESSMENT: type('[Set Selected Assessment] Set Selected Assessment'),
  SET_TOTAL_PAGES: type('[Set Total Pages] Set Total Pages'),
  SET_SUBMISSIONS: type('[Set Submissions] Set Submissions'),
  SET_SELECTED_SUBMISSION: type('[Set Selected Submission] Selected Submission'),
  GET_SUBMISSIONS_COUNT: type('[Get Submissions Count] Submissions Count'),
  UPDATE_ALL_ASSESSMENT_TAGS: type('[Set Assessment] Update All Tags'),
  UPDATE_ALL_ASSESSMENT_TAGS_SUCCESS: type('[Set Assessment] Update All Tags Success'),
  GET_ALL_ASSESSMENT_TAGS: type('[Set Assessment] Get All Tags'),
  GET_ALL_ASSESSMENT_TAGS_SUCCESS: type('[Set Assessment] Get All Tags Success')
}

export class GetQuestions implements Action {
  type = ActionTypes.GET_QUESTIONS;
  constructor(public payload: any) { }
}

export class GetQuestionsSuccess implements Action {
  type = ActionTypes.GET_QUESTIONS_SUCCESS;
  constructor(public payload: any) { }
}

export class SaveAssessment implements Action {
  type = ActionTypes.SAVE_ASSESSMENT;
  constructor(public payload: any) { }
}

export class SaveAssessmentSuccess implements Action {
  type = ActionTypes.SAVE_ASSESSMENT_SUCCESS;
  constructor(public payload: any) { }
}

export class GetAssessments implements Action {
  type = ActionTypes.GET_ASSESSMENTS;
  constructor(public payload: any) { }
}

export class GetPublishedAssessments implements Action {
  type = ActionTypes.GET_PUBLISHED_ASSESSMENTS;
  constructor(public payload: any) { }
}

export class PublishAssessment implements Action {
  type = ActionTypes.PUBLISH_ASSESSMENT;
  constructor(public payload: any) { }
}

export class UnPublishAssessment implements Action {
  type = ActionTypes.UNPUBLISH_ASSESSMENT;
  constructor(public payload: any) { }
}

export class ArchiveAssessment implements Action {
  type = ActionTypes.ARCHIVE_ASSESSMENT;
  constructor(public payload: any) { }
}

export class UnarchiveAssessment implements Action {
  type = ActionTypes.UNARCHIVE_ASSESSMENT;
  constructor(public payload: any) { }
}

export class SelectedAssessment implements Action {
  type = ActionTypes.SELECTED_ASSESSMENT;
  constructor(public payload: any) { }
}

export class SetTotalPages implements Action {
  type = ActionTypes.SET_TOTAL_PAGES;
  constructor(public payload: any) { }
}

export class SetSubmissions implements Action {
  type = ActionTypes.SET_SUBMISSIONS;
  constructor(public payload: any) { }
}

export class SetSelectedSubmission implements Action {
  type = ActionTypes.SET_SELECTED_SUBMISSION;
  constructor(public payload: any) { }
}

export class GetSubmissionsCount implements Action {
  type = ActionTypes.GET_SUBMISSIONS_COUNT;
  constructor(public payload: any) { }
}

export class UpdateAllAssessmentTags implements Action {
  type = ActionTypes.UPDATE_ALL_ASSESSMENT_TAGS;
  constructor(public payload: any) { }
}

export class UpdateAllAssessmentTagsSuccess implements Action {
  type = ActionTypes.UPDATE_ALL_ASSESSMENT_TAGS_SUCCESS;
  constructor(public payload: any) { }
}

export class GetAllAssessmentTags implements Action {
  type = ActionTypes.GET_ALL_ASSESSMENT_TAGS;
  constructor(public payload: any) { }
}

export class GetAllAssessmentTagsSuccess implements Action {
  type = ActionTypes.GET_ALL_ASSESSMENT_TAGS_SUCCESS;
  constructor(public payload: any) { }
}

export type Actions = GetQuestions | GetQuestionsSuccess | SaveAssessment | SaveAssessmentSuccess | GetAssessments | PublishAssessment | UnPublishAssessment | ArchiveAssessment | UnarchiveAssessment | SelectedAssessment | GetPublishedAssessments | SetTotalPages | SetSubmissions | SetSelectedSubmission | GetSubmissionsCount
| UpdateAllAssessmentTags | UpdateAllAssessmentTagsSuccess | GetAllAssessmentTags | GetAllAssessmentTagsSuccess;