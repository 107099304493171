import * as app from './action';

export interface State {
    timerStopped: boolean;
}

const initialState: State = {
    timerStopped: false
}

export function reducer(state = initialState, action: app.Actions): State {
    switch (action.type) {
        case app.ActionTypes.TIMER_STOPPED:
            return Object.assign({}, state, { timerStopped: action.payload.timerStopped });
        default:
            return state;
    }
}

export const timer_stopped = (state: State) => state.timerStopped;
;
