import * as app from './action';

export class AssessmentAction {
  constructor(public id: number, public displayText: string, public methodName: string) {
  }
}

export class Assessment {
  id: string;
  description: string;
  duration: number;
  subject: string;
  subjectCode: string;
  totalMarks: number;
  sections: [];
  status: number;
  multipleAttempts: boolean = false;
  canTakeAssessment: boolean = true;
  evaluatedOn: Date;
  actions: AssessmentAction[] = [];
  submissionCount: number;
  createdOn: Date;
  modifiedOn: Date;
  tags: any[] = [];
  tagNames: string;
  get allowedActions(): AssessmentAction[] {
    let result = [];
    switch (this.status) {
      case 40:
        result.push(this.actions[0]) && result.push(this.actions[4]);
        break;
      case 50:
        result.push(this.actions[2]) && result.push(this.actions[4]) && result.push(this.actions[1]);
        break;
      case 80:
        result.push(this.actions[3]) && result.push(this.actions[4]);
        break;
      default:
        result = [];
    }
    return result;
  }
  constructor() {
    this.actions.push(new AssessmentAction(1, "Publish", "ut_publish"));
    this.actions.push(new AssessmentAction(2, "Unpublish", "ut_unpublish"));
    this.actions.push(new AssessmentAction(3, "Archive", "ut_archive"));
    this.actions.push(new AssessmentAction(4, "Unarchive", "ut_unarchive"));
    this.actions.push(new AssessmentAction(5, "Refresh", "ut_refresh"));
  }
}

export class Question {
  id: string;
  questionText: string;
  htmlText: string;
  options: [];
  hints: any[] = [];
  defaultWeightage: number;
  instructions: string;
  answerType: string;
  tags: any[] = [];
  expand: boolean = false;
  status: string;
  questions: any[] = [];
  keywords: string = "";
  plainText: string;
  tagList: any[] = [];
  difficulty: string;
}

// export class QuestionAnswer {
//   id: string;
//   questionText: string;
//   options: any[];
//   answerType: string;
//   tags: string;
//   meta: {};
//   defaultWeightage: number;
//   status: string;
// }

function setAssessmentList(dataFromServer) {
  var result = [];
  if (dataFromServer) {
    dataFromServer.assessments.forEach(d => {
      let assessment = new Assessment();
      let tags_list = [];
      assessment.id = d.id;
      assessment.duration = d.duration;
      assessment.description = d.description;
      assessment.subject = d.subject;
      assessment.subjectCode = d.subjectCode;
      assessment.totalMarks = d.totalMarks;
      assessment.sections = d.sections;
      assessment.status = d.status;
      assessment.multipleAttempts = d.multipleAttempts;
      assessment.createdOn = d.createdOn;
      assessment.modifiedOn = d.modifiedOn;
      assessment.tags = d.tags;
      assessment.tags.forEach(t => {
        tags_list.push(t.name);
      });
      assessment.tagNames = tags_list.join(",");
      result.push(assessment)
    })
  }
  return result;
}

function publishAssessment(assessment_list, id): Assessment[] {
  const assessmentList: Assessment[] = assessment_list.map((item) => {
    if (item.id == id) {
      item.status = 50;
      return item;
    }
    return item;
  });
  return assessmentList;
}

function unpublishAssessment(assessment_list, id): Assessment[] {
  const assessmentList: Assessment[] = assessment_list.map((item) => {
    if (item.id == id) {
      item.status = 40;
      return item;
    }
    return item;
  });
  return assessmentList;
}

function archiveAssessment(assessment_list, id): Assessment[] {
  const assessmentList: Assessment[] = assessment_list.map((item) => {
    if (item.id == id) {
      item.status = 80;
      return item;
    }
    return item;
  });
  return assessmentList;
}

function unarchiveAssessment(assessment_list, id): Assessment[] {
  const assessmentList: Assessment[] = assessment_list.map((item) => {
    if (item.id == id) {
      item.status = 50;
      return item;
    }
    return item;
  });
  return assessmentList;
}

function setQuestions(data) {
  var result = []
  if (data) {
    data.forEach(o => {
      let ques = new Question();
      ques.id = o.id;
      //ques.meta = o.meta;
      ques.tags = o.tags;
      ques.questionText = o.questionText;
      ques.htmlText = o.htmlText;
      ques.answerType = o.answerType;
      ques.options = o.options;
      ques.defaultWeightage = o.defaultWeightage;
      ques.status = o.status;
      ques.questions = o.questions;
      ques.keywords = o.keywords;
      ques.plainText = o.plainText;
      ques.tagList = o.tagsList;
      ques.difficulty = o.difficulty;
      result.push(ques)
    })
  }
  return result;
}

export interface State {
  questions: any;
  assessmentQuestions: any;
  assessments: Assessment[];
  publishedAssessments: Assessment[];
  selectedAssessment: Assessment;
  totalPages: number;
  submissions: any;
  selectedSubmission: any;
  submissionsCount: any;
  allTags: any;
}

const initialState: State = {
  questions: null,
  assessmentQuestions: null,
  assessments: [],
  publishedAssessments: [],
  selectedAssessment: null,
  totalPages: 0,
  submissions: [],
  selectedSubmission: null,
  allTags: null,
  submissionsCount: null
}

export class AllTags {
  resourceType: string;
  list: any[]
}

function setAllTags(allTags) {
  var tags: AllTags = allTags
  return tags
}

export function reducer(state = initialState, action: app.Actions): State {
  switch (action.type) {
    case app.ActionTypes.GET_QUESTIONS:
      return state;
    case app.ActionTypes.GET_QUESTIONS_SUCCESS:
      return Object.assign({}, state, { questions: setQuestions(action.payload) });
    case app.ActionTypes.SAVE_ASSESSMENT:
      return state;
    case app.ActionTypes.SAVE_ASSESSMENT_SUCCESS:
      return Object.assign({}, state, { assessmentQuestions: action.payload });
    case app.ActionTypes.GET_ASSESSMENTS:
      return Object.assign({}, state, { assessments: setAssessmentList(action.payload) });
    case app.ActionTypes.GET_PUBLISHED_ASSESSMENTS:
      return Object.assign({}, state, { publishedAssessments: setAssessmentList(action.payload) });
    case app.ActionTypes.PUBLISH_ASSESSMENT:
      return Object.assign({}, state, { assessments: publishAssessment(state.assessments, action.payload.assessment_id) });
    case app.ActionTypes.PUBLISH_ASSESSMENT:
      return Object.assign({}, state, { assessments: unpublishAssessment(state.assessments, action.payload.assessment_id) });
    case app.ActionTypes.PUBLISH_ASSESSMENT:
      return Object.assign({}, state, { assessments: archiveAssessment(state.assessments, action.payload.assessment_id) });
    case app.ActionTypes.PUBLISH_ASSESSMENT:
      return Object.assign({}, state, { assessments: unarchiveAssessment(state.assessments, action.payload.assessment_id) });
    case app.ActionTypes.SELECTED_ASSESSMENT:
      return Object.assign({}, state, { selectedAssessment: action.payload });
    case app.ActionTypes.SET_TOTAL_PAGES:
      return Object.assign({}, state, { totalPages: action.payload.totalPages });
    case app.ActionTypes.SET_SUBMISSIONS:
      return Object.assign({}, state, { submissions: action.payload.submissions });
    case app.ActionTypes.SET_SELECTED_SUBMISSION:
      return Object.assign({}, state, { selectedSubmission: action.payload.selectedSubmission });
    case app.ActionTypes.GET_SUBMISSIONS_COUNT:
      return Object.assign({}, state, { submissionsCount: action.payload });
    case app.ActionTypes.UPDATE_ALL_ASSESSMENT_TAGS:
      return state;
    case app.ActionTypes.UPDATE_ALL_ASSESSMENT_TAGS_SUCCESS:
      return Object.assign({}, state, { allTags: setAllTags(action.payload) })
    case app.ActionTypes.GET_ALL_ASSESSMENT_TAGS:
      return state;
    case app.ActionTypes.GET_ALL_ASSESSMENT_TAGS_SUCCESS:
      return Object.assign({}, state, { allTags: setAllTags(action.payload) })
    default:
      return state;
  }
}

export const get_questions_data = (state: State) => state.questions;
export const get_assessments = (state: State) => state.assessments;
export const get_published_assessments = (state: State) => state.publishedAssessments;
export const get_selected_assessment = (state: State) => state.selectedAssessment;
export const assessment_total_pages = (state: State) => state.totalPages;
export const get_assessment_submissions = (state: State) => state.submissions;
export const get_selected_submission = (state: State) => state.selectedSubmission;
export const get_submissions_count = (state: State) => state.submissionsCount;
export const get_all_assessment_tags = (state: State) => state.allTags
