import * as app from './action';

export class CurrentPersona {
    clientName: string;
    acadYearFrom: Date;
    acadYearTo: Date;
    id: string;
}

export class Course {
    id: string;
    name: string;
    fullName: string;
    termName: string;
    secName: string;
    sections: Section[] = [];
    status: number
    branch: any;
    selected: boolean = false;
    section: any;
}

export class Exam {
    id: string;
    name: string;
    selected: boolean = false;
}

export class Section {
    name: string;
    subjects: string[] = []
}

export class TT {
    SST: { subjectName: any, staffId: any };
    number: number;
    subject: string;
    teacherData: TeacherData;
}

export class TeacherData {
    stId: string;
}

export class Student {
    status: number;
    present: boolean = true;
    rollNo: number;
    name: string;
    studentId: string;

    attendance: string;
    id: string;
    tags: string;
    gender: string;
    DOB: string;
    DOA: string;
    course: Course;
    courseId: string;
    admissionNo: number;
    applicationNo: number;
    addressLine1: string;
    addressLine2: string;
    email: string;
    mobile: number;
    homePhone: number;
    city: string;
    state: string;
    pinCode: number;
    country: string;
    editRollNo: false;
    editEmail: false;
    editMobile: false;
    editGender: false;
}

export class ExamsCoursewise {
    courseId: string;
    courseName: string;
    branch: string;
    examName: string;
    examIds: any[] = [];
    selected: boolean = false;
}

export class Facets {
    name: string;
    options: FacetOptions[] = [];
}

export class FacetOptions {
    value: string;
}

export class AllTags {
    resourceType: string;
    list: any[]
}
export class QuestionAnswer {
    id: string;
    q: string;
    a: any[];
    options: any[];
    answerEnum: string;
    tags: [];
    hints: [];
    meta: {};
    status: string;
    statusAsString: string;
    ansTypeAsString: string;
    defaultWeightage: number;
    questions: any[];
    difficulty: string;
    difficultyAsString: string;
    keywordsAsString: string;
    modifiedOn: string;
    answerText: string;
}

export interface State {
    userLinks: any[];
    courseList: Course[];
    studentList: Student[];
    absenteeList: Student[];
    examFacetStudents: any[];
    examFacet: any[];
    exams: ExamsCoursewise[];
    selectedExams: ExamsCoursewise[];
    allExams: any[];
    courseFacet: any[];
    questions: any;
    selectedQuestion: any;
    allTags: any;
    qTotalPages: any;
    topics: any[];
    selectedTopic: any;
    allContentTags: any;
    topicsByContent: any[];
    publishedTopics: any[];
}

const initialState: State = {
    userLinks: null,
    courseList: null,
    studentList: null,
    absenteeList: null,
    examFacetStudents: null,
    examFacet: null,
    exams: null,
    selectedExams: null,
    allExams: null,
    courseFacet: null,
    questions: null,
    selectedQuestion: null,
    allTags: null,
    qTotalPages: 0,
    topics: [],
    selectedTopic: null,
    allContentTags: null,
    topicsByContent: null,
    publishedTopics: null
};

function setAllTags(allTags) {
    var tags: AllTags = allTags
    return tags
}

function setPublishedQuestions(questions, publishedQuestions) {
    questions.map(ques => {
        let publishedQues = publishedQuestions.find(publishedQuestion => publishedQuestion.id == ques.id)
        if (publishedQues) {
            ques.a = publishedQues.a
            ques.answerEnum = publishedQues.answerEnum
            ques.meta = publishedQues.meta
            ques.options = publishedQues.options
            ques.q = publishedQues.q
            ques.status = publishedQues.status
            ques.tags = publishedQues.tags
        }
        return ques
    })
    return questions
}
function setUnpublishedQuestions(questions, unpublishedQuestions) {
    questions.map(ques => {
        let unpublishedQues = unpublishedQuestions.find(unpublishedQuestion => unpublishedQuestion.id == ques.id)
        if (unpublishedQues) {
            ques.a = unpublishedQues.a
            ques.answerEnum = unpublishedQues.answerEnum
            ques.meta = unpublishedQues.meta
            ques.options = unpublishedQues.options
            ques.q = unpublishedQues.q
            ques.status = unpublishedQues.status
            ques.tags = unpublishedQues.tags
        }
        return ques
    })
    return questions
}
function saveQuestion(questions, updatedQuestion) {
    questions.map((ques: QuestionAnswer) => {
        if (ques.id == updatedQuestion.id) {
            ques.a = updatedQuestion.a
            ques.answerEnum = updatedQuestion.answerEnum
            ques.meta = updatedQuestion.meta
            ques.options = updatedQuestion.options
            ques.q = updatedQuestion.q
            ques.status = updatedQuestion.status
            ques.tags = updatedQuestion.tags

        }
        return ques
    })
    return questions

}
function selectedQuestion(questions, questionId) {
    var selectedQuestion: QuestionAnswer = questions.find(question => question.id == questionId)
    return selectedQuestion
}
function setQuestions(data) {
    let answerType = {
        1: "Text",
        2: "ManyOf",
        3: "ManyOf"
    }
    let status = {
        40: "wip",
        50: "published"
    }
    var result = []
    if (data) {
        data.forEach(o => {
            let ques = new QuestionAnswer();
            ques.id = o.id;
            ques.meta = o.meta;
            ques.tags = o.tags || [];
            ques.q = o.questionText;
            ques.answerEnum = answerType[o.answerType];
            ques.a = o.answers;
            ques.options = o.options;
            ques.hints = o.hints || [];
            ques.status = status[o.status];
            ques.statusAsString = o.statusAsString;
            ques.difficultyAsString = o.difficultyAsString.toLowerCase();
            ques.difficulty = o.difficultyAsString.toLowerCase();
            ques.keywordsAsString = o.keywordsAsString;
            ques.ansTypeAsString = o.answerTypeAsString;
            ques.defaultWeightage = o.defaultWeightage;
            ques.questions = o.questions;
            ques.modifiedOn = o.modifiedOn;
            ques.answerText = o.answerText || "";
            if (o.questions && o.questions.length > 0) {
                o.questions.forEach(que => {
                    que.id = que.id;
                    que.hints = que.hints || [];
                    que.meta = que.meta;
                    que.keywordsAsString = que.keywordsAsString;
                    que.tags = que.tags || [];
                    que.q = que.questionText;
                    que.answerEnum = answerType[que.answerType];
                    que.a = que.answers;
                    que.status = status[que.status];
                    que.answerText = que.answerTex || "";
                    ques.options = o.options;
                    ques.statusAsString = o.statusAsString;
                    ques.ansTypeAsString = o.answerTypeAsString;
                    ques.defaultWeightage = o.defaultWeightage;
                });
            }
            result.push(ques)
        })
    }
    return result;
}
function setCourseArray(dataFromServer): Course[] {
    let result = [];
    dataFromServer.data.forEach(o => {
        let course = new Course();
        course.id = o.id;
        course.fullName = o.name;
        course.status = o.status;
        course.branch = o.branch;
        if (o.branch) { course.fullName = course.fullName + " " + o.branch; }
        o.terms.forEach(t => {
            course.sections = t.sections;
        });
        result.push(course);
    });
    return result;
}

function setCourseArraySectionwise(dataFromServer): Course[] {
    let courses = [];
    dataFromServer.data.forEach(o => {
        o.terms.forEach(t => {
            if (t)
                t.sections.forEach(s => {
                    if (s) {
                        let course = new Course();
                        course.id = o.id;
                        course.fullName = o.name;
                        course.secName = s.name;
                        course.termName = t.name;
                        course.status = o.status;
                        course.branch = o.branch;
                        if (o.branch) { course.fullName = course.fullName + " " + o.branch; }
                        course.fullName = course.fullName + " " + s.name;
                        courses.push(course);
                    }
                })
        })
    });
    return courses;
}

function setStudentArray(dataFromServer): Student[] {
    let result = [];
    dataFromServer.data.forEach(o => {
        let student = new Student();
        student.name = o.firstName + " " + o.lastName;
        student.DOB = o.dob;
        result.push(student);
    });
    return result;
}

function setCourseArrayForAttendance(dataFromServer): Course[] {
    let courses = [];
    dataFromServer.data.forEach(o => {
        o.terms.forEach(t => {
            if (t)
                t.sections.forEach(s => {
                    if (s) {
                        let course = new Course();
                        course.id = o.id;
                        course.name = o.name;
                        course.fullName = o.name;
                        course.status = o.status;
                        course.secName = s.name;
                        course.termName = t.name;
                        course.branch = o.branch;
                        course.section = s;
                        if (o.branch) { course.fullName = course.fullName + " " + o.branch; }
                        course.fullName = course.fullName + " " + course.secName + " " + "(" + course.termName + ")";
                        courses.push(course);
                    }
                })
        })
    });
    return courses;
}

function disableContent(topic_list, id): any[] {
    const topics: any[] = topic_list.map((item) => {
        if (item.id == id) {
            item.status = 99;
            item.statusAsString = "Disabled";
            return item;
        }
        return item;
    });
    return topics;
}

function unpublishContent(topic_list, id): any[] {
    const topics: any[] = topic_list.map((item) => {
        if (item.id == id) {
            item.status = 1;
            item.statusAsString = "Active";
            return item;
        }
        return item;
    });
    return topics;
}

function enableContent(topic_list, id): any[] {
    const topics: any[] = topic_list.map((item) => {
        if (item.id == id) {
            item.status = 1;
            item.statusAsString = "Active";
            return item;
        }
        return item;
    });
    return topics;
}

function publishContent(topic_list, id): any[] {
    const topics: any[] = topic_list.map((item) => {
        if (item.id == id) {
            item.status = 70;
            item.statusAsString = "Published";
            return item;
        }
        return item;
    });
    return topics;
}

function setExamsCoursewiseArray(dataFromServer) {
    let result = [];
    dataFromServer.forEach(e => {
        let exam = new ExamsCoursewise();
        exam.courseId = e.courseId;
        exam.courseName = e.courseName;
        exam.branch = e.branch;
        exam.examName = e.examName;
        exam.examIds = e.examIds;
        result.push(exam);
    });
    return result;
}

function addTopic(topics, newTopic) {
    topics.push(newTopic);
    return topics;
}

export function reducer(state = initialState, action: app.Actions): State {
    switch (action.type) {
        case app.ActionTypes.ADD_QUESTION:
            return state;
        case app.ActionTypes.ADD_QUESTION_SUCCESS:
            return state;
        case app.ActionTypes.PUBLISH_QUESTIONS:
            return state;
        case app.ActionTypes.PUBLISH_QUESTIONS_SUCCESS:
            return Object.assign({}, state, { questions: setPublishedQuestions(state.questions, action.payload) })
        case app.ActionTypes.UNPUBLISH_QUESTIONS:
            return state;
        case app.ActionTypes.UNPUBLISH_QUESTIONS_SUCCESS:
            return Object.assign({}, state, { questions: setUnpublishedQuestions(state.questions, action.payload) })
        case app.ActionTypes.SAVE_QUESTION:
            return state;
        case app.ActionTypes.SAVE_QUESTION_SUCCESS:
            return Object.assign({}, state, { questions: saveQuestion(state.questions, action.payload) })
        case app.ActionTypes.UPDATE_ALL_TAGS:
            return state;
        case app.ActionTypes.UPDATE_ALL_TAGS_SUCCESS:
            return Object.assign({}, state, { allTags: setAllTags(action.payload) })
        case app.ActionTypes.GET_ALL_TAGS:
            return state;
        case app.ActionTypes.GET_ALL_TAGS_SUCCESS:
            return Object.assign({}, state, { allTags: setAllTags(action.payload) })
        case app.ActionTypes.GET_QUESTIONS:
            return state;
        case app.ActionTypes.GET_QUESTIONS_SUCCESS:
            return Object.assign({}, state, { questions: setQuestions(action.payload) })
        case app.ActionTypes.EDIT_QUESTION:
            return Object.assign({}, state, { selectedQuestion: selectedQuestion(state.questions, action.payload) })
        case app.ActionTypes.SET_SELECTED_USER_LINK:
            return Object.assign({}, state, { selectedUserLink: action.payload.userLink });
        case app.ActionTypes.SET_HTML:
            return Object.assign({}, state, { setHTML: action.payload });
        case app.ActionTypes.SET_USER_LINKS:
            return Object.assign({}, state, { userLinks: action.payload.user_links });
        case app.ActionTypes.UPDATE_DASHBOARD:
            return Object.assign({}, state, { userLinks: action.payload.userLinks });
        case app.ActionTypes.SET_COURSES:
            return Object.assign({}, state, { courseList: setCourseArray(action.payload.course_list) });
        case app.ActionTypes.SET_COURSES_SECTIONWISE:
            return Object.assign({}, state, { courseList: setCourseArraySectionwise(action.payload.course_list) });
        case app.ActionTypes.SET_STUDENTS:
            return Object.assign({}, state, { studentList: setStudentArray(action.payload.student_list) });
        case app.ActionTypes.SET_ABSENTEES:
            return Object.assign({}, state, { absenteeList: action.payload.absentee_list });
        case app.ActionTypes.SET_COURSES_FOR_ATTENDANCE:
            return Object.assign({}, state, { courseList: setCourseArrayForAttendance(action.payload.course_list) });
        case app.ActionTypes.SET_EXAM_FACET_STUDENTS:
            return Object.assign({}, state, { examFacetStudents: action.payload.examFacetStudents });
        case app.ActionTypes.SET_EXAM_FACET:
            return Object.assign({}, state, { examFacet: action.payload.examFacet });
        case app.ActionTypes.SET_COURSE_FACET:
            return Object.assign({}, state, { courseFacet: action.payload.courseFacet });
        case app.ActionTypes.SET_EXAMS_COURSEWISE:
            return Object.assign({}, state, { exams: setExamsCoursewiseArray(action.payload.exams) });
        case app.ActionTypes.SET_SELECTED_EXAMS:
            return Object.assign({}, state, { selectedExams: setExamsCoursewiseArray(action.payload.selected_exams) });
        case app.ActionTypes.SET_ALL_EXAMS:
            return Object.assign({}, state, { allExams: action.payload.allExams });
        case app.ActionTypes.SET_Q_TOTAL_PAGES:
            return Object.assign({}, state, { qTotalPages: action.payload });
        case app.ActionTypes.SET_TOPICS:
            return Object.assign({}, state, { topics: action.payload });
        case app.ActionTypes.SET_TOPICS_BY_CONTENT:
            return Object.assign({}, state, { topicsByContent: action.payload });
        case app.ActionTypes.SET_SELECTED_TOPIC:
            return Object.assign({}, state, { selectedTopic: action.payload });
        case app.ActionTypes.ADD_TOPIC:
            return Object.assign({}, state, { topics: addTopic(state.topics, action.payload) });
        case app.ActionTypes.UPDATE_ALL_CONTENT_TAGS:
            return state;
        case app.ActionTypes.UPDATE_ALL_CONTENT_TAGS_SUCCESS:
            return Object.assign({}, state, { allContentTags: setAllTags(action.payload) })
        case app.ActionTypes.GET_ALL_CONTENT_TAGS:
            return state;
        case app.ActionTypes.GET_ALL_CONTENT_TAGS_SUCCESS:
            return Object.assign({}, state, { allContentTags: setAllTags(action.payload) })
        case app.ActionTypes.DISABLE_CONTENT:
            return Object.assign({}, state, { topics: disableContent(state.topics, action.payload) })
        case app.ActionTypes.ENABLE_CONTENT:
            return Object.assign({}, state, { topics: enableContent(state.topics, action.payload) })
        case app.ActionTypes.PUBLISH_CONTENT:
            return Object.assign({}, state, { topics: publishContent(state.topics, action.payload) })
        case app.ActionTypes.UNPUBLISH_CONTENT:
            return Object.assign({}, state, { topics: unpublishContent(state.topics, action.payload) })
            case app.ActionTypes.SET_PUBLISHED_TOPICS:
                return Object.assign({}, state, { publishedTopics: action.payload });
            default:
            return state;
    }
}

export const get_state_academics = (state: State) => state;
export const get_courses_for_acad = (state: State) => state.courseList;
export const get_courses_sectionwise_for_acad = (state: State) => state.courseList;
export const get_students_for_acad = (state: State) => state.studentList;
export const get_absentee_list = (state: State) => state.absenteeList;
export const get_students_for_exam_facet = (state: State) => state.examFacetStudents;
export const get_exam_facet = (state: State) => state.examFacet;
export const get_course_facet = (state: State) => state.courseFacet;
export const get_exams_coursewise_for_acad = (state: State) => state.exams;
export const get_selected_exams = (state: State) => state.selectedExams;
export const get_all_exams_for_acad = (state: State) => state.allExams;
export const get_questions = (state: State) => state.questions;
export const get_all_tags = (state: State) => state.allTags;
export const get_selected_question = (state: State) => state.selectedQuestion;
export const get_q_total_pages = (state: State) => state.qTotalPages;
export const get_topics = (state: State) => state.topics;
export const get_topics_by_content = (state: State) => state.topicsByContent;
export const get_selected_topic = (state: State) => state.selectedTopic;
export const get_all_content_tags = (state: State) => state.allContentTags;
export const get_published_topics = (state: State) => state.publishedTopics;