import { Component, OnInit,Input,OnChanges } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { MathJxService as service } from './service/service';

@Component({
  selector: 'mathjax',
  inputs:['content'],
  templateUrl: './component.html',
  styleUrls: ['./component.css'],
  providers: [service],
})
export class MathjaxComponent implements OnChanges {
    @Input() content: string;

    constructor(public service: service) { }
    mathJaxObject;
    ngOnChanges(changes: SimpleChanges) {
        if (changes['content']) {
            this.renderMath()
        }
    }

    renderMath() {
        this.mathJaxObject = this.service.nativeGlobal()['MathJax'];
        let angObj = this;
        setTimeout(() => {
            angObj.mathJaxObject.Hub.Queue(["Typeset", angObj.mathJaxObject.Hub], 'mathContent');
        }, 1000)
    }
    loadMathConfig() {
        this.mathJaxObject = this.service.nativeGlobal()['MathJax'];
        this.mathJaxObject.Hub.Config({
            showMathMenu: false,
            tex2jax: { inlineMath: [["$", "$"], ["\\(", "\\)"]] },
            menuSettings: { zoom: "Double-Click", zscale: "150%" },
            CommonHTML: { linebreaks: { automatic: true } },
            "HTML-CSS": { linebreaks: { automatic: true } },
            SVG: { linebreaks: { automatic: true } }
        });
    }
    ngOnInit() {
        this.loadMathConfig()
        this.renderMath();
    }
}