import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import * as rootReducer from '../../rootReducer';
import { SMSDlrChartService as service } from './service/service';
import * as _ from 'underscore';

class Model {
    smsDlrData: any[] = [];
    totalCredits: any;
    totalDelivered: any;
    totalUndelivered: any;
    totalRecipients: any;
}
@Component({
    selector: 'sms-dlr-report',
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
    providers: [service]
})

export class SMSDlrReportComponent {
    public m: Model;
    dlrData: any;
    @Input() pin: boolean;
    @Input() selectedMonthYear: string;
    constructor(private store: Store<rootReducer.State>, private service: service) { }

    ngOnInit() {
        this.init_model();
        this.init_store();
    }
    init_model() {
        this.m = new Model();
    }

    init_store() {
        this.store.select(rootReducer.get_selected_month_year).subscribe((res: any) => {
            if (res) {
                this.render_chart(res.monthYear);
            }
        });
        this.store.select(rootReducer.get_sms_delivery_chart_data).subscribe(res => {
            if (res) {
                this.dlrData = res.sms_dlr_json;
                this.render_chart(this.selectedMonthYear);
            }
        });
    }

    render_chart(monthYear) {
        let data = this.dlrData;
        let mth_yr = monthYear.split(' ');
        let dlr_data = _.find(data, function (d) {
            return (d.month == mth_yr[0] && d.year == mth_yr[1]);
        });
        if (dlr_data) {
            this.m.smsDlrData = dlr_data.dlr;
            this.m.totalDelivered = dlr_data.total_delivered.coef;
            this.m.totalUndelivered = dlr_data.total_undelivered.coef;
            this.m.totalCredits = dlr_data.total_credits.coef;
            this.m.totalRecipients = dlr_data.total_recipients.coef;
        }
        else {
            this.m.smsDlrData = [];
            this.m.totalDelivered = 0;
            this.m.totalUndelivered = 0;
            this.m.totalCredits = 0;
            this.m.totalRecipients = 0;
        }
    }

    pinChart() {
        this.pin = false;
        let payload = ["sms_dlr_json"];
		this.service.pinWidget(payload).subscribe(res => {
			if(res) this.service.getDashboardWidgets();
		});
     
    }
    unpinChart() {
        this.pin = true;
        let payload = ["sms_dlr_json"];
		this.service.unpinWidget(payload).subscribe(res => {
			if(res) this.service.getDashboardWidgets();
		});
    }
}