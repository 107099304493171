import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { PulseService } from '../../../../app/service/pulse';

import { CTApi } from '../../../../app/service/ct-api';
import * as rootReducer from '../../../../app/rootReducer';
import { environment } from '../../../../environments/environment';
import * as googleMeetAction from '../store/action';

@Injectable()
export class GoogleMeetService {
    constructor(public ctapi: CTApi, private pulseService: PulseService, private store: Store<rootReducer.State>, private httpClient: HttpClient) { }

    createInventoryJournal(ownerId, partyId, date, creditItems, debitItems, reference, particulars) {
        return this.ctapi.createInventoryJournal(ownerId, partyId, date, creditItems, debitItems, reference, particulars);
    };
    
    sendParentSMS(recp, text, smsRefId, includeTags) {
        return this.ctapi.sendParentSMSMeet(recp, text, smsRefId, includeTags);
    }
    
    getParentSMSRecp(recp, text, includeTags) {
        return this.ctapi.getParentSMSRecpMeet(recp, text, includeTags);
    }
    
    uploadNotes(fileList, eventId, meetingLink) {
        const uploadUrl = `${environment.API_URL}/arte/artefact/uploadNotes`;
        const formData = new FormData();
        // JavaScript file-like object
        if (fileList.length > 0) {
            fileList.forEach(e => {
                formData.append('files', e);
                formData.append('eventId', eventId);
                formData.append('meetingLink', meetingLink)
            });
            this.httpClient.post(uploadUrl, formData).subscribe(res => {
                if (res) {
                    this.pulseService.sync_notes(eventId, meetingLink);
                }
            });
        }
    }

    getStudentProfile(userEmail)
    {
      return this.ctapi.getStudentProfile(userEmail);
    }
}