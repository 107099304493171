import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DragulaService } from 'ng2-dragula';

@Component({
  selector: 'app-dex-select',
  templateUrl: './dex-select.component.html',
  styleUrls: ['./dex-select.component.css']
})
export class DexSelectComponent implements OnInit {
  @Input() dexSelection: string[];
  @Output() onChanges: EventEmitter<{ type: string; value: string; json: Array<string> }> = new EventEmitter();
  selections: Array<{ column: string; checked: boolean }> = []

  constructor(private dragulaService: DragulaService) { }

  ngOnInit() {
    this.selections = this.dexSelection.map((column) => ({ column, checked: true }));
    this.emitChanges();
    this.dragulaService.drop("dex-select").subscribe((value) => {
      this.emitChanges();
    });
  }
  ut_onChangeSelection(e: any) {
    this.emitChanges();
  }
  private emitChanges() {
    this.onChanges.emit({ type: "select", value: this.pRQL, json: this.json });
  }
  private get json(): Array<string> {
    return this.selections.filter((s) => s.checked).map(c => c.column);
  }
  private get pRQL(): string {
    return "select " + this.selections.filter((s) => s.checked).map(c => c.column).join(", ");
  }
}
