import { Action } from '@ngrx/store';
import { type } from '../../util';

export const ActionTypes = {
  SET_TAB: type('[NavTabs] Set Tab'),
};

export class SetTab implements Action {
  type = ActionTypes.SET_TAB;
  constructor(public payload: any = null) {
  }
}

export type Actions
  = SetTab;