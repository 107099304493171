import * as app from './action';

export class CurrentPersona {
    clientName: string;
    acadYearFrom: Date;
    acadYearTo: Date;
    id: string;
}

export class Designation {
    title: string = "";
    from: Date;
    remarks: string;
    role: string;
    subjectsTaught: any;
}

export class Staff {
    id: string;
    name: string;
    alias: string;
    DOJ: Date;
    DOB: Date;
    currentDesignation: string;
    designations: any[] = [];
    staffType: string;
    classTeacher: string;
    status: number;
    gender: string;
    email: string;
    mobile: number;
    refId: string;
    editGender: false;
    editStaffRefId: false;
    salaries: any[] = [];
    payslips: any[] = [];
    attendance: string;
    attendanceList:any[];
    attList: any;
}

export class Course {
    id: string;
    fullName: string;
    sections: Section[] = [];
    status: number
}

export class Section {
    name: string;
    subjects: string[] = []
}

function assign() { }

function disableStaff(staffs, id) {
    var staffList: Staff[] = staffs.map((staff) => {
        if (staff.id == id) staff.status = 99;
        return staff;
    });
    return staffList;
}

function enableStaff(staffs, id) {
    var staffList: Staff[] = staffs.map((staff) => {
        if (staff.id == id) staff.status = 1;
        return staff;
    });
    return staffList;
}

function selectedStaff(staffs, id): Staff {
    var selectedStaff: Staff = staffs.find(staff => staff.id == id);
    return selectedStaff;
}

function setStaffArray(dataFromServer): Staff[] {
    let result = [];
    dataFromServer.data.forEach(o => {
        let staff = new Staff();
        staff.id = o.id;
        staff.name = o.name;
        staff.refId = o.refId;
        staff.DOJ = o.doj.substring(0, 10);
        if (o.dob)
            staff.DOB = o.dob.substring(0, 10);
        staff.gender = o.gender;
        staff.designations = o.designations;
        if (o.staffType == 0)
            staff.staffType = "Teaching";
        else if (o.staffType == 1)
            staff.staffType = "NonTeaching";
        else
            staff.staffType = "Management";
        staff.salaries = o.salaries;
        staff.payslips = o.payslips;
        staff.email = o.email;
        staff.mobile = o.mobile;
        staff.status = o.status;
        staff.alias = o.alias;
        staff.classTeacher = o.classTeacher;
        staff.attendanceList = o.attendanceList;

        if(o.contacts){
        o.contacts.forEach(c => {
            if (c.contactType == 10)
                staff.email = c.info;
            else
                staff.mobile = c.info;
        })
    }
        result.push(staff);
    });

    return result;
}

function updateStaff(staffs, id, name, designation, DOB, DOJ, role) {
    var staffList: Staff[] = staffs.map((staff) => {
        if (staff.id == id) {
            staff.name = name;
            staff.designation = designation;
            staff.DOB = DOB;
            staff.DOJ = DOJ;
            staff.role = role;
        }
        return staff;
    });
}

function updateEmail(staffs, id, email) {
    var staffList: Staff[] = staffs.map((staff) => {
        if (staff.id == id) {
            staff.email = email;
        }
        return staff;
    });
}

function updateMobile(staffs, id, mobile) {
    var staffList: Staff[] = staffs.map((staff) => {
        if (staff.id == id) {
            staff.mobile = mobile;
        }
        return staff;
    });
}

function updateRefId(staffs, id, refId) {
    var staffList: Staff[] = staffs.map((staff) => {
        if (staff.id == id) {
            staff.refId = refId;
        }
        return staff;
    });
}

export interface State {
    currentPersona: CurrentPersona;
    staffs: Staff[];
    selectedStaff: Staff;
    courseList: Course[];
    salaryStructures: any[];
    currentSalaryStructure: any;
    salaries: any[];
    selectedStaffDetails: any;
    payslipMonthYear: any;
}

const initialState: State = {
    currentPersona: null,
    staffs: [],
    selectedStaff: new Staff(),
    courseList: null,
    salaryStructures: [],
    currentSalaryStructure: null,
    salaries: null,
    selectedStaffDetails: null,
    payslipMonthYear: null
};

function setCourseArray(dataFromServer): Course[] {
    let courses = [];
    dataFromServer.data.forEach(o => {
        o.terms.forEach(t => {
            if (t)
                t.sections.forEach(s => {
                    if (s) {
                        let course = new Course();
                        course.id = o.id;
                        course.fullName = o.name;
                        course.status = o.status;
                        if (o.branch) { course.fullName = course.fullName + " " + o.branch; }
                        course.fullName = course.fullName + " " + s.name;
                        courses.push(course);
                    }
                })
        })
    });
    return courses;
}

export function reducer(state = initialState, action: app.Actions): State {
    switch (action.type) {
        case app.ActionTypes.ADD_STAFF:
            return Object.assign({}, state, { staff: action.payload.staff });
        case app.ActionTypes.ASSIGN:
            return Object.assign({}, state, { staff: assign() });
        case app.ActionTypes.DISABLE_STAFF:
            return Object.assign({}, state, { staffs: disableStaff(state.staffs, action.payload.id) });
        case app.ActionTypes.ENABLE_STAFF:
            return Object.assign({}, state, { staffs: enableStaff(state.staffs, action.payload.id) });
        case app.ActionTypes.SET_SELECTED_STAFF:
            return Object.assign({}, state, { selectedStaff: selectedStaff(state.staffs, action.payload.staff_id) });
        case app.ActionTypes.SET_STAFF:
            return Object.assign({}, state, { staffs: setStaffArray(action.payload.staff_list) });
        case app.ActionTypes.UPDATE:
            return Object.assign({}, state, { staffs: updateStaff(state.staffs, action.payload.id, action.payload.name, action.payload.designation, action.payload.DOB, action.payload.DOJ, action.payload.role) });
        case app.ActionTypes.UPDATE_EMAIL:
            return Object.assign({}, state, { staffs: updateEmail(state.staffs, action.payload.id, action.payload.email) });
        case app.ActionTypes.UPDATE_MOBILE:
            return Object.assign({}, state, { staffs: updateMobile(state.staffs, action.payload.id, action.payload.mobile) });
        case app.ActionTypes.UPDATE_REF_ID:
            return Object.assign({}, state, { staffs: updateRefId(state.staffs, action.payload.id, action.payload.refId) });
        case app.ActionTypes.SET_COURSES:
            return Object.assign({}, state, { courseList: setCourseArray(action.payload.course_list) });
        case app.ActionTypes.SET_SALARY_STRUCTURES:
            return Object.assign({}, state, { salaryStructures: action.payload.salaryStructures });
        case app.ActionTypes.SET_CURRENT_SALARY_STRUCTURE:
            return Object.assign({}, state, { currentSalaryStructure: action.payload.currentSalaryStructure });
        case app.ActionTypes.SET_STAFF_SALARIES:
            return Object.assign({}, state, { salaries: action.payload.salaries });
        case app.ActionTypes.SET_SELECTED_STAFF_DETAILS:
            return Object.assign({}, state, { selectedStaffDetails: action.payload.staffDetails });
            case app.ActionTypes.SET_PAYSLIP_DATE:
            return Object.assign({}, state, { payslipMonthYear: action.payload.payslipMonthYear })
            
        default:
            return state;
    }
}

export const get_state_staff = (state: State) => state;
export const get_staff = (state: State) => state.staffs;
export const get_selected_staff = (state: State) => state.selectedStaff;
export const get_courses_for_staff = (state: State) => state.courseList;
export const get_salary_structures = (state: State) => state.salaryStructures;
export const get_current_salary_structure = (state: State) => state.currentSalaryStructure;
export const get_staff_salaries = (state: State) => state.salaries;
export const get_selected_staff_details = (state: State) => state.selectedStaffDetails;
export const get_payslip_month_year = (state: State) => state.payslipMonthYear;