import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs/Subscription';

import * as rootReducer from '../../rootReducer';

class Model {
    topics: any[]=[];
}

@Component({
    selector: 'subscriptions',
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
})

export class SubscriptionsComponent implements OnInit {
    public m: Model;
    constructor(private store: Store<rootReducer.State>) { }

    ngOnInit(){
        this.init_model();
        this.init_store();
        this.sub_store();
    }

    init_model(){
        this.m = new Model();
    }
    init_store(){}
    sub_store(){
        this.m.topics = [{
            "id": "",
            "name": "puc_I/pcmb",
            "base": "btlcol/2021",
            "description": "Faculty / Students",
            "friendlyName": "puc_I/pcmb",
            "type": "invitations"
        }]
    }
}