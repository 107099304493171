import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { AcademicsService as service } from '../../academics/service/service';
import * as _ from 'underscore';
import * as LC from 'literallycanvas';
import { FileUploader } from 'ng2-file-upload';
import 'brace/index';
import 'brace/theme/eclipse';
import 'brace/mode/markdown';
import 'brace/ext/language_tools';
import * as marked from 'marked';
import * as stopWords from '../../../../assets/dummy/stop-words.json';
import { KatexOptions, MarkdownService } from 'ngx-markdown';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import * as rootReducer from '../../rootReducer';

class Model {
  selectedItem:any = {};
  itemNameCopy:string="";
}

const URL = '';

@Component({
  selector: 'title-tree',
  templateUrl: './component.html',
  styleUrls: ['./component.css'],
  providers: [service],
})
export class TitleTreeComponent implements OnInit {
  constructor(private store: Store<rootReducer.State>, private el: ElementRef, public service: service, private markdownService: MarkdownService) {
  }
  @Input('data') items: Array<Object>;
  @Input('key') key: string;
  @Output() selectedItem = new EventEmitter<any>();
  public m: Model;
  ngOnInit() {
    this.init_model();
    this.sub_store();
  }
  ngAfterViewInit() {
  }
  init_model() {
    this.m = new Model()
  }
  sub_store() {
  }
  toggleExpand(item){
    if(item.children && item.children.length>0){
      item.expand = !item.expand;
    } 
  }
  setSelectedItem(item){
    this.m.selectedItem = item;
    this.m.selectedItem.selected = true;
    this.selectedItem.emit(this.m.selectedItem);
  }
  selectedNode(ev){
    this.selectedItem.emit(ev); 
  }
}