import { Action } from '@ngrx/store';
import { type } from '../../util';

export const ActionTypes = {
    SET_REPORT: type('[Report] Set Report'),
    SET_VIEW: type('[Report] Set View'),
    DISABLE_REPORT: type('[Report] Disable Report'),
    ENABLE_REPORT: type('[Report] Enable Report'),
    SET_REPORT_CONTEXT: type('[Report] Set Report Context'),
    SET_SELECTED_REPORT: type('[Report] Set Selected Report'),
    UPDATE_REPORT: type('[Report] Update Report'),
    SET_TEMPLATE: type('[Report] Set Template'),
};

export class SetReport implements Action {
    type = ActionTypes.SET_REPORT;
    constructor(public payload: any) { }
}
export class SetTemplate implements Action {
    type = ActionTypes.SET_TEMPLATE;
    constructor(public payload: any) { }
}
export class SetView implements Action {
    type = ActionTypes.SET_VIEW;
    constructor(public payload: any) { }
}
export class UpdateReport implements Action {
    type = ActionTypes.UPDATE_REPORT;
    constructor(public payload: any) { }
}
export class DisableReport implements Action {
    type = ActionTypes.DISABLE_REPORT;
    constructor(public payload: any) { }
}
export class EnableReport implements Action {
    type = ActionTypes.ENABLE_REPORT;
    constructor(public payload: any) { }
}
export class SetReportContext implements Action {
    type = ActionTypes.SET_REPORT_CONTEXT;
    constructor(public payload: any) { }
}
export class SetSelectedReport implements Action {
    type = ActionTypes.SET_SELECTED_REPORT;
    constructor(public payload: any) { }
}

export type Actions
    = SetReport | DisableReport | EnableReport | UpdateReport | SetSelectedReport | SetView | SetTemplate;
