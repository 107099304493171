import { Action } from '@ngrx/store';
import { type } from '../../../app/util';

export const ActionTypes = {
    SET_SELECTED_USER_LINK: type('[Academics] Set Selected User Link'),
    SET_USER_LINKS: type('[Academics]] Set User Links'),
    UPDATE_DASHBOARD: type('[Academics] Update Dashboard'),
    SET_HTML: type('[Academics] Set HTML'),
    SET_COURSES: type('[Academics] Set Courses'),
    SET_EXAMS_COURSEWISE: type('[Exam] Set Exams Coursewise'),
    SET_STUDENTS: type('[Academics] Set Students'),
    SET_ABSENTEES: type('[Academics] Set Absentees'),
    SET_COURSES_FOR_ATTENDANCE: type('[Academics] Set Courses for Attendance'),
    SET_COURSES_SECTIONWISE: type('[Academics] Set Courses Sectionwise'),
    SET_SELECTED_EXAMS: type('[Academics] Set Selected Exams'),
    SET_ALL_EXAMS: type('[Academics] Set All Exams'),
    SET_EXAM_FACET: type('[Academics] Set Exam Facet'),
    SET_COURSE_FACET: type('[Academics] Set Course Facet'),
    SET_EXAM_FACET_STUDENTS: type('[Academics] Set Exam Facet Students'),
    GET_QUESTIONS: type('[QA] Get Questions'),
    GET_QUESTIONS_SUCCESS: type('[QA] Get Questions Success'),
    GET_ALL_TAGS: type('[QA] Get All Tags'),
    GET_ALL_TAGS_SUCCESS: type('[QA] Get All Tags Success'),
    UPDATE_ALL_TAGS: type('[QA] Update All Tags'),
    UPDATE_ALL_TAGS_SUCCESS: type('[QA] Update All Tags Success'),
    EDIT_QUESTION: type('[QA] Edit Question'),
    SAVE_QUESTION: type('[QA] Save Question'),
    SAVE_QUESTION_SUCCESS: type('[QA] Save Question Success'),
    PUBLISH_QUESTIONS: type('[QA] Publish Questions'),
    PUBLISH_QUESTIONS_SUCCESS: type('[QA] Publish Questions Success'),
    UNPUBLISH_QUESTIONS: type('[QA] Unpublish Questions'),
    UNPUBLISH_QUESTIONS_SUCCESS: type('[QA] Unpublish Questions Success'),
    ADD_QUESTION: type('[QA] Add Question'),
    ADD_QUESTION_SUCCESS: type('[QA] Add Question Success'),
    SET_Q_TOTAL_PAGES: type('[QA] Set Q Total Pages'),
    SET_TOPICS: type('[TOPIC] Set Topics'),
    SET_TOPICS_BY_CONTENT: type('[TOPIC] Set Topics By Content'),
    SET_SELECTED_TOPIC: type('[TOPIC] Set Selected Topic'),
    ADD_TOPIC: type('[TOPIC] Add Topic'),
    GET_ALL_CONTENT_TAGS: type('[Content] Get All Content Tags'),
    GET_ALL_CONTENT_TAGS_SUCCESS: type('[Content] Get All Content Tags Success'),
    UPDATE_ALL_CONTENT_TAGS: type('[Content] Update All Conetnt Tags'),
    UPDATE_ALL_CONTENT_TAGS_SUCCESS: type('[Content] Update All Content Tags Success'),
    DISABLE_CONTENT: type('[Content] Disable Content'),
    ENABLE_CONTENT: type('[Content] Enable Content'),
    PUBLISH_CONTENT: type('[Content] Publish Content'),
    UNPUBLISH_CONTENT: type('[Content] Unpublish Content'),
    SET_PUBLISHED_TOPICS: type('[Content] Set Published Topics')
};

export class AddQuestion implements Action {
    type = ActionTypes.ADD_QUESTION
    constructor(public payload: any) { }
}
export class AddQuestionSuccess implements Action {
    type = ActionTypes.ADD_QUESTION_SUCCESS
    constructor(public payload: any) { }
}
export class PublishQuestions implements Action {
    type = ActionTypes.PUBLISH_QUESTIONS
    constructor(public payload: any) { }
}
export class PublishQuestionsSuccess implements Action {
    type = ActionTypes.PUBLISH_QUESTIONS_SUCCESS
    constructor(public payload: any) { }
}
export class UnpublishQuestions implements Action {
    type = ActionTypes.UNPUBLISH_QUESTIONS
    constructor(public payload: any) { }
}
export class UnpublishQuestionsSuccess implements Action {
    type = ActionTypes.UNPUBLISH_QUESTIONS_SUCCESS
    constructor(public payload: any) { }
}
export class SaveQuestionSuccess implements Action {
    type = ActionTypes.SAVE_QUESTION_SUCCESS
    constructor(public payload: any) { }
}
export class SaveQuestion implements Action {
    type = ActionTypes.SAVE_QUESTION
    constructor(public payload: any) { }
}
export class UpdateAllTags implements Action {
    type = ActionTypes.UPDATE_ALL_TAGS;
    constructor(public payload: any) { }
}
export class UpdateAllTagsSuccess implements Action {
    type = ActionTypes.UPDATE_ALL_TAGS_SUCCESS;
    constructor(public payload: any) { }
}
export class GetAllTags implements Action {
    type = ActionTypes.GET_ALL_TAGS;
    constructor(public payload: any) { }
}
export class GetAllTagsSuccess implements Action {
    type = ActionTypes.GET_ALL_TAGS_SUCCESS;
    constructor(public payload: any) { }
}
export class GetQuestions implements Action {
    type = ActionTypes.GET_QUESTIONS;
    constructor(public payload: any) { }
}

export class GetQuestionsSuccess implements Action {
    type = ActionTypes.GET_QUESTIONS_SUCCESS;
    constructor(public payload: any) { }
}

export class EditQuestion implements Action {
    type = ActionTypes.EDIT_QUESTION;
    constructor(public payload: String) { }
}
export class SetHTML implements Action {
    type = ActionTypes.SET_HTML;
    constructor(public payload: any) { }
}

export class SetSelectedUserLink implements Action {
    type = ActionTypes.SET_SELECTED_USER_LINK;
    /**"Id": "" */
    constructor(public payload: any) { }
}

export class SetUserLinks implements Action {
    type = ActionTypes.SET_USER_LINKS;
    constructor(public payload: any) { }
}

export class UpdateDashboard implements Action {
    type = ActionTypes.UPDATE_DASHBOARD;
    constructor(public payload: any) { }
}

export class SetCourses implements Action {
    type = ActionTypes.SET_COURSES;
    constructor(public payload: any) {
    }
}

export class SetCoursesSectionwise implements Action {
    type = ActionTypes.SET_COURSES_SECTIONWISE;
    constructor(public payload: any) {
    }
}

export class SetCoursesForAttendance implements Action {
    type = ActionTypes.SET_COURSES_FOR_ATTENDANCE;
    constructor(public payload: any) {
    }
}

export class SetExamsCoursewise implements Action {
    type = ActionTypes.SET_EXAMS_COURSEWISE;
    constructor(public payload: any) { }
}

export class SetStudents implements Action {
    type = ActionTypes.SET_STUDENTS;
    constructor(public payload: any) {
    }
}

export class SetAbsentees implements Action {
    type = ActionTypes.SET_ABSENTEES;
    constructor(public payload: any) {
    }
}

export class SetExamFacetStudents implements Action {
    type = ActionTypes.SET_EXAM_FACET_STUDENTS;
    constructor(public payload: any) { }
}

export class SetExamFacet implements Action {
    type = ActionTypes.SET_EXAM_FACET;
    constructor(public payload: any) { }
}

export class SetCourseFacet implements Action {
    type = ActionTypes.SET_COURSE_FACET;
    constructor(public payload: any) { }
}

export class SetSelectedExams implements Action {
    type = ActionTypes.SET_SELECTED_EXAMS;
    constructor(public payload: any) { }
}

export class SetAllExams implements Action {
    type = ActionTypes.SET_ALL_EXAMS;
    constructor(public payload: any) { }
}

export class SetQTotalPages implements Action {
    type = ActionTypes.SET_Q_TOTAL_PAGES;
    constructor(public payload: any) { }
}

export class SetTopics implements Action {
    type = ActionTypes.SET_TOPICS;
    constructor(public payload: any) { }
}

export class SetTopicsByContent implements Action {
    type = ActionTypes.SET_TOPICS_BY_CONTENT;
    constructor(public payload: any) { }
}

export class SetSelectedTopic implements Action {
    type = ActionTypes.SET_SELECTED_TOPIC;
    constructor(public payload: any) { }
}

export class AddTopic implements Action {
    type = ActionTypes.ADD_TOPIC;
    constructor(public payload: any) { }
}

export class UpdateAllContentTags implements Action {
    type = ActionTypes.UPDATE_ALL_CONTENT_TAGS;
    constructor(public payload: any) { }
}
export class UpdateAllContentTagsSuccess implements Action {
    type = ActionTypes.UPDATE_ALL_CONTENT_TAGS_SUCCESS;
    constructor(public payload: any) { }
}
export class GetAllContentTags implements Action {
    type = ActionTypes.GET_ALL_CONTENT_TAGS;
    constructor(public payload: any) { }
}
export class DisableContent implements Action {
    type = ActionTypes.DISABLE_CONTENT;
    constructor(public payload: any) { }
}
export class EnableContent implements Action {
    type = ActionTypes.ENABLE_CONTENT;
    constructor(public payload: any) { }
}
export class PublishContent implements Action {
    type = ActionTypes.PUBLISH_CONTENT;
    constructor(public payload: any) { }
}
export class UnpublishContent implements Action {
    type = ActionTypes.UNPUBLISH_CONTENT;
    constructor(public payload: any) { }
}
export class GetAllContentTagsSuccess implements Action {
    type = ActionTypes.GET_ALL_CONTENT_TAGS_SUCCESS;
    constructor(public payload: any) { }
}

export class SetPublishedTopics implements Action {
    type = ActionTypes.SET_PUBLISHED_TOPICS;
    constructor(public payload: any) { }
}

export type Actions
    = SetAllExams | SetExamsCoursewise | SetHTML | SetSelectedUserLink | SetUserLinks | UpdateDashboard | SetCourses | SetCoursesSectionwise | SetStudents | SetAbsentees | SetCoursesForAttendance | SetExamFacetStudents | SetSelectedExams | SetExamFacet | SetCourseFacet
    | GetQuestions | GetQuestionsSuccess | EditQuestion | GetAllTags | GetAllTagsSuccess | UpdateAllTags | UpdateAllTagsSuccess | SaveQuestion | SaveQuestionSuccess | PublishQuestions | PublishQuestionsSuccess | UnpublishQuestions | UnpublishQuestionsSuccess | AddQuestion
    | AddQuestionSuccess | SetQTotalPages | SetTopics | SetSelectedTopic | AddTopic | GetAllContentTags | GetAllContentTagsSuccess | UpdateAllContentTags | UpdateAllContentTagsSuccess | SetTopicsByContent | DisableContent | EnableContent | PublishContent | UnpublishContent | SetPublishedTopics;