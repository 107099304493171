import { Component, OnInit } from '@angular/core';

import { Store } from '@ngrx/store';
import * as rootReducer from '../rootReducer';
import { Subscription } from 'rxjs/Subscription';

class Model {}

@Component({
	templateUrl: './component.html',
	styleUrls: ['./component.css']
})
export class UserPreferencesComponent implements OnInit {
    public m: Model;

    ngOnInit() {
		this.init_model();
		this.init_store();
		this.sub_store();
	}
	ngOnDestroy() {
	}
	init_model() {
		this.m = new Model();
	}
	init_store() {
	}
	sub_store() {}
}