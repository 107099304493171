import { Component, ElementRef, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ApiService } from '../../chart-dashboard/service/api-service';
import { ChartWidgetModel } from '../../chart-dashboard/service/models';
import * as R from 'ramda';
import { Store } from "@ngrx/store";
import * as rootReducer from '../../../../app/rootReducer';
import { Subscription } from 'rxjs';

declare const google: any;

@Component({
  selector: 'gv-barchart',
  templateUrl: './gv-barchart.component.html',
  styleUrls: ['./gv-barchart.component.css']
})
export class GvBarchartComponent implements OnInit {
  cwm!: ChartWidgetModel;
  @Input() modelObserver: any;
  currentPersona: string;
  subs = new Subscription();

  @ViewChild('barchart', { read: ViewContainerRef }) barChartElement?: ElementRef;
  chartStatus = 'Loading chart';

  barChartSpec: any = {
    "$schema": "https://vega.github.io/schema/vega-lite/v5.json",
    "description": "A simple bar chart with embedded data.",
    "data": {
      "name": 'table',
      "values": [
        {
          "a": "A",
          "b": 28
        },
        {
          "a": "B",
          "b": 55
        },
        {
          "a": "C",
          "b": 43
        }
      ]
    },
    "mark": "bar",
    "encoding": {
      "x": { "field": "a", "type": "nominal", "axis": { "labelAngle": 0 } },
      "y": { "field": "b", "type": "quantitative" }
    }
  };

  constructor(private apiService: ApiService, private store: Store<rootReducer.State>) { }

  ngOnInit(): void {
    this.sub_store();
  }

  sub_store() {
    if (this.modelObserver) {
      this.modelObserver.subscribe((modelObserver: any) => {
        this.cwm = modelObserver;
        this.drawChart();
      });
    } else {
      this.cwm = new ChartWidgetModel();
    }
    this.store.select(rootReducer.get_state_app).subscribe((res: any) => {
      if (res) {
        this.currentPersona = res.currentPersona.title;
      }
    });
    this.subs.add(this.store.select(rootReducer.get_chart_meta_data).subscribe((res: any) => {
      if (res) {
        this.cwm.chartMetaData = res;
        this.drawChart();
      }
    }));
  }

  ngOnDestroy() {
    if (this.modelObserver) {
      this.modelObserver.unsubscribe();
    }
    this.subs.unsubscribe();
  }

  ut_refreshWidget() {
    this.apiService.refreshWidgetData(this.cwm);
  }

  drawChart() {
    if (this.cwm.options) {
      this.barChartSpec = R.mergeDeepRight(this.barChartSpec, this.cwm.options);
      if (this.barChartSpec.hasOwnProperty('vconcat')) {
        delete this.barChartSpec['mark'];
        delete this.barChartSpec['encoding'];
      }
    }
    if ((!R.path(["data", "isSampleData"], this.cwm.widgetSpecs)) && R.path(["widgetSpecs", "data", "api", "end_point"], this.cwm) && R.path([this.cwm.dashboardId, this.cwm.widgetId, "msg"], this.cwm.chartMetaData)) {
      this.chartStatus = R.path([this.cwm.dashboardId, this.cwm.widgetId, "msg"], this.cwm.chartMetaData);
    } else if (!R.path(["data", "isSampleData"], this.cwm.widgetSpecs) && R.path(["widgetSpecs", "data", "api", "end_point"], this.cwm) && this.cwm.data_values && (this.cwm.data_values.length > 0)) {
      this.barChartSpec['data']['values'] = this.cwm.data_values;
      try {
        google.charts.load('visualization', { 'packages': ['vegachart'] });
        const self: any = this;
        google.setOnLoadCallback(function () {
          const dataTable = new google.visualization.DataTable();
          const chart = new google.visualization.VegaChart(self.barChartElement.element.nativeElement);
          chart.draw(dataTable, { 'vegaLite': self.barChartSpec });
        });
      } catch (_) { }
    } else if (R.path(["data", "isSampleData"], this.cwm.widgetSpecs) && this.cwm.data_values && (this.cwm.data_values.length > 0)) {
      this.barChartSpec['data']['values'] = this.cwm.data_values;
      try {
        google.charts.load('visualization', { 'packages': ['vegachart'] });
        const self: any = this;
        google.setOnLoadCallback(function () {
          const dataTable = new google.visualization.DataTable();
          const chart = new google.visualization.VegaChart(self.barChartElement.element.nativeElement);
          chart.draw(dataTable, { 'vegaLite': self.barChartSpec });
        });
      } catch (_) { }
    } else {
      this.chartStatus = 'No data available.';
    }
  }
}
