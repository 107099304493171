import * as app from './action';
import * as _ from 'underscore';

export class CurrentPersona {
    clientName: string;
    acadYearFrom: Date;
    acadYearTo: Date;
    id: string;
    clientShortName: string;
    clientId: string;
    title: string;
}

// export class TopicAction {
//     constructor(public id: number, public displayText: string, public methodName: string) {
//     }
// }

// export class Topic {
//     id: string;
//     name: string;
//     status: number;
//     statusAsString: string;
//     description: string = "";
//     addDescription: false;
//     addFriendlyName: false;
//     friendlyName: string = "";
//     category: string = "Open";
//     role: string = "Admin";
//     actions: TopicAction[] = [];
//     get allowedActions(): TopicAction[] {
//         let result = [];
//         switch (this.status) {
//             case 1: //active
//                 result = [];
//                 result.push(this.actions[1]);
//                 break;
//             case 99: //disabled
//                 result = [];
//                 result.push(this.actions[0]);
//                 break;
//             default:
//                 result = [];
//         }
//         return result;
//     }
//     constructor() {
//         this.actions.push(new TopicAction(1, "Enable", "ut_enableTopic"));
//         this.actions.push(new TopicAction(2, "Disable", "ut_disableTopic"));
//     }
// }

export class TFTD {
    id: string;
    quote: string;
    by: string;
}

export class SmsTemplate {
    id: string;
    description: string;
}

export class NewSMSTemplate {
    message: string;
    credits: number;
    id: string;
    length: number;
    statusAsString: string;
    status: number;
    reason: string;
}

export class Receipients {
    type: string;
    details: string;
}

export class Facets {
    name: string;
    options: FacetOptions[] = [];
}

export class FacetOptions {
    value: string;
}

export class Person {
    id: string;
    name: string;
    personType: string;
    studentName: string;
    studentClass: string;
    designation: string;
    emailBlocked: boolean = false;
    editEmail: boolean = false;
    editMobile: boolean = false;
    email: string;
    mobileBlocked: boolean = false;
    mobile: string;
}

export class Workflow {
    id: string;
    jobs: Job[] = [];
    title: string;
}
export class Job {
    id: string;
    payload: any;
    status: number;
    statusAsString: string;
    title: string;
}

export class SMS {
    smsId: string;
    date: any;
    createdOn: any;
    message: string;
    smsCreditsPerMessage: number;
    recipientCount: number;
    smsCreditsUsed: number;
    deliveryStatus: any;
}

export class Email {
    emailId: string;
    messageType: string;
    manualRecipients: string[] = [];
    subject: string;
    message: string;
    attachments: any;
    attachmentNames: any;
    enableAttachment: boolean = false;
    date: Date;
    recipientCount: number;
    emailCreditsUsed: string;
    status: any;
    deliveryStatus: any;
}

export interface State {
    userLinks: any[];
    html: any;
    sms: SMS[];
    email: Email[];
    studentFacet: any[];
    studentFacetEmail: any[];
    approvedTemplates: any[];
    smsRecipients: any;
    parentRecipients: any[];
    staffFacet: any[];
    emailRecipients: any[];
    manualRecipients: any[];
    emailType: any;
    emailFrom: any;
    emailSubject: any;
    emailSMS: any;
    emailBody: any;
    emailTo: any;
    emailAttachment: any;
    smsTotalPages: any;
    emailTotalPages: any;
    workflow: any;
    smsTemplates: NewSMSTemplate[];
    smsDeliveryStatus: any[];
    emailDeliveryStatus: any[];
    smsDeliveryData: any[];
    smsDlrChartData: any;
    emailDeliveryData: any[];
    selectedEmail: any;
    smsBalance: any;
    emailBalance: any;
    monthYear: string;
    connectInfo: any;
    // topicList: any[];
}

const initialState: State = {
    userLinks: null,
    html: null,
    sms: [],
    email: [],
    studentFacet: null,
    studentFacetEmail: null,
    approvedTemplates: null,
    smsRecipients: {},
    parentRecipients: null,
    staffFacet: null,
    emailRecipients: null,
    manualRecipients: null,
    emailType: null,
    emailFrom: null,
    emailSubject: null,
    emailSMS: null,
    emailBody: null,
    emailTo: null,
    emailAttachment: null,
    smsTotalPages: null,
    emailTotalPages: null,
    workflow: null,
    smsTemplates: [],
    smsDeliveryStatus: [],
    emailDeliveryStatus: [],
    smsDeliveryData: [],
    smsDlrChartData: null,
    emailDeliveryData: [],
    selectedEmail: null,
    smsBalance: null,
    emailBalance: null,
    monthYear: null,
    connectInfo: null
    // topicList: null
};

function setApprovedMsgs(appMsg) {
    var smsTemplates = [];
    appMsg.forEach((v, k) => {
        var template = {};
        template['id'] = v.id;
        template['description'] = v.message;
        smsTemplates.push(template);
    });
    return smsTemplates;
};

function disableSMSTemplate(smsTemplate_list, arte_id): NewSMSTemplate[] {
    const smsTemplateList: NewSMSTemplate[] = smsTemplate_list.map((item) => {
        if (item.id == arte_id) {
            item.status = 99;
            item.statusAsString = "Disabled";
            return item;
        }
        return item;
    });
    return smsTemplateList;
}

function enableSMSTemplate(smsTemplate_list, arte_id): NewSMSTemplate[] {
    const smsTemplateList: NewSMSTemplate[] = smsTemplate_list.map((item) => {
        if (item.id == arte_id) {
            item.status = 1;
            item.statusAsString = "Enabled";
            return item;
        }
        return item;
    });
    return smsTemplateList;
}

function approveSMSTemplate(smsTemplate_list, arte_id): NewSMSTemplate[] {
    const smsTemplateList: NewSMSTemplate[] = smsTemplate_list.map((item) => {
        if (item.id == arte_id) {
            item.status = 0;
            item.statusAsString = "Approved";
            return item;
        }
        return item;
    });
    return smsTemplateList;
}

function disapproveSMSTemplate(smsTemplate_list, arte_id): NewSMSTemplate[] {
    const smsTemplateList: NewSMSTemplate[] = smsTemplate_list.map((item) => {
        if (item.id == arte_id) {
            item.status = 90;
            item.statusAsString = "Disapproved";
            return item;
        }
        return item;
    });
    return smsTemplateList;
}

function getSMSTemplateArray(dataFromServer) {
    var result = [];
    if (dataFromServer) {
        dataFromServer.forEach(d => {
            let smsTemplate = new NewSMSTemplate();
            smsTemplate.id = d.id;
            smsTemplate.credits = d.credits;
            smsTemplate.length = d.length;
            smsTemplate.message = d.message;
            smsTemplate.status = d.status;
            smsTemplate.statusAsString = d.statusAsString;
            smsTemplate.reason = d.reason;
            result.push(smsTemplate);
        });
    }
    return result;
}

function setSMSArray(dataFromServer) {
    let result = [];
    if (dataFromServer) {
        let sms_arr = dataFromServer.smsList;
        sms_arr.forEach(o => {
            let sms = Object.assign(new SMS(), o, {
                deliveryStatus: o.deliveryStatus || {
                    undelivered: "NA",
                    delivered: "NA",
                }
            });
            result.push(sms);
        });
    }
    return result;
}

// function disableTopic(topics, id) {
//     var topicList: Topic[] = topics.map((item) => {
//         if (item.id == id) item.status = 99; //99
//         return item;
//     });
//     return topicList;
// }
// function enableTopic(topics, id) {
//     var topicList: Topic[] = topics.map((item) => {
//         if (item.id == id) item.status = 1; //1
//         return item;
//     });
//     return topicList;
// }

function setEmailArray(dataFromServer) {
    let result = [];
    if (dataFromServer) {
        let email_arr = dataFromServer.emailList;
        email_arr.forEach(o => {
            let email = Object.assign(new Email(), o, {
                deliveryStatus: o.deliveryStatus || {
                    undelivered: "NA",
                    delivered: "NA",
                }
            });
            result.push(email);
        });
    }
    return result;
}

// function setTopicList(dataFromServer) {
//     let result = [];
//     if (dataFromServer) {
//         dataFromServer.forEach(o => {
//             let topic = new Topic();
//             topic.id = o.id;
//             topic.status = o.status;
//             topic.statusAsString = o.statusAsString;
//             topic.name = o.name;
//             topic.description = o.description;
//             topic.friendlyName = o.friendlyName;
//             topic.category = o.category;
//             topic.role = o.role;
//             result.push(topic);
//         });
//     }
//     return result;
// }

export function reducer(state = initialState, action: app.Actions): State {
    switch (action.type) {
        case app.ActionTypes.SET_SELECTED_USER_LINK:
            return Object.assign({}, state, { selectedUserLink: action.payload.userLink });
        case app.ActionTypes.SET_USER_LINKS:
            return Object.assign({}, state, { userLinks: action.payload.user_links });
        case app.ActionTypes.UPDATE_DASHBOARD:
            return Object.assign({}, state, { userLinks: action.payload.userLinks });
        case app.ActionTypes.SET_HTML:
            return Object.assign({}, state, { html: action.payload });
        case app.ActionTypes.SET_SMS:
            return Object.assign({}, state, { sms: setSMSArray(action.payload) });
        case app.ActionTypes.SET_EMAIL:
            return Object.assign({}, state, { email: setEmailArray(action.payload) });
        case app.ActionTypes.SET_STUDENT_FACET:
            return Object.assign({}, state, { studentFacet: action.payload.studentFacet });
        case app.ActionTypes.SET_STUDENT_FACET_EMAIL:
            return Object.assign({}, state, { studentFacetEmail: action.payload.studentFacetEmail });
        case app.ActionTypes.SET_APPROVED_TEMPLATES:
            return Object.assign({}, state, { approvedTemplates: setApprovedMsgs(action.payload.approvedTemplates) });
        case app.ActionTypes.SET_SMS_RECIPIENTS:
            return Object.assign({}, state, { smsRecipients: action.payload.smsRecipients });
        case app.ActionTypes.SET_PARENT_RECIPIENTS:
            return Object.assign({}, state, { parentRecipients: action.payload.parentRecipients });
        case app.ActionTypes.SET_STAFF_FACET:
            return Object.assign({}, state, { staffFacet: action.payload.staffFacet });
        case app.ActionTypes.SET_EMAIL_RECIPIENTS:
            return Object.assign({}, state, { emailRecipients: action.payload.emailRecipients });
        case app.ActionTypes.SET_EMAIL_MANUAL_RECIPIENTS:
            return Object.assign({}, state, { manualRecipients: action.payload.manualRecipients });
        case app.ActionTypes.SET_EMAIL_TYPE:
            return Object.assign({}, state, { emailType: action.payload.emailType });
        case app.ActionTypes.SET_EMAIL_FROM:
            return Object.assign({}, state, { emailFrom: action.payload.emailFrom });
        case app.ActionTypes.SET_EMAIL_SUBJECT:
            return Object.assign({}, state, { emailSubject: action.payload.emailSubject });
        case app.ActionTypes.SET_EMAIL_SMS:
            return Object.assign({}, state, { emailSMS: action.payload.emailSMS });
        case app.ActionTypes.SET_EMAIL_BODY:
            return Object.assign({}, state, { emailBody: action.payload.emailBody });
        case app.ActionTypes.SET_EMAIL_TO:
            return Object.assign({}, state, { emailTo: action.payload.emailTo });
        case app.ActionTypes.SET_EMAIL_ATTACHMENT:
            return Object.assign({}, state, { emailAttachment: action.payload.emailAttachment });
        case app.ActionTypes.SET_SMS_TOTAL_PAGES:
            return Object.assign({}, state, { smsTotalPages: action.payload.smsTotalPages });
        case app.ActionTypes.SET_EMAIL_TOTAL_PAGES:
            return Object.assign({}, state, { emailTotalPages: action.payload.emailTotalPages });
        case app.ActionTypes.SET_WORK_FLOW:
            return Object.assign({}, state, { workflow: action.payload.workflow });
        case app.ActionTypes.DISABLE_SMS_TEMPLATE:
            return Object.assign({}, state, { smsTemplates: disableSMSTemplate(state.smsTemplates, action.payload.smsTemplate_id) });
        case app.ActionTypes.ENABLE_SMS_TEMPLATE:
            return Object.assign({}, state, { smsTemplates: enableSMSTemplate(state.smsTemplates, action.payload.smsTemplate_id) });
        case app.ActionTypes.APPROVE_SMS_TEMPLATE:
            return Object.assign({}, state, { smsTemplates: approveSMSTemplate(state.smsTemplates, action.payload.smsTemplate_id) });
        case app.ActionTypes.DISAPPROVE_SMS_TEMPLATE:
            return Object.assign({}, state, { smsTemplates: disapproveSMSTemplate(state.smsTemplates, action.payload.smsTemplate_id) });
        case app.ActionTypes.SET_SMS_TEMPLATES:
            return Object.assign({}, state, { smsTemplates: getSMSTemplateArray(action.payload.smsTemplates) });
        case app.ActionTypes.SET_SMS_DELIVERY_STATUS:
            return Object.assign({}, state, { smsDeliveryStatus: action.payload.smsDeliveryStatus });
        case app.ActionTypes.SET_SMS_DELIVERY_DATA:
            return Object.assign({}, state, { smsDeliveryData: action.payload.smsDeliveryData });
        case app.ActionTypes.SET_SMS_DLR_CHART_DATA:
            return Object.assign({}, state, { smsDlrChartData: action.payload.smsDlrChartData });
        case app.ActionTypes.SET_EMAIL_DELIVERY_DATA:
            return Object.assign({}, state, { emailDeliveryData: action.payload.emailDeliveryData });
        case app.ActionTypes.SET_SELECTED_EMAIL:
            return Object.assign({}, state, { selectedEmail: action.payload.selectedEmail });
        case app.ActionTypes.SET_SMS_BALANCE:
            return Object.assign({}, state, { smsBalance: action.payload.smsBalance });
        case app.ActionTypes.SET_EMAIL_BALANCE:
            return Object.assign({}, state, { emailBalance: action.payload.emailBalance });
        case app.ActionTypes.SELECTED_MONTH_YEAR:
            return Object.assign({}, state, { monthYear: action.payload });
        case app.ActionTypes.SET_CONNECT_INFO:
            return Object.assign({}, state, { connectInfo: action.payload });
        // case app.ActionTypes.SET_TOPICS:
        //     return Object.assign({}, state, { topicList: setTopicList(action.payload.topicList) });
        //     case app.ActionTypes.DISABLE_TOPIC:
        //         return Object.assign({}, state, { topicList: disableTopic(state.topicList, action.payload.id) });
        //     case app.ActionTypes.ENABLE_TOPIC:
        //         return Object.assign({}, state, { topicList: enableTopic(state.topicList, action.payload.id) });    
        default:
            return state;
    }
}

export const get_state_connect = (state: State) => state;
export const get_html_connect = (state: State) => state.html;
export const get_sms = (state: State) => state.sms;
export const get_email = (state: State) => state.email;
export const get_student_facet = (state: State) => state.studentFacet;
export const get_student_facet_email = (state: State) => state.studentFacetEmail;
export const get_approved_templates = (state: State) => state.approvedTemplates;
export const get_sms_recipients = (state: State) => state.smsRecipients;
export const get_parent_recipients = (state: State) => state.parentRecipients;
export const get_staff_facet = (state: State) => state.staffFacet;
export const get_email_recipients = (state: State) => state.emailRecipients;
export const get_email_manual_recipients = (state: State) => state.manualRecipients;
export const get_email_type = (state: State) => state.emailType;
export const get_email_from = (state: State) => state.emailFrom;
export const get_email_subject = (state: State) => state.emailSubject;
export const get_email_sms = (state: State) => state.emailSMS;
export const get_email_body = (state: State) => state.emailBody;
export const get_email_to = (state: State) => state.emailTo;
export const get_email_attachment = (state: State) => state.emailAttachment;
export const get_sms_total_pages = (state: State) => state.smsTotalPages;
export const get_email_total_pages = (state: State) => state.emailTotalPages;
export const get_email_work_flow = (state: State) => state.workflow;
export const get_smsTemplates = (state: State) => state.smsTemplates;
export const get_sms_delivery_status = (state: State) => state.smsDeliveryStatus;
export const get_email_delivery_status = (state: State) => state.emailDeliveryStatus;
export const get_sms_delivery_data = (state: State) => state.smsDeliveryData;
export const get_email_delivery_data = (state: State) => state.emailDeliveryData;
export const get_selected_email = (state: State) => state.selectedEmail;
export const get_sms_balance = (state: State) => state.smsBalance;
export const get_email_balance = (state: State) => state.emailBalance;
export const get_sms_delivery_chart_data = (state: State) => state.smsDlrChartData;
export const get_selected_month_year = (state: State) => state.monthYear;
export const get_connect_info = (state: State) => state.connectInfo;
// export const get_topic_list = (state: State) => state.topicList;