import { Action } from '@ngrx/store';
import { type } from '../../../app/util';

export const ActionTypes = {
    SET_INSTS: type('[Inst] Set Inst'),
    SET_SELECTED_INST: type('[Inst] Set Selected Inst'),
    SET_SMS_TX: type('[Inst] Set SMS Tx'),
    INSTITUTE_LOCKED: type('[Inst] Institute Locked'),
    INSTITUTE_UNLOCKED: type('[Inst] Institute Unlocked'),
    SET_WF_DEF: type('[Inst] Set Workflow Definitions'),
    SET_WF_EXEC: type('[Inst] Set Workflow Executions'),
    SET_FILES: type('[Inst] Set Files'),
}

export class SetInsts implements Action {
    type = ActionTypes.SET_INSTS;
    constructor(public payload: any) {
    }
}

export class SetSelectedInst implements Action {
    type = ActionTypes.SET_SELECTED_INST;
    constructor(public payload: any) {
    }
}

export class SetSMSTx implements Action {
    type = ActionTypes.SET_SMS_TX;
    constructor(public payload: any) {
    }
}

export class InstituteLocked implements Action {
    type = ActionTypes.INSTITUTE_LOCKED;
    constructor(public payload: any) {
    }
}

export class InstituteUnlocked implements Action {
    type = ActionTypes.INSTITUTE_UNLOCKED;
    constructor(public payload: any) {
    }
}

export class SetWfDef implements Action {
    type = ActionTypes.SET_WF_DEF;
    constructor(public payload: any) {
    }
}

export class SetWfExec implements Action {
    type = ActionTypes.SET_WF_EXEC;
    constructor(public payload: any) {
    }
}

export class SetFiles implements Action {
    type = ActionTypes.SET_FILES;
    constructor(public payload: any) {
    }
}

export type Actions
    = SetInsts | SetSelectedInst | SetSMSTx | InstituteLocked | InstituteUnlocked | SetWfDef | SetWfExec | SetFiles;
