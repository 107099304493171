import { Injectable } from '@angular/core';
import * as introJs from 'intro.js/intro.js';
import { INTRO_IDS, INTRO_OPTIONS } from './constants';
import { Store } from '@ngrx/store';
import { State } from '../rootReducer';
import { SetIntroDone } from '../store/intro-action';
import { AppService } from './app.service';

/**
 * Rules:
 * Prefix all the intro element ids and class names with `intro-`
 * Don't apply any Style to these ids or classes to prevent any conflict in maintainance and updation
 */

@Injectable({
  providedIn: 'root'
})
export class IntroJsService {
  intros: { [a: string]: any } = {};
  userId: string = "";

  private intro: introJs.IntroJs;
  private previousEvent: any;
  private runableIntro = { introId: "" };

  constructor(private store: Store<State>, private appService: AppService) {
    this.intro = new introJs();
  }

  initIntros(intros: { [a: string]: any }) {
    this.intros = intros;
    for (const key in intros) {
      if (Object.prototype.hasOwnProperty.call(intros, key)) {
        if (!intros[key]) {
          // this.runIntro(key, intro);
          this.runableIntro.introId = key;
        }
      }
    }
  }
  startIntro() {
    this.runIntro(this.runableIntro.introId);
  }
  startIntroById(introId: INTRO_IDS) {
    this.runIntro(introId);
  }
  private runIntro(introId: string) {
    switch (introId) {
      case INTRO_IDS.HEADER:
        let steps = [];
        INTRO_OPTIONS.HEADER.steps.forEach(step => {
          if (step && step.element) {
            let str: any = step.element;
            let element = document.querySelector(str);
            if (element && element.innerHTML != "") {
              steps.push(step);
            }
          }
        });
        INTRO_OPTIONS.HEADER.steps = steps
        this.intro.setOptions(INTRO_OPTIONS.HEADER);
        this.intro.onexit(() => {
          document.body.style.overflow = ''; // to enable scrolling
          this.store.dispatch(new SetIntroDone(introId));
          this.appService.debouncerWithKey('userIntro', () => {
            this.appService.updateUserIntros({ Id: this.userId, Intros: this.intros }).subscribe();
          }, 3000);
          this.runableIntro = { introId: "" };
        });

        // this.intro.onbeforechange((e) => {
        //   if (e && e.classList.contains("intro-header-um")) {
        //     console.log("clicking...");
        //     setTimeout(() => {
        //     e.click();
        //       console.log("clicked");
        //     }, 1000);
        //     this.previousEvent = e;
        //   }
        // })
        // WIP
        // this.intro.onbeforechange((e) => {
        //   if (e && e.classList.contains("intro-header-ui")) {
        //     e.click();
        //     this.previousEvent = e;
        //   }
        // })

        this.intro.onbeforechange((e) => {
          // if (e.innerHTML == "") {
          //   this.intro.nextStep();
          // }
        })
        this.intro.onchange((e) => {
        });
        this.intro.onafterchange((e) => {
        })
        document.body.style.overflow = 'hidden'; // to prevent scrolling
        this.intro.start();
        break;

      default:
        break;
    }
  }

  private cardIntro() {
    this.intro.setOptions({
      steps: [
        {
          element: '.summary-card',
          intro: 'This is summary card',
        },
        {
          element: '.card-title',
          intro: 'Card Title',
        },
        {
          element: '.card-summary',
          intro: 'Card Summary',
        },
        {
          element: '.view-more',
          intro: 'View more details',
        }
      ]
    });
    // this.intro.start();
  }
}
