import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import * as R from 'ramda';
import { environment } from "../../../../environments/environment";
import { ChartWidgetModel } from './models';
import { Store } from "@ngrx/store";
import * as actions from '../../../widgets/store/action';
import * as rootReducer from '../../../../app/rootReducer';

@Injectable()
export class ApiService {
    constructor(private httpClient: HttpClient, private store: Store<rootReducer.State>) { }

    getApiContent(payload: any) {
        let endPoint = R.pathOr("", ['data', 'api', 'end_point'], payload);
        if (endPoint) {
            if (endPoint[0] != "/") {
                endPoint = "/" + endPoint;
            }
        }

        if (R.pathOr("", ['data', 'api', 'verb'], payload) == "POST") {
            return this.httpClient.post(environment.API_URL + endPoint, payload.data.postdata);
        } else {
            return this.httpClient.get(environment.API_URL + endPoint);
        }
    }

    getAssetContent(payload: any) {
        return this.httpClient.get("../../../../" + R.pathOr("", ['data', 'test_file'], payload));
    }
    refreshWidgetData(model: ChartWidgetModel) {
        this.store.select(rootReducer.get_chart_dashboard_specs).subscribe(res => {
            if (res) {
                if (R.path([model.dashboardId], res)) {
                    for (let index = 0; index < res[model.dashboardId].widgets.length; index++) {
                        const widget = res[model.dashboardId].widgets[index];
                        if (widget.id == model.widgetId) {
                            if (R.path(['data', 'isSampleData'], widget)) {
                                if (R.path(['data', 'test_file'], widget)) {
                                    this.store.dispatch(new actions.LoadAssetsData({ dashboardId: model.dashboardId, widgetId: widget.id, data: widget.data }));
                                }
                            } else {
                                if (R.path(['data', 'api', 'end_point'], widget)) {
                                    this.store.dispatch(new actions.LoadEndpointData({ dashboardId: model.dashboardId, widgetId: widget.id, data: widget.data }));
                                }
                            }
                        }
                    }
                }
            }
        })
    }

    handleError(error: HttpErrorResponse) {
        if (error.status === 0) {
            // console.log('An error occurred:', error.error);
        } else {
            // console.log(`Backend returned code ${error.status}, body was: `, error.error);
        }
        return throwError('Something bad happened; please try again later.');
    }
}