import { Action } from '@ngrx/store';
import { type } from '../../util';

export const ActionTypes = {
    SET_EXAMS: type('[Exam] Set Exam'),
    SET_SELECTED_EXAM: type('[Exam] Set Selected Exam'),
    SET_EVAL_STUDENTS: type('[Exam] Set Evaluation Students'),
    SET_GRADING: type('[Exam] Set Grading'),
};

export class SetExams implements Action {
    type = ActionTypes.SET_EXAMS;
    constructor(public payload: any) { }
}

export class SetSelectedExam implements Action {
    type = ActionTypes.SET_SELECTED_EXAM;
    constructor(public payload: any) { }
}

export class SetEvalStudents implements Action {
    type = ActionTypes.SET_EVAL_STUDENTS;
    constructor(public payload: any) { }
}
export class SetGrading implements Action {
    type = ActionTypes.SET_GRADING;
    constructor(public payload: any) { }
}

export type Actions = SetExams | SetSelectedExam | SetEvalStudents | SetGrading;
