import { Component, ElementRef, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DashPanelModel } from '../../chart-dashboard/service/models';

@Component({
  selector: 'dash-panel',
  templateUrl: './dash-panel.component.html',
  styleUrls: ['./dash-panel.component.css']
})
export class DashPanelComponent implements OnInit {
  @Input() public dpm: DashPanelModel;
  chartStatus: any;

  @ViewChild('dashpanelpos', { read: ViewContainerRef }) dashPanelPosition?: ElementRef;

  constructor() { }

  ngOnInit() { }

}
