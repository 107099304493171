import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import * as rootReducer from '../../rootReducer';
import { Router } from '@angular/router';
import { AcademicsSetupService as service } from './service/service';

class Course {
    name: string;
    branch: string;
    sections: string[];
}

class Exam {
    name: string;
    start_date: string;
    courses: string[];
}

class AcadData {
    exams: Exam[] = [];
    courses: Course[] = [];
}

class Model {
    acadData: AcadData;
}
@Component({
    selector: 'acad-setup',
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
    providers: [service]
})

export class AcadSetupComponent {
    public m: Model;
    @Input() pin: boolean;
    constructor(private router: Router, private service: service, private store: Store<rootReducer.State>) { }
    ngOnInit() {
        this.init_model();
        this.init_store();
    }
    init_model() {
        this.m = new Model();
    }

    init_store() {
        this.store.select(rootReducer.get_academics_setup_widget).subscribe((res: any) => {
            if (res) {
                this.m.acadData = res;
            }
        });
    }

    ut_routeToCourseAdd() {
        this.router.navigate(['/course/add']);
    }
    ut_routeToCourseManage() {
        this.router.navigate(['/admin/course/manage']);
    }
    ut_routeToExamAdd() {
        this.router.navigate(['/academics/exam']);
    }

    pinChart() {
        this.pin = false;
        let payload = ["academics_setup"];
		this.service.pinWidget(payload).subscribe(res => {
			if(res) this.service.getDashboardWidgets();
		});
     
    }
    unpinChart() {
        this.pin = true;
        let payload = ["academics_setup"];
		this.service.unpinWidget(payload).subscribe(res => {
			if(res) this.service.getDashboardWidgets();
		});
    }
}