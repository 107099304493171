import { Component, OnInit, ElementRef, EventEmitter, Output, Input, SimpleChanges } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { Store } from '@ngrx/store';
import * as rootReducer from '../../rootReducer';

import { Subscription, Observable } from 'rxjs';
import { TagService as service } from './service/service';

import { Http } from '@angular/http';
import * as _ from 'underscore';

class Model {
    searchText:string = "";
    addTag:boolean = false;
    newTag:string = "";
}

@Component({
    selector: 'tag',
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
    providers: [service]
})

export class TagComponent implements OnInit {
    public m: Model;
    modalRef: BsModalRef;
    subs = new Subscription();
    @Input() tags: any;
    @Input() selectedRows: any[];
    @Output() updatedRowData = new EventEmitter();
    @Output() updatedTags = new EventEmitter();
    constructor(
        private store: Store<rootReducer.State>, private el: ElementRef, private service: service, private http: Http, private modalService: BsModalService) { }
    ngOnInit() {
        this.init_model();
        this.sub_store();
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.refreshTags(changes.selectedRows.currentValue);
    }

    init_model() {
        this.m = new Model();
    }

    sub_store() {

    }

    refreshTags(selRows){
        if(this.tags.list.length>0 && selRows.length>0){
            var somePresent = false;
            var allPresent = false;
            this.tags.list.forEach(tag => {
                tag.applied = false;
                tag.indeterminate = false;
                somePresent = _.some(selRows, function(row) { return row.Tags.includes(tag.name) });
                if(somePresent){
                    tag.indeterminate = true;
                    allPresent = _.every(selRows, function(row) {  return row.Tags.includes(tag.name) });
                    if(allPresent){  
                        tag.indeterminate = false;
                        tag.applied = true;
                    }
                }
                else{ tag.applied = false; }
            });
        }
    }

    ut_updateTag(tag){
        if(tag.applied){
            this.ut_applyTag(tag);
        }
        else if(!tag.applied){
            this.ut_removeTag(tag);
        }
    }

    ut_applyTag(tag) {
        var selectedData = this.selectedRows;
        selectedData.forEach(e => {
            if(e.Tags){
                var tagIncluded = e.Tags.includes(tag.name);
                if(!tagIncluded){ e.Tags = e.Tags + "," + tag.name; }
            }
            else{ e.Tags = tag.name; }
        });
        this.emitUpdatedData(selectedData);
    }

    ut_removeTag(tag) {
        var selectedData = this.selectedRows;
        selectedData.forEach(e => {
            if(e.Tags){
                var tagIncluded = e.Tags.includes(tag.name);
                if(tagIncluded){ 
                    var tagArr = e.Tags.split(",");
                    tagArr = tagArr.filter(tg => tg != tag.name);
                    e.Tags = tagArr.toString();
                }
            }
        });
        this.emitUpdatedData(selectedData);
    }
    emitUpdatedData(value) {
        this.updatedRowData.emit(value);
    }
    emitUpdatedTags(value) {
        this.updatedTags.emit(value);
    }
    openAddTag(ev) {
        ev.stopPropagation();
        this.m.addTag = true;
    }
    cancelAddTag(ev) {
        ev.stopPropagation();
        this.m.addTag = false;
        this.m.newTag = "";
    }
    addTag(ev) {
        ev.stopPropagation();
        var newTag = {name:this.m.newTag, applied:false, indeterminate:false};
        this.tags.list.unshift(newTag);
        this.m.newTag = "";
        this.m.addTag = false;
        this.emitUpdatedTags(this.tags);
    }
    hideTag(ev,tag,indx) {
        ev.stopPropagation();
        this.ut_removeTag(tag)
        this.tags.list.splice(indx, 1);
        this.emitUpdatedTags(this.tags);
    }
    omit_special_char(event){   
        var k;  
        k = event.charCode; 
        return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 58)); 
    }
}