import { Pipe, PipeTransform } from "@angular/core";

@Pipe({name: 'minToHm'})
export class MinToHmPipe implements PipeTransform {
  transform(value: number): string {
		var h = Math.floor(value/60);
		var m = value%60;
		var result = h + "h " + m + "m ";
		return result;
	}
}