import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs/Subscription';

import * as rootReducer from '../../rootReducer';

class Model {
}

@Component({
    selector: 'sms-balance',
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
})

export class SMSBalanceComponent {
    html: string = `<span style="color: gray">SMS balance count service is being updated. You can still send SMS or add SMS credits.</span>`;
    public m: Model;
    private sub1: Subscription;
    private sub2: Subscription;
    @Input() balance: any;
    constructor(private store: Store<rootReducer.State>) { }
}