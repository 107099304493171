import * as app from './action';
import * as _ from 'underscore';

export class SelectedPersona {
  title: string;
  status: number;
  id: string;
}

export class Persona {
  id: string;
  title: string;
  status: number;
}

export interface State {
  // personas: Persona[];
  personas: any;
  selected_persona: any;
  // selected_persona: SelectedPersona;
}
//state
const initialState: State = {
  personas: [],
  selected_persona: {},
  // selected_persona: new SelectedPersona()
};

function clientwise_personas(personas): any {
  personas.forEach(per => {
    if (!per.isInstituteLocked) {
      var uniqClient = per.clientName;
      if (per.acadYearFrom && per.acadYearTo) {
        uniqClient = uniqClient + ' ' + new Date(per.acadYearFrom).getFullYear() + '-' + new Date(per.acadYearTo).getFullYear().toString().substr(2, 2);
      }
      per.UniqClientName = uniqClient;
    }
  });
  var groupedPersonasArr = [];
  var groupedPersonas = _.groupBy(personas, function (r) { return r.UniqClientName; });
  _.each(groupedPersonas, function (v, k) {
    var instObj = { name: "", shortName: "", value: "" };
    instObj.name = k;
    instObj.value = v;
    instObj.shortName = v[0].clientShortName;
    if (instObj.name != 'undefined') groupedPersonasArr.push(instObj);
  });
  return groupedPersonasArr;
}

//commands
export function reducer(state = initialState, action: app.Actions): State {
  switch (action.type) {
    case app.ActionTypes.SET_PERSONAS:
      return Object.assign({}, state, { personas: clientwise_personas(action.payload.personas) });
    case app.ActionTypes.SET_SELECTED_PERSONA:
      return Object.assign({}, state, { selected_persona: action.payload });
    default:
      return state;
  }
}

//queries
export const get_personas = (state: State) => state.personas;
export const get_selected_persona = (state: State) => state.selected_persona;