import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as feesAction from '../../../fees/store/action';
import { CTApi } from '../../../../app/service/ct-api';
import * as rootReducer from '../../../../app/rootReducer';

@Injectable()
export class NavTreeService {

  constructor(public ctapi: CTApi, private store: Store<rootReducer.State>) { }
  
  getAllStudents(qry) {
    return this.ctapi.getAllStudents(qry).subscribe(res => {
        this.store.dispatch(new feesAction.SetStudents({ "student_list": res }))
    });
}
}