import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { State, get_scheduled_publishes } from '../../rootReducer';
import { PulseService } from '../../service/pulse';

@Component({
  selector: 'app-scheduled-publishes',
  templateUrl: './scheduled-publishes.component.html',
  styleUrls: ['./scheduled-publishes.component.css']
})
export class ScheduledPublishesComponent implements OnInit, OnDestroy {
  private readonly sub: Subscription = new Subscription();
  list: Array<{

  }> = Array()

  constructor(private store: Store<State>, private pulseService: PulseService) { }

  ngOnInit() {
    this.pulseService.getScheduledPublishes();

    this.sub.add(this.store.select(get_scheduled_publishes).subscribe((sps) => {
      let list = Array();
      sps.forEach((sp) => {
        list.push({
          id: sp.id,
          nextDate: new Date(sp.next_run).toLocaleString(),
          action: sp.message.metadata.action,
          topic: sp.message.topic,
          isComplete: new Date(sp.next_run) < new Date(),
          isPaused: sp.status === 3,
          next_run: sp.next_run,
        })
      });
      // sort by nextDate recent first
      list.sort((a: any, b: any) => (+new Date(a.next_run)) - (+new Date(b.next_run)));
      this.list = list;
    }));
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  ut_pause(item) {
    console.log(item);
    this.pulseService.updateScheduledPublish({ id: item.id, status: 3 });
  }
  ut_resume(item) {
    console.log(item);
    this.pulseService.updateScheduledPublish({ id: item.id, status: 1 });
  }
}
