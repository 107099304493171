import * as app from './action';

export class Course {
    id: string;
    name: string;
    branch: string;
    eduLevel: number;
    eduLevelUI: string;
    affiliation: string;
    status: number;
    examSubOrderString: string;
    terms: Term[] = [];
    eduLevelAsString: string;
    rank: number;
    editCourseRank: boolean = false;
    seatsAvailable: number = 0;
    program: string;
}

export class Term {
    from: Date;
    to: Date;
    name: string;
    status: number;
    sections: Section[] = [];
}

export class Section {
    name: string;
    status: number;
    subjects: Subject[] = [];
}

export class Subject {
    name: string;
    alias: string;
    status: number;
}

export class CourseContext {
    constructor(public selectedCourseId: string, public selectedCourseName: string) {
    }
}

export class CurrentPersona {
    clientName: string;
    acadYearFrom: Date;
    acadYearTo: Date;
    title: string;
}

export interface State {
    currentPersona: CurrentPersona;
    courses: Course[];
    selectedCourse: Course;
    selectedTerm: string;
    selectedSection: string;
    selectedSectionTT : any;
    selectedSubject: string;
    course_context: CourseContext;
    courseSearchTerm: string;
    queryString: string;
}

const initialState: State = {
    currentPersona: null,
    courses: null,
    selectedCourse: null,
    course_context: new CourseContext("", ""),
    selectedTerm: null,
    selectedSection: null,
    selectedSectionTT : null,
    selectedSubject: null,
    courseSearchTerm: null,
    queryString: null
};

function setCourseArray(dataFromServer): Course[] {
    let result = [];
    dataFromServer.data.forEach(o => {
        let course = new Course();
        course.id = o.id;
        course.name = o.name;
        course.branch = o.branch;
        course.eduLevel = o.eduLevel;
        course.eduLevelUI = o.eduLevelUI;
        course.affiliation = o.affiliation;
        course.status = o.status;
        course.terms = o.terms;
        course.examSubOrderString = "";
        course.eduLevelAsString = o.eduLevelAsString;
        course.rank = o.rank;
        course.program = o.program;
        if (o.examSubjectOrder && o.examSubjectOrder.length > 0) {
            course.examSubOrderString = "";
            o.examSubjectOrder.forEach(sub => {
                course.examSubOrderString = course.examSubOrderString + sub + "\n";
            });
        }
        result.push(course);
    });
    return result;
}

function selectCourse(course_list, course_id): Course {
    var selectedCourse: Course = course_list.find(course => course.id == course_id);
    return selectedCourse;
}



function enableCourse(course_list, course_id): Course[] {
    const courseList: Course[] = course_list.map((item) => {
        if (item.id == course_id) {
            item.status = 1;
            return item;
        }
        return item;
    });
    return courseList;
}

function disableCourse(course_list, course_id): Course[] {
    const courseList: Course[] = course_list.map((item) => {
        if (item.id == course_id) {
            item.status = 99;
            return item;
        }
        return item;
    });
    return courseList;
}

function removeTerm(course_list, term_name, course_name): Course[] {
    const courseList: Course[] = course_list.map((c) => {
        if (c.name == course_name) {
            c.terms.forEach(t => {
                if (t.name == term_name)
                    t.status = 99
            });
            return c;
        }
        return c;
    });
    return courseList;
}

function removeSection(course_list, term_name, course_name, sec_name): Course[] {
    const courseList: Course[] = course_list.map((c) => {
        if (c.name == course_name) {
            c.terms.forEach(t => {
                if (t.name == term_name)
                    t.sections.forEach(s => {
                        if (s.name == sec_name)
                            s.status = 99
                    })
            });
            return c;
        }
        return c;
    });
    return courseList;
}

function removeSubject(course_list, term_name, course_name, sec_name, sub_name): Course[] {
    const courseList: Course[] = course_list.map((c) => {
        if (c.name == course_name) {
            c.terms.forEach(t => {
                if (t.name == term_name)
                    t.sections.forEach(s => {
                        if (s.name == sec_name)
                            s.subjects.forEach(sub => {
                                if (sub.name == sub_name)
                                    sub.status = 99
                            })
                    })
            });
            return c;
        }
        return c;
    });
    return courseList;
}

export function reducer(state = initialState, action: app.Actions): State {
    switch (action.type) {
        case app.ActionTypes.CREATE_COURSE:
            return Object.assign({}, state, { course: action.payload.course });
        case app.ActionTypes.SET_COURSES:
            return Object.assign({}, state, { courses: setCourseArray(action.payload.courses) });
        case app.ActionTypes.SET_SELECTED_COURSE:
            var obj = Object.assign({}, state, { selectedCourse: selectCourse(state.courses, action.payload.selectedCourseId) });
            if (action.payload.selectedCourseName) {
                obj = Object.assign({}, obj, { course_context: action.payload });
            }
            return obj;
        case app.ActionTypes.DISABLE_COURSE:
            return Object.assign({}, state, { courses: disableCourse(state.courses, action.payload.course_id) });
        case app.ActionTypes.ENABLE_COURSE:
            return Object.assign({}, state, { courses: enableCourse(state.courses, action.payload.course_id) });
        case app.ActionTypes.SET_SELECTED_TERM:
            return Object.assign({}, state, { selectedTerm: action.payload.term });
        case app.ActionTypes.SET_SELECTED_SECTION:
            return Object.assign({}, state, { selectedSection: action.payload.name });
        case app.ActionTypes.SET_SELECTED_SECTION_TT:
            return Object.assign({}, state, { selectedSectionTT: action.payload.selectedSection });
        case app.ActionTypes.SET_SELECTED_SUBJECT:
            return Object.assign({}, state, { selectedSubject: action.payload.name });
        case app.ActionTypes.REMOVE_TERM:
            return Object.assign({}, state, { courses: removeTerm(state.courses, action.payload.term_name, action.payload.course) });
        case app.ActionTypes.REMOVE_SECTION:
            return Object.assign({}, state, { courses: removeSection(state.courses, action.payload.term_name, action.payload.course, action.payload.sec_name) });
        case app.ActionTypes.REMOVE_SECTION:
            return Object.assign({}, state, { courses: removeSubject(state.courses, action.payload.term_name, action.payload.course, action.payload.sec_name, action.payload.sub_name) });
        case app.ActionTypes.SET_COURSE_SEARCH_TERM:
            return Object.assign({}, state, { courseSearchTerm: action.payload.search_text });
        case app.ActionTypes.SET_QUERY_STRING:
            return Object.assign({}, state, { queryString: action.payload.query_string });
        default:
            return state;
    }
}

export const get_course_state = (state: State) => state;
export const get_courses = (state: State) => state.courses;
export const get_course_selected = (state: State) => state.selectedCourse;
export const get_course_context = (state: State) => state.course_context;
export const get_course_term_selected = (state: State) => {
    if (state.selectedCourse && state.selectedTerm) {
        var t = state.selectedCourse.terms.find(t => t.name == state.selectedTerm);
        return t;
    }
    return null;
}
export const get_course_section_selected = (state: State) => {
    if (state.selectedCourse && state.selectedTerm && state.selectedSection) {
        var t = state.selectedCourse.terms.find(t => t.name == state.selectedTerm);
        if (t) {
            var s = t.sections.find(s => s.name == state.selectedSection);
            return s || null;
        }
    }
    return null;
}

export const get_course_section_subject_selected = (state: State) => {
    if (state.selectedCourse && state.selectedTerm && state.selectedSection) {
        var t = state.selectedCourse.terms.find(t => t.name == state.selectedTerm);
        if (t) {
            var s = t.sections.find(s => s.name == state.selectedSection);
            if (s) {
                var sub = s.subjects.find(sub => sub.name == state.selectedSubject);
                return sub || null;
            }
        }
    }
    return null;
}
export const get_course_search_term = (state: State) => state.courseSearchTerm;
export const get_course_query_string = (state: State) => state.queryString;
export const get_selected_section = (state: State) => state.selectedSection;
export const get_selected_section_tt = (state: State) => state.selectedSectionTT;