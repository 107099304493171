import { Component, TemplateRef, OnInit } from '@angular/core';
import { AppService } from '../../../app/service/app.service';
import { DexService } from '../services/dex.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { Location } from '@angular/common';
class Model {
  queryTitle: string = "";
  queryDescription: string = ""
}
@Component({
  selector: 'app-dex-sidebar-header',
  templateUrl: './dex-sidebar-header.component.html',
  styleUrls: ['./dex-sidebar-header.component.css']
})
export class DexSidebarHeaderComponent implements OnInit {
  modalRef: BsModalRef;
  readonly m: Model = new Model();

  constructor(private appService: AppService, public dexService: DexService,
    private modalService: BsModalService, private location: Location) { }

  ngOnInit() {
  }
  ut_reset() {
    this.dexService.resetQuery();
  }
  ut_toggleDex() {
    this.appService.toggleDexSidebar();
  }
  ut_goBack() {
    this.location.back();
  }
  ut_openSaveModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }
  ut_saveQuery() {
    this.dexService.saveQuery(this.m.queryTitle, this.m.queryDescription);
  }
  ut_updateQuery() {
    this.dexService.updateQuery();
  }
  ut_deleteQuery() {
    this.dexService.deleteQuery();
  }
}
