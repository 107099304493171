import { Injectable } from '@angular/core';

import { CTApi } from '../../../app/service/ct-api';

import { Store } from '@ngrx/store';
import * as rootReducer from '../../../app/rootReducer';
import * as personaAction from '../store/action'


@Injectable()
export class PersonaService {
    constructor(public ctapi: CTApi, private store: Store<rootReducer.State>, ) { }
    getPersonas(userId) {
        this.ctapi.get_personas(userId)
            .subscribe(res => {
                this.store.dispatch(new personaAction.SetPersonas({ "personas": res['data'] }));
            });
    }

}
