import * as introJs from "intro.js"

enum WIDGET_NAME {
    COURSE_WISE_GENDER_BREAKUP = "course_wise_gender_breakup",
    PERSONA_WISE_USER_COUNT = "persona_wise_user_count",
    SCHEDULED_EXAMS = "scheduled_exams",
    COURSEWISE_FEES_COLLECTION_STATUS = "coursewise_fees_collection_status",
    COURSEWISE_FEES_BREAKUP_WITH_STUDENT_COUNT = "coursewise_fees_breakup_with_student_count",
    COURSEWISE_FEES_BREAKUP = "coursewise_fees_breakup",
    USERWISE_PERSONAS = "userwise_personas",
    PERSONA_WISE_USERS = "personawise_users",
    EXAM_PERFORMANCE = "exam_performance",
    CLASSWISE_EXAM_PERFORMANCE = "classwise_exam_performance",
    SUBJECTWISE_EXAM_PERFORMANCE = "subjectwise_exam_performance"
}
const ALL_WIDGETS = [
    WIDGET_NAME.COURSE_WISE_GENDER_BREAKUP,
    WIDGET_NAME.PERSONA_WISE_USER_COUNT,
    WIDGET_NAME.SCHEDULED_EXAMS,
    WIDGET_NAME.COURSEWISE_FEES_COLLECTION_STATUS,
    WIDGET_NAME.COURSEWISE_FEES_BREAKUP_WITH_STUDENT_COUNT,
    WIDGET_NAME.COURSEWISE_FEES_BREAKUP,
    WIDGET_NAME.USERWISE_PERSONAS,
    WIDGET_NAME.PERSONA_WISE_USERS,
    WIDGET_NAME.EXAM_PERFORMANCE,
    WIDGET_NAME.CLASSWISE_EXAM_PERFORMANCE,
    WIDGET_NAME.SUBJECTWISE_EXAM_PERFORMANCE,
]
const WIDGET_FILTERS = {
    [WIDGET_NAME.EXAM_PERFORMANCE]: {
        courseName: "VI",
        subjectName: "Social Studies"
    },
    [WIDGET_NAME.SUBJECTWISE_EXAM_PERFORMANCE]: {
        courseName: "VI"
    }
}

enum TIME_PERIOD {
    TODAY = "today",
    WEEK = "week",
    MONTH = "month",
    QUARTER = "quarter",
    YEAR = "year"
}

enum INTRO_IDS {
    HEADER = "header"
}

const ALL_INTROS = [INTRO_IDS.HEADER];

const INTRO_OPTIONS: { [a: string]: introJs.Options } = {
    HEADER: {
        steps: [
            { element: '.intro-header-ct', intro: 'To view release info of CampusTrack' },
            { element: '.intro-header-hb', intro: 'To toggle the tab bar' },
            { element: '.intro-header-ab', intro: 'Action bar' },
            { element: '.intro-header-ui', intro: 'To view User Info and Persona list' },
            // { element: '.intro-header-um', intro: 'User menu' },
        ],
    }
}

const IN_PAGE_SIDEBAR_DATA = {
    'ScheduledPublishes': {
        'title': 'Publish',
        'data': []
    }
}
const QUICKLINKS_FOR_PUBLISH = [
    { label: "Calendar Events", path: "/academics/calendar/events" },
    { label: "Fees Defaulters Report", path: "/fees/feesDefaultersListReport" },
    { label: "Create Topics", path: "/connect/manageTopics" },
    { label: "Scheduled Publishes", click: (modalRef) => { } }
]

export { WIDGET_NAME, ALL_WIDGETS, WIDGET_FILTERS, TIME_PERIOD, INTRO_IDS, ALL_INTROS, INTRO_OPTIONS, IN_PAGE_SIDEBAR_DATA, QUICKLINKS_FOR_PUBLISH }
