import { Component, ElementRef, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ApiService } from '../../chart-dashboard/service/api-service';
import { ChartWidgetModel } from '../../chart-dashboard/service/models';
import * as R from 'ramda';
import { Store } from "@ngrx/store";
import * as rootReducer from '../../../../app/rootReducer';
import { Subscription } from 'rxjs';

declare const google: any;

@Component({
  selector: 'gv-linechart',
  templateUrl: './gv-linechart.component.html',
  styleUrls: ['./gv-linechart.component.css']
})
export class GvLinechartComponent implements OnInit {
  cwm!: ChartWidgetModel;
  @Input() modelObserver: any;
  currentPersona: string;
  subs = new Subscription();

  @ViewChild('linechart', { read: ViewContainerRef }) lineChartElement?: ElementRef;
  chartStatus = 'Loading chart';

  lineChartSpec: any = {
    "$schema": "https://vega.github.io/schema/vega-lite/v5.json",
    "description": "Google's stock price over time.",
    "title": "stock price over time",
    "data": {
      "values": [
        {
          "symbol": "GOOG",
          "date": "Aug 1 2004",
          "price": 102.37
        },
        {
          "symbol": "GOOG",
          "date": "Sep 1 2004",
          "price": 129.6
        },
        {
          "symbol": "GOOG",
          "date": "Oct 1 2004",
          "price": 190.64
        },
        {
          "symbol": "GOOG",
          "date": "Nov 1 2004",
          "price": 181.98
        },
        {
          "symbol": "GOOG",
          "date": "Dec 1 2004",
          "price": 192.79
        },
        {
          "symbol": "GOOG",
          "date": "Jan 1 2005",
          "price": 195.62
        },
        {
          "symbol": "GOOG",
          "date": "Feb 1 2005",
          "price": 187.99
        },
        {
          "symbol": "GOOG",
          "date": "Mar 1 2005",
          "price": 180.51
        }
      ]
    },
    "mark": "line",
    "encoding": {
      "x": {
        "field": "date",
        "type": "temporal"
      },
      "y": {
        "field": "price",
        "type": "quantitative"
      }
    }
  };

  constructor(private apiService: ApiService, private store: Store<rootReducer.State>) { }

  ngOnInit(): void {
    this.sub_store();
  }

  sub_store() {
    if (this.modelObserver) {
      this.modelObserver.subscribe((modelObserver: any) => {
        this.cwm = modelObserver;
        this.drawChart();
      });
    } else {
      this.cwm = new ChartWidgetModel();
    }
    this.store.select(rootReducer.get_state_app).subscribe((res: any) => {
      if (res) {
        this.currentPersona = res.currentPersona.title;
      }
    });
    this.subs.add(this.store.select(rootReducer.get_chart_meta_data).subscribe((res: any) => {
      if (res) {
        this.cwm.chartMetaData = res;
        this.drawChart();
      }
    }));
  }

  ngOnDestroy() {
    if (this.modelObserver) {
      this.modelObserver.unsubscribe();
    }
    this.subs.unsubscribe();
  }

  ut_refreshWidget() {
    this.apiService.refreshWidgetData(this.cwm);
  }

  drawChart() {
    if (this.cwm.options) {
      this.lineChartSpec = R.mergeDeepRight(this.lineChartSpec, this.cwm.options);
      if (this.lineChartSpec.hasOwnProperty('vconcat')) {
        delete this.lineChartSpec['mark'];
        delete this.lineChartSpec['encoding'];
      }
    }
    if ((!R.path(["data", "isSampleData"], this.cwm.widgetSpecs)) && R.path(["widgetSpecs", "data", "api", "end_point"], this.cwm) && R.path([this.cwm.dashboardId, this.cwm.widgetId, "msg"], this.cwm.chartMetaData)) {
      this.chartStatus = R.path([this.cwm.dashboardId, this.cwm.widgetId, "msg"], this.cwm.chartMetaData);
    } else if (!R.path(["data", "isSampleData"], this.cwm.widgetSpecs) && R.path(["widgetSpecs", "data", "api", "end_point"], this.cwm) && this.cwm.data_values && (this.cwm.data_values.length > 0)) {
      this.lineChartSpec['data']['values'] = this.cwm.data_values;
      try {
        google.charts.load('visualization', { 'packages': ['vegachart'] });
        const self: any = this;
        google.setOnLoadCallback(function () {
          const dataTable = new google.visualization.DataTable();
          const chart = new google.visualization.VegaChart(self.lineChartElement.element.nativeElement);
          chart.draw(dataTable, { 'vegaLite': self.lineChartSpec });
        });
      } catch (_) { }
    } else if (R.path(["data", "isSampleData"], this.cwm.widgetSpecs) && this.cwm.data_values && (this.cwm.data_values.length > 0)) {
      this.lineChartSpec['data']['values'] = this.cwm.data_values;
      try {
        google.charts.load('visualization', { 'packages': ['vegachart'] });
        const self: any = this;
        google.setOnLoadCallback(function () {
          const dataTable = new google.visualization.DataTable();
          const chart = new google.visualization.VegaChart(self.lineChartElement.element.nativeElement);
          chart.draw(dataTable, { 'vegaLite': self.lineChartSpec });
        });
      } catch (_) { }
    } else {
      this.chartStatus = 'No data available.';
    }
  }
}
