import { Component, OnInit, TemplateRef, NgZone, ViewChild } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';

import { Store } from '@ngrx/store';
import * as rootReducer from '../rootReducer';
import { Subscription } from 'rxjs/Subscription';
import { ActivatedRoute, Router } from '@angular/router';

import * as feesAction from '../fees/store/action';
import { HomeService as service } from './service/home-service';
import * as appAction from '../store/app-action';
import { DragulaService } from 'ng2-dragula';
import { RecaptchaLoaderService } from 'ng-recaptcha';
import { ChartDashboardModel } from './../components/chart-dashboard/service/models';
import { BehaviorSubject } from 'rxjs';

import { PulseService } from '../service/pulse';
import * as _ from 'underscore';
import { ALL_WIDGETS, WIDGET_NAME } from '../service/constants';
import { UpdateChartDashboardData } from '../widgets/store/action';

import { CTApi } from "../service/ct-api";
import * as Data from '../../app/userLinks.js';

class model {
    showInvalidCred: any = false;
    token: any = '';
    userLinks: any;
    domain: string = "";
	subDomain: string = "";
    disableStep1: boolean = false;
    disableStep2: boolean = false;
    captchaVerified: boolean = false;
    captchaResponse: string;
    email: string;
    password: string = "";
    newUser: boolean = true;
    errorMessage: string;
    mobilePattern: "^[6-9]\d{9}$";
    referral: string;
    emailCode: string;
    smsCode: string;
    leadGenerated: boolean = false;
    leadId: string;
    resendVerCode: boolean = false;
    verCodeResent: boolean = false;
    timer: number;
    activateResendLink: boolean = false;
    applicant: any = {
        id: "",
        name: "",
        title: "",
        email: "",
        mobile: "",
        dateOfApplication: new Date(),
        otpEmail: "",
        otpMobile: "",
        password: ""
    };
    isKes: boolean = false;
    isAkshadhaa: boolean = false;
    kesISN: string = "kesshr";
    akshadhaaISN: string = "akshadhaa";
    kesHostName: string = "kesshr.campustrack.net";
    akshadhaaHostName: string = "akshadhaa.campustrack.net";
    ctContext: any;
    userLoggedIn: any = false;
    recAct: any;
    userPreferredItems: string[] = [];
    bellChartData: any = [
        ['X Value', 'Activities', 'Students', { type: 'string', role: 'tooltip' }, { role: 'style' }],
        [-3.5, 0, 0, 'Ramesh S', 'red'],
        [-3, 2, 2, 'Prakash P', 'red'],
        [-2.5, 9, 9, 'Mateen H', 'red'],
        [-2, 27, 27, 'Asha L', 'red'],
        [-1.5, 65, 65, 'Abhinav K', 'orange'],
        [-1, 121, 121, 'Nikhil S', 'orange'],
        [-0.5, 176, 176, 'Pratap G', 'green'],
        [0, 200, 200, 'Akash S', 'green'],
        [0.5, 176, 176, 'Amar S', 'green'],
        [1, 121, 121, 'Ajay S', 'orange'],
        [1.5, 65, 65, 'Avinash S', 'orange'],
        [2, 27, 27, 'Ajinkya S', 'red'],
        [2.5, 9, 9, 'Apoorva S', 'red'],
        [3, 2, 2, 'Pradeep K', 'red'],
        [3.5, 0, 0, 'Pramod K', 'red']
    ];
    public bellChartOptions: any = {
        title: 'Daffodils',
        chartArea: { width: '85%' },
        backgroundColor: '#f5fafa',
        legend: { position: 'top' },
        seriesType: 'area',
        series: { 1: { type: 'bars' } },
        hAxis: {
            title: 'Students',
        },
        vAxis: {
            textPosition: "in",
            title: 'No Of Activities',
        },
    };
    public monthlyChartData: any = [
        ['Months', 'Sensorial', 'Language', 'Arithmetic', 'Culture', 'Average'],
        ['Jan', 15, 10, 20, 10, 15],
        ['Feb', 5, 15, 15, 5, 10],
        ['March', 30, 10, 15, 20, 20]
    ];
    public monthlyChartOptions: any = {
        title: 'Daffodils',
        chartArea: { width: '85%' },
        backgroundColor: '#f5fafa',
        displayExactValues: true,
        hAxis: {
            title: 'Months',
            textStyle: {
                bold: true,
                fontSize: 10,
                color: '#4d4d4d'
            },
            titleTextStyle: {
                bold: true,
                fontSize: 10,
                color: '#4d4d4d'
            }
        },
        vAxis: {
            textPosition: "in",
            title: 'Students',
            minValue: 0,
            textStyle: {
                fontSize: 9,
                bold: true,
                color: '#848484'
            },
            titleTextStyle: {
                fontSize: 10,
                bold: true,
                color: '#848484'
            }
        },
        legend: { position: 'top' },
        seriesType: 'bars',
        series: { 4: { type: 'line' } }
    };
    public pieChartData: any = [
        ['Gender', 'Count'],
        ['Boys', 200],
        ['Girls', 150]];
    public pieChartOptions: any = {
        backgroundColor: '#f5fafa',
        is3D: true,
        title: 'Daffodils'
    };
    public calChartData: any = [
        [{ type: 'date', id: 'Date' }, { type: 'number', id: 'Won/Loss' }],
        [new Date(2017, 0, 26), 1],
        [new Date(2017, 1, 12), -1],
        [new Date(2017, 1, 9), 1],
        [new Date(2017, 1, 10), 1],
        [new Date(2017, 2, 12), 1],
        [new Date(2017, 4, 20), 1],
        [new Date(2017, 5, 16), 1],
        [new Date(2017, 7, 10), 1],
        [new Date(2017, 9, 1), 1],
        [new Date(2017, 10, 30), 1],
        [new Date(2017, 11, 9), 1]];
    public calChartOptions: any = {
        chartArea: {
            width: '85%',
            backgroundColor: {
                fill: '#1EB81E'
            },
        },
        noDataPattern: {
            backgroundColor: '#1EB81E',
            color: '#1EB81E'
        },
        backgroundColor: '#1EB81E',
        calendar: { cellSize: 14 },
        colorAxis: { colors: ['yellow', 'orange', 'red'] }
    };
    public actChartData: any = [
        ['Activity', 'Completed', 'Pending'],
        ['Ex Of Practical Life', 20, 10],
        ['Sensorial', 15, 15],
        ['Arithmetic', 20, 10],
        ['Language', 25, 5],
        ['Culture', 22, 8],
        ['Art', 28, 2]];
    public actChartOptions: any = {
        title: 'Daffodils',
        chartArea: { width: '85%' },
        backgroundColor: '#f5fafa',
        isStacked: true,
        displayExactValues: true,
        hAxis: {
            title: '',
            textStyle: {
                bold: true,
                fontSize: 10,
                color: '#4d4d4d'
            },
            titleTextStyle: {
                bold: true,
                fontSize: 10,
                color: '#4d4d4d'
            }
        },
        vAxis: {
            textPosition: "in",
            title: 'Students',
            minValue: 0,
            textStyle: {
                fontSize: 9,
                bold: true,
                color: '#848484'
            },
            titleTextStyle: {
                fontSize: 10,
                bold: true,
                color: '#848484'
            }
        },
        colors: ['green', 'red'],
        legend: { position: 'top' }
    };
    public mBarChartData: any = [
        ['Environment', 'Boys', 'Girls'],
        ['Daffodils', 200, 180],
        ['Tulips', 180, 170]];
    public mBarChartOptions: any = {
        title: '',
        chartArea: { width: '85%' },
        backgroundColor: '#f5fafa',
        isStacked: true,
        displayExactValues: true,
        hAxis: {
            title: 'Environment',
            textStyle: {
                bold: true,
                fontSize: 10,
                color: '#4d4d4d'
            },
            titleTextStyle: {
                bold: true,
                fontSize: 10,
                color: '#4d4d4d'
            }
        },
        vAxis: {
            textPosition: "in",
            title: 'Strength',
            minValue: 0,
            textStyle: {
                fontSize: 9,
                bold: true,
                color: '#848484'
            },
            titleTextStyle: {
                fontSize: 10,
                bold: true,
                color: '#848484'
            }
        },
        legend: { position: 'top' }
    };
    public barChartData: any = [
        ['Course', 'Boys', 'Girls'],
        ['I PUC', 200, 180],
        ['II PUC', 180, 170]];
    public barChartOptions: any = {
        title: '',
        chartArea: { width: '85%' },
        backgroundColor: '#f5fafa',
        isStacked: true,
        displayExactValues: true,
        hAxis: {
            title: 'Course',
            textStyle: {
                bold: true,
                fontSize: 10,
                color: '#4d4d4d'
            },
            titleTextStyle: {
                bold: true,
                fontSize: 10,
                color: '#4d4d4d'
            }
        },
        vAxis: {
            textPosition: "in",
            title: 'Strength',
            minValue: 0,
            textStyle: {
                fontSize: 9,
                bold: true,
                color: '#848484'
            },
            titleTextStyle: {
                fontSize: 10,
                bold: true,
                color: '#848484'
            }
        },
        legend: { position: 'top' }
    };
    public cpChartData: any = [
        ['Course', 'Upto 50%', '50% to 80%', '80% to 100%'],
        ['I PUC PCMB', 30, 50, 20]];
    public cpChartOptions: any = {
        title: '',
        chartArea: { width: '85%' },
        backgroundColor: '#f5fafa',
        isStacked: true,
        displayExactValues: true,
        hAxis: {
            title: 'Course',
            textStyle: {
                bold: true,
                fontSize: 10,
                color: '#4d4d4d'
            },
            titleTextStyle: {
                bold: true,
                fontSize: 10,
                color: '#4d4d4d'
            }
        },
        vAxis: {
            textPosition: "in",
            title: 'Strength',
            minValue: 0,
            textStyle: {
                fontSize: 9,
                bold: true,
                color: '#848484'
            },
            titleTextStyle: {
                fontSize: 10,
                bold: true,
                color: '#848484'
            }
        },
        legend: { position: 'top' }
    };
    public stuChartData: any = [
        ['Exam', 'Physics', 'Chemistry', 'Maths', 'Biology', 'Average'],
        ['Unit Test', 70, 80, 75, 80, 75],
        ['Midterm Exam', 60, 70, 60, 70, 65],
        ['Final Exam', 80, 90, 80, 90, 85]
    ];
    public stuChartOptions: any = {
        title: '',
        chartArea: { width: '85%' },
        backgroundColor: '#f5fafa',
        displayExactValues: true,
        hAxis: {
            title: 'Exam',
            textStyle: {
                bold: true,
                fontSize: 10,
                color: '#4d4d4d'
            },
            titleTextStyle: {
                bold: true,
                fontSize: 10,
                color: '#4d4d4d'
            }
        },
        vAxis: {
            textPosition: "in",
            title: 'Score',
            minValue: 0,
            textStyle: {
                fontSize: 9,
                bold: true,
                color: '#848484'
            },
            titleTextStyle: {
                fontSize: 10,
                bold: true,
                color: '#848484'
            }
        },
        legend: { position: 'top' },
        seriesType: 'bars',
        series: { 4: { type: 'line' } }
    };
    public notifsChartData: any = [
        ['Date', 'Event'],
        ['11-04-2018', 'CampusTrack Demo Meeting'],
        ['18-04-2018', 'Basava Jayanthi'],
        ['20-04-2018', 'Shankara Jayanthi'],
        ['30-04-2018', 'Buddha Poornima'],
        ['03-05-2018', 'Staff Meeting'],
        ['06-05-2018', 'Orientation Programme']];
    public notifsChartOptions: any = {
        title: '',
        chartArea: { width: '100%' },
        backgroundColor: '#f5fafa',
        width: '100%'
    };
    // acadYearComponent: any = { title: 'acad_year', data: { from: '2018-05-01', to: '2019-04-30', acad_set: false } };
    acadYearComponent: any;
    // courseComponent: any = { title: 'course', data: { count: 0 } };
    courseComponent: any;
    // calendarComponent: any = { title: 'calendar', data: { holiday_count: 0 } };
    calendarComponent: any;
    courses: any[] = [];
    acadYearFrom: Date;
    acadYearTo: Date;
    userData: any;
    clientShortName: string;
    currentMonthYear: string;
    constructor() {
        this.acadYearComponent = null;
        this.courseComponent = null;
        this.calendarComponent = null;
    }
}

@Component({
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    providers: [service]
})
export class HomeComponent implements OnInit {
    public cdm: ChartDashboardModel;
    chartModelObserver: any;
    specsFileName: string = "";
    currentPersona: string = "";
    
    @ViewChild("notificationConsent") private contentRef: TemplateRef<any>;
    onRightClick(event) {
        return false
    }
    public resolved(captchaResponse: string) {
        if (captchaResponse == null) this.m.captchaVerified = false;
        else {
            this.m.captchaVerified = true;
            this.m.captchaResponse = captchaResponse;
        }
    }
    public m: model;
    modalRef: BsModalRef;
    private sub_user_status: Subscription;
    private sub_ct_context: Subscription;
    private readonly subscription: Subscription = new Subscription();
    readonly tableHeaders: string[] = ["#", "Name", "Data Count", "Created on", "Updated on", "Action"];
    readonly widgets: Array<any> = [];

    constructor(private pulseService: PulseService, public ctapi: CTApi, private dragulaService: DragulaService, public service: service, private store: Store<rootReducer.State>, private router: Router, private _ngZone: NgZone, private modalService: BsModalService, private activatedRoute: ActivatedRoute) { }
    ngOnInit() {
        this.init_model();
        this.checkIsKes();
        this.checkIsAkshadhaa();
        this.init_store();
        this.sub_store();
    }
    ngOnDestroy() {
        this.sub_user_status.unsubscribe();
        if (this.subscription) this.subscription.unsubscribe();
    }
    init_model() {
        this.m = new model();
        this.m.userLinks = Data.userLinks;

        this.getSubdomain();

        this.cdm = new ChartDashboardModel();
        this.cdm.dashboardId = "";
        this.cdm.dashboardSpecs = {};
        this.cdm.widgetList = [];
        this.cdm.dataList = [];
        this.chartModelObserver = new BehaviorSubject(this.cdm);

        let calendarMonths: any[] = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        let _ms = calendarMonths;
        var month_name = function (dt) {
            return _ms[dt.getMonth()];
        };
        let currentDate = new Date();
        this.m.currentMonthYear = month_name(currentDate) + ' ' + currentDate.getFullYear();
        this.service.getWidgetDataByNames(ALL_WIDGETS);
    }
    init_store() {
    }
    sub_store() {
        this.sub_user_status = this.store.select(rootReducer.get_user_status).subscribe(
            res => {
                this._ngZone.run(() => {
                    this.m.userLoggedIn = res || false;
                });
            },
            err => console.log(err)
        );
        this.sub_ct_context = this.store.select(rootReducer.get_state_app).subscribe(
            context => {
                if (context) {
                    this.m.ctContext = context;
                    this.m.clientShortName = context.currentPersona.clientShortName;
                    this.m.acadYearFrom = context.currentPersona.acadYearFrom;
                    this.m.acadYearTo = context.currentPersona.acadYearTo;
                    if (context.currentPersona.title != 'Student') this.getDashboardWidgets();
                    if (!context.notification) this.openNotificationConsentModal();

                    this.currentPersona = context.currentPersona.title.toLowerCase();
                    this.specsFileName = "specs-home-" + this.currentPersona + ".json";
                    if ((this.m.ctContext.currentPersona.clientShortName == 'BTLCOL' || this.m.ctContext.currentPersona.clientShortName == 'PPEC' || this.m.ctContext.currentPersona.clientShortName == 'PPECPS' || this.m.ctContext.currentPersona.clientShortName == 'AKSHFN') && this.m.ctContext.currentPersona.title != 'Applicant') this.getSpecFile();
                }
            });
        this.sub_ct_context = this.store.select(rootReducer.get_rec_act).subscribe(
            res => {
                if (res) {
                    this.m.recAct = res;
                }
                else {
                    this.service.getRecentActivity();
                }
            });
        this.sub_ct_context = this.store.select(rootReducer.get_courses).subscribe(res => {
            if (res) this.m.courses = res;
        })
        this.sub_ct_context = this.store.select(rootReducer.get_user_updated_data).subscribe((res: any) => {
            if (res) {
                this.m.userData = res;
                this.m.acadYearComponent = res.components.find(function (element) {
                    element.title == "acad_year";
                }) || null;
                this.m.calendarComponent = res.components.find(function (element) {
                    element.title == "calendar";
                }) || null;
                this.m.courseComponent = res.components.find(function (element) {
                    element.title == "course";
                }) || null;
            }
        });

        this.sub_ct_context = this.store.select(rootReducer.get_dashboard_widgets).subscribe(res => {
            if (res) this.m.userPreferredItems = res;
        });
        this.subscription.add(this.store.select(rootReducer.get_dashboard).subscribe(res => {
            if (res && res.length > 0) {
                this.widgets.length = 0;
                ALL_WIDGETS.forEach((widget_name: any) => {
                    this.widgets.push({
                        widget_name: widget_name, data: { widget_data: [] }, inserted_at: "", updated_at: "",
                    });
                });
                res.forEach((widget: any) => {
                    switch (widget.widget_name) {
                        case WIDGET_NAME.COURSE_WISE_GENDER_BREAKUP:
                            this.updateWidgetData(widget, 100);
                            break;
                        case WIDGET_NAME.PERSONA_WISE_USER_COUNT:
                            this.updateWidgetData(widget, 101);
                            break;
                        case WIDGET_NAME.SCHEDULED_EXAMS:
                            this.updateWidgetData(widget, 102);
                            break;
                        case WIDGET_NAME.COURSEWISE_FEES_COLLECTION_STATUS:
                            this.updateWidgetData(widget, 103);
                            break;
                        case WIDGET_NAME.COURSEWISE_FEES_BREAKUP_WITH_STUDENT_COUNT:
                            this.updateWidgetData(widget, 105);
                            break;
                        case WIDGET_NAME.COURSEWISE_FEES_BREAKUP:
                            this.updateWidgetData(widget, 106);
                            break;
                        case WIDGET_NAME.USERWISE_PERSONAS:
                            this.updateWidgetData(widget, 107);
                            break;
                        case WIDGET_NAME.PERSONA_WISE_USERS:
                            this.updateWidgetData(widget, 108);
                            break;
                        case WIDGET_NAME.EXAM_PERFORMANCE:
                            this.updateWidgetData(widget, 109);
                            break;
                        case WIDGET_NAME.CLASSWISE_EXAM_PERFORMANCE:
                            this.updateWidgetData(widget, 110);
                            break;
                        case WIDGET_NAME.SUBJECTWISE_EXAM_PERFORMANCE:
                            this.updateWidgetData(widget, 111);
                            break;
                        default:
                            break;
                    }
                    const indexToUpdate = this.widgets.findIndex(w => w.widget_name === widget.widget_name);
                    if (indexToUpdate !== -1) {
                        this.widgets[indexToUpdate] = { ...this.widgets[indexToUpdate], ...widget };
                    }
                });
            }
        }));
    }
    private updateWidgetData(widget: any, widgetId: number) {
        if (widget.data && widget.data.widget_data) {
            this.store.dispatch(new UpdateChartDashboardData({
                dashboardId: "home-" + this.currentPersona, widgetId: widgetId, data: widget.data.widget_data
            }));
        }
    }
    getSubdomain() {
		let hostName = window.location.hostname;
		if (hostName) {
			let hostNameArr = hostName.split('.');
			this.m.subDomain = hostNameArr[0];
			if (hostName.startsWith("localhost")) this.m.domain = "localhost";
		}
	}
    getSpecFile() {
        this.service.getSpecsFile(this.specsFileName).subscribe((res: any) => {
            if (res) {
                let result = JSON.parse(res.body);
                this.cdm.dashboardId = result.id;
                this.cdm.dashboardSpecs = result;
                this.cdm.widgetList = result.widgets;
                this.cdm.fileName = this.specsFileName;
                if (this.chartModelObserver.isStopped) {
                    this.chartModelObserver = new BehaviorSubject(this.cdm);
                } else {
                    this.chartModelObserver.next(this.cdm);
                }
            }
        });
    }

    getDashboardWidgets() {
        this.service.getDashboardWidgets();
    }

    drag_widget(evt, data) {
        if (data)
            evt.dataTransfer.setData("text", JSON.stringify(data));
    }

    drop_widget(ev, dropped) {
        ev.preventDefault();
        var data = ev.dataTransfer.getData("text");
        var dragged_item = JSON.parse(data);
        if (dropped) {
            var old_index = this.m.userPreferredItems.indexOf(dragged_item);
            var new_index = this.m.userPreferredItems.indexOf(dropped);

            this.m.userPreferredItems = this.array_move(this.m.userPreferredItems, old_index, new_index);
            this.service.setWidgetsOrder(this.m.userPreferredItems);
        }
    }
    allowDrop(ev) {
        ev.preventDefault();
    }

    array_move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr;
    };

    goToRA(sel_fee_obj) {
        var routerPath = sel_fee_obj.Component;
        this.store.dispatch(new feesAction.SetFeeContext({ "selectedFeeId": sel_fee_obj.Context.SelectedFeeId }));
        this.router.navigate([routerPath]);
    }

    openNotificationConsentModal() {
        this.modalRef = this.modalService.show(this.contentRef);
    }

    ut_confirmNotificationConsent() {
        this.modalRef.hide();
        this.service.confirmNotificationConsent(this.m.ctContext.userId).subscribe(res => {
            this.m.ctContext.notification = true;
            this.store.dispatch(new appAction.LoginSuccess({ "userLoggedIn": true, "ctContext": this.m.ctContext }));
        });
    }

    ut_cancelNotificationConsent() {
        this.modalRef.hide();
        sessionStorage.accessToken = "";
        sessionStorage.ctContext = null;
        this.store.dispatch(new appAction.Logout(''));
    }
    isSubDomainKes(hostName) {
        if (hostName) {
            let hostNameArr = hostName.split('.');
            if (hostNameArr && hostNameArr.length > 0 && hostNameArr[0] == this.m.kesISN) { return true }
            else { return false }
        }
        else { return false }
    }
    isSubDomainAkshadhaa(hostName) {
        if (hostName) {
            let hostNameArr = hostName.split('.');
            if (hostNameArr && hostNameArr.length > 0 && hostNameArr[0] == this.m.akshadhaaISN) { return true }
            else { return false }
        }
        else { return false }
    }
    checkIsKes() {
        let hostName = window.location.hostname;
        if (hostName && (hostName == this.m.kesHostName) && this.isSubDomainKes(hostName)) {
            this.m.isKes = true;
        }
        else { this.m.isKes = false }
    }
    checkIsAkshadhaa() {
        let hostName = window.location.hostname;
        if (hostName && (hostName == this.m.akshadhaaHostName) && this.isSubDomainAkshadhaa(hostName)) {
            this.m.isAkshadhaa = true;
        }
        else { this.m.isAkshadhaa = false }
    }
    ut_getApplicantOTP(step1template: TemplateRef<any>) {
        this.m.applicant.name = this.m.applicant.name.toUpperCase();
        let applicantDetails = { id: this.m.applicant.id, name: this.m.applicant.name.toUpperCase(), mobile: this.m.applicant.mobile, email: this.m.applicant.email, title: this.m.applicant.title }
        this.service.getApplicantOTP(applicantDetails).subscribe(res => {
            if (res) {
                let resp = JSON.parse(res.body);
                this.m.applicant.id = resp.data.id;
                this.openModal(step1template);
                this.m.disableStep1 = true;
            }
        },
            err => {
            });
    }
    _keyPress(event: any) {
        const pattern = /[0-9]/;
        let inputChar = String.fromCharCode(event.charCode);
        if (!pattern.test(inputChar)) {
            event.preventDefault();
        }
    }
    ut_registerApplicant(step2template: TemplateRef<any>, invalidOtpTemplate: TemplateRef<any>) {
        this.m.applicant.name = this.m.applicant.name.toUpperCase();
        let todayDate = new Date();
        todayDate.setMinutes(todayDate.getMinutes() - todayDate.getTimezoneOffset());
        this.m.applicant.dateOfApplication = todayDate.toISOString().slice(0, 10);
        this.service.registerApplicant(this.m.applicant).subscribe(res => {
            if (res) {
                this.openModal(step2template);
                this.m.disableStep2 = true;
            }
        },
            err => {
                this.openModal(invalidOtpTemplate);
            });
    }
    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template);
    }

    ut_refreshWidgets() {
        this.service.getWidgetDataByNames(ALL_WIDGETS);
    }

    ut_syncWidgets() {
        ALL_WIDGETS.forEach(widgetName => {
            this.pulseService.syncWidgetData(widgetName);
        });
    }
    ut_syncWidget(w) {
        this.pulseService.syncWidgetData(w.widget_name);
    }
    ut_refreshWidget(w) {
        this.service.getWidgetDataByNames([w.widget_name]);
    }
    ut_cardsData() {
        this.pulseService.getCardsData();
    }

    openBrochure(){
        window.open('https://prod.campustrack.net/assets/ctbrochure.pdf', '_blank')
    }

    login() {
		this.m.showInvalidCred = false;
		this.ctapi.authenticate(this.m.email, this.m.password, this.m.token)
			.subscribe(data => {
				if (data.headers.get('Authorization')) {
					let access_token = data.headers.get('Authorization');
					let ct_context = data.headers.get('CT-Context');
					let ct_context_json = JSON.parse(ct_context);
					this.m.ctContext = ct_context_json;
					sessionStorage.accessToken = access_token;
					sessionStorage.ctContext = JSON.stringify(ct_context_json);
					this.store.dispatch(new appAction.SetAccessToken({ "accessToken": access_token }));
					this.store.dispatch(new appAction.SetAccessCode());
					if (ct_context_json.notification) this.store.dispatch(new appAction.LoginSuccess({ "userLoggedIn": true, "ctContext": ct_context_json }));
					else this.store.dispatch(new appAction.LoginSuccess({ "userLoggedIn": false, "ctContext": ct_context_json }));
					this.store.dispatch(new appAction.SetUserLinks({ "userLinks": this.m.userLinks }));
					ct_context_json.scope.forEach((sc: string) => {
						if (sc && sc.includes("acad/course"))
							this.ctapi.courseCache().subscribe(() => { });
					});
					ct_context_json.scope.forEach((sc: string) => {
						if (sc && sc.includes("staff"))
							this.ctapi.staffCache().subscribe(() => { });
					});

					// After successful login redirect to return url else handle below cases
					const returnUrl = this.activatedRoute.snapshot.queryParams['returnUrl'];
					if (returnUrl) {
						this.router.navigateByUrl(returnUrl);
					} else {
						if (['BTLCOL', 'PPECPS'].includes(ct_context_json.currentPersona.clientShortName) || this.m.ctContext.currentPersona.title == 'Applicant') {
							this.router.navigate(['/cards']);
						} else {
							this.router.navigate(['/home']);
						}
					}

					if (ct_context_json.currentPersona.clientShortName == 'PPECPS')
						this.store.dispatch(new appAction.SetNBDisplay({ name: "Classic", type: "classic", hideNavBar: false }));
				}
			},
				err => { this.m.showInvalidCred = true; })
	}
}