import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import * as rootReducer from '../../rootReducer';
import { AcademicsService as service } from '../../academics/service/service';
import * as acadAction from '../../academics/store/action';

import * as _ from 'underscore';

class Model {
    path: string = "vx/curriculum";
    topics: any[] = [];
    searchText: string;
    getWidth(width) {
        return `${width}%`;
    }
}

@Component({
    selector: 'curriculum-progress',
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
    providers: [service]
})

export class CurriculumProgressComponent {
    public m: Model;
    public sub1: Subscription;
    @Input() pin: boolean;
    skip: number = 0;
    top: number = 20;
    constructor(private store: Store<rootReducer.State>, public service: service) { }

    ngOnInit() {
        this.init_model();
        this.init_store();
        this.sub_store();
    }

    ngOnDestroy() {
        this.sub1.unsubscribe();
    }

    init_model() {
        this.m = new Model();
    }

    init_store() {
        let qry = `&$search=&$orderby=title&$sortorder=asc&$skip=${this.skip}&$top=${this.top}`;
        this.service.getDocsContentByPath(this.m.path, qry).subscribe((res: any) => {
            if (res && res.length > 0) {
                this.store.dispatch(new acadAction.SetTopicsByContent(res));
            }
        });
    }

    sub_store() {
        this.sub1 = this.store.select(rootReducer.get_topics_by_content).subscribe(files => {
            if (files && files.length > 0) {
                this.m.topics = files;
                this.calculateLp();
            }
        });
    }

    calculateLp() {
        if (this.m.topics && this.m.topics.length > 0) {
            let topics = this.m.topics;
            topics = _.map(topics, function (tp) {
                if (tp.content && tp.content != null && typeof (tp.content) == 'string') {
                    tp.content = JSON.parse(tp.content);
                }
                let lxCount = 0;
                let lxCompleted = 0;
                let progress: any;

                if (tp.content && tp.content.nodes.length > 0) {
                    tp.content.nodes.forEach(nd => {
                        nd.children.forEach(c => {
                            lxCount += c.lxList.length;
                            c.lxList.forEach(l => {
                                if (l.status == 'complete') lxCompleted += 1
                            })
                        });
                    });

                    progress = Math.round((lxCompleted / lxCount) * 100);

                }
                let obj = { lxCount: lxCount, lxCompleted: lxCompleted, progress: progress };
                return tp = Object.assign({}, tp, obj);
            });

            this.m.topics = topics;
        }
    }

    onScroll() {
        var elem = document.getElementById("curr");
        if (elem.scrollTop >= (elem.scrollHeight - elem.offsetHeight)) {
            elem.scrollTop = elem.scrollHeight;
            this.skip += 20;
            let qry;
            if(this.m.searchText != '') qry = `&$search=search:${this.m.searchText}&$orderby=title&$sortorder=asc&$skip=${this.skip}&$top=${this.top}`;
            else qry = `&$search=&$orderby=title&$sortorder=asc&$skip=${this.skip}&$top=${this.top}`;
            this.service.getDocsContentByPath(this.m.path, qry).subscribe((res: any) => {
                if (res && res.length > 0) {
                    this.m.topics = [];
                    this.store.dispatch(new acadAction.SetTopicsByContent(res));
                }
            });
        }
    }

    clearSearch() {
        this.m.searchText = "";
        this.skip = 0;
        this.top = 20;
        let qry = `&$search=&$orderby=title&$sortorder=asc&$skip=${this.skip}&$top=${this.top}`;
        this.service.getDocsContentByPath(this.m.path, qry).subscribe((res: any) => {
            if (res && res.length > 0) {
                this.store.dispatch(new acadAction.SetTopicsByContent(res));
            }
        });
    }

    searchContent() {
        let qry = `&$search=search:${this.m.searchText}&$orderby=title&$sortorder=asc&$skip=0&$top=1024`;
        this.service.getDocsContentByPath(this.m.path, qry).subscribe((res: any) => {
            if (res && res.length > 0) {
                res = _.map(res, function (tp) {
                    if (tp.content && tp.content != null && typeof (tp.content) == 'string') {
                        tp.content = JSON.parse(tp.content);
                    }
                    let lxCount = 0;
                    let lxCompleted = 0;
                    let progress: any;

                    if (tp.content && tp.content.nodes.length > 0) {
                        tp.content.nodes.forEach(nd => {
                            nd.children.forEach(c => {
                                if (c.lxList) {
                                    lxCount += c.lxList.length;
                                    c.lxList.forEach(l => {
                                        if (l.status == 'complete') lxCompleted += 1
                                    })
                                }
                            });
                        });
                        progress = (lxCompleted / lxCount) * 100;
                    }
                    let obj = { lxCount: lxCount, lxCompleted: lxCompleted, progress: progress };
                    return tp = Object.assign({}, tp, obj);
                });

                this.m.topics = [];

                res.forEach(r => {
                    this.m.topics.push(r);
                });
            }
        });
    }
}