import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { CreateDynamicComponentService } from './service/create-dynamic-component.service';
import * as R from 'ramda';
import * as actions from '../../widgets/store/action';
import * as rootReducer from '../../../app/rootReducer';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { ChartDashboardModel } from './service/models';
class Model {
	chartData: any = {};
}
@Component({
	selector: 'chart-dashboard',
	templateUrl: './chart-dashboard.component.html',
	styleUrls: ['./chart-dashboard.component.css']
})
export class ChartDashboardComponent implements OnInit {
	public cdm: ChartDashboardModel;
	public m: Model;
	@Input() modelObserver: any;
	@ViewChild('dyn_compo_pos', { read: ViewContainerRef })
	public viewContainerRef: ViewContainerRef | undefined;
	@ViewChild('refreshBtn', { read: ViewContainerRef })
	public refreshButton: ViewContainerRef | undefined;
	subs = new Subscription();
	refreshInSeconds: any = 0;
	startRefreshTimerObject: any = null;

	constructor(private createDynamicComponentService: CreateDynamicComponentService, private store: Store<rootReducer.State>) { }

	ngOnInit() {
		this.cdm = new ChartDashboardModel();
		this.m = new Model();
		this.sub_store();
		this.initDashboardModel();
		this.store.dispatch(new actions.UpdateChartMetaData({ reset: true }));
	}
	sub_store() {
		if (this.modelObserver) {
			this.modelObserver.subscribe((modelObserver: any) => {
				this.cdm = modelObserver;
				this.store.dispatch(new actions.UpdateDashboardSpecs(this.cdm));
				// this.initDashboardModel();
				// this.updateDashboard();
			});
		} else {
			this.cdm = new ChartDashboardModel();
		}
		this.subs.add(this.store.select(rootReducer.get_chart_dashboard_data).subscribe(res => {
			if (res) {
				this.m.chartData = res;
				this.updateDashboard();
			}
		}));
		this.subs.add(this.store.select(rootReducer.get_dashboard_specs).subscribe((res: any) => {
			if (res) {
				this.cdm = R.pathOr(this.cdm, [this.cdm.dashboardId], res);
				this.initDashboardModel();
				this.updateDashboard();
			}
		}));
	}

	ngAfterViewInit() {
		Promise.resolve(null).then(() => this.createDynamicCompo(this.cdm.widgetList));
	}

	ngOnDestroy() {
		if (this.modelObserver) {
			this.modelObserver.unsubscribe();
		}
		this.subs.unsubscribe();
	}

	createDynamicCompo(dashJson: any = null) {
		this.createDynamicComponentService.clearAllComponent();
		this.createDynamicComponentService.createComponent(dashJson, this.cdm, this.viewContainerRef!);
	}
	initDashboardModel() {
		this.store.dispatch(new actions.UpdateChartDashboardSpecs({ dashboardId: this.cdm.dashboardId, dashboardSpecs: this.cdm.dashboardSpecs }));
		if (this.cdm.dataList) {
			this.cdm.dataList.forEach((dataModel: any) => {
				this.cdm.widgetList.forEach((widget: any) => {
					if (widget.id == dataModel.id.split("/")[1]) {
						widget.data.data_values = dataModel.data;
					}
				});
			});
		}
		if (this.cdm.widgetList) {
			this.cdm.widgetList.forEach((widget: any) => {
				if (!R.path([this.cdm.dashboardId, widget.id], this.m.chartData)) {
					if (R.path(['data', 'isSampleData'], widget)) {
						if (R.path(['data', 'test_file'], widget)) {
							this.store.dispatch(new actions.LoadAssetsData({ dashboardId: this.cdm.dashboardId, widgetId: widget.id, data: widget.data }));
						}
					} else {
						if (R.path(['data', 'api', 'end_point'], widget)) {
							this.store.dispatch(new actions.LoadEndpointData({ dashboardId: this.cdm.dashboardId, widgetId: widget.id, data: widget.data }));
						}
						widget.data.data_values = [];
					}
				}
			});
		}
	}
	updateDashboard() {
		if (R.path([this.cdm.dashboardId], this.m.chartData)) {
			this.cdm.widgetList.forEach((widget: any) => {
				let data = R.path([this.cdm.dashboardId, widget.id], this.m.chartData);
				if (data) {
					widget.data.data_values = data;
				}
			});
		}
		this.createDynamicCompo(this.cdm.widgetList);
	}
	ut_search() {
		this.store.dispatch(new actions.UpdateDashboardSpecsSearchBy({ searchBy: "Bar", dashboardId: this.cdm.dashboardId }));
	}
	startRefreshTimer(count = 0) {
		if (count > 0) {
			count--;
			this.refreshInSeconds = count;
			const self = this;
			return setTimeout(function () { self.startRefreshTimer(count) }, 1000);
		}
		return null;
	}
	ut_refreshWidgets() {
		this.refreshButton.element.nativeElement.disabled = true;
		const self = this;
		this.startRefreshTimerObject = this.startRefreshTimer(14);
		setTimeout(function () {
			if (self.startRefreshTimerObject) {
				clearTimeout(self.startRefreshTimerObject);
			}
			self.refreshButton.element.nativeElement.disabled = false;
		}, 15000);
		if (this.cdm.widgetList) {
			this.cdm.widgetList.forEach((widget: any) => {
				if (R.path(['data', 'isSampleData'], widget)) {
					if (R.path(['data', 'test_file'], widget)) {
						this.store.dispatch(new actions.LoadAssetsData({ dashboardId: this.cdm.dashboardId, widgetId: widget.id, data: widget.data }));
					}
				} else {
					if (R.path(['data', 'api', 'end_point'], widget)) {
						this.store.dispatch(new actions.LoadEndpointData({ dashboardId: this.cdm.dashboardId, widgetId: widget.id, data: widget.data }));
					}
				}
			});
		}
	}
}
