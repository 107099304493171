import { Component, OnInit, TemplateRef, } from '@angular/core';

import { CTApi } from '../service/ct-api';

import { Store } from '@ngrx/store';
import * as rootReducer from '../rootReducer';
import { Subscription } from 'rxjs/Subscription';
import * as personaAction from './store/action';
import * as appAction from '../store/app-action';
import * as googleMeetAction from '../components/google-meet/store/action';
import * as cardsAction from '../cards/store/action';
import { PersonaService as service } from './service/service';
import { Location } from '@angular/common';
import { PulseService } from '../service/pulse';

class model {
	public personas: any = [];
	public ctContext: any;
}

declare const gapi: any;

@Component({
	selector: 'persona',
	templateUrl: './persona.component.html',
	styleUrls: ['./persona.component.css'],
	providers: [service],
})
export class PersonaComponent implements OnInit {
	public m: model;
	subs = new Subscription();
	constructor(
		public ctapi: CTApi,
		private store: Store<rootReducer.State>,
		public service: service,
		private location: Location,
		private pulseService: PulseService
	) { }
	ngOnInit() {
		this.init_model();
		this.init_store();
		this.sub_store();
	}
	ngOnDestroy() {
		this.subs.unsubscribe();
	}
	init_model() {
		this.m = new model();
	}
	init_store() {
	}
	sub_store() {
		this.subs.add(this.store.select(rootReducer.get_personas).subscribe(personas => {
			this.m.personas = personas || [];
		}));
		this.subs.add(this.store.select(rootReducer.get_state_app).subscribe(context => {
			if (context) {
				this.m.ctContext = context;
				this.getPersonas();
			}
		}));
	}
	getPersonas() { this.service.getPersonas(this.m.ctContext.userId); }

	setPersona(persona) {
		this.store.dispatch(new personaAction.SetSelectedPersona(persona));
		this.store.dispatch(new googleMeetAction.ResetSelectedMeetings(''));
		this.store.dispatch(new appAction.UserChannelJoined({ 'userChannelJoined': false }));
		this.store.dispatch(new cardsAction.ResetCardsData(null));
		this.pulseService.disconnectSocket();
	}
	back() {
		this.location.back();
	}
}