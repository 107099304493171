import * as app from './action';
import * as _ from 'underscore';
import { validateConfig } from '@angular/router/src/config';

class MeetingList { key: string; val: [] }

function getMeetings(date, clientShortName, state) {
    let meetings = _.find(state.meetingList, function (m) { return m.key == date });
    if (meetings && meetings.val[0].inst_short_name == clientShortName.toLowerCase()){ 
       
        return meetings.val;
    }
    else { return date; }
}

function setMeetingList(meetings, state) {
    let todayDate = new Date();
    let currentYear = todayDate.getFullYear();
    let currentMonth = todayDate.getMonth() + 1;
    let currentDate = todayDate.getDate();
    let currentDateString = currentYear + '-' + ('0' + currentMonth).slice(-2) + '-' + ('0' + currentDate).slice(-2);

    let meet = meetings[0];
    if (meet) {
        let _date = new Date(meet.from_time);

        let month = _date.getMonth() + 1;
        let year = _date.getFullYear();
        let d = _date.getDate();
        let dt = year + '-' + ('0' + month).slice(-2) + '-' + ('0' + d).slice(-2);
        if (dt != currentDateString) {
            let obj = new MeetingList();
            obj.key = dt;
            obj.val = meetings;

            let s = _.find(state.meetingList, function (e) { return e.key == dt });
            if (!s) {
                let obj = new MeetingList();
                obj.key = dt;
                obj.val = meetings;
                state.meetingList.push(obj);
            }
            state.meetingList = state.meetingList.slice(0);
        }
    }
    return meetings;
}

function resetSelectedMeetings(state)
{
    state.meetings = [];
    state.datewiseMeetings = null;
}

export interface State {
    meetings: any[];
    meetingList: any[];
    datewiseMeetings: any[];
    studentProfile: any[];
    selectedDate: Date;
}

const initialState: State = {
    meetings: null,
    meetingList: [],
    datewiseMeetings: [],
    studentProfile: [],
    selectedDate: null
}


export function reducer(state = initialState, action: app.Actions): State {
    switch (action.type) {
        case app.ActionTypes.SET_MEETINGS:
            return Object.assign({}, state, { meetings: setMeetingList(action.payload.meetings, state) });
        case app.ActionTypes.GET_MEETINGS:
            return Object.assign({}, state, { datewiseMeetings: getMeetings(action.payload.date, action.payload.clientShortName, state) });
        case app.ActionTypes.SET_STUDENT_PROFILE:
            return Object.assign({}, state, { studentProfile: action.payload.studentProfile });
        case app.ActionTypes.SET_SELECTED_DATE:
                return Object.assign({}, state, { selectedDate: action.payload.selectedDate });    
                case app.ActionTypes.RESET_SELECTED_MEETINGS:
                    return Object.assign({}, state, { datewiseMeetings: resetSelectedMeetings(state) });            
        default:
            return state;
    }
}

export const get_google_meetings = (state: State) => state.meetings;
export const get_all_meetings = (state: State) => state.meetingList;
export const get_datewise_meetings = (state: State) => state.datewiseMeetings;
export const get_student_profile = (state: State) => state.studentProfile;
export const set_online_class_selected_date = (state: State) => state.selectedDate;