import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { Store } from '@ngrx/store';
import * as rootReducer from '../../rootReducer';

class Model {}

@Component({
	templateUrl: './component.html',
	styleUrls: ['./component.css']
})
export class LpInstitutesComponent implements OnInit {
	public m: Model;
    constructor(private store: Store<rootReducer.State>, private router: Router, private location: Location) { }
	ngOnInit() {
		this.init_model();
		this.init_store();
		this.sub_store();
	};
	init_model() {
		this.m = new Model();
	}
	init_store() {}

    sub_store() {}

    ut_login(){
		this.router.navigate(['/home']);
	}

	ut_learners(){
		this.router.navigate(['/learners']);
	}

	ut_institutes(){
		this.router.navigate(['/institutes']);
	}
}