import { Injectable, TemplateRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import { CTApi } from '../../../app/service/ct-api';

import * as acadAction from '../store/action';
import * as courseAction from '../../course/store/action';
import * as rootReducer from '../../../app/rootReducer';
import * as staffAction from '../../staff/store/action';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class AcademicsService {
    constructor(private httpClient: HttpClient,public ctapi: CTApi, private store: Store<rootReducer.State>) { }

    getSpecsFile(fileName: string) {
        return this.ctapi.getSpecsFile(fileName);
    }

    getTopics() {
        // return this.httpClient.get("./assets/dummy/topics.json").subscribe(res => {
        //     this.store.dispatch(new acadAction.SetTopics(res))
        // });
    }
    
    addQuestion(data) {
        return this.ctapi.addQuestion(data)
    }
    createTag(tag) {
        return this.ctapi.createTag(tag);
    }
    createContentTag(tag) {
        return this.ctapi.createContentTag(tag);
    }
    createImageTag(tag) {
        return this.ctapi.createArtefactTag(tag);
    }
    enableTag(tagId) {
        return this.ctapi.enableTag(tagId);
    }
    disableTag(tagId) {
        return this.ctapi.disableTag(tagId);
    }
    enableContentTag(tagId) {
        return this.ctapi.enableContentTag(tagId);
    }
    disableContentTag(tagId) {
        return this.ctapi.disableContentTag(tagId);
    }
    enableImgTag(tagId) {
        return this.ctapi.enableImgTag(tagId);
    }
    disableImgTag(tagId) {
        return this.ctapi.disableImgTag(tagId);
    }
    archiveQ(qId) {
        return this.ctapi.archiveQ(qId);
    }
    unarchiveQ(qId) {
        return this.ctapi.unarchiveQ(qId);
    }
    updateTag(tagObj) {
        return this.ctapi.updateTag(tagObj);
    }
    updateContentTag(tagObj) {
        return this.ctapi.updateContentTag(tagObj);
    }
    updateImgTag(tagObj) {
        return this.ctapi.updateImgTag(tagObj);
    }
    updateQTags(qArr) {
        return this.ctapi.updateQTags(qArr);
    }
    updateAllTags(data) {
        return this.ctapi.updateAllTags(data)
    }
    getAllTags() {
        return this.ctapi.getAllTags();
    }
    getAllImageTags() {
        return this.ctapi.getArtefactTags();
    }
    updateArtefactTags(payload) {
        return this.ctapi.updateArtefactTags(payload);
    }
    getQuestions(queryString) {
        return this.ctapi.getQuestions(queryString)
    }
    saveQuestion(data) {
        return this.ctapi.saveQuestion(data)
    }
    publishQuestions(data) {
        return this.ctapi.publishQuestions(data)
    }
    unpublishQuestions(data) {
        return this.ctapi.unpublishQuestions(data)
    }
    printabsenteesReport(printOptions, selectedDate) {
        return this.ctapi.printabsenteesReport(printOptions, selectedDate);
    }
    printAllAPlusReport(printOptions, selectedCourseIds, tags) {
        return this.ctapi.printAllAPlusReport(printOptions, selectedCourseIds, tags);
    }
    printAttendanceReport(printOptions) {
        return this.ctapi.printAttendanceReport(printOptions);
    }
    printConsolidatedMarksReport(printOptions, selectedCourseIds, sortString, tags) {
        return this.ctapi.printConsolidatedMarksReport(printOptions, selectedCourseIds, sortString, tags);
    }
    printConsolidatedReport(printOptions, courseId, sectionName, term, sortBy, showTopTen) {
        return this.ctapi.printConsolidatedReport(printOptions, courseId, sectionName, term, sortBy, showTopTen);
    }
    printCoursewiseResultAnalysisReport(printOptions, examIds, examName, tags) {
        return this.ctapi.printCoursewiseResultAnalysisReport(printOptions, examIds, examName, tags);
    }
    printExamAttendanceReport(printOptions) {
        return this.ctapi.printExamAttendanceReport(printOptions);
    }
    printExamListReport(printOptions) {
        return this.ctapi.printExamListReport(printOptions);
    }
    printExamwiseFailuresReport(printOptions, examIds, examName, tags) {
        return this.ctapi.printExamwiseFailuresReport(printOptions, examIds, examName, tags);
    }
    printFailuresReport(printOptions, courseIds, tags) {
        return this.ctapi.printFailuresReport(printOptions, courseIds, tags);
    }
    printTopTenScorersReport(printOptions, courseIds, tags) {
        return this.ctapi.printTopTenScorersReport(printOptions, courseIds, tags);
    }
    printFullMarksReport(printOptions, examIds, examName, tags) {
        return this.ctapi.printFullMarksReport(printOptions, examIds, examName, tags);
    }
    printGradewiseStatisticsReport(printOptions, examIds, examName, tags) {
        return this.ctapi.printGradewiseStatisticsReport(printOptions, examIds, examName, tags);
    }
    printHallTicketReport(contentType, students) {
        return this.ctapi.printHallTicketReport(contentType, students);
    }
    printHolidayList(printOptions) {
        return this.ctapi.printHolidayList(printOptions);
    }
    printNoDueCertificateReport(students) {
        return this.ctapi.printNoDueCertificateReport(students);
    }
    printRankListReport(printOptions, tags) {
        return this.ctapi.printRankListReport(printOptions, tags);
    }
    printResultAnalysisReport(printOptions, examIds, examName, tags) {
        return this.ctapi.printResultAnalysisReport(printOptions, examIds, examName, tags);
    }
    printResultAtAGlanceReport(printOptions, tags) {
        return this.ctapi.printResultAtAGlanceReport(printOptions, tags);
    }
    printResultAtAGlanceSummaryReport(printOptions, examIds, examName, tags, comprehensive) {
        return this.ctapi.printResultAtAGlanceSummaryReport(printOptions, examIds, examName, tags, comprehensive);
    }
    printStudentPerformanceReport(printOptions, examId, tags) {
        return this.ctapi.printStudentPerformanceReport(printOptions, examId, tags);
    }
    studentPerformanceReportAsCSV(examId, tags) {
        return this.ctapi.studentPerformanceReportAsCSV(examId, tags);
    }
    printSubjectAverageReport(printOptions, examIds, examName, tags) {
        return this.ctapi.printSubjectAverageReport(printOptions, examIds, examName, tags);
    }
    printTodaysEvent(printOptions) {
        return this.ctapi.printTodaysEvent(printOptions);
    }
    printSubjectwiseTopScorersReport(printOptions, courseIds, tags) {
        return this.ctapi.printSubjectwiseTopScorersReport(printOptions, courseIds, tags);
    }
    printAcademicsUpcomingEvents(printOptions) {
        return this.ctapi.printAcademicsUpcomingEvents(printOptions);
    }

    getCourses() {
        return this.ctapi.getCourses().subscribe(res => {
            this.store.dispatch(new acadAction.SetCourses({ "course_list": res }))
        });
    }

    getCoursesSectionwise() {
        return this.ctapi.getCourses().subscribe(res => {
            this.store.dispatch(new acadAction.SetCoursesSectionwise({ "course_list": res }))
        });
    }

    getCoursesForAttendance() {
        return this.ctapi.getCourses().subscribe(res => {
            this.store.dispatch(new acadAction.SetCoursesForAttendance({ "course_list": res }));
            this.store.dispatch(new courseAction.SetCourses({ "courses": res }));
        });
    }

    getAbsentees(instId, courseId, termName, secName, period, date) {
        // return this.ctapi.getAbsentees(instId, courseId, termName, secName, period, date).subscribe((res: any) => {
        //     if (res) {
        //         res = [{ "rollNo": 1, "name": "Abhay", "present": true },
        //         { "rollNo": 2, "name": "Nivedha", "present": true },
        //         { "rollNo": 3, "name": "Akshay", "present": true }];
        //         this.store.dispatch(new acadAction.SetAbsentees({ "absentee_list": res }))
        //     }
        // });
        return this.ctapi.getAbsentees(instId, courseId, termName, secName, period, date);
    }

    getAllExams(qryString) {
        this.ctapi.getExams(qryString)
            .subscribe(res => {
                this.store.dispatch(new acadAction.SetAllExams({ 'allExams': res['data'] }));
            });
    }

    getExamsCoursewise() {
        this.ctapi.getExamsCoursewise()
            .subscribe(res => {
                this.store.dispatch(new acadAction.SetExamsCoursewise({ 'exams': res['data'] }));
            });
    }

    getExamFacet() {
        this.ctapi.getExamFacet().subscribe(res => {
            this.store.dispatch(new acadAction.SetExamFacet({ "examFacet": res['data'] }));
        })
    }

    getCourseFacet() {
        this.ctapi.getCourseFacet().subscribe(res => {
            this.store.dispatch(new acadAction.SetCourseFacet({ "courseFacet": res['data'] }));
        })
    }

    getStudentsForExamFacet(courseId) {
        this.ctapi.getStudentsForExamFacet(courseId)
            .subscribe(res => {
                this.store.dispatch(new acadAction.SetExamFacetStudents({ "examFacetStudents": res['data'] }));
            });
    }

    addCalendarRule(title, cronExp, eventCategory) {
        var postData = { Title: title, CronExpression: cronExp, EventCategory: eventCategory };
        return this.ctapi.addCalendarRule(postData);
    }
    removeCalendarRule(title) {
        var postData = { Title: title };
        return this.ctapi.removeCalendarRule(postData);
    }
    getCalendarRules(): any {
        return this.ctapi.getCalendarRules();
    }
    getCalendarEvents(): any {
        return this.ctapi.getCalendarEvents();
    }
    generateCalendar(): any {
        return this.ctapi.generateCalendar();
    }
    removeOccurence(title, date) {
        var postData = { Title: title, Date: date };
        return this.ctapi.removeOccurence(postData);
    }
    getPdf(html: any, filename: string) {
        return this.ctapi.getPdf(html).subscribe(res => {
            var newBlob = new Blob([res.body], { type: "application/pdf" });

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(newBlob);
                return;
            }

            // For other browsers: 
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob);
            var link = document.createElement('a');
            link.href = data;
            link.download = filename + ".pdf";
            link.click();

            setTimeout(() => {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
            }, 100);
        });
    }

    downloadCSV(csv, filename){
        var newBlob = new Blob([csv], { type: "application/csv" });
        const data = window.URL.createObjectURL(newBlob);
        var link = document.createElement('a');
        link.href = data;
        link.download = filename + ".csv";
        link.click();

        setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
        }, 100);
    }

    getTimeTablePeriodwise(crs) {
        return this.ctapi.getTimeTablePeriodwise(crs);
    }
    getStaffList() {
        return this.ctapi.getStaff()
            .subscribe(res => {
                this.store.dispatch(new staffAction.SetStaff({ "staff_list": res }));
            });
    }
    applyAttnEvents(attnEvts) {
        return this.ctapi.applyAttnEvents(attnEvts);
    }
    getStu(qStr) {
        return this.ctapi.getStudents(true, false, '', qStr);
    }
    uploadFiles(files_list, data, acadYear) {
        const uploadUrl = `${environment.API_URL}/arte/artefact/upsert`;
        const fileCount: number = files_list.length;
        const formData = new FormData();
        let headers = new HttpHeaders();
        let topic = "meet.sync";
        headers = headers.append('Topic', topic);
        // JavaScript file-like object
        const content = JSON.stringify(data);
        const blob = new Blob([content], { type: 'application/json' });
        if (fileCount > 0) {
            for (let i = 0; i < fileCount; i++) {
                formData.append('files', blob);
                formData.append('files', files_list.item(i));
                formData.append('acadYear', acadYear);
            }
            return this.httpClient.post(uploadUrl, formData, {headers: headers});
        }
        else return null;
    }
    getVaultImages(qryString) {
        return this.ctapi.getVaultImages(qryString);
    }
    saveImage(imageFile, data, acadYear) {
        const uploadUrl = `${environment.API_URL}/arte/artefact/upsert`;
        const formData = new FormData();
        const content = JSON.stringify(data);
        const blob = new Blob([content], { type: 'application/json' });
        if (imageFile) {
                formData.append('files', blob);
                formData.append('files', imageFile);
                formData.append('acadYear', acadYear);
                return this.httpClient.post(uploadUrl, formData);
        }
    }
    saveJson(jsonFile, data, acadYear) {
        const uploadUrl = `${environment.API_URL}/arte/artefact/upsert`;
        const formData = new FormData();
        const content = JSON.stringify(data);
        const blob = new Blob([content], { type: 'application/json' });
        if (jsonFile) {
                formData.append('files', blob);
                formData.append('files', jsonFile);
                formData.append('acadYear', acadYear);
            return this.httpClient.post(uploadUrl, formData);
        }
    }
    saveVx(jsonFile, data, id, path) {
        const uploadUrl = `${environment.API_URL}/arte/github/upsert`;
        const formData = new FormData();
        const content = JSON.stringify(data);
        const blob = new Blob([content], { type: 'application/json' });
        const tags = [];
        if (jsonFile) {
                formData.append('files', blob);
                formData.append('files', jsonFile);
                formData.append('id', id);
                formData.append('path', path);
            return this.httpClient.post(uploadUrl, formData);
        }
    }
    getArtefactData() {
        return this.ctapi.getArtefactData();
    }
    getDocs(id) {
        return this.ctapi.getDocs(id);
    }
    enableDoc(id) {
        return this.ctapi.enableDoc(id);
    }
    disableDoc(id) {
        return this.ctapi.disableDoc(id);
    }
    publishDoc(id) {
        return this.ctapi.publishDoc(id, "");
    }
    publishToPublic(id, topicId) {
        return this.ctapi.publishDoc(id, topicId);
    }
    unpublishDoc(id) {
        return this.ctapi.unpublishDoc(id);
    }
    getDocsByPath(path, qry) {
        return this.ctapi.getDocsByPath(path, qry);
    }
    getDocsContentByPath(path, qry) {
        return this.ctapi.getDocsContentByPath(path, qry);
    }
    getCurriculum(id,attributeName) {
        return this.ctapi.downloadJson(id)
            .subscribe(res => {
                if(res && res[attributeName] && res[attributeName].length>0){
                    this.store.dispatch(new acadAction.SetTopics(res));
                }
            });
    }
    getDocContent(id) {
        return this.ctapi.downloadDoc(id);
    }

    addComment(payload) {
        return this.ctapi.addComment(payload);
    }

    addVideoLink(payload) {
        return this.ctapi.addVideoLink(payload);
    }

    updateDataAttributes(payload){
        return this.ctapi.updateDataAttributes(payload);
    }

    updateContentTags(data) {
        return this.ctapi.updateContentTag(data)
    }
    getAllContentTags() {
        return this.ctapi.getContentTags();
    }
}
export class WindowRef {
    constructor() { }

    getNativeWindow() {
        return window;
    }
}