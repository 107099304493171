import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';

import * as rootReducer from '../../rootReducer';
import { Router } from '@angular/router';
import { UserAccessService as service } from './service/service';

class Model {
    userAccess: any;
    showActiveUsers: boolean;
}
@Component({
    selector: 'user-access',
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
    providers: [service]
})

export class UserAccessComponent {
    public m: Model;
    @Input() pin: boolean;
    constructor(private router: Router, private service: service, private store: Store<rootReducer.State>) { }
    ngOnInit() {
        this.init_model();
        this.init_store();
    }
    init_model() {
        this.m = new Model();
    }

    init_store() {
        // this.store.select(rootReducer.get_user_access_widget).subscribe((res: any) => {
        //     if (res) {
        //     }
        // });
        this.m.userAccess = [{
            "inst_short_name": "BTLCOL",
            "users": [{
                "name": "Nivedha",
                "email": "nivedha@bitloka.com",
                "status": 1,
                "last_access": "2019-10-18T07:07:42.6858730Z",
                "last_action": "Student add"
            }]
        }];
    }

    ut_showActiveUsers(){}

    pinChart() {
        this.pin = false;
        let payload = ["user_access"];
        this.service.pinWidget(payload).subscribe(res => {
            if (res) this.service.getDashboardWidgets();
        });

    }
    unpinChart() {
        this.pin = true;
        let payload = ["user_access"];
        this.service.unpinWidget(payload).subscribe(res => {
            if (res) this.service.getDashboardWidgets();
        });
    }
}