import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs/Subscription";
import { CTApi } from "../../service/ct-api";
import { HomeService as service } from '../service/home-service';
import * as rootReducer from '../../rootReducer';
import { NgxSpinnerService } from "ngx-spinner";


class Model {
    email: string = "";
    mobile: string = "";
    userName: string = "";
    password: string = "";
    mobileIsValid: boolean;
    userRegistered: boolean = false;
    userRegistrationError: boolean = false;
    userExists: boolean = false;
}


@Component({
    selector: 'sign-up',
    templateUrl: './component.html',
    styleUrls: ['./component.css'],
    providers: [service]
})
export class SignUpComponent implements OnInit {
    public m: Model;

    constructor(public service: service, private store: Store<rootReducer.State>, public ctapi: CTApi, private router: Router, private spinner: NgxSpinnerService) { }
    ngOnInit() {
        this.init_model();
        this.init_store();
        this.sub_store();
    }

    ngOnDestroy() {
    }
    init_model() {
        this.m = new Model();
    }
    init_store() {
    }
    sub_store() { }

    ut_submit() {
        let payload = { "Name": this.m.userName, "Email": this.m.email, "Password": this.m.password, "Mobile": this.m.mobile };
        this.service.signUp(payload).subscribe(res => {
            if(res) this.m.userRegistered = true; 
        }, 
        err => { 
            this.spinner.hide();
            this.m.userRegistrationError = true; }
        );
    }

    userNameChanged(userName){
        if(this.m.userExists) this.m.userExists = false;
    }

    focusOut() {
        this.service.getUserByName(this.m.userName).subscribe(res => {
            if(res != null) this.m.userExists = true;
            else this.m.userExists = false;
        },
          err => { }
        );
    }

    omit_special_char_handle(event) {
        var k;
        k = event.charCode;
        return (k == 46 || k == 64 || (k > 96 && k < 123) || k == 8 || (k >= 48 && k <= 58) || (k >= 65 && k <= 90) || k == 95 || k == 43);
    }

    validateMobile(mobile) {
		if (mobile.length <= 10) this.m.mobileIsValid = true;
        else this.m.mobileIsValid = false;
	}

    omit_characters_except_numbers(event){
        var k;
        k = event.charCode;
        return (k >= 48 && k <= 57);
    }
}